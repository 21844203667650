import { useTable, usePagination } from 'react-table';
import styled from 'styled-components';

export default function Table({ columns, data, title, tableTag, children }) {
  // Use the state and functions returned from useTable to build your UI
  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  //   useTable({
  //     columns,
  //     data,
  //   });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <StyledTable>
      <table {...getTableProps()}>
        <caption className="text-left text-2xl p-4">
          {children}
          <span className="p-4 block">{tableTag}</span>
          <div className="bg-[#E0E0E0] h-[1px]" />
        </caption>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    className: column.className,
                    style: column.style,
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        {' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        {' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        {' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        {' '}
      </div>
    </StyledTable>
  );
}
const StyledTable = styled.div`
  max-width: 100rem;
  width: 100%;
  
  table {
    width: 100%;
    background: ${({ theme }) => theme.colors.white};
    font-size: 1.2rem;
    border-spacing: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary_lemon};
    border-left: 2px solid ${({ theme }) => theme.colors.primary_lemon};
    border-right: 2px solid ${({ theme }) => theme.colors.primary_lemon};
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
  }
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  caption{
    text-align: left;
    background: #ffffff;
    padding: 5px;
    border-top: 2px solid ${({ theme }) => theme.colors.primary_lemon};
    border-left: 2px solid ${({ theme }) => theme.colors.primary_lemon};
    border-right: 2px solid ${({ theme }) => theme.colors.primary_lemon};
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  },
  th,
  td {
    border-bottom: 1px solid #D1E8E2;
    margin: 0;
    padding: 0.5rem;
    padding: 1rem 0;
    text-align: start;
    :last-child {
      border-right: 0;
    }
  }

  // td{
  //   border-bottom: 1px solid #D1E8E2;
  // }

  th {
    ${({ theme }) => theme.typography.headingBody5};
    font-weight: 500;
  }
  .pagination {
    display: flex;
    justify-content: end;
    button {
      padding: 0.5rem 0.7rem;
      border-radius: 5px;
      margin-right: 0.5rem;
      background: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.primary_lemon};
      cursor: pointer;
    }
  }
`;
