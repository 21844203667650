import React from 'react';
import styled from 'styled-components';

function FRRQuestionBoard({ children }) {
  return <Styled className="orr_ques_nav">{children}</Styled>;
}
const Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 6px solid #d1e8e2;
  width: 68%;
  border-radius: 15px;
  min-height: 60vh;
  position: relative;
  padding: 0 10%;
`;

export default FRRQuestionBoard;
