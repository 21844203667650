import React, { useState } from 'react';
import Button, { NextButton, PrevButton } from 'components/Buttons';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ErrorHandler from 'helpers/errorHandler';
import useSubSectorImage from 'hooks/useSubSectorImage';
import { validateAnswer } from 'helpers/questionsAnswers';
import Question from './QuestionTypes';
import Tab from '../Tab';

export default function Controller(props) {
  const [subSectorImage, isImageLoading] = useSubSectorImage();
  const [star, setStar] = useState(0);
  const [selected, setSelected] = useState({});
  const [input, setInput] = useState('');
  const [selectedMultiple, setSelectedMultiple] = useState([]);

  const setQuesIndexToSession = (value) => {
    sessionStorage.setItem('_otherQuestionIndex', value);
  };

  const prevHandler = () => {
    if (props.currentQuestionIndex == 0) {

    } else if (props.currentQuestionIndex >= 1) {
      if (
        props.questions[props.currentQuestionIndex - 1]?.questionClass != 'S'
      ) {
        props.setCurrentQuestionIndex((prev) => {
          setQuesIndexToSession(prev - 1);
          return prev - 1;
        });
      } else {
        const getPrimaryQuestion = props.questions.filter(
          (elem) => elem.followUpId
            == props.questions[props.currentQuestionIndex - 1].questionId
        );
        const triggerAnswer = getPrimaryQuestion[0]?.selectedAnswer?.answer?.toUpperCase();
        if (
          getPrimaryQuestion.length > 0
          && triggerAnswer == getPrimaryQuestion[0].followUpTrigger.toUpperCase()
        ) {
          props.setCurrentQuestionIndex((prev) => {
            setQuesIndexToSession(prev - 1);
            return prev - 1;
          });
        } else {
          if (props.currentQuestionIndex - 2 <= 0) {
            props.setCurrentQuestionIndex(0);
            return;
          }
          props.setCurrentQuestionIndex((prev) => {
            setQuesIndexToSession(prev - 2);
            return prev - 2;
          });
        }
      }
    } else {
      toast.error('Incomplete Field or Invalid Inputs');
    }
  };

  const nextHandler = () => {
    const quesLength = props?.questions?.length - 1;
    const entry = props.questions[props.currentQuestionIndex];

    if (props.currentQuestionIndex == quesLength && validateAnswer(entry)) {
      // go to summary
      // nextSectionHandler();
      return props.summaryNav();
    } if (
      props.currentQuestionIndex < quesLength
      && validateAnswer(entry)
    ) {
      if (
        props.questions[props.currentQuestionIndex + 1]?.questionClass != 'S'
      ) {
        props.setCurrentQuestionIndex((prev) => {
          setQuesIndexToSession(prev + 1);
          return prev + 1;
        });
        setStar(0);
        setSelected({});
        setInput('');
        return setSelectedMultiple([]);
      }
      const getPrimaryQuestion = props.questions.filter(
        (elem) => elem.followUpId
            == props.questions[props.currentQuestionIndex + 1].questionId
      );
      const triggerAnswer = getPrimaryQuestion[0]?.selectedAnswer?.answer?.toUpperCase();
      if (
        getPrimaryQuestion.length > 0
          && triggerAnswer == getPrimaryQuestion[0].followUpTrigger.toUpperCase()
      ) {
        props.setCurrentQuestionIndex((prev) => {
          setQuesIndexToSession(prev + 1);
          return prev + 1;
        });
        setStar(0);
        setSelected({});
        setInput('');
        return setSelectedMultiple([]);
      }
      if (props.currentQuestionIndex + 2 > quesLength) {
        // nextSectionHandler();
        // go to summary
        return props.summaryNav();
      }
      // last questions
      props.setCurrentQuestionIndex((prev) => {
        setQuesIndexToSession(prev + 2);
        return prev + 2;
      });
      setStar(0);
      setSelected({});
      setInput('');
      return setSelectedMultiple([]);
    }
    toast.error('Incomplete Field or Invalid Inputs');
  };
  return (
    <Styled className="section_ques">
      <main className="collateral_ques_main">
        <Tab othersPages />
        {props.questions && (
          <>
            <div className="collateral_ques_container">
              <Question
                index={props.currentQuestionIndex}
                question={props?.questions[props.currentQuestionIndex]}
                questionLength={props?.questions?.length}
                updateQuestionsPoolHandler={props.updateQuestionsPoolHandler}
                star={star}
                setStar={setStar}
                selected={selected}
                setSelected={setSelected}
                input={input}
                setInput={setInput}
                selectedMultiple={selectedMultiple}
                setSelectedMultiple={setSelectedMultiple}
              />
            </div>
            <div className="collateral_button_section">
              {props.currentQuestionIndex ? (
                <PrevButton onClick={prevHandler} />
              ) : (
                <div />
              )}
              <NextButton
                onClick={nextHandler}
                title={
                  props.currentQuestionIndex == props?.questions?.length - 1
                    ? 'Save & Proceed'
                    : ''
                }
              />
            </div>
          </>
        )}
      </main>
      <aside className="collateral_ques_aside">
        <div className="img-wrapper">
          <img
            src={subSectorImage.length > 2 ? subSectorImage : undefined}
            alt=""
          />
        </div>
        <Button mode="5" title="Exit" onClick={props?.exitHandler} />
      </aside>
    </Styled>
  );
}

const Styled = styled.div`
  display: flex;
  justify-content: space-between;

  .collateral_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    min-height: 45rem;
    background-color: #fff;
    display: flex;
  }

  .collateral_button_section {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
  }
  .collateral_ques_main {
    width: 65%;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  .collateral_ques_aside {
    width: 28%;
    text-align: center;
    padding-top: 8rem;
    @media (max-width: 1200px) {
      width: 30%;
    }
    @media (max-width: 85rem) {
      display: none;
    }
    button {
      background: #004c4e;
      color: #fff;
    }
    .img-wrapper {
      height: 50rem;
      overflow: hidden;
      margin-bottom: 3.5rem;
      border-radius: 1rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
