import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomInput from 'components/FormikInputs/Input';
import { NextButton, PrevButton } from 'components/Buttons';
import { validationApis, firsApis, AccountOpeningV2} from 'apis';
import * as SignUpFormActions from 'store/actions/signUpForm';
import * as SignUpActions from 'store/actions/signUpForm';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ErrorHandler from 'helpers/errorHandler';
import styled from 'styled-components';
import AccountValidation from './AccountValidation';
import OverLaySpinner from 'components/OverLays/OverLaySpinner';
import errorHandler from 'helpers/errorHandler';
import useNotify from 'hooks/useNotify';

export default function AccountNo() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const { signUpForm } = useSelector((state) => state);
  const [ directorateLoader, setDirectorateLoader ] = useState(false);
  const dispatch = useDispatch();
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {}
  function closeModal() {
    setIsOpen(false);
  }
  const validationSchema = yup.object({
    companyAccountNumber: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only Numbers')
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits')
      .required('Account Number required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyAccountNumber: signUpForm?.accDetails?.ACCT_NO
        ? signUpForm?.accDetails?.ACCT_NO
        : '',
    },
    validationSchema,
    onSubmit: (values) => {
      checkAccountDirectorate(values.companyAccountNumber)
    },
  });

  const [setNotify] = useNotify();

  const accountNotify = (message) => setNotify({
    show: true,
    type: 'info',
    btnText: 'Edit Account',
    btnTextTwo: 'Proceed',
    message: message,
    func: () => {
      
    },
    funcTwo: () => {
      dispatch(SignUpFormActions.SaveCustomerType('New'));
    }
  });
  
  const checkAccountDirectorate = async (arg) => {
    setDirectorateLoader(true);
    try{
      const response = await AccountOpeningV2.getAccountDirectorate(arg);
      if(response?.data?.status){
        validateAccNo(arg);
      }else{
        accountNotify(`The account directorate isn't available on Gazelle, Kindly enter an appropriate account number.`);
      }
    }catch(error){
      errorHandler(error);
    }finally{
      setDirectorateLoader(false);
    }
  }

  const validateAccNo = async (accNo) => {
    try {
      setLoader(true);
      const response = await firsApis.getAccountDetailsByNuban(accNo);

      if (response?.data?.BankAccountFullInfo?.ACCT_NO.length == 10) {
        const filterAccDetails = {
          ACCT_NO: response.data.BankAccountFullInfo.ACCT_NO,
          CUS_NUM: response.data.BankAccountFullInfo.CUS_NUM,
          TEL_NUM: response.data.BankAccountFullInfo.TEL_NUM,
          AccountTitle: response.data.BankAccountFullInfo.AccountTitle,
          ADD_LINE1: response.data.BankAccountFullInfo.ADD_LINE1,
          email: response.data.BankAccountFullInfo.email,
        };
        dispatch(SignUpActions.SaveFinal({}));
        dispatch(SignUpFormActions.SaveAccount(filterAccDetails));
      } else if (
        response?.data?.BankAccountFullInfo?.ACCT_NO
        == 'No records were found that matched the selection criteria'
      ) {
        toast.error('Error Retrieving Details, Try Again Later');
        return;
      } else if (response?.data?.BankAccountFullInfo?.ResponseCode) {
        toast.error('Error Retrieving Details, Try Again Later');
        return;
      } else {
        toast.error('Invalid Account Number');
        return;
      }
      const responseNubanMore = await firsApis.getMoreByNuban(
        response.data.BankAccountFullInfo.CUS_NUM
      );

      if (responseNubanMore.data.Record['TAX.ID'] == null) {
        return toast.error(
          'No Tin-No is attached to your account, kindly contact your account manager'
        );
      }

      const filterAccDetails = {
        'TAX.ID': responseNubanMore.data.Record['TAX.ID'],
        SECTOR: responseNubanMore.data.Record.SECTOR,
        'RC.NUMBER': responseNubanMore.data.Record['RC.NUMBER'],
      };
      dispatch(SignUpActions.UpdateAccount(filterAccDetails));

      const responseTin = await validationApis.checkExistTin(
        responseNubanMore.data.Record['TAX.ID']
      );

      if (responseTin?.status == 200 && responseTin?.data?.status == true) {
        toast.error(
          'The Tin No attached to your account already exist in our system'
        );
        return;
      }

      setIsOpen(true);
    } catch (error) {
      ErrorHandler(error, 'Error Verifying Account, Kindly Try Again');
    } finally {
      setLoader(false);
    }
  };
  return (
    <Styled>
      {directorateLoader ? <OverLaySpinner /> : ''} 
      <h2 className="title">Sign Up</h2>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          name="companyAccountNumber"
          label="Account Number"
          type="textNumber"
          maxLength="10"
          required
          formik={formik}
        />
        <div className="navButton">
          <PrevButton
            mode="3"
            title="Prev"
            type="button"
            onClick={() => dispatch(SignUpActions.SaveActivePage('loan'))}
          />
          <NextButton loader={loader} type="submit" mode="1" title="Next" />
        </div>
      </form>
      <AccountValidation
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        afterOpenModal={afterOpenModal}
      />
    </Styled>
  );
}
const Styled = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 5rem;
  padding-top: 15%;
  .navButton {
    display: flex;
    justify-content: space-between;
  }
  .title {
    margin-bottom: 2rem;
    font-weight: 500;
    color: #004c4e;
  }
`;
