import React, { useState } from 'react';
import Button from 'components/Buttons';
import { useSelector } from 'react-redux';
import { firsApis } from 'apis';
import ErrorHandler from 'helpers/errorHandler';
import styled from 'styled-components';

export default function AccValidateModal(props) {
  const [loader, setLoader] = useState(false);
  const { signUpForm } = useSelector((state) => state);
  const requestOtp = async () => {
    try {
      setLoader(true);
      await firsApis.generateEmailPhoneOtp({
        phone: signUpForm?.accDetails?.TEL_NUM,
        email: signUpForm?.accDetails?.email || '',
      });

      props.setAccountPage(true);
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <Styled>
      <p className="title">Company Name Validation</p>
      <div>
        <p className="company-name">{signUpForm.accDetails.AccountTitle}</p>
        <p className="correct">Is this correct?</p>
      </div>
      <div className="buttonRow">
        <p onClick={props.closeModal} className="noItsNot">
          No, its not
        </p>
        <Button
          loader={loader}
          title="Yes, it is"
          mode="1"
          onClick={requestOtp}
        />
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  .title {
    margin-bottom: 1rem;
    font-weight: 500;
    color: #004c4e;
    font-size: 1.8rem;
  }
  .label {
    font-size: 1.4rem;
    color: #505050;
  }
  .company-name {
    background-color: #e5e5e5;
    padding: 1rem;
    border-radius: 5px;
    margin-top: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .correct {
    margin: 2rem 0;
    color: #004c4e;
  }
  .buttonRow {
    display: flex;
    margin-top: 2rem;
    align-items: center;
    justify-content: flex-end;
    color: #004c4e;
    button {
      margin-left: 2rem;
    }
  }
  .noItsNot {
    cursor: pointer;
  }
`;
