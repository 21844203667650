import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomInput from 'components/FormikInputs/Input';
import { NextButton, PrevButton } from 'components/Buttons';
import { firsApis, validationApis } from 'apis';
import * as SignUpFormActions from 'store/actions/signUpForm';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ErrorHandler from 'helpers/errorHandler';
import styled from 'styled-components';
import TinValidation from './TinValidation';

export default function TIn() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const { signUpForm } = useSelector((state) => state);
  const dispatch = useDispatch();
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {}
  function closeModal() {
    setIsOpen(false);
  }
  const validationSchema = yup.object({
    tin: yup.string().min(12).required('Tin required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tin: signUpForm?.tinDetails.tin ? signUpForm?.tinDetails.tin : '',
    },
    validationSchema,
    onSubmit: (values) => {
      checkIfTinExist(values.tin.trim());
    },
  });
  const onValidateTin = async (tin) => {
    try {
      const response = await firsApis.getTin(tin);
      if (
        response.data?.data?.tin == tin
        && response.data?.data?.taxPayerName?.length > 3
      ) {
        dispatch(SignUpFormActions.SaveFinal({}));
        dispatch(
          SignUpFormActions.SaveTin({
            ...response.data.data,
            isTinVerified: true,
          })
        );
        setIsOpen(true);
      } else if (
        (response?.data?.data == null
          && response?.data?.message.includes(
            'Unfortunately, we encountered error while processing'
          ))
        || (response?.data?.data?.taxPayerName == null
          && response.data.message.includes(
            'The service provider is not available at the moment'
          ))
      ) {
        dispatch(SignUpFormActions.SaveTin({ tin, isTinVerified: false }));
        dispatch(SignUpFormActions.SaveFinal({}));
        dispatch(SignUpFormActions.SaveActivePage('newExitingCreateUser'));
      }
    } catch (error) {
      ErrorHandler(error);
      dispatch(SignUpFormActions.SaveTin({ tin, isTinVerified: false }));
      dispatch(SignUpFormActions.SaveFinal({}));
      dispatch(SignUpFormActions.SaveActivePage('newExitingCreateUser'));
    } finally {
      setLoader(false);
    }
  };
  const checkIfTinExist = async (tin) => {
    setLoader(true);
    try {
      const response = await validationApis.checkExistTin(tin);
      if (response.status == 200 && response.data.status == true) {
        toast.error('Tin Already Exist');
        return setLoader(false);
      } if (response.status == 200 && response.data.status == false) {
        return onValidateTin(tin);
      }
    } catch (error) {
      setLoader(false);
      ErrorHandler(error);
    }
  };
  return (
    <Styled>
      <h2 className="title">Sign Up</h2>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          name="tin"
          label="Company TIN"
          placeholder="12345678-0001"
          type="text"
          required
          formik={formik}
        />
        <div className="navButton">
          <PrevButton
            mode="3"
            title="Prev"
            type="button"
            onClick={() => dispatch(SignUpFormActions.SaveActivePage('loan'))}
          />
          <NextButton loader={loader} type="submit" mode="1" title="Next" />
        </div>
      </form>
      <TinValidation
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        afterOpenModal={afterOpenModal}
      />
    </Styled>
  );
}
const Styled = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 5rem;
  padding-top: 15%;
  @media (max-width: 992px) {
    padding-top: 5%;
  }
  .navButton {
    display: flex;
    justify-content: space-between;
  }
  .title {
    margin-bottom: 2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
  }
`;
