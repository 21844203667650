import { colors } from './colors';

export const typography = {
  heading1: {
    color: colors.primary,
    fontSize: '6.0rem',
    lineHeight: '8.0rem',
    letterSpacing: '-0.03em',
    textTransform: 'uppercase',
  },
  heading2: {
    color: colors.primary,
    fontSize: '5.0rem',
    lineHeight: '7.0rem',
    letterSpacing: '-0.03em',
    textTransform: 'uppercase',
  },
  heading3: {
    color: colors.primary,
    fontSize: '4.0rem',
    lineHeight: '6.0rem',
    letterSpacing: '-0.03em',
    textTransform: 'uppercase',
  },
  heading4: {
    color: colors.primary,
    fontSize: '2.8rem',
    lineHeight: '4.0rem',
    letterSpacing: '-0.03em',
    textTransform: 'uppercase',
  },
  heading5: {
    color: colors.primary,
    fontSize: '1.8rem',
    lineHeight: '3.0rem',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
  },
  heading6: {
    color: colors.primary,
    fontSize: '1.4rem',
    lineHeight: '2.0rem',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
  },
  //   headingBody
  headingBody1: {
    color: colors.primary,
    fontSize: '2.8rem',
    lineHeight: '4.0rem',
    letterSpacing: '-0.03em',
  },
  headingBody2: {
    color: colors.primary,
    fontSize: '2.4rem',
    lineHeight: '3.5rem',
    letterSpacing: '-0.03em',
  },
  headingBody3: {
    color: colors.primary,
    fontSize: '1.8rem',
    lineHeight: '3.0rem',
    letterSpacing: '-0.03em',
  },
  headingBody4: {
    color: colors.primary,
    fontSize: '16px',
    lineHeight: '3.0rem',
    letterSpacing: 'normal',
  },
  headingBody5: {
    color: colors.primary,
    fontSize: '1.4rem',
    lineHeight: '2.5rem',
    letterSpacing: 'normal',
  },
  headingBody6: {
    color: colors.primary,
    fontSize: '1.2rem',
    lineHeight: '2.0rem',
    letterSpacing: 'normal',
  },
  //   Body
  body1: {
    fontSize: '2.8rem',
    lineHeight: '8.0rem',
    letterSpacing: '-0.03em',
  },
  body2: {
    fontSize: '2.4rem',
    lineHeight: '4.3rem',
    letterSpacing: '-0.03em',
  },
  body3: {
    fontSize: '1.8rem',
    lineHeight: '3.2rem',
    letterSpacing: 'normal',
  },
  body4: {
    fontSize: '16px',
    lineHeight: '29px',
    letterSpacing: 'normal',
  },
  body5: {
    fontSize: '1.4rem',
    lineHeight: '2.5rem',
    letterSpacing: 'normal',
  },
  body6: {
    fontSize: '1.2rem',
    lineHeight: '2.2rem',
    letterSpacing: 'normal',
  },
};
