import React from 'react';
import styled from 'styled-components';
import Button from 'components/Buttons';
import ProgressBar from './ProgressBar';

export function FeedBack({ isFeedback }) {
  let text = '';
  if (isFeedback) {
    text = <span className="feedback_red">You have pending feedbacks</span>;
  } else {
    text = 'No feedback yet.';
  }

  return (
    <p>
      {' '}
      Feedback:
      {text}
      {' '}
    </p>
  );
}

export function Progress({
  feedBackLoader,
  isDocLoading,
  feedBackStatus,
  getProgress,
}) {
  return (
    <>
      {!isDocLoading && !feedBackLoader ? (
        <>
          <div className="application_box_col_2">
            <p>
              Progress:
              {getProgress()}
              %
            </p>
            <ProgressBar width={getProgress()} />
          </div>
          <FeedBack isFeedback={feedBackStatus} />
        </>
      ) : undefined}
    </>
  );
}

export default function Box({
  Icon,
  clickHandler,
  title,
  feedBackLoader,
  feedBackStatus,
  isDocLoading,
  getProgress,
  noProgress,
}) {
  return (
    <Styled>
      <div className="application_box_col">
        <Icon fill="#00989C" />
        <p>{title}</p>
      </div>
      <div>
        <p>{isDocLoading && feedBackLoader ? 'loading...' : undefined}</p>
      </div>

      {!noProgress ? (
        <Progress
          isDocLoading={isDocLoading}
          feedBackLoader={feedBackLoader}
          getProgress={getProgress}
          feedBackStatus={feedBackStatus}
        />
      ) : (
        <div className="mt-4">
          <FeedBack isFeedback={feedBackStatus} />
        </div>
      )}

      <div className="application_box_btn">
        <Button
          mode="4"
          onClick={clickHandler}
          className="application_btn"
          title="Proceed"
        />
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  margin-right: 1rem;
  padding: 4rem 3rem 3rem 4rem;
  background: #fff;
  border-radius: 5px;
  color: #004c4e;
  font-size: 1.4rem;
  min-width: 350px;
  margin-bottom: 2rem;

  p:nth-of-type(1) {
    font-weight: 500;
    font-size: 1.4rem;
  }
  .application_btn {
    &:hover {
      color: #fff;
    }
  }
  .feedback_red {
    color: #d45f36;
    font-size: 1.2rem;
  }

  .application_box_col {
    display: flex;
    align-items: center;
    p {
      margin-left: 1rem;
    }
  }
  .application_box_col_2 {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    p {
      margin-right: 0.5rem;
    }
  }
  .application_box_btn {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  #feedback {
    margin-top: 0.5rem;
    color: #004c4e;
  }
`;
