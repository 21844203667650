import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import { NextButton } from 'components/Buttons';
import { FiMinusCircle, FiPlusCircle, FiInfo } from 'react-icons/fi';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { adminUserApis, firsApis } from 'apis';
import Spinner from 'components/OverLays/OverLaySpinner';
import { toast } from 'react-toastify';

export const formModelTwo = {
  businessId: '',
  firstName: '',
  lastName: '',
  gender: 'Male',
  position: '',
  qualification: '',
  relationshipWithSterlingStaff: false,
  noOfYearsInRole: '0-2yrs',
  bvn: '',
  politicalOfficeHolder: false,
  primaryPromoter: false,
  email: '',
  phone: '',
  relationshipWithSterlingStaff_name: '',
  relationshipWithSterlingStaff_relationship: '',
};
export default function DirectorsForm(props) {
  const [state, setState] = useState({
    tab: [formModelTwo],
    activeTab: 0,
  });
  useEffect(() => {
    setState({
      tab: props?.directors?.length > 0 ? props?.directors : [formModelTwo],
      activeTab: 0,
    });
  }, [props.directors]);

  const [mgtList, setMgtList] = useState([]);
  useEffect(() => {
    let isMounted = true;
    const getAllMgtPosition = async () => {
      // store in local storage to avoid repeat
      try {
        if (sessionStorage.getItem('principal_mgtList')?.length > 10) {
          isMounted
            && setMgtList(JSON.parse(sessionStorage.getItem('principal_mgtList')));
        } else {
          const res = await adminUserApis.getAllMgtPositions();
          if (res.data.status) {
            sessionStorage.setItem(
              'principal_mgtList',
              JSON.stringify(res.data.data)
            );
            isMounted && setMgtList(res.data.data);
          }
        }
      } catch (error) {}
    };
    getAllMgtPosition();
    return () => {
      isMounted = false;
    };
  }, []);
  const addTab = async (formikValidate) => {
    const res = await formikValidate.validateForm();
    if (_isEmpty(res) && state?.tab?.length < 6) {
      setState((prev) => ({
        ...prev,
        tab: [...prev.tab, formModelTwo],
        activeTab: state?.tab?.length,
      }));
    }
  };
  const removeTab = () => {
    const stateClone = _cloneDeep(state.tab);
    const filterTab = stateClone?.filter((elem, i) => i != state?.activeTab);
    setState((prev) => ({
      ...prev,
      tab: filterTab,
      activeTab: 0,
    }));
  };
  const setActiveTabHandler = async (index, formikValidate) => {
    const res = await formikValidate.validateForm();
    if (_isEmpty(res)) {
      setState((prev) => ({
        ...prev,
        activeTab: index,
      }));
    }
  };
  const onChangeHandler = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const stateClone = _cloneDeep(state);
    stateClone.tab[state?.activeTab][name] = value;
    setState(stateClone);
  };
  const onGenderHandler = (value) => {
    const stateClone = _cloneDeep(state);
    stateClone.tab[state?.activeTab].gender = value;
    setState(stateClone);
  };
  const onYearInRoleHandler = (value) => {
    const stateClone = _cloneDeep(state);
    stateClone.tab[state?.activeTab].noOfYearsInRole = value;
    setState(stateClone);
  };

  const nextHandler = async (e) => {
    props.setDirectors(state?.tab);
    if (!isBvnDuplicate(state?.tab)) {
      return;
    } if (state.tab.length <= 2) {
      return toast.error('Minimum of Three Principals');
    } if (!isCeoChairmanPresent(state.tab)) {
      return toast.error(
        'Min of 2 of these officers: 1 CEO/1 Chairman/1 Executive Director'
      );
    } if (state?.tab?.length - 1 != state?.activeTab) {
      return setState((prev) => ({
        ...prev,
        activeTab: prev.activeTab + 1,
      }));
    }
    props.nextHandler(props.next);
  };
  function checkForDuplicates(array) {
    return new Set(array).size !== array.length;
  }
  const isBvnDuplicate = (values) => {
    const getAllBVN = [];
    for (const element of values) {
      getAllBVN.push(element.bvn);
    }
    if (checkForDuplicates(getAllBVN)) {
      toast.error('Duplicate BVN Inputted');
    }
    return !checkForDuplicates(getAllBVN);
  };
  const isCeoChairmanPresent = (values) => {
    const isPresent = new Set();
    for (const element of values) {
      if (element.position == 1) {
        isPresent.add(1);
      } else if (element.position == 2) {
        isPresent.add(2);
      } else if (element.position == 3) {
        isPresent.add(3);
      }
    }
    return isPresent.size >= 2;
  };

  const formik = useFormik({
    initialValues: state.tab[state.activeTab],
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      gender: yup.string().required(),
      position: yup.string().required(),
      qualification: yup.string().required(),
      bvn: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only Numbers')
        .min(11, 'Must be exactly 11 digits')
        .max(11, 'Must be exactly 11 digits')
        .required(),
      email: yup.string().email().required(),
      phone: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only Numbers')
        .min(11, 'Must be exactly 11 digits')
        .max(11, 'Must be exactly 11 digits')
        .required(),
      noOfYearsInRole: yup.string().required(),
    }),
    enableReinitialize: true,
    onSubmit: async () => {
      nextHandler();
    },
  });
  return (
    <Styled onSubmit={formik.handleSubmit}>
      <div className="warning_message">
        <FiInfo size={20} />
        <p>
          Min of 3, max of 6 principal officers. Min of 2 of these officers: 1
          CEO/1 Chairman/1 Executive Director. Duplicate BVN not allowed.
        </p>
      </div>
      <div className="orr_ques_container">
        <div className="orr_ques_container_wrapper">
          <div className="title">
            <p>Provide details of Principal Officer(s)</p>
          </div>
          <section className="tab_form">
            <div className="tab_form_nav">
              {state?.tab.map((elem, index) => (
                <div
                  role="tab"
                  onClick={() => setActiveTabHandler(index, formik)}
                  key={index}
                  className={`tab_form_nav_item ${
                    state?.activeTab == index && 'tab_form_nav_item-active'
                  }`}
                >
                  {index + 1}
                </div>
              ))}
            </div>
            <div className="tab_form_main">
              <section>
                <div className="form-group">
                  <label htmlFor="firstName">First Name:</label>
                  <div className="input_error_container">
                    <input
                      id="firstName"
                      placeholder="Enter details"
                      type="text"
                      value={formik?.values?.firstName || ''}
                      onChange={onChangeHandler}
                      name="firstName"
                      required
                      autoComplete="off"
                    />
                    <div>
                      <small className="error_message">
                        {formik?.errors?.firstName && formik.errors.firstName}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name:</label>
                  <div className="input_error_container">
                    <input
                      id="lastName"
                      placeholder="Enter details"
                      type="text"
                      value={formik?.values?.lastName || ''}
                      onChange={onChangeHandler}
                      name="lastName"
                      required
                      autoComplete="off"
                    />
                    <div>
                      <small className="error_message">
                        {formik?.errors?.lastName && formik.errors.lastName}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Gender:</label>
                  <div className="input_error_container">
                    <div className="genderTab">
                      <div
                        role="button"
                        className={`genderTab_btn ${
                          formik.values?.gender == 'Male'
                          && 'genderTab_btn_selected'
                        }`}
                        onClick={() => onGenderHandler('Male')}
                      >
                        Male
                      </div>
                      <div
                        role="button"
                        className={`genderTab_btn ${
                          formik.values?.gender == 'Female'
                          && 'genderTab_btn_selected'
                        }`}
                        onClick={() => onGenderHandler('Female')}
                      >
                        Female
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Position:</label>
                  <div className="input_error_container">
                    <select
                      required
                      name="position"
                      id=""
                      onChange={onChangeHandler}
                      value={formik?.values?.position || ''}
                    >
                      <option value="" disabled>
                        Select Position
                      </option>
                      {mgtList?.map((elem, i) => (
                        <option value={elem.positionId} key={i}>
                          {elem.positionName}
                        </option>
                      ))}
                    </select>
                    <div>
                      <small className="error_message">
                        {formik?.errors?.position && formik?.errors.position}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <div className="input_error_container">
                    <input
                      id="email"
                      placeholder="Enter details"
                      type="text"
                      value={formik.values?.email || ''}
                      onChange={onChangeHandler}
                      name="email"
                      required
                      autoComplete="off"
                    />
                    <div>
                      <small className="error_message">
                        {formik?.errors?.email && formik?.errors.email}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Years in Role:</label>
                  <div className="input_error_container">
                    <div className="genderTab">
                      <div
                        role="button"
                        className={`genderTab_btn ${
                          formik.values?.noOfYearsInRole == '0-2yrs'
                          && 'genderTab_btn_selected'
                        }`}
                        onClick={() => onYearInRoleHandler('0-2yrs')}
                      >
                        0-2yrs
                      </div>
                      <div
                        role="button"
                        className={`genderTab_btn ${
                          formik.values?.noOfYearsInRole == '3-5yrs'
                          && 'genderTab_btn_selected'
                        }`}
                        onClick={() => onYearInRoleHandler('3-5yrs')}
                      >
                        3-5yrs
                      </div>
                      <div
                        role="button"
                        className={`genderTab_btn ${
                          formik.values?.noOfYearsInRole == '6-8yrs'
                          && 'genderTab_btn_selected'
                        }`}
                        onClick={() => onYearInRoleHandler('6-8yrs')}
                      >
                        6-8yrs
                      </div>
                      <div
                        role="button"
                        className={`genderTab_btn ${
                          formik.values?.noOfYearsInRole == '>10yrs'
                          && 'genderTab_btn_selected'
                        }`}
                        onClick={() => onYearInRoleHandler('>10yrs')}
                      >
                        {'>10yrs'}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="bvn">Qualification:</label>
                  <div className="input_error_container">
                    <input
                      id="bvn"
                      placeholder="e.g. BSc. Economics"
                      type="text"
                      value={formik?.values?.qualification || ''}
                      onChange={onChangeHandler}
                      name="qualification"
                      required
                      autoComplete="off"
                    />
                    <div>
                      <small className="error_message">
                        {formik?.errors?.qualification
                          && formik?.errors.qualification}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="bvn">BVN:</label>
                  <div className="input_error_container">
                    <input
                      id="bvn"
                      maxLength="11"
                      minLength="11"
                      placeholder="11 Digits"
                      type="text"
                      value={formik?.values?.bvn || ''}
                      onChange={onChangeHandler}
                      name="bvn"
                      required
                      autoComplete="off"
                    />
                    <div>
                      <small className="error_message">
                        {formik?.errors?.bvn && formik?.errors.bvn}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="phone">Phone No:</label>
                  <div className="input_error_container">
                    <input
                      id="phone"
                      maxLength="11"
                      minLength="11"
                      placeholder="08012341234"
                      type="text"
                      value={formik?.values?.phone || ''}
                      onChange={onChangeHandler}
                      name="phone"
                      required
                      autoComplete="off"
                    />
                    <div>
                      <small className="error_message">
                        {formik?.errors?.phone && formik?.errors.phone}
                      </small>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          <div className="add_tab">
            <div
              role="button"
              onClick={() => addTab(formik)}
              className={`flex ${formik.dirty && 'disable_div'}`}
            >
              <FiPlusCircle size={20} />
              <p>Add Another Principal Officer</p>
            </div>
            {state.tab.length > 1 && (
              <div role="button" className="flex" onClick={removeTab}>
                <FiMinusCircle size={20} />
                <p>Remove Principal Officer</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="orr_button_section">
        <div />
        <NextButton type="submit" disabled={formik.dirty} />
      </div>
    </Styled>
  );
}
const Styled = styled.form`
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    height: 100%;
    min-height: 58rem;
    margin-bottom: 2rem;
    background-color: #fff;
  }
  .disable_div {
    pointer-events: none;
  }
  .flex {
    display: flex;
    cursor: pointer;
    p {
      font-size: 1.4rem;
    }
  }
  .orr_ques_container_wrapper {
    padding: 2rem 4rem;
  }
  .title {
    padding: 0 5rem;
    padding-bottom: 1rem;
    text-align: center;
    @media (max-width: 128rem) {
      padding: 0 0rem;
      padding-bottom: 1rem;
    }
  }
  .orr_button_section {
    display: flex;
    justify-content: space-between;
  }
  .tab_form {
  }
  .tab_form_nav {
    display: flex;
  }
  .tab_form_nav_item {
    background-color: #e5e5e5;
    color: #808080;
    width: 5rem;
    padding: 0.5rem 0;
    text-align: center;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 1.4rem;
  }
  .tab_form_nav_item-active {
    background-color: #00989c;
    color: #fff;
  }
  .tab_form_main {
    border: thin solid #00989c;
    padding: 1rem 0;
    border-radius: 5px;
    border-top-left-radius: 0;
  }
  .form-group {
    display: flex;
    margin: 1rem 0rem;
    label {
      width: 20%;
      text-align: right;
      margin-right: 1rem;
      color: #004c4e;
      align-self: center;
      @media (max-width: 128rem) {
        font-size: 1.4rem;
      }
    }
    .input_error_container {
      width: 70%;
    }
    .error_message {
      color: red;
      font-size: 1.2rem;
    }
    input,
    select {
      border: thin solid #e5e5e5;
      border-radius: 5px;
      padding: 0.8rem;
    }
    input,
    select,
    .genderTab {
      width: 100%;
    }
    .genderTab {
      display: flex;
      .genderTab_btn {
        padding: 6px 2rem;
        border: thin solid #e5e5e5;
        margin-right: 1rem;
        border-radius: 5px;
        color: #505050;
        cursor: pointer;
        font-size: 1.4rem;
        @media (max-width: 128rem) {
          padding: 0.6rem 1rem;
        }
      }
      .genderTab_btn_selected {
        background: #fff;
        border: 1px solid #00989c;
        color: #000;
      }
    }
  }
  .add_tab {
    display: flex;
    justify-content: space-around;
    text-align: center;
    color: #004c4e;
    font-size: 1.4rem;
    margin-top: 1rem;
  }
  .warning_message {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.55);
    background-color: #fff;
    border-color: #ffeeba;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 1rem;
    text-align: justify;
    font-weight: 500;
    p {
      font-size: 1.3rem;
      padding-left: 1rem;
    }
  }
`;
