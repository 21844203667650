import { forwardRef } from 'react';
import styled from 'styled-components';

const TextInput = forwardRef((props, ref) => (
  <Styled
    onChange={props.onChange}
    ref={ref}
    type={props?.type ? props?.type : 'text'}
    placeholder={props.placeholder}
    id={props?.id}
    name={props.name}
    disabled={props.disabled}
    value={
      props.formik === '' || !props.formik
        ? props.value
        : props.formik !== ''
          ? props.formik.values[props.name]
          : ''
    }
  />
));

const Styled = styled.input`
  padding: 1rem;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid grey !important;
  margin-top: 1rem;
  outline: none !important;
  :focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export default TextInput;
