import React, { useState } from 'react';
import styled from 'styled-components';
import authApis from 'apis/user';
import { toast } from 'react-toastify';
import Spinner from 'components/OverLays/OverLaySpinner';
import Button from 'components/Buttons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import ErrorHandler from 'helpers/errorHandler';
import { useNavigate } from 'react-router-dom';
import * as AuthActions from 'store/actions/auth';
import CustomInput from '../FormikInputs/Input';
import Layout from '../Layouts/Settings';

export default function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const { auth } = useSelector((state) => state);
  const validationSchema = yup.object({
    currentPassword: yup.string().trim().min(8).required('Required'),
    newPassword: yup
      .string()
      .required('Required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .test(
        'passwords-match',
        'Passwords should not match current password',
        function (value) {
          return this.parent.currentPassword !== value;
        }
      ),
    confirmPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  });
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      const payLoad = { ...values, passportId: auth.user.passportId };
      try {
        setLoader(true);
        const response = await authApis.userChangePassword(payLoad);
        if (response.data.status) {
          actions.resetForm();
          toast.info('Password changed successfully');
          navigate('/auth/login', { replace: true });
          dispatch(AuthActions.AuthResetUser());
        } else {
          toast.error(response.data.data);
        }
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    },
  });
  return (
    <Layout activeBar="settings">
      <Styled>
        {isLoading ? <Spinner /> : ''}
        <div className="profile_wrapper">
          <main className="profile">
            <h3>Change Password</h3>
            <form className="form-wrapper" onSubmit={formik.handleSubmit}>
              <CustomInput
                name="currentPassword"
                label="Current Password"
                type="password"
                required
                formik={formik}
              />
              <CustomInput
                name="newPassword"
                label="New Password"
                type="password"
                required
                formik={formik}
              />
              <CustomInput
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                required
                formik={formik}
              />
              <div className="update_btn-container">
                <Button title="Update" mode="1" type="submit" />
              </div>
            </form>
          </main>
        </div>
      </Styled>
    </Layout>
  );
}
const Styled = styled.div`
  width: 100%;
  .profile_wrapper {
    max-width: 60rem;
    background: #fff;
    margin: 2rem;
    border: 4px solid #d1e8e2;
    border-radius: 15px;
    padding: 1rem 0;
  }
  .profile {
    border-radius: 1rem;
    padding: 2rem 4rem;
    h3 {
      font-weight: 600;
      color: #004c4e;
    }
  }
  .form-wrapper {
    padding: 2rem 0;
  }
  .pwd-input {
    width: 50%;
  }
  .update_btn-container {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }
`;
