export const SAVE_BVN_SIGNUP_FORM = 'SAVE_BVN_SIGNUP_FORM';
export const SAVE_ACCOUNT_SIGNUP_FORM = 'SAVE_ACCOUNT_SIGNUP_FORM';
export const UPDATE_ACCOUNT_SIGNUP_FORM = 'UPDATE_ACCOUNT_SIGNUP_FORM';
export const SAVE_TIN_SIGNUP_FORM = 'SAVE_TIN_SIGNUP_FORM';
export const SAVE_ABOUT_YOU_SIGNUP_FORM = 'SAVE_ABOUT_YOU_SIGNUP_FORM';
export const SAVE_BUSINESS_SIGNUP_FORM = 'SAVE_BUSINESS_SIGNUP_FORM';
export const SAVE_LOAN_SIGNUP_FORM = 'SAVE_LOAN_SIGNUP_FORM';
export const SAVE_CUSTOMER_TYPE_SIGNUP_FORM = 'SAVE_CUSTOMER_TYPE_SIGNUP_FORM';
export const SAVE_ACTIVE_PAGE_SIGNUP_FORM = 'SAVE_ACTIVE_PAGE_SIGNUP_FORM';
export const SAVE_FINAL_SIGNUP_FORM = 'SAVE_FINAL_SIGNUP_FORM';
export const RESET_SIGNUP_FORM = 'RESET_SIGNUP_FORM';
