import {
  ADD_FACILITY_QUESTIONS,
  UPDATE_FACILITY_DETAIL_DURATION,
  UPDATE_FACILITY_DETAIL_AMOUNT,
  UPDATE_FACILITY_SUB_PURPOSE_RESPONSE,
  UPDATE_FRR_QUESTIONS,
  EMPTY_FRR_QUESTIONS
} from '../../action-types/sub-purpose-questions';

const initialState = {

  facilityPurposeQuestions: [
    {
      frrQuestion: 'How much do you need?',
      id: null,
      questionType: 'number',
      response: 'text',
      options: [],
      questionClass: 'purpose'
    },
    {
      frrQuestion: 'How long do you need this plan for?',
      id: null,
      questionType: 'dropdown',
      response: 'number',
      options: {
        year: [
          '0 year', '1 year', '2 years', '3 years', '4 years', '5 years', '6 years', '7 years', '8 years', '9 years', '10 years'
        ],
        month: [
          '0 month', '1 month', '2 months', '3 months', '4 months', '5 months', '6 months', '7 months', '8 months', '9 months', '10 months'
        ],
        day: [
          '0 day', '1 day', '2 days', '3 days', '4 days', '5 days', '6 days', '7 days', '8 days', '9 days', '10 days'
        ]
      },
      questionClass: 'purpose'
    },
    {
      frrQuestion: 'What is your preferred repayment frequency?',
      id: null,
      questionType: 'dropdown',
      response: 'text',
      options: ['Monthly', 'Quarterly', 'Termly', 'Bi-annually', 'Annually'],
      questionClass: 'purpose'
    },
    {
      frrQuestion: 'Why do you need the facility?',
      id: null,
      questionType: 'dropdown',
      options: [],
      questionClass: 'purpose'
    },
    {
      frrQuestion: 'Kindly select facility type.',
      id: null,
      questionType: 'dropdown',
      options: [],
      questionClass: 'purpose'
    }
  ],
  facilitySubPurposefrrQuestions: [

  ],
  facilityGeneralfrrQuestions: [
    {
      frrQuestion: 'Justification for the facility.',
      id: null,
      questionType: 'text',
      options: [],
      questionClass: 'general'
    },
    {
      frrQuestion: 'Which of these best describes this facility in the order of seniority?',
      id: null,
      questionType: 'single',
      options: ['Senior debt', 'Surbodinate debt'],
      questionClass: 'general'
    },
    {
      frrQuestion: 'What is the primary source of repayment for the facility?',
      id: null,
      questionType: 'dropdown',
      options: ['Sales proceeds', 'Contract proceeds', 'Operational proceeds', 'Others'],
      questionClass: 'general'
    },
    {
      frrQuestion: 'Provide details of a primary source of payment.',
      id: null,
      questionType: 'text',
      options: [],
      questionClass: 'general'
    },
    {
      frrQuestion: 'Is there an alternative source of repayment?',
      id: null,
      questionType: 'single',
      options: ['YES', 'NO'],
      questionClass: 'general'
    },
    {
      frrQuestion: 'Provide details of the alternative source of payment.',
      id: null,
      questionType: 'dropdown',
      options: ['Other business proceeds', 'Contract proceeds', 'Expected inflows from known counterparties'],
      questionClass: 'general'
    },
    {
      frrQuestion: 'Which of these best describes the structure of the facility request?',
      id: null,
      questionType: 'single',
      options: ['One-off facility', 'Credit line'],
      questionClass: 'general'
    },
    {
      frrQuestion: 'What is the preferred disbursement mode?',
      id: null,
      questionType: 'single',
      options: ['Lumpsum/Bullet', 'Tranches'],
      questionClass: 'general'
    },
    {
      frrQuestion: 'Kindly indicate details of tranches.',
      id: null,
      questionType: 'text',
      options: [],
      questionClass: 'general'
    },
    {
      frrQuestion: 'Kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients.',
      id: null,
      questionType: 'single',
      options: ['Single', 'Multiple'],
      questionClass: 'general'
    },
    {
      frrQuestion: 'Indicate the name of the counterparty.',
      id: null,
      questionType: 'text',
      options: [],
      questionClass: 'general'
    },
    {
      frrQuestion: 'Select multiple counterparty option.',
      id: null,
      questionType: 'single',
      options: ['2-5 Clients', '6 Clients & above'],
      questionClass: 'general'
    },
    {
      frrQuestion: 'List names of counterparties.',
      id: null,
      questionType: 'text',
      options: [],
      questionClass: 'general'
    }
  ],
  facilityData: {
    purpose: '',
    subPurpose: '',
    amount: '',
    facilityType: '',
    subPurposeResponses: [],
    facilityDuration: null,
    equityContribution: null,
    facilitySeniority: '',
    repaymentSource: '',
    thereIsAlternativeSourceOfRepayment: true,
    alternativeSourceOfRepayment: '',
    facilityRequestStructure: '',
    disbursementMode: '',
    disbursementModeTranches: {
      numberOfTranches: null,
      disbursementFrequency: '',
      expectedDate: '',
      amount: null
    },
    repaymentCashFlow: {
      isMultipleClient: false,
      counterpartyName: []
    },
    proposedInterestRate: ''
  }
};

const facilitySubPurposeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FACILITY_QUESTIONS:
      return {
        ...state, facilitySubPurposefrrQuestions: action.payload
      };
    case UPDATE_FACILITY_DETAIL_AMOUNT:
      return {
        ...state,
        facilityData: {
          ...state.facilityData, amount: action.payload
        }
      };
    case UPDATE_FACILITY_DETAIL_DURATION:
      return {
        ...state,
        facilityData: {
          ...state.facilityData, facilityDuration: action.payload
        }
      };
    case UPDATE_FACILITY_SUB_PURPOSE_RESPONSE:
      return {
        ...state,
        facilityData: {
          subPurposeResponses: [
            action.payload
          ]
        }
      };
    case UPDATE_FRR_QUESTIONS:
      return {
        ...state,
        facilitySubPurposefrrQuestions: [
          ...action.payload
        ]
      };
    case EMPTY_FRR_QUESTIONS:
      return {
        ...state, facilitySubPurposefrrQuestions: []
      };
    default:
      return state;
  }
};

export default facilitySubPurposeReducer;
