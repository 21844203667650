import React, { useState, useEffect } from 'react';
import Button, { NextButton, PrevButton } from 'components/Buttons';
import { useSelector } from 'react-redux';
import { orrApis, businessApis } from 'apis';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ErrorHandler from 'helpers/errorHandler';
import { financialAnswerModel } from 'constants/orrFinancials';
import useSubSectorImage from 'hooks/useSubSectorImage';
import Tab from './Tab';
import FinancialInterface from './Financials';
import Question from './BusinessMgt/Question';
import { validateAnswer } from '../../../helpers/questionsAnswers';

export default function Controller(props) {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const [subSectorImage, isImageLoading] = useSubSectorImage();
  const [star, setStar] = useState(0);
  const [selected, setSelected] = useState({});
  const [input, setInput] = useState('');
  const [selectedMultiple, setSelectedMultiple] = useState([]);

  useEffect(() => {
    if (props?.questions?.length == 0) {
      navigate('/app/orr/principals', { replace: true });
    }
    return () => {};
  }, []);

  const prevHandler = () => {
    if (props.currentQuestionIndex == 0) {

    } else if (props.currentQuestionIndex >= 1) {
      if (
        props.questions[props.currentQuestionIndex - 1].questionClass != 'S'
      ) {
        props.setCurrentQuestionIndex((prev) => (prev -= 1));
      } else {
        const getPrimaryQuestion = props.questions.filter(
          (elem) => elem.followupId
            == props.questions[props.currentQuestionIndex - 1].questionId
        );
        const triggerAnswer = getPrimaryQuestion[0]?.selectedAnswer?.answer?.toUpperCase();
        if (
          getPrimaryQuestion.length > 0
          && triggerAnswer == getPrimaryQuestion[0].followUpTrigger.toUpperCase()
        ) {
          props.setCurrentQuestionIndex((prev) => (prev -= 1));
        } else {
          if (props.currentQuestionIndex - 2 <= 0) {
            props.setCurrentQuestionIndex((prev) => 0);
            return;
          }
          props.setCurrentQuestionIndex((prev) => (prev -= 2));
        }
      }
    } else {
      toast.error('Incomplete Field or Invalid Inputs');
    }
  };

  const nextHandler = () => {
    const quesLength = props?.questions?.length - 1;

    if (props.currentQuestionIndex == quesLength && validateAnswer(props.questions[props.currentQuestionIndex])) {
      return nextSectionHandler();
    } if (props.currentQuestionIndex < quesLength && validateAnswer(props.questions[props.currentQuestionIndex])) {
      if (
        props.questions[props.currentQuestionIndex + 1].questionClass != 'S'
      ) {
        props.setCurrentQuestionIndex((prev) => (prev += 1));
        setStar(0);
        setSelected({});
        setInput('');
        return setSelectedMultiple([]);
      }
      const getPrimaryQuestion = props.questions.filter(
        (elem) => elem.followupId
            == props.questions[props.currentQuestionIndex + 1].questionId
      );
      const triggerAnswer = getPrimaryQuestion[0]?.selectedAnswer?.answer?.toUpperCase();
      if (
        getPrimaryQuestion.length > 0
          && triggerAnswer == getPrimaryQuestion[0].followUpTrigger.toUpperCase()
      ) {
        props.setCurrentQuestionIndex((prev) => (prev += 1));
        setStar(0);
        setSelected({});
        setInput('');
        return setSelectedMultiple([]);
      }
      if (props.currentQuestionIndex + 2 > quesLength) {
        return nextSectionHandler();
      }
      // last questions
      props.setCurrentQuestionIndex((prev) => (prev += 2));
      setStar(0);
      setSelected({});
      setInput('');
      return setSelectedMultiple([]);
    }
    toast.error('Incomplete Field or Invalid Inputs');
  };

  const updateBusinessStatus = async () => {
    const statusObj = {
      Management: 'orr-management',
      Financials: 'orr-financial',
      Business: 'orr-business',
    };
    await businessApis.updateBusinessStatus({
      businessId: auth?.user?.businessId,
      profileStatus: statusObj[props.activeSection],
      isEditable: 'false',
    });
  };

  const nextSectionHandler = async () => {
    props.setLoader(true);
    try {
      const payLoad = formatAnswerForSaving();
      const res = await orrApis.updateOrrResponse(payLoad);
      await updateBusinessStatus();
      if (res.status == 200) {
        toast.info('Section Saved');
        props.setCurrentQuestionIndex(0);
        props.sectionHandler();
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      props.setLoader(false);
    }
  };

  const answerFormatter = (elem) => {
    const selectedAnswerInstance = elem.selectedAnswer instanceof Array;
    if (elem.selectedAnswer && !selectedAnswerInstance) {
      return {
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: String(elem.selectedAnswer.answerId),
        unWeightedAnswer: 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.selectedAnswer && selectedAnswerInstance) {
      const getAnswersId = elem.selectedAnswer.map((elem) => elem.answerId);
      return {
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: getAnswersId.join(),
        unWeightedAnswer: 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.questionType == 'File') {
      return {
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: 'string',
        hasDocument: true,
        documentUrl: elem.inputtedAnswer,
      };
    } if (elem.questionType == 'list') {
      return {
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: elem.inputtedAnswer.join() || 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.questionType == 'contact-list') {
      return {
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: JSON.stringify(elem.inputtedAnswer) || 'string',
        hasDocument: false,
        documentUrl: '',
      };
    }
    return {
      businessId: auth?.user?.businessId,
      questionId: elem.questionId,
      answerId: '0',
      unWeightedAnswer: String(elem.inputtedAnswer) || 'string',
      hasDocument: false,
      documentUrl: '',
    };
  };

  const formatAnswerForSaving = (exit = null) => {
    const questionList = [...props.questions];
    if (exit) {
      questionList.length = props.currentQuestionIndex;
    }
    const formattedAnswers = [];
    for (let i = 0; i < questionList.length; i++) {
      const elem = questionList[i];
      if (elem.questionClass == 'P') {
        formattedAnswers.push(answerFormatter(elem));
      } else if (elem.questionClass == 'S') {
        if (
          (elem.inputtedAnswer != 'undefined'
            && elem.inputtedAnswer?.length > 1)
          || (elem.selectedAnswer && elem.selectedAnswer != 'undefined')
        ) {
          formattedAnswers.push(answerFormatter(elem));
        }
      }
    }
    return formattedAnswers;
  };

  const saveAndExitHandler = async () => {
    const verifyExit = window.confirm('Are you sure you want to continue later');
    if (verifyExit) {
      props.setLoader(true);
      try {
        if (props.activeSection != 'Financials') {
          const payLoad = formatAnswerForSaving('exit');
          const res = await orrApis.updateOrrResponse(payLoad);
          if (res.status == 200) toast.info('Saved');
        } else {
          saveFinancialHandler();
        }
        navigate('/app/orr/dashboard', { replace: true });
      } catch (error) {
        ErrorHandler(error);
      } finally {
        props.setLoader(false);
      }
    }
  };

  const [financialAnswers, setFinancialAnswers] = useState(financialAnswerModel);
  const covertToMilOrThousand = (financialNumbers) => {
    const financialAnswersCopy = { ...financialNumbers };
    for (const i in financialAnswersCopy) {
      if (
        typeof financialAnswersCopy[i] === 'number'
        && financialAnswersCopy.valueScale == 'millions'
        && financialAnswersCopy[i] != 0
        && i != 'id'
      ) {
        financialAnswersCopy[i] *= 1000000;
        financialAnswersCopy[i] = parseFloat(
          financialAnswersCopy[i].toFixed(2)
        );
      } else if (
        typeof financialAnswersCopy[i] === 'number'
        && financialAnswersCopy.valueScale == 'thousands'
        && financialAnswersCopy[i] != 0
        && i != 'id'
      ) {
        financialAnswersCopy[i] *= 1000;
        financialAnswersCopy[i] = parseFloat(
          financialAnswersCopy[i].toFixed(2)
        );
      }
    }
    return financialAnswersCopy;
  };

  const saveFinancialHandler = async () => {
    financialAnswers.businessId = auth?.user?.businessId;
    try {
      props.setLoader(true);
      const covertToMillionOrThousand = covertToMilOrThousand(financialAnswers);
      if (financialAnswers.id) {
        await orrApis.putOrrFinancialAnswers(covertToMillionOrThousand);
      } else {
        await orrApis.postOrrFinancialAnswers(covertToMillionOrThousand);
      }
      props.setCurrentQuestionIndex((prev) => prev + 1);
    } catch (error) {
      ErrorHandler(error);
    } finally {
      props.setLoader(false);
    }
  };

  return (
    <Styled className="section_ques">
      <main className="orr_ques_main">
        <Tab
          tabHandler={props.tabHandler}
          history={props.history}
          activeSection={props.activeSection}
        />
        {props?.allQuestion?.Management
          && props?.activeSection != 'Financials' && (
          <>
            <div className="orr_ques_container">
              <Question
                index={props.currentQuestionIndex}
                question={props?.questions[props.currentQuestionIndex]}
                questionLength={props?.questions?.length}
                updateQuestionsPoolHandler={props.updateQuestionsPoolHandler}
                star={star}
                setStar={setStar}
                selected={selected}
                setSelected={setSelected}
                input={input}
                setInput={setInput}
                selectedMultiple={selectedMultiple}
                setSelectedMultiple={setSelectedMultiple}
              />
            </div>
            <div className="orr_button_section">
              {props.currentQuestionIndex ? (
                <PrevButton onClick={prevHandler} />
              ) : props.activeSection == 'Management' ? (
                <PrevButton
                  title="Back To Principals"
                  onClick={() => navigate('/app/orr/principals')}
                />
              ) : (
                <div />
              )}
              <NextButton
                onClick={nextHandler}
                title={
                  props.currentQuestionIndex == props?.questions?.length - 1
                    ? 'Save & Proceed'
                    : ''
                }
              />
            </div>
          </>
        )}
        {props.activeSection == 'Financials' && (
          <FinancialInterface
            queryParams={props.queryParams}
            financialAnswers={financialAnswers}
            setFinancialAnswers={setFinancialAnswers}
            saveFinancialHandler={saveFinancialHandler}
            setCurrentQuestionIndex={props.setCurrentQuestionIndex}
            currentQuestionIndex={props.currentQuestionIndex}
            businessId={auth?.user?.businessId}
            setLoader={props.setLoader}
          />
        )}
      </main>
      <aside className="orr_ques_aside">
        <div className="img-wrapper">
          <img
            src={subSectorImage.length > 2 ? subSectorImage : undefined}
            alt=""
          />
        </div>
        {props.queryParams?.get('status') == 'review' ? undefined : (
          <>
            {(props.activeSection == 'Financials'
              && props.currentQuestionIndex == 0)
            || (props.activeSection == 'Financials'
              && props.currentQuestionIndex == 2) ? undefined : (
                <Button
                  mode="5"
                  title="Save & Exit"
                  onClick={saveAndExitHandler}
                />
              )}
          </>
        )}
      </aside>
    </Styled>
  );
}

const Styled = styled.div`
  display: flex;
  justify-content: space-between;

  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    min-height: 45rem;
    background-color: #fff;
    display: flex;
  }

  .orr_button_section {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
  }
  .orr_ques_main {
    width: 65%;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  .orr_ques_aside {
    width: 28%;
    text-align: center;
    padding-top: 8rem;
    @media (max-width: 1200px) {
      width: 30%;
    }
    @media (max-width: 85rem) {
      display: none;
    }
    button {
      background: #004c4e;
      color: #fff;
    }
    .img-wrapper {
      height: 50rem;
      overflow: hidden;
      margin-bottom: 3.5rem;
      border-radius: 1rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
