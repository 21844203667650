import React from 'react';
import styled from 'styled-components';
import Header from '../Headers/General';
import Tabs from './Tabs';

export default function Settings(props) {
  return (
    <Styled>
      <Header />
      <section className="box">
        <aside className="aside">
          <Tabs activeBar={props.activeBar} />
        </aside>
        <main className="main">{props.children}</main>
      </section>
    </Styled>
  );
}
const Styled = styled.div`
  background: #ebede3;
  width: 100%;

  .box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    border-radius: 5px;
  }
  .main {
    width: calc(100% - 25rem);
    padding: 0rem;
  }
  .aside {
    min-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 22rem;
    padding: 2rem 1rem;
    /* border-right: 2px solid #e5e5e5; */
    background: rgba(245, 248, 240, 0.95);
  }

  .active-tab {
    background: #d1e8e2;
    border-radius: 4px;
    color: #004c4e;
  }
`;
