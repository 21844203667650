import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/General';
import styled from 'styled-components';
import Button from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import useOrrQuesAns from 'hooks/useOrrQuesAns';
import Spinner from 'components/OverLays/OverLaySpinner';
import orrApis from 'apis/orr';
import { useSelector } from 'react-redux';
import { ReactComponent as RollerSvg } from 'assets/svgs/loader-roller.svg';
import FinancialComponent from '../AnswersReview/Financial';
import { AnswerContainer } from '../AnswersReview/index';

export default function Review() {
  const navigate = useNavigate();
  const [current, setCurrent] = useState('Management');
  const [
    businessMgtQuestionsPool,
    isLoading,
    setLoader,
    setBusinessMgtQuestionsPool,
  ] = useOrrQuesAns();
  const { business } = useSelector((state) => state);
  const [finAnswers, setFinAnswers] = useState({});
  const [auditorList, setAuditorList] = useState([]);
  const [borrowList, setBorrowList] = useState([]);
  const businessId = business?.businessProfiles[0]?.businessId;
  useEffect(() => {
    let isMounted = true;
    const getBorrowingList = async (e) => {
      try {
        const res = await orrApis.getBrowsingHistory(businessId);
        isMounted && setBorrowList((prev) => res.data.data);
      } catch (error) {}
    };
    getBorrowingList();
    return () => {
      isMounted = false;
    };
  }, [businessId]);
  useEffect(() => {
    let isMounted = true;
    const getAnswer = async () => {
      try {
        const res = await orrApis.getOrrFinancialAnswers(businessId);
        if (res.status == 200 && res.data.data.length != 0) {
          isMounted && setFinAnswers(res.data.data[0]);
        }
      } catch (error) {}
    };
    getAnswer();
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    let isMounted = true;
    const getAuditorList = async () => {
      try {
        const res = await orrApis.getAuditorsList();
        if (res.data.status) {
          const auditorsList = res.data.data;
          if (auditorsList.length > 0) {
            isMounted && setAuditorList(auditorsList);
          }
        }
      } catch (error) {}
    };
    getAuditorList();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Styled>
      {isLoading ? <Spinner /> : ''}
      <Header />
      <div className="box">
        <p className="review_title">Review</p>
        <section className="tab_form">
          <div className="tab_form_nav">
            <div
              className={`tab_form_nav_item ${
                current == 'Management' && 'tab_form_nav_item-active'
              }`}
              onClick={() => setCurrent('Management')}
            >
              Management
            </div>
            <div
              className={`tab_form_nav_item ${
                current == 'Business' && 'tab_form_nav_item-active'
              }`}
              onClick={() => setCurrent('Business')}
            >
              Business
            </div>
            <div
              className={`tab_form_nav_item ${
                current == 'Financials' && 'tab_form_nav_item-active'
              }`}
              onClick={() => setCurrent('Financials')}
            >
              Financials
            </div>
          </div>
          <div className="tab_form_main">
            {isLoading ? (
              <div className="loader-container">
                <RollerSvg height="8rem" width="8rem" color="red" />
              </div>
            ) : (
              <>
                {businessMgtQuestionsPool[current]
                  ?.filter(
                    (elem) => (elem.questionType !== 'File'
                        && elem.inputtedAnswer != 'undefined'
                        && elem.selectedAnswer != 'undefined'
                        && elem.inputtedAnswer != undefined)
                      || elem.selectedAnswer != undefined
                  )
                  ?.map((elem, i) => (
                    <div key={i} className="tab_form_main-item">
                      <p className="review_question_title">
                        <span className="button_circle" />
                        Question
                      </p>
                      <p className="review_question">{elem.question}</p>
                      <p className="review_answer_title">
                        <span className="button_circle_green" />
                        Answer
                      </p>
                      <AnswerContainer questionProperties={elem} />
                    </div>
                  ))}
                {current == 'Financials' && (
                  <FinancialComponent
                    edit={false}
                    finAnswers={finAnswers}
                    auditorList={auditorList}
                    borrowList={borrowList}
                  />
                )}
              </>
            )}
            {/* if Financials Answers */}
          </div>
        </section>
        <div className="orr_button_section">
          <Button
            onClick={() => navigate('/app/orr/dashboard')}
            mode="1"
            title="Return Home"
          />
        </div>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  .box {
    margin: 0 auto;
    max-width: 774px;
    width: 100%;
    padding: 1rem;
  }
  .button_circle {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 5rem;
    background: #d9b08c;
    display: inline-block;
    margin-right: 0.5rem;
  }
  .button_circle_green {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 5rem;
    background: #00989c;
    display: inline-block;
    margin-right: 0.5rem;
  }
  .review_answer_title,
  .review_question_title {
    font-size: 1.4rem;
    font-weight: 500;
    color: #505050;
    padding-top: 0.5rem;
  }
  .review_answer,
  .review_question {
    font-size: 1.4rem;
    color: #808080;
    padding-left: 1.2rem;
  }

  .review_title {
    font-weight: 500;
    color: #004c4e;
    margin: 4rem 0;
    font-size: 2rem;
  }
  .tab_form_nav {
    display: flex;
  }
  .tab_form_nav_item {
    background-color: #e5e5e5;
    color: #808080;
    margin-right: 0.2rem;
    padding: 0.5rem 1rem;
    text-align: center;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 1.4rem;
  }
  .tab_form_nav_item-active {
    background-color: #00989c;
    color: #fff;
  }
  .tab_form_main {
    border: thin solid #00989c;
    padding: 1rem 2rem;
    border-radius: 5px;
    border-top-left-radius: 0;
    height: 50rem;
    overflow-y: scroll;
    background: #fff;
  }
  .tab_form_main-item {
    padding: 1rem 0;
    border-bottom: 1px solid #e5e5e5;
  }
  .orr_button_section {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;
  }
  /* table */
  .styled-table-contact th,
  .styled-table-contact td {
    padding: 0.5rem;
    border: thin solid #ddd;
  }
  .styled-table-contact td {
    font-weight: 400;
    font-size: 1.3rem;
  }
  .styled-table-contact th {
    background-color: #f2f2f2;
    font-size: 1.3rem;
    font-weight: 400;
  }
  .styled-table-contact {
    font-weight: 400;
    border-collapse: collapse;
    margin-left: 1.5rem;
    margin-top: 1rem;
  }
  .styled-table-contact tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
