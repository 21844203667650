import { apiWithToken, apiWithoutToken } from './axiosInterceptor';

const request = {
  userLogin: (data) => {
    const method = 'post';
    const url = '/api/User/Login';
    return apiWithoutToken({
      method,
      url,
      data,
    });
  },
  resetSession: (data) => {
    const method = 'post';
    const url = '/api/User/ResetSession';
    return apiWithoutToken({
      method,
      url,
      data
    });
  },
  // createUser: (data) => {
  //   const method = 'post';
  //   const url = '/api/User/Create';
  //   return apiWithoutToken({ method, url, data });
  // },
  userChangePassword: (data) => {
    const method = 'post';
    const url = '/api/User/ChangePassword';
    return apiWithoutToken({ method, url, data });
  },
  forgetPassword: (data) => {
    const method = 'get';
    const url = '/api/User/ForgetPassword';
    return apiWithoutToken({
      method,
      url,
      params: { username: data },
    });
  },
  confirmForgetPassword: (data) => {
    const method = 'post';
    const url = '/api/User/ConfirmForgetPassword';
    return apiWithoutToken({
      method,
      url,
      data,
    });
  },
  createNewPassword: (data) => {
    const method = 'post';
    const url = '/api/User/CreateNewPassword';
    return apiWithoutToken({
      method,
      url,
      data,
    });
  },
  postSupportMessage: (data) => {
    const method = 'post';
    const url = '/api/User/CreateSendUsMessage';
    return apiWithoutToken({
      method,
      url,
      data,
    });
  },
  userConfirmation: (params) => {
    const method = 'get';
    const url = '/api/User/UserConfirmation';
    return apiWithoutToken({
      method,
      url,
      params: { emailAddress: params.emailAddress, code: params.code },
    });
  },
  createUserBusiness: (data) => {
    const method = 'post';
    const url = '/api/User/CreateBusinessAndAccount';
    return apiWithoutToken({ method, url, data });
  },
  getAllNotifications: (businessId) => {
    const method = 'get';
    const url = '/api/User/GetNotificationByBusinessId';
    return apiWithToken({ method, url, params: { businessId } });
  },
  setNotificationAsRead: (data) => {
    const method = 'post';
    const url = '/api/User/SetNotificationAsRead';
    return apiWithToken({
      method,
      url,
      params: { isRead: true, id: data.id },
    });
  },
  createNotification: (data) => {
    const method = 'post';
    const url = '/api/User/CreateUserNotification';
    return apiWithToken({
      method,
      url,
      params: {
        businessId: data.businessId,
        message: data.message,
        subject: data.subject,
      },
    });
  },
  resendActivationMail: (data) => {
    const method = 'get';
    const url = `/api/User/ResendActivationMail`;
    return apiWithToken({
      method,
      url,
      params: {
        emailAddress: data
      },
    });
  },
};

export default request;
