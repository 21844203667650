import * as actionTypes from '../action-types/collateral';

const initialState = {
  checks: {
    state_classification: false,
    search_fee: false,
    valuation: false,
  },
  payLoad: {
    collateralId: 0,
    collateralTypeId: null,
    collateralSeniorityValue: null,
    collateralSeniorityName: null,
    lgaId: null,
    stateId: null,
    searchFeePropertyType: null,
    searchFeePayNow: null,
    isValuationExisting: null,
    carryOutNewValuation: null,
    pricingId: null,
    newValuationPayNow: null,
    knownValuerId: null,
    unknownValuerName: null,
    valuationDate: null,
    valuationEstimate: null,
    completedProcess: false,
  },
  //   completed: 'aboutYou',
};
export default function auth(state = initialState, { type, payLoad }) {
  switch (type) {
    case actionTypes.SET_COLLATERAL_CHECKS:
      return {
        ...state,
        checks: payLoad,
      };
    case actionTypes.UPDATE_COLLATERAL_PAYLOAD:
      return {
        ...state,
        payLoad: { ...state.payLoad, ...payLoad },
      };
    case actionTypes.CLEAR_COLLATERAL:
      return initialState;
    default:
      return state;
  }
}
