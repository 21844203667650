import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { NextButton, PrevButton } from 'components/Buttons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as SignUpActions from 'store/actions/signUpForm';
import { adminUserApis, businessApis } from 'apis';
import Spinner from 'components/OverLays/OverLaySpinner';
import styled from 'styled-components';
import ErrorHandler from 'helpers/errorHandler';
import CustomInput from '../../../FormikInputs/Input';

const formatAllSector = (data) => data.map((elem) => ({
  text: elem.sectorName,
  value: elem.sectorId,
}));

const formatAllSubSector = (data) => data.map((elem) => ({
  text: elem.subSectorName,
  value: elem.subSectorId,
}));
export default function NewCustomer() {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [allSector, setAllSector] = useState([]);
  const [allSubSector, setAllSubSector] = useState([]);
  const [displaySubSector, setDisplaySubSector] = useState(true);
  useEffect(() => {
    const getAllSector = async () => {
      setLoader(true);
      try {
        const res = await adminUserApis.getAllSectors();
        setAllSector(formatAllSector(res?.data?.data));
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    };
    getAllSector();
    return () => {};
  }, []);
  const getSubSectorHandler = async (sectorId) => {
    try {
      setDisplaySubSector(false);
      setLoader(true);
      // 12 is government
      const res = await adminUserApis.getAllSubSectorBySectorId(
        sectorId == 12 ? 11 : sectorId
      );
      if (res?.data?.data?.length == 0) {
        const res2 = await adminUserApis.getAllSubSectorBySectorId(11);
        setAllSubSector(formatAllSubSector(res2?.data?.data));
      } else {
        setAllSubSector(formatAllSubSector(res?.data?.data));
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoader(false);
    }
  };
  const { signUpForm } = useSelector((state) => state);
  const initialValues = {
    companyTin: signUpForm?.tinDetails?.tin,
    businessName: signUpForm?.tinDetails.taxPayerName,
    alternativeCompanyName: '',
    companyPrimaryAddress: signUpForm?.tinDetails?.address,
    companyPhoneNumber: '',
    companyEmail: '',
    companySector: '',
    companySubSector: '',
    rcNumber: signUpForm?.tinDetails?.rcNumber,
  };
  const validationSchema = yup.object({
    alternativeCompanyName: yup.string(),
    companyEmail: yup.string().email('Input Valid Email').required(),
    companyPhoneNumber: yup
      .string()
      .matches(/^\+?[0-9]+$/, 'Must be only Numbers')
      .required(),
    companySector: yup.string().required('Company Sector is required'),
    companySubSector: yup.string().required('Company SubSector is required'),
  });

  const formik = useFormik({
    initialValues: signUpForm.finalPage.companyEmail
      ? signUpForm.finalPage
      : initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      values.subSectorList = allSubSector;
      dispatch(SignUpActions.SaveFinal(values));
      dispatch(SignUpActions.SaveActivePage('createUser'));
    },
  });
  return (
    <Styled>
      {isLoading ? <Spinner /> : ''}
      <h2 className="formTitle">Sign Up</h2>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          name="companyTin"
          label="Company TIN"
          disabled
          type="text"
          required
          formik={formik}
        />
        <CustomInput
          name="businessName"
          label="Company Name"
          type="text"
          required
          disabled={!!signUpForm?.tinDetails?.taxPayerName}
          formik={formik}
        />
        <CustomInput
          asterisk="true"
          name="alternativeCompanyName"
          label="Alternative Brand Name"
          type="text"
          formik={formik}
        />
        <CustomInput
          name="companyEmail"
          label="Company Email Address"
          type="text"
          required
          formik={formik}
        />
        <CustomInput
          name="companyPhoneNumber"
          label="Company Phone Number"
          type="intPhone"
          required
          formik={formik}
        />
        <CustomInput
          name="companyPrimaryAddress"
          label="Company Primary Address"
          type="text"
          required
          disabled={!!signUpForm?.tinDetails?.address}
          formik={formik}
        />
        <CustomInput
          name="companySector"
          label="Sector"
          type="option"
          options={allSector}
          getSubSector={getSubSectorHandler}
          required
          formik={formik}
        />
        <CustomInput
          name="companySubSector"
          label="Sub Sector"
          type="option"
          options={
            (allSubSector[0]?.value && allSubSector)
            || signUpForm?.finalPage?.subSectorList
          }
          disabled={signUpForm?.finalPage ? false : displaySubSector}
          required
          formik={formik}
        />
        <div className="navButton">
          <PrevButton
            type="button"
            mode="3"
            title="Prev"
            onClick={() => dispatch(SignUpActions.SaveActivePage('tinOrAcc'))}
          />
          <NextButton type="submit" mode="1" title="Next" />
        </div>
      </form>
    </Styled>
  );
}
const Styled = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 5rem;
  padding-top: 5%;
  .navButton {
    display: flex;
    justify-content: space-between;
  }
  .formTitle {
    color: #004c4e;
    margin-bottom: 2rem;
    font-weight: 500;
  }
`;
