import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export default function Capital(props) {
  return (
    <Styled>
      <table className="styled-table">
        <thead>
          <tr>
            <th />
            <th>
              {new Date(props?.finAnswers?.financialEndYearDate).getFullYear()}
            </th>
            <th>
              {new Date(props?.finAnswers?.financialEndYearDate).getFullYear()
                - 1}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-60">Shareholder Capital (₦)</td>
            <td>
              <NumberFormat
                name="shareholderCapitalYear1"
                value={props?.finAnswers?.shareholderCapitalYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="shareholderCapitalYear2"
                value={props?.finAnswers?.shareholderCapitalYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Retained Earnings (₦)</td>
            <td>
              <NumberFormat
                name="retainedEarningsYear1"
                value={props?.finAnswers?.retainedEarningsYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="retainedEarningsYear2"
                value={props?.finAnswers?.retainedEarningsYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Styled>
  );
}
const Styled = styled.div`
  .calculateBold {
    font-weight: 600;
  }
  .styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 40rem;
    width: 100%;
    margin: 0;
  }
  .styled-table th,
  .styled-table td {
    font-size: 1.4rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
  }
  .styled-table th {
    background-color: #f2f2f2;
  }
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .styled-table input {
    min-width: 10rem;
    width: 100%;
    border: thin solid #ddd;
    height: 2.5rem;
    text-align: right;
  }
`;
