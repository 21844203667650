import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { verificationApis } from 'apis';
import { FeedBackMailSvg } from 'theme/icons';
import { FiInfo } from 'react-icons/fi';
import Button from 'components/Buttons';
import Spinner from 'components/OverLays/OverLaySpinner';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import Layout from '../../Layouts/Default';

export default function Feedback() {
  const navigate = useNavigate();
  const location = useLocation();
  const facilityId = location?.state?.facilityId;
  const { business } = useSelector((state) => state);
  const [documentFeedBacks, setDocumentFeedBacks] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { businessId } = business.businessProfiles[0];
  useEffect(() => {
    let mounted = true;
    const getFeedBack = async () => {
      try {
        setLoading(true);
        const res = await verificationApis.getFlaggedQuestions({
          businessId,
        });
        mounted && setDocumentFeedBacks(res.data.data);
      } catch (error) {
        mounted && setLoading(false);
      } finally {
        mounted && setLoading(false);
      }
    };
    getFeedBack();
    return () => {
      mounted = false;
    };
  }, [businessId]);

  return (
    <Styled>
      <Layout activeBar="validation-feedback">
        <div className="feedback_container">
          <div className="header">
            <h2>Validation Feedback</h2>
          </div>
          <div className="feedbacks">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {documentFeedBacks.length > 0 ? (
                  <>
                    {documentFeedBacks.map((elem) => (
                      <div
                        key={elem.orrResponseId}
                        className="feedbacks_item"
                      >
                        <div className="feedbacks_item-left">
                          <FeedBackMailSvg width="35px" height="35px" />
                        </div>
                        <div className="feedbacks_item-right">
                          <h3>{elem.question}</h3>
                          <div className="report">
                            <FiInfo color="#D45F36" />
                            <p>{elem.feedbackComment}</p>
                          </div>
                          <div className="button">
                            <p className="status">
                              Status:
                              {' '}
                              {elem.isTreated ? (
                                'Treated'
                              ) : (
                                <span className="pending">Pending</span>
                              )}
                            </p>
                            <Button
                              onClick={() => navigate('/app/orr/feedback/edit', {
                                state: {
                                  feedBack: elem,
                                  feedBackType: 'business_mgt',
                                },
                              })}
                              mode="1"
                              title="Treat"
                              style={{ padding: '.5rem 1rem' }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="empty_feedback">
                    <p>Empty Feedback</p>
                    <Button
                      mode="1"
                      onClick={() => navigate('/app/orr/dashboard')}
                      className="button"
                    >
                      Back
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Layout>
    </Styled>
  );
}
const Styled = styled.div`
  .feedback_container {
    margin: 2rem;
  }
  .header {
    margin-bottom: 1rem;
    color: #004c4e;
    h2 {
      font-size: 2rem;
      font-weight: 500;
    }
  }
  .feedbacks {
    background: #fff;
    border-radius: 5px;
    max-width: 80rem;
    /* width: max-content; */
  }
  .feedbacks_item {
    display: flex;
    padding: 1.5rem;
    border-bottom: 1px solid #eeeeee;
  }
  .feedbacks_item-left {
    margin-right: 1rem;
  }

  .feedbacks_item-right {
    width: 100%;
    h3 {
      font-weight: 500;
      font-size: 1.6rem;
      color: #004c4e;
    }
    p {
      color: #808080;
      font-size: 1.2rem;
      padding-left: 0.5rem;
    }
    .status {
      color: #004c4e;
    }
    .report {
      display: flex;
      margin: 0.5rem 0;
      align-items: center;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .button {
      display: flex;
      justify-content: space-between;
    }
    .pending {
      color: #d45f36;
    }
  }
  .empty_feedback {
    padding: 1rem;
    text-align: center;
    .button {
      margin-top: 4rem;
    }
  }
`;
