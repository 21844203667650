import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export default function AuditedDocument(props) {
  return (
    <Styled>
      <section className="orr_ques_container">
        <div className="question">
          <div className="table-wrapper">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Institution Name</th>
                  <th>Credit Limit</th>
                  <th>Outstanding Balance</th>
                  <th>Facility Type</th>
                  <th>Collateral Type</th>
                  <th>Collateral Value</th>
                </tr>
              </thead>
              <tbody>
                {props.borrowAnswer.map((elem, i) => (
                  <tr key={i}>
                    <td>
                      <input
                        type="text"
                        name="bankName"
                        value={elem.bankName}
                        readOnly
                      />
                    </td>
                    <td>
                      <NumberFormat
                        name="creditLimit"
                        value={elem.creditLimit}
                        readOnly
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        name="balance"
                        value={elem.balance}
                        readOnly
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="facilityType"
                        value={elem.facilityType}
                        readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="collateralType"
                        value={elem.collateralType}
                        readOnly
                      />
                    </td>
                    <td>
                      <NumberFormat
                        name="collateralValue"
                        value={elem.collateralValue}
                        readOnly
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </Styled>
  );
}
const Styled = styled.form`
  height: 100%;
  .orr_ques_container {
    height: 100%;
    margin-bottom: 2rem;
    background-color: #fff;
  }
  .question {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  .styled-table th,
  .styled-table td {
    font-size: 1.4rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
  }
  .styled-table td {
    font-weight: 300;
    font-size: 1.4rem;
  }
  .styled-table th {
    background-color: #f2f2f2;
    font-size: 1.2rem;
    width: 11.1rem;
    padding: 1rem 0.2rem;
  }
  .styled-table {
    font-weight: 400;
    border-collapse: collapse;
    min-width: 667px;
  }
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .styled-table input {
    min-width: 10rem;
    width: 100%;
    border: thin solid #ddd;
    height: 2.5rem;
    text-align: right;
    padding-right: 0.5rem;
    border-radius: 0rem;
    box-shadow: none;
  }
  .table-wrapper {
    width: 100%;
    overflow-y: scroll;
    max-height: 30rem;
  }
`;
