import {
  useEffect, useState, useLayoutEffect
} from 'react';
import styled from 'styled-components';
import Header from 'components/Headers/General';
import useSubSectorImage from 'hooks/useSubSectorImage';
import Button, { NextButton, PrevButton } from 'components/Buttons';
import { useSelector } from 'react-redux';
import frrApis from 'apis/frr';
import Spinner from 'components/OverLays/OverLaySpinner';
import errorHandler from 'helpers/errorHandler';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import NumberFormat from 'react-number-format';
import TextArea from '../text-area';
import TextInput from '../text-input';
import DropDownInput from '../drop-down-input';
import FRRQuestionBoard from '../question-board';

function FacilityFeedbackResponse() {
  const navigate = useNavigate();
  const location = useLocation();
  const [subSectorImage, isImageLoading] = useSubSectorImage();
  const feedbackResponses = location?.state?.responses;
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionBank, setQuestionBank] = useState([]);
  const [answerLoader, setAnswerLoader] = useState(false);
  const [lastQuestion, setLastQuestion] = useState(false);
  const [frrAnswers, setfrrAnswers] = useState([]);
  const [frrAnswerId, setFrrAnswerId] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [answeredResponses, setAnsweredResponses] = useState([]);
  const [unWeightedAnswer, setUnWeightedAnswer] = useState('');
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [checkboxChange, setCheckboxChange] = useState(false);

  const { facilityId } = feedbackResponses[0].f;

  const postResponses = async () => {
    setAnswerLoader(true);
    try {
      const response = await frrApis.submitFacilityFeedbackResponses(
        answeredResponses
      );
      navigate('/app/frr/facilityevaluation');
    } catch (error) {
      errorHandler(error);
    } finally {
      setAnswerLoader(false);
    }
  };

  useEffect(() => {
    setQuestionBank(feedbackResponses);
  }, []);

  const currentQuestion = questionBank[questionIndex];

  const businessId = useSelector(
    (state) => state?.business?.businessProfiles[0].businessId
  );

  useLayoutEffect(() => {
    const getfrrAnswers = async () => {
      setAnswerLoader(true);

      try {
        const response = await frrApis.getFacilityQuestionAnswers(
          currentQuestion?.q.frrQuestionId
        );
        setfrrAnswers(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setAnswerLoader(false);
      }
    };

    if (
      (currentQuestion?.q.questionType.toLowerCase().includes('single')
        || currentQuestion?.q.questionType.toLowerCase().includes('multiple')
        || currentQuestion?.q.questionType.toLowerCase().includes('dropdown'))
      && (currentQuestion?.q.questionClass.toLowerCase() === 'p'
        || currentQuestion?.q.questionClass.toLowerCase() === 's')
    ) {
      getfrrAnswers();
    }
  }, [questionIndex || currentQuestion]);

  useLayoutEffect(() => {
    // This is making sure the user's former answer is selected
    for (const res of answeredResponses) {
      if (
        res.frrResponseId == currentQuestion.f.frrResponseId
        && res.frrAnswerId != ''
      ) {
        setCurrentAnswer(Number(res.frrAnswerId));
      }
    }

    // This is checking whether the user is at the last question to show the save button to the user.
    if (questionBank.length === questionIndex + 1) {
      setLastQuestion(true);
    } else {
      setLastQuestion(false);
    }
  }, [currentQuestion || questionIndex]);

  const incrementHandler = () => {
    setQuestionIndex((questionIndex) => questionIndex + 1);
    setFrrAnswerId('');
    setUnWeightedAnswer('');
  };

  const decrementHandler = () => {
    setQuestionIndex((questionIndex) => questionIndex - 1);
  };

  useEffect(() => {
    const response = {
      frrResponseId: currentQuestion?.f.frrResponseId,
      businessId,
      frrQuestionId: currentQuestion?.q.frrQuestionId,
      facilityId: currentQuestion?.f.facilityId,
      frrAnswerId,
      unWeightedAnswer,
    };

    for (let i = 0; i < answeredResponses.length; i++) {
      if (
        answeredResponses[i]?.frrResponseId === response?.frrResponseId
        && (frrAnswerId != '' || unWeightedAnswer != '')
      ) {
        answeredResponses.splice(i, 1);
      }
    }

    if (frrAnswerId !== '' || unWeightedAnswer !== '') {
      setAnsweredResponses([...answeredResponses, response]);
    }
  }, [frrAnswerId || unWeightedAnswer || questionIndex || currentQuestion]);

  useEffect(() => {
    let answer = '';

    if (checkboxValues.length > 0) {
      for (let i = 0; i < checkboxValues.length; i++) {
        answer += `${checkboxValues[i]},`;
      }
    }

    setFrrAnswerId(answer);
  }, [checkboxChange]);

  useEffect(() => {
    if (frrAnswerId == '' && unWeightedAnswer == '') {
      setDisableNextButton(true);
    }

    if (frrAnswerId != '' || unWeightedAnswer != '') {
      setDisableNextButton(false);
    }
  }, [frrAnswerId || unWeightedAnswer]);

  useLayoutEffect(() => {
    for (const res of answeredResponses) {
      if (
        res.frrResponseId == currentQuestion?.f.frrResponseId
        && res.unWeightedAnswer
      ) {
        setUnWeightedAnswer(res.unWeightedAnswer);
      }

      if (
        res.frrResponseId == currentQuestion?.f.frrResponseId
        && res.frrAnswerId
      ) {
        setFrrAnswerId(String(res.frrAnswerId));
      }
    }
  }, [questionIndex]);

  return (
    <Styled>
      <Header />

      <div className="Main-holder">
        <div className="Questions-main">
          <div className="Questions-holder">
            <div className="Questions-holder-inner">
              <FRRQuestionBoard>
                <div className="Feedback-holder">
                  <p>
                    {' '}
                    <span style={{ fontWeight: '700' }}>Feedback:</span>
                    {' '}
                    { currentQuestion?.ass?.feedbackComment }
                  </p>
                </div>
                <div className="Question-div">
                  {currentQuestion?.q.frrQuestion && (
                    <p className="Question">{currentQuestion?.q.frrQuestion}</p>
                  )}
                </div>

                {/*  This is for sub purpose question types of single, meaning that they have buttons  */}
                {currentQuestion?.q.questionType
                  ?.toLowerCase()
                  .includes('single')
                && (currentQuestion?.q.questionClass.toLowerCase() === 'p'
                  || currentQuestion?.q.questionClass.toLowerCase() === 's') ? (
                    <div className="Multiple-select-buttons-holder">
                      {frrAnswers.length > 0
                      && frrAnswers.map((answer) => (
                        <button
                          className={
                            currentAnswer == answer.frrAnswerId
                              ? 'Multi-select-button-focus'
                              : 'Multi-select-button'
                          }
                          key={answer.frrAnswerId}
                          onClick={() => {
                            setFrrAnswerId(answer.frrAnswerId);
                            setCurrentAnswer(answer.frrAnswerId);
                          }}
                        >
                          {answer.frrAnswer.toUpperCase()}
                        </button>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}

                {
                  currentQuestion?.q.questionType.toLowerCase() === 'text'
                  && (currentQuestion?.q.questionClass.toLowerCase() === 'p'
                  || currentQuestion?.q.questionClass.toLowerCase() === 's') ? (
                    <div>
                        <div className="Counterparty-div">
                        <TextInput
                            onChange={(e) => {
                              if (
                                (currentQuestion?.q.questionClass.toLowerCase()
                              === 'p'
                              || currentQuestion?.q.questionClass.toLowerCase()
                                === 's')
                            && currentQuestion?.q.questionType.toLowerCase()
                              === 'text'
                              ) {
                                setUnWeightedAnswer(e.target.value);
                              }
                            }}
                            type="text"
                            name=""
                            value={unWeightedAnswer}
                          />
                      </div>
                      </div>
                    ) : (
                      ''
                    )
                }

                {currentQuestion?.q.questionType
                  ?.toLowerCase()
                  .includes('number') ? (
                    <div style={{ width: '100%' }}>
                      <TextInput
                        onChange={(e) => {
                          setUnWeightedAnswer(e.target.value);
                        }}
                        type="number"
                        value={unWeightedAnswer}
                      />
                    </div>
                  ) : (
                    ''
                  )}

                {currentQuestion?.q.questionType
                  .toLowerCase()
                  .includes('long-text')
                && (currentQuestion?.q.questionClass.toLowerCase() === 'p'
                  || currentQuestion?.q.questionClass.toLowerCase() === 's') ? (
                    <TextArea
                      onChange={(e) => {
                        setUnWeightedAnswer(e.target.value);
                      }}
                      value={unWeightedAnswer}
                    />
                  ) : (
                    ''
                  )}

                {currentQuestion?.q.questionType
                  .toLowerCase()
                  .includes('amount')
                && (currentQuestion?.q.questionClass.toLowerCase() === 'p'
                  || currentQuestion?.q.questionClass.toLowerCase() === 's') ? (
                    <NumberFormat
                      id="amount-input"
                      thousandSeparator
                      name="name"
                      value={unWeightedAnswer}
                      onValueChange={(values) => {
                        const { formattedValue } = values;
                        setUnWeightedAnswer(formattedValue);
                      }}
                    />
                  ) : (
                    ''
                  )}

                {currentQuestion?.q.questionType
                  .toLowerCase()
                  .includes('date') ? (
                    <div id="tranches-date-holder">
                      <TextInput
                        onChange={(e) => {
                          setUnWeightedAnswer(e.target.value);
                        }}
                        type="date"
                        placeholder="Enter expected date"
                        id="tranches-date-input"
                        value={unWeightedAnswer}
                      />
                      <i
                        id="tranches-date-icon"
                        className="fas fa-calendar-day"
                      />
                    </div>
                  ) : (
                    ''
                  )}

                {currentQuestion?.q.questionType.toLowerCase()
                  === 'percentages'
                && (currentQuestion?.q.questionClass.toLowerCase() === 'p'
                  || currentQuestion?.q.questionClass.toLowerCase() === 's') ? (
                    <div className="Percentage-input-holder">
                      <input
                        onChange={(e) => {
                          setUnWeightedAnswer(e.target.value.toString());
                        }}
                        type="range"
                        min={0}
                        max={100}
                        className="Range"
                        value={unWeightedAnswer}
                      />

                      <div>
                        <p>0%</p>
                        <p>100%</p>
                      </div>

                      <div id="percentage-value-holder">
                        <p>
                          {unWeightedAnswer}
                          {' '}
                          %
                        </p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                {currentQuestion?.q.questionType
                  ?.toLowerCase()
                  .includes('multiple')
                && (currentQuestion?.q.questionClass.toLowerCase() === 'p'
                  || currentQuestion?.q.questionClass.toLowerCase() === 's') ? (
                    <div className="Multi-select-holder">
                      {frrAnswers.length > 0
                      && frrAnswers.map((answer) => {
                        let checked = false;
                        if (
                          checkboxValues.includes(String(answer.frrAnswerId))
                        ) {
                          checked = true;
                        }

                        return (
                          <div
                            key={answer.frrAnswerId}
                            className="Checkbox w-5 h-5 text-blue-600 bg-gray-300 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          >
                            <input
                              type="checkbox"
                              className="Checkbox-input"
                              onChange={() => {
                                if (
                                  !checkboxValues.includes(
                                    String(answer.frrAnswerId)
                                  )
                                ) {
                                  setCheckboxValues([
                                    ...checkboxValues,
                                    String(answer.frrAnswerId),
                                  ]);
                                  checked = true;
                                } else {
                                  checked = false;
                                }

                                for (
                                  let i = 0;
                                  i < checkboxValues.length;
                                  i++
                                ) {
                                  if (
                                    answer.frrAnswerId
                                    === Number(checkboxValues[i])
                                  ) {
                                    checkboxValues.splice(i, 1);
                                  }
                                }

                                setCheckboxChange(!checkboxChange);
                              }}
                              defaultChecked={checked}
                            />
                            <p>{answer.frrAnswer}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ''
                  )}

                {currentQuestion?.q.questionType
                  .toLowerCase()
                  .includes('dropdown')
                && (currentQuestion?.q.questionClass.toLowerCase() === 'p'
                  || currentQuestion?.q.questionClass.toLowerCase() === 's') ? (
                    <DropDownInput
                      onChange={(e) => {
                        setFrrAnswerId(String(e.target.value));
                      }}
                      present="sub-purpose"
                      dropDownData={frrAnswers}
                      currentData={currentAnswer}
                    />
                  ) : (
                    ''
                  )}

                <p className="Progress-counter">
                  {questionIndex + 1}
                  {' '}
                  of
                  {' '}
                  {questionBank.length}
                </p>
              </FRRQuestionBoard>

              <img
                className="FRR-image"
                src={subSectorImage}
                alt="A pictorial representation"
              />
            </div>
          </div>

          <div className="Buttons-holder">
            <div className="FRR-prev-next-buttons-holder">
              {questionIndex > 0 ? (
                <PrevButton
                  onClick={() => decrementHandler()}
                  id="prev-button"
                />
              ) : (
                ''
              )}

              {lastQuestion ? (
                <Button
                  onClick={() => postResponses()}
                  mode="1"
                  title="Save & Proceed"
                  id="save-button"
                />
              ) : (
                <NextButton
                  onClick={() => incrementHandler()}
                  id="next-button"
                  disabled={disableNextButton}
                />
              )}
            </div>

            <div className="FRR-save-button-holder">
              <Button
                onClick={() => {}}
                mode="1"
                title="Save & Exit"
                id="save-exit-button"
              />
            </div>
          </div>
        </div>
      </div>

      {answerLoader ? <Spinner /> : ''}
    </Styled>
  );
}

const Styled = styled.div`

    input{
        cursor: text;
    }

    min-height: 100vh;
    background-color: #E5E5E5;

    .Main-holder{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 94vh;
    }

    .Questions-main{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .Questions-holder{
        width: 100%;
    }

    .Questions-holder-inner{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .Buttons-holder{
        width: 100%;
        display: flex;
        align-items; center;
        justify-content: space-between;
        margin-top: 1rem;
    }

    .FRR-image{
        height: 60vh;
        border-radius: 10px;
    }

    .FRR-prev-next-buttons-holder{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 68%;
        position: relative;
    }
    
    #next-button{
        color: #D9B08C;
        font-size: 1rem;
        padding: 1rem 2rem;
        background: linear-gradient(90deg, #00989C, #004C4E);
        cursor: pointer;
        position: absolute;
        right: 0;
    }

    #save-button{
        position: absolute;
        right: 0;
        background: linear-gradient(90deg, #00989C, #004C4E);
        padding: 1rem 2rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 1rem;
    }

    #save-exit-button{
        background-color: transparent;
        border: 1px solid #AF6E51;
        border-radius: 16.5px;
        cursor: pointer;
        visibility: hidden;
    }

    #save-exit-button-disabled{
        background-color: transparent;
        border: 1px solid #AF6E51;
        border-radius: 16.5px;
        opacity: .5;
        cursor: not-allowed;
    }

    #prev-button{
        color: #D9B08C;
        font-size: 1rem;
        padding: 1rem 2rem;
        background: linear-gradient(90deg, #00989C, #004C4E);
        cursor: pointer;
        position: absolute;
        left: 0;
    }

    .FRR-save-button-holder{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27%;
    }

    .Question-div{
        margin-bottom: 3rem;
        width: 80%;
    }

    .Progress-counter{
        position: absolute;
        bottom: 10px;
        left: 48%;
        font-size: 1rem;
    }

    .Multi-select-button{
        padding: 1rem 1.5rem;
        background-color: white;
        border-radius: 3px;
        border: 2px solid #E5E5E5;
        margin-right: 1rem;
        cursor: pointer;
        min-width: 7rem;
    }

    .Multi-select-button-focus{
        padding: 1rem 1.5rem;
        background-color: white;
        border-radius: 3px;
        border: 2px solid #E5E5E5;
        margin-right: 1rem;
        cursor: pointer;
        min-width: 7rem;
        border: 2px solid #00989C;
    }

    .Question{
        text-align: center;
    }

    .Response-div{
        width: 80%;
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    #amount-div{
        position: relative;
        padding-left: 5rem;
        margin-top: 1rem;
        width: 100%;
    }

    #amount-input{
        width: 100%;
        margin-top: unset;
    }

    .Currency-holder{
        position: absolute;
        left: 0;
        background-color: #E5E5E5;
        color: black;
        top: 0;
        bottom: 0;
        cursor: pointer;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        outline: none;
    }

    #tranches-date-holder{
        position: relative;
        margin-top: 1rem;
    }

    #tranches-date-input{
        width: 100%;
        margin-top: unset;
        padding-left: 4rem;
    }

    #tranches-date-icon{
        position: absolute;
        left: 0;
        top: 0;
        font-size: 1.2rem;
        background-color: #E5E5E5;
        padding: 1.3rem 1.2rem;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border: 1px solid grey;
        cursor: pointer;
        pointer-events: none;
    }

    #tranches-amount-holder{
        position: relative;
        margin-top: 1rem;
        overflow: hidden;
    }

    #tranches-amount-input{
        width: 100%;
        margin-top: unset;
        padding-left: 4rem;
    }

    #tranches-amount-icon{
        position: absolute;
        left: 0;
        top: 0;
        background
        font-size: 1rem;
        background-color: #E5E5E5;
        padding: .82rem 1rem;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border: 1px solid grey;
    }

    .Duration-holder{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .Duration-input{
        padding-right: 3rem;
    }

    .Duration-year-holder{
        width: 30%;
        position: relative;
    }

    .Duration-year-icon,
    .Duration-month-icon,
    .Duration-day-icon{
        position: absolute;
        right: 0;
        top: 0;
        padding: 1.38rem;
        font-size: 1rem;
        background-color: #E5E5E5;
        border: 1px solid grey;
        cursor: pointer;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        pointer-events: none;
    }

    .Duration-month-holder{
        width: 30%;
        position: relative;
    }


    .Duration-day-holder{
        width: 30%;
        position: relative;
    }

    .Duration-input{
        margin-top: unset;
    }

    .Multiple-select-buttons-holder{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Dropdown{
        padding: 1rem 15rem 1rem 1rem;
        width: 100%;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid grey;
        margin-top: 1rem;
        outline: none;
    }

    .Checkbox{
        margin-right: 1rem;
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;

        input{
            margin-right: 1rem;
            cursor: pointer;
        }
    }

    input[type="checkbox"] {
        accent-color: #00989C;
        padding: 2rem;
    }

    .Multi-select-holder{
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        border-top: 1px solid grey;
        padding-top: 3rem;
    }

    .Range{
        width: 100%;
        cursor: pointer;
        background-color: #000;
    }

    input[type='range']::-webkit-slider-thumb {
        width: 6px;
        -webkit-appearance: none;
        height: 12px;
        background: black;
        border-radius: 2px;
    }

    .Percentage-input-holder{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;

        div{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: .5rem;
        }
    }

    #percentage-value-holder{
        width:: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .Counterparty-div{
        display: flex;
        align-items: center;
        justify-content: space-between;

        i{
            font-size: 2rem;
            color: #00989C;
            margin-left: 1rem;
            cursor: pointer;
        }
    }

    .Minus-icon{
        font-size: 2rem;
        color: #00989C;
        margin-left: 1rem;
        cursor: pointer;
    }

    .Individual-party{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .Parties-holder{
        margin-top: 1rem;
    }

    .Single-party-holder{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
    }

    .Error-message{
        color: red;
        position: absolute;
        bottom: -3rem;
        font-size: 1.1rem;
    }

    .Feedback-holder{
      position: absolute;
      top: 0;
      left; 0;
      right: 0;
      width: 100%;
      background-color: #F2FBFD;
      padding: 2rem 1rem;
      display: flex;
      align-items: flex;
      justify-content: center;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
`;

export default FacilityFeedbackResponse;
