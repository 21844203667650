export const populateTranchesDetails = (formik, filledData) => { 
    const tranches = {
        numberOfTranches: '',
        frequency: '',
        date: '',
        amount: ''
    }
    if (
        Object.keys(filledData).length > 0
        && filledData.disbursementMode.toLowerCase().includes('tranches')
      ) {
        const splitted = formik.values.disbursementModeTranches.split('');
       
        
        for (let i = 0; i < splitted.length; i++) {
          tranches.numberOfTranches += splitted[i];
          if (splitted[i + 1] === ';') {
            break;
          }
        }
  
        const newTranches = formik.values.disbursementModeTranches.replace(
          `${tranches.numberOfTranches};`,
          ''
        );
        const newSplitted = newTranches.split('');
  
        for (let i = 0; i < newSplitted.length; i++) {
            tranches.frequency += newSplitted[i];
          if (newSplitted[i + 1] === ';') {
            break;
          }
        }
  
        const remainingTranches = formik.values.disbursementModeTranches.replace(
          `${tranches.numberOfTranches};${tranches.frequency};`,
          ''
        );
        const remainingSplitted = remainingTranches.split('');
  
        for (let i = 0; i < remainingSplitted.length; i++) {
            tranches.date += remainingSplitted[i];
          if (remainingSplitted[i + 1] === ';') {
            break;
          }
        }
  
        const lastTranches = formik.values.disbursementModeTranches.replace(
          `${tranches.numberOfTranches};${tranches.frequency};${tranches.date};`,
          ''
        );
        tranches.amount = lastTranches;
  
      }

      return tranches;
}