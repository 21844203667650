export const financialAnswerModel = {
  lastFinancial: '',
  auditor: '',
  afsQualification: '',
  financialEndYearDate: '',
  revenueYear1: '',
  revenueYear2: '',
  costOfSalesYear1: '',
  costOfSalesYear2: '',
  grossProfitYear1: '',
  grossProfitYear2: '',
  otherIncomeYear1: '',
  otherIncomeYear2: '',
  operatingExpensesYear1: '',
  operatingExpensesYear2: '',
  operatingProfitYear1: '',
  operatingProfitYear2: '',
  financeIncomeYear1: '',
  financeIncomeYear2: '',
  financeCostYear1: '',
  financeCostYear2: '',
  taxYear1: '',
  taxYear2: '',
  profitAfterTaxYear1: '',
  profitAfterTaxYear2: '',
  cashflowOperatingYear1: '',
  cashflowOperatingYear2: '',
  cashflowInvestingYear1: '',
  cashflowInvestingYear2: '',
  cashflowFinancingYear1: '',
  cashflowFinancingYear2: '',
  nonCurrentAssetYear1: '',
  nonCurrentAssetYear2: '',
  currentAssetYear1: '',
  currentAssetYear2: '',
  receivablesYear1: '',
  receivablesYear2: '',
  inventoryYear1: '',
  inventoryYear2: '',
  nonCurrentLiabilityYear1: '',
  nonCurrentLiabilityYear2: '',
  longTermLoansYear1: '',
  longTermLoansYear2: '',
  currentLiabilityYear1: '',
  currentLiabilityYear2: '',
  payablesYear1: '',
  payablesYear2: '',
  shortTermLoansYear1: '',
  shortTermLoansYear2: '',
  bankOverdraftYear1: '',
  bankOverdraftYear2: '',
  shareholderCapitalYear1: '',
  shareholderCapitalYear2: '',
  retainedEarningsYear1: '',
  retainedEarningsYear2: '',
  auditedFinancialsDocument: '',
  depreciationAmortisationYear1: '',
  depreciationAmortisationYear2: '',
  accruedExpensesYear1: '',
  accruedExpensesYear2: '',
};
