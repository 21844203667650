import React from 'react';
import Navbar from 'components/Home/Navbars/Navbar';
import Main from 'components/Home/HowItWorks';
import Footer from 'components/Home/Main/Footer';
import { Helmet } from 'react-helmet-async';

export default function HowItWorks() {
  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #f7fffd' }}>
        <title>How It Works | Gazelle by Sterling</title>
      </Helmet>
      <Navbar />
      <Main />
      <Footer />
    </>
  );
}
