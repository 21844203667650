import { apiWithToken, apiWithoutToken } from './axiosInterceptor';

const request = {
  getBvn: (data) => {
    const method = 'post';
    const url = '/api/FIRS/BVN';
    return apiWithoutToken({
      method,
      url,
      data: { BVN: data },
    });
  },
  validateEmailPhoneOtp: (data) => {
    const method = 'post';
    const url = '/api/FIRS/ValidatePhoneEmailToken';
    return apiWithoutToken({
      method,
      url,
      data
      // params: { phone: data.phone, token: data.token, email: data.email },
    });
  },
  generateEmailPhoneOtp: (data) => {
    const method = 'post';
    const url = '/api/FIRS/GeneratePhoneEmailToken';
    return apiWithoutToken({
      method,
      url,
      data,
      // params: { email: data.email, phone: data.phone },
    });
  },
  getTin: (data) => {
    const method = 'get';
    const url = '/api/FIRS/TIN';
    return apiWithoutToken({
      method,
      url,
      params: { TIN: data },
      timeout: 30000,
    });
  },
  getAccountDetailsByNuban: (data) => {
    const method = 'get';
    const url = '/api/FIRS/GetAccountNumberByNuban';
    return apiWithoutToken({
      method,
      url,
      params: { nuban: data },
    });
  },
  getMoreByNuban: (data) => {
    const method = 'get';
    const url = '/api/FIRS/GetCustomerInfoByCusNumber';
    return apiWithoutToken({
      method,
      url,
      params: { Cus_Num: data },
    });
  },
  getAllSectorT24: (data) => {
    const method = 'get';
    const url = '/api/FIRS/GetAllT24Sectors';
    return apiWithoutToken({
      method,
      url,
    });
  },
  checkWatchList: (data) => {
    const method = 'get';
    const url = '/api/FIRS/CheckWatchList';
    return apiWithToken({
      method,
      url,
      params: { firstName: data.firstName, lastName: data.lastName },
    });
  },
  checkPEPSearch: (data) => {
    const method = 'get';
    const url = '/api/FIRS/CheckPEPSearch';
    return apiWithToken({
      method,
      url,
      params: { firstName: data.firstName, lastName: data.lastName },
    });
  },
};

export default request;
