import React from 'react';
import styled from 'styled-components';
import Header from '../Headers/General';
import Tabs from './Tabs';

export default function Dashboard(props) {
  return (
    <Styled>
      <Header id={props.headerId} />
      <section className="box">
        <aside className="aside" id={props.tabsBackgroundId}>
          <Tabs activeBar={props.activeBar} id={props.tabsId} />
        </aside>
        <main className="main">{props.children}</main>
      </section>
    </Styled>
  );
}
const Styled = styled.div`
  background: #e5e5e5;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  .box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    border-radius: 5px;
  }
  .main {
    width: calc(100% - 25rem);
    padding: 0rem;
  }
  .aside {
    min-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 22rem;
    padding: 2rem 1rem;
    border-right: 2px solid #e5e5e5;
    background: rgba(245, 248, 240, 0.95);
    /* .company_name {
      margin-bottom: 4rem;
      font-weight: 600;
      color: #004c4e;
    } */
  }
  /* .red_feedback_dot {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: #d45f36;
    border-radius: 5rem;
    top: 10;
    right: 0;
    z-index: 2;
  } */
  /* .aside-tab-item {
    position: relative;
    display: flex;
    padding: 1rem 0 1rem 5px;
    color: #004c4e;
    cursor: pointer;
    margin-bottom: 1rem;
    font-weight: 500;
    p {
      font-size: 14px;
    }
    &:hover {
      color: #004c4e;
    }
    svg {
      margin-right: 1rem;
      width: 25px;
    }
  } */

  .active-tab {
    background: #d1e8e2;
    border-radius: 4px;
    color: #004c4e;
  }
  /* .cursor-not-allowed {
    cursor: not-allowed;
  } */
`;
