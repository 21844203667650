import { useEffect, useState } from 'react';
import { frrApis } from 'apis';
import { useSelector } from 'react-redux';
import errorHandler from 'helpers/errorHandler';

export default function useFacilityFeedBack() {
  const { business } = useSelector((state) => state);
  const [facilityFeedBackAvailable, setFacilityFeedBackAvailable] = useState({
    status: null,
    loader: false,
  });

  const businessId = business?.businessProfiles[0].businessId;

  useEffect(() => {
    const getAllFeedbacks = async () => {
      try {
        const response = await frrApis.getFacilityFeedbackByBusinessId(
          businessId
        );
        setFacilityFeedBackAvailable(prev => ({
          ...prev, status: response.data.data
        }));
      } catch (error) {
        errorHandler(error);
      }
    };

    getAllFeedbacks();
  }, []);

  return [facilityFeedBackAvailable];
}
