import * as actionTypes from '../action-types/notify';

export const ShowNotify = (data) => async (dispatch, getState) => {
  document.querySelector('html').classList.toggle('modal-open');
  dispatch({ type: actionTypes.SHOW_NOTIFY, payLoad: data });
};
export const HideNotify = (data) => async (dispatch, getState) => {
  document.querySelector('html').classList.toggle('modal-open');
  dispatch({ type: actionTypes.HIDE_NOTIFY, payLoad: data });
};
