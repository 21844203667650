import React from 'react';
import Button, { NextButton, PrevButton } from 'components/Buttons';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ErrorHandler from 'helpers/errorHandler';
import useSubSectorImage from 'hooks/useSubSectorImage';
import Tab from './Tab';

export default function Layout(props) {
  const [subSectorImage, isImageLoading] = useSubSectorImage();

  return (
    <Styled className="section_ques">
      <main className="orr_ques_main">
        <Tab />
        <div className="orr_ques_container">{props.children}</div>
        <div className="orr_button_section">
          {props.stage == 'start' ? (
            <div />
          ) : (
            <PrevButton onClick={props.prevHandler} />
          )}
          {props.stage == 'summary' ? (
            <Button mode="4" title="Submit" onClick={props.submitHandler} />
          ) : (
            <NextButton onClick={props.nextHandler} />
          )}
        </div>
      </main>
      <aside className="orr_ques_aside">
        <div className="img-wrapper">
          <img
            src={subSectorImage.length > 2 ? subSectorImage : undefined}
            alt=""
          />
        </div>
        <Button mode="5" title="Exit" onClick={props?.exitHandler} />
      </aside>
    </Styled>
  );
}

const Styled = styled.div`
  display: flex;
  justify-content: space-between;
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    min-height: 45rem;
    background-color: #fff;
    display: flex;
  }
  .orr_button_section {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
  }
  .orr_ques_main {
    width: 65%;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  .orr_ques_aside {
    width: 28%;
    text-align: center;
    padding-top: 8rem;
    @media (max-width: 1200px) {
      width: 30%;
    }
    @media (max-width: 85rem) {
      display: none;
    }
    button {
      background: #004c4e;
      color: #fff;
    }
    .img-wrapper {
      height: 50rem;
      overflow: hidden;
      margin-bottom: 3.5rem;
      border-radius: 1rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
