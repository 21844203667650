import React from 'react';
import styled from 'styled-components';
import { NextButton, PrevButton } from 'components/Buttons';
import NumberFormat from 'react-number-format';

export default function Assets(props) {
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    props.setFinAnswers((prev) => ({
      ...prev,
      [name]: Number(String(value)?.replaceAll(',', '')),
    }));
  };
  const prevHandler = () => {
    props.prevHandler();
  };
  const nextHandler = (e) => {
    e.preventDefault();
    props.nextHandler();
  };
  const yearHandler = (e) => {
    const auditYear = new Date(
      props.finAnswers.financialEndYearDate
    ).getFullYear();
    return auditYear;
  };
  return (
    <Styled onSubmit={nextHandler}>
      <section className="orr_ques_container">
        <div className="question">
          <p className="question_title">
            Kindly provide details of your Assets below
          </p>
          <div>
            <table className="styled-table">
              <thead>
                <tr>
                  <th />
                  <th>{yearHandler()}</th>
                  <th>{yearHandler() - 1}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-60">Non-Current Asset (₦)</td>
                  <td>
                    <NumberFormat
                      name="nonCurrentAssetYear1"
                      value={props?.finAnswers?.nonCurrentAssetYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="nonCurrentAssetYear2"
                      value={props?.finAnswers?.nonCurrentAssetYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60">Current Asset (₦)</td>
                  <td>
                    <NumberFormat
                      name="currentAssetYear1"
                      value={props?.finAnswers?.currentAssetYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="currentAssetYear2"
                      value={props?.finAnswers?.currentAssetYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60">Trade Receivables (₦)</td>
                  <td>
                    <NumberFormat
                      name="receivablesYear1"
                      value={props?.finAnswers?.receivablesYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="receivablesYear2"
                      value={props?.finAnswers?.receivablesYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60">Inventory (₦)</td>
                  <td>
                    <NumberFormat
                      name="inventoryYear1"
                      value={props?.finAnswers?.inventoryYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="inventoryYear2"
                      value={props?.finAnswers?.inventoryYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="indicationWrapper">
            7 of
            {' '}
            <strong style={{ fontSize: '14px' }}>10</strong>
          </p>
        </div>
      </section>
      <section className="orr_button_section">
        <PrevButton onClick={prevHandler} />
        <NextButton type="submit" />
      </section>
    </Styled>
  );
}
const Styled = styled.form`
  height: 100%;
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    height: 100%;
    margin-bottom: 2rem;
    background-color: #fff;
  }
  .question {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    justify-content: center;
    padding: 0 5%;
  }
  .question_title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .styled-table {
    border-collapse: collapse;
    border: none;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 40rem;
    width: 100%;
  }

  .styled-table th,
  .styled-table td {
    font-size: 1.4rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border: none;
  }
  .styled-table td {
    font-weight: 300;
    font-size: 1.4rem;
  }
  .styled-table th {
    background-color: #f2f2f2;
  }
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .styled-table input {
    min-width: 10rem;
    width: 100%;
    border: thin solid #ddd;
    height: 2.5rem;
    text-align: right;
  }
  .td-60 {
  }
  .orr_button_section {
    display: flex;
    justify-content: space-between;
  }
  .indicationWrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.2rem;
  }
`;
