import React, { useState, useEffect } from 'react';
import frrApis from 'apis/frr';
import { UpdateCollateralPayload } from 'store/actions/collateral';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { asyncWrapper } from 'helpers/asyncWrapper';
import Button from 'components/Buttons';
import { AnswerContainer } from 'components/AnswerReview/QuesAnswerTypesView';
import { formatDate } from '../../../helpers/dateFormat';

function TitleValue({ title, value, isDisplay = true }) {
  return isDisplay ? (
    <div>
      <p className="text_lt">
        {title}
        :
      </p>
      <p className="text_rn">{value}</p>
    </div>
  ) : (
    ''
  );
}

export default function Summary(props) {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const [selectedPricing, setSelectedPricing] = useState({});
  const [solicitorFee, setSolicitorFee] = useState(null);
  const { collateral } = useSelector((state) => state);
  const { checks } = collateral;

  useEffect(() => {
    const { collateralTypeId } = collateral.payLoad;
    if (collateralTypeId == null) {
      return navigate('/app/collateral/new/start');
    }
    return () => {};
  }, [collateral]);

  useEffect(() => {
    const {
      collateralTypeId,
      carryOutNewValuation,
      securityValue,
      stateId,
      searchFeePropertyType,
    } = collateral.payLoad;
    const getPricingTbl = async () => {
      props?.setLoading(true);
      const [res, err] = await asyncWrapper(
        frrApis.getCollateralPricingTbl({
          collateralTypeId,
        })
      );
      props?.setLoading(false);
      const getSecurityValue = parseFloat(securityValue?.replace(/,/g, ''));
      const fallsBetween = (min, max, val) => val > min && val < max;
      const pricingList = res.data.data;

      const selectedPrice = pricingList.filter((elem) => fallsBetween(elem.minPrice, elem.maxPrice, getSecurityValue));

      setSelectedPricing(
        selectedPrice[0] || { pricingId: 8, feeCharge: 40000 }
      );
      dispatch(
        UpdateCollateralPayload({
          pricingId: selectedPrice[0]?.pricingId || 8,
        })
      );
      //
    };
    if (carryOutNewValuation) {
      getPricingTbl();
    }
    const getSolicitorFee = async () => {
      props?.setLoading(true);
      const [res, err] = await asyncWrapper(
        frrApis.getSolicitorFeeStateMappingByStatId({
          stateId,
        })
      );
      props?.setLoading(false);
      if (searchFeePropertyType === 'Commercial') {
        return setSolicitorFee(res?.data?.data[0]?.commercialTotalFee);
      }
      setSolicitorFee(res?.data?.data[0]?.residentialTotalFee);
    };
    if (stateId) {
      getSolicitorFee();
    }
    return () => {};
  }, []);

  const { payLoad } = collateral;
  const dispatch = useDispatch();
  const getSessionData = (key) => sessionStorage.getItem(key) || null;

  const getPoolQuestions = () => JSON.parse(getSessionData('_questionsPool'))?.filter(
    (elem) => (elem.questionType !== 'File'
          && elem.inputtedAnswer != 'undefined'
          && elem.selectedAnswer != 'undefined'
          && elem.inputtedAnswer != undefined)
        || elem.selectedAnswer != undefined
  );
  const otherQuestionsHandler = () => navigate('/app/collateral/new/other-questions');

  return (
    <Styled>
      <div className="box">
        <p className="review_title">Review Your Answers</p>
      </div>
      <section className="tab_form">
        <div className="tab_form_nav">
          <div
            className={`tab_form_nav_item ${
              current == 1 && 'tab_form_nav_item-active'
            }`}
            onClick={() => setCurrent(1)}
          >
            Summary
          </div>
          <div
            className={`tab_form_nav_item ${
              current == 2 && 'tab_form_nav_item-active'
            }`}
            onClick={() => setCurrent(2)}
          >
            Questions
          </div>
        </div>
        <div className="tab_form_main">
          {current == 1 && (
            <div>
              <div>
                <TitleValue
                  title="Collateral Type"
                  value={
                    payLoad.collateralTypeId
                    && getSessionData('_collateralType')
                  }
                />
                <TitleValue
                  title="Collateral Seniority"
                  value={
                    typeof payLoad.collateralSeniorityValue === 'number'
                    && getSessionData('_seniority')
                  }
                />
                <TitleValue
                  title="Security Value"
                  value={payLoad.securityValue}
                />
              </div>
              {/* state classification */}

              {checks.state_classification && (
                <section className="state_classification">
                  <div className="sub_header">
                    <p className="title">State Classification</p>
                  </div>
                  <TitleValue
                    title="State"
                    value={payLoad.stateId && getSessionData('_state')}
                  />
                  <TitleValue
                    title="Local Government"
                    value={payLoad.lgaId && getSessionData('_lga')}
                  />
                </section>
              )}
              {/* search fee */}
              {checks.search_fee && (
                <section className="search_fee">
                  <div className="sub_header">
                    <p className="title">Search Fee</p>
                  </div>

                  <TitleValue
                    title="Property Type"
                    value={payLoad.searchFeePropertyType}
                  />
                  <TitleValue
                    title="Payment Now"
                    value={payLoad.searchFeePayNow}
                  />
                  <TitleValue title="Fee" value={solicitorFee} />
                </section>
              )}
              {/* Valuation */}
              {checks.valuation && (
                <section className="valuation">
                  <div className="sub_header">
                    <p className="title">Valuation</p>
                  </div>
                  <div>
                    <TitleValue
                      title="Have you carried out valuation on collateral"
                      value={payLoad?.isValuationExisting}
                    />
                    <TitleValue
                      title="Will you like to carry out a valuation"
                      value={payLoad?.carryOutNewValuation}
                      isDisplay={payLoad.carryOutNewValuation}
                    />

                    <TitleValue
                      title="Valuation Fee"
                      value={selectedPricing?.feeCharge}
                      isDisplay={payLoad.pricingId}
                    />
                    <TitleValue
                      title="Valuer"
                      value={
                        payLoad?.knownValuerId && getSessionData('_valuer')
                      }
                      isDisplay={payLoad.knownValuerId}
                    />
                    <TitleValue
                      title="Valuation Date"
                      value={
                        payLoad?.valuationDate
                        && formatDate(payLoad?.valuationDate)
                      }
                      isDisplay={payLoad.valuationDate}
                    />
                    <TitleValue
                      title="Valuation Estimate"
                      value={payLoad?.valuationEstimate}
                      isDisplay={payLoad.valuationEstimate}
                    />
                  </div>
                </section>
              )}
            </div>
          )}
          {current == 2 && (
            <div>
              {getPoolQuestions()?.map((elem, i) => (
                <div key={i} className="tab_form_main-item">
                  <p className="review_question_title">
                    <span className="button_circle" />
                    Question
                  </p>
                  <p className="review_question">{elem.question}</p>
                  <p className="review_answer_title">
                    <span className="button_circle_green" />
                    Answer
                  </p>
                  <AnswerContainer questionProperties={elem} />
                </div>
              ))}
              {getPoolQuestions().length == 0 && <p>No Questions</p>}
            </div>
          )}
        </div>
      </section>
      <div className="orr_button_section">
        <Button mode="1" onClick={otherQuestionsHandler}>
          Back
        </Button>
        <Button mode="1" onClick={props.submitHandler}>
          Submit
        </Button>
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  padding: 2rem;
  width: 100%;
  .box {
    margin: 0 auto;
    /* max-width: 90rem; */
    width: 100%;
    padding: 1rem;
  }
  .header {
    text-align: center;
    .title {
      font-size: 1.8rem;
      font-weight: 500;
      text-transform: uppercase;
    }
    margin-bottom: 2rem;
  }
  section {
    margin-top: 2rem;
  }
  .sub_header {
    .title {
      font-weight: 500;
    }
  }
  .text_lt {
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
  }
  .text_rn {
    display: inline-block;
    padding-left: 1rem;
    font-size: 1.4rem;
    text-transform: capitalize;
  }
  .tab_form_main {
    padding: 1rem 2rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 50rem;
    overflow-y: scroll;
    background: #fff;
  }
  .tab_form_main-item {
    padding: 1rem 0;
    border-bottom: 1px solid #e5e5e5;
  }
  .tab_form_nav {
    display: flex;
    justify-content: space-between;
  }
  .tab_form_nav_item {
    color: #808080;
    padding: 0.8rem;
    text-align: center;
    cursor: pointer;
    width: 100%;
    background: #fff;
    font-weight: 500;
    border-bottom: thin solid #e5e5e5;
    font-size: 1.4rem;
    &:nth-of-type(1) {
      border-top-left-radius: 5px;
    }
    &:nth-of-type(3) {
      border-top-right-radius: 5px;
    }
  }
  .tab_form_nav_item-active {
    color: #00989c;
    border-bottom: 3px solid #00989c;
  }
  .review_title {
    font-weight: 500;
    color: #004c4e;
    margin: 1rem 2rem 0;
    font-size: 2rem;
  }
  .review_answer_title,
  .review_question_title {
    font-size: 1.4rem;
    font-weight: 500;
    color: #505050;
    padding-top: 0.5rem;
  }
  .review_answer,
  .review_question {
    display: block;
    font-size: 1.4rem;
    color: #808080;
    padding-left: 1.2rem;
  }
  .button_circle {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 5rem;
    background: #d9b08c;
    display: inline-block;
    margin-right: 0.5rem;
  }
  .button_circle_green {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 5rem;
    background: #00989c;
    display: inline-block;
    margin-right: 0.5rem;
  }
  .orr_button_section {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
`;
