import { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Header from 'components/Headers/General';
import { useSelector } from 'react-redux';
import frrApis from 'apis/frr';
import Spinner from 'components/OverLays/OverLaySpinner';
import errorHandler from 'helpers/errorHandler';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import EclipesIcon from '../../../assets/svgs/facility-submission-eclipse.svg';
import Button from '../../Buttons/index';
import Modal from '../modal';

function FacilitySubmission() {
  const navigate = useNavigate();
  const location = useLocation();

  const [modal, setModal] = useState(false);
  const [facilityLoader, setFacilityLoader] = useState(false);
  const [facilityData, setFacilityData] = useState({
    status: 'okay',
    like: true
  });
  const queryList = queryString.parse(location.search);
  const facilityIdFromQuery = Number(queryList.facilityId);

  const user = useSelector((state) => state?.auth.user);
  const facilityId = useSelector(
    (state) => state?.facilityAnsweredQuestions?.facilityId.facilityId
  );

  useLayoutEffect(() => {
    const getFacilityDetail = async () => {
      setFacilityLoader(true);
      try {
        const response = await frrApis.getFacilityDetailsByFacilityId(
          !isNaN(facilityIdFromQuery) ? facilityIdFromQuery : facilityId
        );
        // const facility = response?.data?.data?.f;
        setFacilityData(response?.data?.data?.f);

        // if(Object.keys(facility).length > 0 && facility.status.toLowerCase().includes('initiated')){
        //   setFacilityData({
        //     ...facilityData, status: 'completed'
        //   })
        // }
      } catch (error) {
        errorHandler(error);
      } finally {
        setFacilityLoader(false);
      }
    };

    if (facilityId) {
      getFacilityDetail();
    }
  }, [facilityId]);

  // useEffect(() => {
  //   if (
  //     Object.keys(facilityData).length > 0
  //     && (facilityData?.status?.toLowerCase().includes('initiated'))
  //   ) {
  //     setFacilityData({
  //       ...facilityData,
  //       status: 'completed',
  //     });
  //   };

  // }, [Object.keys(facilityData).length]);

  const updateFacilityData = () => {
    if (
      Object.keys(facilityData).length > 0
      && (facilityData?.status?.toLowerCase().includes('initiated'))
    ) {
      setFacilityData({
        ...facilityData,
        status: 'completed',
      });
    };
  }

  const updateFacility = async () => {
    setFacilityLoader(true);
    try {
      const response = await frrApis.updateFacility(facilityData);
      navigate('/app/frr/facilityconclusion');
    } catch (error) {
      errorHandler(error);
    } finally {
      setFacilityLoader(false);
    }
  };

  return (
    <Styled>
      <Header />

      <div className="Submission-main">
        <div className="Submission-holder">
          <img src={EclipesIcon} alt="an eclipse icon" />
          <p>
            Dear
            {' '}
            {user.firstName}
            ,
            <br />
            your Facility Evaluation is in progress.
          </p>
          <Button
            title="Submit application"
            id="submit-button"
            onClick={() => {
              setModal(true);
              updateFacilityData();
            }}
            disabled = { Object.keys(facilityData).length < 1 }
          />
        </div>
        {/* <img
          className="Background-image"
          src={'https://businesslending.azureedge.net/web/assets/images/flower-pot.png'}
          alt="3D background"
        /> */}
      </div>

      {modal === true ? (
        <Modal onClick={() => setModal(false)}>
          <p className="Modal-text">
            Dear
            {' '}
            {user.firstName}
            ,
            {' '}
            <br />
            kindly note that your request is received
            {' '}
            <br />
            and will be sent for approval.
          </p>
          <div className="Modal-button-holder">
            <Button onClick={updateFacility} title="Okay" id="modal-button" />
          </div>
        </Modal>
      ) : (
        ''
      )}

      {facilityLoader ? <Spinner /> : ''}
    </Styled>
  );
}

const Styled = styled.div`
  width: 100%;
  background-color: #e5e5e5;
  position: relative;

  .Submission-main {
    width: 100%;
    position: relative;
    height: 93.6vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  .Submission-holder {
    z-index: 2;
    background-color: white;
    border-radius: 10px;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;

    p {
      margin: 3rem 0;
      text-align: center;
    }
  }

  #submit-button {
    padding: 1rem 2rem;
    color: #d9b08c;
    background: linear-gradient(261.93deg, #004c4e 0%, #00989c 100%);
    border-radius: 6px;
    border: none;
    cursor: pointer;
  }

  .Modal-text {
    margin: 1rem 0;
    color: white;
    text-align: center;
  }

  .Modal-button-holder {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }

  #modal-button {
    background-color: white;
    border: 2px solid #004c4e;
    padding: 1rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    color: #004c4e;
  }
`;

export default FacilitySubmission;
