import { apiWithToken } from './axiosInterceptor';

const request = {
  openCorporateAccount: (data) => {
    const method = 'post';
    const url = '/api/verification/Opencorporateaccount';
    return apiWithToken({ method, url, data });
  },
  getAccountDirectorate: (data) => {
    const method = 'post';
    const url = `api/directorate/validate`;
    return apiWithToken({ method, url, data: { accNo: data } });
  },
  getDirectorates: () => {
    const method = 'get';
    const url = `api/directorate`;
    return apiWithToken({ method, url });
  },
  mapAccountDirectorate: (businessId) => {
    const method = 'patch';
    const url = `api/directorate/business/${businessId}`;
    return apiWithToken({ method, url });
  }
};

export default request;
