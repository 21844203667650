import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import {
  ReceiptV2Svg,
  IndustrySvg,
  IndustryCarSvg,
  DashboardSvg,
  SettingSvg,
  LogOutV2Svg,
  FeedbackSvg,
  CollateralSvg,
  OfferLetterIcon
} from 'theme/icons';
import { useDispatch, useSelector } from 'react-redux';
import useOrrFeedBack from 'hooks/useOrrFeedBack';
import useFacilityFeedBack from 'hooks/useFacilityFeedback';
import useCollateralFeedBack from 'hooks/useCollateralFeedback';
import * as AuthActions from 'store/actions/auth';
import { useFacilityAndCollateral } from 'hooks/useFacilityAndCollateral';
import useNotify from 'hooks/useNotify';
import useOrrScore from 'hooks/useOrrScore';
import { FaRegFolderOpen } from 'react-icons/fa';
import { BsBank } from 'react-icons/bs';
import Modal from 'components/FacilityRiskRating/modal';
import Document from '../../pages/Documents/index';

export default function Tabs(props) {
  const navigate = useNavigate();
  const [facilities, collaterals] = useFacilityAndCollateral();
  const [isFeedBackAvailable] = useOrrFeedBack();
  const [facilityFeedBackAvailable] = useFacilityFeedBack();
  const [collateralFeedBack] = useCollateralFeedBack();
  const dispatch = useDispatch();
  const [setNotify] = useNotify();
  const [grades, isScoreLoading] = useOrrScore();
  const [openDocument, setOpenDocument] = useState(false);

  const logOutHandler = () => setNotify({
    show: true,
    type: 'info',
    message: 'Are you sure you want to logout',
    func: () => {
      navigate('/auth/login', { replace: true });
      dispatch(AuthActions.AuthResetUser());
    },
  });

  const {
    business: { businessProfiles },
  } = useSelector((state) => state);

  const businessData = businessProfiles[0];

  return (
    <Styled id={props.id}>
      <Link
        to="/app/dashboard"
        className="aside-tab-item"
        id={props.activeBar === 'dashboard' ? 'active' : ''}
      >
        <DashboardSvg />
        <p>Dashboard</p>
      </Link>
      <Link
        to="/app/orr/dashboard"
        className="aside-tab-item "
        id={props.activeBar === 'business-evaluation' ? 'active' : ''}
      >
        <IndustrySvg />
        <p>Business Evaluation</p>
        {isFeedBackAvailable.status && (
          <span className="red_feedback_dot" />
        )}
      </Link>

      {!businessData
        || businessData?.companyAccountNumber == ''
        || !grades
        || grades?.orrScore < 35 ? (
        ''
      ) : (
        <>
          <Link
            to="/app/frr/facilityevaluation"
            className="aside-tab-item"
            id={props.activeBar === 'facility-evaluation' ? 'active' : ''}
          >
            <IndustryCarSvg />
            <p>Facility Evaluation</p>
            {!facilityFeedBackAvailable.status || Object.keys(facilityFeedBackAvailable.status).length > 0 && (
              <span className="red_feedback_dot" />
            )}
          </Link>
          <Link
            to="/app/collateral"
            className="aside-tab-item"
            id={props?.activeBar === 'collateral-evaluation' ? 'active' : ''}
          >
            <CollateralSvg />
            <p>Collateral Evaluation</p>
            {collateralFeedBack.status && (
              <span className="red_feedback_dot" />
            )}
          </Link>
        </>
      )}

      <Link
        to="/app/orr/feedback"
        className="aside-tab-item"
        id={props.activeBar === 'validation-feedback' ? 'active' : ''}
      >
        <FeedbackSvg height="25px" />
        <p>Validation Feedback</p>
        {isFeedBackAvailable.status && (
          <span className="red_feedback_dot" />
        )}
      </Link>

      <Link
        to="/app/frr/offer-letter"
        className="aside-tab-item"
        id={props.activeBar === 'offer-letter' ? 'active' : ''}
      >
        <OfferLetterIcon height="25px" />
        <p>Offer Letter</p>
      </Link>
      {/* <Link
        to="/app/document"
        className="aside-tab-item"
        id={
          props.activeBar === "document"
            ? "document-button-active"
            : "document-button"
        }
      > */}
      <button
        onClick={() => {
          setOpenDocument(true);
        }}
        className="aside-tab-item"
        id={
          props.activeBar === 'document'
            ? 'document-button-active'
            : 'document-button'
        }
      >
        <FaRegFolderOpen size={22} color="#5FB19C" />
        <p>Documents</p>
      </button>
      {/* </Link> */}
      {!businessData || businessData?.companyAccountNumber == '' ? (
        ''
      ) : (
        <Link
          to="/app/frr/credit-history"
          className="aside-tab-item"
          id={
            props.activeBar === 'credit-history'
              ? 'document-button-active'
              : 'document-button'
          }
        >
          <ReceiptV2Svg height="25px" />
          <p>Credit History</p>
        </Link>
      )}
      {/* Account Number */}
      {!businessData || businessData?.companyAccountNumber != '' ? (
        ''
      ) : (
        <Link
          to="/app/account-opening"
          className="aside-tab-item"
          id={props?.activeBar === 'account-opening' ? 'active' : ''}
        >
          <BsBank size={22} color="#5FB19C" />
          <p>Account Opening</p>
        </Link>
      )}
      <Link
        to="/app/settings"
        className="aside-tab-item"
        id={props.activeBar === 'settings' ? 'active' : ''}
      >
        <SettingSvg stroke="#5FB19C" strokeWidth="2px" height="22px" />
        <p>Settings</p>
      </Link>
      <div className="aside-tab-item" onClick={logOutHandler}>
        <LogOutV2Svg width="22px" height="22px" />
        <p>Logout</p>
      </div>

      {openDocument ? (
        <Modal
          onClick={() => {
            setOpenDocument(false);
          }}
          id="modal"
          iconId="modal-icon"
        >
          <Document />
        </Modal>
      ) : (
        ''
      )}
    </Styled>
  );
}
const Styled = styled.div`
  .aside-tab-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem 0 1rem 0.5rem;
    color: #004c4e;
    cursor: pointer;
    margin-bottom: 1rem;
    font-weight: 500;
    p {
      font-size: 1.4rem;
    }
    &:hover {
      color: #004c4e;
    }
    svg {
      margin-right: 1rem;
      width: 2.5rem;
    }
  }
  .cursor-not-allowed {
    cursor: not-allowed;
  }
  .red_feedback_dot {
    position: absolute;
    width: 0.6rem;
    height: 0.6rem;
    background-color: #d45f36;
    border-radius: 10rem;
    border: thin solid #d45f36;
    top: 1rem;
    right: 0;
    z-index: 2;
  }

  #active {
    background-color: #d1e8e2;

    border-radius: 6px;
  }

  #modal {
    background-color: white;
    padding: 2rem 4rem 4rem 4rem;
  }

  #modal-icon {
    color: black;
  }

  #document-button {
    background-color: transparent;
    border: none;
    padding-right: 5rem;
  }

  #document-button-active {
    background-color: #d1e8e2;
    border-radius: 6px;
    border: none;
    padding-right: 5rem;
  }
`;
