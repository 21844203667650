import { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import Header from 'components/Headers/General';
import { useSelector } from 'react-redux';
import frrApis from 'apis/frr';
import Spinner from 'components/OverLays/OverLaySpinner';
import { useNavigate, Link } from 'react-router-dom';
import errorHandler from 'helpers/errorHandler';
import moment from 'moment';
import CollateralIcon from '../../../assets/svgs/hand-icon.svg';
import NoCollateralIcon from '../../../assets/svgs/no-collateral-icon.svg';
import Modal from '../modal';

function FacilityCollateralMapping() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [collaterals, setCollaterals] = useState([]);
  const [formikData, setFormikData] = useState({});
  const [formikLoader, setFormikLoader] = useState(false);
  const [mapLoader, setMapLoader] = useState(false);
  const [collateralLoader, setCollateralLoader] = useState(false);
  const [selectedCollateral, setSelectedCollateral] = useState({});
  const [mapDetail, setMapDetail] = useState({
    collateralId: 0,
    facilityId: 0,
  });

  const businessId = useSelector(
    (state) => state?.business?.businessProfiles[0].businessId
  );

  const facilityId = useSelector(
    (state) => state?.facilityAnsweredQuestions?.facilityId
  );

  useEffect(() => {
    if (Object.keys(formikData).length > 0) {
      setMapDetail({
        ...mapDetail,
        facilityId: Number(formikData.facilityId),
      });
    }
  }, [Object.keys(formikData).length]);

  useEffect(() => {
    const getCollaterals = async () => {
      setCollateralLoader(true);
      try {
        const response = await frrApis.getCollateralDetails(businessId);
        setCollaterals(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setCollateralLoader(false);
      }
    };

    getCollaterals();
  }, [businessId]);

  const mapFacilityToCollateral = async () => {
    setMapLoader(true);
    try {
      const response = await frrApis.mapFacilityToCollateral(mapDetail);
      navigate('/app/frr/facilitysubmission');
    } catch (error) {
      errorHandler(error);
    } finally {
      setMapLoader(false);
    }
  };

  useLayoutEffect(() => {
    const getFacilityDetail = async () => {
      setFormikLoader(true);
      try {
        const response = await frrApis.getFacilityDetailsByFacilityId(
          facilityId.facilityId
        );
        setFormikData(response.data.data.f);
      } catch (error) {
        errorHandler(error);
      } finally {
        setFormikLoader(false);
      }
    };

    if (facilityId.faclityId !== 0) {
      getFacilityDetail();
    }
  }, [facilityId]);

  return (
    <Styled>
      <Header />

      <div className="Collateral-main">
        <div className="Collateral-holder">
          <p>
            Dear customer,
            {' '}
            <br />
            Kindly select your desired Collateral option
            {' '}
            <br />
            to support your facility.
          </p>

          <div className="Collateral-options-holder">
            <button
              onClick={() => {
                setModal(true);
              }}
              className="Collateral-button"
            >
              <img src={CollateralIcon} alt="an icon" />
              <p>
                Select existing
                {' '}
                <br />
                {' '}
                Collateral
              </p>
            </button>
            <button
              onClick={() => {
                navigate(
                  `/app/collateral/new/start?facilityId=${formikData.facilityId}`
                );
              }}
              className="Collateral-button"
            >
              <img src={CollateralIcon} alt="an icon" />
              <p>
                Add new
                {' '}
                <br />
                {' '}
                Collateral now
              </p>
            </button>

            {/* <Link to="/app/frr/facilitysubmission">
              <button className="Collateral-button">
                <img src={NoCollateralIcon} alt="an icon" />
                <p>
                  Proceed without
                  {' '}
                  <br />
                  {' '}
                  Collateral
                </p>
              </button>
            </Link> */}
          </div>
        </div>
      </div>

      {modal ? (
        <Modal iconId="modal-close" id="modal" onClick={() => setModal(false)}>
          <h4 className="Modal-header">Select existing collateral</h4>

          <div className="Collateral-names-holder">
            <p>
              Collateral Name
              <span style={{ color: 'red' }}>*</span>
            </p>
            <select
              onChange={(e) => {
                setMapDetail({
                  ...mapDetail,
                  collateralId: Number(e.target.value),
                });
              }}
              className="Dropdown"
            >
              <option value="">Select option</option>
              {collaterals?.length > 0
                && collaterals.map((collateral) => (
                  <option
                    key={collateral.c.collateralId}
                    value={collateral.c.collateralId}
                  >
                    {`${collateral.t.collateralTypeName
                    }_${
                      collateral.c.collateralId}`}
                  </option>
                ))}
            </select>
          </div>

          <div className="Buttons-holder">
            <button onClick={() => setModal(false)} className="Cancel-button">
              Cancel
            </button>
            <button
              onClick={mapFacilityToCollateral}
              className="Proceed-button"
              disabled={
                mapDetail.collateralId === 0
                || mapDetail.collateralId === ''
                || mapDetail.facilityId === 0
                || mapDetail.facilityId === ''
              }
            >
              Proceed
            </button>
          </div>
        </Modal>
      ) : (
        ''
      )}

      {mapLoader || collateralLoader || formikLoader ? <Spinner /> : ''}
    </Styled>
  );
}

const Styled = styled.div`
  width: 100%;
  background-color: #e5e5e5;

  .Collateral-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93.6vh;
  }

  .Collateral-holder {
    width: 50%;
    padding: 5rem;
    border: 1px solid #5fb19c;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
      color: #004c4e;
    }
  }

  .Collateral-button {
    padding: 2rem;
    background-color: white;
    border: 2px solid #5fb19c;
    border-radius: 5px;
    box-shadow: 0px 3px 7px 0px rgb(226, 222, 222);
    cursor: pointer;
    color: #004c4e;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s ease-in-out;

    p {
      text-align: center;
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }

  .Collateral-options-holder {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    padding: 4rem 0;
  }

  #modal {
    background-color: white;
    width: 30vw;
  }

  #modal-close {
    color: black;
  }

  .Dropdown {
    padding: 1rem 15rem 1rem 1rem;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid grey;
    margin-top: 1rem;
    outline: none;
    background-color: #eee;
  }

  .Buttons-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  .collateral-names-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Modal-header {
    color: #505050;
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .Proceed-button {
    padding: 1rem 3rem;
    background: linear-gradient(95.26deg, #00989c -30.18%, #004c4e 100%);
    border-radius: 5px;
    color: #d9b08c;
    border: none;
    cursor: pointer;
    margin-left: 5rem;
  }

  .Cancel-button {
    color: #004c4e;
    border: none;
    cursor: pointer;
    background-color: transparent;
  }
`;

export default FacilityCollateralMapping;
