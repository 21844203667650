import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import Layout from 'components/Layouts/BusinessEvaDashboard';
import BackButton from 'components/Buttons/BackButton';
import Button from 'components/Buttons';
import OfferLetterApis from 'apis/offerLetter';
import styled from 'styled-components';
import { asyncWrapper } from 'helpers/asyncWrapper';
import { useReactToPrint } from 'react-to-print';
import { useNavigate, useParams } from 'react-router-dom';
import useNotify from 'hooks/useNotify';
import Spinner from 'components/OverLays/OverLaySpinner';
import errorHandler from 'helpers/errorHandler';

function TitleAndValue({ title, value, currency }) {
  return (
    <div className="flex">
      <div className="w-1/4">
        <p className="mt-4 text-xl pr-4">{title}</p>
      </div>
      <div className="w-3/4">
        {
          currency
            ? (
              <p className="mt-4 text-xl">
                <span>{currency}</span>
                {value}
              </p>
            )
            : <p className="mt-4 text-xl">{value}</p>
        }
      </div>
    </div>
  );
}
function Directors({ directors }) {
  return (
    <div className="flex">
      <div className="w-1/4">
        <p className="mt-4 text-xl pr-4">Directors’ BVN:</p>
      </div>
      <div className="w-3/4">
        {directors?.map((e, i) => <p key={i} className="mt-2 text-xl">{`${e.firstName} ${e.lastName} - ${e.bvn}`}</p>) }
      </div>
    </div>
  );
}
function TermsList({ terms }) {
  return (
    <ol className="ml-5">
      {terms?.map((e, i) => <li key={i} className="mt-4 text-xl">{`${e.termsAndConditionsValue}`}</li>) }
    </ol>
  );
}
function Security({ securities, title }) {
  return (
    <div className="flex">
      <div className="w-1/4">
        <p className="mt-4 text-xl pr-4">{title}</p>
      </div>
      <div className="w-3/4">
        <ol className="ml-5" type="i">
          {securities?.map((e, i) => <li key={i} className="mt-4 text-xl">{`${e.collateralTypeName}`}</li>) }
        </ol>
      </div>
    </div>
  );
}
function NameSignature() {
  return (
    <div className="my-8">
      <p className="mb-2">
        <strong className="text-[#505050] text-2xl">Name:</strong>
        .................................................................................
      </p>
      <p className="mb-2">
        {' '}
        <strong className="text-[#505050] text-2xl">Designation:</strong>
        {' '}
        .................................................................
      </p>
      <p className="mb-2">
        <strong className="text-[#505050] text-2xl">Signature:</strong>
        {' '}
        .......................................................................
      </p>
      <p className="mb-2">
        {' '}
        <strong className="text-[#505050] text-2xl">Date:</strong>
        {' '}
        ....................................................................................
      </p>
    </div>
  );
}

export default function OfferLetterDetails() {
  const docRef = useRef(null);
  const params = useParams();
  const [offerLetterDetails, setOfferLetterDetails] = useState({});
  const [isLoading, setLoader] = useState(false);
  const [ rejectLoader, setRejectLoader] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => docRef.current,
  });
  const navigate = useNavigate();
  const [setNotify] = useNotify();

  useEffect(() => {
    const getFeedBack = async () => {
      setLoader(true);
      const [res, err] = await asyncWrapper(
        OfferLetterApis.getOfferLettersByFacilityId({
          facilityId: params.id,
        })
      );
      setLoader(false);
      setOfferLetterDetails(res?.data?.data);
    };
    getFeedBack();
  }, []);

  const handleAcceptOffer = () => {
    setNotify({
      show: true,
      type: 'info',
      message: 'Proceed to Accept the Offer? <br/> This will download the Offer Letter to enable signatories append their signatures.',
      func: () => handlePrint(),
      btnText: 'Accept'
    });
  };

  const rejectHandler = async () => {
    setRejectLoader(true);
    try{
      const response = await OfferLetterApis.rejectOfferLetter({facilityId: params.id});
      navigate('/app/frr/offer-letter');
    }catch(error){
      errorHandler(error);
    }finally{
      setRejectLoader(false);
    }
  }

  const handleRejectOffer = () => {
    setNotify({
      show: true,
      type: 'info',
      message: 'Are you sure you want to Reject the offer?',
      func: () => rejectHandler(),
      btnText: 'Proceed'
    });
  };

  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5' }}>
        <title>Home | Gazelle by Sterling</title>
      </Helmet>
      <Layout>
        <div className="p-10 h-full">
          <div className="flex items-center">
            <BackButton
              color="#00989C"
              size={15}
            />
            <h4 className="text-primary-900 pl-4 font-normal">Offer Letter</h4>
          </div>
          <div className="w-full max-w-[900px] rounded-2xl border-solid border-[#D1E8E2] border-2 mt-5 mb-4 bg-white p-4 px-8">
            <div className="my-5 flex justify-between w-full ">
              <div>
                <p className="text-xl">
                  Offer Valid till:
                  {' '}
                  {offerLetterDetails?.dbOfferLetter?.offerValidityDate && new Date(offerLetterDetails?.dbOfferLetter?.offerValidityDate).toLocaleDateString()}
                </p>
                <p className="text-xl text-red-400 mt-5">Click on the print icon to print the offer letter</p>
              </div>
              <Button mode="4" title = 'Reject Offer' onClick = {() => handleRejectOffer()} />
              <Button mode="4" title="Upload Signed Offer" onClick={() => navigate(`/app/frr/offer-letter/${offerLetterDetails?.dbOfferLetter?.offerLetterId}/upload`, { replace: true })} />
            </div>
          </div>
          <embed className="w-full h-[100%]" src={offerLetterDetails?.dbOfferLetter?.adminSignedOfferLetterUrl} type="application/pdf" />
        </div>
      </Layout>

      {isLoading || rejectLoader ? <Spinner /> : ''}
    </>
  );
}

