import * as actionTypes from '../action-types/notification';

const initialState = {
  notificationData: [],
  newNotification: false,
  isNotificationLoading: false,
};
export default function auth(state = initialState, { type, payLoad }) {
  switch (type) {
    case actionTypes.SAVE_NOTIFICATION:
      return {
        ...state,
        newNotification: payLoad.newNotification || false,
        notificationData: payLoad.notificationData,
      };
    case actionTypes.SET_LOADING:
      return {
        ...state,
        isNotificationLoading: payLoad,
      };
    default:
      return state;
  }
}
