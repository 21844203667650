import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from 'components/FormikInputs/Input';
import { NextButton } from 'components/Buttons';
import { positionsList } from 'constants/signUp';
import { validationApis } from 'apis';
import { useDispatch, useSelector } from 'react-redux';
import * as SignUpFormActions from 'store/actions/signUpForm';
import { toast } from 'react-toastify';
import ErrorHandler from 'helpers/errorHandler';
import styled from 'styled-components';
import BvnValidation from './BvnValidation/BvnValidation';

const firstValidation = {
  businessPosition: yup.string().required('Business Position is required'),
  customerPhoneNumber: yup.string().required('Phone Number is required'),
  customerEmail: yup.string().email().required('Email is required'),
  customerName: yup.string().required('Name is required'),
};

export default function TellUsAboutYou(props) {
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);

  const { signUpForm } = useSelector((state) => state);
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    ...firstValidation,
  });

  const validateCustomerEmail = async (email) => {
    const res = await validationApis.validateUserEmail(email);
    return res;
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: signUpForm?.aboutYou.businessPosition
      ? signUpForm?.aboutYou
      : {
        businessPosition: '',
        customerPhoneNumber: '',
        customerEmail: '',
        customerName: '',
      },

    validationSchema,

    onSubmit: async (values) => {
      try {
        setLoader(true);
        const res = await validateCustomerEmail(values.customerEmail);
        if (res.status == 200 && res.data.status == false) {
          setUserData(values);
          setLoader(false);
          return openModal();
        } if (res.status == 200 && res.data.status) {
          setLoader(false);
          return toast.error('Email Already Exist');
        }
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    },
  });

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const onBvnValidationHandler = () => {
    closeModal();
    dispatch(SignUpFormActions.SaveAboutYou(userData));
    dispatch(SignUpFormActions.SaveActivePage('business'));
  };

  return (
    <Styled>
      <h2 className="formTitle">Tell Us About You</h2>

      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          name="customerName"
          label="Your Name"
          type="text"
          required
          formik={formik}
        />
        <CustomInput
          name="businessPosition"
          label="Your Position in the Business"
          type="option"
          options={positionsList}
          required
          formik={formik}
        />
        <CustomInput
          name="customerPhoneNumber"
          label="Your Phone Number(Personal)"
          type="intPhone"
          required
          formik={formik}
        />
        <CustomInput
          name="customerEmail"
          label="Email Address(Personal)"
          type="email"
          required
          formik={formik}
        />
        <div className="navButton">
          <NextButton type="submit" mode="3" title="Next" loader={loader} />
        </div>
      </form>

      <BvnValidation
        userData={userData}
        openModal={openModal}
        afterOpenModal={afterOpenModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        onBvnValidationHandler={onBvnValidationHandler}
      />
    </Styled>
  );
}
const Styled = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 5rem;
  margin-top: 15%;

  .navButton {
    display: flex;
    justify-content: flex-end;
  }
  .formTitle {
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 2rem;
    font-weight: 500;
  }
`;
