export const routes = {
  summary: '/app/collateral/new/summary',
  valuer_list: '/app/collateral/new/valuer-list',
  pricing_tbl: '/app/collateral/new/pricing-tbl',
  new_valuation: '/app/collateral/new/new-valuation',
  confirm_val: '/app/collateral/new/confirm-valuation',
  search_fee_payment: '/app/collateral/new/search-fee-payment',
  prop_type: '/app/collateral/new/property-type',
  state_class: '/app/collateral/new/state-class',
  seniority: '/app/collateral/new/seniority',
  start: '/app/collateral/new/start',
  security_value: '/app/collateral/new/security-value',
  collateral_dashboard: '/app/collateral',
  other_questions: '/app/collateral/new/other-questions',
};
