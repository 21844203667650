import {
  useEffect, useRef, useState, useLayoutEffect
} from 'react';
import styled from 'styled-components';
import Header from 'components/Headers/General';
import useSubSectorImage from 'hooks/useSubSectorImage';
import Button, { NextButton, PrevButton } from 'components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import frrApis from 'apis/frr';
import Spinner from 'components/OverLays/OverLaySpinner';
import errorHandler from 'helpers/errorHandler';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import TextInput from '../text-input';
import DropDownInput from '../drop-down-input';
import FRRQuestionBoard from '../question-board';
import FRRTab from '../tab';
import TextArea from '../text-area';
import NumberFormat from 'react-number-format';
import { convertDaysToYears, convertYearsToDays } from './daysConverter';
import { questionIndexDecrementChecker } from './QuestionDecrement';
import { questionCountSetter } from './QuestionCountSetter';
import { dynamicResponsesduplicateRemover } from './DuplicateRemover';
import { populateTranchesDetails } from './PopulateTranchesDetail';

function FRRQuestions() {
  const navigate = useNavigate();
  const [subSectorImage, isImageLoading] = useSubSectorImage();
  const [treatedIndex, setTreatedIndex] = useState([]);
  const [imageLoader, setImageLoader] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [facilityTypes, setFacilityTypes] = useState([]);
  const [isTranchesRemoved, setIsTranchesRemoved] = useState(false);
  const [
    primarySourceOfRepaymentOtherRemoved,
    setPrimarySourceOfRepaymentOtherRemoved,
  ] = useState(false);
  const [
    isAlternativeSourceOfRepaymentRemoved,
    setIsAlternativeSourceOfRepaymentRemoved,
  ] = useState(false);
  const [purposes, setPurposes] = useState([]);
  const [facilityTypesLoader, setFacilityTypesLoader] = useState(false);
  const [purposeGetterLoader, setPurposeGetterLoader] = useState(false);
  const [purposeLoader, setPurposeLoader] = useState(false);
  const [subPurposeLoader, setSubPurposeLoader] = useState(false);
  const [subPurposes, setSubPurposes] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState('');
  const [selectedSubPurpose, setSelectedSubPurpose] = useState('');
  const [primarySourceOfRepaymentOther, setPrimarySourceOfRepaymentOther] = useState(false);
  const [isTranches, setIsTranches] = useState(false);
  const [isAlternativeSourceOfRepayment, setIsAlternativeSourceOfRepayment] = useState(false);
  const [isCashFlowMultipleClient, setIsCashFlowMultipleClient] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [skippedIndexes, setSkippedIndexes] = useState(5);
  const [currencies, setCurrencies] = useState([]);
  const [currenciesLoader, setCurrenciesLoader] = useState(false);
  const [FRRQuestionsLoader, setFRRQuestionsLoader] = useState(false);
  const [questionBank, setQuestionBank] = useState([]);
  const [answerLoader, setAnswerLoader] = useState(false);
  const [frrAnswers, setfrrAnswers] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [frrResponseLoader, setFrrResponseLoader] = useState(false);
  const [counterParties, setCounterParties] = useState([]);
  const [newParty, setNewParty] = useState('');
  const [partyRemoved, setPartyRemoved] = useState(false);
  const [counterParty, setCounterParty] = useState('');
  const [party, setParty] = useState('');
  const [facilityDuration, setFacilityDuration] = useState({
    years: '',
    months: '',
    days: '',
  });
  const [facilityLoader, setFacilityLoader] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [showedPurpose, setShowedPurpose] = useState('');
  const [showedSubPurpose, setShowedSubPurpose] = useState('');
  const [showedFacility, setShowedFacility] = useState('');
  const [unWeightedAnswer, setUnWeightedAnswer] = useState('');
  const [frrAnswerId, setFrrAnswerId] = useState('');
  const [questionLength, setQuestionLength] = useState(0);
  const [lastQuestion, setLastQuestion] = useState(false);
  const [answeredPurposeQuestions, setAnsweredPurposeQuestions] = useState([]);
  const [questionResponse, setQuestionResponse] = useState('');
  const [
    selectedAlternativeSourceOfRepayment,
    setSelectedAlternativeSourceOfRepayment,
  ] = useState('');
  const [
    selectedMutipleCounterPartyOption,
    setSelectedMultipleCounterPartyOption,
  ] = useState('');
  const [tranchesDetail, setTranchesDetail] = useState({
    numberOfTranches: '',
    amount: '',
    frequency: '',
    date: '',
  });
  const [answeredGeneralQuestions, setAnsweredGeneralQuestions] = useState([]);
  const [answeredFrrResponses, setAnsweredFrrResponses] = useState([]);
  const [frrQuestions, setFrrQuestions] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [currentFrrResponseId, setCurrentFrrResponseId] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(questionIndex + 1);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [checkboxChange, setCheckboxChange] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [filteredFrrResponses, setFilteredFrrResponses] = useState([]);
  const [ secondaryQuestions, setSecondaryQuestions ] = useState([]);

  const businessId = useSelector(
    (state) => state?.business?.businessProfiles[0].businessId
  );
  const filledData = useSelector(
    (state) => state?.facilityAnsweredQuestions.facilityfilledData
  );

  const [ convertedYears, convertedMonths, convertedDays ] = convertDaysToYears(filledData);

  useEffect( () => {
      setFacilityDuration({
        ...facilityDuration, years: convertedYears, months: convertedMonths, days: convertedDays
      });
  }, [convertedDays, convertedMonths, convertedYears])

  const formik = useFormik({
    initialValues: {
      facilityId: Object.keys(filledData).length !== 0 ? filledData.facilityId : 0,
      facilityTypeId: Object.keys(filledData).length !== 0 ? filledData?.facilityType?.facilityTypeId : 0,
      subPurposeId: Object.keys(filledData).length > 0 ? filledData.subPurposeId : 0,
      facilityAmount: Object.keys(filledData).length !== 0 ? filledData.facilityAmount : '',
      repaymentFrequency: Object.keys(filledData).length !== 0 ? filledData.repaymentFrequency : '',
      currencyId: Object.keys(filledData).length !== 0 ? filledData.currencyId : null,
      facilityDuration: Object.keys(filledData).length !== 0 ? filledData.facilityDuration : 0,
      justification: Object.keys(filledData).length !== 0 ? filledData.justification : '',
      isAlternativeSourceOfRepayment: Object.keys(filledData).length !== 0 ? filledData.isAlternativeSourceOfRepayment : false,
      equityContribution: '',
      repaymentSource: Object.keys(filledData).length !== 0 ? filledData.repaymentSource : '',
      facilitySeniority: Object.keys(filledData).length !== 0 ? filledData.facilitySeniority : '',
      counterPartyName: Object.keys(filledData).length !== 0 ? filledData.counterPartyName : '',
      alternativeSourceOfRepayment: Object.keys(filledData).length !== 0 ? filledData.alternativeSourceOfRepayment : '',
      primarySourceOfRepayment: Object.keys(filledData).length !== 0 ? filledData.primarySourceOfRepayment : '',
      otherPrimarySourceOfRepayment: Object.keys(filledData).length !== 0 ? filledData.otherPrimarySourceOfRepayment : '',
      facilityRequestStructure: Object.keys(filledData).length !== 0 ? filledData.facilityRequestStructure : '',
      disbursementMode: Object.keys(filledData).length !== 0 ? filledData.disbursementMode : '',
      disbursementModeTranches: Object.keys(filledData).length !== 0 ? filledData.disbursementModeTranches : '',
      repaymentCashFlow: Object.keys(filledData).length !== 0 ? filledData.repaymentCashFlow : '',
      status: Object.keys(filledData).length !== 0 && filledData.status === 'completed' ? 'completed' : 'initiated',
      businessId,
    },

    onSubmit: (values) => {
      if (values.facilityId !== 0) {
        updateFacility(values);
      }

      if (formik.values.facilityId !== 0 && frrResponseData.length > 0) {
        postFrrResponse();
      }
    },
  });

  const inputRef = useRef();
  const dateInputRef = useRef();
  const textInputRef = useRef();
  const dispatch = useDispatch();

  const facilityQuestions = useSelector(
    (state) => state.facilitySubPurposeReducer
  );
  const { facilityPurposeQuestions } = facilityQuestions;
  const facilitySubPurposeQuestions = facilityQuestions.facilitySubPurposefrrQuestions;
  const facilityGeneralQuestions = facilityQuestions.facilityGeneralfrrQuestions;
  const currentQuestion = questionBank[questionIndex];

  // Converting facility duration from years, months and days to only days.
  const yearsConverter = () => {
    const days = convertYearsToDays(facilityDuration);
    formik.setFieldValue('facilityDuration', days);
    setQuestionResponse(`${days}days`);
  };

  // Concatenations are done in this function
  const concatenateTranchesHandler = () => {
    const concatenatedTranchesDetail = tranchesDetail.numberOfTranches
      .concat(';')
      .concat(tranchesDetail.frequency)
      .concat(';')
      .concat(tranchesDetail.date)
      .concat(';')
      .concat(tranchesDetail.amount);
    formik.setFieldValue(
      'disbursementModeTranches',
      concatenatedTranchesDetail
    );

    // if(currentQuestion?.frrQuestion.toLowerCase().includes('Kindly indicate details of tranches')){
    //     setQuestionResponse(concatenatedTranchesDetail);
    // }
  };

  const postFrrResponse = async () => {
    setFrrResponseLoader(true);
    try {
      const response = await frrApis.postFrrResponse(filteredFrrResponses);
      if (saveAndExit) {
        navigate('/app/frr/facilityevaluation');
      } else if (!saveAndExit) {
        navigate('/app/frr/facilityquestionsreview');
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setFrrResponseLoader(false);
    }
  };

  const [frrResponseData, setFrrResponseData] = useState([]);

  // This is to post facility while the user is filling the form to get facility Id for the frr responses api.
  const postInitialFacility = async (data) => {
    setFacilityLoader(true);
    try {
      const response = await frrApis.postFacility(data);
      formik.setFieldValue('facilityId', response.data.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setFacilityLoader(false);
    }
  };

  // This is to update facility
  const updateFacility = async (data) => {
    setFacilityLoader(true);
    try {
      const response = await frrApis.updateFacility(data);
      postFrrResponse();
    } catch (error) {
      errorHandler(error);
    } finally {
      setFacilityLoader(false);
    }
  };

  useLayoutEffect(() => {
    setCurrentIndex(questionIndex + 1);
  }, [questionIndex]);

  useLayoutEffect(() => {
    const purposeQuestions = [];

    for (let i = 0; i < facilityPurposeQuestions.length; i++) {
      if (questionBank.length < 1) {
        purposeQuestions.push(facilityPurposeQuestions[i]);
      }
    }

    setQuestionBank(purposeQuestions);
  }, []);

  useEffect(() => {
    const getCurrencies = async () => {
      setCurrenciesLoader(true);

      try {
        const response = await frrApis.getCurrencyCodes();
        setCurrencies(response.data.data);

        // This is for setting default currency code.
        for (let i = 0; i < response.data.data.length; i++) {
          if (
            response.data.data[i].currencyCode.toLowerCase() === 'ngn'
            && selectedCurrency === ''
          ) {
            setSelectedCurrency('NGN');
            formik.setFieldValue(
              'currencyId',
              Number(response.data.data[i].currencyId)
            );
          }
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setCurrenciesLoader(false);
      }
    };

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('how much do you need')
      || currencies.length < 1
    ) {
      getCurrencies();
    }
  }, [questionIndex]);

  useEffect(() => {
    // Restoring filled values for when the user presses the back button
    // There is no need writing for values that are available in formik because formik will automatically keep it.
    const checkIfQuestionIsTreated = () => {
      for (let i = 0; i < treatedIndex.length; i++) {
        if (treatedIndex[i] === questionIndex) {
        }
      }
    };

    checkIfQuestionIsTreated();
  }, [treatedIndex, questionIndex, currentQuestion?.frrQuestion]);

  useLayoutEffect(() => {
    setQuestionLength(questionBank.length);
  }, [questionBank.length]);

  // Getting facility types
  useEffect(() => {
    const getFacilityTypes = async () => {
      setFacilityTypesLoader(true);

      try {
        const response = await frrApis.getFacilityTypes();
        const { data } = response.data;
        const sortedData = data.sort((a, b) => {
          const textA = a.facilityTypeName.toUpperCase();
          const textB = b.facilityTypeName.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        setFacilityTypes(sortedData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setFacilityTypesLoader(false);
      }
    };

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly select facility type')
    ) {
      getFacilityTypes();
    }
  }, [questionIndex]);

  // Get purpose id using subpurpose id
  useLayoutEffect(() => {

    // if (Object.keys(filledData).length !== 0) {
    //   const { purposeId } =  useQuery(filledData.subPurposeId, getPurposeId);
    // }

    const getPurposeBySubPurposeId = async () => {
      setPurposeGetterLoader(true);
      try {
        const response = await frrApis.getPurposeBySubPurposeId(
          filledData.subPurposeId
        );
        setSelectedPurpose(response.data.data.p.purposeId);
      } catch (error) {
        errorHandler(error);
      } finally {
        setPurposeGetterLoader(false);
      }
    };

    if (Object.keys(filledData).length !== 0) {
      getPurposeBySubPurposeId();
    }
  }, []);

  useLayoutEffect(() => {
    const getFacilityPurpose = async () => {
      setPurposeLoader(true);

      try {
        const response = await frrApis.getFacilityPurpose();
        setPurposes(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setPurposeLoader(false);
      }
    };

    getFacilityPurpose();
  }, [selectedPurpose]);

  // Getting facility sub purpose
  useLayoutEffect(() => {
    const getSubPurposeHandler = async () => {
      setSubPurposeLoader(true);

      try {
        const response = await frrApis.getFacilitySubPurposeByPurposeId(
          selectedPurpose
        );
        for (const data of response.data.data) {
          if (filledData.subPurposeId === data.subPurposeId) {
            setShowedSubPurpose(data.subPurposeName);
          }
        }
        setSubPurposes(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setSubPurposeLoader(false);
      }
    };

    if (
      (Object.keys(filledData).length > 0
      && subPurposes.length < 1
      && selectedPurpose !== '') || selectedPurpose !== ''
    ) {
      getSubPurposeHandler();
    }
  }, [selectedPurpose]);

  // For skipping a question when primary source of repayment when the option is not "Others" or not.
  useLayoutEffect(() => {
    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('provide details of a primary source of payment')
      && primarySourceOfRepaymentOther === false
    ) {
      setQuestionIndex(questionIndex + 1);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('provide details of a primary source of payment')
      && primarySourceOfRepaymentOther === true
    ) {

    }
  }, [questionIndex]);

  // For skipping a question when there is an alternative source of repayment or not.
  useLayoutEffect(() => {
    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('provide details of the alternative source of payment')
      && isAlternativeSourceOfRepayment === false
    ) {
      setQuestionIndex(questionIndex + 1);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('provide details of an alternative source of payment')
      && isAlternativeSourceOfRepayment === true
    ) {

    }
  }, [questionIndex]);

  // For skipping a question when disburement mode is not tranches
  useLayoutEffect(() => {
    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
      && isTranches === false
    ) {
      setQuestionIndex(questionIndex + 1);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
      && isTranches === true
    ) {

    }
  }, [questionIndex]);

  // For skipping a question when cash flow is from a single client
  useLayoutEffect(() => {
    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
      && isCashFlowMultipleClient === true
    ) {
      setQuestionIndex(questionIndex + 1);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
      && isCashFlowMultipleClient === false
    ) {
      setDisableNextButton(true);
    }
  }, [questionIndex]);

  useEffect(() => {
    const getFRRQuestions = async () => {
      setFRRQuestionsLoader(true);

      dispatch({ type: 'EMPTY_FRR_QUESTIONS' });

      try {
        const response = await frrApis.getFacilitySubPurposeQuestions(
          selectedSubPurpose
        );
        dispatch({ type: 'UPDATE_FRR_QUESTIONS', payload: response.data.data });
      } catch (error) {
        errorHandler(error);
      } finally {
        setFRRQuestionsLoader(false);
      }
    };

    if (selectedSubPurpose !== '') {
      getFRRQuestions();
    }
  }, [selectedSubPurpose]);

  useEffect(() => {
    if (filledData.shouldUpdate) {
      setQuestionBank([]);
      setQuestionBank(() => facilityPurposeQuestions.concat(facilityGeneralQuestions));
    }

    if (!filledData.shouldUpdate) {
      setQuestionBank([]);
      setQuestionBank(() => facilityPurposeQuestions
        .concat(facilitySubPurposeQuestions)
        .concat(facilityGeneralQuestions));
    }
  }, [facilitySubPurposeQuestions]);

  useLayoutEffect(() => {
    const getfrrAnswers = async () => {
      setAnswerLoader(true);

      try {
        const response = await frrApis.getFacilityQuestionAnswers(
          currentQuestion?.frrQuestionId
        );
        setfrrAnswers(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setAnswerLoader(false);
      }
    };

    if (
      (currentQuestion?.questionType.toLowerCase().includes('single')
        || currentQuestion?.questionType.toLowerCase().includes('multiple')
        || currentQuestion?.questionType.toLowerCase().includes('dropdown'))
      && (currentQuestion?.questionClass.toLowerCase() === 'p'
        || currentQuestion?.questionClass.toLowerCase() === 's')
    ) {
      getfrrAnswers();
    }
  }, [questionIndex]);

  const addGeneralQuestion = () => {
    const generalResponse = {
      question: currentQuestion?.frrQuestion,
      response: questionResponse,
    };

    for (let i = 0; i < answeredGeneralQuestions.length; i++) {
      if (
        answeredGeneralQuestions[i].question === generalResponse.question
        && questionResponse !== ''
        && currentQuestion?.questionClass.toLowerCase() === 'general'
      ) {
        answeredGeneralQuestions.splice(i, 1);
      }
    }

    setAnsweredGeneralQuestions([...answeredGeneralQuestions, generalResponse]);
  };

  const questionIndexIncrementHandler = () => {
    if (questionBank.length > questionIndex) {
      setQuestionIndex(questionIndex + 1);
    }


    setTreatedIndex([...treatedIndex, questionIndex]);

    const newFrrResponse = {
      businessId,
      frrQuestionId: currentQuestion?.frrQuestionId,
      facilityId: formik.values.facilityId,
      frrAnswerId: frrAnswerId !== '' ? frrAnswerId?.toString() : '',
      unWeightedAnswer,
      frrResponseId: Number(currentFrrResponseId),
    };

    for (let i = 0; i < frrResponseData.length; i++) {
      if (
        frrResponseData[i].frrQuestionId === currentQuestion?.frrQuestionId
        && (frrAnswerId !== '' || unWeightedAnswer !== '')
      ) {
        frrResponseData.splice(i, 1);
      }
    }

    if (
      (currentQuestion?.questionClass.toLowerCase() === 'p'
        || currentQuestion?.questionClass.toLowerCase() === 's')
      && (frrAnswerId !== '' || unWeightedAnswer !== '')
    ) {
      setFrrResponseData([...frrResponseData, newFrrResponse]);
    }

    const purposeResponse = {
      question: currentQuestion?.frrQuestion,
      response: questionResponse,
    };

    // Removing duplicate from general questions
    for (let i = 0; i < answeredPurposeQuestions.length; i++) {
      if (
        answeredPurposeQuestions[i].question === purposeResponse.question
        && questionResponse !== ''
        && currentQuestion?.questionClass.toLowerCase() === 'purpose'
      ) {
        answeredPurposeQuestions.splice(i, 1);
      }
    }

    if (currentQuestion?.questionClass.toLowerCase() === 'purpose') {
      setAnsweredPurposeQuestions([
        ...answeredPurposeQuestions,
        purposeResponse,
      ]);
    }

    if (currentQuestion?.questionClass.toLowerCase() === 'general') {
      addGeneralQuestion();
    }

    if (
      currentQuestion?.questionType.toLowerCase() === 'text'
      && (currentQuestion?.questionClass.toLowerCase() === 'p'
        || currentQuestion?.questionClass.toLowerCase() === 's'
        || currentQuestion?.questionClass.toLowerCase() === 'general')
      && !currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
    ) {
      textInputRef.current.value = '';
    }

    if (currentQuestion?.questionType.toLowerCase().includes('date')) {
      dateInputRef.current.value = '';
    }

    setUnWeightedAnswer('');
    setFrrAnswerId('');
    setQuestionResponse('');
    setCheckboxValues([]);

  };

  const questionIndexDecrementHandler = () => {

    const value = questionIndexDecrementChecker(questionIndex, currentQuestion, primarySourceOfRepaymentOther, isAlternativeSourceOfRepayment, isTranches, isCashFlowMultipleClient);

    if (questionIndex > 0) setQuestionIndex(questionIndex - 1)

    // Decrementing and skipping a question that was initially skipped
    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('is there an alternative source of repayment')
      && primarySourceOfRepaymentOther === false
    ) {
      setQuestionIndex(questionIndex - 2);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('is there an alternative source of repayment')
      && primarySourceOfRepaymentOther === true
    ) {
      setQuestionIndex(questionIndex - 1);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
          'which of these best describes the structure of the facility request'
        )
      && isAlternativeSourceOfRepayment === false
    ) {
      setQuestionIndex(questionIndex - 2);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
          'which of these best describes the structure of the facility request'
        )
      && isAlternativeSourceOfRepayment === true
    ) {
      setQuestionIndex(questionIndex - 1);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
          'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
      && isTranches === false
    ) {
      setQuestionIndex(questionIndex - 2);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
          'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
      && isTranches === true
    ) {
      setQuestionIndex(questionIndex - 1);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
      && isCashFlowMultipleClient === true
    ) {
      setQuestionIndex(questionIndex - 2);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
      && isCashFlowMultipleClient === false
    ) {
      setQuestionIndex(questionIndex - 1);
    }

    setDisableNextButton(false);
    setUnWeightedAnswer('');
    setFrrAnswerId('');
    setQuestionResponse('');
    setCheckboxValues([]);
  };

  // These lines of code is for validating that the form has been filled before moving to the next question
  useLayoutEffect(() => {
    // This is for setting the currency code on the page
    for (let i = 0; i < currencies.length; i++) {
      if (currencies[i].currencyId === formik.values.currencyId) {
        setSelectedCurrency(currencies[i].currencyCode);
      }

      if (
        currencies[i].currencyId === filledData.currencyId
        && Object.keys(filledData).length > 0
      ) {
        setSelectedCurrency(currencies[i].currencyCode);
      }
    }

    for (let i = 0; i < purposes.length; i++) {
      if (purposes[i].purposeId == selectedPurpose) {
        setShowedPurpose(purposes[i].purposeName);
      }
    }

    for (let i = 0; i < subPurposes.length; i++) {
      if (subPurposes[i].subPurposeId == selectedSubPurpose) {
        setShowedSubPurpose(subPurposes[i].subPurposeName);
      }
    }

    for (let i = 0; i < facilityTypes.length; i++) {
      if (facilityTypes[i].facilityTypeId == formik.values.facilityTypeId) {
        setShowedFacility(facilityTypes[i].facilityTypeName);
      }
    }

    if (
      formik.values.repaymentFrequency.length < 1 && currentQuestion?.frrQuestion
      .toLowerCase()
      .includes('how much do you need')
    ) {
      if (
        formik.values.facilityAmount.toString().length < 7
        || formik.values.currencyId === null
        || formik.values.currencyId === 0
      ) {
        setDisableNextButton(true);
      } else {
        setDisableNextButton(false);
      }
    }

    if(currentQuestion?.frrQuestion
      .toLowerCase()
      .includes('what is your preferred repayment frequency')
      ){
        if (
          formik.values.repaymentFrequency.length < 1
        ) {
          setDisableNextButton(true);
        } else {
          setDisableNextButton(false);
        }
      }
   

    if(currentQuestion?.frrQuestion
      .toLowerCase()
      .includes('how long do you need this plan for')
      ){
        if (
          facilityDuration.years === ''
            || facilityDuration.months === ''
            || facilityDuration.days === ''
            || ( 
               facilityDuration.years === 0
              && facilityDuration.months === 0
              && facilityDuration.days === 0
            )
        ) {
          setDisableNextButton(true);
        } else if (
          facilityDuration.years !== ''
            || facilityDuration.months !== ''
            || facilityDuration.days !== ''
        ) {
          setDisableNextButton(false);
          yearsConverter();
        }
      }
    

    if (
      (formik.values.subPurposeId === null
        || formik.values.subPurposeId === 0
        || formik.values.subPurposeId === '')
      && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('why do you need the facility')
      && showedSubPurpose === ''
    ) {
      setDisableNextButton(true);
    } else if (
      (formik.values.subPurposeId !== null
        || formik.values.subPurposeId !== 0
        || formik.values.subPurposeId !== '')
      && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('why do you need the facility')
      && showedSubPurpose !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      (formik.values.facilityTypeId === 0
        || formik.values.facilityTypeId === null)
      && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly select facility type')
    ) {
      setDisableNextButton(true);
    } else if (
      (formik.values.facilityTypeId !== 0
        || formik.values.facilityTypeId !== null)
      && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly select facility type')
    ) {
      setDisableNextButton(false);
    }

    if (
      (frrAnswerId === '' || unWeightedAnswer === '')
      && (currentQuestion?.questionClass.toLowerCase() === 'p'
        || currentQuestion?.questionClass.toLowerCase() === 's')
    ) {
      setDisableNextButton(true);
    }

    if (
      (unWeightedAnswer !== '' || frrAnswerId !== '')
      && (currentQuestion?.questionClass.toLowerCase() === 'p'
        || currentQuestion?.questionClass.toLowerCase() === 's')
    ) {
      setDisableNextButton(false);
    }

    if (
      tranchesDetail.numberOfTranches !== ''
      && tranchesDetail.amount !== ''
      && tranchesDetail.frequency !== ''
      && tranchesDetail.date !== ''
    ) {
      concatenateTranchesHandler();
    }

    if (
      formik.values.disbursementModeTranches !== ''
      && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
    ) {
      setQuestionResponse(formik.values.disbursementModeTranches);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('justification for the facility')
      && formik.values.justification === ''
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('justification for the facility')
      && formik.values.justification !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
          'which of these best describes this facility in the order of seniority'
        )
      && formik.values.facilitySeniority === ''
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
          'which of these best describes this facility in the order of seniority'
        )
      && formik.values.facilitySeniority !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('what is the primary source of repayment for the facility')
      && formik.values.primarySourceOfRepayment === ''
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('what is the primary source of repayment for the facility')
      && formik.values.primarySourceOfRepayment !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('provide details of a primary source of payment')
      && formik.values.otherPrimarySourceOfRepayment === ''
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('provide details of a primary source of payment')
      && formik.values.otherPrimarySourceOfRepayment !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('is there an alternative source of repayment')
      && formik.values.isAlternativeSourceOfRepayment === ''
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('is there an alternative source of repayment')
      && formik.values.isAlternativeSourceOfRepayment !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('provide details of the alternative source of payment')
      && formik.values.alternativeSourceOfRepayment === ''
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('provide details of the alternative source of payment')
      && formik.values.alternativeSourceOfRepayment !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
          'which of these best describes the structure of the facility request'
        )
      && formik.values.facilityRequestStructure === ''
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
          'which of these best describes the structure of the facility request'
        )
      && formik.values.facilityRequestStructure !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('what is the preferred disbursement mode')
      && formik.values.disbursementMode === ''
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('what is the preferred disbursement mode')
      && formik.values.disbursementMode !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
      && (tranchesDetail.numberOfTranches === ''
        || tranchesDetail.frequency === ''
        || tranchesDetail.amount === ''
        || tranchesDetail.date === '')
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
      && tranchesDetail.numberOfTranches !== ''
      && tranchesDetail.frequency !== ''
      && tranchesDetail.amount !== ''
      && tranchesDetail.date !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
          'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
      && formik.values.repaymentCashFlow === ''
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
          'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
      && formik.values.repaymentCashFlow !== ''
    ) {
      setDisableNextButton(false);
    }

    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
      && selectedMutipleCounterPartyOption === ''
    ) {
      setDisableNextButton(true);
    } else if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
      && selectedMutipleCounterPartyOption !== ''
    ) {
      setDisableNextButton(false);
    }
  }, [
    formik.values.facilityAmount, questionIndex, formik.values.currencyId, selectedCurrency, facilityDuration.years, facilityDuration.months,
    facilityDuration.days, formik.values.subPurposeId, selectedPurpose, showedSubPurpose, formik.values.facilityTypeId, frrAnswerId,
    unWeightedAnswer, currentQuestion?.questionId, businessId, tranchesDetail.numberOfTranches, tranchesDetail.amount, tranchesDetail.date,
    tranchesDetail.frequency, formik.values.disbursementModeTranches, questionResponse, formik.values.justification, tranchesDetail.amount,
    tranchesDetail.date, tranchesDetail.frequency, tranchesDetail.numberOfTranches, formik.values.isAlternativeSourceOfRepayment, formik.values.alternativeSourceOfRepayment,
    formik.values.disbursementMode, formik.values.otherPrimarySourceOfRepayment, formik.values.primarySourceOfRepayment, formik.values.facilitySeniority,
    formik.values.facilityRequestStructure, formik.values.repaymentCashFlow, formik.values.repaymentFrequency, Object.keys(filledData).length,
  ]);

  const primarySourceOfRepaymentOtherHandler = (value) => {
    if (primarySourceOfRepaymentOther === true && value !== 'Others') {
      setPrimarySourceOfRepaymentOther(false);
      setSkippedIndexes(skippedIndexes + 1);
    }

    if (primarySourceOfRepaymentOther === false && value === 'Others') {
      setPrimarySourceOfRepaymentOther(true);
      setSkippedIndexes(skippedIndexes - 1);
      setPrimarySourceOfRepaymentOtherRemoved(false);
    }
  };

  const alternativeSourceOfRepaymentHandler = (value) => {
    if (
      isAlternativeSourceOfRepayment === true
      && value.toLowerCase() !== 'yes'
    ) {
      setSkippedIndexes(skippedIndexes + 1);
      setIsAlternativeSourceOfRepayment(false);
      setIsAlternativeSourceOfRepaymentRemoved(false);
    }

    if (
      isAlternativeSourceOfRepayment === false
      && value.toLowerCase() !== 'no'
    ) {
      setIsAlternativeSourceOfRepayment(true);
      setSkippedIndexes(skippedIndexes - 1);
    }
  };

  const tranchesHandler = (value) => {
    if (isTranches === true && value.toLowerCase() !== 'tranches') {
      setIsTranches(false);
      setSkippedIndexes(skippedIndexes + 1);

      // This will reset the formik tranches value if disbursement mode changes to lumpsum
      formik.setFieldValue('disbursementModeTranches', '');
      setTranchesDetail({
        ...tranchesDetail,
        numberOfTranches: '',
        date: '',
        amount: '',
        frequency: '',
      });

      for (let i = 0; i < answeredGeneralQuestions.length; i++) {
        if (
          answeredGeneralQuestions[i].question
            .toLowerCase()
            .includes('kindly indicate details of tranches')
        ) {
          answeredGeneralQuestions.splice(i, 1);
        }
      }
    }

    if (isTranches === false && value.toLowerCase().includes('tranches')) {
      setIsTranches(true);
      setSkippedIndexes(skippedIndexes - 1);
      setIsTranchesRemoved(false);
    }
  };

  const cashFlowMultipleClientHandler = (value) => {
    if (
      isCashFlowMultipleClient === false
      && value.toLowerCase() === 'multiple'
    ) {
      setIsCashFlowMultipleClient(true);
      setSkippedIndexes(skippedIndexes - 1);
      setCounterParty('');
      formik.setFieldValue('counterPartyName', '');
    }

    if (isCashFlowMultipleClient === true && value.toLowerCase() === 'single') {
      setIsCashFlowMultipleClient(false);
      setSkippedIndexes(skippedIndexes + 1);
      setCounterParties([]);
      formik.setFieldValue('counterPartyName', '');
    }
  };

  // This will show the save and proceed button conditionally
  useLayoutEffect(() => {
    if (
      currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
      || currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('list names of counterparties')
    ) {
      setLastQuestion(true);
    }

    if (
      !currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
      && !currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('list names of counterparties')
    ) {
      setLastQuestion(false);
    }
  }, [questionIndex]);

  useLayoutEffect(() => {
    // These lines are removing some conditional questions when the conditions change

    for (let i = 0; i < answeredGeneralQuestions.length; i++) {
      if (isCashFlowMultipleClient) {
        if (
          answeredGeneralQuestions[i].question
            .toLowerCase()
            .includes('indicate the name of the counterparty')
        ) {
          answeredGeneralQuestions.splice(i, 1);
        }
      }

      if (!isCashFlowMultipleClient) {
        if (
          answeredGeneralQuestions[i]?.question
            .toLowerCase()
            .includes('list names of counterparties')
        ) {
          answeredGeneralQuestions.splice(i, 1);
        }

        if (
          answeredGeneralQuestions[i]?.question
            .toLowerCase()
            .includes('select multiple counterparty option')
        ) {
          answeredGeneralQuestions.splice(i, 1);
        }
      }

      if (!isTranches) {
        if (
          answeredGeneralQuestions[i]?.question
            .toLowerCase()
            .includes('kindly indicate details of tranches')
        ) {
          answeredGeneralQuestions.splice(i, 1);
        }
      }

      if (!isAlternativeSourceOfRepayment) {
        if (
          answeredGeneralQuestions[i]?.question
            .toLowerCase()
            .includes('provide details of the alternative source of payment')
        ) {
          answeredGeneralQuestions.splice(i, 1);
        }
      }

      if (!primarySourceOfRepaymentOther) {
        if (
          answeredGeneralQuestions[i]?.question
            .toLowerCase()
            .includes('provide details of a primary source of payment')
        ) {
          answeredGeneralQuestions.splice(i, 1);
        }
      }

      // This will remove any reoccuring duplicate question with blank responses

      if (answeredGeneralQuestions[i]?.response === '') {
        answeredGeneralQuestions.splice(i, 1);
      }
    }

    for (let i = 0; i < answeredPurposeQuestions.length; i++) {
      // This will remove any reoccuring duplicate question with blank responses

      if (answeredPurposeQuestions[i].response === '') {
        answeredPurposeQuestions.splice(i, 1);
      }
    }
  }, [
    questionIndex,
    answeredGeneralQuestions.length,
    answeredPurposeQuestions.length,
  ]);

  // This is setting the answer of subpurpose to the actual text rather than id because of the review page.
  useLayoutEffect(() => {
    for (let i = 0; i < subPurposes.length; i++) {
      if (Number(selectedSubPurpose) === subPurposes[i].subPurposeId) {
        setQuestionResponse(subPurposes[i].subPurposeName);
      }
    }
  }, [selectedSubPurpose]);

  useLayoutEffect(() => {
    for (let i = 0; i < facilityTypes.length; i++) {
      if (Number(showedFacility) === facilityTypes[i].facilityTypeId) {
        setQuestionResponse(facilityTypes[i].facilityTypeName);
      }
    }
  }, [showedFacility]);

  // This will get all frr responses tied to the facility.
  useLayoutEffect(() => {
    const newFrrResponse = [];
    const getFrrResponses = async () => {
      setFrrResponseLoader(true);
      try {
        const response = await frrApis.getFrrResponse(filledData.facilityId);
        setAnsweredFrrResponses(response.data.data);

        // This is populated frr response data of the previously answered questions by the user
        for (const data of response.data.data) {
          const myData = {
            businessId,
            facilityId: filledData.facilityId,
            frrAnswerId: data.frrAnswerId,
            frrQuestionId: data.frrQuestionId,
            unWeightedAnswer: data.unWeightedAnswer,
            frrResponseId: data.frrResponseId,
          };
          newFrrResponse.push(myData);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setFrrResponseLoader(false);
      }
    };

    setFrrResponseData(newFrrResponse);

    if (Object.keys(filledData).length > 0) {
      getFrrResponses();
    }
  }, [Object.keys(filledData).length]);

  // This will load frr questions based on the sub purpose from the continue data.
  useLayoutEffect(() => {
    const getFRRQuestions = async () => {
      setFRRQuestionsLoader(true);
      try {
        const response = await frrApis.getFacilitySubPurposeQuestions(
          filledData.subPurposeId
        );
        setFrrQuestions(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setFRRQuestionsLoader(false);
      }
    };

    if (Object.keys(filledData).length > 0) {
      getFRRQuestions();
    }
  }, [filledData?.subPurposeId]);

  useLayoutEffect(() => {
    const newQuestions = [];

    if (answeredFrrResponses?.length > 0 && frrQuestions?.length > 0) {
      frrQuestions.map((question) => {
        for (const response of answeredFrrResponses) {
          if (
            question.frrQuestionId === response.frrQuestionId
            && (response.frrAnswerId !== '' || response.unWeightedAnswer !== '')
          ) {
            const newData = {
              frrAnswerId: response.frrAnswerId,
              unWeightedAnswer: response.unWeightedAnswer,
              documentName: question.documentName,
              facilitySubPurposeTbl: question.facilitySubPurposeTbl,
              followUpTrigger: question.followUpTrigger,
              followupId: question.followupId,
              frrQuestion: question.frrQuestion,
              frrQuestionId: question.frrQuestionId,
              identifier: question.identifier,
              isDeleted: question.isDeleted,
              isDisabled: question.isDisabled,
              isRequired: question.isRequired,
              isWeighted: question.isWeighted,
              questionClass: question.questionClass,
              questionType: question.questionType,
              score: question.score,
              sequenceId: question.sequenceId,
              subPurposeId: question.subPurposeId,
            };
            newQuestions.push(newData);
          }
        }
      });
    }

    if (newQuestions.length > 0) {
      frrQuestions.map((question, index) => {
        for (const newQuestion of newQuestions) {
          if (question.frrQuestionId === newQuestion.frrQuestionId) {
            frrQuestions.splice(index, 1, newQuestion);
          }
        }
      });
    }

    dispatch({ type: 'UPDATE_FRR_QUESTIONS', payload: frrQuestions });
  }, [answeredFrrResponses.length, frrQuestions.length]);

  // This is where the frr responses are being auto filled for the ones that have been previously answered
  useLayoutEffect(() => {
    if (
      currentQuestion?.questionClass.toLowerCase() === 'p'
      || currentQuestion?.questionClass.toLowerCase() === 's'
    ) {
      for (const response of frrResponseData) {
        if (
          response.frrQuestionId === currentQuestion?.frrQuestionId
          && response.frrAnswerId !== ''
        ) {
          setCurrentAnswer(Number(response.frrAnswerId));
          setDisableNextButton(false);
        } else if (
          response.frrQuestionId === currentQuestion?.frrQuestionId
          && response.unWeightedAnswer !== ''
        ) {
          setCurrentAnswer(response.unWeightedAnswer.toString());
          setUnWeightedAnswer(response.unWeightedAnswer.toString());
          setDisableNextButton(false);
        }

        if (
          currentQuestion?.questionType.toLowerCase() === 'multiple'
          && response.frrQuestionId === currentQuestion?.frrQuestionId
          && response.frrAnswerId !== ''
        ) {
          const splittedFrrIds = response.frrAnswerId.split(',');

          for (let i = 0; i < splittedFrrIds.length; i++) {
            if (splittedFrrIds[i] === '') {
              splittedFrrIds.splice(i, 1);
            }
          }
          setCheckboxValues(splittedFrrIds);
          setDisableNextButton(false);
        }
      }
    }
  }, [questionIndex]);

  // This is making the current frr Response id made available to the frrResponse being updated.
  useLayoutEffect(() => {
    if (
      currentQuestion?.questionClass.toLowerCase() === 'p'
      || currentQuestion?.questionClass.toLowerCase() === 's'
    ) {
      for (const response of frrResponseData) {
        if (response.frrQuestionId === currentQuestion?.frrQuestionId) {
          setCurrentFrrResponseId(response.frrResponseId);
        }
      }
    }
  }, [questionIndex]);

  // This will enable the next button when the user contines for the listed hardcoded questions.
  useLayoutEffect(() => {
    if (
      currentQuestion?.questionClass.toLowerCase().includes('purpose')
      && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('how much do you need')
    ) {
      setQuestionResponse(formik.values.facilityAmount);
    } else if (
      currentQuestion?.questionClass.toLowerCase().includes('purpose')
      && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('how long do you need this plan for')
    ) {
      setQuestionResponse(formik.values.facilityDuration);
    } else if (
      currentQuestion?.questionClass.toLowerCase().includes('purpose')
      && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('why do you need the facility')
    ) {
      setQuestionResponse(formik.values.subPurposeId);
    } else if (
      currentQuestion?.questionClass.toLowerCase().includes('purpose')
      && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly select facility type')
    ) {
      setQuestionResponse(formik.values.facilityTypeId);
    }
  }, [questionIndex, currentQuestion?.frrQuestion]);

  // This will post the facility after the first four required values are provided to allow for frr response posting.
  useEffect(() => {
    if (
      formik.facilityTypeId !== 0
      && formik.values.facilityId === 0
      && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly select facility type')
    ) {
      postInitialFacility(formik.values);
    }
  }, [formik.values.facilityTypeId]);

  // These next couple of lines are for setting question count correctly for when user clicks on continue. (Begins here)

  const counterPartiesHandler = () => {
    const nameSplitted = formik.values.counterPartyName.split(';');

    for (let i = 0; i < nameSplitted.length; i++) {
      if (nameSplitted[i] === '') {
        nameSplitted.splice(i, 1);
      }
    }
    setCounterParties(nameSplitted);

    if(nameSplitted.length> 0 && nameSplitted.length < 6){
      setSelectedMultipleCounterPartyOption('2-5 Clients');
    }else if(nameSplitted.length > 5){
      setSelectedMultipleCounterPartyOption('6 Clients & above');
    }
  };

  // This is auto filling when user returns to continue.
  useEffect(() => {
    if (
      formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.primarySourceOfRepayment.toLowerCase().includes('others')
      && formik.values.isAlternativeSourceOfRepayment === true
      && formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setIsTranches(true);
      setSkippedIndexes(1);
      setIsTranchesRemoved(false);
      setPrimarySourceOfRepaymentOther(true);
      setPrimarySourceOfRepaymentOtherRemoved(false);
      setIsAlternativeSourceOfRepayment(true);
      setIsAlternativeSourceOfRepaymentRemoved(false);
      setSelectedAlternativeSourceOfRepayment('YES');
      setIsCashFlowMultipleClient(true);
      setCounterParty('');

      counterPartiesHandler();
    }

    if (
      !formik.values.primarySourceOfRepayment
        .toLowerCase()
        .includes('others')
      && formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === false
      && !formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setIsTranches(true);
      setSkippedIndexes(4);
      setIsTranchesRemoved(false);
      setSelectedAlternativeSourceOfRepayment('NO');
    }

    if (
      formik.values.primarySourceOfRepayment.toLowerCase().includes('others')
      && !formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === false
      && !formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setPrimarySourceOfRepaymentOther(true);
      setSkippedIndexes(4);
      setPrimarySourceOfRepaymentOtherRemoved(false);
      setSelectedAlternativeSourceOfRepayment('NO');
    }

    if (
      !formik.values.primarySourceOfRepayment
        .toLowerCase()
        .includes('others')
      && !formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === true
      && !formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setIsAlternativeSourceOfRepayment(true);
      setSelectedAlternativeSourceOfRepayment('YES');
      setSkippedIndexes(4);
      setIsAlternativeSourceOfRepaymentRemoved(false);
    }

    if (
      !formik.values.primarySourceOfRepayment
        .toLowerCase()
        .includes('others')
      && !formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === false
      && formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setIsCashFlowMultipleClient(true);
      setSkippedIndexes(4);
      setCounterParty('');
      setSelectedAlternativeSourceOfRepayment('NO');

      counterPartiesHandler();
    }

    if (
      formik.values.primarySourceOfRepayment.toLowerCase().includes('others')
      && formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === false
      && !formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setIsTranches(true);
      setSkippedIndexes(3);
      setIsTranchesRemoved(false);
      setSelectedAlternativeSourceOfRepayment('NO');
      setPrimarySourceOfRepaymentOther(true);
      setPrimarySourceOfRepaymentOtherRemoved(false);
    }

    if (
      formik.values.primarySourceOfRepayment.toLowerCase().includes('others')
      && !formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === true
      && !formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setPrimarySourceOfRepaymentOther(true);
      setPrimarySourceOfRepaymentOtherRemoved(false);
      setSkippedIndexes(3);
      setIsAlternativeSourceOfRepayment(true);
      setSelectedAlternativeSourceOfRepayment('YES');
      setIsAlternativeSourceOfRepaymentRemoved(false);
    }

    if (
      formik.values.primarySourceOfRepayment.toLowerCase().includes('others')
      && !formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === false
      && formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setPrimarySourceOfRepaymentOther(true);
      setPrimarySourceOfRepaymentOtherRemoved(false);
      setSkippedIndexes(3);
      setIsCashFlowMultipleClient(true);
      setCounterParty('');
      setSelectedAlternativeSourceOfRepayment('NO');

      counterPartiesHandler();
    }

    if (
      !formik.values.primarySourceOfRepayment
        .toLowerCase()
        .includes('others')
      && formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === true
      && !formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setIsTranches(true);
      setSkippedIndexes(3);
      setIsTranchesRemoved(false);
      setIsAlternativeSourceOfRepayment(true);
      setSelectedAlternativeSourceOfRepayment('YES');
      setIsAlternativeSourceOfRepaymentRemoved(false);
    }

    if (
      !formik.values.primarySourceOfRepayment
        .toLowerCase()
        .includes('others')
      && formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === false
      && formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setIsTranches(true);
      setSkippedIndexes(3);
      setIsTranchesRemoved(false);
      setIsCashFlowMultipleClient(true);
      setCounterParty('');
      setSelectedAlternativeSourceOfRepayment('NO');

      counterPartiesHandler();
    }

    if (
      !formik.values.primarySourceOfRepayment
        .toLowerCase()
        .includes('others')
      && !formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === true
      && formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setIsAlternativeSourceOfRepayment(true);
      setSelectedAlternativeSourceOfRepayment('YES');
      setIsAlternativeSourceOfRepaymentRemoved(false);
      setSkippedIndexes(3);
      setIsCashFlowMultipleClient(true);
      setCounterParty('');

      counterPartiesHandler();
    }

    if (
      formik.values.primarySourceOfRepayment.toLowerCase().includes('others')
      && !formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === true
      && formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setIsAlternativeSourceOfRepayment(true);
      setSelectedAlternativeSourceOfRepayment('YES');
      setIsAlternativeSourceOfRepaymentRemoved(false);
      setSkippedIndexes(2);
      setIsCashFlowMultipleClient(true);
      setCounterParty('');
      setPrimarySourceOfRepaymentOther(true);
      setPrimarySourceOfRepaymentOtherRemoved(false);

      counterPartiesHandler();
    }

    if (
      formik.values.primarySourceOfRepayment.toLowerCase().includes('others')
      && formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === false
      && formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setSkippedIndexes(2);
      setIsCashFlowMultipleClient(true);
      setCounterParty('');
      setPrimarySourceOfRepaymentOther(true);
      setPrimarySourceOfRepaymentOtherRemoved(false);
      setIsTranches(true);
      setIsTranchesRemoved(false);
      setSelectedAlternativeSourceOfRepayment('NO');

      counterPartiesHandler();
    }

    if (
      !formik.values.primarySourceOfRepayment
        .toLowerCase()
        .includes('others')
      && formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === true
      && formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setIsAlternativeSourceOfRepayment(true);
      setSelectedAlternativeSourceOfRepayment('YES');
      setIsAlternativeSourceOfRepaymentRemoved(false);
      setSkippedIndexes(2);
      setIsCashFlowMultipleClient(true);
      setCounterParty('');
      setIsTranches(true);
      setIsTranchesRemoved(false);

      counterPartiesHandler();
    }

    // This is auto filling when user returns to continue.
    if (Object.keys(filledData).length > 0) {
      setShowedFacility(filledData.facilityType?.facilityTypeName);
    }
  }, [Object.keys(filledData).length]);

  useEffect(() => {
    if (
      formik.values.primarySourceOfRepayment.toLowerCase().includes('others')
      && formik.values.disbursementMode.toLowerCase().includes('tranches')
      && formik.values.isAlternativeSourceOfRepayment === true
      && !formik.values.repaymentCashFlow.toLowerCase().includes('multiple')
      && Object.keys(filledData).length > 0
    ) {
      setSkippedIndexes(2);
      setPrimarySourceOfRepaymentOther(true);
      setPrimarySourceOfRepaymentOtherRemoved(false);
      setIsTranches(true);
      setIsTranchesRemoved(false);
      setIsAlternativeSourceOfRepayment(true);
      setIsAlternativeSourceOfRepaymentRemoved(false);
      setSelectedAlternativeSourceOfRepayment('YES');

      counterPartiesHandler();
    }
    // The setting for question count correctly for when user clicks on continue. (Ends here)
    // This is auto filling when user returns to continue.
    if (
      formik.values.repaymentCashFlow.toLowerCase().includes('single')
      && formik.values.counterPartyName !== ''
      && Object.keys(filledData).length > 0
    ) {
      setCounterParty(formik.values.counterPartyName);
    }
    //This is for populating tranches detail for when the user comes back to continue
    const filledTranches = populateTranchesDetails(formik, filledData);
    setTranchesDetail({
      ...tranchesDetail, numberOfTranches: filledTranches.numberOfTranches, frequecy: filledTranches.frequency, date: filledTranches.date, amount: filledTranches.amount,
    });
  }, [Object.keys(filledData).length]);


  useLayoutEffect(() => {
    if (formik.values.facilityAmount === 0) {
      formik.setFieldValue('facilityAmount', '');
    }
  }, [formik.values.facilityAmount]);
  
  useLayoutEffect(() => {
    // This is for setting the current index not to double when a question is skipped. (Begins here)
    const indexCountSetter = questionCountSetter(currentQuestion, isTranches, primarySourceOfRepaymentOther, primarySourceOfRepaymentOtherRemoved, isAlternativeSourceOfRepayment, isAlternativeSourceOfRepaymentRemoved, isTranchesRemoved);
    // For "is there an alternative source of repayment" page.
    if ( indexCountSetter?.primarySourceOfRepaymentOtherRemovedSetter ) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setPrimarySourceOfRepaymentOtherRemoved(true);
    }
    // For "provide details of the alternative source of payment" page.
    if (indexCountSetter.isAlternativeSourceOfRepaymentRemovedSetter) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setIsAlternativeSourceOfRepaymentRemoved(false);
    }
    // For "which of these best describes the structure of the facility request" page.
    if (indexCountSetter.isAlternativeSourceOfRepaymentRemovedTrueSetter) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setIsAlternativeSourceOfRepaymentRemoved(true);
    }
    if (indexCountSetter.isAlternativeSourceOfRepaymentRemovedTrueSetter2) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
      setIsAlternativeSourceOfRepaymentRemoved(true);
    }
    if (indexCountSetter.isAlternativeSourceOfRepaymentRemovedFalseSetter) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setIsAlternativeSourceOfRepaymentRemoved(false);
    }
    if (indexCountSetter.isAlternativeSourceOfRepaymentRemovedFalseSetter2) {
      setIsAlternativeSourceOfRepaymentRemoved(false);
    }
    // For "what is the preferred disbursement mode" page.
    if (indexCountSetter.disbursementMode1) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
    if (indexCountSetter.disbursementMode2) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
    if (indexCountSetter.disbursementMode3) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
    }
    // For "kindly indicate details of tranches" page
    if (indexCountSetter.tranches1) {
      setCurrentIndex((currentIndex) => currentIndex - 3);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.tranches2) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.tranches3) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.tranches4) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.tranches5) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.tranches6) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.tranches7) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setIsTranchesRemoved(true);
    }
    // For "kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients" page
    if (indexCountSetter.multipleClientSetter1) {
      setCurrentIndex((currentIndex) => currentIndex - 3);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.multipleClientSetter2) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.multipleClientSetter3) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.multipleClientSetter4) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.multipleClientSetter5) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
      setIsTranchesRemoved(false);
    }
    if (indexCountSetter.multipleClientSetter6) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setIsTranchesRemoved(true);
    }
    if (indexCountSetter.multipleClientSetter7) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setIsTranchesRemoved(true);
    }
    // For "indicate the name of the counterparty" page
    if (indexCountSetter.counterPartyName1) {
      setCurrentIndex((currentIndex) => currentIndex - 3);
    }
    if (indexCountSetter.counterPartyName2) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
    }
    if (indexCountSetter.counterPartyName3) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
    }
    if (indexCountSetter.counterPartyName4) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
    if (indexCountSetter.counterPartyName5) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
    }
    if (indexCountSetter.counterPartyName6) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
    if (indexCountSetter.counterPartyName7) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
    // For "select multiple counterparty option" page.
    if (indexCountSetter.multipleOption1) {
      setCurrentIndex((currentIndex) => currentIndex - 4);
    }
    if (indexCountSetter.multipleOption2) {
      setCurrentIndex((currentIndex) => currentIndex - 3);
    }
    if (indexCountSetter.multipleOption3) {
      setCurrentIndex((currentIndex) => currentIndex - 3);
    }
    if (indexCountSetter.multipleOption4) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
    }
    if (indexCountSetter.multipleOption5) {
      setCurrentIndex((currentIndex) => currentIndex - 3);
    }
    if (indexCountSetter.multipleOption6) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
    }
    if (indexCountSetter.multipleOption7) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
    }
    if (indexCountSetter.multipleOption8) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
    // For "list names of counterparties" page.
    if (indexCountSetter.counterParties1) {
      setCurrentIndex((currentIndex) => currentIndex - 4);
    }

    if (indexCountSetter.counterParties2) {
      setCurrentIndex((currentIndex) => currentIndex - 3);
    }
    if (indexCountSetter.counterParties3) {
      setCurrentIndex((currentIndex) => currentIndex - 3);
    }
    if (indexCountSetter.counterParties4) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
    }
    if (indexCountSetter.counterParties5) {
      setCurrentIndex((currentIndex) => currentIndex - 3);
    }
    if (indexCountSetter.counterParties6) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
    }
    if (indexCountSetter.counterParties7) {
      setCurrentIndex((currentIndex) => currentIndex - 2);
    }
    if (indexCountSetter.counterParties8) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
    // The setting for current index not to double when a question is skipped. (Ends here)

    // This next logic is very important oo. It is saving us from sending duplicate.
    const duplicateFilteredResponses = dynamicResponsesduplicateRemover(frrResponseData);
    setFilteredFrrResponses(duplicateFilteredResponses);

  }, [questionIndex]);

  useEffect(() => {
    if (currentQuestion?.questionType.toLowerCase().includes('dropdown')) {
      for (const answer of frrAnswers) {
        if (answer.frrAnswerId == currentAnswer) {
          setUnWeightedAnswer(answer.frrAnswer);
        }
      }
    }
  }, [questionIndex]);

  useEffect(() => {
    let answer = '';
    if (checkboxValues.length > 0) {
      for (let i = 0; i < checkboxValues.length; i++) {
        answer += `${checkboxValues[i]},`;
      }
    }
    setFrrAnswerId(answer);
  }, [checkboxChange]);

  return (
    <Styled>
      <Header />

      <div className="Main-holder">
        <div className="Questions-main">
          <div className="Questions-holder">
            <FRRTab activeSegment={currentQuestion?.questionClass} />

            <div className="Questions-holder-inner">
              <FRRQuestionBoard>
                <div className="Question-div">
                  {currentQuestion?.frrQuestion && (
                    <p className="Question">{currentQuestion?.frrQuestion}</p>
                  )}
                </div>

                <div className="Response-div">
                  <form onSubmit={(e) => e.preventDefault()}>
                    {currentQuestion?.questionType
                      ?.toLowerCase()
                      .includes('number')
                    && !currentQuestion?.frrQuestion
                      .toLowerCase()
                      .includes(
                        'provide details of an alternative source of payment'
                      )
                    && !currentQuestion?.frrQuestion
                      .toLowerCase()
                      .includes('how long do you need this plan for')
                    && !currentQuestion?.frrQuestion
                      .toLowerCase()
                      .includes('kindly indicate details of tranches') ? (
                        <div
                          id={
                            currentQuestion?.frrQuestion
                              .toLowerCase()
                              .includes('how much do you need')
                          && currentQuestion?.questionClass.toLowerCase()
                            === 'purpose'
                              ? 'amount-div'
                              : ''
                          }
                        >

                        {
                            !currentQuestion?.frrQuestion
                            .toLowerCase()
                            .includes('how much do you need')
                            && currentQuestion?.questionClass.toLowerCase()
                            !== 'purpose' && (
                          <TextInput
                            onChange={(e) => {
                              if (
                                currentQuestion?.questionClass.toLowerCase()
                                === 'p'
                              || currentQuestion?.questionClass.toLowerCase()
                                === 's'
                              ) {
                                setUnWeightedAnswer(e.target.value);
                              }
                            }}
                            ref={inputRef}
                            type={currentQuestion?.questionType}
                            id={
                              currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes('how much do you need')
                                ? 'amount-input'
                                : ''
                            }
                            name={
                              currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes('how much do you need')
                            && currentQuestion?.questionClass.toLowerCase()
                              === 'purpose'
                                ? 'facilityAmount'
                                : ''
                            }
                            formik={
                              currentQuestion?.questionClass.toLowerCase()
                              === 'p'
                            || currentQuestion?.questionClass.toLowerCase() === 's'
                                ? ''
                                : formik
                            }
                            value={unWeightedAnswer}
                          />
                            
                          )
                        }

                        {/* This takes care of facility Amount */}
                          {
                            currentQuestion?.frrQuestion
                            .toLowerCase()
                            .includes('how much do you need')
                            && currentQuestion?.questionClass.toLowerCase()
                          === 'purpose' && (
                            <NumberFormat
                              id="amount-input"
                              thousandSeparator
                              name="name"
                              value={formik.values.facilityAmount}
                              onValueChange={ (values) =>  {
                                const { formattedValue, value } = values;
                                formik.setFieldValue('facilityAmount', formattedValue);
                                setQuestionResponse(
                                  selectedCurrency + formattedValue
                                );
                              }}
                            />
                          )
                            
                          }

                          {currentQuestion?.frrQuestion
                            .toLowerCase()
                            .includes('how much do you need')
                        && currentQuestion?.questionClass.toLowerCase()
                          === 'purpose' ? (
                              <select
                              onChange={(e) => {
                                  formik.setFieldValue(
                                    'currencyId',
                                    Number(e.target.value)
                                  );
                                }}
                              className="Currency-holder"
                              name="currencyId"
                            >
                              <option value={0}>{selectedCurrency}</option>
                              {currencies.length > 0
                              && currencies.map((currency) => (
                                <option
                                  key={currency.currencyId}
                                  value={currency.currencyId}
                                >
                                  {currency.currencyCode}
                                </option>
                              ))}
                            </select>
                            ) : (
                              ''
                            )}
                        </div>
                      ) : currentQuestion?.frrQuestion
                        .toLowerCase()
                        .includes('how long do you need this plan for') ? (
                          <div className="Duration-holder">
                            <div className="Duration-year-holder">
                              <DropDownInput
                                onChange={(e) => {
                                  const { value } = e.target;
                                  let years;

                                  if (value.length === 6 || value.length === 7) {
                                    years = value.slice(0, 1);
                                  } else if (value.length === 8) {
                                    years = value.slice(0, 2);
                                  } else {
                                    return;
                                  }

                                  setFacilityDuration({
                                    ...facilityDuration,
                                    years: Number(years),
                                  });
                                }}
                                className="Duration-input"
                                dropDownData={currentQuestion?.options.year}
                                currentData={`${
                                  `${facilityDuration.years} ` + 'years'
                                }`}
                              />
                              <i className="fas fa-chevron-down Duration-year-icon" />
                            </div>

                            <div className="Duration-month-holder">
                              <DropDownInput
                                onChange={(e) => {
                                  const { value } = e.target;
                                  let months;

                                  if (value.length === 7 || value.length === 8) {
                                    months = value.slice(0, 1);
                                  } else if (value.length === 9) {
                                    months = value.slice(0, 2);
                                  } else {
                                    return;
                                  }

                                  setFacilityDuration({
                                    ...facilityDuration,
                                    months: Number(months),
                                  });
                                }}
                                className="Duration-input"
                                dropDownData={currentQuestion?.options.month}
                                currentData={`${
                                  `${facilityDuration.months} ` + 'months'
                                }`}
                              />
                              <i className="fas fa-chevron-down Duration-month-icon" />
                            </div>

                            <div className="Duration-day-holder">
                              <DropDownInput
                                onChange={(e) => {
                                  const { value } = e.target;
                                  let days;

                                  if (value.length === 5 || value.length === 6) {
                                    days = value.slice(0, 1);
                                  } else if (value.length === 7) {
                                    days = value.slice(0, 2);
                                  } else {
                                    return;
                                  }

                                  setFacilityDuration({
                                    ...facilityDuration,
                                    days: Number(days),
                                  });
                                }}
                                className="Duration-input"
                                dropDownData={currentQuestion?.options.day}
                                currentData={`${
                                  `${facilityDuration.days} ` + 'days'
                                }`}
                              />
                              <i className="fas fa-chevron-down Duration-day-icon" />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                    {/* This is for facility purpose and facility types */}
                    {currentQuestion?.questionType
                      ?.toLowerCase()
                      .includes('dropdown')
                    && (currentQuestion?.frrQuestion
                      .toLowerCase()
                      .includes('kindly select facility type')
                      || currentQuestion?.frrQuestion
                        .toLowerCase()
                        .includes('why do you need the facility')) ? (
                        <DropDownInput
                            onChange={(e) => {
                            if (
                              currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes('kindly select facility type')
                            && currentQuestion.questionClass === 'purpose'
                            ) {
                              formik.setFieldValue(
                                'facilityTypeId',
                                Number(e.target.value)
                              );
                              setShowedFacility(e.target.value);
                            }

                            if (
                              currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes('why do you need the facility')
                            ) {
                              setSelectedPurpose(e.target.value);
                            }

                            // Skipping a question if source of repayment is not others
                            if (
                              currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes(
                                  'what is the primary source of repayment for the facility'
                                )
                            && e.target.value === 'Others'
                            ) {
                              setPrimarySourceOfRepaymentOther(true);
                            }
                          }}
                            dropDownData={
                            currentQuestion?.frrQuestion
                              .toLowerCase()
                              .includes('kindly select facility type')
                          && currentQuestion?.questionClass
                            .toLowerCase()
                            .includes('purpose')
                              ? facilityTypes
                              : purposes
                          }
                            ref={inputRef}
                            present={
                            currentQuestion?.frrQuestion
                              .toLowerCase()
                              .includes('kindly select facility type')
                              ? 'facility'
                              : currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes('why do you need the facility')
                                ? 'purpose'
                                : ''
                          }
                            currentData={
                            currentQuestion?.frrQuestion
                              .toLowerCase()
                              .includes('why do you need the facility')
                              ? showedPurpose
                              : showedFacility
                          }
                            disabled={filledData?.shouldUpdate}
                          />
                      ) : (
                        ''
                      )}

                    {currentQuestion?.questionType
                      ?.toLowerCase()
                      .includes('dropdown')
                    && currentQuestion?.options?.length > 0 ? (
                        <DropDownInput
                        onChange={(e) => {
                            if (
                              currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes(
                                  'what is the primary source of repayment for the facility'
                                )
                            ) {
                              formik.setFieldValue(
                                'primarySourceOfRepayment',
                                e.target.value
                              );
                              setQuestionResponse(e.target.value);
                            }

                            if (
                              currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes(
                                  'what is your preferred repayment frequency'
                                )
                            ) {
                              formik.setFieldValue(
                                'repaymentFrequency',
                                e.target.value
                              );
                              setQuestionResponse(e.target.value);
                            }

                            // Skipping a question if source of repayment is not others
                            if (
                              currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes(
                                  'what is the primary source of repayment for the facility'
                                )
                            && e.target.value === 'Others'
                            ) {
                              primarySourceOfRepaymentOtherHandler(
                                e.target.value
                              );
                            } else if (
                              currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes(
                                  'what is the primary source of repayment for the facility'
                                )
                            && e.target.value !== 'Others'
                            ) {
                              primarySourceOfRepaymentOtherHandler(
                                e.target.value
                              );

                              // This will reset formik value of other source of repayment if the option selected here is not "others"
                              formik.setFieldValue(
                                'otherPrimarySourceOfRepayment',
                                ''
                              );
                            }

                            if (
                              currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes(
                                  'provide details of the alternative source of payment'
                                )
                            ) {
                              formik.setFieldValue(
                                'alternativeSourceOfRepayment',
                                e.target.value
                              );
                              setQuestionResponse(e.target.value);
                            }
                          }}
                        dropDownData={currentQuestion?.options}
                        ref={inputRef}
                        currentData={
                            currentQuestion?.frrQuestion
                              .toLowerCase()
                              .includes(
                                'what is the primary source of repayment for the facility'
                              )
                              ? formik.values.primarySourceOfRepayment
                              : currentQuestion?.frrQuestion
                                .toLowerCase()
                                .includes(
                                  'provide details of the alternative source of payment'
                                )
                              && formik.values.alternativeSourceOfRepayment !== ''
                                ? formik.values.alternativeSourceOfRepayment
                              :currentQuestion?.frrQuestion
                              .toLowerCase()
                              .includes(
                                'what is your preferred repayment frequency'
                              )
                              ? formik.values.repaymentFrequency
                              : ''
                          }
                      />
                      ) : (
                        ''
                      )}

                    {/* For adding an extra drop down to why the facility is needed */}

                    {(currentQuestion?.frrQuestion
                      ?.toLowerCase()
                      .includes('why do you need the facility')
                      && subPurposes.length > 0)
                    || (currentQuestion?.frrQuestion
                      ?.toLowerCase()
                      .includes('why do you need the facility')
                      && showedSubPurpose !== '') ? (
                        <select
                          onChange={(e) => {
                            setSelectedSubPurpose(e.target.value);
                            formik.setFieldValue(
                              'subPurposeId',
                              Number(e.target.value)
                            );
                            setQuestionResponse(Number(e.target.value));
                          }}
                          className="Dropdown"
                          ref={inputRef}
                          disabled={filledData.shouldUpdate}
                        >
                          <option>
                            {showedSubPurpose !== ''
                              ? showedSubPurpose
                              : 'Select option'}
                          </option>
                          {subPurposes?.length > 0
                          && subPurposes.map((item) => (
                            <option
                              key={item.subPurposeId}
                              value={item.subPurposeId}
                            >
                              {item.subPurposeName}
                            </option>
                          ))}
                        </select>
                      ) : (
                        ''
                      )}

                    {currentQuestion?.frrQuestion
                      .toLowerCase()
                      .includes('kindly indicate details of tranches') ? (
                        <div>
                          <TextInput
                            onChange={(e) => {
                              setTranchesDetail({
                                ...tranchesDetail,
                                numberOfTranches: e.target.value,
                              });
                            }}
                            ref={inputRef}
                            type={currentQuestion?.questionType}
                            placeholder="Number of tranches"
                            value={tranchesDetail.numberOfTranches}
                          />

                          <TextInput
                            onChange={(e) => {
                              setTranchesDetail({
                                ...tranchesDetail,
                                frequency: e.target.value,
                              });
                            }}
                            ref={inputRef}
                            type={currentQuestion?.questionType}
                            placeholder="Frequency of disbursement"
                            value={tranchesDetail.frequency}
                          />

                          <div id="tranches-date-holder">
                            <TextInput
                              onChange={(e) => {
                                setTranchesDetail({
                                  ...tranchesDetail,
                                  date: e.target.value,
                                });
                              }}
                              ref={inputRef}
                              type="date"
                              placeholder="Enter expected date"
                              id="tranches-date-input"
                              value={tranchesDetail.date}
                            />
                            <i
                              id="tranches-date-icon"
                              className="fas fa-calendar-day"
                            />
                          </div>
                          <div id="tranches-amount-holder">
                            <NumberFormat
                              id="tranches-amount-input"
                              thousandSeparator
                              name="name"
                              placeholder= 'Enter amount'
                              value={tranchesDetail.amount}
                              onValueChange={ (values) =>  {
                                const { value } = values;
                                setTranchesDetail({
                                  ...tranchesDetail,
                                  amount: value
                                });
                              }}
                            />
                            <p id="tranches-amount-icon">{selectedCurrency}</p>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                    {/*  This is for general question types of single, meaning that they have buttons  */}
                    {currentQuestion?.questionType
                      ?.toLowerCase()
                      .includes('single')
                    && currentQuestion?.questionClass.toLowerCase()
                      === 'general' ? (
                        <div className="Multiple-select-buttons-holder">
                          {currentQuestion?.options.length > 0
                          && currentQuestion?.options.map((option) => (
                            <button
                              className={
                                // These will keep the buttons selected when the user clicks the back button on any answered question
                                currentQuestion?.frrQuestion
                                  .toLowerCase()
                                  .includes(
                                    'which of these best describes this facility in the order of seniority'
                                  )
                                  && option === formik.values.facilitySeniority
                                  ? 'Multi-select-button-focus'
                                  : currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'is there an alternative source of repayment'
                                    )
                                      && (( option == 'YES' && selectedAlternativeSourceOfRepayment === option ) || ( option == 'NO' && selectedAlternativeSourceOfRepayment === option )) 
                                    ? 'Multi-select-button-focus'
                                    : currentQuestion?.frrQuestion
                                      .toLowerCase()
                                      .includes(
                                        'which of these best describes the structure of the facility request'
                                      )
                                      && option
                                        === formik.values.facilityRequestStructure
                                      ? 'Multi-select-button-focus'
                                      : currentQuestion?.frrQuestion
                                        .toLowerCase()
                                        .includes(
                                          'what is the preferred disbursement mode'
                                        )
                                      && option === formik.values.disbursementMode
                                        ? 'Multi-select-button-focus'
                                        : currentQuestion?.frrQuestion
                                          .toLowerCase()
                                          .includes(
                                            'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
                                          )
                                      && option === formik.values.repaymentCashFlow
                                          ? 'Multi-select-button-focus'
                                          : currentQuestion?.frrQuestion
                                            .toLowerCase()
                                            .includes(
                                              'select multiple counterparty option'
                                            )
                                      && option
                                        === selectedMutipleCounterPartyOption
                                            ? 'Multi-select-button-focus'
                                            : 'Multi-select-button'
                              }
                              key={option}
                              onClick={() => {
                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'which of these best describes this facility in the order of seniority'
                                    )
                                ) {
                                  formik.setFieldValue(
                                    'facilitySeniority',
                                    option
                                  );
                                  setQuestionResponse(option);
                                }

                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'is there an alternative source of repayment'
                                    )
                                    && option.toLowerCase() === 'yes'
                                ) {
                                  setIsAlternativeSourceOfRepayment(true);
                                  setSelectedAlternativeSourceOfRepayment(
                                    option
                                  );
                                  setQuestionResponse(option);
                                  formik.setFieldValue(
                                    'isAlternativeSourceOfRepayment',
                                    true
                                  );
                                } else if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'is there an alternative source of repayment'
                                    )
                                    && option.toLowerCase() === 'no'
                                ) {
                                  setIsAlternativeSourceOfRepayment(false);
                                  setSelectedAlternativeSourceOfRepayment(
                                    option
                                  );
                                  setQuestionResponse(option);
                                  formik.setFieldValue(
                                    'isAlternativeSourceOfRepayment',
                                    false
                                  );

                                  // This is to reset the formik value of alternative source of repayment when user selects "No" for alternative source of repayment option
                                  formik.setFieldValue(
                                    'alternativeSourceOfRepayment',
                                    ''
                                  );
                                }

                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'which of these best describes the structure of the facility request'
                                    )
                                ) {
                                  formik.setFieldValue(
                                    'facilityRequestStructure',
                                    option
                                  );
                                  setQuestionResponse(option);
                                }

                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'what is the preferred disbursement mode'
                                    )
                                ) {
                                  formik.setFieldValue(
                                    'disbursementMode',
                                    option
                                  );
                                  setQuestionResponse(option);
                                }

                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
                                    )
                                ) {
                                  formik.setFieldValue(
                                    'repaymentCashFlow',
                                    option
                                  );
                                  setQuestionResponse(option);
                                }

                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'select multiple counterparty option'
                                    )
                                ) {
                                  setSelectedMultipleCounterPartyOption(
                                    option
                                  );
                                  setQuestionResponse(option);
                                }

                                // Skipping a question if there isn't an alternative source of repayment
                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'is there an alternative source of repayment'
                                    )
                                    && option === 'YES'
                                ) {
                                  alternativeSourceOfRepaymentHandler(option);
                                } else if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'is there an alternative source of repayment'
                                    )
                                    && option === 'NO'
                                ) {
                                  alternativeSourceOfRepaymentHandler(option);
                                }

                                // Skipping a question if disbursement mode is not tranches
                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'what is the preferred disbursement mode'
                                    )
                                    && option === 'Tranches'
                                ) {
                                  tranchesHandler(option);
                                } else if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'what is the preferred disbursement mode'
                                    )
                                    && option === 'Lumpsum/Bullet'
                                ) {
                                  tranchesHandler(option);
                                }

                                // Skipping a question if disbursement mode is not tranches
                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
                                    )
                                    && option === 'Multiple'
                                ) {
                                  cashFlowMultipleClientHandler(option);
                                } else if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
                                    )
                                    && option === 'Single'
                                ) {
                                  cashFlowMultipleClientHandler(option);
                                }
                              }}
                            >
                              {option}
                            </button>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}

                    {/*  This is for sub purpose question types of single, meaning that they have buttons  */}
                    {currentQuestion?.questionType
                      ?.toLowerCase()
                      .includes('single')
                    && (currentQuestion?.questionClass.toLowerCase() === 'p'
                      || currentQuestion?.questionClass.toLowerCase() === 's') ? (
                        <div className="Multiple-select-buttons-holder">
                          {frrAnswers.length > 0
                          && frrAnswers.map((answer) => (
                            <button
                              className={
                                currentAnswer == answer.frrAnswerId
                                  ? 'Multi-select-button-focus'
                                  : 'Multi-select-button'
                              }
                              key={answer.frrAnswerId}
                              onClick={() => {
                                setFrrAnswerId(answer.frrAnswerId);
                                setCurrentAnswer(answer.frrAnswerId);
                              }}
                            >
                              {answer.frrAnswer.toUpperCase()}
                            </button>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}

                    {currentQuestion?.questionType
                      ?.toLowerCase()
                      .includes('multiple')
                    && (currentQuestion?.questionClass.toLowerCase() === 'p'
                      || currentQuestion?.questionClass.toLowerCase() === 's') ? (
                        <div className="Multi-select-holder">
                          {frrAnswers.length > 0
                          && frrAnswers.map((answer) => {
                            let checked = false;
                            if (
                              checkboxValues.includes(
                                String(answer.frrAnswerId)
                              )
                            ) {
                              checked = true;
                            }

                            return (
                              <div
                                key={answer.frrAnswerId}
                                className="Checkbox"
                              >
                                <input
                                  type="checkbox"
                                  className="Checkbox-input w-2 h-2 text-green-800 bg-gray-300 rounded border-gray-300 focus:ring-green-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  onChange={() => {
                                    if (
                                      !checkboxValues.includes(
                                        String(answer.frrAnswerId)
                                      )
                                    ) {
                                      setCheckboxValues([
                                        ...checkboxValues,
                                        String(answer.frrAnswerId),
                                      ]);
                                      checked = true;
                                    } else {
                                      checked = false;
                                    }

                                    for (
                                      let i = 0;
                                      i < checkboxValues.length;
                                      i++
                                    ) {
                                      if (
                                        answer.frrAnswerId
                                        === Number(checkboxValues[i])
                                      ) {
                                        checkboxValues.splice(i, 1);
                                      }
                                    }

                                    setCheckboxChange(!checkboxChange);
                                  }}
                                  defaultChecked={checked}
                                />
                                <p>{answer.frrAnswer}</p>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ''
                      )}

                    {currentQuestion?.questionType
                      .toLowerCase()
                      .includes('date') ? (
                        <div id="tranches-date-holder">
                          <TextInput
                            onChange={(e) => {
                              setUnWeightedAnswer(e.target.value);
                            }}
                            ref={dateInputRef}
                            type="date"
                            placeholder="Enter expected date"
                            id="tranches-date-input"
                            value={unWeightedAnswer}
                          />
                          <i
                            id="tranches-date-icon"
                            className="fas fa-calendar-day"
                          />
                        </div>
                      ) : (
                        ''
                      )}

                    {currentQuestion?.questionType.toLowerCase() === 'text'
                    && (currentQuestion?.questionClass.toLowerCase() === 'p'
                      || currentQuestion?.questionClass.toLowerCase() === 's'
                      || currentQuestion?.questionClass.toLowerCase()
                        === 'general')
                    && !currentQuestion?.frrQuestion
                      .toLowerCase()
                      .includes('kindly indicate details of tranches') ? (
                        <div>
                          <div className="Counterparty-div">
                            <TextInput
                              onChange={(e) => {
                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes('justification for the facility')
                                ) {
                                  formik.setFieldValue(
                                    'justification',
                                    e.target.value
                                  );
                                  setQuestionResponse(e.target.value);
                                }

                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'provide details of a primary source of payment'
                                    )
                                ) {
                                  formik.setFieldValue(
                                    'otherPrimarySourceOfRepayment',
                                    e.target.value
                                  );
                                  setQuestionResponse(e.target.value);
                                }

                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'indicate the name of the counterparty'
                                    )
                                ) {
                                  setParty(e.target.value);
                                  setQuestionResponse(e.target.value);
                                  formik.setFieldValue(
                                    'counterPartyName',
                                    e.target.value
                                  );
                                }

                                if (
                                  currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes('list names of counterparties')
                                ) {
                                  setNewParty(e.target.value);
                                }

                                if (
                                  (currentQuestion?.questionClass.toLowerCase()
                                  === 'p'
                                  || currentQuestion?.questionClass.toLowerCase()
                                    === 's')
                                && currentQuestion?.questionType.toLowerCase()
                                  === 'text'
                                ) {
                                  setUnWeightedAnswer(e.target.value);
                                }
                              }}
                              type="text"
                              name={
                                currentQuestion?.frrQuestion
                                  .toLowerCase()
                                  .includes('justification for the facility')
                                  ? 'justification'
                                  : currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'provide details of a primary source of payment'
                                    )
                                    ? 'otherPrimarySourceOfRepayment'
                                    : ''
                              }
                              value={
                                currentQuestion?.frrQuestion
                                  .toLowerCase()
                                  .includes('list names of counterparties')
                                  ? newParty
                                  : currentQuestion?.frrQuestion
                                    .toLowerCase()
                                    .includes(
                                      'indicate the name of the counterparty'
                                    )
                                    ? party
                                    : unWeightedAnswer
                              }
                              ref={textInputRef}
                              formik={
                                currentQuestion?.questionClass.toLowerCase()
                                === 'p'
                              || currentQuestion?.questionClass.toLowerCase()
                                === 's'
                                  ? ''
                                  : formik
                              }
                            />

                            {currentQuestion?.frrQuestion
                              .toLowerCase()
                              .includes(
                                'indicate the name of the counterparty'
                              )
                          && isCashFlowMultipleClient === false
                          && counterParty === '' ? (
                                <i
                              onClick={() => {
                                    setCounterParty(party);
                                    setParty('');
                                    textInputRef.current.value = '';
                                  }}
                              className="fas fa-plus"
                            />
                              ) : (
                                ''
                              )}

                            {currentQuestion?.frrQuestion
                              .toLowerCase()
                              .includes('list names of counterparties')
                          && isCashFlowMultipleClient === true
                          && counterParties.length < 5 ? (
                                <i
                              onClick={() => {
                                    if (newParty !== '') {
                                      setCounterParties([
                                        ...counterParties,
                                        newParty,
                                      ]);
                                      formik.setFieldValue(
                                        'counterPartyName',
                                        `${formik.values.counterPartyName
                                      + newParty
                                        };`
                                      );
                                      addGeneralQuestion();
                                      textInputRef.current.value = '';
                                    }

                                    setNewParty('');
                                  }}
                              className="fas fa-plus"
                            />
                              ) : (
                                ''
                              )}
                          </div>

                          {currentQuestion?.frrQuestion
                            .toLowerCase()
                            .includes('indicate the name of the counterparty')
                        && counterParty !== '' ? (
                              <div className="Single-party-holder">
                            <p>{counterParty}</p>
                            <i
                                  onClick={() => {
                                    setCounterParty('');
                                    setPartyRemoved(!partyRemoved);
                                    formik.setFieldValue('counterPartyName', '');
                                  }}
                                  className="fas fa-minus Minus-icon"
                                />
                          </div>
                            ) : (
                              ''
                            )}

                          {currentQuestion?.frrQuestion
                            .toLowerCase()
                            .includes('list names of counterparties') ? (
                              <ol type="1" className="Parties-holder">
                                {counterParties.length > 0
                              && counterParties.map((party, i) => (
                                <li
                                  key={i}
                                  value={party}
                                  className="Individual-party"
                                >
                                  <p>{party}</p>
                                  <i
                                    onClick={() => {
                                      counterParties.splice(i, 1);
                                      setPartyRemoved(!partyRemoved);
                                      formik.setFieldValue(
                                        'counterPartyName',
                                        formik.values.counterPartyName.replace(
                                          `${party};`,
                                          ''
                                        )
                                      );
                                      addGeneralQuestion();
                                    }}
                                    className="fas fa-minus Minus-icon"
                                  />
                                </li>
                              ))}
                              </ol>
                            ) : (
                              ''
                            )}
                        </div>
                      ) : (
                        ''
                      )}

                    {currentQuestion?.questionType.toLowerCase()
                      === 'percentages'
                    && (currentQuestion?.questionClass.toLowerCase() === 'p'
                      || currentQuestion?.questionClass.toLowerCase() === 's') ? (
                        <div className="Percentage-input-holder">
                          <input
                            onChange={(e) => {
                              setUnWeightedAnswer(e.target.value.toString());
                              setPercentage(e.target.value);
                            }}
                            type="range"
                            min={0}
                            max={100}
                            className="Range"
                            value={unWeightedAnswer}
                          />

                          <div>
                            <p>0%</p>
                            <p>100%</p>
                          </div>

                          <div id="percentage-value-holder">
                            <p>
                              {unWeightedAnswer}
                              {' '}
                              %
                            </p>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                    {currentQuestion?.questionType.toLowerCase()
                      === 'amount'
                    && (currentQuestion?.questionClass.toLowerCase() === 'p'
                      || currentQuestion?.questionClass.toLowerCase() === 's') ? (
                        <div className="Percentage-input-holder">

                          <NumberFormat
                            id="amount-input"
                            thousandSeparator
                            name="name"
                            value={unWeightedAnswer}
                            onValueChange={ (values) =>  {
                              const { formattedValue } = values;
                              setUnWeightedAnswer(formattedValue);
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}

                    {currentQuestion?.questionType
                      .toLowerCase()
                      .includes('dropdown')
                    && (currentQuestion?.questionClass.toLowerCase() === 'p'
                      || currentQuestion?.questionClass.toLowerCase() === 's') ? (
                        <DropDownInput
                          onChange={(e) => {
                            setFrrAnswerId(String(e.target.value));
                          }}
                          present="sub-purpose"
                          dropDownData={frrAnswers}
                          currentData={currentAnswer}
                        />
                      ) : (
                        ''
                      )}

                    {currentQuestion?.questionType
                      .toLowerCase()
                      .includes('long-text')
                    && (currentQuestion?.questionClass.toLowerCase() === 'p'
                      || currentQuestion?.questionClass.toLowerCase() === 's') ? (
                        <TextArea
                          onChange={(e) => {
                            setUnWeightedAnswer(e.target.value);
                          }}
                          value={unWeightedAnswer}
                        />
                      ) : (
                        ''
                      )}
                  </form>
                </div>

                <p className="Progress-counter">
                  {currentIndex}
                  {' '}
                  of { ' '}
                  {questionLength - skippedIndexes}
                </p>
              </FRRQuestionBoard>

              <img
                className="FRR-image"
                src={subSectorImage}
                alt="A pictorial representation"
              />
            </div>
          </div>

          <div className="Buttons-holder">
            <div className="FRR-prev-next-buttons-holder">
              {questionIndex > 0 ? (
                <PrevButton
                  onClick={() => {
                    questionIndexDecrementHandler();
                  }}
                  id="prev-button"
                />
              ) : (
                ''
              )}

              {lastQuestion ? (
                <Button
                  onClick={() => {
                    formik.handleSubmit();
                    if (
                      currentQuestion?.questionClass.toLowerCase() === 'general'
                    ) {
                      addGeneralQuestion();
                    }

                    dispatch({
                      type: 'UPDATE_FACILITY_ID',
                      payload: {
                        facilityId: formik.values.facilityId,
                        fromDashboard: false,
                      },
                    });
                    setSaveAndExit(false);
                  }}
                  mode="1"
                  title="Save & Proceed"
                  id="save-button"
                  disabled={formik.values.counterPartyName === ''}
                />
              ) : (
                <NextButton
                  onClick={() => {
                    questionIndexIncrementHandler();
                  }}
                  id="next-button"
                  disabled={disableNextButton}
                />
              )}
            </div>

            <div className="FRR-save-button-holder">
              <button
                onClick={() => {
                  formik.handleSubmit();
                  setSaveAndExit(true);
                }}
                id={
                  formik.values.facilityAmount > 100000
                  || formik.values.facilityDuration !== ''
                  || formik.values.subPurposeId !== null
                  || formik.values.subPurposeId !== 0
                  || formik.values.facilityTypeId !== null
                  || formik.values.facilityTypeId !== 0
                    ? 'save-exit-button'
                    : 'save-exit-button-disabled'
                }
                disabled={
                  formik.values.facilityAmount < 100000
                  || formik.values.facilityDuration === ''
                  || formik.values.facilityDuration === 0
                  || formik.values.subPurposeId === null
                  || formik.values.subPurposeId === 0
                  || formik.values.facilityTypeId === null
                  || formik.values.facilityTypeId === 0
                  // || filledData.shouldUpdate
                }
              > Save & Exit</button>
            </div>
          </div>
        </div>
      </div>

      {currenciesLoader || facilityLoader || facilityTypesLoader || purposeLoader || subPurposeLoader || FRRQuestionsLoader || answerLoader || purposeGetterLoader || imageLoader || frrResponseLoader ? (
          <Spinner /> ) : ('')}
    </Styled>
  );
}

const Styled = styled.div`
    min-height: 100vh;
    background-color: #E5E5E5;
    input{
      cursor: text;
    }
    .Main-holder{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 94vh;
    }
    .Questions-main{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Questions-holder{
        width: 100%;
    }
    .Questions-holder-inner{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .Buttons-holder{
        width: 100%;
        display: flex;
        align-items; center;
        justify-content: space-between;
        margin-top: 1rem;
    }
    .FRR-image{
        height: 60vh;
        border-radius: 10px;
    }
    .FRR-prev-next-buttons-holder{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 68%;
        position: relative;
    }
    #next-button{
        color: #D9B08C;
        font-size: 1rem;
        padding: 1rem 2rem;
        background: linear-gradient(90deg, #00989C, #004C4E);
        cursor: pointer;
        position: absolute;
        right: 0;
    }
    #save-button{
        position: absolute;
        right: 0;
        background: linear-gradient(90deg, #00989C, #004C4E);
        padding: 1rem 2rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 1rem;
    }
    #save-exit-button{
        background-color: transparent !important;
        padding: 1rem 2.5rem;
        border: 1px solid #AF6E51;
        border-radius: 16.5px;
        cursor: pointer;
    }
    #save-exit-button-disabled{
        background-color: transparent;
        border: 1px solid #AF6E51;
        border-radius: 16.5px;
        opacity: .5;
        padding: 1rem 2.5rem;
        cursor: not-allowed;
    }
    #prev-button{
        color: #D9B08C;
        font-size: 1rem;
        padding: 1rem 2rem;
        background: linear-gradient(90deg, #00989C, #004C4E);
        cursor: pointer;
        position: absolute;
        left: 0;
    }
    .FRR-save-button-holder{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27%;
    }
    .Question-div{
        margin-bottom: 3rem;
        width: 80%;
    }
    .Progress-counter{
        position: absolute;
        bottom: 10px;
        left: 48%;
        font-size: 1rem;
    }
    .Multi-select-button{
        padding: 1rem 1.5rem;
        background-color: white;
        border-radius: 3px;
        border: 2px solid #E5E5E5;
        margin-right: 1rem;
        cursor: pointer;
        min-width: 7rem;
    }
    .Multi-select-button-focus{
        padding: 1rem 1.5rem;
        background-color: white;
        border-radius: 3px;
        border: 2px solid #E5E5E5;
        margin-right: 1rem;
        cursor: pointer;
        min-width: 7rem;
        border: 2px solid #00989C;
    }
    .Question{
        text-align: center;
    }
    .Response-div{
        width: 80%;
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    #amount-div{
        position: relative;
        padding-left: 5rem;
        margin-top: 1rem;
        width: 100%;
    }

    #amount-input{
        width: 100%;
        margin-top: unset;
        padding: 1rem 1rem 1rem 3rem; 
        outline: none;
        border-radius: 5px;
    }
    #amount-input:focus{
      outline: none;
    }
    .Currency-holder{
        position: absolute;
        left: 0;
        background-color: #E5E5E5;
        color: black;
        top: 0;
        bottom: 0;
        cursor: pointer;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        outline: none;
        border: ipx solid grey !important;

        :focus {
          outline: none !important;
          box-shadow: none !important;
          border: ipx solid grey !important;
        }
    }
    #tranches-date-holder{
        position: relative;
        margin-top: 1rem;
    }
    #tranches-date-input{
        width: 100%;
        margin-top: unset;
        padding-left: 4rem;
    }
    #tranches-date-icon{
        position: absolute;
        left: 0;
        top: 0;
        font-size: 1.2rem;
        background-color: #E5E5E5;
        padding: 1.15rem 1.2rem;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border: 1px solid grey;
        cursor: pointer;
        pointer-events: none;
    }
    #tranches-amount-holder{
        position: relative;
        margin-top: 1rem;
        overflow: hidden;
    }
    #tranches-amount-input{
        width: 100%;
        margin-top: unset;
        padding: 1rem 1rem .9rem 7rem;
        border-radius: 5px;
        outline: none;
    }
    #tranches-amount-input:focus{
      outline: none;
    }
    #tranches-amount-icon{
        position: absolute;
        left: 0;
        top: 0;
        background
        font-size: 1rem;
        background-color: #E5E5E5;
        padding: .82rem 1rem;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border: 1px solid grey;
    }
    .Duration-holder{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .Duration-year-holder{
        width: 30%;
        position: relative;
    }
    .Duration-year-icon,
    .Duration-month-icon,
    .Duration-day-icon{
        position: absolute;
        right: 0;
        top: 0;
        padding: 1.25rem;
        font-size: 1rem;
        background-color: #E5E5E5;
        border: 1px solid grey;
        cursor: pointer;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        pointer-events: none;
    }
    .Duration-month-holder{
        width: 30%;
        position: relative;
    }
    .Duration-day-holder{
        width: 30%;
        position: relative;
    }
    .Duration-input{
        margin-top: unset;
        padding-right: 3rem;
    }
    .Multiple-select-buttons-holder{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Dropdown{
        padding: 1rem 15rem 1rem 1rem;
        width: 100%;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid grey;
        margin-top: 1rem;
        outline: none;
        box-shadow: unset;
    }
    .Checkbox{
        margin-right: 1rem;
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;

        input{
            margin-right: 1rem;
            cursor: pointer;
        }
    }
    input[type="checkbox"] {
        accent-color: #00989C;
        padding: 1rem;
    }
    .Multi-select-holder{
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        border-top: 1px solid grey;
        padding-top: 3rem;
    }
    .Range{
        width: 100%;
        cursor: pointer;
        background-color: #000;
    }
    input[type='range']::-webkit-slider-thumb {
        width: 6px;
        -webkit-appearance: none;
        height: 12px;
        background: black;
        border-radius: 2px;
    }
    .Percentage-input-holder{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;

        div{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: .5rem;
        }
    }
    #percentage-value-holder{
        width:: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }
    .Counterparty-div{
        display: flex;
        align-items: center;
        justify-content: space-between;

        i{
            font-size: 2rem;
            color: #00989C;
            margin-left: 1rem;
            cursor: pointer;
        }
    }
    .Minus-icon{
        font-size: 2rem;
        color: #00989C;
        margin-left: 1rem;
        cursor: pointer;
    }
    .Individual-party{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .Parties-holder{
        margin-top: 1rem;
    }
    .Single-party-holder{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
    }
    .Error-message{
        color: red;
        position: absolute;
        bottom: -3rem;
        font-size: 1.1rem;
    }
`;

export default FRRQuestions;
