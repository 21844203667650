import React from 'react';
import Navbar from 'components/Home/Navbars/Navbar';
import Main from 'components/Home/TermsAndConditions';
import Footer from 'components/Home/Main/Footer';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

export default function termsAndConditions() {
  return (
    <Styled>
      <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5' }}>
        <title>Terms & Conditions | Gazelle by Sterling</title>
      </Helmet>
      <Navbar />
      <Main className="main" />
      <Footer />
    </Styled>
  );
}

const Styled = styled.div`
  .main {
    margin: 3rem 1rem;
  }
`;
