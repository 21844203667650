import React, { useState } from 'react';

import { UpdateCollateralPayload } from 'store/actions/collateral';
import { useDispatch, useSelector } from 'react-redux';
import QuestionTypes from './QuestionTypes';

export default function NewValuation() {
  const { collateral } = useSelector((state) => state);
  const { payLoad, checks } = collateral;
  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();

  const question = {
    question: 'Would you like to carry out a valuation?',
    questionType: 'single',
    optionsArray: [
      { answer: 'Yes', answerId: 446 },
      { answer: 'No', answerId: 447 },
    ],
  };
  const inputHandler = (e) => {
    setSelected(e.answer);
    dispatch(
      UpdateCollateralPayload({
        carryOutNewValuation: e.answer,
        completedProcess: true,
      })
    );
    sessionStorage.setItem('_currentPage', 'carryOutNewValuation');
  };
  return (
    <QuestionTypes
      selected={payLoad.carryOutNewValuation || selected}
      questionTitle={question.question}
      optionsArray={question.optionsArray}
      questionType={question.questionType}
      optionKey="answer"
      optionId="answerId"
      selectHandler={inputHandler}
    />
  );
}
