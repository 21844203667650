import { forwardRef } from 'react';
import styled from 'styled-components';

const DropDownInput = forwardRef((props, ref) => (
  <Styled
    id={props.id}
    onChange={props.onChange}
    ref={ref}
    className={props.className}
  >
    <label>
      {props.label}
      <span style={{ color: 'red' }}>*</span>
    </label>
    <select disabled={props.disabled}>
      <option value="">
        {props.currentData ? props.currentData : 'Select option'}
      </option>
      {props?.dropDownData?.length > 0
          && props.dropDownData.map((data) => (
            <option
              key={
                props.present?.toLowerCase() === 'states'
                  ? data.id
                  : props.present?.toLowerCase() === 'sector'
                    ? data.Sector_T24Code
                    : props.present?.toLowerCase() === 'industry'
                      ? data.Industry_T24Code
                      : data
              }
              value={
                props.present?.toLowerCase() === 'states'
                  ? data.stateName
                  : props.present?.toLowerCase() === 'sector'
                    ? data.Sector_T24Code
                    : props.present?.toLowerCase() === 'industry'
                      ? data.Industry_T24Code
                      : data
              }
            >
              {props.present?.toLowerCase() === 'states'
                ? data.stateName
                : props.present?.toLowerCase() === 'sector'
                  ? data.Sector
                  : props.present?.toLowerCase() === 'industry'
                    ? data.Industry
                    : data}
            </option>
          ))}
    </select>
  </Styled>
));

const Styled = styled.div`
  margin-top: 2rem;

  select {
    padding: 1rem 5rem 1rem 1rem;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #5fb19c;
    outline: none;
  }

  label {
    font-size: 1.4rem;
  }
`;

export default DropDownInput;
