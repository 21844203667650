import React from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';
import Button from 'components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import * as SignUpActions from 'store/actions/signUpForm';
import styled from 'styled-components';

const customStyles = {
  content: {
    borderRadius: '10px',
    position: 'relative',
    margin: 'auto',
    inset: '0',
  },
};
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0 0 0 / 54%)';
export default function TinValidation(props) {
  const { signUpForm } = useSelector((state) => state);
  const dispatch = useDispatch();
  const onValidateCompanyHandler = () => {
    dispatch(SignUpActions.SaveActivePage('newExitingCreateUser'));
  };
  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      isOpen={props.modalIsOpen}
      onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="bvnValidation"
    >
      <Styled>
        <div className="closebtn">
          <FiX size={20} color="#505050" onClick={props.closeModal} />
        </div>
        <div>
          <p className="title">Tin Validation</p>
          <div>
            <small className="label">Company Name </small>
            <p className="company_name">{signUpForm.tinDetails.taxPayerName}</p>
            <p className="correct">Is this correct?</p>
          </div>
          <div className="buttonRow">
            <p onClick={props.closeModal} className="noItsNot">
              No, its not
            </p>
            <Button
              title="Yes, it is"
              mode="1"
              onClick={onValidateCompanyHandler}
            />
          </div>
        </div>
      </Styled>
    </Modal>
  );
}
const Styled = styled.div`
  margin-top: 1rem;
  color: #505050;
  width: 400px;
  .closebtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  input {
    padding: 1rem;
    width: 100%;
    font-size: 1.6rem;
    display: block;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 1px 6px rgba(170, 170, 170, 0.25);
    background-color: #e5e5e5;
  }
  .title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
  }
  .label {
    font-size: 1.4rem;
    color: #505050;
  }
  .company_name {
    background-color: #e5e5e5;
    padding: 1rem;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .correct {
    margin: 2rem 0;
    color: ${(props) => props.theme.colors.primary};
  }
  .buttonRow {
    display: flex;
    margin-top: 2rem;
    align-items: center;
    justify-content: flex-end;
    color: ${(props) => props.theme.colors.primary};
    button {
      margin-left: 2rem;
    }
  }
  .noItsNot {
    cursor: pointer;
  }
`;
