import React from 'react';
import Main from 'components/ObliqueRiskRating/DashBoard/Upload';
import { Helmet } from 'react-helmet-async';

export default function Upload() {
  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5' }} />
      <title>Gazelle | Gazelle by Sterling</title>
      <Main />
    </>
  );
}
