import React from 'react';
import { useSelector } from 'react-redux';
import ExistingCustomer from './ExistingCustomer';
import NewCustomer from './NewCustomer';

export default function Index() {
  const { signUpForm } = useSelector((state) => state);

  return (
    <div>
      {signUpForm?.customerType === 'New' ? (
        <NewCustomer />
      ) : (
        <ExistingCustomer />
      )}
    </div>
  );
}
