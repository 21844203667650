export const ADD_FACILITY_QUESTIONS = 'ADD_FACILITY_QUESTIONS';
export const UPDATE_FACILITY_DETAIL = 'UPDATE_FACILITY_DETAIL';
export const UPDATE_FACILITY_SUB_PURPOSE_RESPONSE = 'UPDATE_FACILITY_SUB_PURPOSE_RESPONSE';
export const UPDATE_FACILITY_DETAIL_DURATION = 'UPDATE_FACILITY_DETAIL_DURATION';
export const UPDATE_FACILITY_DETAIL_AMOUNT = 'UPDATE_FACILITY_DETAIL_AMOUNT';
export const UPDATE_FACILITY_QUESTIONS_ANSWERED = 'UPDATE_FACILITY_QUESTIONS_ANSWERED';
export const UPDATE_FACILITY_GENERAL_QUESTIONS_ANSWERED = 'UPDATE_FACILITY_GENERAL_QUESTIONS_ANSWERED';
export const UPDATE_FRR_RESPONSE_ANSWERED = 'UPDATE_FRR_RESPONSE_ANSWERED';
export const UPDATE_FACILITY_PURPOSE_QUESTIONS_ANSWERED = 'UPDATE_FACILITY_PURPOSE_QUESTIONS_ANSWERED';
export const UPDATE_FRR_QUESTIONS = 'UPDATE_FRR_QUESTIONS';
export const EMPTY_FRR_QUESTIONS = 'EMPTY_FRR_QUESTIONS';
export const UPDATE_FACILITY_ID = 'UPDATE_FACILITY_ID';
