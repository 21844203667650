import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Calculator from 'components/OverLays/Calculator';
import { useSelector, useDispatch } from 'react-redux';
import { CalculatorToggle } from 'store/actions/calculator';
import { FiMenu, FiX } from 'react-icons/fi';
import {
  ToggleSvg,
  CalculatorSvg,
  SignInSvg,
  AnchorSvg,
  QuestionCircleSvg,
  LogoSvg,
} from 'theme/icons';

export default function Navbar() {
  const { calculator } = useSelector((state) => state);
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
  const dispatch = useDispatch();
  const handleCalculatorToggle = () => {
    dispatch(CalculatorToggle());
  };
  const mobileMenuHandler = () => {
    setMobileMenuToggle((prev) => !prev);
  };
  return (
    <Styled>
      <div className="nav-wrapper maxWidth-1440">
        <div className="logo">
          <Link to="/" className="nav_link">
            <LogoSvg width="100px" className="nav_link_svg" height="40px" />
          </Link>
        </div>
        <div className="nav-links">
          <Link to="/how-it-works" className="nav_link">
            <ToggleSvg width="25px" stroke="#D9B08C" className="nav_link_svg" />
            <p>How it Works</p>
          </Link>
          <div className="nav_link" onClick={handleCalculatorToggle}>
            <CalculatorSvg
              width="25px"
              stroke="#D9B08C"
              className="nav_link_svg"
            />
            <p className="nav_link_txt">Loan Calculator</p>
          </div>
          <Link to="/support" className="nav_link">
            <AnchorSvg width="25px" stroke="#D9B08C" className="nav_link_svg" />
            <p>Support</p>
          </Link>
          <Link to="/faq" className="nav_link">
            <QuestionCircleSvg
              width="25px"
              stroke="#D9B08C"
              className="nav_link_svg"
            />
            <p>FAQs</p>
          </Link>
          <Link to="/auth/login" className="nav_link">
            <SignInSvg
              width="25px"
              height="16px"
              stroke="#D9B08C"
              className="nav_link_svg"
              strokeWidth=".05px"
            />
            <p>Sign In</p>
          </Link>
        </div>
      </div>
      <div className="mobile-nav">
        <Link to="/">
          <LogoSvg width="100px" className="logo" height="40px" />
        </Link>
        <div className="mobile-menu-wrapper">
          {mobileMenuToggle ? (
            <FiX color="#d9b08c" size="30" onClick={mobileMenuHandler} />
          ) : (
            <FiMenu color="#d9b08c" size="30" onClick={mobileMenuHandler} />
          )}
        </div>

        {mobileMenuToggle ? (
          <nav className="mobile-nav-dropdown">
            <Link to="/">Home</Link>
            <Link to="/how-it-works">How it Works</Link>
            <Link to="/support">Support</Link>
            <Link to="/faq">FAQs</Link>
            <Link to="/auth/login">Sign In</Link>
          </nav>
        ) : null}
      </div>
      {calculator.display && (
        <Calculator handleCalculatorToggle={handleCalculatorToggle} />
      )}
    </Styled>
  );
}
const Styled = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: ${(props) => props.theme.colors.primary};
  height: 60px;
  @media (max-width: 479px) {
    height: 50px;
  }

  /* position: relative; */
  .mobile-nav {
    position: relative;
    height: 100%;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    @media (max-width: 479px) {
      display: flex;
    }
    .logo {
      font-size: 20px;
      color: #fff;
    }
    .mobile-nav-dropdown {
      position: absolute;
      width: ${window.innerWidth}px;
      z-index: 10;
      left: 0px;
      top: 50px;
      background: #004c4e;
      padding-left: 20px;
      padding-bottom: 20px;
      a {
        display: block;
        padding: 10px;
        font-size: 14px;
        color: #d9b08c;
      }
    }
    .mobile-menu-wrapper {
      position: relative;
      cursor: pointer;
    }
  }
  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-left: 20px;
    border-bottom: 1px solid rgba(217, 176, 140, 0.5);
    @media (max-width: 479px) {
      display: none;
    }
    & > div {
      display: flex;
      align-items: center;
    }
  }
  .maxWidth-1440 {
    max-width: 1440px;
    margin: 0 auto;
  }

  .nav_link {
    text-align: center;
    color: #d9b08c;
    font-size: 11px;
    display: flex;
    align-items: center;
    padding-right: 40px;
    p {
      font-size: 12px;
    }
  }
  .nav_link_svg {
    margin-right: 2px;
    vertical-align: middle;
    @media (max-width: 700px) {
      display: none;
    }
  }
  .nav_link_txt {
    color: #d9b08c;
    cursor: pointer;
  }
`;
