import React, { useState } from 'react';
import Navbar from 'components/Home/Navbars/Navbar';
import styled from 'styled-components';
import authApis from 'apis/user';
import Button from 'components/Buttons';

import {
  ChartMsgSvg, MailInSvg, LocationSvg, PhoneRingSvg
} from 'theme/icons';

export default function Support() {
  const [loading, setIsLoading] = useState(false);
  const [ optionAnswer, setOptionAnswer ] = useState();
  const [status, setStatus] = useState('');
  const inputHandler = async (e) => {
    e.preventDefault();
    const inputs = e.target;

    const payLoad = {};
    for (let i = 0; i < inputs.length - 1; i++) {
      payLoad[inputs[i].name] = inputs[i].value;
    }

    try {
      setIsLoading(true);
      const res = await authApis.postSupportMessage(payLoad);
      if (res.data.data == 'Successful') {
        inputs.reset();
        setStatus('Successful');
      }
    } catch (error) {
      setStatus(error.message);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setStatus('');
      }, 5000);
    }
  };
  return (
    <Styled>
      <Navbar />
      <div className="container-support">
        <h3 className="sub_title">Support</h3>
        <div className="contact-layer">
          <div>
            <div className="contact-layer-box">
              <PhoneRingSvg />
              <p className="contact-layer-box-txt">Call Us:</p>
            </div>
            <div className="contact-layer-box2">
              <p>+234 904 343 9693</p>
            </div>
          </div>
          <div>
            <div className="contact-layer-box">
              <MailInSvg />
              <p className="contact-layer-box-txt">Email Us:</p>
            </div>
            <div className="contact-layer-box2">
              <p>gazelle@sterling.ng</p>
            </div>
          </div>
          <div>
            <div className="contact-layer-box">
              <LocationSvg />
              <p className="contact-layer-box-txt">Locate Us</p>
            </div>
            <div className="contact-layer-box2">
              <p>20, Marina, Lagos</p>
            </div>
          </div>
        </div>
        <div className="send-message">
          <div className="send-message-box">
            <ChartMsgSvg />
            <h4>Send Us a message</h4>
          </div>
          <div className="status-msg">
            <p>{status}</p>
          </div>
          <form className="send-message-col" onSubmit={inputHandler}>
            <div className="send-message-col-left">
              <div>
                <p>Surname*</p>
                <input required type="text" name="surname" minLength="2" />
              </div>
              <div>
                <p>First Name*</p>
                <input required type="text" name="firstname" minLength="2" />
              </div>
              <div>
                <p>Phone Number*:</p>
                <input
                  required
                  type="number"
                  name="phoneNo"
                  pattern="[0-9]{11}"
                  title="08012340000 11 digits"
                />
              </div>
              <div>
                <p>What can we help you with?*</p>
                <select
                  onChange={e => {
                    setOptionAnswer(e.target.value);
                  }
                  }
                  value={optionAnswer}
                  name="type" 
                  required
                >
                  <option readOnly value="">
                    Select
                  </option>
                  <option value="Personal Profile Creation and Sign-Up">
                    Personal Profile Creation and Sign-Up
                  </option>
                  <option value="Business Profile Creation">
                    Business Profile Creation
                  </option>
                  <option value="Profile Update">Profile Update</option>
                  <option value="Delayed Dispute Resolution">
                    Delayed Dispute Resolution
                  </option>
                  <option value="Use of the Platform">
                    Use of the Platform
                  </option>
                  <option value="Business Advisory">Business Advisory</option>
                  <option value="General Enquiries">General Enquiries</option>
                  <option value="Loan Application">Loan Application</option>
                </select>
              </div>
            </div>
            <div className="send-message-col-right">
              <div>
                <p>Email Address*:</p>
                <input required type="email" name="email" />
              </div>
              <div>
                <p>Message*</p>
                <textarea
                  minLength="5"
                  required
                  name="message"
                  cols="30"
                  rows="10"
                />
              </div>
              <Button
                loader={loading}
                type="submit"
                title="Send Message"
                mode="1"
              />
            </div>
          </form>
        </div>
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  color: #004c4e;
  .sub_title {
    font-weight: 500;
  }
  .contact-layer-box {
    display: flex;
    align-items: center;
  }
  .status-msg {
    text-align: center;
    color: #4285f4;
    font-weight: 500;
  }
  .container-support {
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 5px;
    @media (max-width: 479px) {
      padding: 10px;
      margin: 30px 10px;
    }
  }
  .contact-layer {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: calc(100% / 3);
    }
    @media (max-width: 479px) {
      & > div {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .contact-layer-box2 p {
    color: #505050;
    font-size: 12px;
  }
  .send-message {
    padding: 10px;
    background: #e2f1ed;
  }
  .send-message-col {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  }
  .send-message-col-left {
    width: 50%;
    p {
      font-size: 14px;
    }

    div {
      margin-bottom: 10px;
    }
    @media (max-width: 479px) {
      width: 100%;
    }
  }
  input,
  select {
    width: 90%;
    padding: 8px;
    border: thin solid #5fb19c;
    border-radius: 5px;
    @media (max-width: 479px) {
      width: 100%;
    }
  }
  select {
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right 0.9em top 50%;
    background-size: 0.65em auto;
  }
  .send-message-col-right {
    width: 50%;
    @media (max-width: 479px) {
      width: 100%;
    }
    p {
      font-size: 14px;
    }
    textarea {
      width: 90%;
      border-radius: 5px;
      resize: none;
      border: 1px solid #5fb19c;
      padding: 5px;
      height: 160px;
      @media (max-width: 479px) {
        width: 100%;
      }
    }
    button {
      margin-top: 20px;
      padding: 8px 20px;
      background: #004c4e;
      color: #d9b08c;
      border: none;
      border-radius: 5px;
    }
  }
  .contact-layer-box-txt {
    padding-left: 5px;
  }
  .send-message-box {
    display: flex;
  }
`;
