import { apiWithToken } from './axiosInterceptor';

const request = {
  orrDocumentBySubSectorId: (subSectorId) => {
    const method = 'get';
    const url = '/api/Settings/GetDocumentUploadBySubsectorId';
    return apiWithToken({
      method,
      url,
      params: {
        subSectorId,
      },
    });
  },
  postOrrDocument: ({ documentUrl, documentId, businessId }) => {
    const method = 'post';
    const url = '/api/Settings/CreateUploadedDocuments';
    return apiWithToken({
      method,
      url,
      data: {
        documentUrl,
        documentId,
        businessId,
        status: 'Uploaded',
      },
    });
  },
  getOrrDocuments: (businessId) => {
    const method = 'get';
    const url = '/api/Settings/GetUploadedDocumentsByBusinessId';
    return apiWithToken({
      method,
      url,
      params: {
        businessId,
      },
    });
  },
  updateOrrDocuments: (data, params) => {
    const method = 'put';
    const url = '/api/Settings/UpdateUploadedDocuments';
    return apiWithToken({
      method,
      url,
      data,
      params: {
        uploadId: params.uploadId,
        model: data
      },
    });
  },

  trackerDocumentStatus: ({ businessId, subSectorId }) => {
    const method = 'get';
    const url = '/api/Settings/CheckIfDocumentUploadIsCompletedBySubsectorId';
    return apiWithToken({
      method,
      url,
      params: {
        businessId,
        subSectorId,
      },
    });
  },
  getSubSectorImage: ({ SubSectorId }) => {
    const method = 'get';
    const url = '/api/Settings/GetImageUrlBySubSectorId';
    return apiWithToken({
      method,
      url,
      params: {
        SubSectorId,
      },
    });
  },
};
export default request;
