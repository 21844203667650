import { useState, useEffect } from 'react';
import { adminUserApis } from 'apis';
import { useSelector } from 'react-redux';

export default function useOrrActive() {
  const { auth } = useSelector((state) => state);
  const [isOrrActive, setOrrActive] = useState(true);
  const { businessId } = auth?.user;
  useEffect(() => {
    const isActive = true;
    const getAnswers = async () => {
      try {
        const res = await adminUserApis.getGenericAnswers(businessId);
        if (res?.data?.data?.length > 0) {
          return setOrrActive(false);
        }
      } catch (error) {}
    };
    isActive && getAnswers();
    return () => {};
  }, [businessId]);
  return [isOrrActive];
}
