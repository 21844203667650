import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import errorHandler from 'helpers/errorHandler';
import { OfferLetter } from 'apis';

export const useOfferLetters = () => {
  const [offerLetters, setOfferLetters] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const businessId = useSelector(
    (state) => state.business.businessProfiles[0].businessId
  );
  useEffect(() => {
    const getOfferLetters = async () => {
      setLoading(true);

      try {
        const response = await OfferLetter.getOfferLetters(
          businessId
        );
        setOfferLetters(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    getOfferLetters();
  }, []);

  return [offerLetters, loading, setLoading];
};
