import { apiWithToken, apiWithoutToken } from './axiosInterceptor';

const request = {
  userLogout: (username) => {
    const method = 'post';
    const url = '/api/Verification/Logout';
    return apiWithoutToken({
      method,
      url,
      data: {
        username,
        isAdmin: false
      },
    });
  },
  getVerifiedORRResponse: (id) => {
    const method = 'get';
    const url = '/api/Verification/GetORRscoreByBusinessId';
    return apiWithToken({
      method,
      url,
      params: { businessId: id },
    });
  },
  getFlaggedQuestions: ({ businessId }) => {
    const method = 'get';
    const url = '/api/Verification/GetFlaggedOrrQuestionsByBusinessId';
    return apiWithToken({
      method,
      url,
      params: {
        businessId,
      },
    });
  },
  updateFlaggedQuestionById: ({ data }) => {
    const method = 'put';
    const url = '/api/Verification/UpdateFlaggedOrrQuestionsByOrrResponseId';
    return apiWithToken({
      method,
      url,
      data,
    });
  },
};

export default request;
