import React, { useState } from 'react';
import { NextButton, PrevButton } from 'components/Buttons';
import styled from 'styled-components';
import useSubSectorImage from 'hooks/useSubSectorImage';
import { FiInfo } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validateAnswer } from 'helpers/questionsAnswers';
import Question from '../Questions/OtherQuestions/QuestionTypes';

export default function Controller(props) {
  const [subSectorImage, isImageLoading] = useSubSectorImage();
  const [star, setStar] = useState(0);
  const [selected, setSelected] = useState({});
  const [input, setInput] = useState('');
  const [selectedMultiple, setSelectedMultiple] = useState([]);
  const navigate = useNavigate();

  const prevHandler = () => {
    if (props.currentQuestionIndex == 0) {

    } else if (props.currentQuestionIndex >= 1) {
      if (
        props.questions[props.currentQuestionIndex - 1].questionClass != 'S'
      ) {
        props.setCurrentQuestionIndex((prev) => (prev -= 1));
      } else {
        const getPrimaryQuestion = props.questions.filter(
          (elem) => elem.followupId
            == props.questions[props.currentQuestionIndex - 1].questionId
        );
        const triggerAnswer = getPrimaryQuestion[0]?.selectedAnswer?.answer?.toUpperCase();
        if (
          getPrimaryQuestion.length > 0
          && triggerAnswer == getPrimaryQuestion[0].followUpTrigger.toUpperCase()
        ) {
          props.setCurrentQuestionIndex((prev) => (prev -= 1));
        } else {
          if (props.currentQuestionIndex - 2 <= 0) {
            props.setCurrentQuestionIndex((prev) => 0);
            return;
          }
          props.setCurrentQuestionIndex((prev) => (prev -= 2));
        }
      }
    } else {
      toast.error('Incomplete Field or Invalid Inputs');
    }
  };

  const nextHandler = () => {
    const quesLength = props?.questions?.length - 1;
    const entry = props.questions[props.currentQuestionIndex];

    if (props.currentQuestionIndex == quesLength && validateAnswer(entry)) {
      return props.submitHandler();
    } if (
      props.currentQuestionIndex < quesLength
      && validateAnswer(entry)
    ) {
      if (
        props.questions[props.currentQuestionIndex + 1].questionClass != 'S'
      ) {
        props.setCurrentQuestionIndex((prev) => (prev += 1));
        setStar(0);
        setSelected({});
        setInput('');
        return setSelectedMultiple([]);
      }
      const getPrimaryQuestion = props.questions.filter(
        (elem) => elem.followupId
            == props.questions[props.currentQuestionIndex + 1].questionId
      );
      const triggerAnswer = getPrimaryQuestion[0]?.selectedAnswer?.answer?.toUpperCase();
      if (
        getPrimaryQuestion.length > 0
          && triggerAnswer == getPrimaryQuestion[0].followUpTrigger.toUpperCase()
      ) {
        props.setCurrentQuestionIndex((prev) => (prev += 1));
        setStar(0);
        setSelected({});
        setInput('');
        return setSelectedMultiple([]);
      }
      if (props.currentQuestionIndex + 2 > quesLength) {
        return props.submitHandler();
      }
      // last questions
      props.setCurrentQuestionIndex((prev) => (prev += 2));
      setStar(0);
      setSelected({});
      setInput('');
      return setSelectedMultiple([]);
    }
    toast.error('Incomplete Field or Invalid Inputs');
  };

  return (
    <Styled>
      <main className="collateral_ques_main">
        <div className="flex items-center rounded-sm p-4 mb-4 bg-[#d1e8e2] text-[#664d03]">
          <FiInfo color="#D45F36" size="30" />
          <p className="ml-4">
            {
              props?.feedBackDetails[props?.currentQuestionIndex]
                ?.feedbackComment
            }
          </p>
        </div>
        {props.questions && (
          <>
            <div className="collateral_ques_container">
              <Question
                index={props.currentQuestionIndex}
                question={props?.questions[props.currentQuestionIndex]}
                questionLength={props?.questions?.length}
                updateQuestionsPoolHandler={props.updateCurrentQuestion}
                star={star}
                setStar={setStar}
                selected={selected}
                setSelected={setSelected}
                input={input}
                setInput={setInput}
                selectedMultiple={selectedMultiple}
                setSelectedMultiple={setSelectedMultiple}
              />
            </div>
            <div className="collateral_button_section">
              {props?.currentQuestionIndex == 0 ? (
                <div />
              ) : (
                <PrevButton onClick={() => prevHandler()} title="Back" />
              )}
              <NextButton onClick={() => nextHandler()} title="Next" />
            </div>
          </>
        )}
      </main>
      <aside className="collateral_ques_aside">
        <div className="img-wrapper">
          <img
            src={subSectorImage.length > 2 ? subSectorImage : undefined}
            alt=""
          />
        </div>
      </aside>
    </Styled>
  );
}

const Styled = styled.div`
  margin: 0 auto;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;

  .collateral_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    min-height: 45rem;
    background-color: #fff;
    display: flex;
  }

  .collateral_button_section {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
  }
  .collateral_ques_main {
    width: 65%;
    padding-top: 8rem;
    margin: 0 auto;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  .collateral_ques_aside {
    width: 28%;
    text-align: center;
    padding-top: 8rem;
    @media (max-width: 1200px) {
      width: 30%;
    }
    @media (max-width: 85rem) {
      display: none;
    }
    button {
      background: #004c4e;
      color: #fff;
    }
    .img-wrapper {
      height: 50rem;
      overflow: hidden;
      margin-bottom: 3.5rem;
      border-radius: 1rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
