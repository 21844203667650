import React, { useState } from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';
import styled from 'styled-components';
import BvnInput from './BvnInput';
import BvnOtp from './BvnOtp';

const customStyles = {
  content: {
    borderRadius: '10px',
    position: 'relative',
    margin: 'auto',
    inset: '0',
  },
};
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0 0 0 / 54%)';
export default function BvnValidation(props) {
  const [bvnPage, setBvnPage] = useState(false);
  const closeModalHandler = () => {
    setBvnPage(false);
    props.closeModal();
  };
  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      isOpen={props.modalIsOpen}
      onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="bvnValidation"
    >
      <Styled>
        <div className="closebtn">
          <FiX size={20} color="#505050" onClick={closeModalHandler} />
        </div>
        {bvnPage === false ? (
          <BvnInput setBvnPage={setBvnPage} userData={props.userData} />
        ) : (
          <BvnOtp onBvnValidationHandler={props.onBvnValidationHandler} />
        )}
      </Styled>
    </Modal>
  );
}
const Styled = styled.div`
  margin-top: 1rem;
  color: #505050;
  .closebtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
`;
