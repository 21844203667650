import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

function PieChart({
  text, color1, title, percent
}) {
  return (
    <div className="pieChart">
      <CircularProgressbar
        strokeWidth={15}
        value={percent}
        text={text}
        styles={{
          text: { fontSize: '12px', fill: '#000' },
          path: {
            stroke: color1,
          },
        }}
      />
      <p style={{ fontSize: '12px', textAlign: 'center', color: '#00989C' }}>
        {title}
      </p>
    </div>
  );
}
export default PieChart;
