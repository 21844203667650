import { apiWithToken, apiWithoutToken } from './axiosInterceptor';

const request = {
  getBusinessByPassportId: (data) => {
    const method = 'get';
    const url = '/api/Business/BusinessByPassportId';
    return apiWithToken({
      method,
      url,
      params: { passportId: data },
    });
  },
  getBusinessByPassportIdWithoutToken: (data, token) => {
    const method = 'get';
    const url = '/api/Business/BusinessByPassportId';
    return apiWithoutToken({
      method,
      url,
      params: { passportId: data },
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getBusinessByPassportIdAndBusinessId: (passportId, businessId) => {
    const method = 'get';
    const url = '/api/Business/GetBusinessByPassportIdByBusinessId';
    return apiWithToken({
      method,
      url,
      params: { passportId, businessId }
    });
  },
  updateBusinessProfile: (data) => {
    const method = 'put';
    const url = '/api/Business/UpdateBusinessByBusinessId';
    return apiWithToken({
      method,
      url,
      data,
      params: { businessId: data.businessId },
    });
  },
  updateBusinessStatus: ({ businessId, profileStatus, isEditable }) => {
    const method = 'put';
    const url = '/api/Business/UpdateBusinessStatus';
    return apiWithToken({
      method,
      url,
      params: {
        businessId,
        profileStatus,
        isEditable,
      },
    });
  },
  // createBusiness: (data) => {
  //   const method = 'post';
  //   const url = '/api/Business/CreateBusiness';
  //   const tempToken = sessionStorage.getItem('temp_token');
  //   return apiWithoutToken({
  //     method,
  //     url,
  //     data,
  //     headers: {
  //       Authorization: `bearer ${tempToken}`,
  //     },
  //   });
  // },
  getAccountOpeningParametersByPassportId: (passportId) => {
    const method = 'get';
    const url = '/api/Business/GetAccountOpeningParameters';
    return apiWithToken({
      method,
      url,
      params: { passportId }
    });
  },
};
export default request;
