import React from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';
import Button from 'components/Buttons';
import styled from 'styled-components';

const customStyles = {
  content: {
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    border: 'none',
    position: 'relative',
    margin: 'auto',
    top: '0',
  },
};
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0 0 0 / 54%)';
export default function MessageModal(props) {
  const closeHandler = () => {
    props.closeModal();
  };
  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="SignUpSuccessMessage"
    >
      <Styled>
        <div className="header">
          <p>
            {' '}
            {props?.data?.subject}
          </p>
          <div className="closeBtn">
            <FiX size={20} color="#505050" onClick={closeHandler} />
          </div>
        </div>
        <div
          className="main"
          dangerouslySetInnerHTML={{ __html: props?.data?.message }}
        />
        <div className="footer">
          <Button mode="1" title="Okay" onClick={closeHandler} />
        </div>
      </Styled>
    </Modal>
  );
}
const Styled = styled.div`
  color: #505050;
  width: 50rem;
  .header {
    color: #004c4e;
    border-bottom: 1px solid #e5e5e5;
    p {
      font-size: 1.6rem;
    }
  }
  .main {
    margin: 1rem 0;
    font-size: 1.4rem;
  }
  .closeBtn {
    position: absolute;
    top: 1rem;
    right: 2rem;
    cursor: pointer;
  }
  p {
    text-align: justify;
    font-size: 1.4rem;
  }
  .footer {
    text-align: right;
  }
`;
