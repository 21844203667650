import React from 'react';
import styled from 'styled-components';
import StepOne from 'assets/images/how-it-works1.svg';
import StepTwo from 'assets/images/how-it-works2.svg';
import StepThree from 'assets/images/how-it-works3.svg';
import StepFour from 'assets/images/how-it-works4.svg';
import StepFive from 'assets/images/how-it-works5.svg';

export default function howItWorks() {
  return (
    <Styled>
      <div className="how-it-work-root">
        <h3 className="how-it-work-title">How Gazelle Works!</h3>
        <div className="how-it-work-items">
          <div className="how-it-work-item">
            <div className="step-circle" />
            <div className="how-it-work-item-left">
              <p className="how-it-work-item-left-txt1">Step 1</p>
              <p className="how-it-work-item-left-txt2">
                Create a Gazelle Profile
              </p>
              <p className="how-it-work-item-left-txt3">
                Set up a profile for your company and start your business
                evaluation process.
              </p>
            </div>
            <div className="how-it-work-item-right">
              <img src={StepOne} alt="" className="steps-images" />
            </div>
          </div>

          <div className="how-it-work-item">
            <div className="step-circle step-circle-two" />
            <div className="how-it-work-item-left">
              <p className="how-it-work-item-left-txt1">Step 2</p>
              <p className="how-it-work-item-left-txt2">
                Tell us about your Business
              </p>
              <p className="how-it-work-item-left-txt3">
                Answer all relevant questions on your business and upload
                required supporting documents.
              </p>
            </div>
            <div className="how-it-work-item-right">
              <img src={StepTwo} alt="" className="steps-images" />
            </div>
          </div>

          <div className="how-it-work-item">
            <div className="step-circle step-circle-three" />
            <div className="how-it-work-item-left">
              <p className="how-it-work-item-left-txt1">Step 3</p>
              <p className="how-it-work-item-left-txt2">
                Get your Business profile approved
              </p>
              <p className="how-it-work-item-left-txt3">
                Receive an obligor assessment report after we validate the
                information and documents provided.
              </p>
            </div>
            <div className="how-it-work-item-right">
              <img src={StepThree} alt="" className="steps-images" />
            </div>
          </div>

          <div className="how-it-work-item">
            <div className="step-circle step-circle-four" />
            <div className="how-it-work-item-left">
              <p className="how-it-work-item-left-txt1">Step 4</p>
              <p className="how-it-work-item-left-txt2">Apply for a Loan</p>
              <p className="how-it-work-item-left-txt3">
                Once your profile is approved, request for a loan and get a
                decision.
              </p>
            </div>
            <div className="how-it-work-item-right">
              <img src={StepFour} alt="" className="steps-images" />
            </div>
          </div>

          <div className="how-it-work-item">
            <div className="step-circle step-circle-five" />
            <div className="how-it-work-item-left">
              <p className="how-it-work-item-left-txt1">Step 5</p>
              <p className="how-it-work-item-left-txt2">Receive the Loan</p>
              <p className="how-it-work-item-left-txt3">
                Meet the conditions for the loan disbursement and receive your
                loan through your preferred channel.
              </p>
            </div>
            <div className="how-it-work-item-right">
              <img src={StepFive} alt="" className="steps-images" />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  color: #004c4e;
  @media (max-width: 479px) {
    padding: 0 10px;
  }
  .steps-images {
    width: 250px;
    @media (max-width: 479px) {
      width: 200px;
    }
  }
  .how-it-work-root {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 10px;
  }
  .how-it-work-title {
    margin: 40px 0;
  }
  .step-circle {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background: #d1e8e2;
    position: absolute;
    top: -10px;
    left: -10px;
  }
  .step-circle-two {
    background: #8fd2c1;
  }
  .step-circle-three {
    background: #5fb19c;
  }
  .step-circle-four {
    background: #00989c;
  }
  .step-circle-five {
    background: #004c4e;
  }
  .how-it-work-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    padding-left: 40px;
    padding-bottom: 80px;
    @media (max-width: 479px) {
      padding: 0 0 20px 40px;
      flex-direction: column;
    }
    &:nth-of-type(1) {
      border-left: 1px solid #d1e8e2;
    }
    &:nth-of-type(2) {
      border-left: 1px solid #d1e8e2;
    }
    &:nth-of-type(3) {
      border-left: 1px solid #d1e8e2;
    }
    &:nth-of-type(4) {
      border-left: 1px solid #d1e8e2;
    }
  }
  .how-it-work-item-left {
    width: 40%;
    padding-top: 50px;
    @media (max-width: 479px) {
      width: 100%;
      padding-top: 20px;
    }
  }
  .how-it-work-item-right {
    width: 250px;
    @media (max-width: 479px) {
      width: 100%;
      margin-top: 20px;
    }
  }
  .how-it-work-item-left-txt1 {
    color: #00989c;
    font-size: 16px;
    font-weight: 600;
  }
  .how-it-work-item-left-txt2 {
    color: #004c4e;
    font-size: 22px;
    font-weight: 600;
  }
  .how-it-work-item-left-txt3 {
    color: #323232;
    font-size: 12px;
  }
`;
