import { forwardRef } from 'react';
import styled from 'styled-components';

const DropDownInput = forwardRef((props, ref) => (
  <Styled
    disabled={props.disabled}
    onChange={props.onChange}
    ref={ref}
    className={props.className}
  >
    <option value="">
      {props.currentData ? props.currentData : 'Select option'}
    </option>
    {props?.dropDownData?.length > 0
        && props.dropDownData.map((data) => (
          <option
            key={
              props.present?.toLowerCase() === 'facility'
                ? data.facilityTypeId
                : props.present?.toLowerCase() === 'purpose'
                  ? data.purposeId
                  : props.present?.toLowerCase() === 'sub-purpose'
                    ? data.frrAnswerId
                    : data
            }
            value={
              props.present?.toLowerCase() === 'facility'
                ? data.facilityTypeId
                : props.present?.toLowerCase() === 'purpose'
                  ? data.purposeId
                  : props.present?.toLowerCase() === 'sub-purpose'
                    ? data.frrAnswerId
                    : data
            }
          >
            {props.present?.toLowerCase() === 'facility'
              ? data.facilityTypeName
              : props.present?.toLowerCase() === 'purpose'
                ? data.purposeName
                : props.present?.toLowerCase() === 'sub-purpose'
                  ? data.frrAnswer
                  : data}
          </option>
        ))}
  </Styled>
));

const Styled = styled.select`
  padding: 1rem 5rem 1rem 1rem;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid grey !important;
  margin-top: 1rem;

  :focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export default DropDownInput;
