import React, { useState } from 'react';
import Navbar from 'components/Home/Navbars/Navbar';
import styled from 'styled-components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import ReactHtmlParser from 'html-react-parser';
import _cloneDeep from 'lodash/cloneDeep';
import { QuestionAnswerSvg, AddSvg, RemoveSvg } from 'theme/icons';

function Accordion({ title, data, toggle }) {
  const [accordion, setAccordion] = useState(data || []);
  const [displayContent, setDisplayContent] = useState(toggle);
  const toggleAccordion = (id) => {
    const cloneData = _cloneDeep(accordion);
    const getIndex = cloneData.findIndex((elem) => elem.id == id);
    cloneData[getIndex].isOpen = !cloneData[getIndex].isOpen;
    setAccordion(cloneData);
  };
  return (
    <div>
      <div
        className="accordion-header"
        onClick={() => setDisplayContent((prev) => !prev)}
      >
        <h4>{title}</h4>
        <div className="toggle-icons">
          {displayContent ? (
            <IoIosArrowUp size="20" color="#5FB19C" />
          ) : (
            <IoIosArrowDown size="20" color="#5FB19C" />
          )}
        </div>
      </div>
      <div className={`closeAccordion ${displayContent && 'openAccordion'}`}>
        {accordion.map((elem, i) => (
          <div key={i} className="faq-container-item">
            <div
              className="faq-container-item-header"
              onClick={() => toggleAccordion(elem.id)}
            >
              <div
                className={`faq-container-item-flex ${
                  i == 0 ? 'faq-container-item-flex-first' : undefined
                }`}
              >
                <div>
                  <QuestionAnswerSvg color="#004C4E" size="25" />
                </div>
                <h5>{elem.title}</h5>
              </div>
              <div className="toggle-icons">
                {elem.isOpen ? (
                  <RemoveSvg size="20" color="#5FB19C" />
                ) : (
                  <AddSvg size="20" color="#5FB19C" />
                )}
              </div>
            </div>
            <div className={`closeAccordion ${elem.isOpen && 'openAccordion'}`}>
              {elem.body && (
                <div className="faq-container-item-body">
                  <p>{ReactHtmlParser(elem.body)}</p>
                </div>
              )}
              {elem.list && (
                <ul className="faq-container-item-ul">
                  {elem.list.map((elem, i) => (
                    <li key={i}>{elem}</li>
                  ))}
                </ul>
              )}
              {elem.body2 && (
                <div className="faq-container-item-body">
                  <p>{ReactHtmlParser(elem.body2)}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default function FAQ() {
  return (
    <Styled>
      <Navbar />
      <div className="container-root">
        <h3 className="container-root-header">
          Frequently Asked Questions (FAQs)
        </h3>
        <div className="faq-container">
          <Accordion data={about_gazelle} title="ABOUT GAZELLE" toggle />

          <Accordion data={how_gazelle_works} title="HOW GAZELLE WORKS" />

          <Accordion
            data={loan_application_criteria}
            title="LOAN APPLICATION CRITERIA"
          />

          <Accordion data={credit_decision} title="CREDIT DECISIONS" />

          <Accordion data={accessing_funds} title="ACCESSING FUNDS" />

          <Accordion data={privacy_security} title="PRIVACY AND SECURITY" />

          <Accordion data={support} title="SUPPORT" />
        </div>
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  margin-bottom: 30px;
  .container-root {
    max-width: 900px;
    margin: 0 auto;

    @media (max-width: 900px) {
      margin: 0 10px;
    }
  }
  .container-root-header {
    color: #004c4e;
    margin: 40px 0;
  }
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
    h4 {
      color: #00989c;
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
  .faq-container {
    padding: 20px;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    @media (max-width: 479px) {
      padding: 20px 10px;
    }
  }
  .toggle-icons {
    cursor: pointer;
  }
  .closeAccordion {
    visibility: hidden;
    overflow: hidden;
    max-height: 0;
    transition: visibility 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      max-height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    will-change: visibility max-height;
  }
  .openAccordion {
    visibility: visible;
    max-height: 1000px;
  }
  .faq-container-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
  }
  .faq-container-item-flex {
    display: flex;
    align-items: center;
    h5 {
      padding-left: 15px;
      @media (max-width: 479px) {
        padding-left: 5px;
      }
    }
  }
  .faq-container-item-flex-first {
    padding-top: 10px;
  }
  .faq-container-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #004c4e;
    cursor: pointer;
  }

  .faq-container-item-body {
    padding: 20px 0 30px 20px;
    font-size: 14px;
    a {
      color: blue;
    }
    p {
      font-size: 14px;
      color: #323232;
      line-height: 1.4;
    }
  }
  .faq-container-item-ul {
    padding: 20px 0 30px 30px;
    li {
      color: #323232;
      font-size: 14px;
      list-style-type: disc;
      margin-bottom: 10px;
      ::marker {
        font-weight: 600;
        color: #d9b08c;
      }
    }
  }
`;
const about_gazelle = [
  {
    id: 1,
    isOpen: true,
    title: 'What is Gazelle?',
    body: 'Gazelle by Sterling is a digital lending platform that provides credit to corporate and commercial businesses with the aim to democratize business lending and empower customers to take control of their credit applications. Our mission is to make the process of applying - and obtaining - loans easy and hassle-free for our customers.',
  },
  {
    id: 2,
    isOpen: false,
    title: 'Why Gazelle?',
    list: [
      'We provide you a seamless and more efficient way of submitting loan applications.',
      'You get a reduction in turn-around time for review, approval, and loan disbursement.',
      'Gazelle puts you in control of your credit process, by creating a direct communication link between you and your credit officials.',
    ],
  },
  {
    id: 3,
    isOpen: false,
    title: 'Who is eligible for loans on Gazelle?',
    list: [
      'All corporate businesses registered with the CAC in Nigeria.',
      'Businesses with audited financial statements.',
      'Businesses with valid Tax Identification Numbers (TIN).',
      'Profitable businesses with an annual turnover of at least N50,000,000 (fifty million naira).',
    ],
    body2:
      'Also, businesses with a prior history of borrowing have an added advantage.',
  },

  {
    id: 4,
    isOpen: false,
    title: 'What kinds of businesses does Gazelle finance?',
    body: 'We typically finance every business that meets the eligibility criteria. Although, we prioritize businesses in the Health, Education, Agriculture, Renewable Energy and Transportation (HEART) sectors.',
  },
];

const how_gazelle_works = [
  {
    id: 1,
    isOpen: false,
    title: 'How can I sign up on Gazelle?',
    body: "Visit <a target ='_blank' href='http://gazelle.ng/auth/register'>www.gazelle.com</a> to sign up, create your profile, and get started on your loan application process.",
  },
  {
    id: 2,
    isOpen: false,
    title: 'Who can fill the application?',
    body: 'Anyone who is authorized by your company to do so.',
  },
  {
    id: 3,
    isOpen: false,
    title:
      'What if my company decides to change the initial applicant on the platform?',
    body: "Please, notify the Gazelle team to deactivate this applicant’s access to your company’s profile using this <a target ='_blank' href='http://gazelle.ng/support'>resource</a>.",
  },
  {
    id: 4,
    isOpen: false,
    title: 'Does Gazelle charge a fee to register or review an application?',
    body: 'No, we do not. The loan application and review process is completely free. However, there are charges for certain independent third-party verifications such as legal search, property valuation, etc. These verifications will be carried out before loan disbursements.',
  },
  {
    id: 5,
    isOpen: false,
    title: 'How long does it take to complete a loan application?',
    body: 'You can complete your loan application within 60 minutes. ',
  },
  {
    id: 6,
    isOpen: false,
    title:
      'Can I go back to edit answers filled after advancing to other sections?',
    body: 'Yes, you can, but only before submission. Before you submit, you can review/edit all answers entered, as provided on the Summary page of the platform. There is also an option to save your application progress to return later, at any given time with the “Save and Exit” button.',
  },
  {
    id: 7,
    isOpen: false,
    title: 'Can I sign-up as an individual? ',
    body: "No, individuals are not eligible to sign up for loan applications on Gazelle. However, you can <a target ='_blank' href='https://www.myspecta.com/'>click here</a> to access a personal loan from Specta.",
  },
  {
    id: 8,
    isOpen: false,
    title: 'Can I change my disbursement mode after completing evaluation?',
    body: 'You can indicate your preferred disbursement mode during your facility application. This is however subject to approval.',
  },
  {
    id: 9,
    isOpen: false,
    title: 'Can I access the platform from outside of Nigeria?',
    body: 'Yes, you can apply for a loan on the platform from anywhere in the world. However, your business must be registered in Nigeria.',
  },
  {
    id: 10,
    isOpen: false,
    title: 'How often can I conduct a business evaluation on Gazelle?',
    body: 'As often as necessary, provided your company resolves the outstanding challenges from previous evaluations.',
  },
  {
    id: 11,
    isOpen: false,
    title: 'What is a Facility Evaluation?',
    body: 'Facility Evaluation is the process that follows after your company has successfully passed the business evaluation process. During the facility evaluation process, we learn more about your company’s funding needs, we evaluate the information provided, and then communicate the next steps, in terms of getting your loan request approved and disbursed.',
  },
];
const loan_application_criteria = [
  {
    id: 1,
    isOpen: false,
    title:
      'What key documents does my company need to provide to be eligible for a loan?',
    list: [
      'Company’s profile',
      'Latest Audited Financial Statement',
      'Relevant Valid Licenses',
      'IDs of the Principal Officers',
      'Certificate of Incorporation & MEMART',
      'Letter of Exposure/Borrowings from Other Banks',
    ],
  },

  {
    id: 3,
    isOpen: false,
    title: 'What types of collateral are acceptable on Gazelle?',
    body: 'You can find our list of acceptable collaterals <a download target=\'_blank\' href=\'https://businesslending.azureedge.net/web/assets/files/collateral_list.pdf\'>here</a>.',
  },

  {
    id: 4,
    isOpen: false,
    title: 'What do the colors mean?',
    body: 'The colors (Red, Green, and Yellow) show the categorization of the customer after the completion of the evaluation process using a predetermined algorithm. For instance;',
    list: [
      'A Red means that the customer does not meet our eligibility criteria.',
      'A Yellow means that the customer may qualify upon meeting certain criteria.',
      'A Green means that the customer meets our eligibility criteria. ',
    ],
  },
  {
    id: 5,
    isOpen: false,
    title:
      'Is meeting the eligibility criteria the same as passing the evaluation?',
    body: 'No, it is not. Information provided still has to be verified and authenticated before the business can successfully pass the evaluation process.',
  },
  {
    id: 6,
    isOpen: false,
    title: 'How often can I apply for a loan?',
    body: 'As often as needed.',
  },
];
const credit_decision = [
  {
    id: 1,
    isOpen: false,
    title: 'How do I get a credit decision?',
    list: [
      'Create a Gazelle profile.',
      'Provide all relevant information.',
      'Upload all supporting documents.',
      'Have a positive credit status with the credit bureau.',
    ],
  },
  {
    id: 2,
    isOpen: false,
    title: 'How long does it take to arrive at a credit decision?',
    body: 'You can get a credit decision within 48 hours subject to the completeness and correctness of the information and documentation provided.',
  },
  {
    id: 3,
    isOpen: false,
    title:
      'Is there a timeframe for me to reapply for a loan if I did not get a positive credit decision in the past?',
    body: 'No, there is not. You may reapply at any time.',
  },

  {
    id: 4,
    isOpen: false,
    title: 'What may disqualify me from getting a positive credit decision?',
    body: 'Reasons for disqualification may vary by case. However, the only constant across all possible scenarios is a negative credit status with the Credit Bureau.',
  },
  {
    id: 5,
    isOpen: false,
    title: 'What sort of credit history is preferred on Gazelle?',
    body: 'The ideal credit history on Gazelle reflects a record of decent repayment behavior on previous obligations, characterized by on-time and adequate repayments on your loans.',
  },
];
const accessing_funds = [
  {
    id: 1,
    isOpen: false,
    title: 'How do I get my loan disbursed or access funds?',
    list: [
      'Accept and execute the offer letter.',
      'Meet all terms and conditions for loan disbursement.',
      'Have an operational Sterling business account.',
      'Receive your loan. ',
    ],
  },
  {
    id: 2,
    isOpen: false,
    title:
      'Do I need a Sterling Bank business account to access a loan using Gazelle?',
    body: "Yes, you do. <a target='_blank' href='https://sterlingonlineaccount.sterling.ng/#/GetStarted'>Click here</a>. if you do not have an account to open a business account with Sterling Bank in 5 minutes.",
  },
  {
    id: 3,
    isOpen: false,
    title: 'What are the basic requirements for opening a business account?',
    list: [
      'Corporate References',
      'Board Resolution',
      'BVN of Directors',
      'ID card',
      'Company seal/ introduction letter',
      'CAC forms CO2, and CO7',
      'Tax Identification Number (TIN)',
      'Utility bill',
    ],
  },

  {
    id: 4,
    isOpen: false,
    title:
      'Can I receive my loan through another bank, even if I have a Sterling Bank business account?',
    body: 'No, you can not.',
  },
  {
    id: 5,
    isOpen: false,
    title: 'What fees do I pay to borrow?',
    body: 'You will be required to pay interest on the loan as well as other fees that are relevant to your facility type.',
  },
];
const privacy_security = [
  {
    id: 1,
    isOpen: false,
    title: 'How does Gazelle protect my data?',
    list: [
      'Your data will be protected under the data privacy laws against unauthorized third-party access and would be treated with due discretion.',
      'We use strong encryption mechanisms to protect your private information.',
    ],
    body2:
      "Your personal information is safe with us and only required to determine your eligibility. Read more about how your data is protected in our <a target='_blank' href='http://gazelle.ng/privacy-policy'>privacy policy.</a>",
  },
];
const support = [
  {
    id: 1,
    isOpen: false,
    title: 'When can I contact you?',
    body: 'Any time. We are available to help and guide you 24/7.',
  },
  {
    id: 2,
    isOpen: false,
    title: 'How can I contact you?',
    body: "By your preferred <a target ='_blank' href='https://gazelle.ng/support'>channel</a>",
  },
];
