import React from 'react';
import dayjs from 'dayjs';

const formatDate = (data) => dayjs(data).format('YYYY-MMM-DD');

export default function DetailsGrid({ details }) {
  const groupOne = (
    <>
      <div>
        <p className="detail-key">Collateral Seniority</p>
        <p className="detail-value">{details?.x?.collateralSeniorityName}</p>
      </div>
      <div>
        <p className="detail-key">Collateral Id</p>
        <p className="detail-value">{details?.x?.collateralId}</p>
      </div>
      <div>
        <p className="detail-key">Coverage Value</p>
        <p className="detail-value">{details?.x?.coverageValue}</p>
      </div>
      <div>
        <p className="detail-key">Security Value</p>
        <p className="detail-value">{details?.x?.securityValue}</p>
      </div>
      <div>
        <p className="detail-key">Utilized Value</p>
        <p className="detail-value">{details?.x?.utilizedValue}</p>
      </div>
      <div>
        <p className="detail-key">Unutilized Value</p>
        <p className="detail-value">{details?.x?.unUtilizedValue}</p>
      </div>
      <div>
        <p className="detail-key">Valuation Date</p>
        <p className="detail-value">{formatDate(details?.x?.valuationDate)}</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
    </>
  );
  const groupTwo = (
    <>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
    </>
  );
  const groupThree = (
    <>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
    </>
  );
  const groupFour = (
    <>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
    </>
  );
  const groupFive = (
    <>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
    </>
  );
  const groupSix = (
    <>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
    </>
  );
  const groupSeven = (
    <>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
      <div>
        <p className="detail-key">Bond Maturity Date</p>
        <p className="detail-value">15-Jun-2022</p>
      </div>
    </>
  );
  return <>{groupOne}</>;
}
