import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from 'components/FormikInputs/Input';
import Button from 'components/Buttons';
import { useSelector } from 'react-redux';
import { firsApis } from 'apis';
import { toast } from 'react-toastify';
import ErrorHandler from 'helpers/errorHandler';
import styled from 'styled-components';

export default function BvnOtp(props) {
  const { signUpForm } = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const validationSchema = yup.object({
    bvnOtp: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only Numbers')
      //   .min(6, 'Must be exactly 6 digits')
      //   .max(6, 'Must be exactly 6 digits')
      .required('Otp is required'),
  });
  const formik = useFormik({
    initialValues: {
      bvnOtp: '',
    },
    validationSchema,
    onSubmit: (values) => {
      bvnOtpValidation(values.bvnOtp);
    },
  });
  const bvnOtpValidation = async (otp) => {
    try {
      setLoader(true);
      const response = await firsApis.validateEmailPhoneOtp({
        phone: signUpForm.bvnDetails.phoneNumber,
        token: otp,
        email: signUpForm?.bvnDetails?.email || '',
      });

      if (
        response.data.status
        && response.data.data !== 'Invalid phone token!'
      ) {
        setLoader(false);
        return props.onBvnValidationHandler();
      }
      toast.error(response.data.data);
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoader(false);
    }
  };

  const resendOtp = async () => {
    try {
      setLoader(true);
      const response = await firsApis.generateEmailPhoneOtp({
        phone: signUpForm.bvnDetails.phoneNumber,
        email: signUpForm?.bvnDetails?.email || '',
      });
      if (response.data.data == '00') {
        toast.info('OTP Sent');
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <Styled>
      <p className="title">BVN OTP Verification</p>
      <p className="heading">
        An OTP has been sent to your registered mobile number and email address linked to your BVN.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          name="bvnOtp"
          type="textNumber"
          maxLength="6"
          required
          formik={formik}
          style={{
            backgroundColor: '#e5e5e5',
            textAlign: 'center',
            width: '60%',
            margin: 'auto',
          }}
          placeholder="Enter OTP"
        />

        <div className="submitBtn">
          <p className="resendOTP" onClick={resendOtp}>
            Resend OTP
          </p>
          <Button loader={loader} type="submit" title="Validate" mode="4" />
        </div>
      </form>
    </Styled>
  );
}
const Styled = styled.div`
  width: 300px;
  padding: 0rem 0rem 2rem 0rem;

  .heading {
    font-size: 1.4rem;
    text-align: center;
    padding: 1.5rem 0;
  }
  .title {
    font-size: 1.8rem;
    padding: 0rem 1rem 1rem 1rem;
    font-weight: 500;
    color: #004c4e;
  }
  .closeBtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .submitBtn {
    display: flex;
    justify-content: space-between;
  }
  .resendOTP {
    font-size: 1.2rem;
    text-decoration: underline;
    cursor: pointer;
  }
`;
