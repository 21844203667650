import React from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';
import Button from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import * as SignUpActions from 'store/actions/signUpForm';

const customStyles = {
  content: {
    borderRadius: '10px',
    backgroundColor: '#505050',
    border: 'none',
    position: 'relative',
    margin: 'auto',
  },
};
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0 0 0 / 54%)';
export default function AccCreateModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeHandler = () => {
    props.closeModal();
    navigate('/auth/login');
    dispatch(SignUpActions.ResetForm());
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="SignUpSuccessMessage"
    >
      <Styled>
        <div className="closeBtn">
          <FiX size={20} color="#fff" onClick={closeHandler} />
        </div>
        <p>
          Profile Created!
          {' '}
          <br />
          <br />
          An activation link has been
          {' '}
          <br />
          sent to
          {' '}
          {props.email}
          .
          <br />
          Kindly proceed to activate
          {' '}
          <br />
          {' '}
          your account.
        </p>
        <div className="buttonWrapper">
          <Button mode="2" title="Okay" onClick={closeHandler} />
        </div>
      </Styled>
    </Modal>
  );
}
const Styled = styled.div`
  margin-top: 1rem;
  color: #fff;
  .closeBtn {
    position: absolute;
    top: 1rem;
    right: 2rem;
    cursor: pointer;
  }
  p {
    text-align: center;
  }
  .buttonWrapper {
    text-align: center;
    button {
      margin-top: 2rem;
    }
  }
`;
