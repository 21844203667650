import React, { useEffect, useState } from 'react';
import authApis from 'apis/user';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/Buttons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import ErrorHandler from 'helpers/errorHandler';
import useNotify from 'hooks/useNotify';
import CustomInput from '../FormikInputs/Input';

export default function ChangePassword() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [tempPayload, setTempPayload] = useState({});
  const location = useLocation();
  const [setNotify] = useNotify();
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const payLoad = {
      passportId: search.get('pass'),
      code: search.get('code'),
    };
    const confirmCode = async () => {
      try {
        const res = await authApis.confirmForgetPassword(payLoad);
        if (res.data.status) {
          setTempPayload(res.data.data);
        } else {
          toast.error(res.data.data);
        }
      } catch (error) {
        ErrorHandler(error);
      }
    };
    confirmCode();
    return () => {};
  }, []);
  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .required('Username Cant Be Empty')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        'Min 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character(@$!%*#?&)'
      ),
    confirmPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  });
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoader(true);
        const newPayLoad = { ...tempPayload, newPassword: values.newPassword };
        const response = await authApis.createNewPassword(newPayLoad);
        if (response.data.status == true) {
          resetForm({});
          setNotify({
            show: true,
            type: 'ok',
            message: response.data.data,
          });
        } else {
          toast.error(response.data.data);
        }
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    },
  });
  return (
    <div>
      <Styled>
        <div className="form">
          <p className="title">New Password</p>
          <form onSubmit={formik.handleSubmit}>
            <CustomInput
              name="newPassword"
              label="New Password"
              type="password"
              required
              formik={formik}
            />
            <CustomInput
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              required
              formik={formik}
            />
            <p className="forgetPwd" onClick={() => navigate('/auth/login')}>
              Login ?
            </p>
            <div className="btnWrapper">
              <Button loader={loader} type="submit" title="Submit" mode="1" />
            </div>
          </form>
        </div>
      </Styled>
    </div>
  );
}
const Styled = styled.div`
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  justify-content: center;
  align-items: center;
  .title {
    color: #004c4e;
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
  .form {
    min-width: 30rem;
    max-width: 50rem;
    width: 100%;
    background: #fff;
    border-radius: 15px;
    padding: 4rem;
    border: 4px solid #d1e8e2;
    @media (max-width: 479px) {
      padding: 8rem 2rem;
      margin: 0 1rem;
    }
  }
  .forgetPwd {
    color: #004c4e;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .btnWrapper {
    display: flex;
    justify-content: flex-end;
  }
`;
