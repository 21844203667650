import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export default function finStatement(props) {
  return (
    <Styled>
      <table className="styled-table">
        <thead>
          <tr>
            <th />
            <th>
              {new Date(props?.finAnswers?.financialEndYearDate).getFullYear()}
            </th>
            <th>
              {new Date(props?.finAnswers?.financialEndYearDate).getFullYear()
                - 1}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-60">Revenue (₦)</td>
            <td>
              <NumberFormat
                name="revenueYear1"
                value={props?.finAnswers?.revenueYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="revenueYear2"
                value={props?.finAnswers?.revenueYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Cost of Sales (₦)</td>
            <td>
              <NumberFormat
                name="costOfSalesYear1"
                value={props?.finAnswers?.costOfSalesYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="costOfSalesYear2"
                value={props?.finAnswers?.costOfSalesYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60 calculateBold">Gross Profit (₦)</td>
            <td>
              <NumberFormat
                name="grossProfitYear1"
                value={props?.finAnswers?.grossProfitYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="grossProfitYear2"
                value={props?.finAnswers?.grossProfitYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Other Income (₦)</td>
            <td>
              <NumberFormat
                name="otherIncomeYear1"
                value={props?.finAnswers?.otherIncomeYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="otherIncomeYear2"
                value={props?.finAnswers?.otherIncomeYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Operating Expenses (₦)</td>
            <td>
              <NumberFormat
                name="operatingExpensesYear1"
                value={props?.finAnswers?.operatingExpensesYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="operatingExpensesYear2"
                value={props?.finAnswers?.operatingExpensesYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Depreciation & Amortisation (₦)</td>
            <td>
              <NumberFormat
                name="depreciationAmortisationYear1"
                value={props.finAnswers.depreciationAmortisationYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="depreciationAmortisationYear2"
                value={props.finAnswers.depreciationAmortisationYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60 calculateBold">Operating Profit (₦)</td>
            <td>
              <NumberFormat
                name="operatingProfitYear1"
                value={props?.finAnswers?.operatingProfitYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="operatingProfitYear2"
                value={props?.finAnswers?.operatingProfitYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Finance Income (₦)</td>
            <td>
              <NumberFormat
                name="financeIncomeYear1"
                value={props?.finAnswers?.financeIncomeYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="financeIncomeYear2"
                value={props?.finAnswers?.financeIncomeYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Finance Cost (₦)</td>
            <td>
              <NumberFormat
                name="financeCostYear1"
                value={props?.finAnswers?.financeCostYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="financeCostYear2"
                value={props?.finAnswers?.financeCostYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Tax (₦)</td>
            <td>
              <NumberFormat
                name="taxYear1"
                value={props?.finAnswers?.taxYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="taxYear2"
                value={props?.finAnswers?.taxYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60 calculateBold">Profit After Tax (₦)</td>
            <td>
              <NumberFormat
                name="profitAfterTaxYear1"
                value={props?.finAnswers?.profitAfterTaxYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="profitAfterTaxYear2"
                value={props?.finAnswers?.profitAfterTaxYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Styled>
  );
}
const Styled = styled.div`
  .calculateBold {
    font-weight: 600;
  }
  .styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    width: 100%;
  }
  .styled-table th,
  .styled-table td {
    font-size: 1.4rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
  }
  .styled-table th {
    background-color: #f2f2f2;
  }
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .styled-table input {
    min-width: 10rem;
    width: 100%;
    border: thin solid #ddd;
    height: 2.5rem;
    text-align: right;
  }
`;
