import styled from 'styled-components';

function Modal({
  children, onClick, iconId, id, header
}) {
  return (
    <Styled>
      <div className="Modal" id={id}>
        <div className={header ? 'close-icon-holder-header' : 'close-icon-holder'}>
          <p id="header">{header}</p>
          <i
            id={iconId}
            onClick={onClick}
            className="fas fa-times Close-icon"
          />
        </div>
        {children}
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: #0006;
  z-index: 5;

  .Modal {
    background-color: #333333;
    border-radius: 10px;
    padding: 1.5rem 1.5rem 2rem 1.5rem;
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  .close-icon-holder-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .close-icon-holder {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .Close-icon {
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
  }

  #header{
    font-size: 1.2rem;
    font-weight: 700;
  }
`;

export default Modal;
