import React, { useState } from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';
import { NextButton, PrevButton } from 'components/Buttons';
import _cloneDeep from 'lodash/cloneDeep';

const relationShipOption = [
  {
    text: 'Director',
    value: 'Director',
  },
  {
    text: 'Employee',
    value: 'Employee',
  },
];
export default function PrincipalOfficersForm3(props) {
  const [directorsList, setDirectorsList] = useState(props.directors);
  const switchHandler = (e, i) => {
    const cloneData = [...directorsList];
    cloneData[i].relationshipWithSterlingStaff = !cloneData[i].relationshipWithSterlingStaff;
    setDirectorsList(cloneData);
  };
  const nextHandler = (e) => {
    e.preventDefault();
    props.setDirectors(directorsList);
    props.nextHandler(props.next);
  };
  const prevHandler = () => {
    props.prevHandler(props.prev);
  };

  const inputHandler = (e, i) => {
    const cloneState = _cloneDeep(directorsList);
    const { name } = e.target;
    const { value } = e.target;
    cloneState[i][name] = value;
    setDirectorsList(cloneState);
  };
  return (
    <Styled onSubmit={nextHandler}>
      <div className="orr_ques_container">
        <div className="orr_ques_container_wrapper">
          <div className="title">
            <p>
              Do any of the Director(s) below have a relationship (Siblings,
              Uncle, Aunt, Niece, Nephew, Parents, Cousins, Grandparents, etc)
              with any member of staff in Sterling Bank?
            </p>
          </div>
          <section className="ques_section">
            {directorsList.map((elem, i) => (
              <div key={i}>
                <div className="ques_wrapper">
                  <div className="ques_title_wrapper">
                    <span>{i + 1}</span>
                    <p>{`${elem.firstName} ${elem.lastName}`}</p>
                  </div>
                  <Switch
                    checked={elem.relationshipWithSterlingStaff}
                    onChange={(e) => switchHandler(e, i)}
                    onColor="#d1e8e2"
                    onHandleColor="#fff"
                    offHandleColor="#808080"
                    offColor="#EEEEEE"
                    handleDiameter={25}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    checkedIcon={<div className="checkedIcon">Yes</div>}
                    uncheckedIcon={<div className="uncheckedIcon">No</div>}
                  />
                </div>
                {elem.relationshipWithSterlingStaff && (
                  <div className="conditional_question">
                    <input
                      type="text"
                      required
                      name="relationshipWithSterlingStaff_name"
                      placeholder="Enter Name"
                      value={elem.relationshipWithSterlingStaff_name}
                      onChange={(e) => inputHandler(e, i)}
                    />
                    <select
                      required
                      name="relationshipWithSterlingStaff_relationship"
                      value={elem.relationshipWithSterlingStaff_relationship}
                      onChange={(e) => inputHandler(e, i)}
                    >
                      <option value="" disabled>
                        Select Position
                      </option>
                      {relationShipOption.map((elem, i) => (
                        <option value={elem.value} key={i}>
                          {elem.text}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            ))}
          </section>
        </div>
      </div>
      <div className="orr_button_section">
        <PrevButton onClick={prevHandler} />
        <NextButton type="submit" />
      </div>
    </Styled>
  );
}

const Styled = styled.form`
  padding-top: 8rem;
  .uncheckedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.2rem;
    padding-right: 0.2rem;
    font-weight: 600;
    color: #808080;
  }
  .checkedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.2rem;
    padding-right: 0.2rem;
    font-weight: 600;
    color: #004c4e;
  }
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    height: 100%;
    overflow-y: scroll;
    height: 52rem;
    margin-bottom: 2rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .orr_ques_container_wrapper {
    padding: 2rem 4rem;
  }
  .title {
    text-align: center;
  }
  .ques_title_wrapper {
    display: flex;
    font-size: 1.4rem;
    span {
      margin-right: 1rem;
    }
  }
  .ques_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .ques_section {
    padding: 2rem 0;
  }
  .orr_button_section {
    display: flex;
    justify-content: space-between;
  }
  .conditional_question {
    display: flex;
    margin-bottom: 1.5rem;
    input,
    select {
      width: 100%;
      border-radius: 5px;
      border: thin solid #b8b8b8;
      padding: 0.8rem;
      margin-right: 1rem;
    }
  }
`;
