import React, { useEffect, useState } from 'react';
import Layout from 'components/Layouts/Default';
import styled from 'styled-components';
import { settingApis } from 'apis';
import { useSelector } from 'react-redux';

export default function Tracker() {
  const [documentStatus, setDocumentStatus] = useState('');
  const { business } = useSelector((state) => state);
  const {
    businessId,
    subSectorId,
    profileStatus,
    website,
    staffStrength,
    isVerified,
  } = business.businessProfiles[0];
  useEffect(() => {
    const getDocumentStatus = async () => {
      try {
        const res = await settingApis.trackerDocumentStatus({
          businessId,
          subSectorId,
        });
        setDocumentStatus(res.data.data != 'Not Completed');
      } catch (error) {
      }
    };
    getDocumentStatus();
    return () => {};
  }, []);
  const questionsChecker = new Map([
    ['orr-started', true],
    ['orr-management', true],
    ['orr-business', true],
    ['orr-financial', true],
  ]);
  return (
    <Styled>
      <Layout tabsId="tabs" activeBar="business-evaluation">
        <div className="box">
          <div className="Tracker-holder">
            <div className="header">
              <h1 className="header-title">Business Evaluation Tracker</h1>
            </div>
            <div className="tracker">
              <div className="tracker-wrapper">
                <p className="tracker-wrapper-header">Stage</p>
                <p className="tracker-wrapper-header">Status</p>
              </div>
              <div className="tracker-wrapper">
                <p>Profile Update</p>
                <p className="tracker-wrapper-status">
                  {website?.length > 2 && staffStrength?.length > 2
                    ? 'Completed'
                    : 'Not Completed'}
                </p>
              </div>
              <div className="tracker-wrapper">
                <p>Profile Evaluation Questions</p>
                <p className="tracker-wrapper-status">
                  {questionsChecker.has(profileStatus)
                    ? 'Not Completed'
                    : 'Completed'}
                </p>
              </div>
              <div className="tracker-wrapper">
                <p>Document Uploads</p>
                <p className="tracker-wrapper-status">
                  {documentStatus ? 'Completed' : 'Not Completed'}
                </p>
              </div>
              <div className="tracker-wrapper">
                <p>Profile Assessment and Verification</p>
                <p className="tracker-wrapper-status">
                  {isVerified ? 'Verified' : 'Not Verified'}
                </p>
              </div>
              <div className="tracker-wrapper">
                <p>Business Evaluation Report</p>
                <p className="tracker-wrapper-status">Not Started</p>
              </div>
              {/* <div className="orr_button_section">
                <Button
                  onClick={() => navigate('/app/orr/dashboard')}
                  mode="1"
                  title="Return Home"
                />
              </div> */}
            </div>
          </div>
        </div>
      </Layout>
    </Styled>
  );
}
const Styled = styled.div`
  max-height: 100vh;
  overflow: hidden;

  .box {
    width: 100%;
    margin: 0 auto;
    display: flex;
  }
  .header {
    margin: 0 auto;
    width: 70rem;
  }
  .header-title {
    color: #004c4e;
    margin: 2rem 0;
    font-weight: 500;
    font-size: 2rem;
  }
  .tracker {
    margin: 0 auto;
    width: 70rem;
    background: #ffffff;
    border: 6px solid #d1e8e2;
    border-radius: 15px;
    padding: 2rem 1rem;
  }
  .tracker-items {
    padding: 1rem;
  }
  .tracker-wrapper {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: thin solid #d1e8e2;
    font-size: 1.4rem;
  }
  .tracker-wrapper-header {
    font-weight: 500;
    font-size: 2rem;
    font-size: 1.6rem;
    color: #004c4e;
  }
  .tracker-wrapper-status {
    font-weight: 600;
    font-size: 1.3rem;
  }
  .orr_button_section {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }

  #tabs {
    position: fixed;
    top: 6.8rem;
    bottom: -5rem;
    left: 1rem;
    min-height: 100vh;
  }

  #header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }

  .Tracker-holder {
    margin-left: 5rem;
    margin-top: 3rem;
  }
`;
