import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export default function Liability(props) {
  return (
    <Styled>
      <table className="styled-table">
        <thead>
          <tr>
            <th />
            <th>
              {new Date(props?.finAnswers?.financialEndYearDate).getFullYear()}
            </th>
            <th>
              {new Date(props?.finAnswers?.financialEndYearDate).getFullYear()
                - 1}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-60">Non-Current Liability (₦)</td>
            <td>
              <NumberFormat
                name="nonCurrentLiabilityYear1"
                value={props?.finAnswers?.nonCurrentLiabilityYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="nonCurrentLiabilityYear2"
                value={props?.finAnswers?.nonCurrentLiabilityYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Long-Term Loans (₦)</td>
            <td>
              <NumberFormat
                name="longTermLoansYear1"
                value={props?.finAnswers?.longTermLoansYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="longTermLoansYear2"
                value={props?.finAnswers?.longTermLoansYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Current Liability (₦)</td>
            <td>
              <NumberFormat
                name="currentLiabilityYear1"
                value={props?.finAnswers?.currentLiabilityYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="currentLiabilityYear2"
                value={props?.finAnswers?.currentLiabilityYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Payables (₦)</td>
            <td>
              <NumberFormat
                name="payablesYear1"
                value={props?.finAnswers?.payablesYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="payablesYear2"
                value={props?.finAnswers?.payablesYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Short-Term Loans (₦)</td>
            <td>
              <NumberFormat
                name="shortTermLoansYear1"
                value={props?.finAnswers?.shortTermLoansYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="shortTermLoansYear2"
                value={props?.finAnswers?.shortTermLoansYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Bank Overdraft (₦)</td>
            <td>
              <NumberFormat
                name="bankOverdraftYear1"
                value={props?.finAnswers?.bankOverdraftYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="bankOverdraftYear2"
                value={props?.finAnswers?.bankOverdraftYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Accrued Expenses (₦)</td>
            <td>
              <NumberFormat
                name="accruedExpensesYear1"
                value={props.finAnswers.accruedExpensesYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="accruedExpensesYear2"
                value={props.finAnswers.accruedExpensesYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Styled>
  );
}
const Styled = styled.div`
  .calculateBold {
    font-weight: 600;
  }
  .styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 40rem;
    width: 100%;
    margin: 0;
  }
  .styled-table th,
  .styled-table td {
    font-size: 1.4rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
  }
  .styled-table th {
    background-color: #f2f2f2;
  }
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .styled-table input {
    min-width: 10rem;
    width: 100%;
    border: thin solid #ddd;
    height: 2.5rem;
    text-align: right;
  }
`;
