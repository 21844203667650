import { useState, useEffect } from 'react';
import { frrApis } from 'apis';
import { useSelector } from 'react-redux';
import errorHandler from 'helpers/errorHandler';

export const useFacilityAndCollateral = () => {
  const [collaterals, setCollaterals] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const businessId = useSelector(
    (state) => state.business.businessProfiles[0].businessId
  );
  useEffect(() => {
    const getFacilityDetails = async () => {
      //   setFacilitiesLoader(true);

      try {
        const response = await frrApis.getFacilityDetailsByBusinessId(
          businessId
        );
        setFacilities(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        // setFacilitiesLoader(false);
      }
    };

    const getCollaterals = async () => {
      //   setCollateralLoader(true);
      try {
        const response = await frrApis.getCollateralDetails(businessId);
        setCollaterals(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        // setCollateralLoader(false);
      }
    };

    getCollaterals();
    getFacilityDetails();
  }, []);

  return [facilities, collaterals];
};
