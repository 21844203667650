import React, { useEffect, useState } from 'react';
import Header from 'components/Headers/Orr';
import styled from 'styled-components';
import Button from 'components/Buttons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { grading } from 'constants/orr';
import { getOrrSumColor } from 'helpers/utils';
import { userApis } from 'apis';

function PieChart({
  text, color1, title, percent
}) {
  return (
    <div className="pieChart">
      <CircularProgressbar
        strokeWidth={15}
        value={percent}
        text={text}
        styles={{
          text: { fontSize: '14px' },
          path: {
            stroke: color1,
          },
        }}
      />
      <p>{title}</p>
    </div>
  );
}

function Gauge({
  text, title, percent, orrGrade
}) {
  const percentOff = ((percent / 100) * 251.33) / 2;

  return (
    <div
      style={{
        marginTop: '-3rem',
        maxHeight: '16rem',
        border: '1px solid #5FB19C',
        borderRadius: '5px',
      }}
    >
      <svg
        height="20rem"
        width="20rem"
        viewBox="-10 -0 120 120"
        xmlns="http://www.w3.org/2000/svg"
        // style={{ transform: 'rotateX(180deg)' }}
      >
        <circle
          cx="50"
          cy="50"
          r="40"
          strokeWidth="25"
          stroke="#d6d6d6"
          fill="none"
          strokeDasharray="125.66, 251.33"
          style={{
            transform: 'rotateX(180deg) translateY(-12rem)',
          }}
        />
        <circle
          id="outline_curves"
          cx="50"
          cy="50"
          r="40"
          strokeWidth="25"
          stroke={getOrrSumColor(orrGrade).color1}
          strokeDasharray={`${percentOff}, 251.33`}
          fill="none"
          style={{
            transform:
              'rotateX(180deg) translateY(-12rem) rotateY(180deg) translateX(-10rem)',
          }}
        />
        <text
          x="42"
          y="-55"
          style={{
            transform: 'translateY(12rem)',
            fontSize: '.8rem',
            fontWeight: 500,
          }}
        >
          {title}
        </text>
        <text
          x="42"
          y="-40"
          style={{
            transform: 'translateY(12rem)',
            fontSize: '.8rem',
            fill: '#00989C',
          }}
        >
          {text}
        </text>
      </svg>
    </div>
  );
}
export default function Summary() {
  const navigate = useNavigate();
  const location = useLocation();
  const { business } = useSelector((state) => state);
  const [grades, setGrades] = useState({});

  useEffect(() => {
    const sendNotification = async () => {
      try {
        await userApis.createNotification({
          businessId: business.businessProfiles[0].businessId,
          message: `Thank you for submitting your profile for evaluation and validation. 
            Our team will be in touch soon. To get real time updates on your validation process, 
            kindly visit the tracker on the dashboard`,
          subject: 'Business Profile Submitted!',
        });
      } catch (error) {}
    };
    const getGrades = async () => {
      setGrades(location?.state?.orrSummary);
    };
    if (location?.state?.orrSummary) {
      getGrades();
      sendNotification();
    } else {
      // navigate location
      navigate(-1);
    }
    return () => {};
  }, [location, business]);
  const formatValue = (val = 0, val2 = 0) => (val / val2) * 100;
  const getCreditHistory = formatValue(
    grades?.creditHistoryScore,
    grades.creditHistoryWeight
  );
  const getIndustry = formatValue(grades?.industryScore, grades.industryWeight);
  const getBusiness = formatValue(grades?.businessScore, grades.businessWeight);
  const getManagement = formatValue(
    grades?.managementScore,
    grades.managementWeight
  );
  const getFinancial = formatValue(
    grades?.financialScore,
    grades.financialWeight
  );
  return (
    <Styled>
      <Header />
      <section className="chartSection">
        <p className="chartTitle">Score Summary</p>
        <div className="chartContainer">
          <div className="disbursement">
            {grading[grades?.orrGrade] == 'red' ? (
              <div>
                <p>
                  Dear Customer,
                  {' '}
                  <br />
                  Based on this score
                  {' '}
                  {business?.businessProfiles[0]?.businessName}
                  {' '}
                  is not
                  eligible to apply for a facility on Gazelle.
                </p>
              </div>
            ) : (
              <p>
                Dear Customer,
                {' '}
                <br />
                Based on this score
                {' '}
                {business?.businessProfiles[0]?.businessName}
                {' '}
                is able to
                apply for a facility up to
                {' '}
                <strong>
                  ₦
                  {location?.state?.orrSummary?.disbursementAmount?.toLocaleString()}
                </strong>
                {' '}
                on Gazelle.
                {' '}
                <br />
                {' '}
                <small>(Terms and Conditions Apply)</small>
              </p>
            )}
          </div>
          <div className="chartContainer_layer chartContainer_layer-center ">
            <PieChart
              color1="#004C4E"
              title="Credit History"
              text={`${getCreditHistory?.toFixed(1)}%`}
              percent={getCreditHistory}
            />
            <PieChart
              color1="#0B90BA"
              title="Industry"
              text={`${getIndustry?.toFixed(1)}%`}
              percent={getIndustry}
            />
            <PieChart
              color1="#5FB19C"
              text={`${getBusiness?.toFixed(1)}%`}
              title="Business"
              percent={getBusiness}
            />
          </div>
          <div className="chartContainer_layer">
            <PieChart
              color1="#D45F36"
              text={`${getManagement?.toFixed(1)}%`}
              title="Management"
              percent={getManagement}
            />
            <Gauge
              title="Total"
              text={`${grades?.orrScore?.toFixed(1)}%`}
              percent={grades?.orrScore}
              orrGrade={grades?.orrGrade}
            />
            <PieChart
              color1="#D9B08C"
              text={`${getFinancial?.toFixed(1)}%`}
              title="Financials"
              percent={getFinancial}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
            marginTop: '2rem',
            paddingBottom: '5rem',
          }}
        >
          <Button
            mode="1"
            title="Proceed"
            onClick={() => navigate('/app/orr/thank-you')}
          />
        </div>
      </section>
    </Styled>
  );
}
const Styled = styled.div`
  background: #e5e5e5;
  height: 100%;
  min-height: 100vh;
  .disbursement {
    background: #d1e8e2;
    padding: 2rem;
    margin: 0 5rem;
    color: #004c4e;
    font-size: 1.4rem;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #5fb19c;
  }
  .chartTitle {
    font-size: 2rem;
    color: #004c4e;
    font-weight: 500;
    margin-bottom: 4rem;
  }
  .pieChart {
    width: 8rem;
    height: 8rem;
    p {
      font-size: 1.4rem;
    }
  }
  .chartSection {
    max-width: 70rem;
    min-width: 40rem;
    width: 100%;
    margin: 0 auto;
    margin-top: 5rem;
    .chartContainer {
      background: #fff;
      border-radius: 1rem;
      padding: 3rem;
      border: 5px solid #d1e8e2;
      color: #00989c;
      .chartContainer_layer {
        display: flex;
        text-align: center;
        justify-content: space-between;
        padding: 3rem 10rem;
      }
      .chartContainer_layer-center {
        justify-content: space-between;
      }
    }
  }
`;
