import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import FrrApis from 'apis/frr';
import { useQuery } from 'react-query';
import { pathOr } from 'ramda';
import { ReceiptSvg } from 'theme/icons';
import { formatDate } from '../../../helpers/dateFormat';
import DetailsGrid from './DetailsGrid';
import Layout from '../../Layouts/BusinessEvaDashboard';

export default function Index() {
  const params = useParams();
  const navigate = useNavigate();
  const [collateralInfo, setCollateralInfo] = useState(null);
  const [facilityInfo, setFacilityInfo] = useState(null);
  const { isLoading } = useQuery(
    ['getCollateralById', params.id],
    () => FrrApis.getCollateralById({ collateralId: params.id }),
    {
      // enabled: Boolean(pid),
      onSuccess: (res) => {
        setCollateralInfo(res?.data?.data);
      },
    }
  );
  useEffect(() => {
    const getFacilityDetails = async () => {
      const res = await FrrApis.getFacilityDetailsByCollateralId({
        collateralId: params.id,
      });
      setFacilityInfo(pathOr([], ['data', 'data'], res));
    };
    getFacilityDetails();
  }, []);

  return (
    <Styled>
      <Layout>
        {isLoading ? (
          <p>loading...</p>
        ) : (
          <div className="wrapper">
            <p className="title">{collateralInfo?.c?.collateralTypeName}</p>
            <div>
              <div
                className="action-button"
                onClick={() => navigate(
                  `/app/collateral-details/${params.id}/review?collateralTypeId=${collateralInfo?.c?.collateralTypeId}`,
                  {
                    replace: true,
                  }
                )}
              >
                <ReceiptSvg
                  width="4rem"
                  height="3rem"
                  stroke="#004C4E"
                  strokeWidth=".1px"
                  className="action-button-icon"
                />
                <p>Review Answers</p>
              </div>
            </div>
            <div className="details">
              <p className="description">
                Collateral Details
                {/* This is a debt security (liability) of the Federal Government of
                Nigeria (FGN) issued by the Debt Management Office (DMO) for and
                on behalf of the Federal Government. The FGN has an obligation
                to pay the bondholder the principal and agreed interest as and
                when due to the tune of N1,500,340,000 */}
              </p>
              <hr className="underline" />
              <div className="details-grid">
                <DetailsGrid details={collateralInfo} />
              </div>
            </div>

            <table className="facility-table">
              <thead>
                <tr>
                  <th scope="col">Facilities tied to this Collateral</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">Maturity Date</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {facilityInfo?.map((facility, index) => (
                  <tr key={index}>
                    <td data-label="account">
                      {facility?.f?.facilityType.facilityTypeName}
                    </td>
                    <td data-label="due-Date">
                      {facility?.f?.facilityAmount}
                    </td>
                    <td data-label="start-date">
                      {formatDate(facility?.f?.startDate)}
                    </td>
                    <td data-label="maturity-date">
                      {formatDate(facility?.f?.maturityDate)}
                    </td>
                    <td data-label="status">{facility?.f?.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Layout>
    </Styled>
  );
}
const Styled = styled.div`
  .wrapper {
    padding: 4rem 2rem 0;
  }
  .title {
    font-size: 1.8rem;
    font-weight: 500;
  }

  .details {
    background: #f8f8f8;
    padding: 2rem;
    border-radius: 5px;
  }
  .underline {
    margin: 10px 0;
    border: thin solid #e6e6e6;
  }
  .description {
    font-size: 1.4rem;
  }
  .details-grid {
    /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  .detail-key {
    font-size: 1.2rem;
    font-weight: 500;
    color: #004c4e;
  }
  .detail-value {
    font-size: 1.6rem;
    color: #00989c;
  }
  .facility-table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    font-size: 1.4rem;
    margin-top: 2rem;
    tr {
      background-color: #ffffff;
      padding: 10px;
      border-bottom: thin solid #d1e8e2;
    }
    th,
    td {
      padding: 10px;
      text-align: center;
    }
    th {
      font-size: 1.2rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
  .action-button {
    max-width: 18rem;
    width: 100%;
    background: #fff;
    padding: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    border: 2px solid #d1e8e2;
    color: #004c4e;
    margin: 2rem 0;
    :hover {
      background: #a7cfd2;
      color: #004c4e;
    }
    @media (max-width: 950px) {
      max-width: 15rem;
      padding: 1rem 2rem;
    }
  }
  .action-button-icon {
    margin-bottom: 1rem;
    fill: #004c4e;
  }
`;
