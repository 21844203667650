import React from 'react';
import styled from 'styled-components';

export default function ProgressBar({ width = null, containerStyle }) {
  return (
    <Styled style={containerStyle}>
      <div className="progress_bar" style={{ width: `${width}%` }} />
    </Styled>
  );
}
const Styled = styled.div`
  border: 1px solid #00989c;
  background: #fff;
  padding: 1px;
  border-radius: 5rem;
  width: 50%;
  .progress_bar {
    height: 1.2rem;
    border-radius: 5rem;
    width: 0rem;
    background-color: #5fb19c;
    background: repeating-linear-gradient(
        120deg,
        transparent,
        transparent 12px,
        #5fb19c 10px,
        #5fb19c 25px
      ),
      linear-gradient(to bottom, rgba(0, 152, 156, 1), rgba(0, 152, 156, 1));
  }
`;
