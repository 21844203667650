import React from 'react';
import useOrrQuesAns from 'hooks/useOrrQuesAns';
import Spinner from 'components/OverLays/OverLaySpinner';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Header from 'components/Headers/Orr';
import Controller from './Controller';

export default function EditFeedBack() {
  const location = useLocation();
  const [
    businessMgtQuestionsPool,
    isLoading,
    setLoader,
    setBusinessMgtQuestionsPool,
  ] = useOrrQuesAns();

  return (
    <Styled>
      <Header />
      {isLoading ? <Spinner /> : ''}
      <section className="controller_wrapper">
        {businessMgtQuestionsPool?.Management && (
          <Controller
            setLoader={setLoader}
            businessMgtQuestionsPool={businessMgtQuestionsPool}
            feedBackDetails={location.state.feedBack}
          />
        )}
      </section>
    </Styled>
  );
}

const Styled = styled.div`
  background-color: #e5e5e5;
  height: 100%;
  min-height: 100vh;

  .controller_wrapper {
    margin: 0 auto;
    padding: 0 10%;
    max-width: 144rem;
    @media (max-width: 1100px) {
      padding: 0 4%;
    }
    @media (max-width: 479px) {
      padding: 0 2%;
    }
  }
`;
