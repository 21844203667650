import React, { useState } from 'react';
import * as AuthActions from 'store/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useNotification from 'hooks/useNotification';
import {
  HomeSvg,
  LogOutSvg,
  UserSvg,
  NotificationBellSvg,
  SettingSvg,
  LogoSvg,
} from 'theme/icons';
import NotificationBox from './Notification/NotificationBox';

export default function Header() {
  const navigate = useNavigate();
  const { business } = useSelector((state) => state);
  const [isNotification, setNotification] = useState(false);
  const [notificationData, newNotification, isNotificationLoading] = useNotification();
  // const [isLoading, setLoading] = useState(false);
  // const dispatch = useDispatch();
  // const logOutHandler = () => {
  //   let verifyLogout = window.confirm('Are you sure you want to logout');
  //   if (verifyLogout) {
  //     navigate('/auth/login', { replace: true });
  //     return dispatch(AuthActions.AuthResetUser());
  //   }
  // };
  // const settingsHandler = () => {
  //   let verifyLogout = window.confirm('Exiting will terminate the application');
  //   if (verifyLogout) {
  //     navigate('/app/settings');
  //   }
  // };
  const homeHandler = () => {
    const verifyLogout = window.confirm('Exiting will terminate the application');
    if (verifyLogout) {
      navigate('/app/dashboard');
    }
  };

  const toggleNotification = () => {
    setNotification((prev) => !prev);
  };

  return (
    <Styled>
      <nav className="nav">
        <div className="nav_left">
          <p onClick={homeHandler}>
            <LogoSvg width="10rem" height="4rem" />
          </p>
        </div>
        <section className="nav_right">
          <div className="nav_item_text">
            <UserSvg
              width="2.5rem"
              stroke="#D9B08C"
              className="svg"
              strokeWidth=".1px"
            />
            <p className="links">
              {business?.businessProfiles[0]?.businessName}
            </p>
          </div>
          {/* <div>
            <HomeSvg
              width="25px"
              stroke="#D9B08C"
              className="svg"
              strokeWidth=".1px"
              onClick={homeHandler}
            />
          </div> */}
          <div className="notification-wrapper" onClick={toggleNotification}>
            {newNotification && <span className="notification_badge" />}
            <NotificationBellSvg
              width="2.5rem"
              stroke="#D9B08C"
              className="svg"
            />
            {isNotification && (
              <NotificationBox
                isLoading={isNotificationLoading}
                notificationData={notificationData}
              />
            )}
          </div>
          {/* <div>
            <SettingSvg
              width="25px"
              stroke="#D9B08C"
              className="svg"
              onClick={settingsHandler}
            />
          </div> */}
          {/* <div>
            <LogOutSvg
              width="25px"
              stroke="#D9B08C"
              className="svg"
              onClick={logOutHandler}
            />
          </div> */}
        </section>
      </nav>
    </Styled>
  );
}

const Styled = styled.div`
  .notification-wrapper {
    position: relative;
    margin-right: 2rem;
  }
  .notification_badge {
    background: #cc1016;
    height: 1rem;
    width: 1rem;
    border-radius: 1rem;
    position: absolute;
    top: -0.3rem;
    left: 1.4rem;
  }
  .nav {
    background: #004c4e;
    color: #d9b08c;
    padding: 0.2rem 3rem;
    display: flex;
    justify-content: space-between;
    .nav_left {
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
    }
    .nav_right {
      display: flex;
      align-items: center;
      > div {
        margin-right: 2rem;
      }
      .nav_item_text {
        margin-right: 2rem;
        display: flex;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        svg {
          margin-right: 0.5rem;
        }
        a,
        p {
          font-size: 1.4rem;
          max-width: 30rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .nav_right_btn {
      margin-right: 2rem;
      font-size: 1.4rem;
    }
    a {
      margin-right: 2rem;
    }
    .svg {
      stroke: #d9b08c;
      cursor: pointer;
    }
    a:visited,
    a:link {
      color: #d9b08c;
    }
  }
`;
