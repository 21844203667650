import React, { useState, useEffect } from 'react';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import styled from 'styled-components';

const answerModel = {
  name: '',
  phone: '',
  email: '',
  address: '',
  len_of_relationship: '',
};

export default function ContactType(props) {
  const [contactData, setContactData] = useState([answerModel]);
  useEffect(() => {
    const setList = () => {
      if (
        props.question.inputtedAnswer instanceof Array
        && props.question.inputtedAnswer.length > 0
      ) {
        setContactData((prev) => props?.question?.inputtedAnswer);
      } else {
        setContactData((prev) => [answerModel]);
      }
    };
    setList();
    return () => {};
  }, [props?.question?.inputtedAnswer]);

  const onChangeHandler = (e, i) => {
    const { value, name } = e.target;
    const cloneState = [...contactData];
    cloneState[i][name] = value;
    setContactData(cloneState);
    props.listHandler(cloneState);
  };
  const addTableHandler = () => {
    setContactData((prev) => [
      ...prev,
      { ...answerModel, businessId: props.businessId },
    ]);
  };
  const removeTableHandler = () => {
    const newState = [...contactData];
    newState.pop();
    setContactData(newState);
  };
  return (
    <Styled>
      <div className="table-wrapper">
        <table className="styled-table">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Phone No</th>
              <th>Email</th>
              <th>Address</th>
              <th>Length of Relationship(Years)</th>
            </tr>
          </thead>
          <tbody>
            {contactData.map((elem, i) => (
              <tr key={i}>
                <td>
                  <input
                    type="text"
                    name="name"
                    value={elem.name}
                    required
                    onChange={(e) => onChangeHandler(e, i)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="phone"
                    value={elem.phone}
                    required
                    onChange={(e) => onChangeHandler(e, i)}
                  />
                </td>
                <td>
                  <input
                    type="email"
                    name="email"
                    value={elem.email}
                    required
                    onChange={(e) => onChangeHandler(e, i)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="address"
                    value={elem.address}
                    required
                    onChange={(e) => onChangeHandler(e, i)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="len_of_relationship"
                    value={elem.len_of_relationship}
                    required
                    onChange={(e) => onChangeHandler(e, i)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="add-remove-wrapper">
          <div onClick={addTableHandler}>
            <FiPlusCircle size={18} color="#00989C" />
            <p>Add Row</p>
          </div>
          {contactData.length > 1 ? (
            <div onClick={removeTableHandler}>
              <FiMinusCircle size={18} color="#00989C" />
              <p>Remove Row</p>
            </div>
          ) : null}
        </div>
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  .styled-table th,
  .styled-table td {
    font-size: 1.4rem;
    padding: 0.5rem;
    border: thin solid #ddd;
  }
  .styled-table td {
    font-weight: 300;
    font-size: 1.4rem;
  }
  .styled-table th {
    background-color: #f2f2f2;
    font-size: 1.2rem;
  }
  .styled-table {
    font-weight: 400;
    border-collapse: collapse;
  }
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .styled-table input {
    min-width: 10rem;
    width: 100%;
    border: thin solid #ddd;
    height: 2.5rem;
    font-size: 1.4rem;
    padding-right: 0.5rem;
    border-radius: 0rem;
    box-shadow: none;
  }
  .table-wrapper {
    width: 100%;
    overflow-y: scroll;
    max-height: 30rem;
  }
  .add-remove-wrapper {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    & > div:nth-of-type(2) {
      margin-left: 1rem;
    }
  }
`;
