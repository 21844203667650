import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/Buttons';
import { useSelector } from 'react-redux';
import Spinner from 'components/OverLays/OverLaySpinner';
import { settingApis } from 'apis';
import _cloneDeep from 'lodash/cloneDeep';
import { useNavigate } from 'react-router-dom';
import ErrorHandler from 'helpers/errorHandler';
import { FileSvg } from 'theme/icons';
import Layout from 'components/Layouts/Default';
import FileUpload from '../ObliqueRiskRating/BusMgtFin/BusinessMgt/FileUploadV4Dashboard';

export default function Upload() {
  const navigate = useNavigate();
  const { auth, business } = useSelector((state) => state);
  const [isLoading, setLoader] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [businessDocs, setBusinessDocs] = useState({
    Business: [],
    Facility: [],
  });

  const [currentTab, setCurrentTab] = useState('Business');
  // Facility
  const { subSectorId } = business.businessProfiles[0];
  const { businessId } = auth.user;
  useEffect(() => {
    let isMounted = true;
    const getAllDoc = async () => {
      setLoader(true);
      try {
        const response = await settingApis.orrDocumentBySubSectorId(
          subSectorId
        );
        const response2 = await settingApis.getOrrDocuments(businessId);
        const documentToUpload = [...response.data.data].filter(
          (elem) => elem.isDisabled == false
        );
        for (let i = 0; i < documentToUpload.length; i++) {
          for (let j = 0; j < response2.data.data.length; j++) {
            const {
              documentId,
              status,
              uploadId,
              documentUrl,
              feedbackComment,
            } = response2?.data?.data[j];
            if (documentToUpload[i]?.documentId == documentId) {
              documentToUpload[i].fileUrl = documentUrl;
              documentToUpload[i].status = status;
              documentToUpload[i].uploadId = uploadId;
              documentToUpload[i].feedbackComment = feedbackComment;
            }
          }
        }
        // !elem.documentName.includes('Facility')
        const getFacilityDocs = documentToUpload.filter((elem) => elem.documentName.includes('Facility'));
        const getBusinessDocs = documentToUpload.filter(
          (elem) => !elem.documentName.includes('Facility')
        );
        isMounted
          && setBusinessDocs({
            Business: getBusinessDocs,
            Facility: getFacilityDocs,
          });
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    };
    getAllDoc();
    return () => {
      isMounted = false;
    };
  }, [subSectorId, businessId]);

  const fileHandler = (uploadRespond, id) => {
    const stateCopy = _cloneDeep(businessDocs);
    stateCopy[currentTab][id].fileUrl = uploadRespond;
    stateCopy[currentTab][id].status = 'UPLOADED';
    setBusinessDocs(stateCopy);
  };
  const updateStatusHandler = (status, id) => {
    const stateCopy = _cloneDeep(businessDocs);
    stateCopy[currentTab][id].status = status;
    setBusinessDocs(stateCopy);
  };
  const documentStatus = (status) => {
    const statusLowerCase = String(status).toLocaleLowerCase();
    if (statusLowerCase == 'rejected' || statusLowerCase == 'flagged') {
      return 'document_status-rejected';
    } if (statusLowerCase == 'uploaded') {
      return 'document_status-uploaded';
    } if (statusLowerCase == 'approved') {
      return 'document_status-approved';
    } if (statusLowerCase == 'processing') {
      return 'document_status-uploaded';
    }
  };

  return (
    <Layout activeBar="document">
      <Styled>
        <div className="container">
          <p className="upload_title">Business Evaluation Documents</p>
          <section className="tab_form">
            <div className="sort-bar-wrapper">
              <div className="sort-bar">
                <p>
                  Show All (
                  {businessDocs[currentTab].length}
                  )
                </p>
              </div>
              <div className="document-type">
                <p className="document-type-ttl">
                  pdf, doc, xlsx only
                  {' '}
                  <span>(10mb max)</span>
                </p>
              </div>
            </div>
            <div className="title-bar">
              <div className="document-title-bar">
                <p>Title</p>
              </div>
              <div className="document-action-bar">
                <p>Actions</p>
              </div>
              <div className="document-status-bar">
                <p>Status</p>
              </div>
            </div>
            {businessDocs[currentTab].length > 0 ? (
              <div className="tab_form_main">
                {businessDocs[currentTab]?.map((elem, i) => (
                  <div
                    key={elem?.documentId}
                    className="tab_form_main-wrapper"
                  >
                    <div className="tab_form_main-item">
                      <div className="document-title-wrapper">
                        <FileSvg color="#D9B08C" width="25px" />
                        <p className="document-title">{elem?.documentName}</p>
                      </div>
                      <FileUpload
                        uploadId={elem?.uploadId}
                        documentId={elem?.documentId}
                        documentStatus={elem?.status}
                        setBtnDisabled={setBtnDisabled}
                        url={elem?.fileUrl}
                        fileHandler={(e) => fileHandler(e, i)}
                        updateStatusHandler={(status) => updateStatusHandler(status, i)}
                      />
                      <p
                        className={[
                          'document_status',
                          documentStatus(elem?.status),
                        ].join(' ')}
                      >
                        {elem?.status?.toLocaleUpperCase() || 'PENDING'}
                      </p>
                    </div>
                    {elem?.status?.toLocaleUpperCase() == 'FLAGGED'
                        && elem?.feedbackComment?.length > 2 && (
                      <div className="feedback_layer">
                          <p>Feedback Comment:</p>
                          <p className="feedback_comment">
                          {elem?.feedbackComment}
                        </p>
                        </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div
                className="tab_form_main"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p>There are no documents to upload!</p>
              </div>
            )}
            <div className="proceed_wrapper">
              <Button
                title="Return Home"
                mode="1"
                disabled={isBtnDisabled}
                id="submit-button"
                onClick={() => navigate('/app/dashboard')}
              />
            </div>
          </section>
        </div>

        {isLoading ? <Spinner /> : ''}
      </Styled>
    </Layout>
  );
}
const Styled = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 2rem 3rem 2rem;

    @media (max-width: 80rem) {
      flex-direction: column;
    }
  }
  .document-title {
    font-size: 1.4rem;
    color: #004c4e;
    font-weight: 500;
    width: 80%;
  }
  .document-title-wrapper {
    display: flex;
    align-items: center;
    width: 60%;
    svg {
      margin-right: 1rem;
    }
  }
  .document_status {
    width: 10%;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.2rem 0.1rem;
    text-align: center;
    color: #004c4e;
    /* background: #d1e8e2;
    border: 1px solid #d1e8e2; */
    border-radius: 2px;
  }
  .document_status-uploaded {
    color: #0b90ba;
    /* background: #0b90ba40; */
    /* border: 1px solid #0b90ba40; */
  }
  .document_status-approved {
    color: #009467;
    /* background: #009467; */
    /* border: 1px solid #00946740; */
  }
  .document_status-rejected {
    color: #d45f36;
    /* background: #d45f3640; */
    /* border: 1px solid #d45f3640; */
  }

  .document-title-bar {
    width: 60%;
  }
  .document-action-bar {
    width: 30%;
  }
  .document-status-bar {
    width: 10%;
  }
  .upload_title {
    font-weight: 500;
    color: #004c4e;
    margin: 4rem 0 2rem 0;
    font-size: 2rem;
  }
  .tab_form_nav {
    display: flex;
  }
  .tab_form_nav_item {
    background-color: #e5e5e5;
    color: #808080;
    margin-right: 0.2rem;
    padding: 0.5rem 1rem;
    text-align: center;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 1.4rem;
  }
  .tab_form_nav_item-active {
    background-color: #fff;
    color: #000;
    border-bottom: thin solid #d9b08c;
  }
  .tab_form_main {
    padding: 1rem 1rem;
    border-top-left-radius: 0;
    height: 50rem;
    overflow-y: scroll;
    background: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .tab_form_main-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    padding: 1rem;
    align-items: center;
    /* border-bottom: 1px solid #e5e5e5; */
  }
  .tab_form_main-wrapper {
    border-bottom: 1px solid #e5e5e5;
  }
  .orr_button_section {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
  .proceed_wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 2rem;
  }
  .title-bar {
    display: flex;
    justify-content: space-between;
    background: #fff;
    font-size: 1.4rem;
    color: #004c4e;
    border-bottom: 1px solid #c1c1c1;
    padding: 0.8rem 2rem;
    border-top-right-radius: 5px;
  }
  .sort-bar-wrapper {
    background: #fff;
    width: 100%;
    padding: 1rem 2rem 0rem;
    display: flex;
    justify-content: space-between;
  }
  .sort-bar {
    width: 40%;
    border-radius: 5px;
    background: #eeeeee;
    padding: 0.5rem 1rem;
    p {
      color: #004c4e;
      font-size: 1.2rem;
    }
  }
  .document-type {
    background-color: ${(props) => props.theme.colors.grey_light_1};
    border: thin dashed ${(props) => props.theme.colors.red};
    border-radius: 6px;
    padding: 0.2rem;
  }
  .document-type-ttl {
    color: ${(props) => props.theme.colors.red};
    font-size: 1.4rem;
    font-weight: 500;
    span {
      font-size: 1.2rem;
    }
  }
  .feedback_layer {
    display: flex;
    color: #505050;
    font-weight: 500;
    padding-left: 4.5rem;
    p {
      font-size: 1.2rem;
    }
    .feedback_comment {
      color: #cc0000;
      margin-left: 1rem;
    }
  }

  #submit-button {
    background: linear-gradient(90deg, #00989c, #004c4e);
  }
`;
