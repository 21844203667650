import React from 'react';
import Navbar from 'components/Home/Navbars/Navbar';
import Main from 'components/Auth/SignIn';
import { Helmet } from 'react-helmet-async';

export default function index() {
  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5' }}>
        <title>Sign In | Gazelle by Sterling</title>
      </Helmet>
      <Navbar />
      <Main />
    </>
  );
}
