import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'components/Headers/Orr';
import Button from 'components/Buttons';
import { businessApis, firsApis, AccountOpeningV2 } from 'apis';
import request from 'apis/firs';
import errorHandler from 'helpers/errorHandler';
import Spinner from 'components/OverLays/OverLaySpinner';
import { toast } from 'react-toastify';
import useNotify from 'hooks/useNotify';
import Modal from '../FacilityRiskRating/modal';
import * as AuthActions from 'store/actions/auth';

export default function ThanksMsg() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [setNotify] = useNotify();
  const { auth } = useSelector((state) => state);
  const [openModal, setOpenModal] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [inputModal, setInputModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [business, setBusiness] = useState();
  const [TINOkay, setTINOkay] = useState(false);
  const [tinLoader, setTinLoader] = useState(false);
  const [accountDetails, setAccountDetails] = useState();
  const [ directorateLoader, setDirectorateLoader ] = useState(false);
  const [ mappingLoader, setMappingLoader ] = useState(false);

  const data = useSelector((state) => state);
  const { businessId } = data.business.businessProfiles[0];
  const { passportId } = data.business.businessProfiles[0];

  useEffect(() => {
    const getBusiness = async () => {
      setLoader(true);
      try {
        const response = await businessApis.getBusinessByPassportIdAndBusinessId(
          passportId,
          businessId
        );
        setBusiness(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoader(false);
      }
    };

    getBusiness();
  }, []);

  const mapDirectorate = async () => {
    setMappingLoader(true);
    try{
      const response = await AccountOpeningV2.mapAccountDirectorate(businessId);
      if(response?.data?.status){
        navigate('/app/dashboard');
      }
    }catch(error){
      errorHandler(error);
    }finally{
      setMappingLoader(false);
    }
  }

  const checkAccountDirectorate = async () => {
    setDirectorateLoader(true);
    try{
      const response = await AccountOpeningV2.getAccountDirectorate(business.companyAccountNumber);
      if(response?.data?.status){
        verifyTIN(business.companyTin);
        setTINOkay(true);
      }else{
        toast.error(
          `The account directorate isn't available on Gazelle, Kindly enter an appropriate account number.`
        );
      }
    }catch(error){
      errorHandler(error);
    }finally{
      setDirectorateLoader(false);
    }
  }

  const updateBusiness = async () => {
    setLoader(true);
    try {
      const response = await businessApis.updateBusinessProfile(business);
      mapDirectorate()
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoader(false);
    }
  };

  const verifyTIN = async (tin) => {
    setTinLoader(true);
    try {
      const response = await firsApis.getTin(tin);
    } catch (error) {
      errorHandler(error);
    } finally {
      setTinLoader(false);
    }
  };

  const getCustomerInfo = async (customerNumber) => {
    setLoader(true);
    try {
      const response = await firsApis.getMoreByNuban(customerNumber);
      let tin = '';
      const responseTin = response.data.Record['TAX.ID'];
      const splittedResponseTin = responseTin.split('-');
      for (const resTin of splittedResponseTin) {
        tin += resTin;
      }
      let businessTin = '';
      const splittedBusinessTin = business.companyTin.split('-');
      for (const myTin of splittedBusinessTin) {
        businessTin += myTin;
      }

      if (businessTin == tin) {
        checkAccountDirectorate();
      } else {
        setTINOkay(false);
        toast.error(
          'The account number provided does not match your profile, Kindly enter an appropriate account number.'
        );
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoader(false);
    }
  };

  const verifyAccount = async () => {
    setLoader(true);
    try {
      const response = await request.getAccountDetailsByNuban(
        business.companyAccountNumber
      );
      setAccountDetails(response.data.BankAccountFullInfo);
      if (
        response.data.BankAccountFullInfo.NUBAN
          == business.companyAccountNumber
        && response.data.BankAccountFullInfo.CustomerStatusDeecp.toLowerCase()
          == 'corporate customer'
      ) {
        getCustomerInfo(response.data.BankAccountFullInfo.CUS_NUM);
      } else {
        setBusiness({
          ...business,
          companyAccountNumber: '',
        });
        toast.error(
          'The account number provided is not a corporate account, Kindly enter the correct account number.'
        );
      }
    } catch (error) {
      errorHandler(error);
      setBusiness({
        ...business,
        companyAccountNumber: '',
      });
    } finally {
      setLoader(false);
    }
  };

  // const saveExitHandler = () => setNotify({
  //   show: true,
  //   type: 'info',
  //   message: 'Are you sure you want to exit',
  //   func: () => {
  //     navigate('/auth/login', { replace: true });
  //     dispatch(AuthActions.AuthResetUser());
  //   },
  // });

  return (
    <Styled>
      <Header />
      <div className="msg-container">
        <div className="score_container">
          <p className="score_item_1">
            Hi
            {' '}
            {auth?.user?.firstName}
            ,
            {' '}
            <br />
            {' '}
            Thank you for completing the
            evaluation process. Our advisory team would reach out to you within
            48 hours.
          </p>
          <Button
            mode="1"
            title="Proceed"
            onClick={() => {
              setOpenModal(true);
            }}
          />
        </div>
      </div>

      {openModal ? (
        <Modal id="prompt-modal" onClick={() => setOpenModal(false)}>
          <p className="Modal-text">
            Dear customer,
            <br />
            {' '}
            now that we know about
            <br />
            {' '}
            your business, would you like
            to tell
            <br />
            us about your facility request?
          </p>
          <div className="Modal-button-holder">
            <Button
              onClick={() => {
                navigate('/app/dashboard');
              }}
              title="Save and Exit"
              id="exit-button"
            />

            <Button
              onClick={() => {
                setOpenAction(true);
                if (business?.companyAccountNumber == '') {
                  setOpenAction(true);
                } else if (business?.companyAccountNumber != '') {
                  navigate('/app/dashboard');
                }
              }}
              title="Proceed"
              id="proceed-button"
            />
          </div>
        </Modal>
      ) : (
        ''
      )}

      {openAction ? (
        <Modal
          id="action-modal"
          iconId="close-icon"
          onClick={() => setOpenAction(false)}
        >
          <div id="action-modal-top">
            <div id="icon-holder">
              <div id="icon-holder-circle">
                <p id="action-modal-top-icon">i</p>
              </div>
            </div>
          </div>
          <p className="Action-modal-text">
            Dear customer,
            <br />
            {' '}
            we noticed that you do not have a bank
            <br />
            {' '}
            account with
            us.
            <br />
            An account is needed to proceed.
          </p>
          <div className="Modal-button-holder">
            <Button
              onClick={() => {
                setOpenAction(false);
                setInputModal(true);
              }}
              title="Input Account"
              id="input-account-button"
            />
            <Button
              onClick={() => {
                navigate('/app/account-opening');
              }}
              title="Open Account"
              id="open-input-button"
            />
          </div>
        </Modal>
      ) : (
        ''
      )}

      {inputModal ? (
        <Modal
          id="input-modal"
          iconId="input-close-icon"
          header="Company account validation"
          onClick={() => {
            setInputModal(false);
            setBusiness({
              ...business,
              companyAccountNumber: '',
            });
          }}
        >
          <div id="input-holder">
            <label>Account Number</label>
            <input
              onChange={(e) => {
                setBusiness({
                  ...business,
                  companyAccountNumber: String(e.target.value),
                });
              }}
              value={business.companyAccountNumber}
              type="number"
              placeholder="Enter details"
            />
          </div>

          {TINOkay ? (
            <div id="input-holder">
              <label>Company Name</label>
              <input
                disabled
                type="text"
                value={accountDetails?.AccountTitle}
              />
              <p id="correct-text">Is this correct?</p>
            </div>
          ) : (
            ''
          )}

          {TINOkay ? (
            <div id="question-buttons-holder">
              <Button
                onClick={() => {
                  setTINOkay(false);
                  setBusiness({
                    ...business,
                    companyAccountNumber: '',
                  });
                }}
                title="No, it's not"
                id="no-button"
              />

              <Button
                onClick={() => {
                  updateBusiness();
                }}
                title="Yes, it is"
                id="yes-button"
              />
            </div>
          ) : (
            <div className="Input-modal-button-holder">
              <Button
                onClick={() => {
                  verifyAccount();
                }}
                title="Validate"
                id="open-input-button"
                disabled={business.companyAccountNumber == ''}
                style={
                  business.companyAccountNumber == '' ? { opacity: '0.4' } : {}
                }
              />
            </div>
          )}
        </Modal>
      ) : (
        ''
      )}

      {loader || tinLoader || directorateLoader || mappingLoader ? <Spinner /> : ''}
    </Styled>
  );
}
const Styled = styled.form`
  background-color: #e5e5e5;
  .msg-container {
    height: calc(100vh - 4.4rem);
    display: flex;
    justify-content: center;
  }
  .score_container {
    background: #fff;
    width: 42.4rem;
    height: 28.6rem;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    text-align: center;
  }
  .score_item_1 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .Modal-button-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  #exit-button {
    padding: 1rem;
    border: 1px solid #5fb19c;
    background-color: #004c4e;
    border-radius: 6px;
    color: white;
    cursor: pointer;
  }

  #proceed-button {
    padding: 1rem;
    border: 1px solid #5fb19c;
    background-color: white;
    border-radius: 6px;
    color: #004c4e;
    cursor: pointer;
  }

  #open-account-button {
    padding: 1rem;
    border: 1px solid #5fb19c;
    background-color: #eee;
    border-radius: 6px;
    color: #004c4e;
    cursor: pointer;
  }

  #open-input-button {
    padding: 1rem 2rem;
    border: 1px solid #5fb19c;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-radius: 6px;
    color: #d9b08c;
    cursor: pointer;
  }

  .Modal-text {
    color: white;
    text-align: center;
    margin: 3rem 0;
  }

  .Action-modal-text {
    color: #505050;
    text-align: center;
    margin: 18rem 2rem 3rem 2rem;
  }

  #prompt-modal {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #action-modal {
    background-color: white;
    height: 40rem;
  }

  #action-modal-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 15rem;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  #icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  #close-icon {
    z-index: 100;
    color: #d9b08c;
  }

  #input-close-icon {
    color: #c1c1c1;
  }

  #action-modal-top-icon {
    font-size: 10rem;
    color: #d9b08c;
  }

  #icon-holder-circle {
    height: 10rem;
    width: 10rem;
    border: 3px solid #d9b08c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #input-holder {
    margin: 3rem 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
  }

  input {
    padding: 1rem;
    border: 1px solid #5fb19c;
    border-radius: 6px;
    width: 100%;
    outline: none;
  }

  #input-modal {
    background-color: white;
    width: 25%;
  }

  .Input-modal-button-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #correct-text {
    color: #004c4e;
    margin-top: 0.5rem;
  }

  #question-buttons-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #no-button {
    border: none;
    margin-right: 2rem;
    background-color: transparent;
    color: #004c4e;
    cursor: pointer;
  }

  #yes-button {
    padding: 1rem 3rem;
    border: none;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-radius: 6px;
    color: #d9b08c;
    cursor: pointer;
  }

  #input-account-button {
    padding: 1rem;
    border: 1px solid #5fb19c;
    background-color: #eee;
    border-radius: 6px;
    color: #004c4e;
    cursor: pointer;
  }
`;
