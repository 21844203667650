export const colors = {
  primary: '#004C4E',
  primary_light: '#00989C',
  primary_lemon: '#D1E8E2',
  primary_lemon_deep: '#5FB19C',
  secondary: '#D9B08C',
  black: '#000',
  white: '#fff',
  white_grey: '#e5e5e5',
  grey_light_1: '#F2F2F2',
  black_light: '#505050',
  red: '#D45F36',
};
