import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/Orr';
import styled from 'styled-components';
import { NextButton, PrevButton } from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import useOrrQuesAns from 'hooks/useOrrQuesAns';
import Spinner from 'components/OverLays/OverLaySpinner';
import orrApis from 'apis/orr';
import { useSelector } from 'react-redux';
import { ReactComponent as RollerSvg } from 'assets/svgs/loader-roller.svg';
import { FiEdit } from 'react-icons/fi';
import FinancialComponent from './Financial';

function MultipleContainer(props) {
  return (
    <>
      {props.data.map((elem, i) => (
        <p key={elem.answer} className="review_answer">
          {elem.answer}
        </p>
      ))}
    </>
  );
}
function ListContainer(props) {
  return (
    <>
      {props.data.map((elem, i) => (
        <p key={i} className="review_answer">
          {elem}
        </p>
      ))}
    </>
  );
}
function ContactListContainer(props) {
  return (
    <table className="styled-table-contact">
      <thead>
        <tr>
          <th>Name</th>
          <th>Phone No</th>
          <th>Email</th>
          <th>Address</th>
          <th>Length of Relationship</th>
        </tr>
      </thead>
      <tbody>
        {props?.data?.map((elem, i) => (
          <tr key={i}>
            <td>{elem.name}</td>
            <td>{elem.phone}</td>
            <td>{elem.email}</td>
            <td>{elem.address}</td>
            <td>{elem.len_of_relationship}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function AnswerContainer({ questionProperties }) {
  const { questionType, selectedAnswer, inputtedAnswer } = questionProperties;
  let answer;
  if (questionType == 'Multiple') {
    answer = <MultipleContainer data={selectedAnswer} />;
  } else if (questionType == 'list') {
    answer = <ListContainer data={inputtedAnswer} />;
  } else if (questionType == 'contact-list') {
    answer = <ContactListContainer data={inputtedAnswer} />;
  } else {
    answer = (
      <p className="review_answer">
        {selectedAnswer?.answer || inputtedAnswer}
      </p>
    );
  }
  return <>{answer}</>;
}
export default function Review() {
  const navigate = useNavigate();
  const [current, setCurrent] = useState('Management');
  const [
    businessMgtQuestionsPool,
    isLoading,
    setLoader,
    setBusinessMgtQuestionsPool,
  ] = useOrrQuesAns();
  const { business } = useSelector((state) => state);
  const [finAnswers, setFinAnswers] = useState({});
  const [auditorList, setAuditorList] = useState([]);
  const [borrowList, setBorrowList] = useState([]);
  useEffect(() => {
    const getBorrowingList = async (e) => {
      try {
        const res = await orrApis.getBrowsingHistory(
          business?.businessProfiles[0]?.businessId
        );

        setBorrowList((prev) => res.data.data);
      } catch (error) {
      } finally {
      }
    };
    getBorrowingList();
    return () => {};
  }, []);
  useEffect(() => {
    const getAnswer = async () => {
      try {
        const res = await orrApis.getOrrFinancialAnswers(
          business?.businessProfiles[0]?.businessId
        );
        if (res.status == 200 && res.data.data.length != 0) {
          setFinAnswers(res.data.data[0]);
        }
      } catch (error) {
      } finally {
      }
    };
    getAnswer();
    return () => {};
  }, []);
  useEffect(() => {
    const getAuditorList = async () => {
      try {
        const res = await orrApis.getAuditorsList();
        if (res.data.status) {
          const auditorsList = res.data.data;
          if (auditorsList.length > 0) {
            setAuditorList(auditorsList);
          }
        }
      } catch (error) {
      } finally {
      }
    };
    getAuditorList();
    return () => {};
  }, []);
  const handleNextTab = () => {
    if (current == 'Management') {
      setCurrent('Business');
    } else if (current == 'Business') {
      setCurrent('Financials');
    } else {
      navigate('/app/orr/grade', { replace: true });
    }
  };
  const handleEditNav = (index, section) => {
    navigate(
      `/app/orr/business-mgt-financial?index=${
        index
      }&dest=${
        section
      }&status=review`
    );
  };
  return (
    <Styled>
      <Header />
      <div className="box">
        <p className="review_title">Review Your Answers</p>
        <section className="tab_form">
          <div className="tab_form_nav">
            <div
              className={`tab_form_nav_item ${
                current == 'Management' && 'tab_form_nav_item-active'
              }`}
              onClick={() => setCurrent('Management')}
            >
              Management
            </div>
            <div
              className={`tab_form_nav_item ${
                current == 'Business' && 'tab_form_nav_item-active'
              }`}
              onClick={() => setCurrent('Business')}
            >
              Business
            </div>
            <div
              className={`tab_form_nav_item ${
                current == 'Financials' && 'tab_form_nav_item-active'
              }`}
              onClick={() => setCurrent('Financials')}
            >
              Financials
            </div>
          </div>
          <div className="tab_form_main">
            {isLoading ? (
              <div className="loader-container">
                <RollerSvg height="8rem" width="8rem" color="red" />
              </div>
            ) : (
              <>
                {businessMgtQuestionsPool[current]
                  ?.filter(
                    (elem) => (elem.questionType !== 'File'
                        && elem.inputtedAnswer != 'undefined'
                        && elem.selectedAnswer != 'undefined'
                        && elem.inputtedAnswer != undefined)
                      || elem.selectedAnswer != undefined
                  )
                  ?.map((elem, i) => (
                    <div key={i} className="tab_form_main-item">
                      <p className="review_question_title">
                        <span className="button_circle" />
                        Question
                      </p>
                      <p className="review_question">{elem.question}</p>
                      <p className="review_answer_title">
                        <span className="button_circle_green" />
                        Answer
                        <span
                          className="edit-btn"
                          onClick={() => handleEditNav(i, current)}
                        >
                          Edit
                          {' '}
                          <FiEdit />
                        </span>
                      </p>
                      <AnswerContainer questionProperties={elem} />
                    </div>
                  ))}
                {current == 'Financials' && (
                  <FinancialComponent
                    edit
                    finAnswers={finAnswers}
                    auditorList={auditorList}
                    borrowList={borrowList}
                  />
                )}
              </>
            )}
            {/* if Financials Answers */}
          </div>
        </section>
        <div className="orr_button_section">
          <PrevButton onClick={() => navigate('/app/orr/upload')} />
          <NextButton onClick={handleNextTab} />
        </div>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  background: #e5e5e5;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 4rem;
  .box {
    margin: 0 auto;
    max-width: 90rem;
    width: 100%;
    padding: 1rem;
  }
  .edit-btn {
    margin-left: 2rem;
    padding: 0.3rem;
    background: #00989c;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
  .button_circle {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 5rem;
    background: #d9b08c;
    display: inline-block;
    margin-right: 0.5rem;
  }
  .button_circle_green {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 5rem;
    background: #00989c;
    display: inline-block;
    margin-right: 0.5rem;
  }
  .review_answer_title,
  .review_question_title {
    font-size: 1.4rem;
    font-weight: 500;
    color: #505050;
    padding-top: 0.5rem;
  }
  .review_answer,
  .review_question {
    display: block;
    font-size: 1.4rem;
    color: #808080;
    padding-left: 1.2rem;
  }

  .review_title {
    font-weight: 500;
    color: #004c4e;
    margin: 4rem 0;
    font-size: 2rem;
  }
  .tab_form_nav {
    display: flex;
    justify-content: space-between;
  }
  .tab_form_nav_item {
    color: #808080;
    padding: 0.8rem;
    text-align: center;
    cursor: pointer;
    width: 100%;
    background: #fff;
    font-weight: 500;
    border-bottom: thin solid #e5e5e5;
    font-size: 1.4rem;
    &:nth-of-type(1) {
      border-top-left-radius: 5px;
    }
    &:nth-of-type(3) {
      border-top-right-radius: 5px;
    }
  }
  .tab_form_nav_item-active {
    color: #00989c;
    border-bottom: 3px solid #00989c;
  }
  .tab_form_main {
    padding: 1rem 2rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 50rem;
    overflow-y: scroll;
    background: #fff;
  }
  .tab_form_main-item {
    padding: 1rem 0;
    border-bottom: 1px solid #e5e5e5;
  }
  .orr_button_section {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
  /* table */
  .styled-table-contact th,
  .styled-table-contact td {
    padding: 0.5rem;
    border: thin solid #ddd;
  }
  .styled-table-contact td {
    font-weight: 400;
    font-size: 1.3rem;
  }
  .styled-table-contact th {
    background-color: #f2f2f2;
    font-size: 1.3rem;
    font-weight: 400;
  }
  .styled-table-contact {
    font-weight: 400;
    border-collapse: collapse;
    margin-left: 1.5rem;
    margin-top: 1rem;
  }
  .styled-table-contact tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
