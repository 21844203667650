import * as actionTypes from '../action-types/signUpForm';

export const SaveAboutYou = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_ABOUT_YOU_SIGNUP_FORM, payLoad: data });
};
export const SaveAccount = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_ACCOUNT_SIGNUP_FORM, payLoad: data });
};
export const UpdateAccount = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.UPDATE_ACCOUNT_SIGNUP_FORM, payLoad: data });
};
export const SaveActivePage = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_ACTIVE_PAGE_SIGNUP_FORM, payLoad: data });
};
export const SaveBusiness = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_BUSINESS_SIGNUP_FORM, payLoad: data });
};
export const SaveBVN = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_BVN_SIGNUP_FORM, payLoad: data });
};
export const SaveCustomerType = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_CUSTOMER_TYPE_SIGNUP_FORM, payLoad: data });
};
export const SaveLoan = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_LOAN_SIGNUP_FORM, payLoad: data });
};
export const SaveTin = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_TIN_SIGNUP_FORM, payLoad: data });
};
export const SaveFinal = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_FINAL_SIGNUP_FORM, payLoad: data });
};
export const ResetForm = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.RESET_SIGNUP_FORM });
};
