import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import errorHandler from 'helpers/errorHandler';
import { AccountOpeningV2 } from 'apis';

export const useDirectorateStatus = () => {
  const [directorateMapped, setDirectorateMapped] = useState(null);
  const [ directorateCheckLoading, setDirectorateCheckLoading ] = useState(false);
  const accountNumber = useSelector(
    (state) => state.business.businessProfiles[0].companyAccountNumber
  );

  useEffect(() => {
    const checkBusinessDirectorateMapping = async () => {
      setDirectorateCheckLoading(true);

      try {
        const response = await AccountOpeningV2.getAccountDirectorate(
          accountNumber
        );

        if(response?.data?.status){
            setDirectorateMapped(true);
        }else{
            setDirectorateMapped(false);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setDirectorateCheckLoading(false);
      }
    };
    if(accountNumber !== ''){
        checkBusinessDirectorateMapping();
    }else{
        return;
    }
  }, []);

  return [directorateMapped, directorateCheckLoading ];
};
