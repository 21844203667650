import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { store } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import * as SignUpActions from 'store/actions/signUpForm';
import useNotify from 'hooks/useNotify';
import { useIdleTimer } from 'react-idle-timer';
import * as AuthActions from 'store/actions/auth';
// pages

// Facility components
import FacilityEvaluation from 'pages/FacilityRiskRating/facilityEvaluation/facilityEvaluation';
import FacilityQuestions from 'pages/FacilityRiskRating/purpose/questions';
import FacilityQuestionsReview from 'pages/FacilityRiskRating/review/review';
import FacilityCollateralMapping from 'components/FacilityRiskRating/collateral-mapping/collateral-mapping';
import FacilitySubmission from 'components/FacilityRiskRating/facility-submission/facility-submission';
import FacilityConclusion from 'components/FacilityRiskRating/facility-conclusion/facility-conclusion';
import CreditHistory from 'components/CreditHistory/index';
import FacilityFeedback from 'components/FacilityRiskRating/feedback/index';
import FacilityFeedbackResponse from 'components/FacilityRiskRating/feedbackResponse';
import FacilityFeedbackResponseReview from 'components/FacilityRiskRating/feedbackReview/index';
// Documents Upload
import FacilityDocumentUpload from 'components/DocumentUpload/facility';
import CollateralDocumentUpload from 'components/DocumentUpload/collateral';
import BusinessDocumentUpload from 'components/DocumentUpload/business';
// Account Opening
import AccountOpening from 'pages/AccountOpening';
// OfferLetter
import OfferLetterDashboard from 'components/OfferLetter/Dashboard';
import UploadOfferLetter from 'components/OfferLetter/UploadOfferLetter';
import OfferLetterDetails from 'pages/OfferLetter/OfferLetterDetails';
// Static Pages
import Home from 'pages/Home';
import Support from 'pages/Home/Support';
import FAQ from 'pages/Home/Faq';
import PrivacyPolicy from 'pages/Home/PrivacyPolicy';
import TermsAndConditions from 'pages/Home/TermsAndConditions';
import HowItWorks from 'pages/Home/HowItWorks';
import Register from 'pages/Auth/SignUp';
import ChangePwd from 'pages/Auth/ChangePwd';
import ForgotPwd from 'pages/Auth/ForgotPwd';
import ConfirmEmail from 'pages/Auth/ConfirmEmail';
import Login from 'pages/Auth/SignIn';
// App
import Dashboard from 'pages/Dashboard/Dashboard';
import DocumentUpload from 'pages/Documents';
// Orr
import ORR from 'pages/ObliqueRiskRating/BusMgtFin';
import GenericORR from 'pages/ObliqueRiskRating/Principals';
import ORRSummary from 'pages/ObliqueRiskRating/Summary';
import ORRScore from 'pages/ObliqueRiskRating/Score';
import ORRReview from 'pages/ObliqueRiskRating/Review';
import ORRUpload from 'pages/ObliqueRiskRating/Upload';
import ORRTracker from 'pages/ObliqueRiskRating/Tracker';
import ORRFeedBack from 'pages/ObliqueRiskRating/Feedback';
import ORRFeedBackEdit from 'pages/ObliqueRiskRating/Feedback/Edit';
import ORRThankYou from 'pages/ObliqueRiskRating/Thankyou';
import FacilityDashboard from 'pages/ObliqueRiskRating/Dashboard';
import OrrDashBoardReview from 'pages/ObliqueRiskRating/Dashboard/Review';
import OrrDashBoardUpload from 'pages/ObliqueRiskRating/Dashboard/Upload';
import FrrDocumentUpload from 'pages/FacilityRiskRating/DocumentUpload';
// settings
import BusProfile from 'pages/Settings/BusinessProfile';
import UserProfile from 'pages/Settings/UserProfile';
import Settings from 'pages/Settings';
import ChangePwdSettings from 'pages/Settings/ChangePassword';
import Notifications from 'pages/Settings/Notification';
// collateral
import CollateralDashboard from 'pages/Collateral/Dashboard';
import CollateralDetails from 'pages/Collateral/Details';
import CollateralReview from 'pages/Collateral/Review';
import CollateralQuestions from 'pages/Collateral/Questions';
import CollateralsWithFeedBack from 'pages/Collateral/CollateralsWithFeedBack';
import FeedBacksByCollateralId from 'pages/Collateral/FeedBacksByCollateralId';
import CollateralFeedBackEdit from 'pages/Collateral/FeedBackEdit';

function PrivateRoute({ as: Component, ...props }) {
  const navigate = useNavigate();
  const { notify } = useSelector((state) => state);
  const [setNotify] = useNotify();

  useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: signOut,
    debounce: 500,
  });

  function signOut() {
    if (!store?.getState()?.auth?.token) return;
    if (!notify.show) {
      navigate('/auth/login', { replace: true });
      store.dispatch(AuthActions.AuthResetUser());
      setNotify({
        show: true,
        type: 'bad',
        message:
          'You’re logged out due to inactivity. Please, login again',
      });
    }
  }
  if (store?.getState()?.auth.token?.length > 30) {
    return <Component {...props} />;
  }
  return <Navigate to="/auth/login" replace />;
}

function PreventAccessWhenLoggedIn({ as: Component, ...props }) {
  if (store?.getState()?.auth.token?.length > 30) {
    return <Navigate to="/app/dashboard" replace />;
  }
  return <Component {...props} />;
}

function PreventAccessWhenLoggedInRegister({ as: Component, ...props }) {
  useIdleTimer({
    timeout: 1000 * 60 * 5,
    // timeout: 1000 * 60 * 12000,
    onIdle: exitSignUp,
    debounce: 500,
  });

  const dispatch = useDispatch();

  function exitSignUp() {
    return dispatch(SignUpActions.ResetForm());
  }

  useEffect(() => {
    const _beforeunloadfn = window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault();
      const msg = 'You have unsaved changes /n which will not be saved.';
      ev.returnValue = msg;
      return msg;
    });
    const _unloadfn = window.addEventListener('unload', (ev) => dispatch(SignUpActions.ResetForm()));
    return () => {
      window.removeEventListener('beforeunload', _beforeunloadfn);
      window.removeEventListener('unload', _unloadfn);
    };
  }, []);

  if (store?.getState()?.auth.token?.length > 30) {
    return <Navigate to="/app/dashboard" replace />;
  }
  return <Component {...props} />;
}

export const ScrollToTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return children;
};

export default function Index() {
  return (
    <BrowserRouter>
      {/* <ScrollToTop path="/"> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/support" element={<Support />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route
          element={<PreventAccessWhenLoggedInRegister as={Register} />}
          path="/auth/register"
        />
        <Route
          element={<PreventAccessWhenLoggedIn as={Login} />}
          path="/auth/login"
        />
        <Route path="/auth/change-password" element={<ChangePwd />} />
        <Route path="/auth/forget-password" element={<ForgotPwd />} />
        <Route path="/email_confirmation/callback" element={<ConfirmEmail />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-conditions" element={<TermsAndConditions />} />

        <Route
          element={<PrivateRoute as={Dashboard} />}
          path="/app/dashboard"
        />
        <Route
          element={<PrivateRoute as={DocumentUpload} />}
          path="/app/document"
        />
        <Route element={<PrivateRoute as={Settings} />} path="/app/settings" />
        <Route
          element={<PrivateRoute as={BusProfile} />}
          path="/app/settings/business-profile"
        />
        <Route
          element={<PrivateRoute as={UserProfile} />}
          path="/app/settings/user-profile"
        />

        <Route
          element={<PrivateRoute as={ChangePwdSettings} />}
          path="/app/settings/password-change"
        />
        <Route
          element={<PrivateRoute as={Notifications} />}
          path="/app/notifications"
        />
        {/* <FacilityHistory path="/app/facility-history" /> */}
        <Route
          element={<PrivateRoute as={GenericORR} />}
          path="/app/orr/principals"
        />
        <Route
          element={<PrivateRoute as={ORR} />}
          path="/app/orr/business-mgt-financial"
        />
        <Route element={<PrivateRoute as={ORRScore} />} path="/app/orr/grade" />
        <Route
          element={<PrivateRoute as={ORRUpload} />}
          path="/app/orr/upload"
        />
        <Route
          element={<PrivateRoute as={ORRReview} />}
          path="/app/orr/review"
        />
        <Route
          element={<PrivateRoute as={ORRSummary} />}
          path="/app/orr/summary"
        />
        <Route
          element={<PrivateRoute as={ORRThankYou} />}
          path="/app/orr/thank-you"
        />
        <Route
          element={<PrivateRoute as={ORRTracker} />}
          path="/app/orr/tracker"
        />
        <Route
          element={<PrivateRoute as={ORRFeedBack} />}
          path="/app/orr/feedback"
        />
        <Route
          element={<PrivateRoute as={ORRFeedBackEdit} />}
          path="/app/orr/feedback/edit"
        />
        <Route
          element={<PrivateRoute as={FacilityDashboard} />}
          path="/app/orr/dashboard"
        />
        <Route
          element={<PrivateRoute as={OrrDashBoardReview} />}
          path="/app/orr/dashboard/review"
        />
        <Route
          element={<PrivateRoute as={OrrDashBoardUpload} />}
          path="/app/orr/dashboard/upload"
        />
        {/* Collateral */}

        <Route
          element={<PrivateRoute as={CollateralDashboard} />}
          path="/app/collateral"
        />
        <Route
          element={<PrivateRoute as={CollateralQuestions} />}
          path="/app/collateral/new/:id"
        />
        <Route
          element={<PrivateRoute as={CollateralDetails} />}
          path="/app/collateral-details/:id"
        />
        <Route
          element={<PrivateRoute as={CollateralReview} />}
          path="/app/collateral-details/:id/review"
        />
        <Route
          element={<PrivateRoute as={CollateralsWithFeedBack} />}
          path="/app/collateral/collaterals-with-feedbacks/"
        />
        <Route
          element={<PrivateRoute as={FeedBacksByCollateralId} />}
          path="/app/collateral/feedbacks/:collateralId"
        />
        <Route
          element={<PrivateRoute as={CollateralFeedBackEdit} />}
          path="/app/collateral/feedbacks/:collateralId/edit"
        />
        {/* FRR */}
        <Route
          element={<PrivateRoute as={FrrDocumentUpload} />}
          path="/app/frr/upload"
        />
        <Route
          element={<PrivateRoute as={FacilityEvaluation} />}
          path="/app/frr/facilityevaluation"
        />
        <Route
          element={<PrivateRoute as={FacilityQuestions} />}
          path="/app/frr/facilityquestions"
        />
        <Route
          element={<PrivateRoute as={FacilityQuestionsReview} />}
          path="/app/frr/facilityquestionsreview"
        />
        <Route
          element={<PrivateRoute as={FacilityCollateralMapping} />}
          path="/app/frr/facilitycollateralmapping"
        />
        <Route
          element={<PrivateRoute as={FacilitySubmission} />}
          path="/app/frr/facilitysubmission"
        />
        <Route
          path="/app/frr/facilityconclusion"
          element={<PrivateRoute as={FacilityConclusion} />}
        />
        <Route
          path="/app/document/facility"
          element={<PrivateRoute as={FacilityDocumentUpload} />}
        />
        <Route
          path="/app/document/collateral"
          element={<PrivateRoute as={CollateralDocumentUpload} />}
        />
        <Route
          path="/app/document/business"
          element={<PrivateRoute as={BusinessDocumentUpload} />}
        />
        <Route
          path="/app/account-opening"
          element={<PrivateRoute as={AccountOpening} />}
        />
        <Route
          path="/app/frr/credit-history"
          element={<PrivateRoute as={CreditHistory} />}
        />
        <Route
          path="/app/frr/facility-feedback"
          element={<PrivateRoute as={FacilityFeedback} />}
        />
        <Route
          path="/app/frr/facility-feedback-response"
          element={<PrivateRoute as={FacilityFeedbackResponse} />}
        />
        <Route
          path="/app/frr/feedback-response-review"
          element={<PrivateRoute as={FacilityFeedbackResponseReview} />}
        />

        <Route
          path="/app/frr/offer-letter"
          element={<PrivateRoute as={OfferLetterDashboard} />}
        />
        <Route
          path="/app/frr/offer-letter/:id"
          element={<PrivateRoute as={OfferLetterDetails} />}
        />
        <Route
          path="/app/frr/offer-letter/:id/upload"
          element={<PrivateRoute as={UploadOfferLetter} />}
        />

        <Route path="*" element={<Home />} />
      </Routes>
      {/* </ScrollToTop> */}
    </BrowserRouter>
  );
}
