import * as actionTypes from '../action-types/auth';

const initialState = {
  loggedIn: false,
  user: null,
  token: null,
  loading: false,
  errorMessage: null,
};
export default function auth(state = initialState, { type, payLoad }) {
  switch (type) {
    case actionTypes.SAVE_USER_AUTH:
      return {
        ...state,
        token: payLoad.token,
        user: payLoad,
      };
    case actionTypes.RESET_USER_AUTH:
      return initialState;
    default:
      return state;
  }
}
