export const grading = {
  AAA: 'green',
  AA: 'green',
  A: 'green',
  BBB: 'green',
  BB: 'yellow',
  B: 'yellow',
  CCC: 'red',
  'CC/C': 'red',
};
