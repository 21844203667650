export const positionsList = [
  {
    value: 'Chief Executive Officer/Managing Director',
    text: 'Chief Executive Officer/Managing Director',
  },
  {
    value: 'Chief Finance Officer/Head of Finance',
    text: 'Chief Finance Officer/Head of Finance',
  },
  {
    value: 'Chief Operations Officer',
    text: 'Chief Operations Officer',
  },
  {
    value: 'General Manager',
    text: 'General Manager',
  },
  {
    value: 'Head of Sales & Marketing/Business Development',
    text: 'Head of Sales & Marketing/Business Development',
  },
  {
    value: 'Treasurer',
    text: 'Treasurer',
  },
  {
    value: 'Account Officer',
    text: 'Account Officer',
  },
  {
    value: 'Finance Director',
    text: 'Finance Director',
  },
  {
    value: 'Administrative Officer',
    text: 'Administrative Officer',
  },
  {
    value: 'Finance Officer',
    text: 'Finance Officer',
  },
  {
    value: 'Accountant/ Bookkeeper',
    text: 'Accountant/ Bookkeeper',
  },
  {
    value: 'Business Analyst',
    text: 'Business Analyst',
  },
  {
    value: 'Others',
    text: 'Others',
  },
];

export const tenorOptions = {
  days: [
    { text: '0 day', value: '0' },
    { text: '1 day', value: '1' },
    { text: '2 days', value: '2' },
    { text: '3 days', value: '3' },
    { text: '4 days', value: '4' },
    { text: '5 days', value: '5' },
    { text: '6 days', value: '6' },
    { text: '7 days', value: '7' },
    { text: '8 days', value: '8' },
    { text: '9 days', value: '9' },
    { text: '10 days', value: '10' },
    { text: '11 days', value: '11' },
    { text: '12 days', value: '12' },
    { text: '13 days', value: '13' },
    { text: '14 days', value: '14' },
  ],
  months: [
    { text: '0 month', value: '0' },
    { text: '1 month', value: '1' },
    { text: '2 months', value: '2' },
    { text: '3 months', value: '3' },
    { text: '4 months', value: '4' },
    { text: '5 months', value: '5' },
    { text: '6 months', value: '6' },
    { text: '7 months', value: '7' },
    { text: '8 months', value: '8' },
    { text: '9 months', value: '9' },
    { text: '10 months', value: '10' },
    { text: '11 months', value: '11' },
    { text: '12 months', value: '12' },
  ],
  years: [
    { text: '1 year', value: '1' },
    { text: '2 years', value: '2' },
    { text: '3 years', value: '3' },
    { text: '4 years', value: '4' },
    { text: '5 years', value: '5' },
    { text: '6 years', value: '6' },
    { text: '7 years', value: '7' },
    { text: '8 years', value: '8' },
    { text: '9 years', value: '9' },
    { text: '10 years', value: '10' },
    { text: '11 years', value: '11' },
    { text: '12 years', value: '12' },
    { text: '13 years', value: '13' },
    { text: '14 years', value: '14' },
    { text: '15 years', value: '15' },
  ],
};
export const denominations = [
  { text: '₦', value: '₦' },
  { text: '$', value: '$' },
  { text: '£', value: '£' },
  { text: '€', value: '€' },
];
export const facilityEstimate = [
  { text: '0-10 million', value: '0-10 million' },
  { text: '11 million - 30 million', value: '11 million - 30 million' },
  { text: '31 million - 60 million', value: '31 million - 60 million' },
  { text: '61 million - 100 million', value: '61 million - 100 million' },
  { text: '100 - 500 million', value: '100 - 500 million' },
  { text: '500 million - 1 billion', value: '500 million - 1 billion' },
  { text: '1 billion - 5 billion', value: '1 billion - 5 billion' },
  { text: '5 billion - 10 billion', value: '5 billion - 10 billion' },
  { text: '10 billion - 20 billion', value: '10 billion - 20 billion' },
];
export const loanPurpose = [
  { text: 'Working Capital', value: 'Working Capital' },
  { text: 'Construction Finance', value: 'Construction Finance' },
  { text: 'Contracting Finance', value: 'Contracting Finance' },
  {
    text: 'Export/Import Activity Finance',
    value: 'Export/Import Activity Finance',
  },
  {
    text: 'Assets & Equipment Finance',
    value: 'Assets & Equipment Finance',
  },
  {
    text: 'Vehicle/Spare Part Finance',
    value: 'Vehicle/Spare Part Finance',
  },
  { text: 'Stock Finance', value: 'Stock Finance' },
  { text: 'Public Finance', value: 'Public Finance' },
  { text: 'Property Finance', value: 'Property Finance' },
  {
    text: 'Project Investment Finance',
    value: 'Project Investment Finance',
  },
  { text: 'Investment Finance', value: 'Investment Finance' },
  { text: 'Object Finance', value: 'Object Finance' },
  { text: 'Bonds And Guarantees', value: 'Bonds And Guarantees' },
  { text: 'Miscellaneous', value: 'Miscellaneous' },
];
export const genderBinary = {
  Male: 0,
  Female: 1,
};
export const maritalStatusBinary = {
  Single: 0,
  Married: 1,
  Widow: 2,
  Widower: 3,
  Complicated: 4,
};
export const businessType = [
  { text: 'Sole Proprietorship', value: 'Sole Proprietorship' },
  { text: 'Partnership', value: 'Partnership' },
  { text: 'Association', value: 'Association' },
  { text: 'Enterprise', value: 'Enterprise' },
  { text: 'Limited Partnership', value: 'Limited Partnership' },
  {
    text: 'Private Limited Liability',
    value: 'Private Limited Liability',
  },
  { text: 'Public Limited Liability', value: 'Public Limited Liability' },
  { text: 'Government Institution', value: 'Government Institution' },
  { text: 'Non Government Organization', value: 'NGO' },
];
