import React from 'react';
import styled from 'styled-components';
import {
  FiX, FiInfo, FiAlertTriangle, FiCheckCircle
} from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { HideNotify } from 'store/actions/notify';
import Portal from '../OverLays/Portal';

export function Notify() {
  const { notify } = useSelector((state) => state);
  const dispatch = useDispatch();
  let getIcon;
  if (notify.type === 'info') {
    getIcon = (
      <FiInfo color="#D9B08C" size="70" className="notification__icon" />
    );
  } else if (notify.type === 'bad') {
    getIcon = (
      <FiAlertTriangle
        color="#FF7474"
        size="70"
        className="notification__icon"
      />
    );
  } else if (notify.type === 'ok') {
    getIcon = (
      <FiCheckCircle color="#00989C" size="70" className="notification__icon" />
    );
  }
  const okayHandler = () => {
    dispatch(HideNotify());
  };
  const closeHandler = () => {
    typeof notify.func === 'function' && notify.func();
    dispatch(HideNotify());
  };

  const funcTwoHandler = () => {
    typeof notify.func === 'function' && notify.funcTwo();
    dispatch(HideNotify());
  };
  return (
    <div>
      {notify.show ? (
        <Portal>
          <Alert>
            <div className="overlay__inner">
              <div className="overlay__content">
                <div className="box">
                  <div className="box__header">
                    {getIcon}
                    <FiX
                      color="#D9B08C"
                      className="close__btn cursor-pointer"
                      size="25"
                      onClick={okayHandler}
                    />
                  </div>
                  <div className="box__main">
                    <div dangerouslySetInnerHTML={{ __html: `<span>${notify?.message}</span>` }} />
                      <div className={ notify?.funcTwo && notify?.btnTextTwo ? 'flex justify-between w-full' : ''}>
                        <button
                          type="button"
                          className="okay__btn"
                          onClick={closeHandler}
                        >
                          { notify.btnText ?? 'Okay'}
                        </button>
                      {
                        notify?.funcTwo && notify?.btnTextTwo ? 
                        <button
                          type="button"
                          className="okay__btn"
                          onClick={funcTwoHandler}
                        >  
                          { notify.btnTextTwo ?? 'Okay'}
                        </button> : ''
                      }
                        
                         
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </Alert>
        </Portal>
      ) : (
        ''
      )}
    </div>
  );
}
const Alert = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #232c30b6;
  z-index: 9999;
  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .box {
    background: #fff;
    height: 30rem;
    width: 30rem;
    border-radius: 5px;
  }
  .box__header {
    background: #004c4e;
    height: 10rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    height: calc(100% - 10rem);
    width: 100%;
    padding: 0rem 1rem;
    text-align: center;
    font-size: 14px;
  }
  .close__btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .notification__icon {
    stroke-width: 1px;
  }
  .okay__btn {
    padding: 1rem 1.5rem;
    background: #004c4e;
    border-radius: 5px;
    border: none;
    color: #d9b08c;
  }
`;
