    // checking for duplicate frr responses.
export const dynamicResponsesduplicateRemover = (frrResponseData) => {
    const sortedResponse = frrResponseData.sort(
        (a, b) => a.frrQuestionId - b.frrQuestionId
    );
    const response = [];

    for (let i = 0; i < sortedResponse?.length; i++) {
    if (
        sortedResponse[i]?.frrQuestionId != sortedResponse[i + 1]?.frrQuestionId
    ) {
        sortedResponse.slice(i, 1);
        response.push(sortedResponse[i]);
    }
    }

    return response;
}