import React, { useState, useEffect } from 'react';
import frrApis from 'apis/frr';
import { UpdateCollateralPayload } from 'store/actions/collateral';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { InputTypes } from './QuestionTypes';

export default function ValuersList(props) {
  const { collateral } = useSelector((state) => state);
  const { payLoad, checks } = collateral;
  const [valuersList, setValuersList] = useState([]);
  const [selectedValuers, setSelectedValuers] = useState(null);
  const [valuationDate, setValuationDate] = useState(null);
  const [valuationEstimate, setValuationEstimate] = useState(null);
  const [valuerName, setValuerName] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    const getBankValuers = async () => {
      props?.setLoading(true);
      const res = await frrApis.getBankValuers();
      props?.setLoading(false);
      setValuersList([
        ...res?.data?.data,
        {
          firmOfEstateValuers: 'Others',
          valuersId: 99999,
        },
      ]);
    };
    getBankValuers();
    return () => {};
  }, []);

  const collateralQuestions = {
    question: 'Which of the valuer carried out the evaluation?',
    questionType: 'dropdown',
  };
  const inputHandlerValuer = (e) => {
    setSelectedValuers(e.target.value);
    const getValuer = valuersList.filter(
      (x) => x?.valuersId == e?.target?.value
    )[0];
    sessionStorage.setItem('_valuer', getValuer?.firmOfEstateValuers);
    dispatch(
      UpdateCollateralPayload({
        knownValuerId: e.target.value,
      })
    );
    sessionStorage.setItem('_currentPage', 'knownValuerId');
  };
  const inputHandlerDate = (date) => {
    setValuationDate(date);
    dispatch(
      UpdateCollateralPayload({
        valuationDate: date,
      })
    );
  };
  const inputHandlerValue = (e) => {
    setValuationEstimate(e.target.value);
    dispatch(
      UpdateCollateralPayload({
        valuationEstimate: e.target.value,
      })
    );
  };
  const inputHandlerName = (e) => {
    setValuerName(e.target.value);
    if (String(e.target.value).trim()) {
      sessionStorage.setItem('_valuer', e.target.value);
      dispatch(
        UpdateCollateralPayload({
          unknownValuerName: e.target.value,
        })
      );
    }
  };
  return (
    <Styled>
      <section>
        <p className="question_title">
          Which of the valuer carried out the evaluation?
        </p>
        <div className="select">
          <InputTypes
            optionsArray={valuersList}
            questionType={collateralQuestions.questionType}
            optionKey="firmOfEstateValuers"
            optionId="valuersId"
            inputHandler={inputHandlerValuer}
            inputtedAnswer={
              payLoad.knownValuerId == null ? '' : payLoad.knownValuerId
            }
          />
        </div>
      </section>
      <section>
        <p className="question_title">Valuation Date?</p>
        <div className="">
          <InputTypes
            className="date"
            questionType="date"
            inputtedAnswer={valuationDate}
            inputHandler={inputHandlerDate}
          />
        </div>
      </section>
      <section>
        <p className="question_title">Estimated Value in Naira?</p>
        <div className="">
          <InputTypes
            questionType="number"
            inputHandler={inputHandlerValue}
            inputtedAnswer={valuationEstimate}
          />
        </div>
      </section>
      {selectedValuers == 99999 && (
        <section>
          <p className="question_title">Valuer Name?</p>
          <div className="">
            <InputTypes
              questionType="text"
              inputHandler={inputHandlerName}
              inputtedAnswer={valuerName}
            />
          </div>
        </section>
      )}
    </Styled>
  );
}
const Styled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7%;
  width: 100%;
  overflow: scroll;
  height: 44.4rem;
  @media (max-width: 1280px) {
    padding: 0 5%;
  }
  .question_title {
    font-size: 1.4rem;
    margin: 0.5rem 0;
    font-weight: 500;
  }
  .mb_20 {
    margin-bottom: 2rem;
  }
  section {
    margin-bottom: 2rem;
  }
  .date {
    padding: 1rem;
    padding-right: 3.5rem;
    width: 100%;
    font-size: 1.6rem;
    display: block;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 1px 6px rgba(170, 170, 170, 0.25);
    border: thin solid #5fb19c;
    background-color: #fff;
  }
`;
