import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import PaginatedTable from 'components/Tables/index';
import Layout from 'components/Layouts/Default';
import BackButton from 'components/Buttons/BackButton';
import Spinner from 'components/OverLays/OverLaySpinner';
import CurrencyFormat from 'react-currency-format';
import { useOfferLetters } from 'hooks/useOfferLetters';
import { useNavigate } from 'react-router';
import { formatDate } from '../../helpers/dateFormat';

export default function OfferLetterDashboard() {
  const [offerLetters, loading] = useOfferLetters();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: 'S/N',
        accessor: (value, index) => index + 1,
      },
      {
        Header: 'ID',
        accessor: 'facilityId',
      },
      {
        Header: 'Facility',
        accessor: 'facilityType',
        style: {
          width: '40%',
        },
      },
      {
        Header: 'Value',
        accessor: (value) => (
          <CurrencyFormat
            value={value.facilityAmount}
            displayType="text"
            thousandSeparator
            prefix={value.facilityCurrencyCode}
          />
        ),
      },
      {
        Header: 'Offer Date',
        accessor: 'offerDate',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => value || 'Pending',
      },
      {
        Header: 'Action',
        accessor: (value) => (
          <button
            className="table-btn"
            onClick={() => {
              navigate(`/app/frr/offer-letter/${value.facilityId}`, { replace: true });
            }}
            disabled={value?.status?.toLowerCase().includes('expired') || value?.status?.toLowerCase().includes('accepted') || value?.status?.toLowerCase().includes('rejected')}
            style={value?.status?.toLowerCase().includes('expired') || value?.status?.toLowerCase().includes('accepted') || value?.status?.toLowerCase().includes('rejected') ? { background: 'grey' } : {}}
          >
            View
          </button>
        ),
      },
    ],
    []
  );
  const data = offerLetters?.length > 0 ? [...offerLetters] : [];

  return (
    <Styled>
      <Layout activeBar="offer-letter">
        <div className="wrapper">
          <section className="title-wrapper">
            <BackButton
              color="#00989C"
              size={15}
            />
            <h4 className="title">Offer Letter</h4>
          </section>
          <section>
            <PaginatedTable tableTag="All Offer Letters" columns={columns} data={data} />
          </section>
        </div>
      </Layout>

      { loading ? (
        <Spinner />
      ) : (
        ''
      )}

    </Styled>
  );
}
const Styled = styled.div`
  .wrapper {
    padding: 0 2rem;
  }
  .title {
    color: #004c4e;
    padding: 0.8rem;
    font-weight: 500;
  }
  .title-wrapper{
      display: flex;
      align-items: center;
      margin-top: 5rem;
      margin-bottom: 3rem;
  }
  .validation-container {
    margin-bottom: 4rem;
    background: #fff;
    padding-bottom: 1rem;
    border-radius: 8px;
    max-width: 65rem;
  }
  .validation-title {
    background: #d1e8e2;
    color: #004c4e;
    padding: 0.8rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    p {
      font-size: 1.4rem;
    }
  }
  .validation-body {
    padding: 1rem;
  }
  .empty-orr {
    background: #fff;
    padding: 1rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .validation-item {
    display: flex;
    p {
      font-size: 1.4rem;
      margin-left: 1rem;
      color: #d45f36;
    }
  }
  .validation-button {
    display: flex;
    justify-content: flex-end;
  }
  .validation-empty {
    text-align: center;
    padding: 0.5rem;
    p {
      font-size: 1.4rem;
    }
  }
  .validation-loader {
    padding: 0.5rem;
    text-align: center;
  }

  .table-wrapper {
    margin: 2rem 0 1rem;
  }
  .table-heading {
    ${({ theme }) => theme.typography.headingBody3}
  }
  .table-btn{
    border: none;
    cursor: pointer;
    color: white;
    padding: .3rem 1rem;
    background: linear-gradient(95.26deg, #00989C -30.18%, #004C4E 100%);
    border-radius: 5px;
    font-size: 1.2rem;
  }
`;
