import Main from 'components/AccountOpening/index';
import { Helmet } from 'react-helmet-async';

export default function AccountOpening() {
  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5' }}>
        <title>Account Opening | Gazelle by Sterling</title>
      </Helmet>
      <Main />
    </>
  );
}
