import { apiWithToken } from './axiosInterceptor';

const request = {
  getStates: (data) => {
    const method = 'get';
    const url = '/api/Locations/GetStates';
    return apiWithToken({ method, url, params: {} });
  },
  getLgaByStateId: (data) => {
    const method = 'get';
    const url = '/api/Locations/GetLGA';
    return apiWithToken({ method, url, params: { state: data } });
  },
};
export default request;
