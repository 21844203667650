import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CalculatorToggle } from 'store/actions/calculator';
import {
  EnvelopeSvg,
  TwitterSvg,
  InstagramSvg,
  SupportSvg,
  ToggleSvg,
  PrivacySvg,
  CalculatorSvg,
  TermsSvg,
} from 'theme/icons';

export default function Footer() {
  const dispatch = useDispatch();
  const handleCalculatorToggle = () => {
    dispatch(CalculatorToggle());
  };
  return (
    <Styled>
      <div className="footer_bottom">
        <div className="footer_bottom-layer1">
          <div className="footer_bottom-layer1-social">
            <div>
              <h3>Social</h3>
            </div>
            <div className="icon-text-wrapper">
              <EnvelopeSvg width="25px" stroke="#D1E8E2" />
              <a
                href="mailto:gazelle@sterling.ng"
                target="_blank"
                rel="noreferrer"
              >
                gazelle@sterling.ng
              </a>
            </div>
            <div className="icon-text-wrapper">
              <TwitterSvg width="25px" stroke="#D1E8E2" strokeWidth=".1px" />
              <a href="http://twitter.com">@gazelle</a>
            </div>
            <div className="icon-text-wrapper">
              <InstagramSvg width="25px" stroke="#D1E8E2" />
              <a href="http://instagram.com">gazelle@sterling.ng</a>
            </div>
          </div>
          <div className="footer_bottom-layer1-content">
            <div>
              <h3>Resources</h3>
            </div>
            <div className="icon-text-wrapper">
              <SupportSvg width="25px" stroke="#D1E8E2" strokeWidth=".2px" />
              <Link className="footer_bottom-layer1-item" to="/support">
                Contact Us
              </Link>
            </div>
            <div className="icon-text-wrapper">
              <ToggleSvg width="25px" stroke="#D1E8E2" />
              <Link className="footer_bottom-layer1-item" to="/how-it-works">
                How it works
              </Link>
            </div>
            <div className="icon-text-wrapper">
              <CalculatorSvg width="25px" stroke="#D1E8E2" />
              <p
                className="footer_bottom-layer1-item"
                onClick={handleCalculatorToggle}
              >
                Loan Calculator
              </p>
            </div>
          </div>
          <div className="footer_bottom-layer1-legal">
            <div>
              <h3>Legal</h3>
            </div>
            <div className="icon-text-wrapper">
              <TermsSvg width="25px" stroke="#D1E8E2" strokeWidth=".2px" />
              <Link
                className="footer_bottom-layer1-item"
                to="/terms-conditions"
              >
                Terms & Conditions
              </Link>
            </div>
            <div className="icon-text-wrapper">
              <PrivacySvg width="25px" stroke="#D1E8E2" strokeWidth=".2px" />
              <Link className="footer_bottom-layer1-item" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>Gazelle by Sterling © 2020. All Right Reserved.</p>
        </div>
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  h3 {
    font-size: 18px;
    color: #d9b08c;
    margin-bottom: 10px;
  }
  .maxWidth-1440 {
    max-width: 1440px;
    margin: 0 auto;
  }
  .footer_bottom {
    background-color: #505050;
    padding: 20px;
    color: #fff;
    font-size: 14px;
    a:visited,
    a:link {
      color: #fff;
    }
  }
  .footer_bottom-layer1 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @media (max-width: 479px) {
      justify-content: space-between;
    }
  }
  .footer_bottom-layer1-item {
    padding-right: 10px;
    cursor: pointer;
    display: inline-block;
  }
  .icon-text-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    svg {
      margin-right: 5px;
    }
  }
  .footer-bottom {
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 479px) {
    .footer_bottom-layer1-social,
    .footer_bottom-layer1-content,
    .footer_bottom-layer1-legal {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;
