import React from 'react';
import styled from 'styled-components';
import { NoteMarkSvg } from 'theme/icons';

export default function Tab(props) {
  const { tabHandler, activeSection, history } = props;

  return (
    <Styled className="orr_ques_nav">
      <div
        onClick={() => tabHandler('Management')}
        className={`orr_ques_nav-item ${
          activeSection == 'Management' && 'orr_ques_nav_active_tab'
        }`}
      >
        <NoteMarkSvg
          width="2rem"
          strokeWidth=".1px"
          className={`section_tab-icon ${
            activeSection == 'Management' && 'section_tab-icon-active'
          } ${history.Management && 'section_tab-icon-active'}`}
        />
        <p
          className={`section_tab ${
            activeSection == 'Management' && 'section_tab-active'
          } ${history.Management && 'section_tab-active'}`}
        >
          Management
        </p>
      </div>
      <div
        onClick={() => tabHandler('Business')}
        className={`orr_ques_nav-item ${
          activeSection == 'Business' && 'orr_ques_nav_active_tab'
        }`}
      >
        <NoteMarkSvg
          width="2rem"
          strokeWidth=".1px"
          className={`section_tab-icon ${
            activeSection == 'Business' && 'section_tab-icon-active'
          } ${history.Business && 'section_tab-icon-active'}`}
        />
        <p
          className={`section_tab ${
            activeSection == 'Business' && 'section_tab-active'
          } ${history.Business && 'section_tab-active'}`}
        >
          Business
        </p>
      </div>
      <div
        onClick={() => tabHandler('Financials')}
        className={`orr_ques_nav-item ${
          activeSection == 'Financials' && 'orr_ques_nav_active_tab'
        }`}
      >
        <NoteMarkSvg
          width="2rem"
          strokeWidth=".1px"
          className={`section_tab-icon ${
            activeSection == 'Financials' && 'section_tab-icon-active'
          } ${history.Financials && 'section_tab-icon-active'}`}
        />
        <p
          className={`section_tab ${
            activeSection == 'Financials' && 'section_tab-active'
          } ${history.Financials && 'section_tab-active'}`}
        >
          Financials
        </p>
      </div>
      <div className="orr_ques_nav-item">
        <NoteMarkSvg
          width="2rem"
          strokeWidth=".1px"
          className="section_tab-icon"
        />
        <p className="section_tab">Summary</p>
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #d9b08c;
  margin: 8rem 0 1rem;
  p {
    padding: 0 1rem;
  }
  @media (max-width: 80rem) {
    width: 100%;
  }
  .orr_ques_nav-item {
    text-align: center;
    cursor: pointer;
  }
  .section_tab {
    color: #b8b8b8;
  }
  .section_tab-active {
    color: #000;
  }
  .section_tab-icon {
    stroke: #b8b8b8;
    fill: #b8b8b8;
  }
  .section_tab-icon-active {
    stroke: #004c4e;
    fill: #004c4e;
  }
  .orr_ques_nav_active_tab {
    border-bottom: 3px solid #d9b08c;
  }
`;
