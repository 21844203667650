import React from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';
import styled from 'styled-components';
import Terms from '../../../Home/TermsAndConditions';
import Policy from '../../../Home/PrivacyPolicy';

const customStyles = {
  content: {
    borderRadius: '0px',
    border: 'none',
    position: 'relative',
    margin: 'auto',
    inset: '0',
    padding: '0px',
  },
};
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0 0 0 / 54%)';
export default function TermsModal(props) {
  const closeHandler = () => {
    props.closeModal();
  };
  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="TermsAndCondition"
    >
      <Styled>
        <div className="closeBtn">
          <FiX size={20} color="#000" onClick={closeHandler} />
        </div>
        {props?.current == 'terms' ? <Terms /> : <Policy />}
      </Styled>
    </Modal>
  );
}
const Styled = styled.div`
  overflow-y: scroll;
  height: 90vh;
  .closeBtn {
    position: absolute;
    top: 1rem;
    right: 2rem;
    cursor: pointer;
  }
  .buttonWrapper {
    text-align: center;
    button {
      margin-top: 2rem;
    }
  }
`;
