import * as actionTypes from '../action-types/calculator';

const initialState = {
  display: false,
};
export default function auth(state = initialState, { type, payLoad }) {
  switch (type) {
    case actionTypes.TOGGLE_CALCULATOR:
      return {
        display: !state.display,
      };
    default:
      return state;
  }
}
