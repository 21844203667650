import React, { useEffect, useState } from 'react';
import Button from 'components/Buttons';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'components/OverLays/OverLaySpinner';
import dayjs from 'dayjs';
import { FiEdit } from 'react-icons/fi';
import styled from 'styled-components';
import { businessApis, adminUserApis } from 'apis';
import { toast } from 'react-toastify';
import useOrrActive from 'hooks/useOrrActive';
import useBusinessProfile from 'hooks/useBusinessProfile';
import ErrorHandler from 'helpers/errorHandler';
import * as BusinessActions from 'store/actions/business';
import { useNavigate } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import Layout from '../Layouts/Settings';

const businessStaffs = [
  { text: '0 - 20', value: '0 - 20' },
  { text: '21 - 50', value: '21 - 50' },
  { text: '51 - 100', value: '51 - 100' },
  { text: '101-500', value: '101-500' },
  { text: '501-1000', value: '501-1000' },
  { text: '>1000', value: '>1000' },
];

export default function Profile() {
  const navigate = useNavigate();
  const [params] = useQuery();
  const dispatch = useDispatch();
  const [buzProfile, isLoading, setLoader] = useBusinessProfile();
  const { auth, business } = useSelector((state) => state);

  const [isOrrActive] = useOrrActive();
  const profile = buzProfile[0] || {};

  const [businessProfile, setBusinessProfile] = useState({ ...profile });
  const [subSectorDetails, setSubSectorDetails] = useState({});
  const [activeSubSectorIndex, setActiveSubSectorIndex] = useState(null);
  const [businessPromoter, setBusinessPromoter] = useState({});
  const [allSubSector, setAllSubSector] = useState([]);
  const [readOnly, setReadOnly] = useState({
    staffStrength: true,
    website: true,
    subSector: true,
    rcNumber: true,
  });
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const { subSectorId } = business.businessProfiles[0];
  const businessId = auth?.user?.businessId;

  useEffect(() => {
    let isMounted = true;
    async function getSubSectorDetailsById() {
      try {
        setLoader((prev) => true);
        const res = await adminUserApis.getSubSectorDetailsById(subSectorId);
        if (res.status == 200) {
          isMounted && setSubSectorDetails((prev) => res.data.data[0]);
        }
        await getSubSector(res.data.data[0].sectorId);
      } catch (error) {
        ErrorHandler(error);
      } finally {
        isMounted && setLoader((prev) => false);
      }
    }
    async function getSubSector(sectorId) {
      try {
        const res = await adminUserApis.getAllSubSectorBySectorId(sectorId);
        isMounted && setAllSubSector(formatAllSubSector(res?.data?.data));
      } catch (error) {
        ErrorHandler(error);
      } finally {
        isMounted && setLoader((prev) => false);
      }
    }

    getSubSectorDetailsById();
    return () => {
      isMounted = false;
    };
  }, [subSectorId]);
  useEffect(() => {
    let isMounted = true;
    async function isOrrStarted() {
      try {
        const res = await adminUserApis.getGenericAnswers(businessId);
        if (res.data.status) {
          const promoter = res?.data?.data?.filter(
            (elem) => elem.primaryPromoter
          );
          isMounted && setBusinessPromoter((prev) => promoter[0]);
        }
      } catch (error) {
        ErrorHandler(error);
      } finally {
        isMounted && setLoader((prev) => false);
      }
    }
    isOrrStarted();
    return () => {
      isMounted = false;
    };
  }, [businessId]);

  const updateHandler = async () => {
    const Regex = new RegExp(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
    );
    if (
      businessProfile?.website?.length > 2
      && !Regex.test(businessProfile.website)
    ) {
      return toast.error('Invalid Website');
    }
    if (businessProfile?.rcNumber) {
      if (
        businessProfile?.rcNumber?.trim().length < 2
        || !businessProfile?.rcNumber?.includes('RC')
      ) {
        return toast.error('Invalid RC Number');
      }
    }
    try {
      setLoader(true);
      const newProfile = { ...profile, ...businessProfile };
      newProfile.applicationId = auth.user.applicationId;

      const res = await businessApis.updateBusinessProfile(newProfile);

      if (res.data.status) {
        setReadOnly((prev) => ({
          staffStrength: true,
          website: true,
          subSector: true,
          rcNumber: true,
        }));
        toast.success('Profile Updated');

        const busProfileClone = [...buzProfile];
        const currentBusIndex = busProfileClone.findIndex(
          (elem) => elem.businessId == res.data.data.businessId
        );
        busProfileClone[currentBusIndex] = res.data.data;
        dispatch(BusinessActions.BusinessSaveProfiles(busProfileClone));
        if (activeSubSectorIndex) {
          setSubSectorDetails((prev) => ({
            ...prev,
            subSectorId: activeSubSectorIndex.value,
            subSectorName: activeSubSectorIndex.sectorName,
          }));
        }
      } else {
        toast.error(res.data.data);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoader(false);
    }
  };
  const onChangeHandler = (e) => {
    const { value, name } = e.target;

    setBusinessProfile((prev) => ({
      ...prev,
      [name]: name == 'subSectorId' ? Number(value) : value,
    }));
    if (name == 'subSectorId') {
      const sectorName = e?.target?.selectedOptions[0]?.innerText;

      setActiveSubSectorIndex({ sectorName, value });
    }
  };

  const formatAllSubSector = (data) => data.map((elem) => ({
    text: elem.subSectorName,
    value: elem.subSectorId,
  }));
  const toggleWebsiteEdit = () => {
    setShowUpdateBtn(true);
    setReadOnly((prev) => ({ ...prev, website: !prev.website }));
  };
  const toggleStaffEdit = () => {
    setShowUpdateBtn(true);
    setReadOnly((prev) => ({ ...prev, staffStrength: !prev.staffStrength }));
  };
  const toggleSubSectorEdit = () => {
    setShowUpdateBtn(true);
    setReadOnly((prev) => ({ ...prev, subSector: !prev.subSector }));
  };
  const toggleRcNumberEdit = () => {
    setShowUpdateBtn(true);
    setReadOnly((prev) => ({ ...prev, rcNumber: !prev.rcNumber }));
  };

  return (
    <Layout activeBar="settings">
      <Styled>
        {isLoading ? <Spinner /> : undefined}
        <div className="profile_wrapper">
          <main className="profile">
            <h3>Business Profile</h3>
            <div>
              <div className="profile_row">
                <div className="profile_row_left border-type1">
                  <p>Name of Business</p>
                  <p>{profile?.businessName}</p>
                </div>
                <div className="profile_row_right border-type2">
                  <div className="profile_staff_header">
                    <p style={{ marginRight: '5px' }}>Staff Strength</p>
                    <div style={{ cursor: 'pointer' }}>
                      <FiEdit
                        size={15}
                        color="#004C4E"
                        onClick={toggleStaffEdit}
                      />
                    </div>
                  </div>
                  <div>
                    {readOnly.staffStrength ? (
                      <p
                        className="pTypeTwo"
                        style={{ minWidth: '10rem', display: 'inline-block' }}
                      >
                        {profile?.staffStrength || ''}
                      </p>
                    ) : (
                      <select
                        className="editable_select"
                        name="staffStrength"
                        onChange={onChangeHandler}
                        value={businessProfile?.staffStrength || ''}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {businessStaffs.map((elem, i) => (
                          <option value={elem.value} key={i}>
                            {elem.text}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="profile_row">
                <div className="profile_row_left">
                  <p>Business Type</p>
                  <p>{profile?.businessType}</p>
                </div>
                <div className="profile_row_right ">
                  <p>Business Promoter</p>
                  <p>
                    {`${businessPromoter?.firstName || ''} ${
                      businessPromoter?.lastName || ''
                    }`}
                  </p>
                </div>
              </div>
              <div className="profile_row">
                <div className="profile_row_left ">
                  <p>Date of Incorporation</p>
                  <p>
                    {profile?.dateIncorporated
                      && dayjs(String(profile.dateIncorporated)).format(
                        'YYYY-MMM-DD'
                      )}
                  </p>
                </div>
                <div className="profile_row_right border-type3">
                  <div className="profile_website_header">
                    <p style={{ marginRight: '5px' }}>RC Number</p>
                    {!profile?.rcNumber && (
                      <div style={{ cursor: 'pointer' }}>
                        <FiEdit
                          size={15}
                          color="#004C4E"
                          onClick={toggleRcNumberEdit}
                        />
                      </div>
                    )}
                  </div>

                  <div>
                    {readOnly.rcNumber ? (
                      <p
                        className="pTypeTwo"
                        style={{ minWidth: '10rem', display: 'inline-block' }}
                      >
                        {profile?.rcNumber || ''}
                      </p>
                    ) : (
                      <input
                        className={`editable_input ${
                          !readOnly.rcNumber && 'nonEditable_input'
                        }`}
                        type="text"
                        name="rcNumber"
                        placeholder="RC1234567"
                        onChange={onChangeHandler}
                        readOnly={readOnly.rcNumber}
                        value={businessProfile?.rcNumber || ''}
                        autoComplete="off"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="profile_row">
                <div className="profile_row_left border-type1">
                  <div className="profile_website_header">
                    <p style={{ marginRight: '5px' }}>Business Website</p>
                    <div style={{ cursor: 'pointer' }}>
                      {!profile?.website && (
                        <FiEdit
                          size={15}
                          color="#004C4E"
                          onClick={toggleWebsiteEdit}
                        />
                      )}
                    </div>
                  </div>

                  <div>
                    {readOnly.website ? (
                      <p
                        className="pTypeTwo"
                        style={{ minWidth: '10rem', display: 'inline-block' }}
                      >
                        {profile?.website || ''}
                      </p>
                    ) : (
                      <input
                        className={`editable_input ${
                          !readOnly.website && 'nonEditable_input'
                        }`}
                        type="url"
                        name="website"
                        pattern="https://.*"
                        placeholder="https://example.com"
                        onChange={onChangeHandler}
                        readOnly={readOnly.website}
                        value={businessProfile?.website || ''}
                        autoComplete="off"
                      />
                    )}
                  </div>
                </div>
                <div className="profile_row_right">
                  <p>Business Email Address</p>
                  <p>{profile.companyEmail}</p>
                </div>
              </div>
              <div className="profile_row">
                <div className="profile_row_left border-type2">
                  <p>Sector</p>
                  <p>{subSectorDetails?.sectorName}</p>
                </div>
                <div className="profile_row_right ">
                  <p style={{ display: 'inline-block', marginRight: '1rem' }}>
                    Sub-Sector
                  </p>
                  {isOrrActive && (
                    <FiEdit
                      size={15}
                      color="#004C4E"
                      onClick={toggleSubSectorEdit}
                    />
                  )}

                  <div>
                    {readOnly.subSector ? (
                      <p
                        className="pTypeTwo"
                        style={{ minWidth: '10rem', display: 'inline-block' }}
                      >
                        {subSectorDetails?.subSectorName || ''}
                      </p>
                    ) : (
                      <select
                        className="editable_select"
                        name="subSectorId"
                        onChange={onChangeHandler}
                        value={businessProfile?.subSectorId || ''}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {allSubSector?.map((elem, i) => (
                          <option value={elem.value} key={i}>
                            {elem.text}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="profile_row">
                <div className="profile_row_left border-type1">
                  <p>Company TIN</p>
                  <p>{profile.companyTin}</p>
                </div>
                <div className="profile_row_left ">
                  <p>Business Address</p>
                  <p>{profile.companyPrimaryAddress}</p>
                </div>
              </div>
            </div>
          </main>
          <div className="update_container">
            {showUpdateBtn && (
              <Button title="Update" mode="1" onClick={updateHandler} />
            )}
            {params?.get('from') == 'facility-dashboard' ? (
              <Button
                title="Back to Evaluation"
                mode="1"
                onClick={() => navigate('/app/orr/dashboard')}
                className="backBtnEvaluation "
              />
            ) : undefined}
          </div>
        </div>
      </Styled>
    </Layout>
  );
}
const Styled = styled.div`
  width: 100%;
  .profile_wrapper {
    max-width: 90rem;
    background: #fff;
    margin: 2rem;
    border: 4px solid #d1e8e2;
    border-radius: 15px;
    padding: 1rem 0;
  }
  .backBtnEvaluation {
    margin-left: 3rem;
  }
  .quick_links {
    display: flex;
    justify-content: center;
  }
  .application_btn {
    padding: 1rem 2rem;
    background-color: #004c4e;
    text-align: center;
    margin-bottom: 1rem;
    border-radius: 1rem;
    color: #d9b08c;
    p {
      font-size: 1.6rem;
    }
  }
  .profile {
    border-radius: 1rem;
    padding: 2rem;
    padding-left: 4rem;
    h3 {
      font-weight: 600;
      color: #004c4e;
    }
  }
  .profile_row {
    display: flex;
    justify-content: flex-start;
    margin: 2rem 0;
    white-space: nowrap;
    overflow: hidden;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p:nth-of-type(1) {
      color: #004c4e;
    }
    p:nth-of-type(2) {
      color: #505050;
      font-size: 1.4rem;
      padding-top: 0.5rem;
    }
    .profile_row_left {
      width: 50%;
      border-left: 2px solid #21c55a;
      padding-left: 1rem;
    }
    .profile_row_right {
      border-left: 2px solid #21c55a;
      padding-left: 1rem;
    }
    .border-type1 {
      border-left: 2px solid #21c55a;
    }
    .border-type2 {
      border-left: 2px solid #21c55a;
    }
    .border-type3 {
      border-left: 2px solid #21c55a;
    }
    .pTypeTwo {
      color: #505050 !important;
      font-size: 1.4rem;
      padding-top: 0.5rem;
    }
  }
  .update_container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-right: 5rem;
  }
  .editable_input {
    padding: 0.5rem;
    border: thin solid #c5c9ca;
    border-radius: 5px;
  }
  .editable_select {
    padding: 0.5rem;
    border: thin solid #c5c9ca;
    border-radius: 5px;
  }
  .nonEditable_input {
    border-bottom: thin solid #c5c9ca;
  }
  .profile_staff_header,
  .profile_website_header {
    display: flex;
  }
`;
