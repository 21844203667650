import Layout from 'components/Layouts/Default';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import errorHandler from 'helpers/errorHandler';
import { accountOpeningApis, businessApis } from 'apis';
import Spinner from 'components/OverLays/OverLaySpinner';
import Button from 'components/Buttons';
import Modal from 'components/FacilityRiskRating/modal';
import { AiOutlineCheck } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Uploader from './uploader';
import DropDownInput from './dropdown';
import Input from './input';
import {
  encryptHandler,
  decryptHandler,
} from '../../helpers/accountOpeningEncryption';
import { base64 } from './base64';

function AccountOpening() {
  const navigate = useNavigate();
  const passportId = useSelector(
    (state) => state?.business?.businessProfiles[0]?.passportId
  );
  const businessId = useSelector(
    (state) => state?.business?.businessProfiles[0]?.businessId
  );
  const [loader, setLoader] = useState(false);
  const [states, setStates] = useState([]);
  const [Industries, setIndustries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [sectorCode, setSectorCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [currency, setCurrency] = useState('');
  const [updateBusinessPayload, setUpdateBusinessPayload] = useState({
    businessId,
    passportId,
    companyAccountNumber: '',
  });
  const [accountOpeningPayload, setAccountOpeningPayload] = useState({
    URLSignature: '',
    CorporateAddress: '',
    BranchName: 'NG0020002',
    RcNumber: '',
    IncorpDate: '',
    Tin: '',
    BusinessName: '',
    Branch: 'NG0020002',
    EmailAddress: '',
    ProductCode: 'CLASSIC.CUR',
    Currency: 'NGN',
    ClientID: '52',
    FirstName: '',
    SurName: '',
    Street: '',
    Sector: '',
    Industry: '',
    Nationality: 'NG',
    Bvn: '',
    Dob: '',
    Email: '',
    State: '',
    Phone: '',
    PassportPhoto: '',
    Signature: '',
    Residence: '',
    stateResidence: '',
    AppId: '',
    Sms: '',
    ProductName: '',
    Source: '',
    PassportPic: '',
    StateOfOrigin: '',
    City_Town: '',
    Documents: [],
  });

  useEffect(() => {
    const getAccountOpeningParameter = async () => {
      setLoader(true);
      try {
        const response = await businessApis.getAccountOpeningParametersByPassportId(
          passportId
        );
        setAccountOpeningPayload({
          ...accountOpeningPayload,
          CorporateAddress: response.data.data.companyPrimaryAddress,
          ProductCode: response.data.data.productCode,
          Bvn: response.data.data.bvn,
          EmailAddress: response.data.data.customerEmail,
          Email: response.data.data.customerEmail,
          Sms: response.data.data.companyPhoneNumber,
          Phone: response.data.data.companyPhoneNumber,
          RcNumber: response.data.data.rcNumber,
          Tin: response.data.data.companyTin,
          BusinessName: response.data.data.businessName.length < 36 ? response.data.data.businessName : response.data.data.businessName.slice(0, 34),
          IncorpDate: response.data.data.dateIncorporated,
          FirstName: response.data.data.firstName,
          SurName: response.data.data.lastName,
          Dob: response.data.data.dateOfBirth,
          ClientID: response.data.data.clientId,
          Source: response.data.data.source,
          ProductName: response.data.data.productName,
          AppId: response.data.data.applicationId,
        });
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoader(false);
      }
    };

    const getStates = async () => {
      try {
        const response = await accountOpeningApis.getAllStates();
        setStates(response.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    const getSectors = async () => {
      setIsLoading(true);
      try {
        const response = await accountOpeningApis.getAllSectors();
        const decryptedSector = decryptHandler(response.data);
        setSectors(decryptedSector.Sectors);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    getAccountOpeningParameter();
    getStates();
    getSectors();
  }, []);

  const getIndustries = async (arg) => {
    setIsLoading(true);
    // let data = decryptHandler(arg);
    // const payload = encryptHandler(arg);
    try {
      const response = await accountOpeningApis.getAllIndustries(arg);
      const decryptedIndustry = decryptHandler(response.data);
      setIndustries(decryptedIndustry.Industries);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateBusiness = async () => {
    setLoader(true);
    try {
      const response = await businessApis.updateBusinessProfile(
        updateBusinessPayload
      );
      navigate('/app/dashboard');
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoader(false);
    }
  };

  const uploadDocument = async (file, fileType) => {
    setIsLoading(true);
    const convertedFileAs64String = await base64(file);

    const filesArr = convertedFileAs64String.split(',');
    const fileSplit = filesArr[1];

    const payload = {
      appId: accountOpeningPayload.AppId,
      folderName: `gazelleOnboarding - ${accountOpeningPayload.FirstName} ${accountOpeningPayload.SurName} folder 3`,
      fileName: `gazelleOnboarding - ${accountOpeningPayload.FirstName} ${accountOpeningPayload.SurName} folder 3`,
      base64String: fileSplit,
    };

    const arg = encryptHandler(payload);

    try {
      const res = await accountOpeningApis.uploadFile(arg);
      const decryptedFileData = decryptHandler(res.data);
      if (fileType.toLowerCase() == 'signature') {
        setAccountOpeningPayload({
          ...accountOpeningPayload,
          URLSignature: decryptedFileData.URL,
          Signature: decryptedFileData.URL,
        });
      }

      if (fileType.toLowerCase() == 'passport photograph') {
        setAccountOpeningPayload({
          ...accountOpeningPayload,
          PassportPhoto: decryptedFileData.URL,
          PassportPic: decryptedFileData.URL,
        });
      }

      if (fileType.toLowerCase() == 'cac document') {
        setAccountOpeningPayload({
          ...accountOpeningPayload,
          Documents: [
            {
              AccountNumber: '',
              DocumentName: 'gazelleOnboarding / file 1',
              DocURL: decryptedFileData.URL,
            },
          ],
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const openAccount = async () => {
    setIsLoading(true);
    const arg = encryptHandler(accountOpeningPayload);
    try {
      const response = await accountOpeningApis.createCorporateAccount(arg);
      const decryptedData = decryptHandler(response.data);
      if (decryptedData.responseCode == '1') {
        setAccountNumber(decryptedData.nuban);
        setCurrency(decryptedData.currency);
        setUpdateBusinessPayload({
          ...updateBusinessPayload,
          companyAccountNumber: decryptedData.nuban,
        });
        setOpenModal(true);
      } else {
        toast.error('Error generating an account!');
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Styled>
      <Layout
        activeBar="account-opening"
        headerId="header"
        tabsId="tabs"
        tabsBackgroundId="tabs-background"
      >
        <div className="Wrapper">
          <p id="heading">Account Opening</p>
          <div id="forms-holder">
            <p id="form-heading">Complete this form to open an account</p>

            <DropDownInput
              label="Sector"
              dropDownData={sectors}
              present="sector"
              onChange={(e) => {
                setSectorCode(e.target.value);
                setAccountOpeningPayload({
                  ...accountOpeningPayload,
                  Sector: e.target.value,
                });
                if (e.target.value !== '') {
                  getIndustries(e.target.value);
                } else {
                  setIndustries([]);
                }
              }}
            />
            <DropDownInput
              label="Industry"
              // disabled={sectorCode == ''}
              present="industry"
              dropDownData={Industries}
              onChange={(e) => {
                setSectorCode(e.target.value);
                setAccountOpeningPayload({
                  ...accountOpeningPayload,
                  Industry: e.target.value,
                });
              }}
            />
            <Input
              label="Signatory residence"
              placeholder="Enter residence"
              onChange={(e) => {
                setAccountOpeningPayload({
                  ...accountOpeningPayload,
                  Street: e.target.value,
                  Residence: e.target.value,
                });
              }}
            />
            <DropDownInput
              label=" Signatory state of residence"
              dropDownData={states}
              present="states"
              onChange={(e) => {
                setAccountOpeningPayload({
                  ...accountOpeningPayload,
                  stateResidence: String(e.target.value),
                  State: String(e.target.value),
                });
              }}
            />
            <div className="row-arranger">
              <DropDownInput
                label="Signatory state of origin"
                dropDownData={states}
                present="states"
                id="origin-select"
                onChange={(e) => {
                  setAccountOpeningPayload({
                    ...accountOpeningPayload,
                    StateOfOrigin: e.target.value,
                    City_Town: e.target.value,
                  });
                }}
              />
              <Uploader
                onChange={(e) => {
                  uploadDocument(e.target.files, 'Signature');
                }}
                label="Signature"
                accept = 'image/png, image/gif, image/jpeg'
                fileGuide = ' (png,jpeg,gif)'
                small = {true}
              />
            </div>
            <div className="row-arranger">
              <Uploader
                onChange={(e) => {
                  uploadDocument(e.target.files, 'CAC Document');
                }}
                label="CAC Document"
                accept = 'image/png, image/gif, image/jpeg'
                fileGuide = ' (png,jpeg,gif)'
                small = {true}
              />
              <Uploader
                onChange={(e) => {
                  uploadDocument(e.target.files, 'Passport Photograph');
                }}
                label="Passport photograph"
                accept = 'image/png, image/gif, image/jpeg'
                fileGuide = ' (png,jpeg,gif)'
                small = {true}
              />
            </div>
          </div>
          <div className="button-holder">
            <Button
              onClick={() => openAccount()}
              title="Submit"
              id="submit-button"
              disabled={
                accountOpeningPayload.Sector == ''
                || accountOpeningPayload.Industry == ''
                || accountOpeningPayload.Residence == ''
                || accountOpeningPayload.State == ''
                || accountOpeningPayload.StateOfOrigin == ''
                || accountOpeningPayload.PassportPhoto == ''
                || accountOpeningPayload.Signature == ''
                || accountOpeningPayload.Documents.length < 1
              }
              // style={
              //   accountOpeningPayload.Sector == ''
              //   || accountOpeningPayload.Industry == ''
              //   || accountOpeningPayload.Residence == ''
              //   || accountOpeningPayload.State == ''
              //   || accountOpeningPayload.StateOfOrigin == ''
              //   || accountOpeningPayload.PassportPhoto == ''
              //   || accountOpeningPayload.Signature == ''
              //   || accountOpeningPayload.Documents.length < 1
              //     ? { opacity: '0.1' }
              //     : {}
              // }
            />
          </div>
        </div>
      </Layout>

      {loader || isLoading ? <Spinner /> : ''}

      {openModal ? (
        <Modal id="action-modal" iconId="close-icon">
          <div id="action-modal-top">
            <div className="icon-holder">
              <div id="icon-holder-circle">
                <AiOutlineCheck size={60} color="#00989C" />
              </div>
            </div>
          </div>
          <p className="Action-modal-text">
            Dear customer,
            <br />
            your account has been opened successfully.
            <br />
            <br />
            Account number:
            {' '}
            <span style={{ fontWeight: 'bolder' }}>{accountNumber}</span>
            <br />
            Currency:
            {' '}
            <span style={{ fontWeight: 'bolder' }}>{currency}</span>
          </p>
          <div className="Modal-button-holder">
            <Button
              onClick={() => {
                updateBusiness();
              }}
              title="Save account to profile"
              id="open-input-button"
            />
          </div>
        </Modal>
      ) : (
        ''
      )}
    </Styled>
  );
}

const Styled = styled.div`
  .Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 2rem 10rem 2rem;
    margin-top: 10rem;

    @media (max-width: 80rem) {
      flex-direction: column;
    }
  }

  #tabs {
    position: fixed;
    top: 6.8rem;
    bottom: -5rem;
    left: 1rem;
    min-height: 100vh;
  }

  #header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }

  #heading {
    color: #004c4e;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  #forms-holder {
    width: 80rem;
    margin-top: 2rem;
    background: #ffffff;
    border: 6px solid #d1e8e2;
    border-radius: 15px;
    padding: 3rem 3rem 5rem 3rem;
  }

  #form-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #004c4e;
    margin-bottom: 3rem;
  }

  #submit-button {
    padding: 1rem 3rem;
    border: none;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-radius: 6px;
    color: #d9b08c;
    cursor: pointer;
    float: right;
  }

  .row-arranger {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
  }

  .button-holder {
    width: 80rem;
    margin-top: 2rem;
  }

  #origin-select {
    margin-top: unset;
  }

  .Action-modal-text {
    color: #505050;
    text-align: center;
    margin: 18rem 2rem 3rem 2rem;
  }

  #prompt-modal {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #action-modal {
    background-color: white;
    height: 40rem;
  }

  #action-modal-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 15rem;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  #close-icon {
    z-index: 100;
    display: none;
  }

  #input-close-icon {
    color: #c1c1c1;
  }

  #action-modal-top-icon {
    font-size: 10rem;
    color: #d9b08c;
  }

  #icon-holder-circle {
    height: 10rem;
    width: 10rem;
    border: 3px solid #00989c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #input-modal {
    background-color: white;
    width: 25%;
  }

  .Input-modal-button-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #open-account-button {
    padding: 1rem;
    border: 1px solid #5fb19c;
    background-color: #eee;
    border-radius: 6px;
    color: #004c4e;
    cursor: pointer;
  }

  #open-input-button {
    padding: 1rem;
    border: 1px solid #5fb19c;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-radius: 6px;
    color: #d9b08c;
    cursor: pointer;
  }

  .Modal-button-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .Summary-validation-title {
    background: #d1e8e2;
    color: #004c4e;
    padding: 0.8rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    p {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .Chart-holder {
    width: 80%;
    padding: 0.5rem;
    background-color: #eafbf7;
    border: 1px solid #5fb19c;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Chart-holder-main {
    background-color: white;
    padding: 1rem 2rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #correct-text {
    color: #004c4e;
    margin-top: 0.5rem;
  }

  #question-buttons-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #no-button {
    border: none;
    margin-right: 2rem;
    background-color: transparent;
    color: #004c4e;
    cursor: pointer;
  }

  #yes-button {
    padding: 1rem 3rem;
    border: none;
    background-color: #004c4e;
    border-radius: 6px;
    color: #d9b08c;
    cursor: pointer;
  }
`;

export default AccountOpening;



// import Layout from 'components/Layouts/Default';
// import styled from 'styled-components';
// import { useNavigate } from 'react-router-dom';
// import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import errorHandler from 'helpers/errorHandler';
// import { AccountOpeningV2, accountOpeningApis, businessApis } from 'apis';
// import Spinner from 'components/OverLays/OverLaySpinner';
// import Button from 'components/Buttons';
// import Modal from 'components/FacilityRiskRating/modal';
// import { AiOutlineCheck } from 'react-icons/ai';
// import { toast } from 'react-toastify';
// import Uploader from './uploader';
// import DropDownInput from './dropdown';
// import Input from './input';
// import { base64 } from './base64';

// function AccountOpening() {
//   const navigate = useNavigate();
//   const passportId = useSelector(
//     (state) => state?.business?.businessProfiles[0]?.passportId
//   );
//   const businessId = useSelector(
//     (state) => state?.business?.businessProfiles[0]?.businessId
//   );
//   const [loader, setLoader] = useState(false);
//   const [states, setStates] = useState([]);
//   const [openModal, setOpenModal] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [accountNumber, setAccountNumber] = useState('');
//   const [currency, setCurrency] = useState('');
//   const [updateBusinessPayload, setUpdateBusinessPayload] = useState({
//     businessId,
//     passportId,
//     companyAccountNumber: '',
//   });
//   const [accountOpeningPayload, setAccountOpeningPayload] = useState({
//       passportId: passportId,
//       city_Town: "",
//       addressState: "",
//       taxId: "",
//       passportPhoto: "",
//       signature: "",
//       nextOfKinFullName: "",
//       nextOfKinPhone:"",
//       nextOfKinAddress: "",
//       nextOfKinRelationShip: ""
//   });

//   useEffect(() => {

//     const getStates = async () => {
//       try {
//         const response = await accountOpeningApis.getAllStates();
//         setStates(response.data);
//       } catch (error) {
//         errorHandler(error);
//       }
//     };

//     getStates();
//   }, []);

//   const updateBusiness = async () => {
//     setLoader(true);
//     try {
//       const response = await businessApis.updateBusinessProfile(
//         updateBusinessPayload
//       );
//       navigate('/app/dashboard');
//     } catch (error) {
//       errorHandler(error);
//     } finally {
//       setLoader(false);
//     }
//   };

//   const uploadDocument = async (file, fileType) => {
//     setIsLoading(true);
//     const convertedFileAs64String = await base64(file);

//     const filesArr = convertedFileAs64String.split(',');
//     const fileSplit = filesArr[1];
    
//     if(fileType == 'passport photograph'){
//       setIsLoading(false);
//       return setAccountOpeningPayload({
//         ...accountOpeningPayload,
//         passportPhoto: fileSplit,
//       });
//     }else if(fileType == 'signature'){
//       setIsLoading(false);
//       return setAccountOpeningPayload({
//         ...accountOpeningPayload,
//         signature: fileSplit,
//       });
//     }
//   };

//   const openAccount = async () => {
//     setIsLoading(true);
//     try {
//       const response = await AccountOpeningV2.openCorporateAccount(accountOpeningPayload);
//         if(response?.data?.status) {
//           setAccountNumber(response.data.data.accountNumber);
//           // setCurrency(decryptedData.currency);
//           setUpdateBusinessPayload({
//             ...updateBusinessPayload,
//             companyAccountNumber: response.data.data.accountNumber,
//           });
//           setOpenModal(true);
//         } else {
//           toast.error(response?.data?.data);
//         }
//     } catch (error) {
//       errorHandler(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

  

//   return (
//     <Styled>
//       <Layout
//         activeBar="account-opening"
//         headerId="header"
//         tabsId="tabs"
//         tabsBackgroundId="tabs-background"
//       >
//         <div className="Wrapper">
//           <p id="heading">Account Opening</p>
//           <div id="forms-holder">
//             <p id="form-heading">Complete this form to open an account</p>
            
//             <Input
//               label="Signatory city/town"
//               placeholder="Enter city/town"
//               onChange={(e) => {
//                 setAccountOpeningPayload({
//                   ...accountOpeningPayload,
//                   city_Town: e.target.value
//                 });
//               }}
//             />
//             <DropDownInput
//               label=" Signatory state"
//               dropDownData={states}
//               present="states"
//               onChange={(e) => {
//                 setAccountOpeningPayload({
//                   ...accountOpeningPayload,
//                   addressState: String(e.target.value),
//                 });
//               }}
//             />
            
//             <Input
//               label="Tax Id"
//               placeholder="Enter tax id"
//               onChange={(e) => {
//                 setAccountOpeningPayload({
//                   ...accountOpeningPayload,
//                   taxId: e.target.value,
//                 });
//               }}
//             />
//             <Input
//               label="Next of kin full name"
//               placeholder="Enter next of kin full name"
//               onChange={(e) => {
//                 setAccountOpeningPayload({
//                   ...accountOpeningPayload,
//                   nextOfKinFullName: e.target.value,
//                 });
//               }}
//             />
//             <Input
//               label="Next of kin relationship"
//               placeholder="Enter next of kin relationship"
//               onChange={(e) => {
//                 setAccountOpeningPayload({
//                   ...accountOpeningPayload,
//                   nextOfKinRelationShip: e.target.value,
//                 });
//               }}
//             />
//             <Input
//               label="Next of kin phone number"
//               placeholder="Enter next of kin phone number"
//               onChange={(e) => {
//                 setAccountOpeningPayload({
//                   ...accountOpeningPayload,
//                   nextOfKinPhone: e.target.value,
//                 });
//               }}
//             />
//             <Input
//               label="Next of kin address"
//               placeholder="Enter next of kin address"
//               onChange={(e) => {
//                 setAccountOpeningPayload({
//                   ...accountOpeningPayload,
//                   nextOfKinAddress: e.target.value,
//                 });
//               }}
//             />
//             <div className="row-arranger">
//               <Uploader
//                 onChange={(e) => {
//                   uploadDocument(e.target.files, 'signature');
//                 }}
//                 label="Signature"
//               />
//               <Uploader
//                 onChange={(e) => {
//                   uploadDocument(e.target.files, 'passport photograph');
//                 }}
//                 label="Passport photograph"
//               />
//             </div>
//           </div>
//           <div className="button-holder">
//             <Button
//               onClick={() => openAccount()}
//               title="Submit"
//               id="submit-button"
//               disabled={
//                 accountOpeningPayload.city_Town == ''
//                 || accountOpeningPayload.addressState == ''
//                 || accountOpeningPayload.nextOfKinFullName == ''
//                 || accountOpeningPayload.nextOfKinRelationShip == ''
//                 || accountOpeningPayload.nextOfKinPhone == ''
//                 || accountOpeningPayload.nextOfKinAddress == ''
//                 || accountOpeningPayload.passportPhoto == ''
//                 || accountOpeningPayload.signature == ''
//                 || accountOpeningPayload.taxId == ''
//               }
//               style={
//                 accountOpeningPayload.city_Town == ''
//                 || accountOpeningPayload.addressState == ''
//                 || accountOpeningPayload.nextOfKinFullName == ''
//                 || accountOpeningPayload.nextOfKinRelationShip == ''
//                 || accountOpeningPayload.nextOfKinPhone == ''
//                 || accountOpeningPayload.nextOfKinAddress == ''
//                 || accountOpeningPayload.passportPhoto == ''
//                 || accountOpeningPayload.signature == ''
//                 || accountOpeningPayload.taxId == ''
//                   ? { opacity: '0.4' }
//                   : {}
//               }
//             />
//           </div>
//         </div>
//       </Layout>

//       {loader || isLoading ? <Spinner /> : ''}

//       {openModal ? (
//         <Modal id="action-modal" iconId="close-icon">
//           <div id="action-modal-top">
//             <div className="icon-holder">
//               <div id="icon-holder-circle">
//                 <AiOutlineCheck size={60} color="#00989C" />
//               </div>
//             </div>
//           </div>
//           <p className="Action-modal-text">
//             Dear customer,
//             <br />
//             your account has been opened successfully.
//             <br />
//             <br />
//             Account number:
//             {' '}
//             <span style={{ fontWeight: 'bolder' }}>{accountNumber}</span>
//             <br />
//             Currency:
//             {' '}
//             <span style={{ fontWeight: 'bolder' }}>{currency}</span>
//           </p>
//           <div className="Modal-button-holder">
//             <Button
//               onClick={() => {
//                 updateBusiness();
//               }}
//               title="Save account to profile"
//               id="open-input-button"
//             />
//           </div>
//         </Modal>
//       ) : (
//         ''
//       )}
//     </Styled>
//   );
// }

// const Styled = styled.div`
//   .Wrapper {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     flex-wrap: wrap;
//     padding: 0 2rem 10rem 2rem;
//     margin-top: 10rem;

//     @media (max-width: 80rem) {
//       flex-direction: column;
//     }
//   }

//   #tabs {
//     position: fixed;
//     top: 6.8rem;
//     bottom: -5rem;
//     left: 1rem;
//     min-height: 100vh;
//   }

//   #header {
//     position: fixed;
//     top: 0;
//     right: 0;
//     left: 0;
//     z-index: 2;
//   }

//   #heading {
//     color: #004c4e;
//     font-weight: 500;
//     font-size: 20px;
//     line-height: 24px;
//   }

//   #forms-holder {
//     width: 80rem;
//     margin-top: 2rem;
//     background: #ffffff;
//     border: 6px solid #d1e8e2;
//     border-radius: 15px;
//     padding: 3rem 3rem 5rem 3rem;
//   }

//   #form-heading {
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 20px;
//     color: #004c4e;
//     margin-bottom: 3rem;
//   }

//   #submit-button {
//     padding: 1rem 3rem;
//     border: none;
//     background: linear-gradient(90deg, #00989c, #004c4e);
//     border-radius: 6px;
//     color: #d9b08c;
//     cursor: pointer;
//     float: right;
//   }

//   .row-arranger {
//     display: flex;
//     width: 100%;
//     align-items: center;
//     justify-content: space-between;
//     margin-top: 2rem;
//   }

//   .button-holder {
//     width: 80rem;
//     margin-top: 2rem;
//   }

//   #origin-select {
//     margin-top: unset;
//   }

//   .Action-modal-text {
//     color: #505050;
//     text-align: center;
//     margin: 18rem 2rem 3rem 2rem;
//   }

//   #prompt-modal {
//     padding: 3rem 2rem;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }

//   #action-modal {
//     background-color: white;
//     height: 40rem;
//   }

//   #action-modal-top {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     width: 100%;
//     height: 15rem;
//     background: linear-gradient(90deg, #00989c, #004c4e);
//     border-top-right-radius: 10px;
//     border-top-left-radius: 10px;
//   }

//   .icon-holder {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100%;
//   }

//   #close-icon {
//     z-index: 100;
//     display: none;
//   }

//   #input-close-icon {
//     color: #c1c1c1;
//   }

//   #action-modal-top-icon {
//     font-size: 10rem;
//     color: #d9b08c;
//   }

//   #icon-holder-circle {
//     height: 10rem;
//     width: 10rem;
//     border: 3px solid #00989c;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   #input-modal {
//     background-color: white;
//     width: 25%;
//   }

//   .Input-modal-button-holder {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//   }

//   #open-account-button {
//     padding: 1rem;
//     border: 1px solid #5fb19c;
//     background-color: #eee;
//     border-radius: 6px;
//     color: #004c4e;
//     cursor: pointer;
//   }

//   #open-input-button {
//     padding: 1rem;
//     border: 1px solid #5fb19c;
//     background: linear-gradient(90deg, #00989c, #004c4e);
//     border-radius: 6px;
//     color: #d9b08c;
//     cursor: pointer;
//   }

//   .Modal-button-holder {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: space-evenly;
//   }

//   .Summary-validation-title {
//     background: #d1e8e2;
//     color: #004c4e;
//     padding: 0.8rem;
//     border-top-left-radius: 10px;
//     border-top-right-radius: 10px;
//     p {
//       font-size: 1.5rem;
//       font-weight: 500;
//     }
//   }

//   .Chart-holder {
//     width: 80%;
//     padding: 0.5rem;
//     background-color: #eafbf7;
//     border: 1px solid #5fb19c;
//     border-radius: 5px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }

//   .Chart-holder-main {
//     background-color: white;
//     padding: 1rem 2rem;
//     border-bottom-left-radius: 10px;
//     border-bottom-right-radius: 10px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }

//   #correct-text {
//     color: #004c4e;
//     margin-top: 0.5rem;
//   }

//   #question-buttons-holder {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//   }

//   #no-button {
//     border: none;
//     margin-right: 2rem;
//     background-color: transparent;
//     color: #004c4e;
//     cursor: pointer;
//   }

//   #yes-button {
//     padding: 1rem 3rem;
//     border: none;
//     background-color: #004c4e;
//     border-radius: 6px;
//     color: #d9b08c;
//     cursor: pointer;
//   }
// `;

// export default AccountOpening;

