import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import classes from './input.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';

export default function Input(props) {
  const [pwdToggle, setPwdToggle] = useState(true);

  let render;

  switch (props.type) {
    case 'checkbox':
      render = (
        <div className={classes.checkbox}>
          <input
            required={props.required}
            disabled={props.disabled}
            style={props.style}
            name={props.name}
            type="checkbox"
            onChange={props?.formik?.handleChange}
            value={props?.formik?.values[props.name]}
          />
        </div>
      );
      break;
    case 'password':
      render = (
        <div className={classes.password}>
          <input
            required={props.required}
            disabled={props.disabled}
            style={props.style}
            placeholder={props.placeholder || ''}
            name={props.name}
            type={pwdToggle ? 'password' : 'text'}
            onChange={props?.formik?.handleChange}
            value={props?.formik?.values[props.name]}
          />
          {pwdToggle ? (
            <p
              className={classes.password_icon}
              onClick={() => setPwdToggle((prev) => !prev)}
            >
              Show
            </p>
          ) : (
            <p
              className={classes.password_icon}
              onClick={() => setPwdToggle((prev) => !prev)}
            >
              Hide
            </p>
          )}
        </div>
      );
      break;
    case 'option':
      render = (
        <div className={classes.form_control}>
          <select
            disabled={props.disabled}
            style={props.style}
            name={props.name}
            onChange={(e) => {
              props?.getSubSector && props?.getSubSector(e.target.value);
              props?.formik.handleChange(e);
            }}
            value={props?.formik.values[props.name]}
          >
            <option value="" disabled>
              Select
            </option>
            {props?.options?.map((elem, i) => (
              <option key={i} value={elem?.Name || elem?.value || elem}>
                {elem?.Name || elem?.text || elem}
              </option>
            ))}
          </select>
        </div>
      );
      break;
    case 'intPhone':
      render = (
        <PhoneInput
          containerClass={classes.phoneInputContainer}
          inputClass={classes.phoneInput}
          buttonStyle={{
            border: 'none',
          }}
          country="ng"
          required={props.required}
          disabled={props.disabled}
          style={props.style}
          placeholder={props.placeholder || ''}
          name={props.name}
          type={props.type}
          onChange={(e) => props?.formik?.setFieldValue(props.name, `+${e}`)}
          value={props?.formik?.values[props.name]}
        />
      );
      break;
    case 'currencyDropDownAndNumeric':
      render = (
        <div className={classes.currencyDropDownAndNumeric}>
          <select
            style={props.style}
            name={props.name}
            onChange={props?.formik.handleChange}
            value={props?.formik.values[props.name]}
          >
            {props?.options?.map((elem, i) => (
              <option key={i} value={elem?.Name || elem?.value || elem}>
                {elem?.Name || elem?.text || elem}
              </option>
            ))}
          </select>
          <NumberFormat
            thousandSeparator
            name={props.name2}
            value={props?.formik.values[props.name2]}
            onChange={props?.formik?.handleChange}
          />
        </div>
      );
      break;
    case 'currencyDropDownAndDropDown':
      render = (
        <div className={classes.currencyDropDownAndDropDown}>
          <select
            style={props.style}
            name={props.name}
            onChange={props?.formik.handleChange}
            value={props?.formik.values[props.name]}
          >
            {props?.options?.map((elem, i) => (
              <option key={i} value={elem?.Name || elem?.value || elem}>
                {elem?.Name || elem?.text || elem}
              </option>
            ))}
          </select>
          <select
            style={props.style}
            name={props.name2}
            onChange={props?.formik.handleChange}
            value={props?.formik.values[props.name2]}
          >
            <option value="" selected disabled>
              {props.placeholder2}
            </option>
            {props?.options2?.map((elem, i) => (
              <option key={i} value={elem?.Name || elem?.value || elem}>
                {elem?.Name || elem?.text || elem}
              </option>
            ))}
          </select>
        </div>
      );
      break;
    case 'threeDropDown':
      render = (
        <div className={classes.threeDropDown}>
          <select
            style={props.style}
            name={props.name3}
            onChange={props?.formik.handleChange}
            value={props?.formik.values[props.name3]}
          >
            <option value="" disabled>
              {props.placeholder3}
            </option>
            {props?.options3?.map((elem, i) => (
              <option key={i} value={elem?.Name || elem?.value || elem}>
                {elem?.Name || elem?.text || elem}
              </option>
            ))}
          </select>
          <select
            style={props.style}
            name={props.name2}
            onChange={props?.formik.handleChange}
            value={props?.formik.values[props.name2]}
          >
            <option value="" disabled>
              {props.placeholder2}
            </option>
            {props?.options2?.map((elem, i) => (
              <option key={i} value={elem?.Name || elem?.value || elem}>
                {elem?.Name || elem?.text || elem}
              </option>
            ))}
          </select>
          <select
            style={props.style}
            name={props.name}
            onChange={props?.formik.handleChange}
            value={props?.formik.values[props.name]}
          >
            <option value="" disabled>
              {props.placeholder}
            </option>
            {props?.options?.map((elem, i) => (
              <option key={i} value={elem?.Name || elem?.value || elem}>
                {elem?.Name || elem?.text || elem}
              </option>
            ))}
          </select>
        </div>
      );
      break;
    case 'textNumber':
      render = (
        <div className={classes.form_control}>
          <input
            autoComplete="off"
            required={props.required}
            disabled={props.disabled}
            style={props.style}
            placeholder={props.placeholder || ''}
            name={props.name}
            type={props.type}
            onChange={(e) => {
              const { value } = e.target;
              const regex = new RegExp(/^[0-9]+$/);
              if (regex.test(value) && value.length <= props.maxLength) {
                return props?.formik?.handleChange(e);
              } if (value.length == 0) {
                return props?.formik?.handleChange(e);
              }
            }}
            value={props?.formik?.values[props.name]}
          />
        </div>
      );
      break;
    case 'date':
      render = (
        <div className={classes.form_control_input}>
          <DatePicker
            selected={
              props?.formik?.values[props.name]
              && new Date(props?.formik?.values[props.name])
            }
            onChange={(date) => props.formik.setFieldValue(props.name, date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat="dd/MM/yyyy"
            style={props.style}
            placeholderText={props.placeholder || 'dd/mm/yyyy'}
            disabled={props.disabled}
            maxDate={props.maxDate}
            autoComplete="off"
          />
        </div>
      );
      break;
    default:
      render = (
        <div className={classes.form_control}>
          <input
            autoComplete="off"
            required={props.required}
            disabled={props.disabled}
            style={props.style}
            className={props.className}
            placeholder={props.placeholder || ''}
            name={props.name}
            type={props.type}
            onChange={props?.formik?.handleChange}
            value={props?.formik?.values[props.name]}
          />
        </div>
      );
      break;
  }

  return (
    <div className={classes.wrapper}>
      {props?.label ? (
        <>
          <label htmlFor="" className={props.labelStyle}>
            {props.label}
          </label>

          {props?.asterisk ? null : <span>*</span>}

          <label htmlFor="" className={classes.label2}>
            {props.label2}
          </label>
        </>
      ) : null}

      {render}

      {props?.formik?.touched[props.name] ? (
        <small>
          {props?.formik?.errors[props.name]
            && props?.formik?.errors[props.name]}
        </small>
      ) : null}

      {props?.formik?.touched[props.name2] ? (
        <small>
          {props?.formik?.errors[props.name2]
            && props?.formik?.errors[props.name2]}
        </small>
      ) : null}
    </div>
  );
}
