import * as NotifyActions from 'store/actions/notify';
import { store } from 'store/store';

export default function useNotify() {
  const setNotify = ({
    message, type, func, funcTwo, btnText, btnTextTwo
  }) => {
    store.dispatch(
      NotifyActions.ShowNotify({
        show: true,
        type,
        message,
        func,
        funcTwo,
        btnText,
        btnTextTwo
      })
    );
  };

  return [setNotify];
}
