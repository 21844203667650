import styled from 'styled-components';
import { FeedBackMailRedNotifySvg } from 'theme/icons';
import Button from 'components/Buttons';
import { useNavigate } from 'react-router';

const offerLetterNotifier = () => {
  const navigate = useNavigate();

  return (
    <Styled>
      <div className="validation-container">
        <div className="validation-title">
          <p>Offer Letter</p>
        </div>

        <div className="validation-body">
          <div className="validation-item">
            <figure>
              <FeedBackMailRedNotifySvg />
            </figure>
            <p>
              Dear Customer, your <span style={{ fontWeight: '500', color: '#D45F36' }}>offer letter</span> is ready.
              Kindly treat to complete the process. <br />
              Acceptance of the Bank’s offer shall be required within <span style={{ fontWeight: '700' }}>30 days</span> of a facility offer date <br />
              and conditions met within 90 days. Credit offer shall be deemed to have lapsed <br /> if acceptance
              is not received within the above time limit
            </p>
          </div>
          <div className="validation-button">
            <Button
              onClick={() => {
                navigate('/app/frr/offer-letter')
              }}
              mode="1"
              title="Treat Now"
              id="treat-button"
              style={{ padding: '.5rem 1rem' }}
            />
          </div>
        </div>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  
  .validation-container {
    margin-bottom: 4rem;
    background: #fff;
    padding: .5rem;
    border-radius: 8px;
    width: 100%;
  }

  .validation-body {
    padding: 1rem;
  }

  .validation-item {
    display: flex;
    p {
      font-size: 1.4rem;
      margin-left: 1rem;
      color: black;
    }
  }

  .validation-button {
    display: flex;
    justify-content: flex-end;
  }

  .validation-empty {
    text-align: center;
    padding: 0.5rem;
    font-size: 1.4rem;
  }

  .validation-loader {
    padding: 0.5rem;
    text-align: center;
  }

  .Validation-top-holder {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1rem;
  }

  .validation-title {
    background: #d1e8e2;
    color: #004c4e;
    padding: 0.8rem;
    padding-left: 2rem;
    border-radius: 8px;
    p {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  #treat-button{
    color: white;
    font-weight: 400;
  }

`;

export default offerLetterNotifier;
