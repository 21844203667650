import { verificationApis } from 'apis';
import * as actionTypes from '../action-types/auth';
import * as actionTypesBusiness from '../action-types/business';
import * as actionTypesORRScore from '../action-types/orrScore';
import * as actionTypesCollateral from '../action-types/collateral';

export const AuthSaveUser = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_USER_AUTH, payLoad: data });
};
export const AuthResetUser = () => async (dispatch, getState) => {
  const { auth } = getState();
  dispatch({ type: actionTypes.RESET_USER_AUTH });
  dispatch({ type: actionTypesBusiness.CLEAR_BUSINESS_PROFILES });
  dispatch({ type: actionTypesORRScore.CLEAR_ORR_SCORE });
  dispatch({ type: actionTypesCollateral.CLEAR_COLLATERAL });

  await verificationApis.userLogout(auth.user?.addedBy);
  sessionStorage.clear();
  localStorage.clear();
};
