import React, { useState } from 'react';
import * as yup from 'yup';
import CustomInput from 'components/FormikInputs/Input';
import Button, { PrevButton } from 'components/Buttons';
import { useFormik } from 'formik';
import { authApis } from 'apis';
import { useSelector, useDispatch } from 'react-redux';
import * as SignUpActions from 'store/actions/signUpForm';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ErrorHandler from 'helpers/errorHandler';
import { genderBinary, maritalStatusBinary } from 'constants/signUp';
import { parseDate } from 'helpers/utils';
import SuccessModal from './AccCreateModal';
import TermsAndPolicyModal from './TermsAndPolicyModal';

const convertTenorsToDay = (values) => {
  const yr = values?.facilityTenor2 * 365;
  const months = values?.facilityTenor3 * 30;
  const days = Number(values?.facilityTenor) + Number(yr) + Number(months);
  return `${days}`;
};
export default function NewCustomer() {
  const { signUpForm } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const [termsToggle, setTermsToggle] = useState({
    current: null,
    status: false,
  });
  function termsToggleHandler(current) {
    if (termsToggle) {
      document.querySelector('html').classList.toggle('modal-open');
      setTermsToggle((prev) => ({ status: !prev.status, current }));
    } else {
      document.querySelector('html').classList.toggle('modal-open');
      setTermsToggle((prev) => ({ status: !prev.status, current: null }));
    }
  }
  const validationSchema = yup.object({
    username: yup.string().required('UserName Required'),
    password: yup
      .string()
      .trim()
      .required('Password Required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        'Min 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Characters(@$!%*#?&)'
      ),
    confirmPwd: yup
      .string()
      .trim()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    terms: yup.bool().oneOf([true], '.'),
  });
  const getInitialValues = {
    username: signUpForm.aboutYou.customerEmail,
    password: '',
    confirmPwd: '',
    terms: '',
  };
  const formik = useFormik({
    initialValues: getInitialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const businessPayload = {
          firstName: signUpForm.bvnDetails.firstName,
          lastName: signUpForm.bvnDetails.lastName,
          otherName: signUpForm.bvnDetails.middleName,
          bvn: signUpForm.bvnDetails.bvn,
          password: values.password,
          gender: genderBinary[signUpForm.bvnDetails.gender],
          marital: maritalStatusBinary[signUpForm.bvnDetails.maritalStatus],
          dateOfBirth: parseDate(String(signUpForm.bvnDetails.dateOfBirth)),
          emailAddress: signUpForm.aboutYou.customerEmail,
          phoneNumber: signUpForm.bvnDetails.phoneNumber,
          businessPosition: signUpForm.aboutYou.businessPosition,
          businessName: signUpForm.finalPage.businessName,
          businessType: signUpForm.business.businessType,
          rcNumber: signUpForm.finalPage.rcNumber,
          companyTin: signUpForm.finalPage.companyTin,
          customerPhoneNumber: signUpForm.aboutYou.customerPhoneNumber,
          customerEmail: signUpForm.aboutYou.customerEmail,
          businessTurnover: signUpForm.business.businessTurnover,
          loanPurpose: signUpForm.loan.loanPurpose,
          loanCurrency: signUpForm.loan.loanCurrency,
          facilityAmount: signUpForm.loan.facilityAmount,
          facilityTenor: convertTenorsToDay(signUpForm.loan),
          alternativeCompanyName: signUpForm.finalPage.alternativeCompanyName,
          companyEmail: signUpForm.finalPage.companyEmail,
          companyPhoneNumber: signUpForm.finalPage.companyPhoneNumber,
          companyPrimaryAddress: signUpForm.finalPage.companyPrimaryAddress,
          subSectorId: Number(signUpForm.finalPage.companySubSector),
          customerId: '',
          companyAccountNumber: '',
          dateIncorporated: `${
            new Date(signUpForm.business.dateIncorporated)
              .toISOString()
              .split('T')[0]
          }`,
          isNewCustomer: true,
          isTinVerified: signUpForm?.tinDetails.isTinVerified,
        };
        const res = await authApis.createUserBusiness(businessPayload);
        if(res?.data?.status){
          openModal();
        }else{
          toast.error('An error occurred while creating business. Please try again');
        }
      } catch (error) {
        ErrorHandler(error.response);
      } finally {
        setLoader(false);
      }
    },
  });

  return (
    <Styled>
      <h2 className="formTitle">Create Login Details</h2>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          name="username"
          label="User Name"
          type="text"
          required
          disabled
          formik={formik}
        />
        <CustomInput
          name="password"
          label="Password"
          label2="(Min 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Case Character. This will be required when you want to login)"
          type="password"
          required
          formik={formik}
        />
        <CustomInput
          name="confirmPwd"
          label="Confirm Password"
          type="password"
          required
          formik={formik}
        />

        <div className="termsPolicy">
          <CustomInput
            name="terms"
            text=""
            type="checkbox"
            required
            formik={formik}
          />
          <p>
            Check here to indicate that you have read and agreed to the <span onClick={() => termsToggleHandler('terms')}>Terms of service</span> and <span onClick={() => termsToggleHandler('policy')}>Privacy policy</span>
          </p>
        </div>
        <div className="navButton">
          <PrevButton
            type="button"
            mode="3"
            title="Prev"
            onClick={() => dispatch(SignUpActions.SaveActivePage('newExitingCreateUser'))}
          />
          <Button loader={loader} type="submit" mode="1" title="Submit" />
        </div>
      </form>
      <SuccessModal
        email={signUpForm.aboutYou.customerEmail}
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
      />
      <TermsAndPolicyModal
        openModal={termsToggleHandler}
        closeModal={termsToggleHandler}
        modalIsOpen={termsToggle.status}
        current={termsToggle.current}
      />
    </Styled>
  );
}
const Styled = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 5rem;
  padding-top: 15%;
  .navButton {
    display: flex;
    justify-content: space-between;
  }
  .formTitle {
    color: #004c4e;
    margin-bottom: 2rem;
    font-weight: 500;
  }
  .termsPolicy {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    color: #505050;
    & > div {
      margin: 0;
    }
    span {
      color: blue;
      cursor: pointer;
    }
  }
`;
