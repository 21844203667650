import { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Header from 'components/Headers/General';
import { NextButton, PrevButton } from 'components/Buttons';
import { useSelector } from 'react-redux';
import frrApis from 'apis/frr';
import errorHandler from 'helpers/errorHandler';
import Spinner from 'components/OverLays/OverLaySpinner';
import { useNavigate } from 'react-router-dom';

function FacilityQuestionsReview() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('general');
  const [frrResponseLoader, setFrrResponseLoader] = useState(false);
  const [frrQuestions, setFrrQuestions] = useState([]);
  const [frrQuestionsLoader, setFrrQuestionsLoader] = useState(false);
  const [answeredFrrResponses, setAnsweredFrrResponses] = useState([]);
  const [sortedFrrResponses, setSortedFrrResponses] = useState([]);
  const [formikData, setFormikData] = useState({});
  const [formikLoader, setFormikLoader] = useState(false);
  const [currenciesLoader, setCurrenciesLoader] = useState(false);
  const [currency, setCurrency] = useState('');
  const [subPurpose, setSubPurpose] = useState('');
  const [subPurposeLoader, setSubPurposeLoader] = useState(false);
  const [facilityType, setFacilityType] = useState('');
  const [facilityTypeLoader, setFacilityTypeLoader] = useState(false);
  const [subPurposeAnswers, setSubPurposeAnswers] = useState([]);

  const facilityId = useSelector(
    (state) => state?.facilityAnsweredQuestions?.facilityId
  );

  useLayoutEffect(() => {
    const getCurrencies = async () => {
      setCurrenciesLoader(true);

      try {
        const response = await frrApis.getCurrencyCodes();

        // This is for setting currency code.
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].currencyId === formikData.currencyId) {
            setCurrency(response.data.data[i].currencyCode);
          }
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setCurrenciesLoader(false);
      }
    };

    if (currency === '') {
      getCurrencies();
    }
  }, [Object.keys(formikData).length]);

  useLayoutEffect(() => {
    const getFacilitySubPurpose = async () => {
      setSubPurposeLoader(true);

      try {
        const response = await frrApis.getFacilitySubPurpose();

        // This is for setting sub purpose.
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].subPurposeId === formikData.subPurposeId) {
            setSubPurpose(response.data.data[i].subPurposeName);
          }
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setSubPurposeLoader(false);
      }
    };

    if (subPurpose === '') {
      getFacilitySubPurpose();
    }
  }, [Object.keys(formikData).length]);

  useLayoutEffect(() => {
    const getFacilityTypes = async () => {
      setFacilityTypeLoader(true);

      try {
        const response = await frrApis.getFacilityTypes();

        // This is for setting facility type.
        for (let i = 0; i < response.data.data.length; i++) {
          if (
            response.data.data[i].facilityTypeId === formikData.facilityTypeId
          ) {
            setFacilityType(response.data.data[i].facilityTypeName);
          }
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setFacilityTypeLoader(false);
      }
    };

    if (facilityType === '') {
      getFacilityTypes();
    }
  }, [Object.keys(formikData).length]);

  useLayoutEffect(() => {
    const getFrrResponses = async () => {
      setFrrResponseLoader(true);
      try {
        const response = await frrApis.getFrrResponse(formikData.facilityId);
        setAnsweredFrrResponses(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setFrrResponseLoader(false);
      }
    };

    if (Object.keys(formikData).length > 0) {
      getFrrResponses();
    }
  }, [formikData?.facilityId]);

  // This will load frr questions based on the sub purpose from the continue data.
  useLayoutEffect(() => {
    const getFRRQuestions = async () => {
      setFrrQuestionsLoader(true);
      try {
        const response = await frrApis.getFacilitySubPurposeQuestions(
          formikData?.subPurposeId
        );
        setFrrQuestions(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setFrrQuestionsLoader(false);
      }
    };

    if (Object.keys(formikData).length > 0) {
      getFRRQuestions();
    }
  }, [Object.keys(formikData).length > 0]);

  useLayoutEffect(() => {
    const newQuestions = [];

    if (answeredFrrResponses?.length > 0 && frrQuestions?.length > 0) {
      frrQuestions.map((question) => {
        for (const response of answeredFrrResponses) {
          if (
            question.frrQuestionId === response.frrQuestionId
            && (response.frrAnswerId !== null || response.unWeightedAnswer !== '')
          ) {
            const newData = {
              frrAnswerId: response.frrAnswerId,
              unWeightedAnswer: response.unWeightedAnswer,
              documentName: question.documentName,
              facilitySubPurposeTbl: question.facilitySubPurposeTbl,
              followUpTrigger: question.followUpTrigger,
              followupId: question.followupId,
              frrQuestion: question.frrQuestion,
              frrQuestionId: question.frrQuestionId,
              identifier: question.identifier,
              isDeleted: question.isDeleted,
              isDisabled: question.isDisabled,
              isRequired: question.isRequired,
              isWeighted: question.isWeighted,
              questionClass: question.questionClass,
              questionType: question.questionType,
              score: question.score,
              sequenceId: question.sequenceId,
              subPurposeId: question.subPurposeId,
            };
            newQuestions.push(newData);
          }
        }
      });
    }

    if (newQuestions.length > 0) {
      frrQuestions.map((question, index) => {
        for (const newQuestion of newQuestions) {
          if (question.frrQuestionId === newQuestion.frrQuestionId) {
            frrQuestions.splice(index, 1, newQuestion);
          }
        }
      });
    }

    for (let i = 0; i < newQuestions.length; i++) {
      if (
        newQuestions[i]?.frrQuestionId === newQuestions[i + 1]?.frrQuestionId
      ) {
        newQuestions.splice(i + 1, 1);
      }
    }

    setSortedFrrResponses(newQuestions);
  }, [answeredFrrResponses.length, frrQuestions.length]);

  useLayoutEffect(() => {
    const getFacilityDetail = async () => {
      setFormikLoader(true);
      try {
        const response = await frrApis.getFacilityDetailsByFacilityId(
          facilityId.facilityId
        );
        setFormikData(response.data.data.f);
      } catch (error) {
        errorHandler(error);
      } finally {
        setFormikLoader(false);
      }
    };

    if (facilityId.faclityId !== 0) {
      getFacilityDetail();
    }
  }, [facilityId]);

  useLayoutEffect(() => {
    const getSubPurposeAnswers = async () => {
      try {
        const response = await frrApis.getSubPurposeAnswersBySubPurposeId(
          formikData.subPurposeId
        );
        setSubPurposeAnswers(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
      }
    };

    if (Object.keys(formikData).length > 0) {
      getSubPurposeAnswers();
    }
  }, [Object.keys(formikData).length]);

  return (
    <Styled>
      <Header />

      <div className="Review-main">
        <div className="Review-board">
          <p className="Review-header">Review your answers</p>

          <div id="tab-buttons-holder">
            <div>
              <button
                onClick={() => setActiveTab('general')}
                className={
                  activeTab === 'general'
                    ? 'Review-tabs-button-active'
                    : 'Review-tabs-button'
                }
              >
                General
              </button>
            </div>

            <div>
              <button
                onClick={() => setActiveTab('purpose')}
                className={
                  activeTab === 'purpose'
                    ? 'Review-tabs-button-active'
                    : 'Review-tabs-button'
                }
              >
                Purpose
              </button>
            </div>
          </div>

          <div className="Reviews-holder">
            {activeTab === 'general' ? (
              <div>
                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">Justification for the facility.</p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">{formikData.justification}</p>
                  </div>
                </div>

                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">
                      Which of these best describes this facility in the order
                      of seniority?
                    </p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">{formikData.facilitySeniority}</p>
                  </div>
                </div>

                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">
                      What is the primary source of repayment for the facility?
                    </p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">
                      {formikData.primarySourceOfRepayment}
                    </p>
                  </div>
                </div>

                {formikData?.primarySourceOfRepayment
                  ?.toLowerCase()
                  .includes('others') ? (
                    <div className="Review-item">
                      <div className="Review-question-holder">
                        <div>
                          <div className="Question-icon" />
                          <p className="Question-title">Question</p>
                        </div>
                        <p className="Question">
                          Provide details of a primary source of payment.
                        </p>
                      </div>
                      <div className="Review-answer-holder">
                        <div>
                          <div className="Answer-icon" />
                          <p className="Answer-title">Answer</p>
                        </div>
                        <p className="Answer">
                          {formikData.otherPrimarySourceOfRepayment}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">
                      Is there an alternative source of repayment?
                    </p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">
                      {formikData.isAlternativeSourceOfRepayment === true
                        ? 'True'
                        : formikData.isAlternativeSourceOfRepayment === false
                          ? 'False'
                          : ''}
                    </p>
                  </div>
                </div>

                {formikData?.isAlternativeSourceOfRepayment === true ? (
                  <div className="Review-item">
                    <div className="Review-question-holder">
                      <div>
                        <div className="Question-icon" />
                        <p className="Question-title">Question</p>
                      </div>
                      <p className="Question">
                        Provide details of the alternative source of payment.
                      </p>
                    </div>
                    <div className="Review-answer-holder">
                      <div>
                        <div className="Answer-icon" />
                        <p className="Answer-title">Answer</p>
                      </div>
                      <p className="Answer">
                        {formikData.alternativeSourceOfRepayment}
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">
                      Which of these best describes the structure of the
                      facility request?
                    </p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">
                      {formikData.facilityRequestStructure}
                    </p>
                  </div>
                </div>

                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">
                      What is the preferred disbursement mode?
                    </p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">{formikData.disbursementMode}</p>
                  </div>
                </div>

                {formikData?.disbursementMode
                  ?.toLowerCase()
                  .includes('tranches') ? (
                    <div className="Review-item">
                      <div className="Review-question-holder">
                        <div>
                          <div className="Question-icon" />
                          <p className="Question-title">Question</p>
                        </div>
                        <p className="Question">
                          Kindly indicate details of tranches.
                        </p>
                      </div>
                      <div className="Review-answer-holder">
                        <div>
                          <div className="Answer-icon" />
                          <p className="Answer-title">Answer</p>
                        </div>
                        <p className="Answer">
                          {formikData.disbursementModeTranches}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">
                      Kindly indicate if the cash flow for loan repayment is
                      expected from a single client or multiple clients.
                    </p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">{formikData.repaymentCashFlow}</p>
                  </div>
                </div>

                {/* <div className = 'Review-item'>
                                        <div className = 'Review-question-holder'>
                                            <div>
                                                <div className = 'Question-icon'></div>
                                                <p className = 'Question-title'>Question</p>
                                            </div>
                                            <p className = 'Question'>Select multiple counterparty option.</p>
                                        </div>
                                        <div className = 'Review-answer-holder'>
                                            <div>
                                                <div className = 'Answer-icon'></div>
                                                <p className = 'Answer-title'>Answer</p>
                                            </div>
                                            <p className = 'Answer'>{ item.response }</p>
                                        </div>
                                    </div> */}

                {formikData?.repaymentCashFlow
                  ?.toLowerCase()
                  .includes('multiple') ? (
                    <div className="Review-item">
                      <div className="Review-question-holder">
                        <div>
                          <div className="Question-icon" />
                          <p className="Question-title">Question</p>
                        </div>
                        <p className="Question">List names of counterparties.</p>
                      </div>
                      <div className="Review-answer-holder">
                        <div>
                          <div className="Answer-icon" />
                          <p className="Answer-title">Answer</p>
                        </div>
                        <p className="Answer">{formikData.counterPartyName}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="Review-item">
                      <div className="Review-question-holder">
                        <div>
                          <div className="Question-icon" />
                          <p className="Question-title">Question</p>
                        </div>
                        <p className="Question">
                          Indicate the name of the counterparty.
                        </p>
                      </div>
                      <div className="Review-answer-holder">
                        <div>
                          <div className="Answer-icon" />
                          <p className="Answer-title">Answer</p>
                        </div>
                        <p className="Answer">{formikData.counterPartyName}</p>
                      </div>
                    </div>
                  )}
              </div>
            ) : (
              <div>
                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">How much do you need?</p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">
                      {`${currency} ${formikData.facilityAmount}`}
                    </p>
                  </div>
                </div>

                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">
                      How long do you need this plan for?
                    </p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">
                      {`${formikData.facilityDuration} ` + 'days.'}
                    </p>
                  </div>
                </div>

                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">Why do you need the facility?</p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">{subPurpose}</p>
                  </div>
                </div>

                <div className="Review-item">
                  <div className="Review-question-holder">
                    <div>
                      <div className="Question-icon" />
                      <p className="Question-title">Question</p>
                    </div>
                    <p className="Question">Kindly select facility type.</p>
                  </div>
                  <div className="Review-answer-holder">
                    <div>
                      <div className="Answer-icon" />
                      <p className="Answer-title">Answer</p>
                    </div>
                    <p className="Answer">{facilityType}</p>
                  </div>
                </div>

                {/*   This is for rendering sub purpose questions for review   */}
                {sortedFrrResponses?.length > 0
                  && sortedFrrResponses.map((item) => (
                    <div key={item.frrQuestionId} className="Review-item">
                      <div className="Review-question-holder">
                        <div>
                          <div className="Question-icon" />
                          <p className="Question-title">Question</p>
                        </div>
                        <p className="Question">{item.frrQuestion}</p>
                      </div>
                      <div className="Review-answer-holder">
                        <div>
                          <div className="Answer-icon" />
                          <p className="Answer-title">Answer</p>
                        </div>
                        <p className="Answer">{item.unWeightedAnswer}</p>

                        {subPurposeAnswers.length > 0
                            && subPurposeAnswers.map((answer) => {
                              if (answer.task.length > 0) {
                                for (const taskItem of answer.task) {
                                  if (
                                    item.frrAnswerId == taskItem.frrAnswerId
                                  ) {
                                    return (
                                      <p className="Answer">
                                        {taskItem.frrAnswer}
                                      </p>
                                    );
                                  }
                                }
                              }
                            })}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {facilityId.fromDashboard === false ? (
            <div className="Buttons-holder">
              <PrevButton
                onClick={() => {
                  navigate('/app/frr/facilityquestions');
                }}
                id="prev-button"
              />

              <NextButton
                onClick={() => {
                  navigate('/app/frr/facilitycollateralmapping');
                }}
                id="next-button"
              />
            </div>
          ) : (
            <div className="Buttons-holder">
              <PrevButton
                onClick={() => {
                  navigate('/app/frr/facilityevaluation');
                }}
                id="prev-button"
              />
            </div>
          )}
        </div>
      </div>

      {frrResponseLoader
      || formikLoader
      || currenciesLoader
      || subPurposeLoader
      || facilityTypeLoader
      || frrQuestionsLoader ? (
          <Spinner />
        ) : (
          ''
        )}
    </Styled>
  );
}

const Styled = styled.div`
    width: 100%;
    background-color: #E5E5E5;
    padding-bottom: 5rem;

    .Review-main{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .Review-board{
        width: 50%;
        margin-top: 5rem;
        position:
    }

    .Review-header{
        font-size: 2rem;
        margin-bottom: 2rem;
        font-weight: 500;
        color: #004C4E;
    }

    .Buttons-holder{
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        justify-content: space-between;
    }
    
    #next-button{
        color: #D9B08C;
        font-size: 1rem;
        padding: 1rem 2rem;
        background: linear-gradient(90deg, #00989C, #004C4E);
        cursor: pointer;
    }

    #prev-button{
        color: #D9B08C;
        font-size: 1rem;
        padding: 1rem 2rem;
        background: linear-gradient(90deg, #00989C, #004C4E);
        cursor: pointer;
    }

    .Review-tabs-button{
        cursor: pointer;
        padding: .5rem 1rem;
        margin-right: 1rem;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background-color: #ddd;
        color: #808080;
        border: none;
    }
    
    .Review-tabs-button-active{
    cursor: pointer;
    padding: .5rem 1rem;
    margin-right: 1rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background: linear-gradient(90deg, #00989C, #004C4E);
    color: white;
    border: none;
    }

    #tab-buttons-holder{
        display: flex;
    }

    .Reviews-holder{
        width; 100%;
        background-color: white;
        height: 60vh;
        border: 1px solid #00989C;
        border-radius: 0px 6px 6px 6px;
        overflow-y: scroll;
        padding: 1rem;
    }

    .Question-icon{
        width: .8rem;
        height: .8rem;
        border-radius: 50%;
        background-color: #00989C;
        margin-right: 1rem;
    }

    .Answer-icon{
        width: .8rem;
        height: .8rem;
        border-radius: 50%;
        background-color: #D9B08C;
        margin-right: 1rem;
    }

    .Review-item{
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .Review-question-holder{

        div{
            display: flex;
            align-items: center;
        }
    }

    .Review-answer-holder{
        margin-top: .5rem;

        div{
            display: flex;
            align-items: center;
        }
    }

    .Question-title{
        font-size: 1.2rem;
    }

    .Answer-title{
        font-size: 1.2rem;
    }

    .Question{
        margin-left: 2rem;
        font-size: 1.1rem;
        color: grey;
    }

    .Answer{
        margin-left: 2rem;
        font-size: 1.1rem;
        color: grey;
    }
`;

export default FacilityQuestionsReview;
