import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { UserSvg, LogoSvg } from 'theme/icons';

export default function Header(props) {
  const { business } = useSelector((state) => state);

  return (
    <Styled>
      <nav className="nav">
        <div className="nav_left">
          <p onClick={props.homeHandler}>
            <LogoSvg width="10rem" height="4rem" />
          </p>
        </div>
        <section className="nav_right">
          <div className="nav_item_text">
            <UserSvg
              width="2.5rem"
              stroke="#D9B08C"
              className="svg"
              strokeWidth=".1px"
            />
            <p className="links">
              {business?.businessProfiles[0]?.businessName}
            </p>
          </div>
        </section>
      </nav>
    </Styled>
  );
}

const Styled = styled.div`
  .notification-wrapper {
    position: relative;
    margin-right: 2rem;
  }
  .notification_badge {
    background: #cc1016;
    height: 1rem;
    width: 1rem;
    border-radius: 1rem;
    position: absolute;
    top: -0.3rem;
    left: 1.4rem;
  }
  .nav {
    background: #004c4e;
    color: #d9b08c;
    padding: 0.2rem 3rem;
    display: flex;
    justify-content: space-between;
    .nav_left {
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
    }
    .nav_right {
      display: flex;
      align-items: center;
      > div {
        margin-right: 2rem;
      }
      .nav_item_text {
        margin-right: 2rem;
        display: flex;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        svg {
          margin-right: 0.5rem;
        }
        a,
        p {
          font-size: 1.4rem;
          max-width: 30rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .nav_right_btn {
      margin-right: 2rem;
      font-size: 1.4rem;
    }
    a {
      margin-right: 2rem;
    }
    .svg {
      stroke: #d9b08c;
      cursor: pointer;
    }
    a:visited,
    a:link {
      color: #d9b08c;
    }
  }
`;
