import React from 'react';
import Main from 'components/Home/Faq';
import Footer from 'components/Home/Main/Footer';
import { Helmet } from 'react-helmet-async';

export default function faq() {
  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5' }}>
        <title>FAQ | Gazelle by Sterling</title>
      </Helmet>
      <Main />
      <Footer />
    </>
  );
}
