import { forwardRef } from 'react';
import styled from 'styled-components';

const Input = forwardRef((props, ref) => (
  <Styled>
    <label>
      {props.label}
      <span style={{ color: 'red' }}>*</span>
    </label>
    <input
      onChange={props.onChange}
      ref={ref}
      type={props?.type ? props?.type : 'text'}
      placeholder={props.placeholder}
      id={props?.id}
      name={props.name}
      value={
        props.formik === '' || !props.formik
          ? props.value
          : props.formik !== ''
            ? props.formik.values[props.name]
            : ''
      }
    />
  </Styled>
));

const Styled = styled.div`
  margin-top: 2rem;

  input {
    padding: 1rem;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #5fb19c;

    :focus {
      outline: none;
    }
  }

  label {
    font-size: 1.4rem;
  }
`;

export default Input;
