import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Illustration1 from 'assets/images/calculator-illustrator-1.svg';
import { FiX } from 'react-icons/fi';
import Button from 'components/Buttons';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import raf from 'rc-util/lib/raf';
import Portal from './Portal';
import 'rc-slider/assets/index.css';
import NumberFormat from 'react-number-format';
import CurrencyFormat from 'react-currency-format';

export const handleRender = (node, props) => (
  <HandleTooltip value={props.value} visible={props.dragging}>
    {node}
  </HandleTooltip>
);
function HandleTooltip(props) {
  const {
    value, visible, children, ...restProps
  } = props;
  const tooltipRef = useRef();
  const rafRef = useRef(null);
  useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  function cancelKeepAlign() {
    raf.cancel(rafRef.current);
  }

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign();
    });
  }

  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} months`}
      visible={visible}
      placement="top"
      overlayInnerStyle={{ minHeight: 'auto' }}
      ref={tooltipRef}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
}
function Calculator(props) {
  const [tenor, setTenor] = useState(24);
  const [payment, setPayment] = useState({ month: '', total: '' });

  const handlerCalculate = (e) => {

    let initValue = e.target[0].value;
    let splitted = initValue.split(',');

    let pv = '';
    
    for(let s of splitted){
      pv = pv + s
    }
    
    e.preventDefault();
    let r = e.target[1].value;
    const n = tenor;

    r /= 100;
    r /= 12;
    const month = (r * Number(pv)) / (1 - (1 + r) ** -n);
    setPayment({ month: month.toFixed(2), total: (month * n).toFixed(2) });
  };
  return (
    <div className="calculator-wrapper">
      <div className="close">
        <FiX onClick={props.handleCalculatorToggle} size="30" />
      </div>
      <p className="calculator-title">Loan Calculator</p>
      <div className="calculator-layer-1">
        <form className="calculator-layer-1-form" onSubmit={handlerCalculate}>
          <label>
            Facility Amount
            {/* <input type="number" name="facility" required /> */}
            <NumberFormat
              thousandSeparator
              name="facility"
              required
            />
          </label>
          <label>
            Interest Rate %
            <input
              type="number"
              name="interest"
              min="1"
              max="100"
              required
              step=".01"
            />
          </label>
          <label>
            Tenor
            <div className="tenor-section">
              <Slider
                min={1}
                max={60}
                defaultValue={tenor}
                handleRender={handleRender}
                onAfterChange={(e) => setTenor(e)}
                railStyle={{
                  height: 5,
                }}
                handleStyle={{
                  height: 20,
                  width: 20,
                  marginLeft: -4,
                  marginTop: -8,
                  backgroundColor: '#fff',
                  border: '1px solid #004C4E',
                }}
                trackStyle={{
                  backgroundColor: '#00989C',
                }}
              />
              <div className="tenor-range">
                <p>0 month(s)</p>
                <p>60 months</p>
              </div>
            </div>
          </label>
          <Button
            mode="1"
            type="submit"
            title="Calculate"
            className="calculate_btn"
          />
        </form>
        <div className="calculator-layer-1-img">
          <img src={Illustration1} alt="" />
        </div>
      </div>
      <div className="calculator-layer-2">
        <div className="calculator-layer-2-text">
          <div>
            <p>Monthly Repayment</p>
          </div>
          <div>
            <p>Total Amount to Repay</p>
          </div>
        </div>
        <div className="calculator-layer-2-num">
          <div>
            <p>
              <span>₦</span>
              {' '}
              <CurrencyFormat
                value={payment.month.toLocaleString()}
                displayType="text"
                thousandSeparator
              />
              
            </p>
          </div>
          <div>
            <p>
              <span>₦</span>
              {' '}
              <CurrencyFormat
                value={payment.total.toLocaleString()}
                displayType="text"
                thousandSeparator
              />
              
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function OverLayCalculator(props) {
  return (
    <Portal>
      <Styled className="overlay">
        <div className="overlay__inner">
          <div className="overlay__content">
            <Calculator {...props} />
          </div>
        </div>
      </Styled>
    </Portal>
  );
}
const Styled = styled.div`
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  background: #232c30e0;
  z-index: 10;
  display: flex;
  .overlay__inner {
    margin: auto;
    position: relative;
    display: inline-block;
  }
  .overlay__content {
  }
  .calculate_btn {
    margin-top: 1rem;
  }
  .calculator-wrapper {
    background: #fff;
    padding: 1rem;
    height: 50rem;
    width: 70rem;
    border-radius: 1rem;
    @media (max-width: 479px) {
      width: 350px;
    }
  }
  .close {
    display: flex;
    justify-content: flex-end;
    font-weight: 600;
    svg {
      cursor: pointer;
    }
  }
  .calculator-layer-1 {
    display: flex;
    input {
      display: block;
      width: 100%;
      padding: 0.8rem;
      border: 1px solid #5fb19c;
      border-radius: 5px;
      direction: rtl;
    }
    label {
      color: #004c4e;
      font-size: 1.4rem;
      display: block;
      margin-bottom: 1rem;
      position: relative;
    }
    .calculator-layer-1-form {
      width: 55%;
      padding: 1rem;
      @media (max-width: 479px) {
        width: 100%;
      }
    }
    .calculator-layer-1-img {
      width: 40%;
      padding: 1rem;
      img {
        width: 100%;
        height: auto;
      }
      @media (max-width: 479px) {
        display: none;
      }
    }
  }
  .calculator-layer-2 {
    @media (max-width: 479px) {
      margin-top: 2rem;
    }
    .calculator-layer-2-text {
      display: flex;
      justify-content: space-between;
      width: 100%;
      background: #004c4e;
      color: #d9b08c;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      @media (max-width: 479px) {
        font-size: 1.4rem;
      }
      div {
        padding: 2rem;
        width: 50%;
        @media (max-width: 479px) {
          padding: 1rem 0.5rem;
        }
      }
      div:nth-of-type(1) {
        border-right: thin solid #5fb19c;
      }
    }
    .calculator-layer-2-num {
      display: flex;
      width: 100%;
      background: #d1e8e2;
      justify-content: space-between;
      color: #004c4e;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      span {
        font-size: 3rem;
        vertical-align: sub;
      }
      div {
        padding: 2rem;
        width: 50%;
        @media ${(props) => props.theme.media.mobileL} {
          padding: 1rem 0.5rem;
        }
      }
      div:nth-of-type(1) {
        border-right: thin solid #5fb19c;
      }
    }
  }
  .calculator-title {
    font-size: 2rem;
    font-weight: 600;
    color: #004c4e;
    padding-left: 1rem;
  }
  .tenor-range {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 1.2rem;
    }
  }
  .tenor-section {
    padding-top: 3rem;
    position: relative;
  }
`;
