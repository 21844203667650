import { useLocation, useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Header from 'components/Headers/Collateral';
import frrApis from 'apis/frr';
import { mergeAnswerWithQuestionsCollateral } from 'helpers/questionsAnswers';
import _cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import Spinner from 'components/OverLays/OverLaySpinner';
import Controller from 'components/Collateral/FeedBack/Controller';

export default function FeedBackEdit(props) {
  const { auth } = useSelector((state) => state);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [collateralQuestions, setCollateralQuestions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const collateralTypeId = location?.state?.collateralTypeId;

  useEffect(() => {
    const getQuestions = async () => {
      const res = await frrApis.getCollateralQuesAndAnswersByCollateralTypeId({
        collateralTypeId,
      });
      const res3 = await frrApis.getFlaggedCollateralQuestionsByCollateralId({
        collateralId: params.collateralId,
      });

      const flaggedQuestionsIds = res3?.data?.data.map((e) => e.questionId);
      const flaggedQuestions = res?.data?.data.filter(
        (e) => flaggedQuestionsIds?.includes(e.questionId) && e
      );

      setCollateralQuestions(
        mergeAnswerWithQuestionsCollateral(flaggedQuestions, res3?.data?.data)
      );
    };
    getQuestions();
  }, []);

  const updateCurrentQuestion = (index, answer) => {
    const cloneState = _cloneDeep(collateralQuestions);
    const quesType = cloneState[index].questionType;
    if (quesType == 'single' || quesType == 'Multiple') {
      cloneState[index].selectedAnswer = answer;
    } else {
      cloneState[index].inputtedAnswer = answer;
    }

    setCollateralQuestions(cloneState);
  };

  const answerFormatter = (elem) => {
    const selectedAnswerInstance = elem.selectedAnswer instanceof Array;
    if (elem.selectedAnswer && !selectedAnswerInstance) {
      return {
        collateralResponseId: elem?.collateralResponseId,
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: String(elem.selectedAnswer.answerId),
        unWeightedAnswer: 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.selectedAnswer && selectedAnswerInstance) {
      const getAnswersId = elem.selectedAnswer.map((elem) => elem.answerId);
      return {
        collateralResponseId: elem?.collateralResponseId,
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: getAnswersId.join(),
        unWeightedAnswer: 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.questionType == 'File') {
      return {
        collateralResponseId: elem?.collateralResponseId,
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: 'string',
        hasDocument: true,
        documentUrl: elem.inputtedAnswer,
      };
    } if (elem.questionType == 'list') {
      return {
        collateralResponseId: elem?.collateralResponseId,
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: elem.inputtedAnswer.join() || 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.questionType == 'contact-list') {
      return {
        collateralResponseId: elem?.collateralResponseId,
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: JSON.stringify(elem.inputtedAnswer) || 'string',
        hasDocument: false,
        documentUrl: '',
      };
    }
    return {
      collateralResponseId: elem?.collateralResponseId,
      businessId: auth?.user?.businessId,
      questionId: elem.questionId,
      answerId: '0',
      unWeightedAnswer: String(elem.inputtedAnswer) || 'string',
      hasDocument: false,
      documentUrl: '',
    };
  };
  const formatAnswerForSaving = () => {
    const questionList = [...collateralQuestions];

    const formattedAnswers = [];
    for (let i = 0; i < questionList.length; i++) {
      const elem = questionList[i];
      if (elem.questionClass == 'P') {
        formattedAnswers.push(answerFormatter(elem));
      } else if (elem.questionClass == 'S') {
        if (
          (elem.inputtedAnswer != 'undefined'
            && elem.inputtedAnswer?.length > 1)
          || (elem.selectedAnswer && elem.selectedAnswer != 'undefined')
        ) {
          formattedAnswers.push(answerFormatter(elem));
        }
      }
    }
    return formattedAnswers;
  };

  const submitHandler = async () => {
    setLoading(true);
    try {
      await frrApis.updateFlaggedCollateralQuestionsByCollateralResponseId(
        formatAnswerForSaving()
      );
      navigate('/app/collateral/collaterals-with-feedbacks');
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading ? <Spinner /> : ''}
      <Header />
      <Controller
        currentQuestionIndex={questionIndex}
        setCurrentQuestionIndex={setQuestionIndex}
        questions={collateralQuestions}
        feedBackDetails={location?.state?.feedBacks}
        updateCurrentQuestion={updateCurrentQuestion}
        submitHandler={submitHandler}
      />
    </>
  );
}
