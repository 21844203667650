export const questionCountSetter = (currentQuestion, isTranches, primarySourceOfRepaymentOther, primarySourceOfRepaymentOtherRemoved, isAlternativeSourceOfRepayment, isAlternativeSourceOfRepaymentRemoved, isTranchesRemoved) => {
    const indexCountSetter = {
        primarySourceOfRepaymentOtherRemovedSetter: null,
        isAlternativeSourceOfRepaymentRemovedSetter: null,
        isAlternativeSourceOfRepaymentRemovedTrueSetter: null,
        isAlternativeSourceOfRepaymentRemovedTrueSetter2: null,
        isAlternativeSourceOfRepaymentRemovedFalseSetter: null,
        isAlternativeSourceOfRepaymentRemovedFalseSetter2: null,
        disbursementMode1: null,
        disbursementMode2: null,
        disbursementMode3: null,
        multipleClientSetter1: null,
        multipleClientSetter2: null,
        multipleClientSetter3: null,
        multipleClientSetter4: null,
        multipleClientSetter5: null,
        multipleClientSetter6: null,
        multipleClientSetter7: null,
        counterPartyName1: null,
        counterPartyName2: null,
        counterPartyName3: null,
        counterPartyName4: null,
        counterPartyName5: null,
        counterPartyName6: null,
        counterPartyName7: null,
        multipleOption1: null,
        multipleOption2: null,
        multipleOption3: null,
        multipleOption4: null,
        multipleOption5: null,
        multipleOption6: null,
        multipleOption7: null,
        multipleOption8: null,
        counterParties1: null,
        counterParties2: null,
        counterParties3: null,
        counterParties4: null,
        counterParties5: null,
        counterParties6: null,
        counterParties7: null,
        counterParties8: null
    }
        // For "is there an alternative source of repayment" page.
    if (
        !primarySourceOfRepaymentOther
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('is there an alternative source of repayment')
    ) {
        indexCountSetter.primarySourceOfRepaymentOtherRemovedSetter = true
    }

    // For "provide details of the alternative source of payment" page.
    if (
        currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('provide details of the alternative source of payment')
        && primarySourceOfRepaymentOtherRemoved
    ) {
        indexCountSetter.isAlternativeSourceOfRepaymentRemovedSetter = true;
    }

    // // For "which of these best describes the structure of the facility request" page.
    if (
        !isAlternativeSourceOfRepayment
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'which of these best describes the structure of the facility request'
        )
        && !primarySourceOfRepaymentOtherRemoved
    ) {
        indexCountSetter.isAlternativeSourceOfRepaymentRemovedTrueSetter = true;
    }

    if (
        !isAlternativeSourceOfRepayment
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'which of these best describes the structure of the facility request'
        )
        && primarySourceOfRepaymentOtherRemoved
    ) {
        indexCountSetter.isAlternativeSourceOfRepaymentRemovedTrueSetter2 = true;
    }

    if (
        isAlternativeSourceOfRepayment
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'which of these best describes the structure of the facility request'
        )
        && primarySourceOfRepaymentOtherRemoved
    ) {
        setCurrentIndex((currentIndex) => currentIndex - 1);
        indexCountSetter.isAlternativeSourceOfRepaymentRemovedFalseSetter = true;
    }

    if (
        isAlternativeSourceOfRepayment
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'which of these best describes the structure of the facility request'
        )
        && !primarySourceOfRepaymentOtherRemoved
    ) {
        indexCountSetter.isAlternativeSourceOfRepaymentRemovedFalseSetter2 = true;
    }

    // For "what is the preferred disbursement mode" page.
    if (
        currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('what is the preferred disbursement mode')
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.disbursementMode1 = true;
    }

    if (
        currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('what is the preferred disbursement mode')
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.disbursementMode2 = true;
    }

    if (
        currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('what is the preferred disbursement mode')
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.disbursementMode3 = true;
    }

    // For "kindly indicate details of tranches" page
    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.tranches1 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.tranches2 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.tranches3 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
        && !primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.tranches4 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.tranches5 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.tranches6 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('kindly indicate details of tranches')
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.tranches7 = true;
    }

    // For "kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients" page
    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleClientSetter1 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleClientSetter2 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleClientSetter3 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
        && !primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleClientSetter4 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleClientSetter5 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleClientSetter6 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes(
            'kindly indicate if the cash flow for loan repayment is expected from a single client or multiple clients'
        )
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleClientSetter7 = true;
    }

    // For "indicate the name of the counterparty" page
    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterPartyName1 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterPartyName2 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterPartyName3 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
        && !primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterPartyName4 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterPartyName5 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterPartyName6 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('indicate the name of the counterparty')
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterPartyName7 = true;
    }

    // For "select multiple counterparty option" page.
    if (
        currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
        && isTranchesRemoved
    ) {
        indexCountSetter.multipleOption1 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleOption2 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleOption3 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
        && !primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleOption4 = true;
    }

    if (
        currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
        && !isTranchesRemoved
    ) {
        indexCountSetter.multipleOption5 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleOption6 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.multipleOption7 = true;
    }

    if (
        currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('select multiple counterparty option')
        && !primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
        && !isTranchesRemoved
    ) {
        indexCountSetter.multipleOption8 = true;
    }

    // For "list names of counterparties" page.
    if (
        currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('list names of counterparties')
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
        && isTranchesRemoved
    ) {
        indexCountSetter.counterParties1 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('list names of counterparties')
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterParties2 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('list names of counterparties')
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterParties3 = true;
    }

    if (
        !isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('list names of counterparties')
        && !primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterParties4 = true;
    }

    if (
        currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('list names of counterparties')
        && primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
        && !isTranchesRemoved
    ) {
        indexCountSetter.counterParties5 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('list names of counterparties')
        && primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterParties6 = true;
    }

    if (
        isTranches
        && currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('list names of counterparties')
        && !primarySourceOfRepaymentOtherRemoved
        && isAlternativeSourceOfRepaymentRemoved
    ) {
        indexCountSetter.counterParties7 = true;
    }

    if (
        currentQuestion?.frrQuestion
        .toLowerCase()
        .includes('list names of counterparties')
        && !primarySourceOfRepaymentOtherRemoved
        && !isAlternativeSourceOfRepaymentRemoved
        && !isTranchesRemoved
    ) {
        indexCountSetter.counterParties8 = true;
    }

    return indexCountSetter;

}