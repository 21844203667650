import React, { useState, useEffect } from 'react';
import Button from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import { frrApis } from 'apis';
import errorHandler from 'helpers/errorHandler';
import { useSelector } from 'react-redux';
import Spinner from 'components/OverLays/OverLaySpinner';
import styled from 'styled-components';
import Layout from 'components/Layouts/BusinessEvaDashboard';

export default function Index() {
  const navigate = useNavigate();
  const [facility, setFacility] = useState(false);
  const [collateral, setCollateral] = useState(false);
  const [collaterals, setCollaterals] = useState([]);
  const [documentBusiness, setDocumentBusiness] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedCollateral, setSelectedCollateral] = useState(null);
  const [facilities, setFacilities] = useState([]);

  const { business } = useSelector((state) => state);
  const businessId = business?.businessProfiles[0]?.businessId;

  useEffect(() => {
    const getFacilityDetails = async () => {
      try {
        const response = await frrApis.getFacilityDetailsByBusinessId(
          businessId
        );
        setFacilities(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
      }
    };

    getFacilityDetails();
  }, []);
  useEffect(() => {
    const getCollaterals = async () => {
      try {
        const response = await frrApis.getCollateralDetails(businessId);
        setCollaterals(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
      }
    };

    getCollaterals();
  }, []);

  const populateSelectedFacility = (arg) => {
    setSelectedCollateral(null);

    for (const facility of facilities) {
      if (arg == facility.d.facilityId) {
        setSelectedFacility(facility);
      }
    }
  };

  const populateSelectedCollateral = (arg) => {
    setSelectedFacility(null);

    for (const collateral of collaterals) {
      if (arg == collateral.c.collateralId) {
        setSelectedCollateral(collateral);
      }
    }
  };
  return (
    // <Layout>
    //   {facilitiesLoader || collateralLoader ? <Spinner /> : ""}
    <Styled>
      <p className="font-medium">Select Document Type</p>
      <div id="cards-holder">
        <div
          onClick={() => {
            setFacility(true);
            setCollateral(false);
            setDocumentBusiness(false);
            setSelectedCollateral(null);
          }}
          className={!facility ? 'document-card' : 'document-card-active'}
        >
          <p className="card-text">Facility Evaluation</p>
        </div>
        <div
          onClick={() => {
            setFacility(false);
            setCollateral(true);
            setDocumentBusiness(false);
            setSelectedFacility(null);
          }}
          className={!collateral ? 'document-card' : 'document-card-active'}
        >
          <p className="card-text">Collateral Evaluation</p>
        </div>
        <div
          onClick={() => {
            setFacility(false);
            setCollateral(false);
            setDocumentBusiness(true);
            navigate('/app/document/business');
            setSelectedFacility(null);
            setSelectedCollateral(null);
          }}
          className={
            !documentBusiness ? 'document-card' : 'document-card-active'
          }
        >
          <p className="card-text">Business Evaluation</p>
        </div>
      </div>

      {facility && (
        <div style={{ marginTop: '2rem' }}>
          <p className="font-medium">Select Facility</p>
          <select
            onChange={(e) => {
              populateSelectedFacility(e.target.value);
            }}
            className="Dropdown text-lg"
          >
            <option value="">Select option</option>
            {facilities?.length > 0
              && facilities.map((facility) => (
                <option
                  key={facility.d.facilityId}
                  value={facility.d.facilityId}
                  className="text-base"
                >
                  {`${facility.t.facilityTypeName}_${facility.d.facilityId}`}
                </option>
              ))}
          </select>
        </div>
      )}

      {collateral && (
        <div style={{ marginTop: '2rem' }}>
          <p className="font-medium">Select Collateral</p>
          <select
            onChange={(e) => {
              populateSelectedCollateral(e.target.value);
            }}
            className="Dropdown text-base"
          >
            <option value="">Select option</option>
            {collaterals?.length > 0
              && collaterals.map((collateral) => (
                <option
                  key={collateral.c.collateralId}
                  value={collateral.c.collateralId}
                  className="text-lg"
                >
                  {`${collateral.t.collateralTypeName
                  }_${
                    collateral.c.collateralId}`}
                </option>
              ))}
          </select>
        </div>
      )}

      <div id="modal-buttons-holder">
        <Button
          onClick={() => {
            // setDocumentModal(false);
            setCollateral(false);
            setFacility(false);
            setDocumentBusiness(false);
          }}
          title="Cancel"
          id="no-button"
        />

        <Button
          onClick={() => {
            if (facility && selectedFacility) {
              navigate('/app/document/facility', {
                state: { facility: selectedFacility },
              });
            }

            if (collateral && selectedCollateral) {
              navigate('/app/document/collateral', {
                state: { collateral: selectedCollateral },
              });
            }
            // setDocumentModal(false);
          }}
          title="Proceed"
          id="yes-button"
        />
      </div>

    </Styled>
  );
}
const Styled = styled.div`
  max-width: 70rem;
  padding: 30px;
  background: #fff;
  margin: 2rem;
  border-radius: 1rem;

  #modal {
    background-color: white;
    padding: 2rem 4rem 4rem 4rem;
  }

  #modal-icon {
    color: black;
  }

  #no-button {
    border: none;
    margin-right: 2rem;
    background-color: transparent;
    color: #004c4e;
    cursor: pointer;
  }

  #yes-button {
    padding: 1rem 3rem;
    border: none;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-radius: 6px;
    color: #d9b08c;
    cursor: pointer;
  }

  #modal-buttons-holder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 5rem;
  }

  #cards-holder {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2rem;
  }

  .document-card {
    width: 15rem;
    height: 9rem;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 5rem;
    border-radius: 3px;
  }

  .document-card-active {
    width: 15rem;
    height: 9rem;
    border: 1.5px solid #00989c;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 5rem;
    border-radius: 3px;
  }

  .card-text {
    text-align: center;
    width: 10rem;
  }

  .Dropdown {
    padding: 1rem 15rem 1rem 1rem;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid grey;
    margin-top: 1rem;
    outline: none;
    background-color: #eee;
  }

  #document-button {
    background-color: transparent;
    border: none;
    padding-right: 5rem;
  }

  #document-button-active {
    background-color: #d1e8e2;
    border-radius: 6px;
    border: none;
    padding-right: 5rem;
  }
`;
