import { forwardRef } from 'react';
import styled from 'styled-components';

const TextArea = forwardRef((props, ref) => (
  <Styled
    onChange={props.onChange}
    ref={ref}
    placeholder={props.placeholder}
    value={props.value}
    rows={6}
  />
));

const Styled = styled.textarea`
  padding: 1rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid grey;
  margin-top: 1rem;
  resize: none;

  :focus {
    outline: none;
  }
`;

export default TextArea;
