import React, { useState, useEffect } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import Header from 'components/Headers/Orr';
import Spinner from 'components/OverLays/OverLaySpinner';
import styled from 'styled-components';
import useOrrQuesAns from 'hooks/useOrrQuesAns';
import useQuery from 'hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import Controller from './Controller';

export default function Index() {
  const navigate = useNavigate();
  const [params] = useQuery();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [
    businessMgtQuestionsPool,
    isLoading,
    setLoader,
    setBusinessMgtQuestionsPool,
  ] = useOrrQuesAns();
  const [activeSection, setActiveSection] = useState('Management');
  const [history, setHistory] = useState({
    Management: true,
    Business: false,
    Financials: false,
  });

  // We need to check the index if wrong we send the user out

  useEffect(() => {
    const isUserReturning = () => {
      setActiveSection(params.get('dest'));
      setCurrentQuestionIndex(0);
    };

    if (
      params?.get('dest')?.length > 4
      && params?.get('status') == 'returning'
    ) {
      isUserReturning();
    }

    if (params.get('dest') == 'Financials') {
      setHistory({
        Management: true,
        Business: true,
        Financials: true,
      });
    }

    // might be wrong
    return () => {};
  }, []);

  useEffect(() => {
    const isUserFromReview = () => {
      setActiveSection(params.get('dest'));
      setCurrentQuestionIndex(Number(params?.get('index')));
    };
    if (
      params?.get('dest')?.length > 4
      && params?.get('index')
      && params?.get('status') == 'review'
    ) {
      isUserFromReview();
      if (params.get('dest') == 'Financials') {
        setHistory({
          Management: false,
          Business: false,
          Financials: true,
        });
      } else if (params.get('dest') == 'Business') {
        setHistory({
          Management: false,
          Business: true,
          Financials: false,
        });
      }
    }
    // might be wrong
    return () => {};
  }, []);

  const updateQuestionsPoolHandler = (index, answer) => {
    const cloneState = _cloneDeep(businessMgtQuestionsPool);
    const currentQuestion = cloneState[activeSection][index];
    const quesType = currentQuestion.questionType;
    if (quesType == 'single' || quesType == 'Multiple') {
      currentQuestion.selectedAnswer = answer;
    } else {
      currentQuestion.inputtedAnswer = answer;
    }
    setBusinessMgtQuestionsPool((prev) => ({ ...prev, ...cloneState }));
  };

  const sectionHandler = () => {
    if (params?.get('status') == 'review') {
      navigate('/app/orr/review', { replace: true });
    } else if (activeSection == 'Management') {
      setActiveSection('Business');
      setHistory((prev) => ({ ...prev, Management: true }));
    } else if (activeSection == 'Business') {
      setHistory((prev) => ({ ...prev, Business: true }));
      setActiveSection('Financials');
    } else if (activeSection == 'Financials') {
      setHistory((prev) => ({ ...prev, Financials: true }));
    }
  };

  const tabHandler = (dest) => {
    if (activeSection != dest && history[dest] == true) {
      setActiveSection(dest);
      setCurrentQuestionIndex(businessMgtQuestionsPool[dest].length - 1);
    }
  };

  return (
    <Styled>
      <Header />
      {isLoading ? <Spinner /> : ''}
      <section className="container_layer1">
        <Controller
          queryParams={params}
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          updateQuestionsPoolHandler={updateQuestionsPoolHandler}
          allQuestion={businessMgtQuestionsPool}
          questions={businessMgtQuestionsPool[activeSection]}
          sectionHandler={sectionHandler}
          activeSection={activeSection}
          setLoader={setLoader}
          tabHandler={tabHandler}
          history={history}
        />
      </section>
    </Styled>
  );
}
const Styled = styled.div`
  background-color: #e5e5e5;
  height: 100%;
  min-height: 100vh;
  .container_layer1 {
    margin: 0 auto;
    padding: 0 10%;
    max-width: 1440px;
    @media (max-width: 1100px) {
      padding: 0 4%;
    }
    @media (max-width: 479px) {
      padding: 0 2%;
    }
  }
`;
