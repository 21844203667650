import Layout from 'components/Layouts/Default';
import styled from 'styled-components';
import Spinner from 'components/OverLays/OverLaySpinner';
import {
  useState, useId, useLayoutEffect
} from 'react';
import { frrApis, businessApis, firsApis } from 'apis';
import errorHandler from 'helpers/errorHandler';
import { store } from 'store/store';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import * as NotifyActions from 'store/actions/notify';
import CurrentFacilityIcon from 'assets/svgs/current-facility-icon.svg';
import ClosedFacilityIcon from 'assets/svgs/closed-facility-icon.svg';
import TotalFacilityIcon from 'assets/svgs/total-facilities-icon.svg';
import { decryptHandler } from 'helpers/encryption';

function CreditHistory() {
  const id = useId();
  const [orrScore, setOrrScore] = useState(null);
  const [facilityDetailsLoader, setFacilityDetailsLoader] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [facilitiesStatuses, setFacilitiesStatuses] = useState({
    closed: 0,
    expired: 0,
    pendingClosure: 0,
    current: 0,
  });
  const [business, setBusiness] = useState(null);
  const [loader, setLoader] = useState(false);
  const [accountLoader, setAccountLoader] = useState(false);

  const { businessId } = store.getState().business.businessProfiles[0];
  const { passportId } = store.getState().business.businessProfiles[0];

  const getFacilityDetails = async (customerNumber) => {
    setFacilityDetailsLoader(true);
    try {
      const response = await frrApis.getCreditHistory(customerNumber);
      if (response.data.sbnReadLoanOrAccount[0].arrangementId != 'No records were found that matched the selection criteria') {
        setFacilities(response.data.sbnReadLoanOrAccount);
      } else {
        return store.dispatch(
          NotifyActions.ShowNotify({
            show: true,
            type: 'bad',
            message: response.data.sbnReadLoanOrAccount[0].arrangementId,
            func: null,
          })
        );
      }
    } catch (error) {
      const decryptedError = decryptHandler(error.response.data);
      const errorMessage = decryptedError?.sbnReadLoanOrAccount;
      if (errorMessage && errorMessage?.length > 0) {
        if (errorMessage.toLowerCase().includes('no records were found that matched the selection criteria')) {
          return store.dispatch(
            NotifyActions.ShowNotify({
              show: true,
              type: 'bad',
              message: errorMessage[0].arrangementId,
              func: null,
            })
          );
        }
      }
      errorHandler(error);
    } finally {
      setFacilityDetailsLoader(false);
    }
  };

  const verifyAccount = async (accountNumber) => {
    setAccountLoader(true);
    try {
      const response = await firsApis.getAccountDetailsByNuban(accountNumber);
      getFacilityDetails(response.data.BankAccountFullInfo.CUS_NUM);
    } catch (error) {
      errorHandler(error);
      setBusiness({
        ...business,
        companyAccountNumber: '',
      });
    } finally {
      setAccountLoader(false);
    }
  };

  useLayoutEffect(() => {
    setLoader(true);
    const getBusiness = async () => {
      try {
        const response = await businessApis.getBusinessByPassportIdAndBusinessId(
          passportId,
          businessId
        );
        setBusiness(response.data.data);
        verifyAccount(response.data.data.companyAccountNumber);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoader(false);
      }
    };

    getBusiness();
  }, []);

  return (
    <Styled>
      <Layout
        activeBar="credit-history"
        headerId="header"
        tabsId="tabs"
        tabsBackgroundId="tabs-background"
        orrScore={orrScore}
      >
        <div className="Wrapper">
          <section className="top-section">
            <p className="Page-header">Credit History</p>

            <div className="cards-holder">
              <Card
                count={facilities?.length}
                title="Total Facilities"
                icon={TotalFacilityIcon}
              />
              <Card
                count={facilitiesStatuses.current}
                title="Running Facilities"
                icon={CurrentFacilityIcon}
              />
              <Card
                count={facilitiesStatuses.current}
                title="Closed Facilities"
                icon={ClosedFacilityIcon}
              />
              <Card
                count={facilitiesStatuses.current}
                title="Plending closure"
                icon={CurrentFacilityIcon}
              />
              <Card
                count={facilitiesStatuses.current}
                title="Expired Facilities"
                icon={CurrentFacilityIcon}
              />
            </div>
          </section>

          <section className="Facilities-summary">
            <div className="Facilities-container">
              <div className="Facilites-header-holder">
                <p id="facilites-header">All Facilities</p>
              </div>
              <div className="Facilities-holder">
                <div className="Facility-item-header">
                  <div className="Facility-type">
                    <p className="Facilities-header">Facility Type</p>
                  </div>
                  <div className="Facility-amount">
                    <p className="Facilities-header">Amount</p>
                  </div>
                  <div className="Facility-start-date">
                    <p className="Facilities-header">Start Date</p>
                  </div>
                  <div className="Facility-status">
                    <p className="Facilities-header">Status</p>
                  </div>
                </div>

                <div>
                  {facilities?.length > 0
                    && facilities.map((facility) => {
                      const addedDate = moment(facility.valueDate)
                        .format('L')
                        .toString();

                      return (
                        <div key={id} className="Facility">
                          <div className="Facility-type">
                            <p>{facility.loanProduct}</p>
                          </div>

                          <div className="Facility-amount">
                            <p>
                              <CurrencyFormat
                                value={facility.loanAmt}
                                displayType="text"
                                thousandSeparator
                                prefix={facility.ccy}
                              />
                            </p>
                          </div>

                          <div className="Facility-start-date">
                            <p>{addedDate}</p>
                          </div>

                          <div className="Facility-status">
                            <p style={{ color: '#00989C' }}>
                              {facility?.arrStatus?.toUpperCase()}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>

      {facilityDetailsLoader || loader || accountLoader ? <Spinner /> : ''}
    </Styled>
  );
}

const Styled = styled.div`
  .Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10rem 2rem 3rem 2rem;
    margin-top: 5rem;

    @media (max-width: 80rem) {
      flex-direction: column;
    }
  }

  .main-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;

    @media (max-width: 80rem) {
      width: 100%;
    }
  }

  .action-button-icon {
    stroke: grey;
    fill: grey;
  }

  .Facilities-holder {
    width: 100;
    border: 5px solid #d1e8e2;
    border-radius: 10px;
    background-color: white;
    padding: 1rem 2rem;
  }

  .Facilites-header-holder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    p {
      color: grey;
      font-size: 1rem;
    }
  }

  #facilites-header {
    color: #004c4e;
    font-size: 1.5rem;
    margin-right: 0.5rem;
    font-weight: 500;
  }

  .Facility-item-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
    border-bottom: 1px solid #d1e8e2;
  }

  #close-icon {
    color: black;
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  .Action-button-disabled {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2fdfa;
    border: 2px solid #d1e8e2;
    box-sizing: border-box;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 2rem 0;
    cursor: not-allowed;

    button {
      font-size: 14px;
      color: #004c4e;
      opacity: 0.4;
      border: none;
      background-color: transparent;
      cursor: not-allowed;
    }

    :not(:last-child) {
      margin-right: 5%;
    }
  }

  .Action-button-icon {
    margin-bottom: 1rem;
    color: #00989c;
    border-radius: 1px;
  }

  .Action-button-icon-disabled {
    margin-bottom: 1rem;
    color: #00989c;
    border-radius: 1px;
    opacity: 0.4;
    cursor: not-allowed;
  }

  .Facility {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
    cursor: pointer;
    transition: 0.3s;
    border-bottom: 1px solid #d1e8e2;

    p {
      font-size: 1.5rem;
    }

    :hover {
      background-color: #f7faf9;
    }
  }

  .Facility-amount {
    width: 25%;
    display: flex;
    justify-content: flex-start;

    p {
      text-align: start;
    }
  }

  .Facility-type {
    width: 35%;
    display: flex;
    justify-content: flex-start;

    p {
      text-align: start;
    }
  }

  .Facility-status {
    width: 15%;
    display: flex;
    justify-content: flex-start;
  }

  .Facilities-header {
    color: #004c4e;
    font-weight: 500;
  }

  .Facility-start-date {
    width: 25%;
  }

  .Facility-id {
    width: 15%;
  }

  #divider {
    width: 90%;
    height: 1px;
    margin: 2rem 0;
    background-color: #d1e8e2;
  }

  #tabs {
    position: fixed;
    top: 6.8rem;
    bottom: -5rem;
    left: 1rem;
    min-height: 100vh;
  }

  #header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }

  .Dropdown {
    padding: 1rem 15rem 1rem 1rem;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid grey;
    margin-top: 1rem;
    outline: none;
    background-color: #eee;
  }

  .Collateral-children-holder {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    border-bottom: 1px solid #d1e8e2;
    padding-bottom: 0.5rem;
    cursor: pointer;

    :hover {
      background-color: #fcfaf9;
    }
  }

  .Collateral-type {
    font-size: 1.2rem;
  }

  .Collateral-status-text {
    font-size: 1.2rem;
  }

  .Collateral-action-button {
    background-color: #fff5ed;
    padding: 0.5rem;
    cursor: pointer;
    width: 60%;
    font-size: 1.2rem;
    color: #d45f36;
    display: flex;
    align-item: center;
    justify-content: center;
    border: none;
  }

  #tabs-background {
    height: auto;
    margin-top: 4rem;
  }

  .Collateral-action-button-disabled {
    background-color: #fff5ed;
    padding: 0.5rem;
    cursor: pointer;
    width: 60%;
    font-size: 1.2rem;
    color: #d45f36;
    display: flex;
    align-item: center;
    justify-content: center;
    border: none;
    opacity: 0.4;
  }

  #tabs-background {
    height: auto;
    margin-top: 4rem;
  }

  #treat-button {
    background: linear-gradient(90deg, #00989c, #004c4e);
    color: white;
    font-weigth: lighter;
  }

  .Page-header {
    font-size: 2rem;
    font-weight: 500;
    color: #004c4e;
    margin-bottom: 3rem;
  }

  .top-section {
    margin-bottom: 8rem;
  }

  .cards-holder {
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

export default CreditHistory;

export function Card(props) {
  return (
    <CardStyled>
      <p id="count">{props.count}</p>
      <div className="card-bottom">
        <img src={props.icon} />
        <p>{props.title}</p>
      </div>
    </CardStyled>
  );
}

const CardStyled = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 1rem 2rem;
  width: 15rem;
  height: 10rem;
  box-shadow: 0px 5px 7px 0px #ddd;
  margin-right: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #count {
    color: #0e4657;
    border-bottom: 1px solid #0e4657;
    width: 100%;
    text-align: center;
  }

  .card-bottom {
    color: #004c4e;
    margin-top: 1rem;
    display: flex;

    p {
      margin-left: 1rem;
    }
  }
`;
