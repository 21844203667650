import React from 'react';
import { useSelector } from 'react-redux';
import { BriefCase2Svg } from 'theme/icons';
import Box from './Box';

export default function BusinessEvaluation(props) {
  const { business } = useSelector((state) => state);
  const { profileStatus, isVerified } = business?.businessProfiles[0];
  const getProgress = () => {
    let initValue = 0;
    if (props.documentStatus) {
      initValue += 1;
    }
    if (profileStatus.toLowerCase() != 'initial') {
      initValue += 1;
    }
    if (isVerified) {
      initValue += 1;
    }
    const fixedTwo = (initValue / 3) * 100;
    return fixedTwo.toFixed(1);
  };
  return (
    <Box
      Icon={BriefCase2Svg}
      getProgress={getProgress}
      isDocLoading={props?.isDocLoading}
      feedBackLoader={props?.isFeedBackAvailable.loader}
      feedBackStatus={props?.isFeedBackAvailable.status}
      clickHandler={props?.startEvaluationHandler}
      title="Business Evaluation"
    />
  );
}
