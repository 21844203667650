import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomInput from 'components/FormikInputs/Input';
import { NextButton, PrevButton } from 'components/Buttons';
import { tenorOptions, loanPurpose, denominations } from 'constants/signUp';
import { useDispatch, useSelector } from 'react-redux';
import * as SignUpFormActions from 'store/actions/signUpForm';
import * as SignUpActions from 'store/actions/signUpForm';
import styled from 'styled-components';
import NewExistingModal from './NewExistingModal';

export default function Loan() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { signUpForm } = useSelector((state) => state);
  const dispatch = useDispatch();
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {}
  function closeModal() {
    setIsOpen(false);
  }
  const validationSchema = yup.object({
    loanPurpose: yup.string().required('Loan Purpose Name is required'),
    loanCurrency: yup.string().required('Loan Currency Type required'),
    facilityAmount: yup.string().required('Facility Amount Type required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: signUpForm?.loan?.loanPurpose
      ? signUpForm?.loan
      : {
        loanPurpose: '',
        facilityAmount: '',
        facilityTenor: '0',
        facilityTenor2: '',
        facilityTenor3: '',
        loanCurrency: '₦',
      },
    validationSchema,
    onSubmit: (values) => {
      dispatch(SignUpFormActions.SaveLoan(values));
      openModal();
    },
  });
  return (
    <Styled>
      <h2 className="title">Tell Us About Your Funding Needs</h2>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          name="loanPurpose"
          label="Purpose For Requesting The Facility"
          type="option"
          required
          options={loanPurpose}
          formik={formik}
        />
        <CustomInput
          name2="facilityAmount"
          name="loanCurrency"
          label="Estimated Funding Needs"
          type="currencyDropDownAndNumeric"
          options={denominations}
          // options2={facilityEstimate}
          required
          formik={formik}
        />
        <CustomInput
          name="facilityTenor"
          name2="facilityTenor2"
          name3="facilityTenor3"
          label="Facility Tenor"
          type="threeDropDown"
          options={tenorOptions.days}
          options2={tenorOptions.months}
          options3={tenorOptions.years}
          placeholder="Days"
          placeholder2="Months"
          placeholder3="Years"
          required
          formik={formik}
        />
        <div className="navButton">
          <PrevButton
            mode="3"
            title="Prev"
            type="button"
            onClick={() => dispatch(SignUpActions.SaveActivePage('business'))}
          />
          <NextButton type="submit" mode="1" title="Next" />
        </div>
      </form>
      <NewExistingModal
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        afterOpenModal={afterOpenModal}
      />
    </Styled>
  );
}
const Styled = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 5rem;
  padding-top: 15%;

  @media (max-width: 992px) {
    padding-top: 5%;
  }
  .navButton {
    display: flex;
    justify-content: space-between;
  }
  .title {
    margin-bottom: 2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
  }
`;
