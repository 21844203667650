import { forwardRef, useRef } from 'react';
import styled from 'styled-components';
import { FaCloudUploadAlt, FaRegFolderOpen } from 'react-icons/fa';

const Uploader = forwardRef((props, ref) => {
  const inputRef = useRef();
  
  return (
    <Styled>
      <div className="upload-holder">
        <label id="label">
          {props.label}
          {
            props.removeAsterisk ?
            '' :
            <span style={{ color: 'red' }}>*</span>
          }

          
          {
            props.fileGuide ?
            <span style={{ color: 'red' }}>{props.fileGuide}</span> : ''
          }
        </label>
        {
          !props.small ?
          <div id='input-holder'>
            <input
              onChange={props.onChange}
              ref={inputRef}
              type={props?.type ? props?.type : 'file'}
              placeholder={props.placeholder}
              id={props?.id}
              className='file-input'
              name={props.name}
              value={
                props.formik === '' || !props.formik
                  ? props.value
                  : props.formik !== ''
                    ? props.formik.values[props.name]
                    : ''
              }
              accept={props.accept ? props.accept : ""}
            />
            {inputRef?.current?.value == '' || !inputRef?.current?.value ? (
                <div id='icon-holder'>
                  <FaCloudUploadAlt size={20} color="#004C4E" />
                  <p>Attach Document</p>
                </div>
              ) : (
                <div id='icon-holder'>
                  <FaRegFolderOpen size={20} color="#004C4E" />
                  <p>
                    {inputRef?.current?.value?.slice(12, 30)}
                    ...
                  </p>
                </div>
              )}
          </div>
          : 
          <div className='upload-parent'>
            <div id={ props.small ? "input-holder-2" : 'input-holder'}>
              <input
                onChange={props.onChange}
                ref={inputRef}
                type={props?.type ? props?.type : 'file'}
                placeholder={props.placeholder}
                id={props?.id}
                className={ props.small ? "file-input-2" : 'file-input'}
                name={props.name}
                value={
                  props.formik === '' || !props.formik
                    ? props.value
                    : props.formik !== ''
                      ? props.formik.values[props.name]
                      : ''
                }
                accept={props.accept ? props.accept : ""}
              />
            </div>
            {inputRef?.current?.value == '' || !inputRef?.current?.value ? (
                <div id={props.small ? "icon-holder-2" : 'icon-holder'}>
                  <FaCloudUploadAlt size={20} color="#004C4E" />
                  <p style={{textAlign: 'center'}}>Click box to attach document</p>
                </div>
              ) : (
                <div id={props.small ? "icon-holder-2" : 'icon-holder'}>
                  <FaRegFolderOpen size={20} color="#004C4E" />
                  <p>
                    {inputRef?.current?.value?.slice(12, 30)}
                    ...
                  </p>
                </div>
            )}
          </div>

        }
        
      </div>
    </Styled>
  );
});

const Styled = styled.div`
  width: 48%;

  #input-holder {
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    border: 1px dashed #5fb19c;
    background-color: #d1e8e2;
    display: flex;
    position: relative;

    :focus {
      outline: none;
    }
  }

  #input-holder-2 {
    width: 50%;
    height: 4rem;
    border-radius: 5px;
    border: 1px dashed #5fb19c;
    background-color: #d1e8e2;
    display: flex;

    :focus {
      outline: none;
    }
  }

  .file-input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .upload-parent{
    display: flex;
    align-items: center;

  }

  .file-input-2 {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  #icon-holder {
    position: absolute;
    top: 5%;
    left: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: none;

    p {
      font-size: 1.2rem;
      cursor: pointer;
      pointer-events: none;
    }
  }

  #icon-holder-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: none;
    margin-left: 3rem;

    p {
      font-size: 1.2rem;
      cursor: pointer;
      pointer-events: none;
    }
  }

  #label {
    font-size: 1.4rem;
  }

  .upload-holder {
    margin-top: unset;
  }
`;

export default Uploader;
