import React, { useState } from 'react';
import styled from 'styled-components';
import { NextButton, PrevButton } from 'components/Buttons';
import NumberFormat from 'react-number-format';
import CSVReader from 'react-csv-reader';
import { toast } from 'react-toastify';

export default function FinStatement(props) {
  const [allInt, setAllInt] = useState(true);
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    computeValues(name, value);
  };
  const prevHandler = (e) => {
    props.prevHandler();
  };
  const nextHandler = (e) => {
    e.preventDefault();
    props.nextHandler();
  };
  const yearHandler = (e) => {
    const auditYear = new Date(
      props?.finAnswers?.financialEndYearDate
    ).getFullYear();
    return auditYear;
  };
  const valueScaleHandler = (e) => {
    props.setFinAnswers((prev) => ({ ...prev, valueScale: e.target.value }));
  };
  function computeValues(name, value) {
    const clonedFinAnswers = { ...props.finAnswers };
    clonedFinAnswers[name] = Number(value?.replaceAll(',', ''));
    clonedFinAnswers.grossProfitYear1 = Number(String(clonedFinAnswers.revenueYear1)?.replaceAll(',', ''))
      - Number(String(clonedFinAnswers.costOfSalesYear1)?.replaceAll(',', ''));
    clonedFinAnswers.grossProfitYear2 = Number(String(clonedFinAnswers.revenueYear2)?.replaceAll(',', ''))
      - Number(String(clonedFinAnswers.costOfSalesYear2)?.replaceAll(',', ''));
    clonedFinAnswers.operatingProfitYear1 = Number(String(clonedFinAnswers.grossProfitYear1)?.replaceAll(',', ''))
      + Number(String(clonedFinAnswers.otherIncomeYear1)?.replaceAll(',', ''))
      - Number(
        String(clonedFinAnswers.operatingExpensesYear1)?.replaceAll(',', '')
      );
    clonedFinAnswers.operatingProfitYear2 = Number(String(clonedFinAnswers.grossProfitYear2)?.replaceAll(',', ''))
      + Number(String(clonedFinAnswers.otherIncomeYear2)?.replaceAll(',', ''))
      - Number(
        String(clonedFinAnswers.operatingExpensesYear2)?.replaceAll(',', '')
      );
    clonedFinAnswers.profitAfterTaxYear1 = Number(
      String(clonedFinAnswers.operatingProfitYear1)?.replaceAll(',', '')
    )
      + Number(String(clonedFinAnswers.financeIncomeYear1)?.replaceAll(',', ''))
      - Number(String(clonedFinAnswers.financeCostYear1)?.replaceAll(',', ''))
      - Number(String(clonedFinAnswers.taxYear1)?.replaceAll(',', ''));
    clonedFinAnswers.profitAfterTaxYear2 = Number(
      String(clonedFinAnswers.operatingProfitYear2)?.replaceAll(',', '')
    )
      + Number(String(clonedFinAnswers.financeIncomeYear2)?.replaceAll(',', ''))
      - Number(String(clonedFinAnswers.financeCostYear2)?.replaceAll(',', ''))
      - Number(String(clonedFinAnswers.taxYear2)?.replaceAll(',', ''));
    props.setFinAnswers((prev) => clonedFinAnswers);
  }
  const handleForce = (data, fileInfo) => {
    setAllInt(true);
    const transformedData = data?.map(e => ({ [`${e.title}Year1`]: e.year1, [`${e.title}Year2`]: e.year2 }));
    const singleObject = Object.assign({}, ...transformedData);
    const obj = {...singleObject};
    for (const key in obj) {
      if(!isNaN(obj[key])){
        if (obj.hasOwnProperty(key)) {
          obj[key] = parseFloat(obj[key].toFixed(2));
        }
      }else{
        setAllInt(false);
        return toast.error('Error! One of the entries of the uploaded file is not of type number. Please correct it and retry', {
          autoClose: 7000
        })
      }
      
    }
    
    if(allInt){
      props.setFinAnswers((prev) => ({ ...prev, ...singleObject }));
    }else{
      return;
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
  };
  return (
    <Styled onSubmit={nextHandler}>
      <section className="orr_ques_container">
        <div className="question">
          <p className="question_title">
            Kindly provide details of your financial statement below
          </p>
          <div className="uploadCsv">
            <p className="uploadCsv_title">Upload a csv of the report for autofill</p>
            <CSVReader
              cssClass="react-csv-input"
              onFileLoaded={handleForce}
              parserOptions={papaparseOptions}
            />
            <a href={`${process.env.PUBLIC_URL}/file/financial_template.csv`} download className="uploadCsv_download" target="_blank" rel="noopener noreferrer">Download csv template</a>
          </div>
          <div style={{ paddingBottom: '2rem' }}>
            <table className="styled-table">
              <thead>
                <tr>
                  <th className="values-pattern">
                    *Values are in
                    <select
                      required
                      className="values-pattern-select"
                      value={props?.finAnswers?.valueScale}
                      name="valueScale"
                      onChange={valueScaleHandler}
                    >
                      <option value="default" selected>
                        default
                      </option>
                      <option value="thousands">thousands</option>
                      <option value="millions">millions</option>
                    </select>
                  </th>
                  <th>{yearHandler()}</th>
                  <th>{yearHandler() - 1}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-60">Revenue (₦)</td>
                  <td>
                    <NumberFormat
                      name="revenueYear1"
                      value={props?.finAnswers?.revenueYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="revenueYear2"
                      value={props?.finAnswers?.revenueYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60">Cost of Sales (₦)</td>
                  <td>
                    <NumberFormat
                      name="costOfSalesYear1"
                      value={props?.finAnswers?.costOfSalesYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="costOfSalesYear2"
                      value={props?.finAnswers?.costOfSalesYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60 calculateBold">Gross Profit (₦)</td>
                  <td>
                    <NumberFormat
                      name="grossProfitYear1"
                      readOnly
                      className="readOnly"
                      value={props?.finAnswers?.grossProfitYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="grossProfitYear2"
                      readOnly
                      className="readOnly"
                      value={props?.finAnswers?.grossProfitYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60">Other Income (₦)</td>
                  <td>
                    <NumberFormat
                      name="otherIncomeYear1"
                      value={props?.finAnswers?.otherIncomeYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="otherIncomeYear2"
                      value={props?.finAnswers?.otherIncomeYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60">Operating Expenses (₦)</td>
                  <td>
                    <NumberFormat
                      name="operatingExpensesYear1"
                      value={props?.finAnswers?.operatingExpensesYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="operatingExpensesYear2"
                      value={props?.finAnswers?.operatingExpensesYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60">Depreciation & Amortisation (₦)</td>
                  <td>
                    <NumberFormat
                      name="depreciationAmortisationYear1"
                      value={props?.finAnswers?.depreciationAmortisationYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="depreciationAmortisationYear2"
                      value={props?.finAnswers?.depreciationAmortisationYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60 calculateBold">Operating Profit (₦)</td>
                  <td>
                    <NumberFormat
                      name="operatingProfitYear1"
                      value={props?.finAnswers?.operatingProfitYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      readOnly
                      className="readOnly"
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="operatingProfitYear2"
                      value={props?.finAnswers?.operatingProfitYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      readOnly
                      className="readOnly"
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60">Finance Income (₦)</td>
                  <td>
                    <NumberFormat
                      name="financeIncomeYear1"
                      value={props?.finAnswers?.financeIncomeYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="financeIncomeYear2"
                      value={props?.finAnswers?.financeIncomeYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60">Finance Cost (₦)</td>
                  <td>
                    <NumberFormat
                      name="financeCostYear1"
                      value={props?.finAnswers?.financeCostYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="financeCostYear2"
                      value={props?.finAnswers?.financeCostYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60">Tax Paid(₦)</td>
                  <td>
                    <NumberFormat
                      name="taxYear1"
                      value={props?.finAnswers?.taxYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="taxYear2"
                      value={props?.finAnswers?.taxYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="td-60 calculateBold">Profit After Tax (₦)</td>
                  <td>
                    <NumberFormat
                      name="profitAfterTaxYear1"
                      value={props?.finAnswers?.profitAfterTaxYear1}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      readOnly
                      className="readOnly"
                      onChange={onChangeHandler}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      name="profitAfterTaxYear2"
                      value={props?.finAnswers?.profitAfterTaxYear2}
                      required
                      autoComplete="off"
                      thousandSeparator
                      decimalScale={2}
                      readOnly
                      className="readOnly"
                      onChange={onChangeHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="indicationWrapper">
            5 of
            {' '}
            <strong style={{ fontSize: '14px' }}>10</strong>
          </p>
        </div>
      </section>
      <section className="orr_button_section">
        <PrevButton onClick={prevHandler} />
        <NextButton type="submit" />
      </section>
    </Styled>
  );
}
const Styled = styled.form`
  height: 100%;
  .values-pattern {
    font-weight: 400;
  }
  .readOnly {
    background: #f2f2f2;
  }
  .values-pattern-select {
    margin-left: 1rem;
    border: thin solid #f2f2f2;
    padding: 0.3rem;
  }
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    height: 100%;
    margin-bottom: 2rem;
    background-color: #fff;
    overflow: scroll;
    height: 45rem;
  }
  .question {
    position: relative;
    width: 100%;
    padding: 3rem 5%;
  }
  .question_title {
    text-align: center;
    margin-bottom: 1rem;
  }
  .styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 40rem;
    width: 100%;
    border: none;
  }

  .styled-table th,
  .styled-table td {
    font-size: 1.4rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border: none;
  }
  .styled-table td {
    font-weight: 300;
    font-size: 1.4rem;
  }
  .styled-table th {
    background-color: #f2f2f2;
  }
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .styled-table input {
    min-width: 10rem;
    width: 100%;
    border: thin solid #ddd;
    height: 2.5rem;
    text-align: right;
  }
  .td-60 {
  }
  .orr_button_section {
    display: flex;
    justify-content: space-between;
  }
  .calculateBold {
    font-weight: 500 !important;
  }
  .indicationWrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.2rem;
  }
  .uploadCsv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px
  }
  .uploadCsv_title{
    font-size: 1.2rem;
    font-weight: 600;
  }
  .uploadCsv_download{
    margin-left: 10px;
    text-decoration: underline;
    color: #000000;
    font-size: 1.2rem;
    font-weight: 600;
  }
`;
