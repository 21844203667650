import React, { useEffect, useState } from 'react';
import Header from 'components/Headers/Orr';
import styled from 'styled-components';
import Button from 'components/Buttons';
import { orrApis, businessApis } from 'apis';
import { useSelector } from 'react-redux';
import Spinner from 'components/OverLays/OverLaySpinner';
import { useNavigate } from 'react-router-dom';
import { grading } from 'constants/orr';
import ErrorHandler from 'helpers/errorHandler';

// API CALL FOR CREDIT SCORE
// if Good display Score click ok to load the score

export default function Grade() {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const { businessId } = auth.user;
  const [isLoading, setLoader] = useState(false);
  const [grades, setGrades] = useState({});
  const [isCreditError, setCreditError] = useState(false);

  useEffect(() => {
    const init = async () => {
      await getOrrScore({ businessId });
    };
    init();
    return () => {};
  }, [businessId]);

  const getOrrScore = async ({ businessId }) => {
    try {
      setLoader(true);
      const resOrrGrade = await orrApis.calculateFinalORRResponse(businessId);
      setGrades((prev) => ({ ...prev, ...resOrrGrade.data.data }));

      if (resOrrGrade.data.data?.isCustomerClean) {
        await businessApis.updateBusinessStatus({
          businessId,
          profileStatus: 'orr-finished',
          isEditable: 'false',
        });
      } else {
        await businessApis.updateBusinessStatus({
          businessId,
          profileStatus: 'orr-bad-credit',
          isEditable: 'false',
        });
      }
    } catch (error) {
      if (error?.message === 'Credit Bureau not available at the moment') {
        setCreditError(true);
        await businessApis.updateBusinessStatus({
          businessId,
          profileStatus: 'orr-financial',
          isEditable: 'false',
        });
      } else {
        ErrorHandler(error);
      }
    } finally {
      setLoader(false);
    }
  };
  function badCreditHandler() {
    navigate('/app/orr/dashboard', {
      replace: true,
    });
  }
  function homeHandler() {
    navigate('/app/dashboard', {
      replace: true,
    });
  }

  let render;

  if (grades?.isCustomerClean && grading[grades?.orrGrade] == 'red') {
    render = (
      <div className="score_box">
        <div className="traffic_light traffic_light_red" />
        <div className="flex flex-col items-center">
          <h4 className="score_item_title">Dear Customer,</h4>
          <p className="score_item_body">
            We are sorry, you do not meet the requirements to access a facility
            on Gazelle.
            <br />
            Our advisory team will be in touch with you regarding further steps.
            <br />
            Thank you for choosing our platform.
          </p>
        </div>
        <Button
          mode="1"
          title="View Business Evaluation Summary"
          onClick={summaryNavHandler}
        />
      </div>
    );
  } else if (
    grades?.isCustomerClean
    && grading[grades?.orrGrade] == 'yellow'
    && !isLoading
  ) {
    render = (
      <div className="score_box">
        <div className="traffic_light traffic_light_yellow" />
        <div className="flex flex-col items-center">
          <h4 className="score_item_title">Dear Customer,</h4>
          <p className="score_item_body">
            Thank you for choosing our platform.
            {' '}
            <br />
            {' '}
            You are okay to proceed.
            {' '}
            <br />
            {' '}
            Based on your score,
            {' '}
            <strong>{grades.business.businessName}</strong>
            {' '}
            is able to apply for
            a facility up to
            {' '}
            <strong>
              ₦
              {grades.disbursementAmount.toLocaleString()}
            </strong>
            {' '}
            on
            Gazelle.
            <br />
            (Terms and Conditions apply)
          </p>
        </div>
        <Button
          mode="1"
          title="View evaluation summary"
          onClick={summaryNavHandler}
        />
      </div>
    );
  } else if (
    grades?.isCustomerClean
    && grading[grades?.orrGrade] == 'green'
    && !isLoading
  ) {
    render = (
      <div className="score_box">
        <div className="traffic_light traffic_light_green" />
        <div className="flex flex-col items-center">
          <h4 className="score_item_title">Congratulations!!!</h4>
          <p className="score_item_body">
            You’re our ideal customer.
            {' '}
            <br />
            Thank you for choosing our platform.
            <br />
            Based on your score,
            {' '}
            <strong>
              {grades.business.businessName}
            </strong>
            {' '}
            is able to apply for a facility up to
            {' '}
            <strong>
              ₦
              {grades.disbursementAmount.toLocaleString()}
            </strong>
            {' '}
            on
            Gazelle.
            <br />
            (Terms and Conditions apply)
          </p>
        </div>
        <Button
          mode="1"
          title="View Business Evaluation Summary"
          onClick={summaryNavHandler}
        />
      </div>
    );
  } else if (grades?.isCustomerClean == false) {
    render = (
      <div className="score_box">
        <div>
          {/* <p className="score_item_title">Dear Customer,</p> */}
          <p className="score_item_body">
            <span className="score_item_title">Dear Customer,</span>
            {' '}
            we have reviewed your credit status
            through the credit reporting bureau, and bad credit
            has been reported. Kindly regularize your outstanding
            obligation and return to continue with your application
          </p>
        </div>
        <Button mode="1" title="Ok" onClick={() => badCreditHandler()} />
      </div>
    );
  } else if (isCreditError) {
    render = (
      <div className="score_box">
        <p className="score_item_title text-center">Credit Bureau Not Available at the moment. Our Team will be in touch</p>
        <Button
          mode="1"
          title="Save Exit"
          onClick={() => homeHandler()}
        />
      </div>
    );
  } else {
    render = (
      <div className="score_box">
        <p className="score_item_title">Error Retrieving Report.</p>
        <Button
          mode="1"
          title="Click to retry"
          onClick={() => getOrrScore({ businessId })}
        />
      </div>
    );
  }
  function summaryNavHandler() {
    navigate('/app/orr/summary', {
      state: { orrSummary: { ...grades } },
    });
  }
  return (
    <Styled>
      {isLoading ? <Spinner /> : ''}
      <Header />
      {!isLoading && <div className="box">{render}</div>}
    </Styled>
  );
}

const Styled = styled.form`
  background-color: #e5e5e5;
  .box {
    background: #e5e5e5;
    background-size: cover;
    background-position: center;
    height: calc(100vh - 4.4rem);
    display: flex;
    justify-content: center;
  }
  .score_box {
    background: #fff;
    width: 42.4rem;
    height: 32rem;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
  }
  .score_item_title {
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #004c4e;
  }
  .score_item_body {
    padding-bottom: 2rem;
    font-size: 1.5rem;
    text-align: center;
  }
  .traffic_light {
    background: radial-gradient(
      56.1% 56.1% at 50% 50%,
      #ffe9e9 0%,
      #ff0303 100%
    );
    transform: rotate(90deg);
    width: 4.1rem;
    height: 4.1rem;
    border-radius: 50%;
  }
  .traffic_light_red {
    background: radial-gradient(
      56.1% 56.1% at 50% 50%,
      #ffe9e9 0%,
      #ff0303 100%
    );
  }
  .traffic_light_yellow {
    background: radial-gradient(
      56.1% 56.1% at 50% 50%,
      #ffe9e9 0%,
      #ffe646 100%
    );
  }
  .traffic_light_green {
    background: radial-gradient(
      56.1% 56.1% at 50% 50%,
      #ffe9e9 0%,
      #00e846 100%
    );
  }
`;
