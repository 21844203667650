import Layout from 'components/Layouts/Default';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { BiMessageDetail } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { frrApis } from 'apis';
import errorHandler from 'helpers/errorHandler';
import Spinner from 'components/OverLays/OverLaySpinner';
import { useLocation } from 'react-router';
import { isNull } from 'lodash';
import moment from 'moment';

function FacilityEvaluation() {
  const navigate = useNavigate();
  const location = useLocation();
  const facilityId = location?.state?.facilityId;
  const [loader, setLoader] = useState(false);
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    const getFeeback = async () => {
      setLoader(true);
      try {
        const response = await frrApis.getFeedbackByFacilityId(facilityId);
        setFeedback(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoader(false);
      }
    };
    getFeeback();
  }, []);

  return (
    <Styled>
      <Layout
        activeBar="validation-feedback"
        headerId="header"
        tabsId="tabs"
        tabsBackgroundId="tabs-background"
      >
        <div className="Wrapper">
          <p id="heading">Validation Feedback</p>
          {feedback ? (
            <div
              onClick={() => {
                navigate('/app/frr/facility-feedback-response', {
                  state: {
                    responses: feedback?.responses,
                  },
                });
              }}
              id="forms-holder"
            >
              <div className="icon-holder">
                <BiMessageDetail size={20} color="#0E4657" />
                <div className="red-dot" />
              </div>
              <div id="message-holder">
                <div className="comment-holder">
                  <p id="feedback-comment">
                    {!feedback.reviews.r.reviewerComment || isNull(feedback)
                      ? 'Hello! You have pending facility questions to re-evaluate.'
                      : feedback.reviews.r.reviewerComment}
                  </p>
                  <p id="feedback-date">{ `${moment(feedback.reviews.r.addedOn).format('L').toString()} ${moment(feedback.reviews.r.addedOn).format('LT').toString()}` }</p>
                </div>
                <p id="feedback-status">
                  Status:
                  {' '}
                  <span style={{ fontWeight: '600', color: '#D45F36' }}>
                    {isNull(feedback)
                      ? ''
                      : feedback.reviews.r.taskStatus.toUpperCase()}
                  </span>
                  {' '}
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </Layout>

      {loader ? <Spinner /> : ''}
    </Styled>
  );
}

const Styled = styled.div`
  .Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 2rem 3rem 2rem;
    margin-top: 5rem;
    background-color: #e5e5e5;

    @media (max-width: 80rem) {
      flex-direction: column;
    }
  }

  #heading {
    font-weight: 500;
    font-size: 2rem;
    color: #0e4657;
  }

  #forms-holder {
    width: 80%;
    margin-top: 2rem;
    background: #ffffff;
    border-radius: 15px;
    padding: 3rem 3rem 5rem 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.3s;

    :hover {
      background-color: #eee;
      cursor: pointer;
    }
  }

  .icon-holder {
    background-color: #e8efee;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    position: relative;
  }

  .red-dot {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    background-color: red;
    border-radius: 50%;
  }

  #message-holder {
    width: 95%;
  }

  #feedback-status {
    margin-top: 2rem;
    color: #004c4e;
  }

  #feedback-comment {
    font-weight: 500;
    color: #004c4e;
  }

  .comment-holder {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  #feedback-date {
    color: #428a8c;
  }
`;

export default FacilityEvaluation;
