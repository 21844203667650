import { useState, useEffect } from 'react';
import businessApis from 'apis/business';
import { useSelector, useDispatch } from 'react-redux';
import * as BusinessActions from 'store/actions/business';
import _isEqual from 'lodash/isEqual';

export default function useBusinessProfile() {
  const {
    auth: {
      user: { passportId },
    },
    business: { businessProfiles },
  } = useSelector((state) => state);
  const [buzProfile, setBuzProfile] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const isActive = true;
    const getProfile = async () => {
      try {
        setLoader(true);
        const res = await businessApis.getBusinessByPassportId(passportId);
        const canUpdate = _isEqual(res.data.data, businessProfiles);
        if (res?.data?.data[0]?.passportId) {
          !canUpdate
            && dispatch(BusinessActions.BusinessSaveProfiles(res?.data?.data));
          setBuzProfile(res?.data?.data);
        }
      } catch (error) {
        setBuzProfile(businessProfiles);
      } finally {
        setLoader(false);
      }
    };
    isActive && getProfile();
    return () => {};
  }, [passportId, businessProfiles]);

  return [buzProfile, isLoading, setLoader];
}
