import React from 'react';
import styled from 'styled-components';
import { NextButton } from 'components/Buttons';
import { toast } from 'react-toastify';

const dataSet = [
  { id: 61, name: 'Yes', value: 'Yes' },
  { id: 62, name: 'No', value: 'No' },
];
export default function LastFinancialCheck(props) {
  const selectHandler = (selectedOption) => {
    props.setFinAnswers((prev) => ({ ...prev, lastFinancial: selectedOption }));
  };

  const nextHandler = () => {
    if (props?.finAnswers?.lastFinancial) {
      props.nextHandler();
    } else {
      toast.error('Invalid Input');
    }
  };
  return (
    <Styled>
      <section className="orr_ques_container">
        <div className="question">
          <p className="question_title">
            Do you have an audited financial statement (AFS)
          </p>
          <div className="select">
            {dataSet.map((elem, index) => (
              <button
                type="button"
                key={index}
                className={`select_item ${
                  props?.finAnswers?.lastFinancial == elem?.value
                    && 'selected_item'
                }`}
                onClick={() => selectHandler(elem.name)}
              >
                {elem.name}
              </button>
            ))}
          </div>
          <p className="indicationWrapper">
            1 of
            {' '}
            <strong style={{ fontSize: '14px' }}>10</strong>
          </p>
        </div>
      </section>
      <section className="orr_button_section">
        <div />
        {/* <PrevButton onClick={prevHandler} /> */}
        <NextButton onClick={nextHandler} />
      </section>
    </Styled>
  );
}
const Styled = styled.div`
  height: 100%;
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;

    height: 100%;
    margin-bottom: 2rem;
    background-color: #fff;
  }
  .question {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    justify-content: center;
    padding: 0 10%;
  }
  .question_title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .select {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .select_item {
    padding: 1rem 3rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    &:hover {
      border: 1px solid #00989c;
    }
  }
  .selected_item {
    background: #fff;
    border: 1px solid #00989c;
    color: #000;
  }
  .orr_button_section {
    display: flex;
    justify-content: space-between;
  }
  .indicationWrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 12px;
  }
`;
