import React from 'react';
import styled from 'styled-components';
import Button from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import PieChartGradient from 'components/PieChartGradient/PieChartGradient';
import { getOrrSumColor } from 'helpers/utils';
import PieChart from '../PieChart/PieChart';

export default function ORRStatus(props) {
  const navigate = useNavigate();
  const profileHandler = () => {
    navigate('/app/orr/dashboard');
  };
  return (
    <Styled className="application_box">
      {props?.orrGrade && (
        <>
          <div className="title">
            <p>ORR Score Summary</p>
          </div>
          <div className="info">
            {props?.isScoreLoading ? (
              <p className="text">loading...</p>
            ) : (
              <div className="chart_wrapper">
                <PieChartGradient
                  color1={getOrrSumColor(props?.orrGrade).color1}
                  color2={getOrrSumColor(props?.orrGrade).color2}
                  title="Total"
                  // text={`${props?.orrScore?.toFixed(1)}%`}
                  width={100}
                  percent={Math.floor(props?.orrScore)}
                />
              </div>
            )}
            {/* <div className="flex justify-center">
              <Button
                size="small"
                mode="7"
                onClick={profileHandler}
                title="View"
              />
            </div> */}
          </div>
        </>
      )}
    </Styled>
  );
}
const Styled = styled.div`
  font-size: 1.4rem;
  color: #004c4e;
  border-radius: 1rem;
  background-color: #fff;
  .title {
    font-weight: 600;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: #d1e8e2;
    padding: 1rem;
    font-size: 1.2rem;
    p {
      font-size: 1.2rem;
    }
  }
  .info {
    padding: 2rem;
    padding-top: 1rem;
    width: 100%;
  }
  .chart_wrapper {
    /* width: 70%; */
    background: #eafbf7;
    border: 1px solid #5fb19c;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }
`;
