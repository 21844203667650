import { useEffect, useState, useMemo } from 'react';
import FrrApis from 'apis/frr';
import { asyncWrapper } from 'helpers/asyncWrapper';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Table from 'components/Tables/CollateralsWithFeedBack';
import Layout from 'components/Layouts/BusinessEvaDashboard';

export default function CollateralsWithFeedBack() {
  const [collateralFeedBack, setCollateralFeedBack] = useState([]);
  const { business } = useSelector((state) => state);
  const navigate = useNavigate();
  const businessId = business?.businessProfiles[0]?.businessId;

  const navigationHandler = (collateralId, collateralTypeId) => {
    navigate(
      `/app/collateral/feedbacks/${collateralId}?collateralTypeId=${collateralTypeId}`,
      { replace: true }
    );
  };

  useEffect(() => {
    const getFeedBack = async () => {
      const [res, err] = await asyncWrapper(
        FrrApis.getFlaggedCollateralsByBusinessId({
          BusinessId: businessId,
        })
      );
      setCollateralFeedBack(res?.data?.data);
    };
    getFeedBack();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'S/N',
        accessor: (value, index) => index + 1,
      },
      {
        Header: 'ID',
        accessor: 'task.collateralId',
      },
      {
        Header: 'Collateral Type Name',
        accessor: 'type.collateralTypeName',
      },
      {
        Header: ' ',
        accessor: (value) => (
          <button
            onClick={() => navigationHandler(
              value.task.collateralId,
              value.type.collateralTypeId
            )}
            className="cursor-pointer"
          >
            View
          </button>
        ),
      },
    ],
    []
  );
  const data = [...collateralFeedBack];

  return (
    <Layout>
      <div className="py-12 px-10">
        <div className="mb-4">
          <p className="text-4xl font-medium text-[#004C4E]">
            Collaterals with Feedbacks
          </p>
        </div>
        <Table columns={columns} data={data} />
      </div>
    </Layout>
  );
}
