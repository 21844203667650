export const mergeAnswerWithQuestions = (
  questionsPool = [],
  allAnswers = []
) => {
  const allQuestions = questionsPool.filter((elem) => elem.questionType != 'T');
  for (let i = 0; i < allQuestions?.length; i++) {
    for (let j = 0; j < allAnswers?.length; j++) {
      const { questionId, questionType } = allQuestions[i];
      const ansQuestionId = allAnswers[j].questionId;
      const ansWeightAns = allAnswers[j].unWeightedAnswer;
      if (questionId === ansQuestionId && questionType == 'text') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'number') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'dropdown') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'long-text') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'list') {
        allQuestions[i].inputtedAnswer = ansWeightAns.split(',');
      } else if (
        questionId === ansQuestionId
        && questionType == 'contact-list'
      ) {
        allQuestions[i].inputtedAnswer = JSON.parse(ansWeightAns);
      } else if (questionId === ansQuestionId && questionType == 'star') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (
        questionId === ansQuestionId
        && questionType == 'financial-variable'
      ) {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'File') {
        allQuestions[i].inputtedAnswer = allAnswers[j].documentUrl;
      } else if (questionId === ansQuestionId && questionType == 'auditors') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'currency') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'date') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'single') {
        const getAnswer = allQuestions[i].answersList.filter(
          (elem) => elem.answerId == allAnswers[j].answerId
        );
        allQuestions[i].selectedAnswer = getAnswer[0];
      } else if (questionId === ansQuestionId && questionType == 'Multiple') {
        const parsedAnswers = allAnswers[j].answerId.split(',');
        const getAnswer = [];
        for (let k = 0; k < allQuestions[i].answersList?.length; k++) {
          for (let l = 0; l < parsedAnswers?.length; l++) {
            if (allQuestions[i].answersList[k].answerId == parsedAnswers[l]) {
              getAnswer.push(allQuestions[i].answersList[k]);
            }
          }
        }
        allQuestions[i].selectedAnswer = getAnswer;
      }
    }
  }

  const allPrimary = [];
  const allSecondary = [];
  for (let i = 0; i < allQuestions?.length; i++) {
    if (allQuestions[i]?.questionClass == 'P') {
      allPrimary.push(allQuestions[i]);
    } else if (allQuestions[i]?.questionClass == 'S') {
      allSecondary.push(allQuestions[i]);
    }
  }
  for (let i = 0; i < allPrimary?.length; i++) {
    const followUpId = allPrimary[i]?.followupId;
    if (followUpId > 0) {
      const getIndex = allSecondary.findIndex(
        (elem, j) => elem?.questionId == followUpId
      );
      allPrimary.splice(i + 1, 0, allSecondary[getIndex]);
    }
  }
  return allPrimary;
};

export const mergeAnswerWithQuestionsCollateral = (
  questionsPool = [],
  allAnswers = []
) => {
  const allQuestions = questionsPool?.length > 0
    && questionsPool?.filter((elem) => elem.questionType != 'T');

  for (let i = 0; i < allQuestions?.length; i++) {
    for (let j = 0; j < allAnswers?.length; j++) {
      const { questionId, questionType } = allQuestions[i];
      const ansQuestionId = allAnswers[j].questionId;
      const ansWeightAns = allAnswers[j].unWeightedAnswer;
      const { collateralResponseId } = allAnswers[j];
      allQuestions[i].collateralResponseId = collateralResponseId;

      if (questionId === ansQuestionId && questionType == 'text') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'number') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'dropdown') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'long-text') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'list') {
        allQuestions[i].inputtedAnswer = ansWeightAns.split(',');
      } else if (
        questionId === ansQuestionId
        && questionType == 'contact-list'
      ) {
        allQuestions[i].inputtedAnswer = JSON.parse(ansWeightAns);
      } else if (questionId === ansQuestionId && questionType == 'star') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'File') {
        allQuestions[i].inputtedAnswer = allAnswers[j].documentUrl;
      } else if (questionId === ansQuestionId && questionType == 'auditors') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'currency') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'date') {
        allQuestions[i].inputtedAnswer = ansWeightAns;
      } else if (questionId === ansQuestionId && questionType == 'single') {
        const getAnswer = allQuestions[i].collateralTypeAnswers.filter(
          (elem) => elem.answerId == allAnswers[j].answerId
        );
        allQuestions[i].selectedAnswer = getAnswer[0];
      } else if (questionId === ansQuestionId && questionType == 'Multiple') {
        const parsedAnswers = allAnswers[j].answerId.split(',');
        const getAnswer = [];
        for (let k = 0; k < allQuestions[i].collateralTypeAnswers?.length; k++) {
          for (let l = 0; l < parsedAnswers?.length; l++) {
            if (
              allQuestions[i].collateralTypeAnswers[k].answerId
              == parsedAnswers[l]
            ) {
              getAnswer.push(allQuestions[i].collateralTypeAnswers[k]);
            }
          }
        }
        allQuestions[i].selectedAnswer = getAnswer;
      }
    }
  }

  const allPrimary = [];
  const allSecondary = [];
  for (let i = 0; i < allQuestions?.length; i++) {
    if (allQuestions[i]?.questionClass == 'P') {
      allPrimary.push(allQuestions[i]);
    } else if (allQuestions[i]?.questionClass == 'S') {
      allSecondary.push(allQuestions[i]);
    }
  }
  for (let i = 0; i < allPrimary?.length; i++) {
    const followUpId = allPrimary[i]?.followUpId;
    if (followUpId > 0) {
      const getIndex = allSecondary.findIndex(
        (elem, j) => elem?.questionId == followUpId
      );
      allPrimary.splice(i + 1, 0, allSecondary[getIndex]);
    }
  }
  return allPrimary;
};

export const validateAnswer = (entry) => {
  const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const getNumber = entry?.inputtedAnswer;

  if (entry.questionType == 'Multiple') {
    return !!(entry?.selectedAnswer?.length != 0
      && entry?.selectedAnswer[0]?.answer != undefined);
  } if (entry.questionType == 'financial-variable') {
    const getNumberTwo = Number(entry?.inputtedAnswer.split(',').join(''));
    return typeof Number(getNumberTwo) === 'number';
  } if (entry.questionType == 'number') {
    return typeof Number(getNumber) === 'number';
  } if (entry.questionType == 'star') {
    return typeof Number(getNumber) === 'number';
  } if (entry.questionType == 'auditors') {
    return String(entry?.inputtedAnswer)?.length > 1;
  } if (entry.questionType == 'text') {
    return entry?.inputtedAnswer?.length > 0;
  } if (entry.questionType == 'list') {
    return entry?.inputtedAnswer?.length > 0;
  } if (entry.questionType == 'long-text') {
    return entry?.inputtedAnswer?.length > 0;
  } if (entry.questionType == 'single') {
    return entry?.selectedAnswer?.answer != undefined;
  } if (entry.questionType == 'File') {
    return entry?.inputtedAnswer?.length > 1;
  } if (entry.questionType == 'dropdown') {
    return entry?.selectedAnswer?.answer != undefined;
  } if (entry.questionType == 'numerical') {
    return typeof Number(getNumber) === 'number';
  } if (entry.questionType == 'currency') {
    return typeof Number(getNumber) === 'number';
  } if (entry.questionType == 'percentages') {
    return typeof Number(getNumber) === 'number';
  } if (entry.questionType == 'contact-list') {
    let response = true;
    response = entry?.inputtedAnswer?.length > 0;
    if (entry?.inputtedAnswer?.length > 0) {
      for (let i = 0; i < entry?.inputtedAnswer?.length; i++) {
        const currentObj = entry.inputtedAnswer[i];
        if (currentObj.name?.length == 0) {
          response = false;
        } else if (currentObj.phone?.length < 5) {
          response = false;
        } else if (!emailReg.test(String(currentObj.email).toLowerCase())) {
          response = false;
        } else if (currentObj.address?.length == 0) {
          response = false;
        } else if (currentObj.len_of_relationship?.length == 0) {
          response = false;
        }
      }
    }
    return response;
  } if (entry.questionType == 'date') {
    return entry?.inputtedAnswer?.length > 0;
  } if (entry.questionType == 'account-number') {
    return String(entry?.inputtedAnswer)?.length > 9;
  }

  return false;
};
