import React from 'react';
import styled from 'styled-components';

export default function privacyPolicy(props) {
  return (
    <Styled {...props}>
      <div className="policy-wrapper">
        <h1>Privacy Policy</h1>
        <br />
        <p>Date Updated: March 11, 2021</p>
        <br />
        <p>
          <strong>Welcome to the</strong>
          {' '}
          Gazelle By Sterling provided by
          Sterling Bank Plc (“We”, “Our”, “Us” or “Sterling”).
        </p>
        <br />
        <h3>Introduction</h3>
        <p>
          This page informs you of our policies regarding the collection, use,
          and disclosure of personal information when you use our Service and
          the choices you have associated with that data. We are committed to
          treating and using your personal information responsibly. Please read
          our privacy policy carefully to get a clear understanding of how we
          collect, use, protect or otherwise handle your Personal Information.
        </p>
        <br />
        <p>
          By using the Service, you agree to the collection and use of personal
          information in accordance with this policy.
        </p>
        <br />
        <h3>Data Collection and Use</h3>
        <p>
          We collect different types of information for various purposes to
          provide and improve our Service to you.
        </p>
        <br />
        <h3> Types of Data Collected</h3>
        <br />
        <h3> Information Submitted Through Our Platform or Website</h3>
        <p>
          Personal data, or personal information means any information provided
          by you with which you can be identified. When you sign up to the
          platform and provide details such as your name, ID (passport data
          page, national ID), biometric data/BVN, Business Tax Identification
          Number, your photograph, house address, email address, phone number,
          voice recordings (when you call our helpline), collateral information
          and other details which would enhance your Service experience:
        </p>
        <br />
        <h3> Details About Your Banking Activities and Transactions With Us</h3>
        <p>
          This includes information on any bank accounts you use, debit card
          numbers, financial history, information you provide to deliver payment
          initiation services and account information services regarding
          accounts you hold with other providers.
        </p>
        <br />
        <h3> Information on How You Use Your Device / Usage Data</h3>
        <p>
          We may also collect information that your browser sends whenever you
          visit our Service or when you access the Service by or through a
          mobile device ("Usage Data").
        </p>
        <br />
        <p>
          This Usage Data may include information such as your computer's
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Service that you visit, the time and date of
          your visit and the time spent on those pages amongst other diagnostic
          data.
        </p>
        <br />
        <p>
          When you access the Service by or through a mobile device this Usage
          Data may include information such as the type of mobile device you
          use, your mobile device unique ID, the IP address of your mobile
          device, your mobile operating system, the type of mobile Internet
          browser you use, unique device identifiers and other diagnostic data.
        </p>
        <br />
        <h3>Tracking &amp; Cookies Data</h3>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and hold certain information.
        </p>
        <p>
          Cookies are files with small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Tracking technologies also used are
          beacons, tags, and scripts to collect and track information and to
          improve and analyze our Service.
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. You can also refuse permissions to read
          your device data by the Platform. However, if you do not accept
          cookies on your browser or allow permissions on your mobile device,
          our online service experience to you may be degraded and you may not
          be able to use some portions of our Service.
        </p>
        <br />
        <h3>Examples of cookies we use:</h3>
        <ul>
          <li>
            <strong>Session Cookies.</strong>
            {' '}
            We use Session Cookies to operate
            our Service. Session cookies will expire at the end of your browser
            session and allow us to link your actions during that particular
            browser session.
          </li>
          <li>
            <strong>Preference Cookies.</strong>
            {' '}
            We use Preference Cookies to
            remember your preferences and actions, across multiple sites.
          </li>
          <li>
            <strong>Security Cookies.</strong>
            {' '}
            We use Security Cookies for
            security purposes.
          </li>
        </ul>
        <br />
        <h3> Information From Social Networks or Online Accounts</h3>
        <p>
          Information from any social media profiles or any accounts that you
          share with us.
        </p>
        <br />
        <h3> Other Information</h3>
        <p>
          We may also process specific categories of information for specific
          and limited purposes, such as detecting and preventing financial crime
          or making our services available to customers. We will only treat
          particular types of information where we have obtained your explicit
          consent or are otherwise lawfully permitted to do so.
        </p>
        <br />
        <h3> Use of Collected Data</h3>
        <p> We use the collected data for various purposes such as to:</p>
        <br />
        <ul>
          <li>
            Verify your identity when you access your profile on the service
          </li>
          <li>Respond to your requests and communicate with you </li>
          <li>Provide and maintain the Service</li>
          <li>
            Process service requests, design products and profile customers
          </li>
          <li>Notify you about changes to our Service</li>
          <li>
            Allow you to participate in interactive features of our Service when
            you choose to do so
          </li>
          <li>
            Provide customer care and support and for internal operations,
            including troubleshooting, data analysis, testing, research,
            security, fraud-detection, and account management
          </li>
          <li>
            Provide analysis or valuable information so that we can improve the
            Service
          </li>
          <li>Monitor the usage of the Service</li>
          <li>Detect, prevent and address technical issues</li>
          <li>
            Prevent fraud and enhance security of your account or our service
            platform.
          </li>
          <li>
            Comply with and enforcing applicable legal and regulatory
            requirements, relevant industry standards, contractual obligations
            and our policies
          </li>
          <li>
            Provide you with tailored content and marketing messages such as
            recommending services we believe you may be interested in
          </li>
        </ul>
        <br />
        <h3> Disclosure of Data</h3>
        <p>
          Your information, including Personal Data, may only be accessed by
          employees that have a business reason to do so. We do not share your
          information with third parties for marketing purposes or any other
          purpose, unless otherwise stated here or you have given your consent
        </p>
        <br />
        <p>
          We will take all steps reasonably necessary to ensure that your data
          is treated securely and in accordance with this Privacy Policy and no
          transfer of your Personal Data will take place to a third party or a
          country without notice to you and adequate controls in place including
          the security of your data and other personal information.
        </p>
        <br />
        <p>
          We may disclose your Personal Data in the good faith and belief that
          such action is necessary and will not disclose your Personal
          Information to anyone except in any of the following circumstances:
        </p>
        <br />
        <ul>
          <li>We have your consent to share the information;</li>
          <li>To comply with a legal obligation;</li>
          <li>
            To bring you improved service across our array of products and
            services, when permissible under relevant laws and regulations, by
            disclosing your personal information with Sterling Bank’s affiliated
            websites and businesses;
          </li>
          <li>To protect and defend the rights or property of the Bank;</li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service;
          </li>
          <li>
            To protect the personal safety of users of the Service or the
            public;
          </li>
          <li>To protect against legal liability;</li>
          <li>
            We find that your actions on our web sites violate any part of our
            Privacy Policy; or
          </li>
          <li>
            We provide same to trusted third parties who assist us in conducting
            our business. We require that these parties agree to process such
            information based on our instructions and in compliance with this
            privacy policy and any other appropriate confidentiality and
            security measures.
          </li>
        </ul>
        <br />
        <h3> Information From Locations Outside Nigeria</h3>
        <p>
          If you are located outside Nigeria and choose to provide information
          to us, please note that the data, including Personal Data, will be
          processed in Nigeria. Your consent to this Privacy Policy followed by
          your submission of such information represents your agreement to that
          transfer.
        </p>
        <br />
        <h3>How Long We Will Keep Your Information</h3>
        <p>
          We will keep your personal data for as long as we have a relationship
          with you, i.e. for as long as you use the service, remain validly
          subscribed to our newsletter or partake in surveys. Once that
          relationship with you has come to an end (e.g. following closure of
          your account) or cancellation of your subscription to the Service, we
          will not gather or collect any new information about you.
        </p>
        <br />
        <p>
          Once the relationship is over, we will only retain personal data for a
          period of time. This period will vary depending on the purpose for
          which we hold that information and subject to business or legal and
          regulatory requirements.
        </p>
        <br />
        <h3>Security of Data</h3>
        <p>
          The security of your data is important to us, but be aware that no
          method of transmission over the Internet, or method of electronic
          storage can guarantee 100% security at all times. While we strive to
          use commercially acceptable means to protect your Personal Data, we
          cannot guarantee its absolute security. You are responsible for
          securing and maintaining the privacy of your password and
          Account/profile registration information and verifying that the
          Personal Data we maintain about you is valid, accurate and up to date.
          If we receive instructions using your account login information, we
          will consider that you have authorized the instructions and process
          your instruction accordingly and without incurring any liability for
          doing so. We reserve the right, in our sole discretion, to refuse to
          provide our services, terminate your subscription, remove or edit
          content.
        </p>
        <br />
        <h3>Service Providers</h3>
        <p>
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), to provide the Service on our behalf,
          to perform specific Service-related roles or to assist us in analyzing
          how our Service is used.
        </p>
        <br />
        <p>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose outside of the service-specific need for which
          the data is required.
        </p>
        <br />
        <h3>Links to Other Sites</h3>
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <br />
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
          You should note the privacy policy, terms of use and security
          practices of the linked third-party website before providing any
          information on that website.
        </p>
        <br />
        <h3>Children's Privacy</h3>
        <p>
          Our Service does not address anyone under the age of 18 ("Children").
        </p>
        <br />
        <p>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Children has provided us with Personal Data,
          please contact us. If we become aware that we have erroneously
          collected Personal Data from children without verification of parental
          consent, we will promptly remove that information from our servers
        </p>
        <br />
        <h3>Information Update</h3>
        <p>
          You must notify us of any change in your Personal Information by
          updating your account profile through our Services. Any changes will
          affect only future uses of your Personal Information. Subject to
          applicable law, which might, from time to time, oblige us to store
          your Personal Information for a certain period of time, we will
          respect your wishes to correct inaccurate information. Otherwise, we
          will hold your Personal Information for as long as we believe it will
          help us achieve our objectives as detailed in this Privacy Policy.
        </p>
        <br />
        <h3> Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <br />
        <p>
          We may let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </p>
        <br />
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <br />
        <h3>Contact Us</h3>
        <p>
          We are committed to resolving your privacy complaints and concerns as
          quickly as possible and have procedures in place to help resolve any
          problems or complaints efficiently. If you have any questions about
          this Privacy Policy, please contact us at
          {' '}
          <a href="mailto:gazelle@sterling.ng">gazelle@sterling.ng</a>
          {' '}
          or call
          +234 904 343 9693.
        </p>
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  .policy-wrapper {
    max-width: 900px;
    margin: 0px auto;
    text-align: justify;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    @media (max-width: 479px) {
      padding: 20px 10px;
    }
  }
  p {
    font-size: 14px;
    line-height: 1.5;
  }
  strong {
    color: #004c4e;
  }
  h1 {
    color: #004c4e;
  }
  h3 {
    margin-bottom: 10px;
    color: #004c4e;
  }
  li {
    font-size: 14px;
    padding-bottom: 5px;
  }
  ul {
    padding-left: 10px;
    margin-left: 10px;
    list-style-type: circle;
  }
`;
