import React, { useState, useEffect } from 'react';
import { UpdateCollateralPayload } from 'store/actions/collateral';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import locationApis from 'apis/location';
import { pathOr } from 'ramda';
import { InputTypes } from './QuestionTypes';

export default function StateClassification(props) {
  const [statesArray, setStates] = useState([]);
  const [lgaArray, setLga] = useState([]);
  const [inputValues, setInput] = useState({ lgaId: null, stateId: null });
  const dispatch = useDispatch();
  useEffect(() => {
    const getStates = async () => {
      props?.setLoading(true);
      const res = await locationApis.getStates();
      props?.setLoading(false);
      setStates(pathOr([], ['data'], res));
    };
    getStates();
    return () => {};
  }, []);

  const stateHandler = async (e) => {
    const getState = statesArray.filter((x) => x?.id == e?.target?.value)[0];
    sessionStorage.setItem('_state', getState?.name);
    setInput((prev) => ({ ...prev, stateId: e.target.value }));
    props?.setLoading(true);
    const res = await locationApis.getLgaByStateId(e.target.value);
    props?.setLoading(false);
    setLga(res?.data);
  };
  const lgaHandler = (e) => {
    const getLga = lgaArray.filter((x) => x?.id == e?.target?.value)[0];
    sessionStorage.setItem('_lga', getLga?.name);
    setInput((prev) => ({ ...prev, lgaId: e?.target?.value }));
    dispatch(
      UpdateCollateralPayload({
        stateId: inputValues.stateId,
        lgaId: e.target.value ? Number(e.target.value) : null,
      })
    );
    sessionStorage.setItem('_currentPage', 'lgaId');
  };
  return (
    <Styled>
      <div>
        <p className="question_title">
          Kindly indicate the State and LGA of the Collateral
        </p>
        <div>
          <InputTypes
            questionType="dropdown"
            optionsArray={statesArray}
            optionKey="name"
            optionId="id"
            placeholder="--select state--"
            wrapperClass="mb_20"
            inputHandler={stateHandler}
          />
          {inputValues?.stateId && (
            <InputTypes
              questionType="dropdown"
              optionsArray={lgaArray}
              optionKey="name"
              optionId="id"
              placeholder="--select lga--"
              inputHandler={lgaHandler}
            />
          )}
        </div>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7%;
  width: 100%;
  overflow: hidden;
  height: 44.4rem;
  @media (max-width: 1280px) {
    padding: 0 5%;
  }
  .question_title {
    text-align: center;
    margin: 2rem 0;
  }
  .mb_20 {
    margin-bottom: 2rem;
  }
`;
