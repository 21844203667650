import { apiWithToken } from './axiosInterceptor';

const request = {

  getFacilityRequiredDocumentsList: (data) => {
    const method = 'get';
    const url = '/api/Frr/GetFacilityDocumentUploadById';
    return apiWithToken({ method, url, params: { SubPurposeId: data } });
  },
  getCollateralRequiredDocumentsList: (data) => {
    const method = 'get';
    const url = '/api/Frr/GetCollateralDocumentUploadById';
    return apiWithToken({ method, url, params: { CollateralTypeId: data } });
  },
  uploadFacilityDocument: (data, params) => {
    const method = 'post';
    const url = `/api/Frr/CreateFacilityUploadedDocuments?facilityId=${params}`;
    return apiWithToken({ method, url, data });
  },
  uploadCollateralDocument: (data, params) => {
    const method = 'post';
    const url = `/api/Frr/CreateCollateralUploadedDocuments?collateralId=${params}`;
    return apiWithToken({ method, url, data });
  },
  getFacilityUploadedDocuments: (data) => {
    const method = 'get';
    const url = '/api/Frr/GetFacilityUploadedDocumentsById';
    return apiWithToken({ method, url, params: { facilityId: data } });
  },
  getCollateralUploadedDocuments: (data) => {
    const method = 'get';
    const url = '/api/Frr/GetCollateralUploadedDocumentsById';
    return apiWithToken({ method, url, params: { collateralId: data } });
  },
  updateFacilityUploadedDocuments: (data, uploadId, facilityId) => {
    const method = 'put';
    const url = `/api/Frr/UpdateFacilityUploadedDocuments?uploadId=${uploadId}&facilityId=${facilityId}`;
    return apiWithToken({ method, url, data });
  },
  updateCollateralUploadedDocuments: (data, uploadId, collateralId) => {
    const method = 'put';
    const url = `/api/Frr/UpdateCollateralUploadedDocuments?uploadId=${uploadId}&collateralId=${collateralId}`;
    return apiWithToken({ method, url, data });
  },
  getUnsubmittedFacilityDocuments: (subpurposeId, facilityId) => {
    const method = 'get';
    const url = `/api/Frr/GetUnSubmittedFacilityDocumentsById?facilityId=${facilityId}&subpurposeId=${subpurposeId}`;
    return apiWithToken({ method, url });
  },
  getUnsubmittedCollateralDocuments: (collateralTypeId, collateralId) => {
    const method = 'get';
    const url = `/api/Frr/GetUnSubmittedCollateralDocumentsById?collateralId=${collateralId}&collateralTypeId=${collateralTypeId}`;
    return apiWithToken({ method, url });
  }

};
export default request;
