import { CircleProgress } from 'react-gradient-progress';

function PieChartGradient({
  color1, color2, title, percent, width
}) {
  return (
    <div className="pieChart">

      <CircleProgress
        strokeWidth={10}
        percentage={percent || 0}
        primaryColor={[color1, color2]}
        secondaryColor="rgb(223, 222, 222)"
        width={width}
      />

      <p style={{ fontSize: '12px', textAlign: 'center', color: '#00989C' }}>
        {title}
      </p>
    </div>
  );
}

export default PieChartGradient;
