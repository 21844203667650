import React from 'react';
import styled from 'styled-components';
import { NextButton, PrevButton } from 'components/Buttons';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';

export default function FinancialEndYearDate(props) {
  const onChangeHandler = (date) => {
    props.setFinAnswers((prev) => ({ ...prev, financialEndYearDate: date }));
  };
  const prevHandler = () => {
    props.prevHandler();
  };
  const nextHandler = () => {
    if (props?.finAnswers?.financialEndYearDate) {
      props.nextHandler();
    } else {
      toast.error('Invalid Input');
    }
  };
  return (
    <Styled>
      <section className="orr_ques_container">
        <div className="question">
          <p className="question_title">When was your Accounting Year-End</p>
          <div className="w-[200px] m-auto my-0">
            <DatePicker
              minDate={new Date(new Date() - 157784760000)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dateFormat="dd/MM/yyyy"
              dropdownMode="select"
              maxDate={new Date()}
              onChange={onChangeHandler}
              className="date_input"
              placeholderText={props.placeholder || 'dd/mm/yyyy'}
              selected={
                props?.finAnswers?.financialEndYearDate
                && new Date(props?.finAnswers?.financialEndYearDate)
              }
            />
          </div>
          <p className="indicationWrapper">
            4 of
            {' '}
            <strong style={{ fontSize: '14px' }}>10</strong>
          </p>
        </div>
      </section>
      <section className="orr_button_section">
        <PrevButton onClick={prevHandler} />
        <NextButton onClick={nextHandler} />
      </section>
    </Styled>
  );
}
const Styled = styled.div`
  height: 100%;
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    height: 100%;
    margin-bottom: 2rem;
    background-color: #fff;
  }
  .question {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding: 0 10%;
  }
  .question_title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .select {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .select_item {
    padding: 1rem 3rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    border: 2px solid #e5e5e5;
    border-radius: 5px;
    outline: none;
    &:hover {
      border: 2px solid #00989c;
    }
  }
  .selected_item {
    background: #fff;
    border: 2px solid #00989c;
    color: #000;
  }
  .orr_button_section {
    display: flex;
    justify-content: space-between;
  }
  .date_input {
    padding: 1rem 2rem;
    border: 1px solid #004c4e;
    border-radius: 5px;
    width: 100%;
  }
  .indicationWrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.2rem;
  }
`;
