import { useEffect, useState } from "react";

export const convertYearsToDays = (facilityDuration) => {
    let totalRepaymentDays = 0;

    const myYears = facilityDuration.years * 365;
    const myMonths = facilityDuration.months * 30;

    totalRepaymentDays = myMonths + myYears + facilityDuration.days;

    return totalRepaymentDays;
  };

export const convertDaysToYears = (filledData) => {
    const [convertedYears, setConvertedYears] = useState(0);
    const [convertedMonths, setConvertedMonths] = useState(0);
    const [convertedDays, setConvertedDays] = useState(0);

    // let convertedYear;
    // let monthsRemainder;
    // let convertedMonth;
    // let convertedDay;

    useEffect( () => {

        if (Object.keys(filledData).length > 0) {
            const totalDays = filledData.facilityDuration;
            const convertedYear = Math.floor(totalDays / 365);
            const monthsRemainder = Math.floor(totalDays % 365);
            const convertedMonth = Math.floor(monthsRemainder / 30);
            const convertedDay = Math.floor(monthsRemainder % 30);
    
            setConvertedYears(() => Math.floor(totalDays / 365));
            setConvertedMonths(() => Math.floor(monthsRemainder / 30));
            setConvertedDays(() => Math.floor(monthsRemainder % 30));
        }

    }, [])

    

    return [convertedYears, convertedMonths, convertedDays];
};