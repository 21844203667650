import React from 'react';
import ourFocusAgric from 'assets/images/home-ourfocus-agric.png';
import ourFocusEducation from 'assets/images/home-ourfocus-education.png';
import ourFocusHealth from 'assets/images/home-ourfocus-health.png';
import ourFocusPower from 'assets/images/home-ourfocus-power.png';
import ourFocusTransport from 'assets/images/home-ourfocus-transport.png';
import ourFocusSterlingHeart from 'assets/images/home-ourfocus-sterling_heart.png';
import sterlingTower from 'assets/images/sterling-tower.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import {
  BulbSvg, PasswordSvg, SmileSvg, DownRankSvg
} from 'theme/icons';
import Button from '../../Buttons';

export default function Home() {
  const navigate = useNavigate();
  const settingsOne = {
    // dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 4000,
    autoplaySpeed: 9000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settingsTwo = {
    infinite: true,
    slidesToShow: 5,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handlerStart = () => {
    navigate('/auth/register');
  };
  return (
    <Styled>
      <div className="section_hero">
        <DownRankSvg width="100px" className="down_sign" />
        <div className="section_hero_main">
          <h1>Smart Loans,</h1>
          <h1>Smart Life</h1>
          <p>
            With
            {' '}
            <strong>Gazelle</strong>
            {' '}
            , you will experience a smarter way to
            access finance and achieve a sustainable growth for your business.
          </p>
          <Button title="Get Started" mode="6" onClick={handlerStart} />
          <Link to="/auth/login">
            <Button
              title="Sign In"
              white
              style={{ marginLeft: '20px' }}
              mode="2"
            />
          </Link>
        </div>
        <Slider {...settingsOne}>
          <div>
            <img
              className="hero-slider-images"
              src="https://businesslending.azureedge.net/web/assets/images/slide_doctor_smiling_01.jpg"
              alt=""
            />
          </div>
          <div>
            <img
              className="hero-slider-images"
              src={sterlingTower}
              alt="An image of the sterling tower."
            />
          </div>
          <div>
            <img
              className="hero-slider-images"
              src="https://businesslending.azureedge.net/web/assets/images/slide_lady_in_a_lecture_hall_01.jpg"
              alt=""
            />
          </div>
          <div>
            <img
              className="hero-slider-images"
              src="https://businesslending.azureedge.net/web/assets/images/slide_vegetable_oil_production_line_01.jpg"
              alt=""
            />
          </div>
          <div>
            <img
              className="hero-slider-images"
              src="https://businesslending.azureedge.net/web/assets/images/slide_young_farmer_smiling_at_crops_01.jpg"
              alt=""
            />
          </div>
        </Slider>
      </div>
      <div className="section_about maxWidth-1440">
        <h3>About Gazelle</h3>
        <p>
          Gazelle is a lending platform that leverages digital technology and
          machine learning functionalities in providing credit to corporates. At
          Gazelle, our aim is to democratize business lending and empower
          customers to take control of their credit application.
        </p>
      </div>
      <div className="section_our_focus ">
        <h3>Our Focus Sectors</h3>
        <div className="section_our_focus-footer">
          <p>
            We finance every qualifying business. However, we prioritize
            businesses in sectors which are at the heart of our business
            interests and representation. These sectors are: Health, Education,
            Agriculture, Renewable Energy, Transport (H.E.A.R.T).
          </p>
        </div>
        <div className="section_our_focus_imageSlider">
          <Slider {...settingsTwo}>
            <>
              <img
                src={ourFocusSterlingHeart}
                alt=""
                className="focus-img-slider"
              />
              <div className="slider-msg-text">
                <p>H.E.A.R.T</p>
              </div>
            </>
            <>
              <img src={ourFocusHealth} alt="" className="focus-img-slider" />
              <div className="slider-msg-text">
                <p>Health</p>
              </div>
            </>
            <>
              <img
                src={ourFocusEducation}
                alt=""
                className="focus-img-slider"
              />
              <div className="slider-msg-text">
                <p>Education</p>
              </div>
            </>
            <>
              <img src={ourFocusAgric} alt="" className="focus-img-slider" />
              <div className="slider-msg-text">
                <p>Agriculture</p>
              </div>
            </>
            <>
              <img src={ourFocusPower} alt="" className="focus-img-slider" />
              <div className="slider-msg-text">
                <p>Renewable Energy</p>
              </div>
            </>
            <>
              <img
                src={ourFocusTransport}
                alt=""
                className="focus-img-slider"
              />
              <div className="slider-msg-text">
                <p>Transport</p>
              </div>
            </>
          </Slider>
        </div>
      </div>
      <div className="section_why_gazelle maxWidth-1440">
        <h3>Why Gazelle</h3>
        <div className="section_why_gazelle_row">
          <div className="why_gazelle_box">
            <div className="svg_wrapper">
              <SmileSvg width="25px" stroke="D1E8E2" />
            </div>
            <p>It is Convenient</p>
            <p>
              You don't need to visit a branch. You can apply for a business
              loan from anywhere in the world within 60 minutes!
            </p>
          </div>
          <div className="why_gazelle_box">
            <div className="svg_wrapper">
              <BulbSvg width="25px" stroke="D1E8E2" />
            </div>
            <p>It is Smart</p>
            <p>
              At Gazelle, we combine the power of intuitive questioning and AI
              functionalities to evaluate your business need(s) and potential(s)
              based on what truly matters to provide you with all your credit
              solutions!
            </p>
          </div>
          <div className="why_gazelle_box">
            <div className="svg_wrapper">
              <PasswordSvg width="25px" stroke="D1E8E2" />
            </div>
            <p>It is Secure</p>
            <p>
              We use strong encryption mechanisms to protect your private
              information and data.
            </p>
          </div>
        </div>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  .maxWidth-1440 {
    max-width: 1440px;
    margin: 0 auto;
  }
  .hero-slider-images {
    width: 100%;
    max-height: 700px;
    overflow: hidden;
    object-fit: cover;
    object-position: top;
  }
  .down_sign {
    position: absolute;
    left: 45%;
    bottom: 150px;
    z-index: 1;
    animation: up_down 500ms linear 500ms infinite alternate;
    @keyframes up_down {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(20px);
      }
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
  .section_hero {
    width: 100%;
    position: relative;
    .section_hero_main {
      position: absolute;
      top: 30%;
      left: 10%;
      /* background: rgba(255, 255, 255, 0.7); */
      max-width: 560px;
      border-radius: 10px;
      padding: 20px 30px;
      z-index: 1;
      color: #fff;
      h1 {
        font-size: 48px;
      }
      p {
        font-size: 14px;
        margin: 20px 0;
      }
      @media (max-width: 767px) {
        top: 5%;
        left: 2%;
        padding: 20px 10px;
        h1 {
          font-size: 20px;
        }
        p {
          font-size: 12px;
          margin: 20px 0;
        }
      }
    }
  }
  .slider-msg-text {
    text-align: center;
    font-size: 14px;
    background-color: #004c4e;
    color: #fff;
    padding: 8px 0;
    border-radius: 0px 0px 10px 10px;
  }
  .focus-img-slider {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
  }
  .section_our_focus {
    padding: 80px 0;
    background-color: #e5e5e5;
    h3 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 25px;
      color: #004c4e;
    }
    .section_our_focus_imageSlider {
      max-width: 1440px;
      margin: 0 auto;
      margin-top: 80px;
      margin-bottom: 80px;
      .slick-slide > div {
        margin: 0 10px;
      }
    }
    .section_our_focus-footer {
      margin: auto;
      max-width: 900px;
      padding: 0px 40px 0;
      text-align: center;
    }
  }
  .section_about {
    max-width: 900px;
    padding: 80px 10px;
    h3 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 25px;
      color: #004c4e;
    }
    p {
      text-align: center;
    }
  }
  .section_why_gazelle {
    background-color: #fff;
    padding: 80px 0;
    text-align: center;
    h3 {
      margin-bottom: 40px;
      font-size: 25px;
      color: #004c4e;
    }
    .section_why_gazelle_row {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .why_gazelle_box {
        position: relative;
        width: 300px;
        height: 220px;
        margin-bottom: 20px;
        border: none;
        background: #d1e8e2;
        padding: 30px 20px;
        border-radius: 5px;
        .svg_wrapper {
          background-color: #004c4e;
          position: absolute;
          top: -20px;
          border-radius: 5px;
          padding: 5px;
          left: 45%;
        }
        p:nth-of-type(1) {
          color: #004c4e;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 500;
        }
        p:nth-of-type(2) {
          font-size: 14px;
        }
        @media (max-width: 900px) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
