import { grading } from 'constants/orr';
/**
 *
 *
 * @export
 * @param {*} String ('21-May-2014')
 * @returns String "21/05/2014"
 */
export function parseDate(s) {
  const months = {
    jan: 0,
    feb: 1,
    mar: 2,
    apr: 3,
    may: 4,
    jun: 5,
    jul: 6,
    aug: 7,
    sep: 8,
    oct: 9,
    nov: 10,
    dec: 11,
  };
  const p = s.split('-');
  return new Date(p[2], months[p[1].toLowerCase()], p[0])
    .toISOString()
    .split('T')[0];
}
export const getOrrSumColor = (orrGrade = 'CCC') => {
  let gradeColor;
  if (grading[orrGrade] == 'red') {
    gradeColor = { color1: '#FF0303', color2: '#821009' };
  } else if (grading[orrGrade] == 'yellow') {
    gradeColor = { color1: '#FFE646', color2: '#827009' };
  } else if (grading[orrGrade] == 'green') {
    gradeColor = { color1: '#00D615', color2: '#004C4E' };
  }
  return gradeColor;
};
