import Layout from 'components/Layouts/Default';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Buttons';
import Spinner from 'components/OverLays/OverLaySpinner';
import { FeedBackMailRedNotifySvg } from 'theme/icons';
import {
  useEffect, useState, useLayoutEffect, useMemo
} from 'react';
import frrApis from 'apis/frr';
import errorHandler from 'helpers/errorHandler';
import { store } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import Modal from './modal';
import ContinueIcon from '../../assets/svgs/continue-icon.svg';
import ProgressIcon from '../../assets/svgs/progress-icon.svg';
import ReviewIcon from '../../assets/svgs/review-icon.svg';
import EditIcon from '../../assets/svgs/edit-icon.svg';
import UploadIcon from '../../assets/svgs/upload-icon.svg';
import UntieIcon from '../../assets/svgs/untie icon.svg';
import useFacilityFeedBack from 'hooks/useFacilityFeedback';
import PaginateTable from '../Tables/index';
import { formatDate } from 'helpers/dateFormat';
import { useDirectorateStatus } from 'hooks/useDirectorateStatus';
import { AccountOpeningV2 } from 'apis';

function FacilityEvaluation() {
  const navigate = useNavigate();
  const [mappingLoader, setMappingLoader] = useState();
  const [feedback, setFeedback] = useState(false);
  const [orrScore, setOrrScore] = useState(null);
  const [facilityDetailsLoader, setFacilityDetailsLoader] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFacilityType, setSelectedFacilityType] = useState('');
  const [selectedFacilityDetail, setSelectedFacilityDetail] = useState({});
  const [collaterals, setCollaterals] = useState([]);
  const [collateralLoader, setCollateralLoader] = useState(false);
  const [mapDetail, setMapDetail] = useState({
    collateralId: 0,
    facilityId: 0,
  });
  const [mapLoader, setMapLoader] = useState(false);
  const [mappedCollaterals, setMappedCollaterals] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currenciesLoader, setCurrenciesLoader] = useState(false);
  const [unMapLoader, setUnMapLoader] = useState(false);
  const [unmapped, setUnmapped] = useState(false);
  const [mapped, setMapped] = useState(false);
  const [facilityFeedBackAvailable] = useFacilityFeedBack();
  const [selectedFeedbackFacility, setSelectedFeedbackFacility] = useState(null);

  const [ directorateMapped, directorateCheckLoading ] = useDirectorateStatus();

  const feedBackHandler = () => {
    // navigate('/app/orr/feedback');
  };

  const dispatch = useDispatch();
  const { businessId } = store.getState().business.businessProfiles[0];

  const facilityId = useSelector(
    (state) => state?.facilityAnsweredQuestions?.facilityId.facilityId
  );

  useEffect(() => {
    if (Object.keys(selectedFacilityDetail).length > 0) {
      setMapDetail({
        ...mapDetail,
        facilityId: Number(selectedFacilityDetail.facilityId),
      });
    }
  }, [
    Object.keys(selectedFacilityDetail).length,
    selectedFacilityDetail.facilityId,
  ]);

  useEffect(() => {
    const getORRSummaryScore = async () => {
      try {
        const response = await frrApis.getORRSummaryScore(businessId);
        setOrrScore(Math.round(response?.data?.data?.orrScore));
      } catch (error) {
        const message = errorHandler(error);
      }
    };

    getORRSummaryScore();
  }, []);

  useEffect(() => {
    const getCollaterals = async () => {
      setCollateralLoader(true);
      try {
        const response = await frrApis.getCollateralDetails(businessId);
        setCollaterals(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setCollateralLoader(false);
      }
    };

    getCollaterals();
  }, []);

  useEffect(() => {
    const getFacilityDetails = async () => {
      setFacilityDetailsLoader(true);

      try {
        const response = await frrApis.getFacilityDetailsByBusinessId(
          businessId
        );
        setFacilities(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setFacilityDetailsLoader(false);
      }
    };

    getFacilityDetails();
  }, []);

  const mapFacilityToCollateral = async () => {
    setMapLoader(true);
    try {
      const response = await frrApis.mapFacilityToCollateral(mapDetail);
    } catch (error) {
      errorHandler(error);
    } finally {
      setMapLoader(false);
      setMapped(!mapped);
    }
  };

  useEffect(() => {
    const getCollateralsMappedToFacility = async () => {
      // setMappedCollateralLoader(true);
      try {
        const response = await frrApis.getCollateralsMappedToFacility(
          selectedFacilityDetail.facilityId
        );
        setMappedCollaterals(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
      }
    };

    // This will give the selected facility time to populate before executing.

    if (Object.keys(selectedFacilityDetail).length > 0) {
      getCollateralsMappedToFacility();
    }
  }, [mapped, unmapped, Object.keys(selectedFacilityDetail).length]);

  useLayoutEffect(() => {
    const getCurrencies = async () => {
      setCurrenciesLoader(true);

      try {
        const response = await frrApis.getCurrencyCodes();
        setCurrencies(response.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setCurrenciesLoader(false);
      }
    };

    if (currencies?.length < 1) {
      getCurrencies();
    }
  }, []);

  const unMapCollateralHandler = async (data) => {
    setUnMapLoader(true);
    try {
      const response = await frrApis.unMapCollateralFromFacility(data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setUnMapLoader(false);
      setUnmapped(!unmapped);
    }
  };

  useLayoutEffect(() => {}, [mappedCollaterals.length]);

  const collateralNavigationHandler = (id) => {
    navigate(`/app/collateral-details/${id}`, { replace: true });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'S/N',
        accessor: (value, index) => index + 1,
      },
      {
        Header: 'ID',
        accessor: 'd.facilityId',
      },
      {
        Header: 'Facility Type',
        accessor: 'd.facilityType.facilityTypeName',
        style: {
          width: '40%',
        },
      },
      {
        Header: 'Amount',
        accessor: 'd',
        Cell: ({ value }) => {
          let facilityCurrency;

          for (const currency of currencies) {
            if (currency.currencyId === value.currencyId) {
              facilityCurrency = currency.currencyCode;
            }
          }

          return  <CurrencyFormat
                    value={value.facilityAmount}
                    displayType="text"
                    thousandSeparator
                    prefix={facilityCurrency}
                  />
        },
      },
      {
        Header: 'Start Date',
        accessor: 'd.addedOn',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Status',
        accessor: ( value ) => {
          return <div>
            <p
              style={
                value.d.shouldUpdate == true
                  ? { color: 'red' }
                  : { color: '#00989C' }
              }
              className='status-item'
            >
              {value.d.shouldUpdate == true
                ? 'FLAGGED'
                : value.d.status.toUpperCase()}
            </p>
          </div>
        }
      },
      {
        Header: 'Action',
        accessor: (value) => (
          <button
            onClick={() => {
              setOpenModal(true);
              setSelectedFacilityType(
                value.t.facilityTypeName
              );
              setSelectedFacilityDetail(value.d);
              setMappedCollaterals(!mappedCollaterals);
            }}
            className="table-btn"
          >
            View
          </button>
        ),
      },
    ],
    []
  );
  const data = [...facilities];

  const mapDirectorate = async () => {
    setMappingLoader(true);
    try{
      const response = await AccountOpeningV2.mapAccountDirectorate(businessId);
      if(response?.data?.status){
        dispatch({ type: 'EMPTY_FRR_QUESTIONS' });
        dispatch({
          type: 'UPDATE_FACILITY_QUESTIONS_ANSWERED',
          payload: {},
        });
        dispatch({
          type: 'UPDATE_FACILITY_ID',
          payload: {
            facilityId: {},
            fromDashboard: false,
          },
        });
        navigate("/app/frr/facilityquestions");
      }
    }catch(error){
      errorHandler(error);
    }finally{
      setMappingLoader(false);
    }
  }

  const newFacilityHandler = () => {
    if(directorateMapped !== null && directorateMapped){
      mapDirectorate()
    }
  }

  return (
    <Styled>
      <Layout
        activeBar="facility-evaluation"
        headerId="header"
        tabsId="tabs"
        tabsBackgroundId="tabs-background"
        orrScore={orrScore}
      >
        <div className="Wrapper">
          <section className="main-content">
            <div className="main-content-inner">
              <div className="Validation-top-holder">
                <h3 className="main-content-title">Facility Evaluation</h3>
              </div>

              <div className="top-main-inner">
                <div className="Validation-holder">
                  { !facilityFeedBackAvailable.status || Object.keys(facilityFeedBackAvailable.status).length < 1 ? (
                    ''
                  ) : (
                    <div className="validation-container">
                      <div className="validation-title">
                        <p>Validation Feedback</p>
                      </div>

                      <div className="validation-body">
                        <div className="validation-item">
                          <figure>
                            <FeedBackMailRedNotifySvg />
                          </figure>
                          <p>
                            Dear Customer, you have pending validation feedback.
                            Kindly treat as soon as possible to complete your
                            evaluation.
                          </p>
                        </div>
                        <div className="validation-button">
                          <Button
                            onClick={() => {
                              setFeedback(true);
                            }}
                            mode="1"
                            title="Treat Now"
                            id="treat-button"
                            style={{ padding: '.5rem 1rem' }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                </div>

                {/* <div className="ORR-summary">
                  <ORRStatus {...grades} isScoreLoading={isScoreLoading} />
                </div> */}
              </div>
            </div>
          </section>

          <section>
            
            <div>
              <PaginateTable tableTag = {'Requested Facilities'} columns={columns} data={data}>
                <button
                  onClick={() => {
                    newFacilityHandler();
                  }}
                  className="New-facility-button"
                >
                  New Facility Request
                </button>
              </PaginateTable>
            </div>

          </section>
          {/* <section className="Facilities-summary">
            <div className="Facilities-container">

              <div className="Facilities-holder">
                <Link to="/app/frr/facilityquestions">
                  <button
                    onClick={() => {
                      dispatch({ type: 'EMPTY_FRR_QUESTIONS' });
                      dispatch({
                        type: 'UPDATE_FACILITY_QUESTIONS_ANSWERED',
                        payload: {},
                      });
                      dispatch({
                        type: 'UPDATE_FACILITY_ID',
                        payload: {
                          facilityId: {},
                          fromDashboard: false,
                        },
                      });
                    }}
                    className="New-facility-button"
                  >
                    New Facility Request
                  </button>
                </Link>
                <div className="Facilites-header-holder">
                  <p id="facilites-header">Requested Facilities</p>
                </div>
                <div className="Facility-item-header">
                  <div className="Facility-id">
                    <p className="Facilities-header">ID</p>
                  </div>
                  <div className="Facility-type">
                    <p className="Facilities-header">Facility Type</p>
                  </div>
                  <div className="Facility-amount">
                    <p className="Facilities-header">Amount</p>
                  </div>
                  <div className="Facility-start-date">
                    <p className="Facilities-header">Start Date</p>
                  </div>
                  <div className="Facility-status">
                    <p className="Facilities-header">Status</p>
                  </div>
                </div>

                <div>
                  {facilities?.length > 0
                    && facilities.map((facility) => {
                      const amount = facility.d.facilityAmount;
                      const addedDate = moment(facility.d.addedOn)
                        .format('L')
                        .toString();
                      let facilityCurrency;

                      for (const currency of currencies) {
                        if (currency.currencyId === facility.d.currencyId) {
                          facilityCurrency = currency.currencyCode;
                        }
                      }

                      return (
                        <div
                          onClick={() => {
                            setOpenModal(true);
                            setSelectedFacilityType(
                              facility.t.facilityTypeName
                            );
                            setSelectedFacilityDetail(facility.d);
                            setMappedCollaterals(!mappedCollaterals);
                          }}
                          key={facility.d.facilityId}
                          className="Facility"
                        >
                          <div className="Facility-id">
                            <p>
                              {String(facility.d.facilityId).length > 10
                                ? `${String(facility.d.facilityId).slice(0, 10)
                                }...`
                                : facility.d.facilityId}
                            </p>
                          </div>
                          <div className="Facility-type">
                            <p>{facility.t.facilityTypeName}</p>
                          </div>

                          <div className="Facility-amount">
                            <p>
                              <CurrencyFormat
                                value={amount}
                                displayType="text"
                                thousandSeparator
                                prefix={facilityCurrency}
                              />
                            </p>
                          </div>

                          <div className="Facility-start-date">
                            <p>{addedDate}</p>
                          </div>

                          <div className="Facility-status">
                            <p
                              style={
                                facility.d.shouldUpdate == true
                                  ? { color: 'red' }
                                  : { color: '#00989C' }
                              }
                            >
                              {facility.d.shouldUpdate == true
                                ? 'FLAGGED'
                                : facility.d.status.toUpperCase()}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

            </div>
          </section> */}
        </div>
      </Layout>

      {openModal ? (
        <Modal
          id="action-modal"
          iconId="close-icon"
          header=""
          onClick={() => {
            setOpenModal(false);
            setSelectedFacilityDetail({});
          }}
        >
          <div id="modal-facility-type">
            <h4>{selectedFacilityType}</h4>
          </div>

          <div className="Action-buttons-holder">
            <div
              onClick={
                selectedFacilityDetail?.status == 'initial'
                || selectedFacilityDetail?.status == 'initiated'
                  ? () => {
                    dispatch({
                      type: 'UPDATE_FACILITY_QUESTIONS_ANSWERED',
                      payload: selectedFacilityDetail,
                    });
                    navigate('/app/frr/facilityquestions');
                  }
                  : ''
              }
              className={
                selectedFacilityDetail?.status == 'initial'
                || selectedFacilityDetail?.status == 'initiated'
                  ? 'Action-button'
                  : 'Action-button-disabled'
              }
            >
              <img
                src={ContinueIcon}
                className={
                  selectedFacilityDetail?.status == 'initial'
                  || selectedFacilityDetail?.status == 'initiated'
                    ? 'Action-button-icon'
                    : 'Action-button-icon-disabled'
                }
                alt="An icon"
              />
              <button>Continue</button>
            </div>
            <div
              onClick={
                selectedFacilityDetail?.status == 'initial'
                || selectedFacilityDetail?.status == 'initiated'
                || selectedFacilityDetail.shouldUpdate == true
                  ? () => {
                    dispatch({
                      type: 'UPDATE_FACILITY_QUESTIONS_ANSWERED',
                      payload: selectedFacilityDetail,
                    });
                    navigate('/app/frr/facilityquestions');
                  }
                  : ''
              }
              className={
                selectedFacilityDetail?.status == 'initial'
                || selectedFacilityDetail?.status == 'initiated'
                || selectedFacilityDetail.shouldUpdate == true
                  ? 'Action-button'
                  : 'Action-button-disabled'
              }
            >
              <img
                src={EditIcon}
                className={
                  selectedFacilityDetail?.status == 'initial'
                  || selectedFacilityDetail?.status == 'initiated'
                  || selectedFacilityDetail.shouldUpdate == true
                    ? 'Action-button-icon'
                    : 'Action-button-icon-disabled'
                }
                alt="An icon"
              />
              <button>Edit</button>
            </div>
            <div
              onClick={() => {
                navigate('/app/orr/upload');
              }}
              className="Action-button"
            >
              <img
                src={UploadIcon}
                className="Action-button-icon"
                alt="An icon"
              />
              <button>Document Upload</button>
            </div>
          </div>
          <div className="Action-buttons-holder">
            <div
              onClick={() => {
                navigate('/app/orr/tracker');
              }}
              className="Action-button"
            >
              <img
                src={ProgressIcon}
                className="Action-button-icon"
                alt="An icon"
              />
              <button>Track Progress</button>
            </div>
            <div
              onClick={() => {
                dispatch({
                  type: 'UPDATE_FACILITY_ID',
                  payload: {
                    facilityId: selectedFacilityDetail.facilityId,
                    fromDashboard: true,
                  },
                });
                navigate('/app/frr/facilityquestionsreview');
              }}
              className="Action-button"
            >
              <img
                src={ReviewIcon}
                className="Action-button-icon"
                alt="An icon"
              />
              <button>Review Questions</button>
            </div>
          </div>

          <div id="divider" />

          <div className="Tie-collateral-main">
            <p className="Tie-collateral-header">
              Select collateral to pledge to this facility
            </p>
            <div className="Tie-collateral-select-holder">
              <select
                onChange={(e) => {
                  setMapDetail({
                    ...mapDetail,
                    collateralId: Number(e.target.value),
                  });
                }}
                className="Dropdown"
              >
                <option value="">Select option</option>
                {collaterals?.length > 0
                  && collaterals.map((collateral) => (
                    <option
                      key={collateral.c.collateralId}
                      value={collateral.c.collateralId}
                    >
                      {`${collateral.t.collateralTypeName
                      }_${
                        collateral.c.collateralId}`}
                    </option>
                  ))}
              </select>

              <Button
                onClick={mapFacilityToCollateral}
                title="Pledge"
                id={
                  selectedFacilityDetail?.status !== 'initiated'
                    ? 'tie-button-disabled'
                    : 'tie-button'
                }
                disabled={
                  mapDetail.collateralId === ''
                  || mapDetail.collateralId === 0
                  || mapDetail.facilityId === 0
                  || selectedFacilityDetail?.status !== 'initiated'
                }
              />
            </div>
            {/* <p className="Tie-collateral-warning">
              *Only approved collaterals are shown
            </p> */}
          </div>

          <div className="Tied-collaterals-main">
            <div className="Tied-collaterals-header">
              <div className="collateral-click">
                <div className="Collaterals">
                  <p>Collaterals tied to this facility</p>
                </div>
                <div className="Collateral-status">
                  <p>Status</p>
                </div>
              </div>
              <div className="Collateral-action">
                <p>Action</p>
              </div>
            </div>
            <div>
              {mappedCollaterals?.length > 0
                && mappedCollaterals.map((collateral) => (
                  <div
                    key={collateral.c.collateralId}
                    className="Collateral-children-holder"
                  >
                    <div
                      onClick={() => {
                        collateralNavigationHandler(collateral.c.collateralId);
                      }}
                      className="collateral-click"
                    >
                      <div className="Collaterals">
                        <p className="Collateral-type">
                          {`${collateral.c.collateralType.collateralTypeName
                          }_${
                            collateral.c.collateralId}`}
                        </p>
                      </div>
                      <div className="Collateral-status">
                        <p className="Collateral-status-text">Status</p>
                      </div>
                    </div>
                    <div className="Collateral-action">
                      <button
                        onClick={() => {
                          unMapCollateralHandler(collateral.m.mappingId);
                          // setOpenModal(false);
                        }}
                        className={
                          selectedFacilityDetail?.status !== 'initiated'
                            ? 'Collateral-action-button-disabled'
                            : 'Collateral-action-button'
                        }
                        disabled={
                          selectedFacilityDetail?.status !== 'initiated'
                        }
                      >
                        Unpledge
                        <span>
                          <img src={UntieIcon} />
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Modal>
      ) : (
        ''
      )}

      {facilityDetailsLoader
      || mapLoader
      || collateralLoader
      || unMapLoader
      || currenciesLoader
      || mappingLoader
      || directorateCheckLoading ? (
          <Spinner />
        ) : (
          ''
        )}

      {feedback ? (
        <Modal
          onClick={() => {
            setFeedback(false);
          }}
          id="modal"
          iconId="modal-icon"
        >
          <p id="modal-header">Select facility</p>
          <div style={{ marginTop: '2rem' }}>
            <select
              onChange={(e) => {
                setSelectedFeedbackFacility(e.target.value);
              }}
              className="Dropdown"
            >
              <option value="">Select option</option>
              {facilityFeedBackAvailable
                && Object.entries(facilityFeedBackAvailable.status).map(([key, value]) => (
                  <option key={key} value={key}>
                    {`${value}_${key}`}
                  </option>
                ))}
            </select>
          </div>

          <div id="modal-buttons-holder">
            <Button
              onClick={() => {
                setFeedback(false);
              }}
              title="Cancel"
              id="no-button"
            />

            <Button
              onClick={() => {
                navigate('/app/frr/facility-feedback', {
                  state: { facilityId: selectedFeedbackFacility },
                });
              }}
              title="Proceed"
              id="yes-button"
            />
          </div>
        </Modal>
      ) : (
        ''
      )}
    </Styled>
  );
}

const Styled = styled.div`
  .Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 2rem 3rem 2rem;
    margin-top: 5rem;
    background-color: #e5e5e5;

    @media (max-width: 80rem) {
      flex-direction: column;
    }
  }

  .main-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    background-color: #e5e5e5;

    @media (max-width: 80rem) {
      width: 100%;
    }
  }

  .main-content-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .main-content-title {
    color: #004c4e;
    padding: 0.8rem;
    font-weight: 500;
  }

  .action-buttons-container {
    background-color: #fff;
    padding: 4rem 3rem;
    border-radius: 1rem;
    @media (max-width: 90rem) {
      padding: 2rem 1.5rem;
    }
  }
  .action-button-icon {
    stroke: grey;
    fill: grey;
  }

  .validation-title {
    background: #d1e8e2;
    color: #004c4e;
    padding: 0.8rem;
    padding-left: 2rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    p {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .validation-container {
    margin-bottom: 4rem;
    background: #fff;
    padding-bottom: 1rem;
    border-radius: 8px;
    width: 60%;
  }

  .validation-body {
    padding: 1rem;
  }

  .validation-item {
    display: flex;
    p {
      font-size: 1.4rem;
      margin-left: 1rem;
      color: #d45f36;
    }
  }

  .validation-button {
    display: flex;
    justify-content: flex-end;
  }

  .validation-empty {
    text-align: center;
    padding: 0.5rem;
    font-size: 1.4rem;
  }

  .validation-loader {
    padding: 0.5rem;
    text-align: center;
  }

  .Validation-top-holder {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1rem;
  }

  .New-facility-button {
    padding: 1rem 3rem;
    background: linear-gradient(95.26deg, #00989C -30.18%, #004C4E 100%);
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    border: 2px solid #d1e8e2;
    margin: 2rem 0;
    cursor: pointer;
    color: white;
    transition: 0.5s ease-in-out;
  }

  .New-facility-button:hover {
    border: 2px solid #00989C;
  }

  .Facilities-summary {
    width: 100%;
    margin-top: 5rem;
  }

  .Faciities-container {
    width: 100%;
  }

  .Facilities-holder {
    width: 100;
    border: 5px solid #d1e8e2;
    border-radius: 10px;
    background-color: white;
    padding: 1rem 2rem;
    // min-height: 50rem;
  }

  .Facilites-header-holder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid #d1e8e2;

    p {
      color: grey;
      font-size: 1rem;
    }
  }

  #facilites-header {
    color: #004c4e;
    font-size: 1.5rem;
    margin-right: 0.5rem;
    font-weight: 500;
  }

  .Facility-item-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
    border-bottom: 1px solid #d1e8e2;
  }

  .Summary-validation-title {
    background: #d1e8e2;
    color: #004c4e;
    padding: 0.8rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    p {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .Chart-holder {
    width: 80%;
    padding: 0.5rem;
    background-color: #eafbf7;
    border: 1px solid #5fb19c;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Chart-holder-main {
    background-color: white;
    padding: 1rem 2rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #orr-summary-button {
    color: white;
    margin-top: 1rem;
    font-size: 1rem;
    padding: 1rem 2rem;
    width: 80%;
    background: linear-gradient(90deg, #00989c, #004c4e);
  }

  .Validation-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  #action-modal {
    background-color: white;
    padding: 2rem;
    width: 50%;
    max-height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }

  #close-icon {
    color: black;
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  .Action-buttons-holder {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .Action-button {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2fdfa;
    border: 2px solid #d1e8e2;
    box-sizing: border-box;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 2rem 0;
    cursor: pointer;

    button {
      font-size: 14px;
      color: #004c4e;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    :not(:last-child) {
      margin-right: 5%;
    }
  }

  .Action-button-disabled {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2fdfa;
    border: 2px solid #d1e8e2;
    box-sizing: border-box;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 2rem 0;
    cursor: not-allowed;

    button {
      font-size: 14px;
      color: #004c4e;
      opacity: 0.4;
      border: none;
      background-color: transparent;
      cursor: not-allowed;
    }

    :not(:last-child) {
      margin-right: 5%;
    }
  }

  .Action-button-icon {
    margin-bottom: 1rem;
    color: #00989c;
    border-radius: 1px;
  }

  .Action-button-icon-disabled {
    margin-bottom: 1rem;
    color: #00989c;
    border-radius: 1px;
    opacity: 0.4;
    cursor: not-allowed;
  }

  .Facility {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    // position: relative;
    padding: 1rem 0;
    cursor: pointer;
    transition: 0.3s;
    border-bottom: 1px solid #d1e8e2;

    p {
      font-size: 1.5rem;
    }

    :hover {
      background-color: #f7faf9;
    }
  }

  .Facility-amount {
    width: 20%;
    display: flex;
    justify-content: flex-start;

    p {
      text-align: start;
    }
  }

  .Facility-type {
    width: 35%;
    display: flex;
    justify-content: flex-start;

    p {
      text-align: start;
    }
  }

  #modal-facility-type {
    width: 90%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;

    h4 {
      color: #505050;
    }
  }

  .Facility-status {
    width: 15%;
    display: flex;
    justify-content: flex-start;
  }

  .Facilities-header {
    color: #004c4e;
    font-weight: 500;
  }

  .Facility-maturity-date,
  .Facility-next-payment-date {
    width: 25%;
  }

  .Facility-start-date {
    width: 15%;
  }

  .Facility-id {
    width: 15%;
  }

  #divider {
    width: 90%;
    height: 1px;
    margin: 2rem 0;
    background-color: #d1e8e2;
  }

  .Tie-collateral-main {
    background-color: #f6f6f6;
    padding: 2rem;
    border-radius: 6px;
    width: 90%;
    margin-top: 1rem;
  }

  .Tie-collateral-warning {
    font-size: 1rem;
  }

  .Tie-collateral-header {
    font-size: 1.5rem;
  }

  .Tie-collateral-select-holder {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #tie-button {
    color: #d9b08c;
    padding: 1.2rem 3rem;
    background: linear-gradient(90deg, #00989c, #004c4e);
    cursor: pointer;
    border-radius: 5px;
    border: none;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  #tie-button-disabled {
    color: #d9b08c;
    padding: 1.2rem 3rem;
    background: linear-gradient(90deg, #00989c, #004c4e);
    cursor: pointer;
    border-radius: 5px;
    border: none;
    margin-left: 2rem;
    margin-top: 1rem;
    opacity: 0.4;
  }

  .Tied-collaterals-main {
    margin-top: 2rem;
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .Tied-collaterals-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #d1e8e2;
    padding-bottom: 0.5rem;

    div {
      p {
        color: #004c4e;
      }
    }
  }

  .Collaterals {
    width: 50%;
  }

  .Collateral-status {
    width: 30%;
  }

  .Collateral-action {
    width: 20%;
  }

  #tabs {
    position: fixed;
    top: 6.8rem;
    bottom: -5rem;
    left: 1rem;
    min-height: 100vh;
  }

  #header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }

  .Dropdown {
    padding: 1rem 15rem 1rem 1rem;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid grey;
    margin-top: 1rem;
    outline: none;
    background-color: #eee;
  }

  .Collateral-children-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    border-bottom: 1px solid #d1e8e2;
    padding-bottom: 0.5rem;
    cursor: pointer;
  }

  .collateral-click{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    cursor: pointer;
    width: 80%;
  }

  .Collateral-type {
    font-size: 1.2rem;
  }

  .Collateral-status-text {
    font-size: 1.2rem;
  }

  .Collateral-action-button {
    background-color: #fff5ed;
    padding: 1rem;
    cursor: pointer;
    width: 60%;
    font-size: 1.2rem;
    color: #d45f36;
    display: flex;
    align-item: center;
    justify-content: center;
    border: none;
  }

  #tabs-background {
    height: auto;
    margin-top: 4rem;
  }

  .Collateral-action-button-disabled {
    background-color: #fff5ed;
    padding: 1rem;
    cursor: pointer;
    width: 60%;
    font-size: 1.2rem;
    color: #d45f36;
    display: flex;
    align-item: center;
    justify-content: center;
    border: none;
    opacity: 0.4;
  }

  #tabs-background {
    height: auto;
    margin-top: 4rem;
  }

  #treat-button {
    background: linear-gradient(90deg, #00989c, #004c4e);
    color: white;
    font-weigth: lighter;
  }

  #modal {
    background-color: white;
    padding: 2rem 4rem 4rem 4rem;
  }

  #modal-icon {
    color: black;
  }

  #no-button {
    border: none;
    margin-right: 2rem;
    background-color: transparent;
    color: #004c4e;
    cursor: pointer;
  }

  #yes-button {
    padding: 1rem 3rem;
    border: none;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-radius: 6px;
    color: #d9b08c;
    cursor: pointer;
  }

  #modal-buttons-holder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 5rem;
  }

  .top-main-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    transition: 0.3s;
  }

  .table-btn{
    border: none;
    cursor: pointer;
    color: white;
    padding: .3rem 1rem;
    background: linear-gradient(95.26deg, #00989C -30.18%, #004C4E 100%);
    border-radius: 5px;
    font-size: 1.2rem;
  }

  .status-item{
    font-size: unset;
  }
`;

export default FacilityEvaluation;
