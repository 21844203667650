export const base64 = (file) => new Promise((resolve, reject) => {
  const fileReader = new FileReader();

  fileReader.readAsDataURL(file[0]);
  fileReader.onload = () => {
    resolve(fileReader.result);
  };
  fileReader.onerror = (error) => {
    reject(error);
  };
});
