import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import Button from 'components/Buttons';
import { FeedBackMailRedNotifySvg } from 'theme/icons';
import { useNavigate } from 'react-router-dom';
import { pathOr } from 'ramda';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { asyncWrapper } from 'helpers/asyncWrapper';
import useCollateralFeedBack from 'hooks/useCollateralFeedback';
import FrrApis from 'apis/frr';
import CollateralTable from '../../Tables/CollateralDashboard';
import { formatDate } from '../../../helpers/dateFormat';
import Layout from '../../Layouts/BusinessEvaDashboard';

export default function Dashboard() {
  const navigate = useNavigate();
  const { business } = useSelector((state) => state);
  const [collateralList, setCollateralList] = useState([]);
  const [collateralFeedBack] = useCollateralFeedBack();

  const businessId = business?.businessProfiles[0]?.businessId;

  const navigationHandler = (id) => {
    navigate(`/app/collateral-details/${id}`, { replace: true });
  };

  useQuery(
    ['getCollateralByBusinessId', businessId],
    () => FrrApis.getCollateralByBusinessId(businessId),
    {
      onSuccess: (res) => {
        setCollateralList(pathOr([], ['data', 'data'], res)?.reverse());
      },
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: 'S/N',
        accessor: (value, index) => index + 1,
      },
      {
        Header: 'ID',
        accessor: 'c.collateralId',
      },
      {
        Header: 'Collateral Type',
        accessor: 't.collateralTypeName',
        style: {
          width: '40%',
        },
      },
      {
        Header: 'Value',
        accessor: 'c.securityValue',
      },
      {
        Header: 'Date',
        accessor: 'c.addedOn',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Status',
        accessor: 'c.status',
        Cell: ({ value }) => value || 'Pending',
      },
      {
        Header: ' ',
        accessor: (value) => (
          <button
            onClick={() => navigationHandler(value.c.collateralId)}
            className="table-btn"
          >
            View
          </button>
        ),
      },
    ],
    []
  );
  const data = [...collateralList];

  return (
    <Styled>
      <Layout activeBar="collateral-evaluation">
        <div className="wrapper">
          <section className="title-wrapper">
            <h4 className="title">Collateral Evaluation</h4>
          </section>
          <section className="validation-container">
            <div className="validation-title">
              <p>Validation Feedback</p>
            </div>
            {/* yet to be implemented */}
            {collateralFeedBack.status ? (
              <div className="validation-body">
                <div className="validation-item">
                  <figure>
                    <FeedBackMailRedNotifySvg />
                  </figure>
                  <p>
                    Dear Customer, you have pending validation feedback. Kindly
                    treat as soon as possible to complete your evaluation.
                  </p>
                </div>
                <div className="validation-button">
                  <Button
                    onClick={() => navigate('/app/collateral/collaterals-with-feedbacks')}
                    mode="1"
                    title="Treat Now"
                    style={{ padding: '.5rem 1rem' }}
                  />
                </div>
              </div>
            ) : (
              <div className="validation-empty">
                <p>No Feedback</p>
              </div>
            )}
          </section>
          <section className="table-wrapper">
            <p className="table-heading">Existing Collateral</p>
          </section>
          <section>
            <CollateralTable columns={columns} data={data} />
          </section>
        </div>
      </Layout>
    </Styled>
  );
}
const Styled = styled.div`
  .wrapper {
    padding: 0 2rem;
  }
  .title {
    color: #004c4e;
    padding: 0.8rem;
    margin-top: 5rem;
    font-weight: 500;
  }
  .validation-container {
    margin-bottom: 4rem;
    background: #fff;
    padding-bottom: 1rem;
    border-radius: 8px;
    max-width: 65rem;
  }
  .validation-title {
    background: #d1e8e2;
    color: #004c4e;
    padding: 0.8rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    p {
      font-size: 1.4rem;
    }
  }
  .validation-body {
    padding: 1rem;
  }
  .empty-orr {
    background: #fff;
    padding: 1rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .validation-item {
    display: flex;
    p {
      font-size: 1.4rem;
      margin-left: 1rem;
      color: #d45f36;
    }
  }
  .validation-button {
    display: flex;
    justify-content: flex-end;
  }
  .validation-empty {
    text-align: center;
    padding: 0.5rem;
    p {
      font-size: 1.4rem;
    }
  }
  .validation-loader {
    padding: 0.5rem;
    text-align: center;
  }

  .table-wrapper {
    margin: 2rem 0 1rem;
  }
  .table-heading {
    ${({ theme }) => theme.typography.headingBody3}
  }
  .table-btn {
    font-size: 1.2rem;
    padding: 0.2rem 0.4rem;
    cursor: pointer;
    border: thin solid #00989c;
    background: transparent;
  }
`;
