import { useEffect, useState } from 'react';
import { verificationApis } from 'apis';
import { useSelector } from 'react-redux';

export default function useOrrFeedBack() {
  const { business } = useSelector((state) => state);
  const [isFeedBackAvailable, setFeedBackAvailable] = useState({
    status: false,
    loader: false,
  });

  const businessId = business?.businessProfiles[0].businessId;

  useEffect(() => {
    const mounted = true;
    const getFeedBack = async () => {
      try {
        setFeedBackAvailable((prev) => ({ ...prev, loader: true }));
        const res = await verificationApis.getFlaggedQuestions({
          businessId,
        });
        mounted
          && setFeedBackAvailable((prev) => ({
            ...prev,
            status: res?.data?.data?.length > 0,
          }));
      } catch (error) {
      } finally {
        mounted && setFeedBackAvailable((prev) => ({ ...prev, loader: false }));
      }
    };
    getFeedBack();
    return () => {};
  }, [businessId]);
  return [isFeedBackAvailable];
}
