import React, { useState } from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';
import { NextButton, PrevButton } from 'components/Buttons';

export default function DirectorsForm2(props) {
  const [directorsList, setDirectorsList] = useState(props.directors);
  const switchHandler = (e, i) => {
    const cloneData = [...directorsList];
    cloneData[i].politicalOfficeHolder = !cloneData[i].politicalOfficeHolder;
    setDirectorsList(cloneData);
  };
  const nextHandler = () => {
    props.nextHandler(props.next);
  };
  const prevHandler = () => {
    props.setDirectors(directorsList);
    props.prevHandler(props.prev);
  };
  return (
    <Styled>
      <div className="orr_ques_container">
        <div className="orr_ques_container_wrapper">
          <div className="title">
            <p>
              Do any of the Directors hold any political office or have held any
              in the past or do any of the Directors have affiliations with any
              political party or are in a relationship with any public office
              holder?
            </p>
          </div>
          <section className="ques_section">
            {directorsList.map((elem, i) => (
              <div key={i} className="ques_wrapper">
                <div className="ques_title_wrapper">
                  <span>{i + 1}</span>
                  <p>{`${elem.firstName} ${elem.lastName}`}</p>
                </div>
                <Switch
                  checked={elem.politicalOfficeHolder}
                  onChange={(e) => switchHandler(e, i)}
                  onColor="#d1e8e2"
                  onHandleColor="#fff"
                  offHandleColor="#808080"
                  offColor="#EEEEEE"
                  handleDiameter={25}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  checkedIcon={<div className="checkedIcon">Yes</div>}
                  uncheckedIcon={<div className="uncheckedIcon">No</div>}
                />
              </div>
            ))}
          </section>
        </div>
      </div>
      <div className="orr_button_section">
        <PrevButton onClick={prevHandler} />
        <NextButton onClick={nextHandler} />
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  padding-top: 8rem;
  .uncheckedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.2rem;
    padding-right: 0.2rem;
    font-weight: 600;
    color: #808080;
  }
  .checkedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.2rem;
    padding-right: 0.2rem;
    font-weight: 600;
    color: #004c4e;
  }
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    height: 100%;
    overflow-y: scroll;
    height: 52rem;
    margin-bottom: 2rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .orr_ques_container_wrapper {
    padding: 2rem 4rem;
  }
  .title {
    text-align: center;
  }
  .ques_title_wrapper {
    display: flex;
    font-size: 1.4rem;
    span {
      margin-right: 1rem;
    }
  }
  .ques_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .ques_section {
    padding: 2rem 0;
  }
  .orr_button_section {
    display: flex;
    justify-content: space-between;
  }
`;
