import React from 'react';
import { BiChevronsRight, BiChevronsLeft } from 'react-icons/bi';
import classes from './button.module.scss';

function Spin() {
  return (
    <div className={classes.lds_ring}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
function Spin2() {
  return (
    <div className={`${classes.lds_ring} ${classes.lds_ring2}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default function Index(props) {
  const clickHandler = () => {
    if (!props.loader || !props.disabled) {
      props?.onClick && props?.onClick();
    }
  };

  let classGroup;

  switch (props.mode) {
    case '1':
      classGroup = `${classes.buttonOne} ${
        props.loader ? classes.spinner : ''
      } ${props?.disabled ? classes.disabled : ''} ${props.className}`;
      break;
    case '2':
      classGroup = `${classes.buttonTwo} ${
        props.loader ? classes.spinner : ''
      } ${props?.disabled ? classes.disabled : ''} ${props.className}`;
      break;
    case '3':
      classGroup = `${classes.buttonThree} ${
        props.loader ? classes.spinner : ''
      } ${props?.disabled ? classes.disabled : ''} ${props.className}`;
      break;
    case '4':
      classGroup = `${classes.buttonFour} ${
        props.loader ? classes.spinner : ''
      } ${props?.disabled ? classes.disabled : ''} ${props.className}`;
      break;
    case '5':
      classGroup = `${classes.buttonFive} ${
        props.loader ? classes.spinner : ''
      } ${props?.disabled ? classes.disabled : ''} ${props.className}`;
      break;
    case '6':
      classGroup = `${classes.buttonSix} ${
        props.loader ? classes.spinner : ''
      } ${props?.disabled ? classes.disabled : ''} ${props.className}`;
      break;
    case '7':
      classGroup = `${classes.buttonSeven} ${
        props.loader ? classes.spinner : ''
      } ${props?.disabled ? classes.disabled : ''} ${
        props?.size == 'small' ? classes.smallBtn : ''
      } ${props.className}`;
      break;
    default:
      classGroup = '';
      break;
  }

  return (
    <button
      id={props.id}
      onClick={clickHandler}
      type={props?.type || 'button'}
      className={classGroup}
      style={props.style}
      disabled={props?.disabled}
      loader={props?.loader ? String(props?.loader) : undefined}
      // || props.disabled
    >
      {props.children}
      {
        !props.loader && props.title === 'Save & Proceed'
          ? (
            <p style={{ marginRight: '.5rem' }}>
              {props.loader ? '' : props.title}
            </p>
          )
          : ''
      }
      { props.loader || props.title === 'Save & Proceed' ? '' : props.title }
      {!props.loader && props.title === 'Save & Proceed' ? <BiChevronsRight size={10} viewBox="5 7 14 10" /> : ''}
      {props.loader ? <Spin /> : null}
      {/* <img src = { props.icon } alt=''/> */}
    </button>
  );
}

export function NextButton(props) {
  const clickHandler = () => {
    if (!props?.loader || !props?.disabled) {
      props?.onClick && props?.onClick();
    }
  };

  return (
    <button
      id={props.id}
      className={`${classes.nextBtn} ${
        props.loader && classes.nextBtn_spinner
      } ${props.disabled && classes.disabled}`}
      onClick={clickHandler}
      disabled={props?.disabled}
      loader={props?.loader ? String(props?.loader) : undefined}
      type={props?.type || 'button'}
      style={props.style}
    >
      {props.loader && <Spin2 />}
      {!props.loader && (
        <p className={classes.nextBtn_text_right}>
          {props.title ? props.title : 'Next'}
        </p>
      )}
      {!props.loader && <BiChevronsRight size={10} viewBox="5 7 14 10" />}
    </button>
  );
}

export function PrevButton(props) {
  const clickHandler = () => {
    if (!props?.loader || !props?.disabled) {
      props?.onClick && props?.onClick();
    }
  };
  return (
    <button
      id={props.id}
      className={`${classes.nextBtn} ${
        props.loader && classes.nextBtn_spinner
      } ${props.disabled && classes.disabled}`}
      onClick={clickHandler}
      disabled={props?.disabled}
      loader={props?.loader ? String(props?.loader) : undefined}
      type={props?.type || 'button'}
    >
      {props.loader && <Spin2 />}
      {!props.loader && <BiChevronsLeft size={10} viewBox="5 7 14 10" />}
      {!props.loader && (
        <p className={classes.nextBtn_text_left}>
          {props.title ? props.title : 'Back'}
        </p>
      )}
    </button>
  );
}
