import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { adminUserApis } from 'apis';
import { useSelector } from 'react-redux';
import Button from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import ErrorHandler from 'helpers/errorHandler';
import Form4 from './PrimaryPromoter';
import Form3 from './PrincipalOfficersForm3';
import Form2 from './PrincipalOfficersForm2';
import Form1 from './PrincipalOfficersForm';

export default function Index() {
  const navigate = useNavigate();
  const { auth, business } = useSelector((state) => state);
  const [currentPage, setCurrentPage] = useState('Form1');
  const [principalOfficers, setPrincipalOfficers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [orrStatus, setOrrStatus] = useState(false);
  const subSectorId = business?.businessProfiles[0]?.subSectorId;
  const businessId = auth?.user?.businessId;

  useEffect(() => {
    let isMounted = true;
    const getGenericAnswers = async () => {
      try {
        const res = await adminUserApis.getGenericAnswers(businessId);
        if (res.data.status) {
          isMounted
            && setPrincipalOfficers((prev) => res?.data?.data?.map((e) => ({ ...e?.p })));
        }
      } catch (error) {
        ErrorHandler(error);
      } finally {
        isMounted && setLoading(true);
      }
    };
    const checkIfOrrIsOk = async () => {
      try {
        const res = await adminUserApis.getQuesAnsBySubSecId(subSectorId);
        const allQuestions = res.data.data.filter(
          (elem) => elem.questionType != 'T'
        );
        let isMgtOk;
        let isBusOk = 0;
        for (const question of allQuestions) {
          if (question.categoryName == 'Management') {
            isMgtOk = 1;
          }
          if (question.categoryName == 'Business') {
            isBusOk = 1;
          }
        }
        const isOrrOk = isMgtOk + isBusOk;

        if (isOrrOk == 2) {
          setOrrStatus(true);
          await getGenericAnswers();
        }
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoading(true);
      }
    };

    const init = async () => {
      await checkIfOrrIsOk();
    };
    init();
    return () => {
      isMounted = false;
    };
  }, [subSectorId, businessId]);

  const nextQuestionHandler = (dest) => {
    setCurrentPage(dest);
  };
  const prevQuestionHandler = (dest) => {
    setCurrentPage(dest);
  };
  let render;
  if (currentPage == 'Form1') {
    render = (
      <Form1
        authStore={auth}
        nextHandler={nextQuestionHandler}
        directors={principalOfficers}
        setDirectors={setPrincipalOfficers}
        next="Form2"
      />
    );
  } else if (currentPage == 'Form2') {
    render = (
      <Form2
        directors={principalOfficers}
        setDirectors={setPrincipalOfficers}
        nextHandler={nextQuestionHandler}
        prevHandler={prevQuestionHandler}
        next="Form3"
        prev="Form1"
      />
    );
  } else if (currentPage == 'Form3') {
    render = (
      <Form3
        directors={principalOfficers}
        setDirectors={setPrincipalOfficers}
        nextHandler={nextQuestionHandler}
        prevHandler={prevQuestionHandler}
        next="Form4"
        prev="Form2"
      />
    );
  } else if (currentPage == 'Form4') {
    render = (
      <Form4
        businessId={auth?.user?.businessId}
        authStore={auth}
        directors={principalOfficers}
        setDirectors={setPrincipalOfficers}
        nextHandler={nextQuestionHandler}
        prevHandler={prevQuestionHandler}
        next="Form5"
        prev="Form3"
      />
    );
  }
  // question Loading && VALidation====> SHOW
  //
  // check if orr is active done
  // check the questions are ok. should be check before coming to this page
  return (
    <Styled>
      {isLoading ? (
        <>
          {orrStatus ? (
            render
          ) : (
            <div className="empty_orr_container">
              <p className="empty_orr_err_msg">
                Questions for this sector are currently unavailable. Try again
                later.
              </p>
              <Button
                mode="1"
                title="Click to Return to Dashboard"
                onClick={() => navigate('/app/dashboard')}
              />
            </div>
          )}
        </>
      ) : (
        <div className="overlay__inner">
          <div className="overlay__content">
            <span className="spinner" />
          </div>
        </div>
      )}
    </Styled>
  );
}
const Styled = styled.div`
  position: relative;
  height: 100%;
  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .spinner {
    width: 7.5rem;
    height: 7.5rem;
    display: inline-block;
    border-width: 3px;
    border-color: rgba(255, 255, 255);
    border-top-color: #004c4e;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  .empty_orr_container {
    padding: 3rem;
  }
  .empty_orr_err_msg {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;
