import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from 'components/FormikInputs/Input';
import Button from 'components/Buttons';
import { useSelector } from 'react-redux';
import { firsApis } from 'apis';
import { toast } from 'react-toastify';
import ErrorHandler from 'helpers/errorHandler';
import styled from 'styled-components';

export default function OtpValidation(props) {
  const [loader, setLoader] = useState(false);
  const { signUpForm } = useSelector((state) => state);
  const validationSchema = yup.object({
    accountOtp: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only Numbers')
      .min(6, 'Must be exactly 6 digits')
      .max(6, 'Must be exactly 6 digits'),
    // .required('Otp is required'),
  });
  const formik = useFormik({
    initialValues: {
      accountOtp: '',
    },
    validationSchema,
    onSubmit: (values) => {
      verifyAccOtp(values.accountOtp);
    },
  });
  const verifyAccOtp = async (otp) => {
    try {
      setLoader(true);
      // ****otp validation
      const response = await firsApis.validateEmailPhoneOtp({
        phone: signUpForm.accDetails.TEL_NUM,
        token: otp,
        email: signUpForm?.accDetails?.email || '',
      });
      if (response.data.data == 'Invalid phone token!') {
        toast.error('Invalid Token');
        return setLoader(false);
      } if (
        response.data.status == false
        || response.data.data == 'Invalid token!'
      ) {
        toast.error('Invalid Token');
        return setLoader(false);
      }
      props.onValidateCompanyHandler();
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoader(false);
    }
  };
  const resendOtp = async () => {
    setLoader(true);
    try {
      const response = await firsApis.generateEmailPhoneOtp({
        phone: signUpForm.accDetails.TEL_NUM,
        email: signUpForm.accDetails.email || '',
      });
      if (response.data.data == '00') {
        toast.info('OTP Sent');
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Styled>
      <p className="title">OTP Verification</p>
      <p className="heading">
        An OTP has been sent to the phone number and email address registered to
        this Account.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="input-wrapper">
          <CustomInput
            name="accountOtp"
            type="textNumber"
            maxLength="6"
            formik={formik}
            placeholder="Enter OTP"
          />
        </div>
        <div className="submitBtn">
          <p className="resendOtp" onClick={resendOtp}>
            Resend OTP
          </p>
          <Button loader={loader} type="submit" title="Validate" mode="4" />
        </div>
      </form>
    </Styled>
  );
}
const Styled = styled.div`
  .title {
    margin-bottom: 1rem;
    font-weight: 500;
    color: #004c4e;
  }
  .heading {
    font-size: 1.4rem;
    text-align: center;
  }
  .submitBtn {
    display: flex;
    justify-content: space-between;
  }
  .resendOtp {
    font-size: 1.4rem;
    cursor: pointer;
    text-decoration: underline;
  }
  .input-wrapper {
    width: 60%;
    margin: 1rem auto;
    padding: 1rem;
  }
`;
