import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Button from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import Layout from '../Layouts/Settings';

export default function Index() {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  return (
    <Layout activeBar="settings">
      <Styled>
        <div className="profile_wrapper">
          <main className="profile">
            <h3>User Profile</h3>
            <div>
              <div className="profile_row">
                <div className="profile_row_left">
                  <p>First Name</p>
                  <p>{auth.user?.firstName}</p>
                </div>
                <div className="profile_row_right border-type2">
                  <p>Last Name</p>
                  <p>{auth.user?.lastName}</p>
                </div>
              </div>
              <div className="profile_row">
                <div className="profile_row_left">
                  <p>Email</p>
                  <p>{auth.user?.addedBy}</p>
                </div>
                <div className="profile_row_right border-type2">
                  <p>Position In Business</p>
                  <p>{auth.user?.businessPosition}</p>
                </div>
              </div>
            </div>
            <div className="btn_wrapper">
              <Button mode="1" title="Back" onClick={() => navigate(-1)} />
            </div>
          </main>
        </div>
      </Styled>
    </Layout>
  );
}

const Styled = styled.div`
  width: 100%;

  .profile_wrapper {
    max-width: 90rem;
    margin: 2rem;
  }
  .profile {
    background: #ffffff;
    border-radius: 1rem;
    padding: 2rem 3rem;
    h3 {
      font-weight: 600;
      color: #004c4e;
    }
  }
  .profile_row {
    display: flex;
    justify-content: flex-start;
    margin: 2rem 0;
    p:nth-of-type(1) {
      color: #004c4e;
    }
    p:nth-of-type(2) {
      color: #505050;
      font-size: 1.4rem;
      padding-top: 5px;
    }
    .profile_row_left {
      width: 50%;
      border-left: 2px solid #21c55a;
      padding-left: 1rem;
    }
    .profile_row_right {
      border-left: 2px solid #21c55a;
      padding-left: 1rem;
    }
    .border-type1 {
      border-left: 2px solid #21c55a;
    }
    .border-type2 {
      border-left: 2px solid #21c55a;
    }
    .border-type3 {
      border-left: 2px solid #21c55a;
    }
    .pTypeTwo {
      color: #505050 !important;
      font-size: 1.4rem;
      padding-top: 0.5rem;
    }
  }
  .btn_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
`;
