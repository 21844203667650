import * as actionTypes from '../action-types/orrScore';

const initialState = {};
export default function orrScore(state = initialState, { type, payLoad }) {
  switch (type) {
    case actionTypes.SAVE_ORR_SCORE:
      return {
        ...state,
        ...payLoad,
      };
    case actionTypes.CLEAR_ORR_SCORE:
      return initialState;
    default:
      return state;
  }
}
