import { toast } from 'react-toastify';

export const asyncWrapper = async (promise) => {
  const result = [null, null];
  try {
    result[0] = await promise;
  } catch (e) {
    result[1] = e;
    if (e?.response?.status == 500) {
      toast.error('Server Error Try Again');
    }
  }
  return result;
};
