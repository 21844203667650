import { userApis } from 'apis';
import * as actionTypes from '../action-types/notification';

export const getNotification = (data = null) => async (dispatch, getState) => {
  const { notifications, auth } = getState();
  const { notificationData } = notifications;
  const { businessId } = auth?.user;
  try {
    dispatch({
      type: actionTypes.SET_LOADING,
      payLoad: true,
    });
    if (notificationData.length > 0) {
      const res = await userApis.getAllNotifications(data || businessId);
      if (notificationData != res.data.data) {
        const notificationList = res?.data?.data;
        let newNotification = false;
        for (let index = 0; index < notificationList.length; index++) {
          if (notificationList[index].isRead == false) {
            newNotification = true;
          }
        }
        dispatch({
          type: actionTypes.SAVE_NOTIFICATION,
          payLoad: {
            newNotification: newNotification || false,
            notificationData: notificationList,
          },
        });
      }
    } else {
      const res = await userApis.getAllNotifications(data || businessId);
      if (notificationData != res.data.data) {
        const notificationList = res?.data?.data;
        let newNotification = false;
        for (let index = 0; index < notificationList.length; index++) {
          if (notificationList[index].isRead == false) {
            newNotification = true;
          }
        }
        dispatch({
          type: actionTypes.SAVE_NOTIFICATION,
          payLoad: {
            newNotification: newNotification || false,
            notificationData: notificationList,
          },
        });
      }
    }
  } catch (error) {
  } finally {
    dispatch({
      type: actionTypes.SET_LOADING,
      payLoad: false,
    });
  }
};

export const saveNotifications = (data = null) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.SAVE_NOTIFICATION,
    payLoad: {
      newNotification: false,
      notificationData: data,
    },
  });
};
