import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import * as BusinessActions from 'store/actions/business';
import { toast } from 'react-toastify';
// Hooks
import useOrrFeedBack from 'hooks/useOrrFeedBack';
import useOrrScore from 'hooks/useOrrScore';
// API
import { AccountOpeningV2, businessApis, firsApis } from 'apis';
// Component
import Spinner from 'components/OverLays/OverLaySpinner';
import errorHandler from 'helpers/errorHandler';
import Button from 'components/Buttons';
import { FacilityIcon, CollateralSvg } from 'theme/icons';
import useCollateralFeedBack from 'hooks/useCollateralFeedback';
import Box from './Box';
import Layout from '../Layouts/Dashboard';
import BusinessBox from './BusinessEvaluation';
import ORRStatus from './ORRStatus';
import Modal from '../FacilityRiskRating/modal';
import useFacilityFeedBack from 'hooks/useFacilityFeedback';
import OfferLetterNotifier from 'components/OfferLetter/OfferLetterNotifier';
import { useOfferLetters } from 'hooks/useOfferLetters';
import { decryptHandler } from 'helpers/encryption';

export default function Dashboard() {
  const navigate = useNavigate();
  const [isFeedBackAvailable] = useOrrFeedBack();

  const [grades, isScoreLoading] = useOrrScore();

  const { business, orrScore } = useSelector((state) => state);
  const { businessId, businessName } = business?.businessProfiles[0];
  const [offerLetters] = useOfferLetters();
  const [draftedOfferLetter, setDraftedOfferLetter] = useState(false);
  const passportId = business?.businessProfiles[0]?.passportId;
  const [ offerCount, setOfferCount ] = useState(0);
  const [ directorateLoader, setDirectorateLoader ] = useState(false);

  const startEvaluationHandler = () => {
    navigate('/app/orr/dashboard');
  };
  
  const [openAction, setOpenAction] = useState(false);
  const [inputModal, setInputModal] = useState(false);

  const [loader, setLoader] = useState(false);
  const [tinLoader, setTinLoader] = useState(false);
  const [updateBusinessPayload, setUpdateBusinessPayload] = useState({
    businessId,
    passportId,
    companyAccountNumber: '',
  });
  const [accountDetails, setAccountDetails] = useState();
  const [TINOkay, setTINOkay] = useState(false);
  const [ facilityFeedBackAvailable ] = useFacilityFeedBack();
  const [ collateralFeedBack ] = useCollateralFeedBack();
  const [ mappingLoader, setMappingLoader ] = useState(false);

  const facilityHandler = () => {
    if (business.businessProfiles[0]?.companyAccountNumber == '') {
      setOpenAction(true);
    } else {
      navigate('/app/frr/facilityevaluation');
    }
  };

  const collateralHandler = () => {
    if (business.businessProfiles[0]?.companyAccountNumber == '') {
      setOpenAction(true);
    } else {
      navigate('/app/collateral');
    }
  };

  const mapDirectorate = async () => {
    setMappingLoader(true);
    try{
      const response = await AccountOpeningV2.mapAccountDirectorate(businessId);
      if(response?.data?.status){
        setInputModal(false);
      }
    }catch(error){
      errorHandler(error);
    }finally{
      setMappingLoader(false);
    }
  }

  const updateBusiness = async () => {
    setLoader(true);
    try {
      const response = await businessApis.updateBusinessProfile(
        updateBusinessPayload
      );
      mapDirectorate();
      const businessProfile = [...business.businessProfiles];
      businessProfile[0].companyAccountNumber = updateBusinessPayload.companyAccountNumber;
      BusinessActions.BusinessSaveProfiles(businessProfile);
      
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoader(false);
    }
  };

  const verifyTIN = async (tin) => {
    setTinLoader(true);
    try {
      const response = await firsApis.getTin(tin);
    } catch (error) {
      errorHandler(error);
    } finally {
      setTinLoader(false);
    }
  };

  const checkAccountDirectorate = async () => {
    setDirectorateLoader(true);
    try{
      const response = await AccountOpeningV2.getAccountDirectorate(updateBusinessPayload.companyAccountNumber);
      if(response?.data?.status){
        setTINOkay(true);
      }else{
        toast.error(
          `The account directorate isn't available on Gazelle, Kindly enter an appropriate account number.`
        );
      }
    }catch(error){
      errorHandler(error);
    }finally{
      setDirectorateLoader(false);
    }
  }

  const getCustomerInfo = async (customerNumber) => {
    setLoader(true);
    try {
      const response = await firsApis.getMoreByNuban(customerNumber);
      let tin = '';
      const responseTin = response.data.Record['TAX.ID'];
      const splittedResponseTin = responseTin.split('-');
      for (const resTin of splittedResponseTin) {
        tin += resTin;
      }
      let businessTin = '';
      const splittedBusinessTin = business?.businessProfiles[0]?.companyTin.split('-');
      for (const myTin of splittedBusinessTin) {
        businessTin += myTin;
      }

      if (businessTin == tin) {
        // verifyTIN(business?.businessProfiles[0]?.companyTin);
        checkAccountDirectorate();
      } else {
        setTINOkay(false);
        toast.error(
          'The account number provided does not match your profile, Kindly enter an appropriate account number.'
        );
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoader(false);
    }
  };

  const offerRunner = useRef();


  useEffect( () => {

    offerRunner.current = setInterval( () => {
      setOfferCount(() => offerCount + 1)
    }, 500);

    if(offerLetters !== null && offerLetters?.length > 0){
      for(let letter of offerLetters){
        if(letter?.status?.toLowerCase().includes('ready')){
          setDraftedOfferLetter(true);
          return clearInterval(offerRunner.current);
        }
      }
    }

  }, [offerCount])

  const verifyAccount = async () => {
    setLoader(true);
    try {
      const response = await firsApis.getAccountDetailsByNuban(
        updateBusinessPayload.companyAccountNumber
      );
      setAccountDetails(response.data.BankAccountFullInfo);

      if (
        response.data.BankAccountFullInfo.NUBAN
          == updateBusinessPayload.companyAccountNumber
        && response.data.BankAccountFullInfo.CustomerStatusDeecp.toLowerCase()
          == 'corporate customer'
      ) {
        getCustomerInfo(response.data.BankAccountFullInfo.CUS_NUM);
      } else {
        setUpdateBusinessPayload({
          ...updateBusinessPayload,
          companyAccountNumber: '',
        });
        toast.error(
          'The account number provided is not a corporate account, Kindly enter the correct account number.'
        );
      }
    } catch (error) {
      errorHandler(error);
      setUpdateBusinessPayload({
        ...updateBusinessPayload,
        companyAccountNumber: '',
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Layout activeBar="dashboard">
      {(loader || tinLoader || directorateLoader || mappingLoader) && <Spinner />}
      <Styled className="info_grid">
        <div className="info_grid_left">
          <div>
            <p className="company_txt">
              Hello
              {' '}
              {businessName}
            </p>
            <p className="greeting_txt">What would you like to do today?</p>
          </div>

          {
            draftedOfferLetter ?

            <OfferLetterNotifier />

            : ''
          }

          {orrScore?.orrScore < 35
          || Object.keys(orrScore).length < 1
          || !orrScore ? (
              <div className="action_tabs">
              <BusinessBox
                  isFeedBackAvailable={isFeedBackAvailable}
                  startEvaluationHandler={startEvaluationHandler}
                />
            </div>
            ) : (
              ''
            )}

          {orrScore?.orrScore > 34 && (
            <div className="action_tabs">
              <Box
                Icon={FacilityIcon}
                // isDocLoading={isDocLoading}
                feedBackLoader={facilityFeedBackAvailable?.loader}
                feedBackStatus={facilityFeedBackAvailable?.status && Object.keys(facilityFeedBackAvailable?.status).length > 0 ? true : false}
                clickHandler={facilityHandler}
                title="Facility Evaluation"
                noProgress
                manualFeedback
                feedback={facilityFeedBackAvailable?.status && Object.keys(facilityFeedBackAvailable?.status).length > 0 ? 'Feedback available.' : 'No feedback yet.'}
              />

              <Box
                Icon={CollateralSvg}
                // isDocLoading={isDocLoading}
                feedBackLoader={collateralFeedBack?.loader}
                feedBackStatus={collateralFeedBack?.status}
                clickHandler={collateralHandler}
                title="Collateral Evaluation"
                noProgress
                manualFeedback
                feedback={collateralFeedBack.status ? 'Feedback available.' : 'No feedback yet.'}
              />
            </div>
          )}
        </div>
        <div className="info_grid_right">
          <ORRStatus {...grades} isScoreLoading={isScoreLoading} />
        </div>

        {openAction && (
          <Modal
            id="action-modal"
            iconId="close-icon"
            onClick={() => setOpenAction(false)}
          >
            <div id="action-modal-top">
              <div id="icon-holder">
                <div id="icon-holder-circle">
                  <p id="action-modal-top-icon">i</p>
                </div>
              </div>
            </div>
            <p className="Action-modal-text">
              Dear customer,
              <br />
              {' '}
              we noticed that you do not have a bank
              <br />
              {' '}
              account with
              us.
              <br />
              An account is needed to proceed.
            </p>
            <div className="Modal-button-holder">
              <Button
                onClick={() => {
                  setOpenAction(false);
                  setInputModal(true);
                }}
                title="Input Account"
                id="input-account-button"
              />
              <Button
                onClick={() => {
                  navigate('/app/account-opening');
                }}
                title="Open Account"
                id="open-input-button"
              />
            </div>
          </Modal>
        )}

        {inputModal && (
          <Modal
            id="input-modal"
            iconId="input-close-icon"
            header="Company account validation"
            onClick={() => {
              setInputModal(false);
            }}
          >
            <div id="input-holder">
              <label>Account Number</label>
              <input
                onChange={(e) => {
                  setUpdateBusinessPayload({
                    ...updateBusinessPayload,
                    companyAccountNumber: String(e.target.value),
                  });
                }}
                type="number"
                value={updateBusinessPayload.companyAccountNumber}
                placeholder="Enter details"
              />
            </div>

            {TINOkay && (
              <div id="input-holder">
                <label>Company Name</label>
                <input
                  disabled
                  type="text"
                  value={accountDetails?.AccountTitle}
                />
                <p id="correct-text">Is this correct?</p>
              </div>
            )}

            {TINOkay ? (
              <div id="question-buttons-holder">
                <Button
                  onClick={() => {
                    setTINOkay(false);
                    setUpdateBusinessPayload({
                      ...updateBusinessPayload,
                      companyAccountNumber: '',
                    });
                  }}
                  title="No, it's not"
                  id="no-button"
                />

                <Button
                  onClick={() => {
                    updateBusiness();
                  }}
                  title="Yes, it is"
                  id="yes-button"
                />
              </div>
            ) : (
              <div className="Input-modal-button-holder">
                <Button
                  onClick={() => {
                    verifyAccount();
                  }}
                  title="Validate"
                  id="open-input-button"
                  disabled={updateBusinessPayload.companyAccountNumber == ''}
                  style={
                    updateBusinessPayload.companyAccountNumber == '' ? {
                      opacity: '0.4'
                    } : {}
                  }
                />
              </div>
            )}
          </Modal>
        )}
      </Styled>
    </Layout>
  );
}
const Styled = styled.main`
  padding: 5rem 2rem;
  width: 95%;
  margin: 0 auto;
  padding-top: 5%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .hello_txt {
    color: #004c4e;
    font-size: 3rem;
  }
  .company_txt {
    color: #004c4e;
    font-size: 2rem;
    font-weight: 600;
  }
  .greeting_txt {
    color: #004c4e;
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
  .action_tabs {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .info_grid_right {
    width: 15rem;
  }

  .Action-modal-text {
    color: #505050;
    text-align: center;
    margin: 18rem 2rem 3rem 2rem;
  }

  #prompt-modal {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #action-modal {
    background-color: white;
    height: 40rem;
  }

  #action-modal-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 15rem;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  #icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  #close-icon {
    z-index: 100;
    color: #d9b08c;
  }

  #input-close-icon {
    color: #c1c1c1;
  }

  #action-modal-top-icon {
    font-size: 10rem;
    color: #d9b08c;
  }

  #icon-holder-circle {
    height: 10rem;
    width: 10rem;
    border: 3px solid #d9b08c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #input-holder {
    margin: 3rem 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
  }

  input {
    padding: 1rem;
    border: 1px solid #5fb19c;
    border-radius: 6px;
    width: 100%;
    outline: none;
  }

  #input-modal {
    background-color: white;
    width: 25%;
  }

  .Input-modal-button-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #input-account-button {
    padding: 1rem;
    border: 1px solid #5fb19c;
    background-color: #eee;
    border-radius: 6px;
    color: #004c4e;
    cursor: pointer;
  }

  #open-input-button {
    padding: 1rem 3rem;
    border: 1px solid #5fb19c;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-radius: 6px;
    color: #d9b08c;
    cursor: pointer;
  }

  .Modal-button-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  #correct-text {
    color: #004c4e;
    margin-top: 0.5rem;
  }

  #question-buttons-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #no-button {
    border: none;
    margin-right: 2rem;
    background-color: transparent;
    color: #004c4e;
    cursor: pointer;
  }

  #yes-button {
    padding: 1rem 3rem;
    border: none;
    background-color: #004c4e;
    border-radius: 6px;
    color: #d9b08c;
    cursor: pointer;
  }
`;
