import { apiWithToken, apiWithoutToken } from './axiosInterceptor';

const request = {
  getAllSectors: () => {
    const method = 'get';
    const url = '/api/AdminUser/GetAllSectors';
    return apiWithoutToken({
      method,
      url,
    });
  },
  getAllSubSectorBySectorId: (data) => {
    const method = 'get';
    const url = '/api/AdminUser/GetSubsectorBySectorId';
    return apiWithoutToken({
      method,
      url,
      params: { sectorId: data },
    });
  },
  getSubSectorDetailsById: (data) => {
    const method = 'get';
    const url = '/api/AdminUser/GetSectorAndSubsectorBySubsectorId';
    return apiWithToken({
      method,
      url,
      params: { subsectorId: data },
    });
  },
  getQuesAnsBySubSecId: (data) => {
    const method = 'get';
    const url = '/api/AdminUser/GetQuestionAndAnswersBySubSectorId';
    return apiWithToken({ method, url, params: { subSectorId: data } });
  },
  saveGenericAnswers: (data) => {
    const method = 'post';
    const url = '/api/AdminUser/CreatePrincipalOfficers';
    return apiWithToken({ method, url, data });
  },
  getGenericAnswers: (data) => {
    const method = 'get';
    const url = '/api/AdminUser/GetPrincipalOfficersByBusinessId';
    return apiWithToken({ method, url, params: { businessId: data } });
  },
  getAllMgtPositions: () => {
    const method = 'get';
    const url = '/api/AdminUser/GetAllMgtPositions';
    return apiWithoutToken({
      method,
      url,
    });
  },
};

export default request;
