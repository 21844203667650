import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BriefCase2Svg, User1Svg, PadlockV2Svg } from 'theme/icons';
import Layout from '../Layouts/Settings';

export default function index() {
  return (
    <Layout activeBar="settings">
      <Styled>
        <div className="settings_wrapper">
          <h2 className="header">Settings</h2>
          <main className="box">
            <div className="links">
              <Link to="/app/settings/user-profile" className="link_wrapper">
                <User1Svg fill="#004C4E" />
                <p>View User Profile</p>
              </Link>
              <Link
                to="/app/settings/business-profile"
                className="link_wrapper"
              >
                <BriefCase2Svg fill="#004C4E" />
                <p>Update Business Profile</p>
              </Link>
              <Link to="/app/settings/password-change" className="link_wrapper">
                <PadlockV2Svg />
                <p>Change Password</p>
              </Link>
            </div>
          </main>
        </div>
      </Styled>
    </Layout>
  );
}

const Styled = styled.div`
  width: 100%;
  .header {
    font-weight: 500;
    font-size: 2rem;
    color: #004c4e;
    margin-bottom: 1rem;
  }
  .settings_wrapper {
    max-width: 70rem;
    margin: 2rem;
  }
  .box {
    background: #ffffff;
    border-radius: 1rem;
    padding: 3rem;
    h3 {
      font-weight: 600;
      color: #004c4e;
    }
  }
  .links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    grid-gap: 2rem;
  }
  .link_wrapper {
    width: 23rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #004c4e;
    align-items: center;
    background-color: #fff;
    border: thin solid #d1e8e2;
    box-shadow: 2px 4px 5px rgba(200, 200, 200, 0.25);
    border-radius: 5px;
    padding: 2rem;
    svg {
      width: 4rem;
      margin-right: 2rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
`;
