import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from 'components/Headers/General';
import Button, { NextButton, PrevButton } from 'components/Buttons';
import frrApis from 'apis/frr';
import errorHandler from 'helpers/errorHandler';
import Spinner from 'components/OverLays/OverLaySpinner';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

function FacilityFeedbackReview() {
  const navigate = useNavigate();
  const location = useLocation();
  const facilityId = location?.state?.facilityId;
  const [frrResponseLoader, setFrrResponseLoader] = useState(false);
  const [sortedFrrResponses, setSortedFrrResponses] = useState([]);

  useEffect(() => {
    const getFeebackResponses = async () => {
      setFrrResponseLoader(true);
      try {
        const response = await frrApis.getSubmittedFacilityFeedbackResponses(
          facilityId
        );
        setSortedFrrResponses(response.data.data.responses);
      } catch (error) {
        errorHandler(error);
      } finally {
        setFrrResponseLoader(false);
      }
    };

    getFeebackResponses();
  }, []);

  return (
    <Styled>
      <Header />

      <div className="Review-main">
        <div className="Review-board">
          <p className="Review-header">Review your answers</p>

          <div className="Reviews-holder">
            <div>
              {/*   This is for rendering sub purpose questions for review   */}
              {sortedFrrResponses?.length > 0
                && sortedFrrResponses.map((item) => (
                  <div key={item.frrQuestionId} className="Review-item">
                    <div className="Review-question-holder">
                      <div>
                        <div className="Question-icon" />
                        <p className="Question-title">Question</p>
                      </div>
                      <p className="Question">{item?.q?.frrQuestion}</p>
                    </div>
                    <div className="Review-answer-holder">
                      <div>
                        <div className="Answer-icon" />
                        <p className="Answer-title">Answer</p>
                      </div>
                      {item?.a !== null ? (
                        <p className="Answer">
                          {!item?.a?.frrAnswer ? '' : item?.a?.frrAnswer}
                        </p>
                      ) : (
                        ''
                      )}
                      {item.f.unWeightedAnswer !== '' ? (
                        <p className="Answer">{item?.f?.unWeightedAnswer}</p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {facilityId.fromDashboard === false ? (
            <div className="Buttons-holder">
              <Button
                onClick={() => {
                  navigate('/app/frr/facilityquestions');
                }}
                id="prev-button"
                title="Home"
              />

              <NextButton
                onClick={() => {
                  navigate('/app/frr/facilitycollateralmapping');
                }}
                id="next-button"
              />
            </div>
          ) : (
            <div className="Buttons-holder">
              <PrevButton
                onClick={() => {
                  navigate('/app/frr/facilityevaluation');
                }}
                id="prev-button"
              />
            </div>
          )}
        </div>
      </div>

      {frrResponseLoader ? <Spinner /> : ''}
    </Styled>
  );
}

const Styled = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #E5E5E5;
    padding-bottom: 5rem;

    .Review-main{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .Review-board{
        width: 50%;
        margin-top: 5rem;
        position:
    }

    .Review-header{
        font-size: 2rem;
        margin-bottom: 2rem;
        font-weight: 500;
        color: #004C4E;
    }

    .Buttons-holder{
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        justify-content: space-between;
    }
    
    #next-button{
        color: #D9B08C;
        font-size: 1rem;
        padding: 1rem 2rem;
        background: linear-gradient(90deg, #00989C, #004C4E);
        cursor: pointer;
    }

    #prev-button{
        color: #D9B08C;
        font-size: 1rem;
        padding: 1rem 2rem;
        background: linear-gradient(90deg, #00989C, #004C4E);
        cursor: pointer;
    }

    .Review-tabs-button{
        cursor: pointer;
        padding: .5rem 1rem;
        margin-right: 1rem;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background-color: #ddd;
        color: #808080;
        border: none;
    }
    
    .Review-tabs-button-active{
    cursor: pointer;
    padding: .5rem 1rem;
    margin-right: 1rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background: linear-gradient(90deg, #00989C, #004C4E);
    color: white;
    border: none;
    }

    #tab-buttons-holder{
        display: flex;
    }

    .Reviews-holder{
        width; 100%;
        background-color: white;
        height: 60vh;
        border: 1px solid #00989C;
        border-radius: 0px 6px 6px 6px;
        overflow-y: scroll;
        padding: 1rem;
    }

    .Question-icon{
        width: .8rem;
        height: .8rem;
        border-radius: 50%;
        background-color: #00989C;
        margin-right: 1rem;
    }

    .Answer-icon{
        width: .8rem;
        height: .8rem;
        border-radius: 50%;
        background-color: #D9B08C;
        margin-right: 1rem;
    }

    .Review-item{
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .Review-question-holder{

        div{
            display: flex;
            align-items: center;
        }
    }

    .Review-answer-holder{
        margin-top: .5rem;

        div{
            display: flex;
            align-items: center;
        }
    }

    .Question-title{
        font-size: 1.2rem;
    }

    .Answer-title{
        font-size: 1.2rem;
    }

    .Question{
        margin-left: 2rem;
        font-size: 1.1rem;
        color: grey;
    }

    .Answer{
        margin-left: 2rem;
        font-size: 1.1rem;
        color: grey;
    }
`;

export default FacilityFeedbackReview;
