import React, { useEffect, useState } from 'react';
import { UpdateCollateralPayload } from 'store/actions/collateral';
import { useDispatch, useSelector } from 'react-redux';
import frrApis from 'apis/frr';
import { pathOr } from 'ramda';
import { asyncWrapper } from 'helpers/asyncWrapper';
import QuestionTypes from './QuestionTypes';

const seniorityNameMapping = {
  firstExclusiveChargeHaircut: 'First and exclusive',
  pariPassuHaircut: 'Pari-passu',
  secondaryChargeHaircut: 'Second Charge',
};

export default function Seniority(props) {
  const { collateral } = useSelector((state) => state);
  const { payLoad, checks } = collateral;
  const dispatch = useDispatch();
  const [seniorityList, setSeniority] = useState([]);

  useEffect(() => {
    const getSeniority = async () => {
      props?.setLoading(true);
      const [res, err] = await asyncWrapper(frrApis.getCollateralSeniority());
      props?.setLoading(false);
      const getValue = pathOr({}, ['data', 'data', 0], res);
      delete getValue.seniorityId;
      const formatData = Object?.entries(getValue)?.map((val) => ({ name: seniorityNameMapping[val[0]], value: val[1] }));
      setSeniority(formatData);
    };
    getSeniority();
    return () => {};
  }, []);

  const inputHandler = (e) => {
    const index = seniorityList.findIndex(
      (elem) => elem.value == e.target.value
    );
    sessionStorage.setItem('_seniority', seniorityList[index].name);
    sessionStorage.setItem('_currentPage', 'collateralSeniorityValue');
    dispatch(
      UpdateCollateralPayload({
        collateralSeniorityValue: seniorityList[index].value,
        collateralSeniorityName: seniorityList[index].name,
      })
    );
  };

  return (
    <QuestionTypes
      questionTitle="What is the Collateral Seniority?"
      optionsArray={seniorityList}
      questionType="dropdown"
      optionKey="name"
      optionId="value"
      inputHandler={inputHandler}
      inputtedAnswer={
        payLoad.collateralSeniorityValue == null
          ? ''
          : payLoad.collateralSeniorityValue
      }
    />
  );
}
