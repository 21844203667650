import React from 'react';
import Main from 'components/FacilityRiskRating/review/review';
import { Helmet } from 'react-helmet-async';

function FacilityQuestionsReview() {
  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5' }}>
        <title>Home | Gazelle by Sterling</title>
      </Helmet>
      <Main />
    </>
  );
}

export default FacilityQuestionsReview;
