import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Layout from 'components/Layouts/Default';
import Button from 'components/Buttons';
import BackButton from 'components/Buttons/BackButton';
import Uploader from 'components/AccountOpening/uploader';
import { base64 } from 'components/AccountOpening/base64';
import Spinner from 'components/OverLays/OverLaySpinner';
import { OfferLetter } from 'apis';
import errorHandler from 'helpers/errorHandler';
import { useNavigate, useParams } from 'react-router';
import Modal from 'components/FacilityRiskRating/modal';

export default function UploadOfferLetter() {
  
  const navigate = useNavigate()
  const params = useParams();

  const { business } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [uploadPayload, setUploadPayload] = useState({
      offerLetterId: Number(params.id),
      documentUpload: "",
      fileType: "pdf"
  })

  const uploadDocument = async (file) => {
    setLoading(true);
    const convertedFileAs64String = await base64(file);

    const filesArr = convertedFileAs64String.split(',');
    const fileSplit = filesArr[1];

    if(fileSplit){
      setLoading(false);
      setUploadPayload({
        ...uploadPayload, documentUpload: fileSplit
      });
    }
    
  };

  const submitDocument = async () => {
    setLoading(true);
    try{
      const response = await OfferLetter.postOfferLetters(uploadPayload);
      setSuccessModal(true);
    }catch(error){
      errorHandler(error);
    }finally{
      setLoading(false);
    }
  }


  return (
    <Styled>
      <Layout activeBar="offer-letter">
        <div className="wrapper">
          <section className="title-wrapper">
            <BackButton
              color="#00989C"
              size={15}
            />
            <h4 className="title">Offer Letter Upload</h4>
          </section>
          <section className='upload-wrapper'>
            <div className='upload-box'>
                <p className='note'><span style={{ color: 'red' }}>*</span>Upload Signed Offer Letter <span style={{ color: 'red' }}>(PDF Only)</span></p>
                <Uploader
                  accept = "application/pdf" 
                  removeAsterisk = {true} 
                  onChange = { e => {
                    uploadDocument(e.target.files)
                  }}
                 />
                <p className='note'><span style={{ color: 'red' }}>*</span>Please note that the two Signatories must sign the Offer Letter before upload</p>
            </div>
            <Button
                onClick={() => {
                  submitDocument();
                }}
                mode="1"
                title="Submit"
                id="submit-button"
                disabled = {
                  uploadPayload.documentUpload === ''
                  || uploadPayload.offerLetterId === null
                }
              />
          </section>
        </div>
      </Layout>


      { loading ? (
          <Spinner />
        ) : (
          ''
        )
      }

      {successModal ? (
        <Modal id="action-modal" iconId="close-icon">
          <div id="action-modal-top">
              <div id="icon-holder">
                <div id="icon-holder-circle">
                  <p id="action-modal-top-icon">i</p>
                </div>
              </div>
            </div>
          <p className="Action-modal-text">
            Dear customer,
            <br />
            your acceptance is well recieved. We will get back to you shortly.
            
          </p>
          <div className="Modal-button-holder">
            <Button
              onClick={() => {
                navigate('/app/dashboard')
              }}
              title="Okay"
              id="open-input-button"
            />
          </div>
        </Modal>
      ) : (
        ''
      )}
    </Styled>
  );
}
const Styled = styled.div`
  .wrapper {
    padding: 0 2rem;
  }
  .title {
    color: #004c4e;
    padding: 0.8rem;
    font-weight: 500;
  }
  .title-wrapper{
      display: flex;
      align-items: center;
      margin-top: 5rem;
      margin-bottom: 3rem;
  }

  .upload-box{
      width: 100%;
      background-color: white;
      border: 1px dashed #5FB19C;
      border-radius: 10px;
      height: 30rem;
      padding: 5rem 0 0 3rem;
  }

  .upload-wrapper{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 50%;
  }

  #submit-button{
      width: 10rem;
      margin-top: 2rem;
  }

  .note{
      font-size: 1.2rem;
      margin: 1rem 0;
  }
  .Action-modal-text {
    color: #505050;
    text-align: center;
    margin: 18rem 2rem 3rem 2rem;
  }

  #prompt-modal {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #action-modal {
    background-color: white;
    height: 40rem;
    width: 25%;
  }

  #action-modal-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 15rem;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  #close-icon {
    z-index: 100;
    color: #F8CAA2;
    display: none;
  }

  #input-close-icon {
    color: #c1c1c1;
  }

  #action-modal-top-icon {
    font-size: 10rem;
    color: #d9b08c;
  }

  #icon-holder-circle {
    height: 10rem;
    width: 10rem;
    border: 3px solid #F8CAA2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #input-modal {
    background-color: white;
    width: 25%;
  }

  .Input-modal-button-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #open-account-button {
    padding: 1rem;
    border: 1px solid #5fb19c;
    background-color: #eee;
    border-radius: 6px;
    color: #004c4e;
    cursor: pointer;
  }

  #open-input-button {
    padding: 1rem;
    border: 1px solid #5fb19c;
    background: linear-gradient(90deg, #00989c, #004c4e);
    border-radius: 6px;
    color: #d9b08c;
    cursor: pointer;
  }

  .Modal-button-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;
