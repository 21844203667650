import { useState, useEffect } from 'react';
import { orrApis, adminUserApis } from 'apis';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { mergeAnswerWithQuestions } from '../helpers/questionsAnswers';

export default function useOrrQuesAns() {
  const [businessMgtQuestionsPool, setBusinessMgtQuestionsPool] = useState({});
  const { auth, business } = useSelector((state) => state);
  const [isLoading, setLoader] = useState(false);
  const subSectorId = business?.businessProfiles[0]?.subSectorId;
  const businessId = auth?.user?.businessId;

  useEffect(() => {
    const getAllSectors = async () => {
      try {
        setLoader(true);
        const res = await adminUserApis.getQuesAnsBySubSecId(subSectorId);
        const res2 = await orrApis.getOrrResponse(businessId);
        const allQuestions = res.data.data.filter(
          (elem) => elem.questionType != 'T'
        );

        const allAnswers = res2.data.data;
        const allPrimary = mergeAnswerWithQuestions(allQuestions, allAnswers);

        const managementQues = [];
        const businessQues = [];
        allPrimary.map((elem) => {
          if (elem?.categoryName == 'Management') {
            managementQues.push(elem);
          } else if (elem?.categoryName == 'Business') {
            businessQues.push(elem);
          }
          return businessQues;
        });
        const dataToState = {
          Management: [...managementQues],
          Business: businessQues,
        };
        setBusinessMgtQuestionsPool(dataToState);
      } catch (error) {
        toast.error('Error Retrieving ORR', error);
      } finally {
        setLoader(false);
      }
    };
    getAllSectors();
    return () => {};
  }, [businessId, subSectorId]);

  return [
    businessMgtQuestionsPool,
    isLoading,
    setLoader,
    setBusinessMgtQuestionsPool,
  ];
}
