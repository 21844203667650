import React from 'react';
import styled from 'styled-components';
import NoteIcon from '../../assets/svgs/note-icon.svg';

function FRRTab({ activeSegment }) {
  return (
    <Styled>
      <div
        className={
          activeSegment?.toLowerCase().includes('purpose')
          || activeSegment?.toLowerCase() === 'p'
            ? 'Underline Active FRR-tab-item-holder'
            : 'Active FRR-tab-item-holder'
        }
      >
        <img className="Note-icon" alt="An icon" src={NoteIcon} />
        <p>Purpose</p>
      </div>

      <div
        className={
          activeSegment?.toLowerCase().includes('general')
            ? 'Underline Active FRR-tab-item-holder'
            : 'FRR-tab-item-holder'
        }
      >
        <img className="Note-icon" alt="An icon" src={NoteIcon} />
        <p>General</p>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  width: 68%;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #d9b08c;
  margin: 0 0 1rem 0;
  p {
    padding: 0 1rem;
  }
  @media (max-width: 80rem) {
    width: 100%;
  }

  .Note-icon {
    color: black;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }

  .FRR-tab-item-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.4;
  }

  .Active {
    opacity: 1;
  }

  .Underline {
    border-bottom: 3px solid #d9b08c;
  }
`;

export default FRRTab;
