import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function BackButton({ size, color }) {
  const navigate = useNavigate();
  return (
    <Styled onClick={() => navigate(-1)}>
      <IoIosArrowBack size={size} color={color} />
    </Styled>
  );
}

const Styled = styled.div`
    padding: .6rem .7rem; 
    border: 2px solid #00989C;
    border-radius: 5px;
    cursor: pointer;
`;

export default BackButton;
