import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { orrApis, settingApis } from 'apis';
import { useSelector } from 'react-redux';
import {
  FiTrash, FiEye, FiUpload, FiX
} from 'react-icons/fi';
import ErrorHandler from 'helpers/errorHandler';
import { toast } from 'react-toastify';
import axios from 'axios';

const baseStyle = {
  width: '10rem',
  padding: '.3rem 1rem',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#5FB19C',
  borderStyle: 'solid',
  backgroundColor: '#EEEEEE',
  color: '#004C4E',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  fontSize: '1.4rem',
  display: 'flex',
  fontWeight: '500',
  cursor: 'pointer',
};
const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};
function RetrieveImg(props) {
  const [isLoading, setLoader] = useState(false);
  const viewFileHandler = (file) => {
    const getFileType = props.fileName.split('.')[5];
    const anchor = document.createElement('a');
    anchor.href = `data:application/octet-stream;base64,${file}`;
    anchor.target = '_blank';
    anchor.download = `download.${getFileType}`;
    anchor.click();
  };
  const getFile = async () => {
    try {
      setLoader(true);
      props.setBtnDisabled(true);
      const res = await orrApis.retrieveDocument(props.fileName);
      if (res.data == null) {
        throw new Error('Error Retrieving File');
      } else if (res.status == 200) {
        viewFileHandler(res?.data?.data?.base64String);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoader(false);
      props.setBtnDisabled(false);
    }
  };
  const statusObj = new Map([
    ['uploaded', true],
    ['orr-management', true],
    ['rejected', true],
    [null, true],
  ]);
  const docStatus = props?.documentStatus?.toLocaleLowerCase();
  return (
    <div className="thumb">
      <div className="thumbInner">
        {isLoading ? (
          <p className="loading-text">Fetching document...</p>
        ) : (
          <>
            {statusObj.has(docStatus) && (
              <div
                className="thumbInner-action action-delete"
                onClick={props.fileDeleteHandler}
              >
                <p>Delete</p>
                <FiTrash
                  size={18}
                  color="#004C4E"
                  style={{ marginLeft: '5px' }}
                />
              </div>
            )}
            <div className="thumbInner-action" onClick={getFile}>
              <p>View</p>
              <FiEye size={18} color="#004C4E" style={{ marginLeft: '1rem' }} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default function FileUploadV3Upload(props) {
  const { auth } = useSelector((state) => state);
  const [imageData, setImageData] = useState(null);
  const [isLoading, setLoader] = useState(false);
  const [cancelAxiosUpload, setCancelAxiosUpload] = useState(null);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: '.pdf,.doc,.docx,.xlsx',
    multiple: false,
    maxFiles: 1,
    preventDropOnDocument: false,
    maxSize: 15000000,
    onDropRejected: onDropError,
    onDropAccepted: uploadFileHandler,
  });
  useEffect(() => {
    setImageData(props?.url);
    return () => {};
  }, [props.url]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  function onDropError(e) {
    toast.error('Max File Size (10MB) Exceeded');
  }
  const fileDeleteHandler = async () => {
    const verifyDelete = window.confirm('Are you sure you want to delete');
    if (verifyDelete) {
      try {
        await settingApis.updateOrrDocuments(
          {
            documentUrl: '',
            documentId: props.documentId,
            businessId: auth?.user?.businessId,
            status: 'PENDING',
          },
          { uploadId: props.uploadId }
        );
      } catch (error) {}
      setImageData(null);
    }
  };
  async function uploadFileHandler(file) {
    const fd = new FormData();
    const fileName = file[0].name;
    const trimName = fileName.replace(/[^a-zA-Z0-9.]/g, '');
    fd.append('file', file[0], trimName);
    // docLibName is either ORR or FRR for now
    try {
      setLoader(true);
      props.setBtnDisabled(true);
      const cancelTokenSource = axios.CancelToken.source();
      setCancelAxiosUpload(cancelTokenSource);
      const res = await orrApis.uploadDocument(
        fd,
        {
          docLibName: 'ORR',
          businessId: auth?.user?.businessId,
        },
        { cancelToken: cancelTokenSource.token }
      );
      if (
        props.documentId
        && typeof res.data.data === 'string'
        && res?.data?.data?.includes('https://sbnk2storage.blob.core.windows.net')
      ) {
        await settingApis.postOrrDocument({
          documentUrl: res.data.data,
          documentId: props.documentId,
          businessId: auth?.user?.businessId,
        });
      } else {
        return toast.error('Error Occurred Uploading the File');
      }
      // save the document to its sector----second layer of saving. its individual now
      if (
        res.status == 200
        && res.data.data?.includes('https://sbnk2storage.blob.core.windows.net')
      ) {
        setImageData(res.data.data);
        props?.fileHandler(res.data.data);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoader(false);
      props.setBtnDisabled(false);
    }
  }
  const cancelUpload = () => {
    setImageData(null);
    cancelAxiosUpload.cancel('Upload cancelled');
  };
  return (
    <Upload>
      {imageData && (
        <RetrieveImg
          documentStatus={props.documentStatus}
          setBtnDisabled={props.setBtnDisabled}
          fileName={imageData}
          fileDeleteHandler={fileDeleteHandler}
        />
      )}
      {isLoading ? (
        <div className="uploading" onClick={cancelUpload}>
          <p>Uploading...</p>
          <FiX />
        </div>
      ) : (
        <>
          {!imageData && (
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p style={{ fontSize: '1.4rem' }}>Upload</p>
              <FiUpload
                size={18}
                color="#004C4E"
                style={{ marginLeft: '1rem' }}
              />
            </div>
          )}
        </>
      )}
    </Upload>
  );
}
const Upload = styled.section`
  width: 30%;
  display: flex;
  text-align: center;
  .loading-text {
    font-size: 1.2rem;
    padding: 0.4rem;
    border: 1px solid #5fb19c;
  }
  .thumb {
    display: inline-flex;
    width: 100%;
    box-sizing: border-box;
  }
  .uploading {
    display: flex;
    align-items: center;
    border: 1px solid #5fb19c;
    padding: 0.5rem;
    font-size: 1.2rem;
    border-radius: 2px;
    &:hover {
      background-color: #004c4e30;
    }
  }
  .thumbInner {
    display: flex;
    width: 100%;
    position: relative;
  }
  .thumbInner-action {
    display: flex;
    background: #d1e8e2;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
    p {
      font-size: 1.2rem;
      color: #004c4e;
    }
  }
  .action-delete {
    background: #fff5ed;
    color: #505050;
    svg {
      stroke: #d45f36;
    }
  }
`;
