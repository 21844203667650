import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomInput from 'components/FormikInputs/Input';
import { NextButton, PrevButton } from 'components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import * as SignUpFormActions from 'store/actions/signUpForm';
import * as SignUpActions from 'store/actions/signUpForm';
import { businessType, denominations } from 'constants/signUp';
import styled from 'styled-components';

export default function Business() {
  const dispatch = useDispatch();
  const { signUpForm } = useSelector((state) => state);
  const validationSchema = yup.object({
    businessType: yup.string().required('Business Type required'),
    businessTurnover: yup.string().required('Turnover is required'),
    dateIncorporated: yup.string().required('Years is required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: signUpForm?.business.businessType
      ? signUpForm?.business
      : {
        businessType: '',
        businessTurnover: '',
        dateIncorporated: '',
        denominations: '₦',
      },
    validationSchema,
    onSubmit: (values) => {
      dispatch(SignUpFormActions.SaveActivePage('loan'));
      dispatch(SignUpFormActions.SaveBusiness(values));
    },
  });
  return (
    <Styled>
      <h2 className="title">Tell Us About Your Business</h2>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          name="businessType"
          label="Business Type"
          type="option"
          required
          options={businessType}
          formik={formik}
        />
        <CustomInput
          name="denominations"
          name2="businessTurnover"
          label="Business Turnover (As at the last audited financial year)"
          type="currencyDropDownAndNumeric"
          options={denominations}
          required
          formik={formik}
        />
        <CustomInput
          name="dateIncorporated"
          label="Date of Incorporation"
          type="date"
          maxDate={new Date()}
          // required
          formik={formik}
        />
        <div className="navButton">
          <PrevButton
            mode="3"
            title="Prev"
            type="button"
            onClick={() => dispatch(SignUpActions.SaveActivePage('aboutYou'))}
          />
          <NextButton type="submit" mode="1" title="Next" />
        </div>
      </form>
    </Styled>
  );
}
const Styled = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 5rem;
  padding-top: 15%;

  .navButton {
    display: flex;
    justify-content: space-between;
  }
  .title {
    margin-bottom: 2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
  }
`;
