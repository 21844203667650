import { store } from 'store/store';
import * as NotifyActions from 'store/actions/notify';
import * as AuthActions from 'store/actions/auth';

const errors = {
  400: 'Bad Request',
  401: 'You are not authorized to access this endpoint, Apologies',
  403: 'You are forbidden for accessing this resource, please provide valid authentication',
  404: "Whatever it is you were looking for wasn't found",
  405: 'The request method used is not supported, please refer to API docs',
  409: 'Email already registered',
  500: 'Apologies. Something happened on our end, please try again',
  503: 'Maintenance On-going, please try again after 3mins',
};

export default function errorHandler(error, msg = null) {
  // const dispatch = useDispatch();
  console.error('error', error, msg);
  let errorMessage = errors[error?.response?.status] || msg || error?.message || 'error';
  if (error?.response?.status === 409 && error?.response.data.message === 'You Already have a running Session Running') {
    errorMessage = 'You already have an active session running';
  } else if (error?.response?.status === 401 && error?.response.data.message === 'Token Expired,Pls login') {
    errorMessage = 'You are logged out because you have an active session on another device';
    store.dispatch(AuthActions.AuthResetUser());
  }

  return store.dispatch(
    NotifyActions.ShowNotify({
      show: true,
      type: 'bad',
      message: errorMessage,
      func: null,
    })
  );
}
