import React from 'react';
import Main from 'components/Settings/Notifications';
import { Helmet } from 'react-helmet-async';

export default function index() {
  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5' }}>
        <title>Gazelle | Gazelle by Sterling</title>
      </Helmet>
      <Main />
    </>
  );
}
