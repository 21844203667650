import { apiWithoutToken } from './axiosInterceptor';

const request = {
  checkExistBvn: (data) => {
    const method = 'post';
    const url = '/api/Validator/BVN';
    return apiWithoutToken({
      method,
      url,
      data: { BVN: data },
    });
  },
  checkExistTin: (data) => {
    const method = 'get';
    const url = '/api/Validator/TIN';
    return apiWithoutToken({
      method,
      url,
      params: { TIN: data },
    });
  },
  validateUserEmail: (data) => {
    const method = 'get';
    const url = '/api/Validator/EmailAddress';
    return apiWithoutToken({
      method,
      url,
      params: { emailAddress: data },
    });
  },
};

export default request;
