import React, { useState, useEffect } from 'react';
import frrApis from 'apis/frr';
import {
  SetCollateralChecks,
  UpdateCollateralPayload,
} from 'store/actions/collateral';
import { useDispatch, useSelector } from 'react-redux';
import { asyncWrapper } from 'helpers/asyncWrapper';
import QuestionTypes from './QuestionTypes';

export default function CollateralType(props) {
  const { collateral } = useSelector((state) => state);
  const { payLoad, checks } = collateral;
  const [collateralTypes, setCollateralTypes] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const getCollateralTypes = async () => {
      props?.setLoading(true);
      const [res, err] = await asyncWrapper(frrApis.getCollateralTypes());
      props?.setLoading(false);
      setCollateralTypes(res.data.data);
    };
    getCollateralTypes();
    return () => {};
  }, []);

  const collateralQuestions = {
    question: 'What is the Collateral type?',
    questionType: 'dropdown',
  };
  const inputHandler = (e) => {
    const index = collateralTypes.findIndex(
      (elem) => elem.collateralTypeId == e.target.value
    );
    sessionStorage.setItem(
      '_collateralType',
      collateralTypes[index].collateralTypeName
    );
    dispatch(
      UpdateCollateralPayload({
        collateralTypeId: collateralTypes[index].collateralTypeId,
      })
    );
    dispatch(
      SetCollateralChecks({
        state_classification: collateralTypes[index].stateClassification,
        search_fee: collateralTypes[index].searchFees,
        valuation: collateralTypes[index].valuationValidityPeriodAndPricing,
      })
    );
    sessionStorage.setItem('_currentPage', 'collateralTypeId');
  };
  return (
    <QuestionTypes
      questionTitle={collateralQuestions.question}
      optionsArray={collateralTypes}
      questionType={collateralQuestions.questionType}
      optionKey="collateralTypeName"
      optionId="collateralTypeId"
      inputHandler={inputHandler}
      wrapperStyle={{ justifyContent: 'center' }}
      inputtedAnswer={payLoad.collateralTypeId || ''}
    />
  );
}
