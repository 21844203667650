import * as actionTypes from '../action-types/signUpForm';

const initialState = {
  bvnDetails: {},
  accDetails: {},
  tinDetails: {},
  aboutYou: {},
  business: {},
  loan: {},
  finalPage: {},
  customerType: null,
  activePage: 'aboutYou',
};

export default function auth(state = initialState, { type, payLoad }) {
  switch (type) {
    case actionTypes.SAVE_BVN_SIGNUP_FORM:
      return {
        ...state,
        bvnDetails: payLoad,
      };
    case actionTypes.SAVE_ACCOUNT_SIGNUP_FORM:
      return {
        ...state,
        accDetails: payLoad,
      };
    case actionTypes.UPDATE_ACCOUNT_SIGNUP_FORM:
      return {
        ...state,
        accDetails: { ...state.accDetails, ...payLoad },
      };
    case actionTypes.SAVE_TIN_SIGNUP_FORM:
      return {
        ...state,
        tinDetails: payLoad,
      };
    case actionTypes.SAVE_ABOUT_YOU_SIGNUP_FORM:
      return {
        ...state,
        aboutYou: payLoad,
      };
    case actionTypes.SAVE_BUSINESS_SIGNUP_FORM:
      return {
        ...state,
        business: payLoad,
      };
    case actionTypes.SAVE_LOAN_SIGNUP_FORM:
      return {
        ...state,
        loan: payLoad,
      };
    case actionTypes.SAVE_CUSTOMER_TYPE_SIGNUP_FORM:
      return {
        ...state,
        customerType: payLoad,
      };
    case actionTypes.SAVE_ACTIVE_PAGE_SIGNUP_FORM:
      return {
        ...state,
        activePage: payLoad,
      };
    case actionTypes.SAVE_FINAL_SIGNUP_FORM:
      return {
        ...state,
        finalPage: payLoad,
      };
    case actionTypes.RESET_SIGNUP_FORM:
      return initialState;
    default:
      return state;
  }
}
