import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from 'components/Buttons';
import authApis from 'apis/user';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FiArrowRight } from 'react-icons/fi';
import ErrorHandler from 'helpers/errorHandler';
import useNotify from 'hooks/useNotify';
import CustomInput from '../FormikInputs/Input';

export default function ForgetPassword() {
  const navigate = useNavigate();
  const [setNotify] = useNotify();
  const [loader, setLoader] = useState(false);

  const validationSchema = yup.object({
    username: yup.string().required('Username Cant Be Empty'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
    },

    validationSchema,

    onSubmit: async (values, { resetForm }) => {
      try {
        setLoader(true);
        const response = await authApis.forgetPassword(values.username);
        if (response.data.status == true) {
          resetForm({});
          setNotify({
            show: true,
            type: 'ok',
            message: response.data.data,
          });
        } else {
          toast.error(response.data.data);
        }
        setLoader(false);
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    },
  });

  return (
    <Styled>
      <div className="form">
        <div className="wrapper-title">
          <p className="title">Forgot Password</p>
          <p className="loginBtn" onClick={() => navigate('/auth/login')}>
            <FiArrowRight color="#004c4e" size="15" className="login-arrow" />
            Login
          </p>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <CustomInput
            name="username"
            label="Email"
            type="text"
            required
            formik={formik}
          />

          <div className="btnWrapper">
            <Button loader={loader} type="submit" title="Submit" mode="1" />
          </div>
        </form>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  justify-content: center;
  align-items: center;
  .title {
    color: #004c4e;
    font-size: 2.5rem;
    font-weight: 600;
  }
  .form {
    min-width: 30rem;
    max-width: 50rem;
    width: 100%;
    background: #fff;
    border-radius: 15px;
    padding: 8rem 4rem;
    border: 4px solid #d1e8e2;
    @media (max-width: 479px) {
      padding: 8rem 2rem;
      margin: 0 1rem;
    }
  }
  .loginBtn {
    color: #004c4e;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
  }
  .btnWrapper {
    display: flex;
    justify-content: flex-end;
  }
  .wrapper-title {
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .login-arrow {
    vertical-align: middle;
  }
`;
