import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as NotificationActions from 'store/actions/notification';

export default function useNotification() {
  const { notifications } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    // 1000 * 60 * 3
    const interval = setInterval(
      () => dispatch(NotificationActions.getNotification()),
      180000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  const { notificationData, newNotification, isNotificationLoading } = notifications;
  return [notificationData, newNotification, isNotificationLoading];
}
