export function MultipleContainer(props) {
  return (
    <>
      {props.data.map((elem, i) => (
        <p key={elem.answer} className="review_answer">
          {elem.answer}
        </p>
      ))}
    </>
  );
}
export function ListContainer(props) {
  return (
    <>
      {props.data.map((elem, i) => (
        <p key={i} className="review_answer">
          {elem}
        </p>
      ))}
    </>
  );
}
export function ContactListContainer(props) {
  return (
    <table className="styled-table-contact">
      <thead>
        <tr>
          <th>Name</th>
          <th>Phone No</th>
          <th>Email</th>
          <th>Address</th>
          <th>Length of Relationship</th>
        </tr>
      </thead>
      <tbody>
        {props?.data?.map((elem, i) => (
          <tr key={i}>
            <td>{elem.name}</td>
            <td>{elem.phone}</td>
            <td>{elem.email}</td>
            <td>{elem.address}</td>
            <td>{elem.len_of_relationship}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function AnswerContainer({ questionProperties }) {
  const { questionType, selectedAnswer, inputtedAnswer } = questionProperties;
  let answer;
  if (questionType == 'Multiple') {
    answer = <MultipleContainer data={selectedAnswer} />;
  } else if (questionType == 'list') {
    answer = <ListContainer data={inputtedAnswer} />;
  } else if (questionType == 'contact-list') {
    answer = <ContactListContainer data={inputtedAnswer} />;
  } else {
    answer = (
      <p className="review_answer">
        {selectedAnswer?.answer || inputtedAnswer}
      </p>
    );
  }
  return <>{answer}</>;
}
