import React from 'react';
import Header from 'components/Headers/Orr';
import styled from 'styled-components';
import useSubSectorImage from 'hooks/useSubSectorImage';
import Questions from './Questions';

export default function GenericQuestion() {
  const [subSectorImage, isImageLoading] = useSubSectorImage();
  return (
    <Styled>
      <Header />
      <section className="container_layer1">
        <div className="section_ques">
          <main className="orr_ques_main">
            <Questions />
          </main>
          <aside className="orr_ques_aside">
            <div className="img-wrapper">
              <img
                src={subSectorImage.length > 2 ? subSectorImage : undefined}
                alt=""
              />
            </div>
            {/* to make this work i will need to make the payload empty string for the rest data */}
            {/* <Button mode="5" title="Save & Continue" /> */}
          </aside>
        </div>
      </section>
    </Styled>
  );
}

const Styled = styled.div`
  background-color: #e5e5e5;
  .container_layer1 {
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0 8%;
    max-width: 144rem;
    padding-bottom: 4rem;
    @media (max-width: 140rem) {
      padding: 0 4%;
    }
    @media (max-width: 479px) {
      padding: 0 2%;
    }
  }
  .section_ques {
    display: flex;
    justify-content: space-around;
  }
  .orr_ques_main {
    width: 65%;
    min-height: 100vh;

    @media (max-width: 850px) {
      width: 100%;
    }
  }
  .orr_ques_aside {
    width: 30%;
    padding-top: 8rem;
    text-align: center;
    @media (max-width: 85rem) {
      display: none;
    }
    .img-wrapper {
      height: 58rem;
      overflow: hidden;
      margin-bottom: 3.5rem;
      border-radius: 1rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
