import React from 'react';
import styled from 'styled-components';
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import FinanceComponent from './FinStatement';
import CashFlowComponent from './CashFlow';
import AssetsComponent from './Assets';
import LiabilityComponent from './Liability';
import CapitalComponent from './Capital';
import BorrowingHistory from './BorrowingHistory';

function EditButton({ edit, index }) {
  const navigate = useNavigate();
  const handleEditNav = (index, section) => {
    navigate(
      `/app/orr/business-mgt-financial?index=${
        index
      }&dest=${
        section
      }&status=review`,
      { replace: true }
    );
  };
  return (
    <>
      {edit ? (
        <span
          className="edit-btn"
          onClick={() => handleEditNav(index, 'Financials')}
        >
          Edit
          {' '}
          <FiEdit />
        </span>
      ) : undefined}
    </>
  );
}
export default function Financial(props) {
  const getAuditorsName = () => props?.auditorList?.filter(
    (elem) => elem.icanListId == props?.finAnswers?.auditor
  )[0]?.auditorName;

  return (
    <Styled>
      <div className="question_wrapper">
        <p className="title">
          Do you have an audited financial statement (AFS)?
          {' '}
          <EditButton edit={props.edit} index={0} />
        </p>
        <p className="answer">{props?.finAnswers?.lastFinancial}</p>
      </div>
      {props?.finAnswers?.lastFinancial == 'Yes' && (
        <>
          <div className="question_wrapper">
            <p className="title">
              Who is your auditor?
              <EditButton edit={props.edit} index={1} />
            </p>
            <p className="answer">{getAuditorsName()}</p>
          </div>
          <div className="question_wrapper">
            <p className="title">
              Is your AFS qualified or unqualified?
              {' '}
              <EditButton edit={props.edit} index={2} />
            </p>
            <p className="answer">{props?.finAnswers?.afsQualification}</p>
          </div>
        </>
      )}
      <div className="question_wrapper">
        <p className="title">
          When is your accounting year-end?
          {' '}
          <EditButton edit={props.edit} index={3} />
        </p>
        <p className="answer">
          {new Date(
            props?.finAnswers?.financialEndYearDate
          ).toLocaleDateString()}
        </p>
      </div>
      <div className="question_wrapper">
        <p className="title">
          Kindly provide details of your financial statement below ?
          <EditButton edit={props.edit} index={4} />
        </p>
        <FinanceComponent finAnswers={props?.finAnswers} />
      </div>
      <div className="question_wrapper">
        <p className="title">
          Kindly provide details of your cashflow below?
          {' '}
          <EditButton edit={props.edit} index={5} />
        </p>
        <CashFlowComponent finAnswers={props?.finAnswers} />
      </div>
      <div className="question_wrapper">
        <p className="title">
          Kindly provide details of your Assets below?
          {' '}
          <EditButton edit={props.edit} index={6} />
        </p>
        <AssetsComponent finAnswers={props?.finAnswers} />
      </div>
      <div className="question_wrapper">
        <p className="title">
          Kindly provide details of your Liability below?
          {' '}
          <EditButton edit={props.edit} index={7} />
        </p>
        <LiabilityComponent finAnswers={props?.finAnswers} />
      </div>
      <div className="question_wrapper">
        <p className="title">
          Kindly provide details of your Capital and Earnings below?
          {' '}
          <EditButton edit={props.edit} index={8} />
        </p>
        <CapitalComponent finAnswers={props?.finAnswers} />
      </div>
      {props.borrowList.length > 0 ? (
        <div className="question_wrapper">
          <p className="title">
            Kindly input your borrowing history details?
            {' '}
            <EditButton edit={props.edit} index={9} />
          </p>
          <BorrowingHistory borrowAnswer={props.borrowList} />
        </div>
      ) : undefined}
    </Styled>
  );
}

const Styled = styled.div`
  .question_wrapper {
    padding: 1rem;
    border-bottom: 1px solid #e5e5e5;
    p:nth-of-type(2) {
      font-size: 1.4rem;
    }
  }
  .title {
    font-size: 1.4rem;
    font-weight: 500;
    color: #505050;
    padding-bottom: 0.5rem;
  }
  .edit-btn {
    margin-left: 2rem;
    padding: 0.3rem;
    background: #00989c;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
`;
