import React from 'react';
import Main from 'components/Auth/ConfirmAccount';
import Navbar from 'components/Home/Navbars/Navbar';
import { Helmet } from 'react-helmet-async';

export default function ConfirmEmail() {
  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5' }}>
        <title>Email Confirmation | Gazelle by Sterling</title>
      </Helmet>
      <Navbar />
      <Main />
    </>
  );
}
