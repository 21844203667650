import { apiWithToken, apiWithoutToken } from './axiosInterceptor';

const request = {

  getOfferLetters: (data) => {
    const method = 'get';
    const url = '/api/WorkFlow/UserOfferLetterHistory/UserOfferLetterHistory';
    return apiWithToken({ method, url, params: { businessId: data } });
  },
  postOfferLetters: (data) => {
    const method = 'put';
    const url = '/api/WorkFlow/UploadSignedOfferLetter/UploadSignedOfferLetter';
    return apiWithToken({ method, url, data });
  },
  getOfferLettersByFacilityId: ({ facilityId }) => {
    const method = 'get';
    const url = '/api/WorkFlow/GetUserOfferLetter/GetUserOfferLetter';
    return apiWithToken({
      method,
      url,
      params: { facilityId },
    });
  },
  rejectOfferLetter: ({ facilityId }) => {
    const method = 'post';
    const url = '/api/WorkFlow/TerminateOfferLetter';
    return apiWithToken({
      method,
      url,
      params: { facilityId },
    });
  }
};

export default request;
