import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { NoteMarkSvg } from 'theme/icons';

export default function Tab(props) {
  const { checks } = useSelector((state) => state.collateral);

  return (
    <Styled className="orr_ques_nav">
      <div className="orr_ques_nav-item ">
        <NoteMarkSvg
          width="2rem"
          strokeWidth=".1px"
          className="section_tab-icon "
        />
        <p className="section_tab ">General</p>
      </div>

      {checks.state_classification && (
        <div className="orr_ques_nav-item ">
          <NoteMarkSvg
            width="2rem"
            strokeWidth=".1px"
            className="section_tab-icon"
          />
          <p className="section_tab">State Classification</p>
        </div>
      )}
      {checks.search_fee && (
        <div className="orr_ques_nav-item ">
          <NoteMarkSvg
            width="2rem"
            strokeWidth=".1px"
            className="section_tab-icon"
          />
          <p className="section_tab">Search Fee</p>
        </div>
      )}

      {checks.valuation && (
        <div className="orr_ques_nav-item ">
          <NoteMarkSvg
            width="2rem"
            strokeWidth=".1px"
            className="section_tab-icon"
          />
          <p className="section_tab">Valuation</p>
        </div>
      )}
      {props?.othersPages && (
        <div className="orr_ques_nav-item ">
          <NoteMarkSvg
            width="2rem"
            strokeWidth=".1px"
            className="section_tab-icon"
          />
          <p className="section_tab">Others</p>
        </div>
      )}
    </Styled>
  );
}
const Styled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #d9b08c;
  margin: 8rem 0 1rem;
  p {
    padding: 0 1rem;
  }
  @media (max-width: 80rem) {
    width: 100%;
  }
  .orr_ques_nav-item {
    text-align: center;
    cursor: pointer;
  }
  .section_tab {
    color: #b8b8b8;
    font-size: 14px;
  }
  .section_tab-active {
    color: #000;
  }
  .section_tab-icon {
    stroke: #b8b8b8;
    fill: #b8b8b8;
  }
  .section_tab-icon-active {
    stroke: #004c4e;
    fill: #004c4e;
  }
  .orr_ques_nav_active_tab {
    border-bottom: 3px solid #d9b08c;
  }
`;
