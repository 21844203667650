import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// APis
import { adminUserApis } from 'apis';
// Hooks
import useOrrFeedBack from 'hooks/useOrrFeedBack';
import useBusinessProfile from 'hooks/useBusinessProfile';
// Component
import Spinner from 'components/OverLays/OverLaySpinner';
import ErrorHandler from 'helpers/errorHandler';
import Button from 'components/Buttons';
import {
  PlaySvg,
  TrendingUpSvg,
  EditTextSvg,
  FastForwardSvg,
  ReceiptSvg,
  UploadSvg,
  FeedBackMailRedNotifySvg,
} from 'theme/icons';
import ConfirmSectorModal from './ConfirmSectorModal';
import UpdateRcNumberModal from './UpdateRcNumberModal';
import Layout from '../../Layouts/BusinessEvaDashboard';
import OrrChart from './ScoreChart';

export default function Index() {
  const navigate = useNavigate();
  const [buzProfile, isLoading, setLoader] = useBusinessProfile();
  const { business, auth } = useSelector((state) => state);
  const [isFeedBackAvailable] = useOrrFeedBack();

  const [orrModal, setOrrModal] = useState(false);
  const [rcNumberCheckModal, setRcNumberCheckModal] = useState(false);

  const closeOrrModal = () => {
    setOrrModal(false);
  };
  const closeRcNumberModal = () => {
    setRcNumberCheckModal(false);
  };
  const currentStatus = buzProfile[0]?.profileStatus.toLowerCase();
  const rcNumber = '' || business.businessProfiles[0].rcNumber;
  const startOrrHandler = () => {
    // check for Rc Number
    if (rcNumber == null || rcNumber?.trim() == '') {
      return setRcNumberCheckModal(true);
    }
    // business.businessProfiles[0].rcNumber
    if (currentStatus == 'initial') {
      setOrrModal(true);
    }
  };

  const continueApplicationHandler = async () => {
    if (rcNumber == null || rcNumber?.trim() == '') {
      return setRcNumberCheckModal(true);
    }
    if (
      currentStatus != 'orr-started'
      || currentStatus != 'orr-management'
      || currentStatus != 'orr-business'
      || currentStatus != 'orr-financial'
      || currentStatus != 'orr-bad-credit'
    ) {
      try {
        setLoader(true);
        const principalOfficersResponse = await adminUserApis.getGenericAnswers(
          auth?.user?.businessId
        );
        if (principalOfficersResponse?.data?.data?.length < 1) {
          toast.error('No Active Application');
          return setLoader(false);
        }
        if (currentStatus == 'orr-started') {
          // management
          return navigate('/app/orr/business-mgt-financial', { replace: true });
        } if (currentStatus == 'orr-management') {
          // business
          return navigate(
            '/app/orr/business-mgt-financial?dest=Business&status=returning',
            { replace: true }
          );
        } if (currentStatus == 'orr-business') {
          // financial
          return navigate(
            '/app/orr/business-mgt-financial?dest=Financials&status=returning',
            { replace: true }
          );
        } if (currentStatus == 'orr-bad-credit') {
          // financial
          return navigate('/app/orr/grade', { replace: true });
        }
        navigate('/app/orr/principals');
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    }
  };

  const startClasses = classnames('action-button', {
    disableButton: currentStatus != 'initial',
  });

  const continueObj = new Map([
    ['orr-started', true],
    ['orr-management', true],
    ['orr-business', true],
    ['orr-financial', true],
    ['orr-bad-credit', true],
  ]);

  const documentObj = new Map([
    ['orr-finished', true],
    ['orr-bad-credit', true],
  ]);

  // the status can manipulate the button
  const continueClasses = classnames('action-button', {
    disableButton: !continueObj.has(currentStatus),
  });

  const editClasses = classnames('action-button', {
    disableButton: !buzProfile[0]?.isNotEditable,
  });

  const trackClasses = classnames('action-button', {
    disableButton: currentStatus == 'initial',
  });

  const documentClasses = classnames('action-button', {
    disableButton: !documentObj.has(currentStatus),
  });

  const reviewClasses = classnames('action-button', {
    disableButton: currentStatus != 'orr-finished',
  });

  const trackHandler = () => {
    navigate('/app/orr/tracker');
  };

  const reviewHandler = () => {
    navigate('/app/orr/dashboard/review');
  };
  const uploadHandler = () => {
    navigate('/app/orr/dashboard/upload');
  };

  const feedBackHandler = () => {
    navigate('/app/orr/feedback');
  };

  return (
    <Styled>
      {isLoading ? <Spinner /> : ''}
      <Layout activeBar="business-evaluation">
        <div className="wrapper">
          <section className="main-content">
            <div className="main-content-inner">
              <h3 className="main-content-title">Business Evaluation</h3>
              <div className="validation-container">
                <div className="validation-title">
                  <p>Validation Feedback</p>
                </div>

                {isFeedBackAvailable.loader ? (
                  <div className="validation-loader">
                    <p>loading...</p>
                  </div>
                ) : isFeedBackAvailable.status ? (
                  <div className="validation-body">
                    <div className="validation-item">
                      <figure>
                        <FeedBackMailRedNotifySvg />
                      </figure>
                      <p>
                        Dear Customer, you have pending validation feedback.
                        Kindly treat as soon as possible to complete your
                        evaluation.
                      </p>
                    </div>
                    <div className="validation-button">
                      <Button
                        onClick={feedBackHandler}
                        mode="1"
                        title="Treat Now"
                        style={{ padding: '.5rem 1rem' }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="validation-empty">
                    <p>No Feedback</p>
                  </div>
                )}
              </div>
              <div className="action-buttons-container">
                <div className="action-buttons-item">
                  <div className={startClasses} onClick={startOrrHandler} role="button">
                    <PlaySvg
                      width="4rem"
                      stroke="#004C4E"
                      className="action-button-icon"
                    />
                    <p>Start</p>
                  </div>
                  <div
                    className={continueClasses}
                    onClick={continueApplicationHandler}
                    role="button"
                  >
                    <FastForwardSvg
                      width="4rem"
                      stroke="#004C4E"
                      className="action-button-icon"
                    />
                    <p>Continue</p>
                  </div>
                  <div className={editClasses}>
                    <EditTextSvg
                      width="4rem"
                      height="4rem"
                      stroke="#004C4E"
                      strokeWidth=".1px"
                      className="action-button-icon"
                    />
                    <p>Edit</p>
                  </div>
                </div>
                <div className="divider" />
                <div className="action-buttons-item">
                  <div className={trackClasses} onClick={trackHandler} role="button">
                    <TrendingUpSvg
                      width="4rem"
                      height="4rem"
                      stroke="#004C4E"
                      className="action-button-icon"
                    />
                    <p>Track Progress</p>
                  </div>
                  <div className={reviewClasses} onClick={reviewHandler} role="button">
                    <ReceiptSvg
                      width="4rem"
                      height="3rem"
                      stroke="#004C4E"
                      strokeWidth=".1px"
                      className="action-button-icon"
                    />
                    <p>Review Questions</p>
                  </div>
                  <div className={documentClasses} onClick={uploadHandler} role="button">
                    <UploadSvg
                      width="4rem"
                      height="3rem"
                      strokeWidth=".1px"
                      stroke="#004C4E"
                      className="action-button-icon"
                    />
                    <p>Document Upload</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="facility-summary">
            <div className="score-title">
              <p>ORR Score Summary</p>
            </div>
            {currentStatus != 'initial' ? (
              <OrrChart businessId={auth?.user?.businessId} />
            ) : (
              <div className="empty-orr">Not Available</div>
            )}
          </section>
        </div>
      </Layout>
      {orrModal && (
        <ConfirmSectorModal
          auth={auth}
          closeModal={closeOrrModal}
          isOpen={orrModal}
          subSectorId={business?.businessProfiles[0]?.subSectorId}
        />
      )}
      {rcNumberCheckModal && (
        <UpdateRcNumberModal
          auth={auth}
          closeModal={closeRcNumberModal}
          isOpen={rcNumberCheckModal}
          subSectorId={business?.businessProfiles[0]?.subSectorId}
        />
      )}
    </Styled>
  );
}
const Styled = styled.div`
  .wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 2rem;
    @media (max-width: 80rem) {
      flex-direction: column;
    }
  }

  .facility-summary {
    width: 25%;
    max-width: 30rem;
    margin-top: 9rem;
    border-radius: 5px;
    @media (max-width: 140rem) {
      width: 30%;
    }
    @media (max-width: 900px) {
      margin-top: 2rem;
      min-width: 30rem;
      max-width: 40%;
    }
    & > div:nth-of-type(1) {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: #d1e8e2;
      padding: 5px 1rem;
      width: 100%;
      text-align: center;
      color: #004c4e;
    }
  }
  .type1 {
    background: #d1e8e2;
  }
  .type2 {
    background: #d8feff;
    margin-top: 8rem;
  }
  .type3 {
    background: #ffddbf;
  }
  .divider {
    border: thin solid #d1e8e2;
    margin: 2rem 0;
    display: block;
    width: 100%;
  }
  .main-content {
    width: 60%;
    display: flex;
    justify-content: center;
    @media (max-width: 80rem) {
      width: 100%;
    }
  }
  .main-content-inner {
    width: 100%;
  }
  .main-content-title {
    color: #004c4e;
    padding: 0.8rem;
    margin-top: 5rem;
    font-weight: 500;
  }
  .action-buttons-container {
    background-color: #fff;
    padding: 4rem 3rem;
    border-radius: 1rem;
    @media (max-width: 90rem) {
      padding: 2rem 1.5rem;
    }
  }
  .action-buttons-item {
    display: flex;
    justify-content: space-between;
  }
  .action-button {
    max-width: 20rem;
    width: 100%;
    background: #fff;
    padding: 1.5rem 3rem;
    font-size: 1.4rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    border: 2px solid #d1e8e2;
    color: #004c4e;
    :hover {
      background: #a7cfd2;
      color: #004c4e;
    }
    @media (max-width: 950px) {
      max-width: 15rem;
      padding: 1rem 2rem;
    }
  }
  .disableButton {
    pointer-events: none !important;
    color: grey;
    .action-button-icon {
      stroke: grey;
      fill: grey;
    }
    .action-button-icon-type2 {
      stroke: grey;
      fill: grey;
    }
  }

  .action-button-icon {
    margin-bottom: 1rem;
    fill: #004c4e;
  }
  .action-button-icon-type2 {
    margin-bottom: 1rem;
    color: #004c4e;
  }
  .validation-title {
    background: #d1e8e2;
    color: #004c4e;
    padding: 0.8rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    p {
      font-size: 1.4rem;
    }
  }
  .validation-container {
    margin-bottom: 4rem;
    background: #fff;
    padding-bottom: 1rem;
    border-radius: 8px;
  }
  .validation-body {
    padding: 1rem;
  }
  .empty-orr {
    background: #fff;
    padding: 1rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .validation-item {
    display: flex;
    p {
      font-size: 1.4rem;
      margin-left: 1rem;
      color: #d45f36;
    }
  }
  .validation-button {
    display: flex;
    justify-content: flex-end;
  }
  .validation-empty {
    text-align: center;
    padding: 0.5rem;
    font-size: 1.4rem;
  }
  .validation-loader {
    padding: 0.5rem;
    text-align: center;
  }
  .score-title {
    font-size: 1.4rem;
    font-weight: 600;
  }
`;
