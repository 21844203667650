import React from 'react';
import Navbar from 'components/Home/Navbars/Navbar';
import Footer from 'components/Home/Main/Footer';
import Main from 'components/Home/Main/Main';
import { Helmet } from 'react-helmet-async';

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Home | Gazelle by Sterling</title>
      </Helmet>
      <Navbar />
      <Main />
      <Footer />
    </>
  );
}
