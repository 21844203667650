import React from 'react';
import Navbar from 'components/Home/Navbars/NavbarV2';
import SignUpForm from 'components/Auth/SignUp/SignUp';
import { Helmet } from 'react-helmet-async';
import Footer from 'components/Home/Main/Footer';

export default function index() {
  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5' }}>
        <title>Sign Up | Gazelle by Sterling</title>
      </Helmet>
      <Navbar />
      <SignUpForm />
      <Footer />
    </>
  );
}
