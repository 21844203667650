import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import settingsApis from 'apis/settings';

export default function useSubSectorImage() {
  const {
    business: { businessProfiles },
  } = useSelector((state) => state);
  const [subSectorImage, setUrl] = useState('');
  const [isImageLoading, setLoader] = useState(false);

  useEffect(() => {
    const isActive = true;
    const getImage = async () => {
      try {
        setLoader(true);
        const res = await settingsApis.getSubSectorImage(
          businessProfiles[0].subSectorId
        );
        setUrl(res.data.data);
      } catch (error) {
      } finally {
        setLoader(false);
      }
    };
    isActive && getImage();
    return () => {};
  }, []);
  return [subSectorImage, isImageLoading];
}
