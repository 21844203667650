import React, { useState } from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import * as SignUpActions from 'store/actions/signUpForm';
import styled from 'styled-components';
import AccountInput from './AccountInput';
import OtpValidation from './OtpValidation';

const customStyles = {
  content: {
    borderRadius: '10px',
    position: 'relative',
    margin: 'auto',
    inset: '0',
  },
};
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0 0 0 / 54%)';
export default function AccountValidation(props) {
  const dispatch = useDispatch();
  const onValidateCompanyHandler = () => {
    dispatch(SignUpActions.SaveActivePage('newExitingCreateUser'));
  };
  const [accountPage, setAccountPage] = useState(false);
  const handlerCloseModal = () => {
    setAccountPage(false);
    props.closeModal();
  };
  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      isOpen={props.modalIsOpen}
      onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="bvnValidation"
    >
      <Styled>
        <div className="closeBtn">
          <FiX size={20} color="#505050" onClick={handlerCloseModal} />
        </div>
        {accountPage ? (
          <OtpValidation onValidateCompanyHandler={onValidateCompanyHandler} />
        ) : (
          <AccountInput
            setAccountPage={setAccountPage}
            closeModal={props.closeModal}
          />
        )}
      </Styled>
    </Modal>
  );
}
const Styled = styled.div`
  margin-top: 1rem;
  color: #505050;
  width: 400px;
  .closeBtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .title {
    margin-bottom: 1rem;
    font-weight: 500;
    color: #004c4e;
  }
  .correct {
    margin: 2rem 0;
    color: #004c4e;
  }
  .buttonRow {
    display: flex;
    margin-top: 2rem;
    align-items: center;
    justify-content: flex-end;
    color: #004c4e;
    button {
      margin-left: 2rem;
    }
  }
`;
