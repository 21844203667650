import {
  UPDATE_FACILITY_QUESTIONS_ANSWERED,
  UPDATE_FACILITY_GENERAL_QUESTIONS_ANSWERED,
  UPDATE_FACILITY_PURPOSE_QUESTIONS_ANSWERED,
  UPDATE_FRR_RESPONSE_ANSWERED,
  UPDATE_FACILITY_ID
}
  from 'store/action-types/sub-purpose-questions';

const initialState = {
  facilityfilledData: {

  },
  answeredPurposeQuestions: [

  ],
  answeredGeneralQuestions: [

  ],
  frrResponseData: [

  ],
  facilityId: {

  }
};

const facilityAnsweredQuestions = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FACILITY_QUESTIONS_ANSWERED:
      return {
        ...state,
        facilityfilledData: {
          ...action.payload
        }
      };
    case UPDATE_FACILITY_GENERAL_QUESTIONS_ANSWERED:
      return {
        ...state,
        answeredGeneralQuestions: [
          ...action.payload
        ]
      };
    case UPDATE_FACILITY_PURPOSE_QUESTIONS_ANSWERED:
      return {
        ...state,
        answeredPurposeQuestions: [
          ...action.payload
        ]
      };
    case UPDATE_FRR_RESPONSE_ANSWERED:
      return {
        ...state,
        frrResponseData: [
          ...action.payload
        ]
      };
    case UPDATE_FACILITY_ID:
      return {
        ...state,
        facilityId: {
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default facilityAnsweredQuestions;
