import axios from 'axios';
import jwt from 'jwt-decode';
// import { toast } from 'react-toastify';
// import { type } from 'ramda';
// import { useNavigate } from 'react-router-dom';
// import * as AuthActions from 'store/actions/auth';
import { store } from 'store/store';
import { encryptHandler, decryptHandler } from 'helpers/encryption';
import { config } from '../config/index';

const encrypt = (data) => `"${encryptHandler(data)}"`;

const apiWithToken = axios.create({
  baseURL: config.serverURI,
  headers: { 'Content-Type': 'text/json' },
  responseType: 'text',
});

const apiWithoutToken = axios.create({
  baseURL: config.serverURI,
  headers: { 'Content-Type': 'text/json' },
  responseType: 'text',
});

export const checkExpiredToken = (tokens) => {
  if (tokens) {
    const { exp } = jwt(tokens);
    const time = exp * 1000;
    const isExpired = time > Date.now();
    return !isExpired;
  }
  return true;
};

apiWithToken.interceptors.request.use(
  (config) => {
    // const navigate = useNavigate();
    const getToken = store?.getState()?.auth?.token;
    config.headers.Authorization = `Bearer ${getToken}`;
    config.headers['Content-Type'] = 'text/json';
    if (!config.url.toLowerCase().includes('uploaddocumenttoazureblob')) {
      config.data = encrypt(config.data);
    }

    // config.headers['Content-Type'] = 'application/json-patch+json';
    // configs.responseType = 'text';

    // const tokenStatus = checkExpiredToken(getToken);
    // if (tokenStatus) {
    //   toast.error('Expired Token');

    //   navigate('/auth/login');
    //   store.dispatch(AuthActions.AuthResetUser());
    //   throw new axios.Cancel('Expired Token 2');
    //   // Promise.reject('Expired Token');
    //   // log out the user
    // }
    return config;
  },
  (error) => {
    error.response.data = decryptHandler(error.response.data);
    return Promise.reject(error, 'axios interceptor');
  }
);
apiWithToken.interceptors.response.use(
  (response) => {
    if (response.headers['content-type'].includes('text/plain')) {
      response.data = decryptHandler(response.data);
    }
    return response;
  },
  (error) => {
    error.response.data = decryptHandler(error.response.data);
    return Promise.reject(error);
  }
);

apiWithoutToken.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'text/json';
    config.data = encrypt(config.data);
    return config;
  },
  (error) => {
    error.response.data = decryptHandler(error.response.data);
    return Promise.reject(error, 'axios interceptor');
  }
);

apiWithoutToken.interceptors.response.use(
  (response) => {
    response.data = decryptHandler(response.data);
    return response;
  },
  (error) => {
    error.response.data = decryptHandler(error.response.data);
    console.log('err', error)
    return Promise.reject(error);
  }
);

export { apiWithToken, apiWithoutToken };
