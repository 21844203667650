import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import TellUsAboutYou from './TellUsAboutYou';
import Business from './Business';
import Loan from './Loan';
import TINOrAccountNo from './TINOrAccountNo';
import FinalStep from './FinalStep';
import CreateUser from './CreateUser';

export default function SignUp() {
  const { signUpForm } = useSelector((state) => state);

  let render;

  if (signUpForm.activePage == 'aboutYou') {
    render = <TellUsAboutYou />;
  } else if (signUpForm.activePage == 'business') {
    render = <Business />;
  } else if (signUpForm.activePage == 'loan') {
    render = <Loan />;
  } else if (signUpForm.activePage == 'tinOrAcc') {
    render = <TINOrAccountNo />;
  } else if (signUpForm.activePage == 'newExitingCreateUser') {
    render = <FinalStep />;
  } else if (signUpForm.activePage == 'createUser') {
    render = <CreateUser />;
  }

  return (
    <Styled>
      <div className="main">{render}</div>
      <div className="aside">
        <img
          src="https://businesslending.azureedge.net/web/assets/images/pre_signup_man_smiling.jpg"
          alt=""
        />
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  display: flex;
  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;
    @media (max-width: 1050px) {
      width: 80%;
    }
  }
  .aside {
    margin: 0 auto;
    width: 40%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }
`;
