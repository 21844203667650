import React from 'react';
import StarRatings from 'react-star-ratings';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import _cloneDeep from 'lodash/cloneDeep';
import ListComponent from './ListType';
import ContactComponent from './ContactList';

export default function Question(props) {
  const changeRating = (newRating, name) => {
    props.setStar(newRating);
    props.updateQuestionsPoolHandler(props.index, newRating);
  };

  const selectHandler = (selectedOption) => {
    props.setSelected(selectedOption);
    props.updateQuestionsPoolHandler(props.index, selectedOption);
  };

  const multipleHandler = (selectedOption) => {
    const checkIfIdExist = props.selectedMultiple.some(
      (elem) => elem.answerId === selectedOption.answerId
    );

    const _clonedSelectedMultiple = _cloneDeep(props.selectedMultiple);

    if (checkIfIdExist) {
      const _newState = _clonedSelectedMultiple.filter(
        (elem) => elem.answerId !== selectedOption.answerId
      );
      props.setSelectedMultiple(_newState);
      props.updateQuestionsPoolHandler(props.index, _newState);
      return _newState;
    }
    const newState = [..._clonedSelectedMultiple, selectedOption];
    props.setSelectedMultiple(newState);
    props.updateQuestionsPoolHandler(props.index, newState);
  };

  const inputHandler = (e) => {
    props.setInput(e.target.value);
    props.updateQuestionsPoolHandler(props.index, e.target.value);
  };

  const listHandler = (data) => {
    props.updateQuestionsPoolHandler(props.index, data);
  };

  const contactHandler = (data) => {
    props.updateQuestionsPoolHandler(props.index, data);
  };

  let render;

  switch (props?.question?.questionType) {
    case 'text':
      render = (
        <input
          autoComplete="off"
          type="text"
          onChange={inputHandler}
          name="name"
          className="typeText"
          value={props?.question.inputtedAnswer || props.input}
        />
      );
      break;
    case 'number':
      render = (
        <NumberFormat
          className="typeNumV2"
          thousandSeparator
          name="name"
          value={props?.question?.inputtedAnswer || props.input}
          onChange={inputHandler}
        />
      );
      break;
    case 'star':
      render = (
        <div style={{ textAlign: 'center' }}>
          <StarRatings
            starRatedColor="#D4AF37"
            starHoverColor="#D4AF37"
            name="rating"
            changeRating={changeRating}
            rating={Number(props.question.inputtedAnswer) || props.star}
            style={{ textAlign: 'center' }}
          />
        </div>
      );
      break;
    case 'single':
      render = (
        <div className="select">
          {props.question.answersList.map((elem, index) => (
            <button
              type="button"
              key={index}
              className={`select_item ${
                props.selected == elem && 'selected_item'
              } ${
                props?.question?.selectedAnswer?.answerId == elem?.answerId
                  && 'selected_item'
              }`}
              onClick={() => selectHandler(elem)}
            >
              {elem.answer}
            </button>
          ))}
        </div>
      );
      break;
    case 'Multiple':
      render = (
        <div className="select">
          {props.question.answersList.map((elem, index) => (
            <button
              type="button"
              key={index}
              className={`select_item
                  ${
            props?.selectedMultiple?.some(
              (item) => item.answerId === elem.answerId
            ) && 'selected_item'
            }
                  ${
            props?.question?.selectedAnswer?.some(
              (item) => item.answerId === elem.answerId
            ) && 'selected_item'
            }
                  `}
              onClick={() => multipleHandler(elem)}
            >
              {elem.answer}
            </button>
          ))}
        </div>
      );
      break;

    case 'dropdown':
      render = (
        <div>
          <select
            value={props?.question?.inputtedAnswer}
            name="dropdown"
            id=""
            className="dropdown"
            onChange={inputHandler}
          >
            <option>Select</option>
            {props.question.answersList.map((elem, index) => (
              <option key={index} value={elem.answerId}>
                {elem.answer}
              </option>
            ))}
          </select>
        </div>
      );
      break;
    case 'long-text':
      render = (
        <textarea
          autoComplete="off"
          onChange={inputHandler}
          name="name"
          className="typeTextArea"
          value={props?.question?.inputtedAnswer || props.input}
        />
      );
      break;
    case 'list':
      render = <ListComponent listHandler={listHandler} {...props} />;
      break;
    case 'contact-list':
      render = <ContactComponent listHandler={contactHandler} {...props} />;
      break;
    case 'account-number':
      render = (
        <input
          autoComplete="off"
          onChange={inputHandler}
          name="account-number"
          className="typeAcc"
          maxLength="10"
          value={props?.question?.inputtedAnswer || props.input}
        />
      );
      break;
    default:
      render = (
        <input
          autoComplete="off"
          onChange={inputHandler}
          name="name"
          className="typeText"
          value={props?.question?.inputtedAnswer || props.input}
        />
      );
  }
  return (
    <Styled>
      <div>
        <p className="question_title">{props?.question?.question}</p>
      </div>
      {render}
      <section className="indicationWrapper">
        <p style={{ fontSize: '12px' }}>
          {`${props.index + 1} of `}
          <strong
            style={{ fontSize: '14px' }}
          >
            {`${props?.questionLength}`}
          </strong>
        </p>
      </section>
    </Styled>
  );
}
const Styled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7%;
  width: 100%;
  overflow: hidden;
  height: 44.4rem;
  @media (max-width: 1280px) {
    padding: 0 5%;
  }
  .question_title {
    text-align: center;
    margin: 2rem 0;
  }
  .select {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .select_item {
    background: #fff;
    padding: 1rem 3rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    border: 1px solid #e5e5e5;
    border-radius: 0.5rem;
    outline: none;
    &:hover {
      border: 1px solid #00989c;
    }
  }
  .selected_item {
    border: 1px solid #00989c;
    color: #000;
  }
  .typeText {
    padding: 1rem;
    max-width: 60rem;
    min-width: 20rem;
    width: 80%;
    border-radius: 0.5rem;
    align-self: center;
  }
  .typeTextArea {
    width: 100%;
    height: 10rem;
    resize: none;
    padding: 0.5rem;
    border: thin solid #d1e8e2;
  }
  .typeAcc{
    padding: 1rem;
    max-width: 60rem;
    min-width: 20rem;
    width: 20%;
    border-radius: 0.5rem;
    align-self: center;
  }

  .typeNumV2 {
    padding: 1rem;
    max-width: 60rem;
    min-width: 20rem;
    width: 50%;
    text-align: right;
    align-self: center;
  }
  input,
  select {
    width: 80%;
    padding: 1rem;
    font-size: 1.6rem;
    display: block;
    border: 1px solid #00989c;
    border-radius: 5px;
    padding-right: 3.5rem;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right 0.9em top 50%;
    background-size: 0.65em auto;
    background-color: #b8b8b8;
  }

  .indicationWrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
  .dropdown {
    width: 100%;
    background-color: #f9f9f9;
  }
`;
