import React, { useState } from 'react';
import Button from 'components/Buttons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from 'components/FormikInputs/Input';
import { validationApis, firsApis } from 'apis';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import * as SignUpFormActions from 'store/actions/signUpForm';
import ErrorHandler from 'helpers/errorHandler';
import styled from 'styled-components';

export default function BvnInput(props) {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = yup.object({
    bvn: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only Numbers')
      .min(11, 'Must be exactly 11 digits')
      .max(11, 'Must be exactly 11 digits')
      .required('Bvn is required'),
  });
  const formik = useFormik({
    initialValues: {
      bvn: '',
    },
    validationSchema,
    onSubmit: (values) => {
      checkIfBVNExist(values.bvn);
    },
  });
  const checkIfBVNExist = async (bvn) => {
    setLoader(true);
    try {
      const response = await validationApis.checkExistBvn(bvn);

      // remember to interchange the true and false
      if (response.status == 200 && response.data.status == true) {
        toast.error('BVN Already Exist');
        return setLoader(false);
      }

      onValidateBvn(bvn);
    } catch (error) {
      setLoader(false);
      ErrorHandler(error);
    }
  };
  const onValidateBvn = async (data) => {
    try {
      const response1 = await firsApis.getBvn(data);

      if (
        response1.data.status
        && response1?.data?.data?.bvn !== 'BVN does not exit'
      ) {
        if(response1?.data?.data?.email === '' && response1?.data?.data?.phoneNumber === ''){
          return toast.error('BVN email address and phone number are not available.');
        }else{
          dispatch(SignUpFormActions.SaveBVN(response1.data.data));
          const response2 = await firsApis.generateEmailPhoneOtp({
            phone: response1?.data?.data?.phoneNumber,
            email: response1?.data?.data?.email || '',
          });
          if (response1.data.status && response2.data.data == '00') {
            props.setBvnPage(true);
          }
          if (response1.data.status && response2.data.data === '') {
            toast.error('Kindly check your bvn has email and phone number attached');
          }
          if (response2?.data?.status && response2?.data?.data === '99') {
            return toast.error(response2?.data?.message);
          }
        }
       
      } else if (
        response1.data.status
        && response1?.data?.data?.bvn == 'BVN does not exit'
      ) {
        toast.error('BVN does not exist. Kindly check and re-enter');
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <Styled>
      <p className="title">BVN Validation</p>
      <p className="subTitle">
        Dear
        {' '}
        {props.userData.customerName}
        , kindly provide your BVN for
        validation.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          name="bvn"
          type="textNumber"
          maxLength="11"
          required
          formik={formik}
          style={{
            backgroundColor: '#e5e5e5',
            textAlign: 'center',
            width: '60%',
            margin: '2rem auto 0 auto',
          }}
          placeholder="Enter BVN"
        />
        <div className="submitBtn">
          <Button loader={loader} type="submit" title="Validate" mode="4" />
        </div>
      </form>
    </Styled>
  );
}
const Styled = styled.div`
  width: 300px;
  form {
    margin-top: 3rem;
    text-align: center;
  }
  .title {
    margin-bottom: 1rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
  }
  .subTitle {
    font-size: 1.4rem;
    text-align: center;
  }
  .submitBtn {
    display: flex;
    justify-content: flex-end;
  }
`;
