import React, { useState, useEffect } from 'react';
import frrApis from 'apis/frr';
import styled from 'styled-components';
import { formatDate } from 'helpers/dateFormat';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { mergeAnswerWithQuestions } from 'helpers/questionsAnswers';
import Spinner from 'components/OverLays/OverLaySpinner';
import Button from 'components/Buttons';
import Header from 'components/Headers/Collateral';
import { AnswerContainer } from 'components/AnswerReview/QuesAnswerTypesView';

function TitleValue({ title, value, isDisplay = true }) {
  return isDisplay ? (
    <div>
      <p className="text_lt">
        {title}
        :
      </p>
      <p className="text_rn">{value}</p>
    </div>
  ) : (
    ''
  );
}

export default function Review() {
  const navigate = useNavigate();

  const query = useParams();
  const [searchParams] = useSearchParams();
  const [collateralInfoObj, setCollateralInfoObj] = useState(null);
  const [questionAns, setQuestionAns] = useState(null);
  const [current, setCurrent] = useState(1);
  const [isLoading, setLoading] = useState(false);

  const collateralTypeId = searchParams.get('collateralTypeId');
  const collateralInfo = collateralInfoObj?.x;
  const checks = collateralInfoObj?.x?.collateralType;

  useEffect(() => {
    const getQuestions = async () => {
      setLoading(true);
      const res = await frrApis.getCollateralById({
        collateralId: query?.id,
      });
      setCollateralInfoObj(res.data.data);
      const res2 = await frrApis.getCollateralResponseByCollateralId({
        collateral: query?.id,
      });
      const res3 = await frrApis.getCollateralQuesAndAnswersByCollateralTypeId({
        collateralTypeId,
      });
      setQuestionAns(mergeAnswerWithQuestions(res3.data.data, res2.data.data));
      setLoading(false);
    };

    getQuestions();
  }, []);

  const getSearchFee = (obj) => {
    const propertyType = obj?.x?.searchFeePropertyType;

    let searchFee;
    if (propertyType == 'Residential') {
      searchFee = obj?.w?.residentialTotalFee;
    } else if (propertyType == 'Commercial') {
      searchFee = obj?.w?.commercialTotalFee;
    }

    return searchFee;
  };

  return (
    <Styled>
      <Header />
      {isLoading ? <Spinner /> : ''}
      <div className="container_layer1">
        <div className="box">
          <p className="review_title">Review Your Answers</p>
        </div>
        <section className="tab_form">
          <div className="tab_form_nav">
            <div
              className={`tab_form_nav_item ${
                current == 1 && 'tab_form_nav_item-active'
              }`}
              onClick={() => setCurrent(1)}
            >
              Summary
            </div>
            <div
              className={`tab_form_nav_item ${
                current == 2 && 'tab_form_nav_item-active'
              }`}
              onClick={() => setCurrent(2)}
            >
              Questions
            </div>
          </div>
          <div className="tab_form_main">
            {current == 1 && (
              <div>
                <div>
                  <TitleValue
                    title="Collateral Type"
                    value={
                      collateralInfo?.collateralTypeId
                      && collateralInfo?.collateralType?.collateralTypeName
                    }
                  />
                  <TitleValue
                    title="Collateral Seniority"
                    value={
                      typeof collateralInfo?.collateralSeniorityValue
                        === 'number' && collateralInfo.collateralSeniorityName
                    }
                  />
                  <TitleValue
                    title="Security Value"
                    value={collateralInfo?.securityValue}
                  />
                </div>
                {/* state classification */}

                {checks?.stateClassification && (
                  <section className="state_classification">
                    <div className="sub_header">
                      <p className="title">State Classification</p>
                    </div>
                    <TitleValue
                      title="State"
                      value={
                        collateralInfo.lgaId
                        && collateralInfoObj?.y?.state?.stateName
                      }
                    />
                    <TitleValue
                      title="Local Government"
                      value={
                        collateralInfo.lgaId && collateralInfoObj?.y?.lgaName
                      }
                    />
                  </section>
                )}
                {/* search fee */}
                {checks?.searchFees && (
                  <section className="search_fee">
                    <div className="sub_header">
                      <p className="title">Search Fee</p>
                    </div>

                    <TitleValue
                      title="Property Type"
                      value={collateralInfo.searchFeePropertyType}
                    />
                    <TitleValue
                      title="Payment Now"
                      value={collateralInfo.searchFeePayNow && 'Yes'}
                    />
                    {/* <TitleValue title="Fee" value={solicitorFee} /> */}
                  </section>
                )}
                {/* Valuation */}
                {checks?.valuationValidityPeriodAndPricing && (
                  <section className="valuation">
                    <div className="sub_header">
                      <p className="title">Valuation</p>
                    </div>
                    <div>
                      <TitleValue
                        title="Have you carried out valuation on collateral"
                        value={collateralInfo?.isValuationExisting && 'Yes'}
                      />
                      <TitleValue
                        title="Will you like to carry out a valuation"
                        value={
                          collateralInfo?.carryOutNewValuation ? 'Yes' : 'No'
                        }
                        isDisplay={collateralInfo.carryOutNewValuation}
                      />

                      <TitleValue
                        title="Valuation Fee"
                        value={getSearchFee(collateralInfoObj)}
                        isDisplay={collateralInfo?.pricingId}
                      />
                      {/* <TitleValue
                title="Valuer"
                value={
                  collateralInfo?.knownValuerId && getSessionData('_valuer')
                }
                isDisplay={collateralInfo.knownValuerId}
              /> */}
                      <TitleValue
                        title="Valuation Date"
                        value={
                          collateralInfo?.valuationDate
                          && formatDate(collateralInfo?.valuationDate)
                        }
                        isDisplay={collateralInfo.valuationDate}
                      />
                      <TitleValue
                        title="Valuation Estimate"
                        value={collateralInfo?.valuationEstimate}
                        isDisplay={collateralInfo.valuationEstimate}
                      />
                    </div>
                  </section>
                )}
              </div>
            )}
            {current == 2 && (
              <div>
                {questionAns
                  ?.filter(
                    (elem) => (elem.questionType !== 'File'
                        && elem.inputtedAnswer != 'undefined'
                        && elem.selectedAnswer != 'undefined'
                        && elem.inputtedAnswer != undefined)
                      || elem.selectedAnswer != undefined
                  )
                  ?.map((elem, i) => (
                    <div key={i} className="tab_form_main-item">
                      <p className="review_question_title">
                        <span className="button_circle" />
                        Question
                      </p>
                      <p className="review_question">{elem.question}</p>
                      <p className="review_answer_title">
                        <span className="button_circle_green" />
                        Answer
                      </p>
                      <AnswerContainer questionProperties={elem} />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </section>
        <div className="orr_button_section">
          <div />
          <Button
            mode="1"
            onClick={() => navigate(`/app/collateral-details/${query?.id}`, {
              replace: true,
            })}
          >
            Back
          </Button>
        </div>
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  background-color: #e5e5e5;
  height: 100%;
  min-height: 100vh;
  .container_layer1 {
    margin: 0 auto;
    padding: 0 10%;
    max-width: 1440px;
    @media (max-width: 1100px) {
      padding: 0 4%;
    }
    @media (max-width: 479px) {
      padding: 0 2%;
    }
  }

  .box {
    margin: 0 auto;
    /* max-width: 90rem; */
    width: 100%;
    padding: 1rem;
  }
  .header {
    text-align: center;
    .title {
      font-size: 1.8rem;
      font-weight: 500;
      text-transform: uppercase;
    }
    margin-bottom: 2rem;
  }
  section {
    margin-top: 2rem;
  }
  .sub_header {
    .title {
      font-weight: 500;
    }
  }
  .text_lt {
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
  }
  .text_rn {
    display: inline-block;
    padding-left: 1rem;
    font-size: 1.4rem;
    text-transform: capitalize;
  }
  .tab_form_main {
    padding: 1rem 2rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 50rem;
    overflow-y: scroll;
    background: #fff;
  }
  .tab_form_main-item {
    padding: 1rem 0;
    border-bottom: 1px solid #e5e5e5;
  }
  .tab_form_nav {
    display: flex;
    justify-content: space-between;
  }
  .tab_form_nav_item {
    color: #808080;
    padding: 0.8rem;
    text-align: center;
    cursor: pointer;
    width: 100%;
    background: #fff;
    font-weight: 500;
    border-bottom: thin solid #e5e5e5;
    font-size: 1.4rem;
    &:nth-of-type(1) {
      border-top-left-radius: 5px;
    }
    &:nth-of-type(3) {
      border-top-right-radius: 5px;
    }
  }
  .tab_form_nav_item-active {
    color: #00989c;
    border-bottom: 3px solid #00989c;
  }
  .review_title {
    font-weight: 500;
    color: #004c4e;
    margin: 1rem 2rem 0;
    font-size: 2rem;
  }
  .review_answer_title,
  .review_question_title {
    font-size: 1.4rem;
    font-weight: 500;
    color: #505050;
    padding-top: 0.5rem;
  }
  .review_answer,
  .review_question {
    display: block;
    font-size: 1.4rem;
    color: #808080;
    padding-left: 1.2rem;
  }
  .button_circle {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 5rem;
    background: #d9b08c;
    display: inline-block;
    margin-right: 0.5rem;
  }
  .button_circle_green {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 5rem;
    background: #00989c;
    display: inline-block;
    margin-right: 0.5rem;
  }
  .orr_button_section {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
`;
