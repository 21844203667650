import { apiWithToken, apiWithoutToken } from './axiosInterceptor';

const request = {
  // Orr
  updateOrrResponse: (data) => {
    const method = 'post';
    const url = '/api/ORR/SubmitAndUpdateOrrResponse';
    return apiWithToken({ method, url, data });
  },
  getOrrResponse: (data) => {
    const method = 'get';
    const url = '/api/ORR/GetOrrResponseByBusinessId';
    return apiWithToken({ method, url, params: { BusinessId: data } });
  },
  getAuditorsList: () => {
    const method = 'get';
    const url = '/api/ORR/GetAllICANListOfFirms';
    return apiWithoutToken({
      method,
      url,
    });
  },
  uploadDocument: (data, params, config) => {
    const method = 'post';
    const url = '/api/ORR/UploadDocumentToAzureBlob';
    /// api/ORR/UploadDocument -- /api/ORR/UploadDocumentToAzureBlob
    return apiWithToken({
      method,
      url,
      data,
      cancelToken: config.cancelToken,
      params: {
        docLibName: params.docLibName,
        businessId: params.businessId,
      },
      onUploadProgress: params.onUploadProgress,
    });
  },
  retrieveDocument: (data) => {
    const method = 'get';
    const url = '/api/ORR/RetrieveDocumentFromAzureBlob';
    // '/api/ORR/RetrieveDocument';
    return apiWithToken({
      method,
      url,
      params: { FileUrl: data },
    });
  },
  calculateFinalORRResponse: (data) => {
    const method = 'get';
    const url = '/api/ORR/CalculateFinalORRResponse';
    return apiWithToken({
      method,
      url,
      params: { businessId: data },
    });
  },
  creditHistorySearch: (data) => {
    const method = 'post';
    const url = '/api/ORR/CreditHistorySearch';
    return apiWithToken({
      method,
      url,
      data,
    });
  },
  corporateCreditHistorySearch: (data) => {
    const method = 'post';
    const url = '/api/ORR/CorporateCreditHistorySearch';
    return apiWithToken({
      method,
      url,
      data,
    });
  },
  postOrrFinancialAnswers: (data) => {
    const method = 'post';
    const url = '/api/ORR/CreateOrrFinancial';
    return apiWithToken({
      method,
      url,
      data,
    });
  },
  putOrrFinancialAnswers: (data) => {
    const method = 'put';
    const url = '/api/ORR/UpdateOrrFinancial';
    return apiWithToken({
      method,
      url,
      data,
      // params: { id: data.id },
    });
  },
  getOrrFinancialAnswers: (data) => {
    const method = 'get';
    const url = '/api/ORR/getOrrFinancialByBusinessId';
    return apiWithToken({
      method,
      url,
      params: { businessId: data },
    });
  },
  getFinancialUploadUrl: (data) => {
    const method = 'get';
    const url = '/api/ORR/GetAuditorFinancialDocumentByBusinessId';
    return apiWithToken({
      method,
      url,
      params: { businessId: data },
    });
  },
  updateFinancialUploadUrl: (data) => {
    const method = 'put';
    const url = '/api/ORR/UpdateAuditorFinancialDocumentByBusinessId';
    return apiWithToken({
      method,
      url,
      params: {
        BusinessId: data.businessId,
        auditedFinancialsDocument: data.doc,
      },
    });
  },
  postBrowsingHistory: (data) => {
    const method = 'post';
    const url = '/api/ORR/CreateBorrowingHistory';
    return apiWithToken({
      method,
      url,
      data,
    });
  },
  getBrowsingHistory: (businessId) => {
    const method = 'get';
    const url = '/api/ORR/GetBorrowingHistoryByBusinessId';
    return apiWithToken({
      method,
      url,
      params: {
        businessId,
      },
    });
  },
  getSectorBySectorCode: (sectorCode) => {
    const method = 'get';
    const url = `/api/orr/getSectorByCode/${sectorCode}`;
    return apiWithToken({
      method,
      url
    });
  },
};
export default request;
