import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';
import styled from 'styled-components';
import Button from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import { adminUserApis } from 'apis';
import ErrorHandler from 'helpers/errorHandler';

const customStyles = {
  content: {
    borderRadius: '10px',
    background: '#505050',
    color: '#fff',
    border: 'none',
    position: 'relative',
    margin: 'auto',
    inset: '0',
  },
};
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0 0 0 / 34%)';
export default function UpdateRcNumberModal(props) {
  const navigate = useNavigate();
  const [isLoading, setLoader] = useState(false);
  const [state, setState] = useState({});
  const [isRcNumberAvailable, setRcNumberAvailable] = useState(false);
  useEffect(() => {
    const getRcNumber = async () => {
      try {
        setLoader(true);
        // check for RC NUmber
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    };
    getRcNumber();
    return () => {};
  }, []);

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
    >
      <Styled>
        <div className="closeBtn">
          <FiX size={20} color="#FFF" onClick={props.closeModal} />
        </div>
        <div className="container_content">
          {!isLoading ? (
            <>
              {isRcNumberAvailable ? (
                <div>
                  <p>You cant start a new Application</p>
                  <p>Kindly Use The Continue Application Button</p>
                </div>
              ) : (
                <>
                  <p>Your RC Number could not be auto retrieved.</p>
                  <p>Kindly click below to update manually</p>
                  <Button
                    title="Update"
                    mode="1"
                    onClick={() => navigate(
                      '/app/settings/business-profile?from=facility-dashboard'
                    )}
                  />
                </>
              )}
            </>
          ) : (
            <div className="loader">
              <div className="overlay__inner">
                <div className="overlay__content">
                  <span className="spinner" />
                </div>
              </div>
            </div>
          )}
        </div>
      </Styled>
    </Modal>
  );
}
const Styled = styled.div`
  max-width: 35rem;
  margin-top: 1rem;
  .closeBtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
  .container_content {
    text-align: center;
    p {
      padding-bottom: 2rem;
    }
  }
  .loader {
    padding: 10rem 13rem;
  }
  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .spinner {
    width: 7.5rem;
    height: 7.5rem;
    display: inline-block;
    border-width: 3px;
    border-color: rgba(255, 255, 255);
    border-top-color: #004c4e;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
