import React from 'react';
import styled from 'styled-components';
import { getOrrSumColor } from 'helpers/utils';
import { ReactComponent as RollerSvg } from 'assets/svgs/loader-roller.svg';
import useOrrScore from 'hooks/useOrrScore';
import PieChart from '../../PieChart/PieChart';

function Charts({ grades }) {
  const formatValue = (val = 0, val2 = 0) => (val / val2) * 100;

  const creditHistory = formatValue(
    grades?.creditHistoryScore,
    grades.creditHistoryWeight
  );
  const industry = formatValue(grades?.industryScore, grades.industryWeight);
  const business = formatValue(grades?.businessScore, grades.businessWeight);
  const management = formatValue(
    grades?.managementScore,
    grades.managementWeight
  );
  const financial = formatValue(grades?.financialScore, grades.financialWeight);

  return (
    <div className="chart-container">
      <div className="chart-item chart-total">
        <PieChart
          color1={getOrrSumColor(grades.orrGrade)}
          title="Total"
          text={`${grades?.orrScore?.toFixed(1)}%`}
          percent={grades.orrScore}
        />
      </div>
      <div className="chart-item">
        <PieChart
          color1="#004C4E"
          title="Credit History"
          text={`${creditHistory?.toFixed(1)}%`}
          percent={creditHistory}
        />
      </div>
      <div className="chart-item">
        <PieChart
          color1="#0B90BA"
          title="Industry"
          text={`${industry?.toFixed(1)}%`}
          percent={industry}
        />
      </div>
      <div className="chart-item">
        <PieChart
          color1="#5FB19C"
          title="Business"
          text={`${business?.toFixed(1)}%`}
          percent={business}
        />
      </div>
      <div className="chart-item">
        <PieChart
          color1="#D45F36"
          title="Management"
          text={`${management?.toFixed(1)}%`}
          percent={management}
        />
      </div>
      <div className="chart-item">
        <PieChart
          color1="#D9B08C"
          title="Financials"
          text={`${financial?.toFixed(1)}%`}
          percent={financial}
        />
      </div>
      <p className="notes">
        *This is a provisional ORR score and might change based on validation
        outcome.
      </p>
    </div>
  );
}

export default function OrrChart(props) {
  const [grades, isScoreLoading] = useOrrScore();

  return (
    <Styled>
      {isScoreLoading ? <RollerSvg stroke="#d1e8e2" /> : undefined}
      {!isScoreLoading && grades?.businessId && grades?.orrScore !== 0 ? (
        <Charts grades={grades} />
      ) : undefined}
      {!isScoreLoading && !grades?.businessId ? (
        <p className="not_available">Not Available</p>
      ) : undefined}
      {!isScoreLoading && grades?.businessId && grades?.orrScore === 0 ? (
        <p className="not_available">Not Available</p>
      ) : undefined}
    </Styled>
  );
}
const Styled = styled.div`
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 2rem;
  .chart-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 1rem;
    flex-wrap: wrap;
  }
  .chart-item {
    width: 35%;
    margin-bottom: 2rem;
  }
  .chart-total {
    width: 40%;
    background: #eafbf7;
    border: 1px solid #5fb19c;
    padding: 1rem;
    border-radius: 1rem;
  }
  .notes {
    font-size: 1.2rem;
    text-align: center;
    color: #004c4e;
  }
  .not_available {
    padding: 0.5rem;
    font-size: 1.4rem;
    text-align: center;
  }
`;
