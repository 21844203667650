import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NextButton, PrevButton } from 'components/Buttons';
import NumberFormat from 'react-number-format';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import orrApis from 'apis/orr';
import ErrorHandler from 'helpers/errorHandler';

const answerModel = {
  bankName: '',
  creditLimit: '',
  balance: '',
  facilityType: '',
  collateralType: '',
  collateralValue: '',
};

export default function AuditedDocument(props) {
  const [borrowList, setBorrowList] = useState([]);
  const prevHandler = () => {
    props.prevHandler();
  };
  useEffect(() => {
    const getBorrowingList = async (e) => {
      try {
        props.setLoader(true);
        const res = await orrApis.getBrowsingHistory(props.businessId);
        setBorrowList((prev) => [...prev, ...res.data.data]);
      } catch (error) {
        ErrorHandler(error);
      } finally {
        props.setLoader(false);
      }
    };
    getBorrowingList();
    return () => {};
  }, []);
  const nextHandler = async (e) => {
    e.preventDefault();
    try {
      props.setLoader(true);
      await orrApis.postBrowsingHistory(borrowList);
    } catch (error) {
      ErrorHandler(error);
    } finally {
      props.setLoader(false);
      props.nextHandler();
    }
  };
  const addTableHandler = () => {
    setBorrowList((prev) => [
      ...prev,
      { ...answerModel, businessId: props.businessId },
    ]);
  };
  const removeTableHandler = () => {
    const newState = [...borrowList];
    newState.pop();
    setBorrowList(newState);
  };
  const onChangeHandler = (e, i) => {
    const { value, name } = e.target;
    const cloneState = [...borrowList];
    cloneState[i][name] = value;
    setBorrowList(cloneState);
  };
  return (
    <Styled onSubmit={nextHandler}>
      <section className="orr_ques_container">
        <div className="question">
          <p className="question_title">
            Kindly input your borrowing history details
          </p>
          <div className="table-wrapper">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Institution Name</th>
                  <th>Credit Limit</th>
                  <th>Outstanding Balance</th>
                  <th>Facility Type</th>
                  <th>Collateral Type</th>
                  <th>Collateral Value</th>
                </tr>
              </thead>
              <tbody>
                {borrowList.map((elem, i) => (
                  <tr key={i}>
                    <td>
                      <input
                        type="text"
                        name="bankName"
                        value={elem.bankName}
                        required
                        autoComplete="off"
                        onChange={(e) => onChangeHandler(e, i)}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        name="creditLimit"
                        value={elem.creditLimit}
                        required
                        autoComplete="off"
                        thousandSeparator
                        decimalScale={2}
                        onChange={(e) => onChangeHandler(e, i)}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        name="balance"
                        value={elem.balance}
                        required
                        autoComplete="off"
                        thousandSeparator
                        decimalScale={2}
                        onChange={(e) => onChangeHandler(e, i)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="facilityType"
                        value={elem.facilityType}
                        required
                        autoComplete="off"
                        onChange={(e) => onChangeHandler(e, i)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="collateralType"
                        value={elem.collateralType}
                        required
                        autoComplete="off"
                        onChange={(e) => onChangeHandler(e, i)}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        name="collateralValue"
                        value={elem.collateralValue}
                        required
                        autoComplete="off"
                        thousandSeparator
                        decimalScale={2}
                        onChange={(e) => onChangeHandler(e, i)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="add-remove-wrapper">
            <div onClick={addTableHandler}>
              <FiPlusCircle size={18} color="#00989C" />
              <p>Add Row</p>
            </div>
            {borrowList.length > 0 ? (
              <div onClick={removeTableHandler}>
                <FiMinusCircle size={18} color="#00989C" />
                <p>Remove Row</p>
              </div>
            ) : null}
          </div>
          <p className="indicationWrapper">
            10 of
            {' '}
            <strong style={{ fontSize: '14px' }}>10</strong>
          </p>
        </div>
      </section>
      <section className="orr_button_section">
        <PrevButton onClick={prevHandler} />
        <NextButton type="submit" />
      </section>
    </Styled>
  );
}
const Styled = styled.form`
  height: 100%;
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    height: 100%;
    margin-bottom: 2rem;
    background-color: #fff;
  }
  .question {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    justify-content: center;
    padding: 0 1.5rem;
  }
  .question_title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .orr_button_section {
    display: flex;
    justify-content: space-between;
  }
  .indicationWrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.2rem;
  }
  .styled-table th,
  .styled-table td {
    font-size: 1.4rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border: none;
  }
  .styled-table td {
    font-weight: 300;
    font-size: 1.4rem;
  }
  .styled-table th {
    background-color: #f2f2f2;
    font-size: 1.2rem;
    padding: 1rem 2px;
    width: 20rem;
  }
  .styled-table {
    font-weight: 400;
    border-collapse: collapse;
    min-width: 667px;
    border: none;
    width: 100%;
    table-layout: fixed;
  }
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .styled-table input {
    min-width: 10rem;
    width: 100%;
    border: thin solid #ddd;
    height: 2.5rem;
    text-align: right;
    padding-right: 0.5rem;
    border-radius: 0px;
    box-shadow: none;
  }
  .table-wrapper {
    width: 100%;
    overflow-y: scroll;
    max-height: 30rem;
  }
  .add-remove-wrapper {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    & > div:nth-of-type(2) {
      margin-left: 1rem;
    }
  }
`;
