import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { asyncWrapper } from 'helpers/asyncWrapper';
import Button from 'components/Buttons';
import FrrApis from 'apis/frr';
import styled from 'styled-components';
import { FeedBackMailSvg } from 'theme/icons';
import { FiInfo } from 'react-icons/fi';
import queryString from 'query-string';
import Layout from 'components/Layouts/BusinessEvaDashboard';

export default function FeedBacksByCollateralId() {
  const [collateralFeedBack, setCollateralFeedBack] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const collateralTypeId = queryString.parse(location.search)?.collateralTypeId;

  useEffect(() => {
    const getFeedBack = async () => {
      const [res, err] = await asyncWrapper(
        FrrApis.getFlaggedCollateralQuestionsByCollateralId({
          collateralId: params.collateralId,
        })
      );
      setCollateralFeedBack(res?.data?.data || []);
    };
    getFeedBack();
  }, []);

  return (
    <Styled>
      <Layout activeBar="validation-feedback">
        <div className="m-10">
          <div className="mb-4 flex justify-between">
            <h2 className="text-[#004c4e] text-3xl">Validation Feedback</h2>
            <div>
              {collateralFeedBack?.length > 0 && (
                <Button
                  onClick={() => navigate('edit', {
                    state: {
                      collateralTypeId,
                      feedBacks: collateralFeedBack,
                    },
                  })}
                  mode="2"
                  title="Treat"
                  style={{ marginRight: '10px' }}
                />
              )}
              <Button
                onClick={() => navigate('/app/collateral/collaterals-with-feedbacks')}
                mode="1"
                title="Back"
              />
            </div>
          </div>
          <div className="bg-white rounded-md max-w-[80rem]">
            {collateralFeedBack?.length > 0 ? (
              <>
                {collateralFeedBack.map((elem, i) => (
                  <div key={i} className="flex p-4 border-b-2">
                    <div className="mr-4">
                      <FeedBackMailSvg width="35px" height="35px" />
                    </div>
                    <div className="feedbacks_item-right">
                      <h3>{elem.question}</h3>
                      <div className="report">
                        <FiInfo color="#D45F36" />
                        <p>{elem.feedbackComment}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="text-[#004c4e]">
                          Status:
                          {' '}
                          {elem.isTreated ? (
                            'Treated'
                          ) : (
                            <span className="text-[#d45f36]">Pending</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="text-center p-2">
                <p>Empty Feedback</p>
                <Button
                  mode="1"
                  onClick={() => navigate('/app/collateral/collaterals-with-feedbacks')}
                  className="mb-4"
                >
                  Back
                </Button>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </Styled>
  );
}
const Styled = styled.div`
  .feedbacks_item-right {
    width: 100%;
    h3 {
      font-weight: 500;
      font-size: 1.6rem;
      color: #004c4e;
    }
    p {
      color: #808080;
      font-size: 1.2rem;
      padding-left: 0.5rem;
    }

    .report {
      display: flex;
      margin: 0.5rem 0;
      align-items: center;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
`;
