import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import CustomInput from 'components/FormikInputs/Input';
import { NextButton, PrevButton } from 'components/Buttons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as SignUpActions from 'store/actions/signUpForm';
import styled from 'styled-components';
import Spinner from 'components/OverLays/OverLaySpinner';
import { adminUserApis } from 'apis';
import ErrorHandler from 'helpers/errorHandler';
import { toast } from 'react-toastify';

const formatAllSector = (data) => data.map((elem) => ({
  text: elem.sectorName,
  value: elem.sectorId,
  t24SectorCode: elem.t24SectorCode,
}));

const formatAllSubSector = (data) => data.map((elem) => ({
  text: elem.subSectorName,
  value: elem.subSectorId,
}));

export default function ExistingCustomer() {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [allSector, setAllSector] = useState([]);
  const [allSubSector, setAllSubSector] = useState([]);
  const { signUpForm } = useSelector((state) => state);
  const [ splittedEmail, setSplittedEmail] = useState([]);

  useEffect( () => {
    const splitAccountEmail = () => {
      let splitEmail = signUpForm?.accDetails?.email.split(' ')
      const newArray = splitEmail.filter(item => item !== "");
      setSplittedEmail(newArray);
    }
    splitAccountEmail();
  }, []);

  const generalSectorId = 5;

  function getSectorName() {
    const name = allSector.filter(
      (elem) => elem.t24SectorCode == signUpForm.accDetails.SECTOR
    );
    return name[0]?.value || generalSectorId;;
  }

  useEffect(() => {
    const getAllSector = async () => {
      try {
        setLoader(true);
        const res = await adminUserApis.getAllSectors();
        const formattedSectors = formatAllSector(res?.data?.data);
        setAllSector(formattedSectors);
        const sectorId = formattedSectors.filter(
          (elem) => elem.t24SectorCode == signUpForm.accDetails.SECTOR
        )[0]?.value || generalSectorId;
        // navigate government to general-commerce
        const res2 = await adminUserApis.getAllSubSectorBySectorId(
          sectorId == 12 ? generalSectorId : sectorId
        );

        if (res2?.data?.data?.length < 1) {
          const res3 = await adminUserApis.getAllSubSectorBySectorId(generalSectorId);
          setAllSubSector(formatAllSubSector(res3?.data?.data));
        } else {
          setAllSubSector(formatAllSubSector(res2?.data?.data));
        }
        setLoader(false);
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    };
    getAllSector();
    return () => {};
  }, []);

  const initialValues = {
    companyAccountNumber: signUpForm?.accDetails?.ACCT_NO,
    businessName: signUpForm?.accDetails?.AccountTitle,
    companyEmail: signUpForm?.accDetails?.email || '',
    companyPrimaryAddress: signUpForm.accDetails.ADD_LINE1,
    alternativeCompanyName: '',
    rcNumber: signUpForm?.accDetails['RC.NUMBER'] || '',
    companyPhoneNumber: signUpForm?.accDetails?.TEL_NUM || '',
    companySector: getSectorName(),
    companySubSector: signUpForm?.finalPage?.companySubSector || '',
    companyTin: signUpForm.accDetails['TAX.ID'],
  };
  const validationSchema = yup.object({
    companyEmail: yup.string().email('Input Valid Email').required(),
    alternativeCompanyName: yup.string(),
    companyPhoneNumber: yup
      .string()
      .matches(/^\+?[0-9]+$/, 'Must be only Numbers'),
    companySector: yup.string().required('Name is required'),
    companySubSector: yup.string().required('Name is required'),
  });
  const formik = useFormik({
    initialValues: signUpForm.finalPage.companyEmail
      ? signUpForm.finalPage
      : initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if( splittedEmail?.includes(values?.companyEmail?.toLowerCase()) ){
        values.subSectorList = allSubSector;
        dispatch(SignUpActions.SaveFinal(values));
        dispatch(SignUpActions.SaveActivePage('createUser'));
      }else{
        toast.error('It seems this inputted email does not match with your record with us. Kindly update.');
      }

      if(splittedEmail?.length < 1){
        toast.error('It seems you do not have an email attached to your account. Kindly update your record with us.')
      }
    },
  });
  return (
    <Styled>
      {isLoading ? <Spinner /> : ''}
      <h2 className="formTitle">Sign Up</h2>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          name="companyAccountNumber"
          label="Account Number"
          type="text"
          disabled
          required
          formik={formik}
        />
        <CustomInput
          name="businessName"
          label="Company Name"
          type="text"
          disabled
          required
          formik={formik}
        />
        <CustomInput
          asterisk="true"
          name="alternativeCompanyName"
          label="Alternative Brand Name"
          type="text"
          formik={formik}
        />
        <CustomInput
          name="companyEmail"
          label="Business Email Address"
          type="text"
          required
          formik={formik}
        />
        <CustomInput
          name="rcNumber"
          label="RC Number"
          type="text"
          disabled
          required
          formik={formik}
        />
        <CustomInput
          name="companyPhoneNumber"
          label="Business Phone Number"
          type="text"
          disabled = {signUpForm?.accDetails?.TEL_NUM}
          required
          formik={formik}
        />
        <CustomInput
          name="companyPrimaryAddress"
          label="Company Primary Address"
          type="text"
          required
          disabled
          formik={formik}
        />
        <CustomInput
          name="companySector"
          label="Sector"
          type="option"
          options={allSector}
          disabled
          required
          formik={formik}
        />
        <CustomInput
          name="companySubSector"
          label="Sub Sector"
          type="option"
          options={
            (allSubSector[0]?.value && allSubSector)
            || signUpForm?.finalPage?.subSectorList
          }
          required
          formik={formik}
        />
        <div className="navButton">
          <PrevButton
            type="button"
            mode="3"
            title="Prev"
            onClick={() => dispatch(SignUpActions.SaveActivePage('tinOrAcc'))}
          />
          <NextButton type="submit" mode="1" title="Next" />
        </div>
      </form>
    </Styled>
  );
}
const Styled = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 5rem;
  padding-top: 5%;
  .navButton {
    display: flex;
    justify-content: space-between;
  }
  .formTitle {
    color: #004c4e;
    margin-bottom: 2rem;
    font-weight: 500;
  }
`;
