import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import Button from 'components/Buttons';
import authApis from 'apis/user';
import ErrorHandler from 'helpers/errorHandler';
import businessApis from 'apis/business';
import * as AuthActions from 'store/actions/auth';
import * as BusinessActions from 'store/actions/business';
import * as SignUpActions from 'store/actions/signUpForm';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useNotify from 'hooks/useNotify';
import useQuery from 'hooks/useQuery';
import * as NotificationActions from 'store/actions/notification';
import CustomInput from '../../FormikInputs/Input';
import errorHandler from 'helpers/errorHandler';
import OverLaySpinner from 'components/OverLays/OverLaySpinner';

export default function Signin() {
  const navigate = useNavigate();
  const [params] = useQuery();

  const [setNotify] = useNotify();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [resetSession, setResetSession] = useState(false);
  const [activateEmailLoader, setActivateEmailLoader] = useState(false);
  const validationSchema = yup.object({
    username: yup.string().required('Username Cant Be Empty'),
    password: yup.string().required('Password Cant Be Empty'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      rememberMe: true,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoader(true);
        if (resetSession) {
          setResetSession(false);
          await authApis.resetSession(values);
        }
        const resLogin = await authApis.userLogin(values);

        const { passportId, token, businessId } = resLogin?.data?.data;

        if (resLogin?.data?.status == false && resLogin?.data?.message?.toUpperCase() !== 'INACTIVE') {
          setLoader(false);
          return setNotify({
            show: true,
            type: 'bad',
            message: resLogin?.data?.data,
            // func: () => navigate('/'),
          });
        }

        if(resLogin?.data?.message){
          if(resLogin?.data?.message?.toUpperCase() === 'INACTIVE'){
            setLoader(false);
            setNotify({
              show: true,
              type: 'info',
              message: 'Please activate your account! Click okay to resend an activation mail.',
              func: () => {
                activateEmailHandler();
              },
            });
          }
        }

        if(resLogin?.data?.status){
          const resBusinessDetails = await businessApis.getBusinessByPassportIdWithoutToken(
            passportId,
            token
          );
          
          dispatch(SignUpActions.ResetForm());
          if (businessId?.length < 30 || businessId == null) {
            return setNotify({
              show: true,
              type: 'bad',
              message:
                'No Business profile attached to your account. Contact Support',
            });
          }
          dispatch(AuthActions.AuthSaveUser(resLogin.data.data));
          dispatch(
            BusinessActions.BusinessSaveProfiles(resBusinessDetails?.data?.data)
          );
          dispatch(NotificationActions.getNotification(businessId));
          if (params?.get('dest') == 'frrupload') {
            new Promise((resolve) => setTimeout(resolve, 1000)).then(() => navigate('/app/frr/upload', { replace: true }));
          } else {
            new Promise((resolve) => setTimeout(resolve, 1000)).then(() => navigate('/app/dashboard', { replace: true }));
          }
        }

        

      } catch (error) {
        if (error?.response?.status === 409 && error?.response.data.message === 'You Already have a running Session Running') {
          setResetSession(true);
        }
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    },
  });

    const activateEmailHandler = async () => {
      setActivateEmailLoader(true);
      try{
        const response = await authApis.resendActivationMail(formik.values.username);
      }catch(error){
        errorHandler(error);
      }finally{
        setActivateEmailLoader(false);
      }
    }

  return (
    <Styled>
      <div className="form-wrapper">
        <div className="form">
          <p className="title">Welcome Back</p>

          <form onSubmit={formik.handleSubmit}>
            <CustomInput
              name="username"
              label="Username"
              placeholder="e.g email@company.com"
              type="text"
              required
              formik={formik}
            />
            <CustomInput
              name="password"
              label="Password"
              placeholder="enter password"
              type="password"
              required
              formik={formik}
            />

            <p
              className="forgetPwd"
              onClick={() => navigate('/auth/forget-password')}
            >
              Forgot Password ?
            </p>

            <div className="btnWrapper">
              <Button loader={loader} type="submit" title="Sign In" mode="1" />
            </div>
          </form>
        </div>
      </div>

      <div className="form-right-image" />

      {activateEmailLoader ? <OverLaySpinner /> : ''}
      
    </Styled>
  );
}
const Styled = styled.div`
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  min-height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .title {
    color: #004c4e;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    font-weight: 600;
  }
  .form-wrapper {
    width: 60%;
    display: flex;
    justify-content: center;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .form {
    min-width: 30rem;
    max-width: 50rem;
    width: 100%;
    background: #fff;
    border-radius: 15px;
    padding: 4rem;
    border: 4px solid #d1e8e2;
    @media (max-width: 500px) {
      padding: 4rem 2rem;
      margin: 0 1rem;
    }
  }
  .form-right-image {
    background: url("https://businesslending.azureedge.net/web/assets/images/smiling_lady_signin.png")
      no-repeat;
    background-size: cover;
    background-position: top;
    width: 40%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 800px) {
      display: none;
    }
  }
  .forgetPwd {
    color: #004c4e;
    font-size: 1.4rem;
    cursor: pointer;
    font-weight: 500;
  }
  .btnWrapper {
    display: flex;
    justify-content: flex-end;
  }
`;
