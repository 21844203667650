import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import orrApis from 'apis/orr';
import ErrorHandler from 'helpers/errorHandler';
import LastFinancialCheck from './LastFinancialCheck';
import AuditorsList from './Auditor';
import AfsQualification from './AfsQualification';
import FinStatement from './FinStatement';
import CashFlow from './CashFlow';
import Assets from './Assets';
import Liability from './Liability';
import Capital from './Capital';
import FinYear from './FinYearDate';
import BrowsingHistory from './BrowsingHistory';

export default function Financials(props) {
  const navigate = useNavigate();
  const { businessId, setFinancialAnswers } = props;
  const passProps = {
    finAnswers: props.financialAnswers,
    setFinAnswers: props.setFinancialAnswers,
    nextHandler,
    prevHandler,
    setLoader: props.setLoader,
    businessId: props.businessId,
  };

  const compList = [
    <LastFinancialCheck {...passProps} />,
    <AuditorsList {...passProps} />,
    <AfsQualification {...passProps} />,
    <FinYear {...passProps} />,
    <FinStatement {...passProps} />,
    <CashFlow {...passProps} />,
    <Assets {...passProps} />,
    <Liability {...passProps} />,
    <Capital {...passProps} />,
    <BrowsingHistory {...passProps} />,
  ];

  useEffect(() => {
    const covertToMilOrThousand = (financialNumbers) => {
      const financialAnswersCopy = { ...financialNumbers };

      for (const i in financialAnswersCopy) {
        if (
          typeof financialAnswersCopy[i] === 'number'
          && financialAnswersCopy.valueScale == 'millions'
          && financialAnswersCopy[i] != 0
          && i != 'id'
        ) {
          financialAnswersCopy[i] /= 1000000;
          financialAnswersCopy[i] = parseFloat(
            financialAnswersCopy[i].toFixed(2)
          );
        } else if (
          typeof financialAnswersCopy[i] === 'number'
          && financialAnswersCopy.valueScale == 'thousands'
          && financialAnswersCopy[i] != 0
          && i != 'id'
        ) {
          financialAnswersCopy[i] /= 1000;
          financialAnswersCopy[i] = parseFloat(
            financialAnswersCopy[i].toFixed(2)
          );
        }
      }
      return financialAnswersCopy;
    };
    const getAnswer = async () => {
      try {
        const res = await orrApis.getOrrFinancialAnswers(businessId);
        if (res.status == 200 && res.data.data.length != 0) {
          setFinancialAnswers(covertToMilOrThousand(res.data.data[0]));
        }
      } catch (error) {
        ErrorHandler(error);
      }
    };
    getAnswer();

    return () => {};
  }, [businessId, setFinancialAnswers]);

  async function nextHandler() {
    const tabLength = compList.length - 1;

    if (props.currentQuestionIndex < 8) {
      if (
        props.financialAnswers.lastFinancial == 'No'
        && props.currentQuestionIndex == 0
      ) {
        props.setCurrentQuestionIndex((prev) => prev + 3);
        return;
      }
      props.setCurrentQuestionIndex((prev) => prev + 1);
    } else if (props.currentQuestionIndex == 8) {
      await props.saveFinancialHandler();
    } else if (props.currentQuestionIndex == tabLength) {
      if (props.queryParams?.get('status') == 'review') {
        navigate('/app/orr/review', { replace: true });
      } else {
        navigate('/app/orr/upload', { replace: true });
      }
    }
  }

  function prevHandler() {
    if (props.currentQuestionIndex > 0) {
      if (
        props.financialAnswers.lastFinancial == 'No'
        && props.currentQuestionIndex == 3
      ) {
        props.setCurrentQuestionIndex((prev) => 0);
        return;
      }
      props.setCurrentQuestionIndex((prev) => prev - 1);
    }
    // final submission
  }

  return <Styled>{compList[props.currentQuestionIndex]}</Styled>;
}

const Styled = styled.div``;
