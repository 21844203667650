import * as actionTypes from '../action-types/business';

const initialState = {
  businessProfiles: [],
};

export default function auth(state = initialState, { type, payLoad }) {
  switch (type) {
    case actionTypes.SAVE_BUSINESS_PROFILES:
      return {
        ...state,
        businessProfiles: payLoad,
      };
    case actionTypes.CLEAR_BUSINESS_PROFILES:
      return initialState;
    default:
      return state;
  }
}
