import * as actionTypes from '../action-types/notify';

const initialState = {
  show: false,
  type: 'info',
  message: null,
  func: null,
  funcTwo: null,
  btnText: null,
  btnTextTwo: null
};

export default function auth(state = initialState, { type, payLoad }) {
  switch (type) {
    case actionTypes.SHOW_NOTIFY:
      return {
        ...state,
        show: true,
        type: payLoad.type,
        func: payLoad.func,
        funcTwo: payLoad.funcTwo,
        message: payLoad.message,
        btnText: payLoad.btnText,
        btnTextTwo: payLoad.btnTextTwo,
      };
    case actionTypes.HIDE_NOTIFY:
      return {
        ...state,
        show: false,
        type: '',
        func: null,
      };
    default:
      return state;
  }
}
