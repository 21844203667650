export const fontSize = {
  xxs: '0.625rem',
  xs: '0.6875rem',
  sm: '.75rem',
  m: '0.875rem',
  base: '1rem', // 16px
  l: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5625rem',
};
export const lineHeight = {
  none: '1',
  tight: '1.25',
  snug: '1.375',
  normal: '1.5',
  relaxed: '1.625',
  loose: '2',
  3: '.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
};
