import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { FiInbox } from 'react-icons/fi';
import { ReactComponent as RollerSvg } from 'assets/svgs/loader-roller.svg';
import { EnvelopeCircleSvg, EnvelopeCircleNotifySvg } from 'theme/icons';
import { userApis } from 'apis';
import { useSelector, useDispatch } from 'react-redux';
import { saveNotifications } from 'store/actions/notification';
import { useTable, usePagination } from 'react-table';
import MessageModal from './MessageModal';
import Header from '../../Headers/General';

export default function Index(props) {
  const { auth } = useSelector((state) => state);
  const [isLoading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const getNotification = async () => {
      try {
        setLoading(true);
        const res = await userApis.getAllNotifications(auth?.user?.businessId);
        mounted && setNotificationData(res?.data?.data);
      } catch (error) {
      } finally {
        mounted && setLoading(false);
      }
    };
    getNotification();
    return () => {
      mounted = false;
    };
  }, []);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  const setNotificationRead = async (data) => {
    try {
      await userApis.setNotificationAsRead(data);
    } catch (error) {}
  };
  function openModal(data) {
    setCurrentNotification(data);
    setIsOpen(true);
    !data.isRead && setNotificationRead(data);
    const isReadIndex = notificationData.findIndex(
      (elem) => elem.id == data.id
    );
    const cloneState = [...notificationData];
    cloneState[isReadIndex].isRead = true;
    setNotificationData(cloneState);
    dispatch(saveNotifications(cloneState));
  }
  function closeModal() {
    setIsOpen(false);
  }

  const columns = useMemo(
    () => [
      {
        Header: 'S/N',
        accessor: (value, index) => index + 1,
      },
    ],
    []
  );

  const {
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: notificationData,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <Styled>
      <Header />
      <div className="notification-section">
        <h3 className="header-title">Notifications</h3>
        <main className="notification-container">
          {isLoading ? (
            <div className="notification-loader">
              <RollerSvg height="8rem" width="8rem" />
            </div>
          ) : (
            <div>
              {page?.length > 0 ? (
                <div>
                  {[...page].reverse().map((elem) => (
                    <div
                      key={elem.original.id}
                      className="notification-box"
                      onClick={() => openModal(elem)}
                      role="tab"
                    >
                      <div className="notification-icon">
                        {elem.original.isRead ? (
                          <EnvelopeCircleSvg />
                        ) : (
                          <EnvelopeCircleNotifySvg />
                        )}
                      </div>
                      <div className="notification-right">
                        <div className="notification-right-up">
                          <p className="notification-right-up-txt1">
                            {elem.original.subject}
                          </p>
                          <p className="notification-right-up-date">
                            {new Date(elem.original.addedOn).toDateString()}
                          </p>
                        </div>
                        <div
                          className="notification-right-down line-clamp-3"
                          dangerouslySetInnerHTML={{
                            __html: elem?.original.message,
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  {/* Pagination */}
                  <div className="pagination">
                    <button
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {'<<'}
                    </button>
                    {' '}
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {'<'}
                    </button>
                    {' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      {'>'}
                    </button>
                    {' '}
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {'>>'}
                    </button>
                    {' '}
                  </div>
                </div>
              ) : (
                <div className="notification-list-empty">
                  <FiInbox size={30} />
                  <p>No Data</p>
                </div>
              )}
            </div>
          )}
        </main>
      </div>
      <MessageModal
        data={currentNotification?.original}
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
      />
    </Styled>
  );
}

const Styled = styled.div`
  background: #e5e5e5;
  height: 100%;
  min-height: 100vh;
  .header-title {
    color: #004c4e;
    margin: 2rem 0;
    font-weight: 500;
  }
  .notification-section {
    max-width: 90rem;
    margin: 0 auto;
    padding-bottom: 30px;
  }
  .notification-container {
    background: #fff;
    border-radius: 1rem;
  }
  .notification-box {
    display: flex;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
    padding: 2rem;
    border-bottom: thin solid #e5e5e5;
    cursor: pointer;
    &:hover {
      background-color: #004c4e1c;
    }
  }
  .notification-icon {
    align-self: center;
    margin-right: 1rem;
  }
  .notification-right {
    width: 100%;
    .notification-right-up {
      display: flex;
      justify-content: space-between;
    }
    .notification-right-down {
      font-size: 1.2rem;
      color: #808080;
    }
  }
  .notification-right-up-txt1 {
    color: #004c4e;
    font-weight: 600;
    font-size: 1.5rem;
  }
  .notification-right-up-date {
    color: #428a8c;
    font-size: 1.2rem;
  }
  .notification-list-empty {
    text-align: center;
    padding: 1rem 0;
    padding: 2rem;
  }
  .pagination {
    display: flex;
    justify-content: end;
    button {
      padding: 0.5rem 0.7rem;
      border-radius: 5px;
      margin-right: 0.5rem;
      background: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.primary_lemon};
      cursor: pointer;
    }
  }
`;
