import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export default function Assets(props) {
  return (
    <Styled>
      <table className="styled-table">
        <thead>
          <tr>
            <th />
            <th>
              {new Date(props?.finAnswers?.financialEndYearDate).getFullYear()}
            </th>
            <th>
              {new Date(props?.finAnswers?.financialEndYearDate).getFullYear()
                - 1}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-60">Non-Current Asset (₦)</td>
            <td>
              <NumberFormat
                name="nonCurrentAssetYear1"
                value={props?.finAnswers?.nonCurrentAssetYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="nonCurrentAssetYear2"
                value={props?.finAnswers?.nonCurrentAssetYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Current Asset (₦)</td>
            <td>
              <NumberFormat
                name="currentAssetYear1"
                value={props?.finAnswers?.currentAssetYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="currentAssetYear2"
                value={props?.finAnswers?.currentAssetYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Receivables (₦)</td>
            <td>
              <NumberFormat
                name="receivablesYear1"
                value={props?.finAnswers?.receivablesYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="receivablesYear2"
                value={props?.finAnswers?.receivablesYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td className="td-60">Inventory (₦)</td>
            <td>
              <NumberFormat
                name="inventoryYear1"
                value={props?.finAnswers?.inventoryYear1}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                name="inventoryYear2"
                value={props?.finAnswers?.inventoryYear2}
                readOnly
                thousandSeparator
                decimalScale={2}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Styled>
  );
}
const Styled = styled.div`
  .calculateBold {
    font-weight: 600;
  }
  .styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    width: 100%;
    margin: 0;
  }
  .styled-table th,
  .styled-table td {
    font-size: 1.4rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
  }
  .styled-table th {
    background-color: #f2f2f2;
  }
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .styled-table input {
    min-width: 10rem;
    width: 100%;
    border: thin solid #ddd;
    height: 2.5rem;
    text-align: right;
  }
`;
