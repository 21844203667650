import CryptoJS from 'crypto-js';
import { config } from '../config/index';

export const key = config.accountOpeningSecretKey;
export const IV = config.accountOpeningInitVector;

export const secretKey = CryptoJS.enc.Utf8.parse(key);
export const intiVector = CryptoJS.enc.Utf8.parse(IV);

export const encryptHandler = (data) => {
  const preEncrypteds = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, {
    iv: intiVector,
    mode: CryptoJS.mode.CBC,
    keySize: 128 / 8,
  });

  const result = preEncrypteds.toString(CryptoJS.format.Hex);
  return result;
};

export const decryptHandler = (data) => {
  const dataHex = CryptoJS.enc.Hex.parse(data);

  const preDecrypted = CryptoJS.AES.decrypt(
    { ciphertext: dataHex },
    secretKey,
    { iv: intiVector, mode: CryptoJS.mode.CBC, keySize: 128 / 8 }
  );

  const encDecrypted = preDecrypted.toString(CryptoJS.enc.Utf8);
  const decrypted = JSON.parse(encDecrypted);
  return decrypted;
};
