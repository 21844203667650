import React, { useEffect, useState } from 'react';
import frrApis from 'apis/frr';
import { mergeAnswerWithQuestionsCollateral } from 'helpers/questionsAnswers';
import _cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { asyncWrapper } from 'helpers/asyncWrapper';
import Controller from './Controller';

export default function OtherQuestions(props) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [collateralQuestions, setCollateralQuestions] = useState([]);
  const { collateral } = useSelector((state) => state);

  useEffect(() => {
    const getIndexFromStorage = sessionStorage.getItem('_otherQuestionIndex');

    if (getIndexFromStorage) {
      setCurrentQuestionIndex(parseInt(JSON.parse(getIndexFromStorage)));
    }
    const getQuestions = async () => {
      props?.setLoading(true);
      const [res, err] = await asyncWrapper(
        frrApis.getCollateralQuesAndAnswersByCollateralTypeId({
          collateralTypeId: collateral?.payLoad?.collateralTypeId,
        })
      );
      props?.setLoading(false);
      // navigate to summary
      if (!res.data.data?.length) {
        props.summaryNav();
      }
      if (sessionStorage.getItem('_questionsPool')) {
        const cachedQuestions = sessionStorage.getItem('_questionsPool');
        setCollateralQuestions(
          mergeAnswerWithQuestionsCollateral(JSON.parse(cachedQuestions))
        );
      } else {
        setCollateralQuestions(mergeAnswerWithQuestionsCollateral(res.data.data));
        sessionStorage.setItem(
          '_questionsPool',
          JSON.stringify(mergeAnswerWithQuestionsCollateral(res.data.data))
        );
      }
    };
    getQuestions();
  }, []);

  const updateQuestionsPoolHandler = (index, answer) => {
    const cloneState = _cloneDeep(collateralQuestions);
    const currentQuestion = cloneState[index];
    const quesType = currentQuestion.questionType;
    if (quesType == 'single' || quesType == 'Multiple' || quesType.toLowerCase() == 'dropdown') {
      currentQuestion.selectedAnswer = answer;
    } else {
      currentQuestion.inputtedAnswer = answer;
    }
    setCollateralQuestions(cloneState);
    sessionStorage.setItem('_questionsPool', JSON.stringify(cloneState));
  };

  return (
    <Controller
      currentQuestionIndex={currentQuestionIndex}
      setCurrentQuestionIndex={setCurrentQuestionIndex}
      questions={collateralQuestions}
      updateQuestionsPoolHandler={updateQuestionsPoolHandler}
      summaryNav={props.summaryNav}
      exitHandler={props?.exitHandler}
    />
  );
}
