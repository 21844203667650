import React, { useState, useEffect } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import Header from 'components/Headers/Collateral';
import Spinner from 'components/OverLays/OverLaySpinner';
import styled from 'styled-components';
import frrApis from 'apis/frr';
import {
  UpdateCollateralPayload,
  ResetCollateral,
} from 'store/actions/collateral';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { routes } from 'constants/links';
import { asyncWrapper } from 'helpers/asyncWrapper';
import queryString from 'query-string';
import Layout from './Layout';
import CollateralType from './CollateralType';
import Seniority from './Seniority';
import StateClassification from './StateClassification';
import PropertyType from './PropertyType';
import SearchFeePayment from './SearchFeePayment';
import ConfirmValuation from './ConfirmValuation';
import NewValuation from './NewValuation';
import ValuersList from './ValuersList';
import OtherQuestions from './OtherQuestions';
import SecurityValue from './SecurityValue';
import Summary from './Summary';

export default function ORR() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const param = useParams();
  const location = useLocation();
  const { collateral, business, auth } = useSelector((state) => state);
  const { payLoad, checks } = collateral;
  const businessId = business?.businessProfiles[0]?.businessId;
  const queryList = queryString.parse(location.search);
  useEffect(() => {
    if (queryList?.facilityId) {
      sessionStorage.setItem('facilityId', queryList.facilityId);
    }
  }, [queryList]);

  const valuationNav = () => {
    // step five valuation
    if (checks.valuation) {
      // one
      if (payLoad.isValuationExisting == null) {
        return navigate(routes.confirm_val);
      } if (payLoad.isValuationExisting == 'Yes') {
        // valuationDate valuationEstimate knownValuerId
        if (
          (payLoad.valuationDate,
          payLoad.valuationEstimate,
          payLoad.knownValuerId)
        ) {
          dispatch(
            UpdateCollateralPayload({
              completedProcess: true,
            })
          );
          return navigate(routes.other_questions);
        }
        return navigate(routes.valuer_list);
      } if (payLoad.isValuationExisting == 'No') {
        if (payLoad.carryOutNewValuation == null) {
          return navigate(routes.new_valuation);
        } if (payLoad.carryOutNewValuation == 'No') {
          return otherQuestionsNav();
        }
        // else if (
        //   payLoad.carryOutNewValuation == 'Yes' &&
        //   payLoad.pricingId == null
        // ) {
        //   return navigate(routes.pricing_tbl);
        // }
        return otherQuestionsNav();
      }
    }
    otherQuestionsNav();
  };

  const searchFeeNav = () => {
    // step four search fee
    if (checks.search_fee) {
      if (payLoad.searchFeePropertyType == null) {
        return navigate(routes.prop_type);
      } if (payLoad.searchFeePayNow == null) {
        return navigate(routes.search_fee_payment);
      }
    }
    valuationNav();
  };

  const stateNav = () => {
    // step three classification
    if (payLoad.collateralSeniorityValue != null && payLoad.lgaId == null) {
      return navigate(routes.state_class);
    }
    searchFeeNav();
  };
  const summaryNav = () => navigate(routes.summary)
  // if (payLoad.completedProcess) {
  //   return navigate(routes.summary);
  // } else if (
  //   (!checks.state_classification, !checks.search_fee && !checks.valuation)
  // ) {
  //   dispatch(
  //     UpdateCollateralPayload({
  //       completedProcess: true,
  //     })
  //   );
  //   return navigate(routes.summary);
  // }
  ;
  // other questions
  const otherQuestionsNav = () => {
    if (payLoad.completedProcess) {
      return navigate(routes.other_questions);
    } if (
      (!checks.state_classification, !checks.search_fee && !checks.valuation)
    ) {
      dispatch(
        UpdateCollateralPayload({
          completedProcess: true,
        })
      );
      return navigate(routes.other_questions);
    }
  };

  function navController() {
    // step two seniority
    if (payLoad?.collateralTypeId == null) {
      return navigate(routes.start);
    }
    // step one collateralTypeId
    if (
      payLoad?.collateralTypeId !== null
      && payLoad?.collateralSeniorityValue == null
    ) {
      return navigate(routes.seniority);
    } if (
      payLoad?.collateralSeniorityValue !== null
      && payLoad?.securityValue == null
    ) {
      return navigate(routes.security_value);
    } if (checks.state_classification) {
      stateNav();
    } else if (checks.search_fee) {
      searchFeeNav();
    } else if (checks.valuation) {
      valuationNav();
    } else {
      otherQuestionsNav();
    }
  }

  const inputsValidator = () => {
    const errorMsg = 'Incomplete Field or Invalid Inputs';
    let response = false;
    if (param.id == 'start') {
      if (payLoad.collateralTypeId == null) {
        response = true;
        toast.error(errorMsg);
      }
    } else if (param.id == 'seniority') {
      if (payLoad.collateralSeniorityValue == null) {
        response = true;
        toast.error(errorMsg);
      }
    } else if (param.id == 'state_class') {
      if (payLoad.lgaId == null) {
        response = true;
        toast.error(errorMsg);
      }
    } else if (param.id == 'property_type') {
      if (payLoad.searchFeePropertyType == null) {
        response = true;
        toast.error(errorMsg);
      }
    } else if (param.id == 'search_fee_payment') {
      if (payLoad.searchFeePayNow == null) {
        response = true;
        toast.error(errorMsg);
      }
    } else if (param.id == 'confirm_valuation') {
      if (payLoad.isValuationExisting == null) {
        response = true;
        toast.error(errorMsg);
      }
    } else if (param.id == 'new_valuation') {
      if (payLoad.carryOutNewValuation == null) {
        response = true;
        toast.error(errorMsg);
      }
    }
    // else if (param.id == 'pricing_tbl') {
    //   if (payLoad.pricingId == null) {
    //     response = true;
    //     toast.error(errorMsg);
    //   }
    // }
    else if (param.id == 'valuer_list') {
      if (payLoad.knownValuerId == null) {
        response = true;
        toast.error(errorMsg);
      } else {
        if (
          payLoad.valuationDate == null
          || payLoad.valuationEstimate == null
        ) {
          response = true;
          toast.error(errorMsg);
        }
        if (
          payLoad.knownValuerId == 99999
          && payLoad.unknownValuerName == null
        ) {
          response = true;
          toast.error(errorMsg);
        }
      }
      if (
        payLoad.knownValuerId != null
        && payLoad.valuationDate != null
        && payLoad.valuationEstimate != null
      ) {
        dispatch(
          UpdateCollateralPayload({
            completedProcess: true,
          })
        );
      }
    }
    return response;
  };

  const nextHandler = () => {
    !inputsValidator() && navController();
  };

  const prevHandler = () => {
    const currentPage = sessionStorage.getItem('_currentPage');
    // note
    // dispatch(
    //   UpdateCollateralPayload({
    //     [currentPage]: null,
    //   })
    // );
    if (location.pathname === routes.summary) {
      dispatch(
        UpdateCollateralPayload({
          completedProcess: false,
        })
      );
    }
    navigate(-1);
  };

  const formatPayLoad = (payLoad) => {
    const newPayLoad = { ...payLoad };
    delete newPayLoad.completedProcess;
    delete newPayLoad.stateId;
    newPayLoad.currencyId = 1;
    newPayLoad.businessId = businessId;
    newPayLoad.status = 'submitted';
    // newPayLoad.coverageValue = 9393;
    for (const key in newPayLoad) {
      const indexValue = newPayLoad[key];
      if (indexValue === 'Yes' || indexValue === 'No') {
        newPayLoad[key] = newPayLoad[key] == 'Yes';
      }
      if (key == 'valuationDate') {
        newPayLoad[key] = payLoadType[key];
      }
      if (newPayLoad[key] == null) {
        newPayLoad[key] = payLoadType[key];
      }
      if (key == 'valuationEstimate' && indexValue) {
        newPayLoad[key] = parseFloat(indexValue?.replace(/,/g, ''));
      }
      if (key == 'securityValue' && indexValue) {
        newPayLoad[key] = parseFloat(indexValue?.replace(/,/g, ''));
      }
      if (key == 'knownValuerId' && indexValue) {
        newPayLoad[key] = Number(indexValue);
      }
    }
    return newPayLoad;
  };
  const payLoadType = {
    collateralId: 0,
    collateralTypeId: 0,
    collateralSeniorityValue: 0,
    collateralSeniorityName: '',
    securityValue: 0,
    // coverageValue: 0,
    currencyId: 1,
    lgaId: 0,
    searchFeePropertyType: '',
    searchFeePayNow: false,
    isValuationExisting: false,
    carryOutNewValuation: false,
    newValuationPayNow: false,
    pricingId: 0,
    knownValuerId: 0,
    unknownValuerName: '',
    valuationEstimate: 0,
    valuationDate: '1900-03-10T09:58:11.916Z',
  };

  const answerFormatter = (elem) => {
    const selectedAnswerInstance = elem.selectedAnswer instanceof Array;
    if (elem.selectedAnswer && !selectedAnswerInstance) {
      return {
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: String(elem.selectedAnswer.answerId),
        unWeightedAnswer: 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.selectedAnswer && selectedAnswerInstance) {
      const getAnswersId = elem.selectedAnswer.map((elem) => elem.answerId);
      return {
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: getAnswersId.join(),
        unWeightedAnswer: 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.questionType == 'File') {
      return {
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: 'string',
        hasDocument: true,
        documentUrl: elem.inputtedAnswer,
      };
    } if (elem.questionType == 'list') {
      return {
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: elem.inputtedAnswer.join() || 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.questionType == 'contact-list') {
      return {
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: JSON.stringify(elem.inputtedAnswer) || 'string',
        hasDocument: false,
        documentUrl: '',
      };
    }
    return {
      businessId: auth?.user?.businessId,
      questionId: elem.questionId,
      answerId: '0',
      unWeightedAnswer: String(elem.inputtedAnswer) || 'string',
      hasDocument: false,
      documentUrl: '',
    };
  };

  const formatAnswersForSaving = () => {
    const cachedQuestions = JSON.parse(
      sessionStorage.getItem('_questionsPool')
    );
    const questionList = [...cachedQuestions];

    const formattedAnswers = [];
    for (let i = 0; i < questionList.length; i++) {
      const elem = questionList[i];
      if (elem.questionClass == 'P') {
        formattedAnswers.push(answerFormatter(elem));
      } else if (elem.questionClass == 'S') {
        if (
          (elem.inputtedAnswer != 'undefined'
            && elem.inputtedAnswer?.length > 1)
          || (elem.selectedAnswer && elem.selectedAnswer != 'undefined')
        ) {
          formattedAnswers.push(answerFormatter(elem));
        }
      }
    }
    return formattedAnswers;
  };

  const handleSubmit = async () => {
    setLoading(true);
    // save collateral
    const [res, err] = await asyncWrapper(
      frrApis.postCollateral(formatPayLoad(payLoad))
    );
    // save dynamic questions
    const [res3, err3] = await asyncWrapper(
      frrApis.submitAndUpdateCollateralResponse({
        collateralId: res?.data?.data,
        qa: formatAnswersForSaving(),
      })
    );
    if (res) {
      const facilityId = sessionStorage.getItem('facilityId');
      if (facilityId) {
        const [res2, err2] = await asyncWrapper(
          frrApis.mapFacilityToCollateral({
            mappingId: 0,
            collateralId: res.data.data,
            facilityId,
          })
        );
      }
      // clear session
      sessionStorage.clear();
      setTimeout(() => {
        dispatch(ResetCollateral());
      }, 100);
      if (facilityId) {
        navigate(`/app/frr/facilitysubmission?facilityId=${facilityId}`, {
          replace: true,
        });
      } else {
        // navigate out
        navigate(routes.collateral_dashboard, { replace: true });
      }
    }
    setLoading(false);
  };
  let renderComponent;
  switch (param.id) {
    case 'start':
      renderComponent = <CollateralType setLoading={setLoading} />;
      break;
    case 'seniority':
      renderComponent = <Seniority setLoading={setLoading} />;
      break;
    case 'security-value':
      renderComponent = <SecurityValue setLoading={setLoading} />;
      break;
    // one
    case 'state-class':
      renderComponent = <StateClassification setLoading={setLoading} />;
      break;
    case 'property-type':
      renderComponent = <PropertyType setLoading={setLoading} />;
      break;
    // two
    case 'search-fee-payment':
      renderComponent = <SearchFeePayment setLoading={setLoading} />;
      break;
    case 'confirm-valuation':
      renderComponent = <ConfirmValuation setLoading={setLoading} />;
      break;
    case 'new-valuation':
      renderComponent = <NewValuation setLoading={setLoading} />;
      break;
    case 'valuer-list':
      renderComponent = <ValuersList setLoading={setLoading} />;
      break;
    case 'other-questions':
      renderComponent = <OtherQuestions setLoading={setLoading} />;
      break;
    // case 'pricing_tbl':
    //   renderComponent = <PricingTable />;
    //   break;
    case 'summary':
      renderComponent = <Summary setLoading={setLoading} />;
      break;
    default:
      renderComponent = <CollateralType setLoading={setLoading} />;
  }
  const exitHandler = () => {
    const verifyLogout = window.confirm('Exiting will terminate the application');
    if (verifyLogout) {
      sessionStorage.clear();
      dispatch(ResetCollateral());
      navigate('/app/collateral');
    }
  };
  return (
    <Styled>
      <Header homeHandler={exitHandler} />
      {isLoading ? <Spinner /> : ''}
      <section className="container_layer1">
        {param.id != 'other-questions' && param.id != 'summary' && (
          <Layout
            submitHandler={handleSubmit}
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            stage={param.id}
            exitHandler={exitHandler}
          >
            {renderComponent}
          </Layout>
        )}
        {param.id === 'other-questions' && (
          <OtherQuestions
            summaryNav={summaryNav}
            setLoading={setLoading}
            exitHandler={exitHandler}
          />
        )}
        {param.id === 'summary' && (
          <Summary submitHandler={handleSubmit} setLoading={setLoading} />
        )}
      </section>
    </Styled>
  );
}
const Styled = styled.div`
  background-color: #e5e5e5;
  height: 100%;
  min-height: 100vh;
  .container_layer1 {
    margin: 0 auto;
    padding: 0 10%;
    max-width: 1440px;
    @media (max-width: 1100px) {
      padding: 0 4%;
    }
    @media (max-width: 479px) {
      padding: 0 2%;
    }
  }
`;
