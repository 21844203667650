import { combineReducers } from 'redux';
import auth from './auth';
import signUpForm from './signUpForm';
import notify from './notify';
import business from './business';
import calculator from './calculator';
import notifications from './notification';
import orrScore from './orrScore';
import facilitySubPurposeReducer from './facility/sub-purpose-reducer';
import facilityAnsweredQuestions from './facility/facility-answered-questions';
import collateral from './collateral';

export default combineReducers({
  auth,
  signUpForm,
  notify,
  business,
  calculator,
  notifications,
  orrScore,
  facilitySubPurposeReducer,
  facilityAnsweredQuestions,
  collateral,
});
