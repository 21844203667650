import { encryptHandler } from 'helpers/accountOpeningEncryption';
import axios from 'axios';
import { config } from '../config/index';

const apiWithoutToken = axios.create({
  baseURL: config.accountOpeningURL,
});

const request = {
  getAllStates: () => {
    const method = 'get';
    const url = '/api/User/states';
    return apiWithoutToken({ method, url });
  },
  getAllIndustries: (data) => {
    const method = 'get';
    const industry = encryptHandler(data);
    const url = `/api/v2/users/GetIndustry?Data=${industry}`;
    return apiWithoutToken({ method, url });
  },
  getAllSectors: () => {
    const method = 'get';
    const url = '/api/v2/users/GetSector';
    return apiWithoutToken({ method, url });
  },
  uploadFile: (data) => {
    const method = 'post';
    const url = '/api/v2/users/UploadImageToAzureWithResponseCode';
    return apiWithoutToken({ method, url, data: { data } });
  },
  createCorporateAccount: (data) => {
    const method = 'post';
    const url = '/api/v2/users/OpenCorporateAccount';
    return apiWithoutToken({ method, url, data: { data } });
  },


  
};
export default request;
