import React from 'react';
import { ReactComponent as RollerSvg } from 'assets/svgs/loader-roller.svg';
import { FiInbox, FiCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export default function NotificationBox({ isLoading, notificationData }) {
  const navigate = useNavigate();
  const navigateToNotification = () => {
    navigate('/app/notifications', {
      state: { notificationData },
    });
  };

  return (
    <Styled className="notification-dropdown">
      {isLoading ? (
        <div className="notification-loader">
          <RollerSvg height="5rem" width="5rem" />
        </div>
      ) : (
        <>
          <div className="notification-header">
            <p className="text-lg">
              {notificationData.filter((elem) => elem.isRead == false).length}
              {' '}
              New Notification
            </p>
          </div>

          {notificationData.length > 0 ? (
            <div className="notification-list">
              {[...notificationData]
                .reverse()
                .filter((elem) => elem.isRead == false)
                .slice(0, 5)
                .map((elem) => (
                  <div
                    key={elem.id}
                    className="notification-item"
                    onClick={navigateToNotification}
                  >
                    <FiCircle size={5} fill="#5FB19C" stroke="#5FB19C" />
                    <p>{elem.subject}</p>
                  </div>
                ))}
            </div>
          ) : (
            <div className="notification-list-empty">
              <FiInbox size={30} />
            </div>
          )}
          <div onClick={navigateToNotification} className="notification-footer">
            View All
          </div>
        </>
      )}
    </Styled>
  );
}

const Styled = styled.div`
  color: #808080;
  font-size: 1.4rem;
  position: absolute;
  margin-top: 0.3rem;
  right: 1.5rem;
  z-index: 500;
  background: rgb(255, 255, 255);
  min-width: 24rem;
  border-radius: 0.3rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px;
  overflow: hidden;
  .notification-header {
    background: #e6edeb;
    padding: 1rem;
    color: #004c4e;
    font-weight: 500;
  }
  .notification-loader {
    padding: 1rem;
  }
  .notification-list {
    padding: 1rem;
  }
  .notification-footer {
    padding: 0.8rem;
    border-top: thin solid #e5e5e5;
    color: #004c4e;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    font-size: 1.2rem;
  }
  .notification-item {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 0.5rem;
    p {
      margin-left: 0.5rem;
      font-size: 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .notification-list-empty {
    text-align: center;
    padding: 1rem 0;
  }
`;
