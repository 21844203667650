import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import _cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function Question(props) {
  return (
    <Styled>
      <div>
        <p className="question_title">{props.questionTitle}</p>
      </div>
      <InputTypes {...props} />
    </Styled>
  );
}
const Styled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7%;
  width: 100%;
  overflow: hidden;
  height: 44.4rem;
  @media (max-width: 1280px) {
    padding: 0 5%;
  }
  .question_title {
    text-align: center;
    margin: 2rem 0;
  }
`;

export function InputTypes({
  questionType,
  inputHandler,
  inputtedAnswer,
  input,
  optionsArray,
  selectedOption,
  selected,
  selectHandler,
  optionKey,
  optionId,
  placeholder,
  wrapperStyle,
  wrapperClass,
}) {
  let renderQuestionType;
  switch (questionType) {
    case 'text':
      renderQuestionType = (
        <input
          autoComplete="off"
          type="text"
          onChange={inputHandler}
          name="name"
          className={classnames('input typeText')}
          value={inputtedAnswer || input}
        />
      );
      break;
    case 'number':
      renderQuestionType = (
        <NumberFormat
          className="input typeNumV2"
          thousandSeparator
          name="name"
          value={inputtedAnswer || input}
          onChange={inputHandler}
        />
      );
      break;
    case 'single':
      renderQuestionType = (
        // style={wrapperStyle || wrapperStyle}
        <div className={classnames('single-select', wrapperClass)}>
          {optionsArray?.map((elem, index) => (
            <button
              type="button"
              key={index}
              onClick={() => selectHandler(elem)}
              className={classnames(
                'select_item',
                { selected_item: selected == elem?.[optionKey] },
                {
                  selected_item:
                      selectedOption?.[optionId] == elem?.[optionId],
                }
              )}
            >
              {elem[optionKey]}
            </button>
          ))}
        </div>
      );
      break;
    case 'dropdown':
      renderQuestionType = (
        <select
          value={inputtedAnswer}
          name="dropdown"
          id=""
          className={classnames('select dropdown', wrapperClass)}
          style={wrapperStyle}
          onChange={inputHandler}
        >
          <option value="">{placeholder || '--Select--'}</option>
          {optionsArray?.map((elem, index) => (
            <option key={elem[optionId] || index} value={elem[optionId]}>
              {elem[optionKey]}
            </option>
          ))}
        </select>
      );
      break;
    case 'date':
      renderQuestionType = (
        <DatePicker
          selected={
            inputtedAnswer || input ? new Date(inputtedAnswer || input) : ''
          }
          // selected={
          //   props?.formik?.values[props.name] &&
          //   new Date(props?.formik?.values[props.name])
          // }
          // (date) => props.formik.setFieldValue(props.name, date)
          onChange={inputHandler}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="dd/MM/yyyy"
          // style={props.style}
          className="input date"
          placeholderText="dd/mm/yyyy"
          // disabled={props.disabled}
          maxDate={new Date()}
          autoComplete="off"
        />
      );
      break;
    default:
      renderQuestionType = (
        <input
          autoComplete="off"
          onChange={inputHandler}
          name="name"
          className="input typeText"
          value={inputtedAnswer || input}
        />
      );
  }
  return <StyledInput>{renderQuestionType}</StyledInput>;
}
const StyledInput = styled.div`
  .date {
    cursor: pointer;
  }
  .single-select {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .select_item {
    background: #fff;
    padding: 1rem 3rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    border: 1px solid #e5e5e5;
    border-radius: 0.5rem;
    outline: none;
    &:hover {
      border: 1px solid #00989c;
    }
  }
  .selected_item {
    border: 1px solid #00989c;
    color: #000;
  }
  .typeText {
    max-width: 70rem;
    min-width: 20rem;
    width: 100%;
    border-radius: 0.5rem;
    align-self: center;
  }
  .typeTextArea {
    width: 100%;
    height: 10rem;
    resize: none;
    padding: 0.5rem;
    border: thin solid #d1e8e2;
  }

  .typeNumV2 {
    padding: 1rem;
    max-width: 70rem;
    min-width: 20rem;
    width: 50%;
    text-align: right;
    align-self: center;
  }
  .input,
  .select {
    width: 100%;
    padding: 0.8rem;
    font-size: 1.4rem;
    display: block;
    border: 1px solid #00989c;
    border-radius: 5px;
    padding-right: 3.5rem;
  }
  .select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right 0.9em top 50%;
    background-size: 0.65em auto;
    background-color: #b8b8b8;
    font-size: 1.4rem;
  }

  .indicationWrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
  .dropdown {
    width: 100%;
    background-color: #f9f9f9;
  }
`;
