import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import verificationApis from 'apis/verification';
import * as orrScoreAction from '../store/actions/orrScore';

export default function useOrrScore() {
  const { business, orrScore } = useSelector((state) => state);
  const state = useSelector((state) => state);
  const [grades, setGrades] = useState({});
  const [isScoreLoading, setLoader] = useState(false);
  const { businessId } = business?.businessProfiles[0];
  const dispatch = useDispatch();
  useEffect(() => {
    const isActive = true;
    const getOrrScore = async () => {
      try {
        setLoader(true);
        if (Object.keys(orrScore).length > 1) {
          setGrades(orrScore);
        } else {
          const res = await verificationApis.getVerifiedORRResponse(businessId);
          dispatch(orrScoreAction.SaveScore(res?.data?.data));
          setGrades(res?.data?.data);
        }
      } catch (error) {
      } finally {
        setLoader(false);
      }
    };
    isActive && getOrrScore();
    return () => {};
  }, [businessId]);
  return [grades, isScoreLoading];
}
