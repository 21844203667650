import styled from 'styled-components';
import Header from 'components/Headers/General';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Buttons/index';

function FacilityConclusion() {
  const user = useSelector((state) => state?.auth.user);
  const business = useSelector(
    (state) => state?.business.businessProfiles[0]
  );

  const dispatch = useDispatch();

  return (
    <Styled>
      <Header />

      <div className="Conclusion-main">
        <div className="Conclusion-holder">
          <p>
            Dear.
            {' '}
            {user.firstName}
            ,
            {' '}
            <br />
            Congratulations! Your loan application for
            {' '}
            <br />
            {business.businessName}
            {' '}
            has been received.
            {' '}
            <br />
            You can track your progress on the Loan Application Tracker.
            {' '}
            <br />
            <br />
            Kindly check your email for more information.
          </p>
          <Link to="/app/dashboard">
            <Button
              onClick={() => {
                dispatch({
                  type: 'UPDATE_FACILITY_ID',
                  payload: {
                    facilityId: {},
                    fromDashboard: false,
                  },
                });
              }}
              title="Return to Home"
              id="submit-button"
            />
          </Link>
        </div>
        <img
          className="Background-image"
          src="https://businesslending.azureedge.net/web/assets/images/flower-pot.png"
          alt="flower background"
        />
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  width: 100%;
  background-color: #e5e5e5;
  position: relative;

  .Conclusion-main {
    width: 100%;
    position: relative;
    height: 93.6vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  .Conclusion-holder {
    z-index: 2;
    background-color: transparent;
    border-radius: 10px;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #5fb19c;

    p {
      margin: 0 0 3rem 0;
      text-align: center;
      color: #004c4e;
    }
  }

  #submit-button {
    padding: 1rem 2rem;
    color: #d9b08c;
    background: linear-gradient(261.93deg, #004c4e 0%, #00989c 100%);
    border-radius: 6px;
    border: none;
    cursor: pointer;
  }
`;

export default FacilityConclusion;
