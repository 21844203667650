import React, { useState, useEffect } from 'react';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import styled from 'styled-components';

export default function ListType(props) {
  const [list, setListData] = useState(['']);
  useEffect(() => {
    const setList = () => {
      if (
        props.question.inputtedAnswer instanceof Array
        && props.question.inputtedAnswer.length > 0
      ) {
        setListData((prev) => props?.question?.inputtedAnswer);
      } else {
        setListData((prev) => ['']);
      }
    };
    setList();
    return () => {};
  }, [props?.question?.inputtedAnswer]);

  const addHandler = () => {
    setListData((prev) => [...prev, '']);
  };
  const removeHandler = (index) => {
    const cloneState = [...list];
    cloneState.splice(index, 1);
    setListData((prev) => cloneState);
  };
  const onChangeHandler = (e) => {
    const { value, dataset } = e.target;
    const cloneState = [...list];
    cloneState[dataset.index] = value;
    setListData((prev) => cloneState);
    props.listHandler(cloneState);
  };
  return (
    <Styled className="answer-wrapper-scroll">
      {list.map((elem, i) => (
        <div key={i} className="input-item">
          <input
            value={list[i]}
            type="text"
            data-index={i}
            placeholder="Enter details"
            onChange={onChangeHandler}
          />
          {list.length > 1 && list.length - 1 == i ? (
            <div onClick={() => removeHandler(i)} className="input-item-icon">
              <FiMinusCircle size={25} color="#00989C" />
            </div>
          ) : null}
        </div>
      ))}
      <div onClick={addHandler} className="add-wrapper">
        <FiPlusCircle size={25} color="#00989C" />
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  padding-top: 4rem;
  max-height: 300px;
  overflow-y: scroll;
  .input-item {
    display: flex;
    margin-bottom: 1rem;
    input {
      width: 90%;
      padding: 1rem;
      margin-right: 1rem;
      font-size: 1.4rem;
    }
    .input-item-icon {
      align-self: center;
      svg {
        cursor: pointer;
      }
    }
  }
  .add-wrapper {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    cursor: pointer;
    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
`;
