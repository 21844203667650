import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer, Zoom } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import { Notify } from 'components/Notify';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga';
import { store, persistor } from './store/store';
import Routes from './routes/index';
import 'react-toastify/dist/ReactToastify.css';
import theme from './theme';

const queryClient = new QueryClient();

function App() {
  ReactGA.initialize('G-TRXYMS9RYD');
  ReactGA.pageview(window.location.pathname + window.location.search);
  // test oh
  return (
    <Provider store={store}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <Notify />
              <ToastContainer
                transition={Zoom}
                autoClose={4000}
                hideProgressBar
                pauseOnHover={false}
                draggable={false}
                position="top-center"
              />
              <Routes />
            </QueryClientProvider>
          </PersistGate>
        </ThemeProvider>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
