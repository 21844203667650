import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authApis from 'apis/user';
import styled from 'styled-components';
import Button from 'components/Buttons';
import ErrorHandler from 'helpers/errorHandler';

export default function Index() {
  const navigate = useNavigate();
  const [requestStatus, setRequestStatus] = useState(false);
  const [requestMessage, setRequestMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const removeHash = new URL(window?.location?.href?.replace(/#/g, ''));
    const search = new URLSearchParams(removeHash.search);
    const payLoad = {
      emailAddress: search.get('emailAddress'),
      code: search.get('code'),
    };

    const confirmCode = async () => {
      setIsLoading(true);
      try {
        const res = await authApis.userConfirmation(payLoad);
        if (!res.data.status) {
          setRequestMessage(res?.data?.data);
        }
        setRequestStatus((prev) => res.data.status);
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };
    confirmCode();
    return () => {};
  }, []);
  return (
    <Styled>
      {isLoading ? (
        <p className="loader">Loading...</p>
      ) : (
        <div className="feedback">
          <div className="text-wrapper">
            {requestStatus ? (
              <div>
                <p>Email Successfully Confirmed</p>
                <p>Thank You For Registering</p>
              </div>
            ) : (
              <p>{requestMessage}</p>
            )}
          </div>
          <Button
            mode="1"
            onClick={() => navigate('/auth/login')}
            title="Click to Login"
          />
        </div>
      )}
    </Styled>
  );
}
const Styled = styled.div`
  width: 100%;
  background: #e5e5e5;
  height: calc(100vh - 6rem);
  display: flex;
  justify-content: center;
  align-items: center;
  .feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    width: 50rem;
    margin: 0 auto;
    border-radius: 15px;
    padding: 6rem 4rem;
    .text-wrapper {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  .loader {
    font-size: 2rem;
    text-align: center;
    padding-top: 2rem;
  }
`;
