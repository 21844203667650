import { apiWithToken } from './axiosInterceptor';

const request = {
  getCollateralTypes: (data) => {
    const method = 'get';
    const url = '/api/Frr/GetCollateralType';
    return apiWithToken({ method, url, params: { BusinessId: data } });
  },
  getBankValuers: (data) => {
    const method = 'get';
    const url = '/api/Frr/GetApprovedBankValuers';
    return apiWithToken({ method, url, params: { BusinessId: data } });
  },
  getCollateralPricingTbl: ({ collateralTypeId }) => {
    const method = 'get';
    const url = '/api/Frr/GetCollateralPricingTbl';
    return apiWithToken({
      method,
      url,
      params: { collateralTypeId },
    });
  },
  // Frr
  getFacilityTypes: () => {
    const method = 'get';
    const url = '/api/Frr/GetFacilityType';
    return apiWithToken({ method, url });
  },
  getFacilityPurpose: () => {
    const method = 'get';
    const url = '/api/Frr/GetFacilityPurpose';
    return apiWithToken({ method, url });
  },
  getFacilitySubPurposeByPurposeId: (purposeId) => {
    const method = 'get';
    const url = `/api/Frr/GetFacilitySubPurposeByPurposeId?PurposeId=${purposeId}`;
    return apiWithToken({ method, url });
  },
  getFacilitySubPurposeQuestions: (subPurposeId) => {
    const method = 'get';
    const url = `/api/Frr/GetFRRQuestionBySubPurposeId?subPurposeId=${subPurposeId}`;
    return apiWithToken({ method, url });
  },
  getFacilityQuestionAnswers: (frrQuestionId) => {
    const method = 'get';
    const url = `/api/Frr/GetAnswerByFRRQuestionId?frrQuestionId=${frrQuestionId}`;
    return apiWithToken({ method, url });
  },
  getORRSummaryScore: (businessId) => {
    const method = 'get';
    const url = `/api/Verification/GetORRScoreByBusinessId?businessId=${businessId}`;
    return apiWithToken({ method, url });
  },
  getCurrencyCodes: () => {
    const method = 'get';
    const url = '/api/Frr/GetCurrencyCodes';
    return apiWithToken({ method, url });
  },
  getFacilityDetailsByBusinessId: (data) => {
    const method = 'get';
    const url = `/api/Frr/GetFacilityDetailsByBusinessId?businessId=${data}`;
    return apiWithToken({ method, url, data });
  },
  getFacilityDetailsByFacilityId: (data) => {
    const method = 'get';
    const url = `/api/Frr/GetFacilityDetailsByFacilityId?facilityId=${data}`;
    return apiWithToken({ method, url, data });
  },
  getAnsweredFrrResponse: (facilityId) => {
    const method = 'get';
    const url = `/api/Frr/GetFrrResponseByFacilityId?facilityId=${facilityId}`;
    return apiWithToken({ method, url });
  },
  getPurposeBySubPurposeId: (subPurposeId) => {
    const method = 'get';
    const url = `/api/Frr/GetFacilityPurposeBySubPurposeId?subPurposeId=${subPurposeId}`;
    return apiWithToken({ method, url });
  },
  getFrrResponse: (facilityId) => {
    const method = 'get';
    const url = `/api/Frr/GetFrrResponseByFacilityId?facilityId=${facilityId}`;
    return apiWithToken({ method, url });
  },
  postFrrResponse: (data) => {
    const method = 'post';
    const url = '/api/Frr/createAndUpdateFrrResponse';
    return apiWithToken({ method, url, data });
  },
  postFacility: (data) => {
    const method = 'post';
    const url = '/api/Frr/createFacilityDetails';
    return apiWithToken({ method, url, data });
  },
  updateFacility: (data) => {
    const method = 'put';
    const url = '/api/Frr/UpdateFacilityDetails';
    return apiWithToken({ method, url, data });
  },
  getSingleFrrQuestion: (data) => {
    const method = 'get';
    const url = `/api/Frr/GetFRRQuestionByQuestionId?questionId=${data}`;
    return apiWithToken({ method, url, data });
  },
  getFacilitySubPurpose: () => {
    const method = 'get';
    const url = '/api/Frr/GetFacilitySubPurpose';
    return apiWithToken({ method, url });
  },
  getCollateralDetails: (data) => {
    const method = 'get';
    const url = `/api/Frr/GetCollateralDetailByBusinessId?businessId=${data}`;
    return apiWithToken({ method, url, data });
  },
  postCollateral: (data) => {
    const method = 'post';
    const url = '/api/Frr/CreateCollateralDetail';
    return apiWithToken({ method, url, data });
  },
  getCollateralSeniority: () => {
    const method = 'get';
    const url = '/api/Frr/GetCollateralSeniority';
    return apiWithToken({ method, url });
  },
  getCollateralByBusinessId: (data) => {
    const method = 'get';
    const url = 'api/Frr/GetCollateralDetailByBusinessId';
    return apiWithToken({ method, url, params: { businessId: data } });
  },
  getCollateralsMappedToFacility: (data) => {
    const method = 'get';
    const url = `/api/Frr/getCollateralDetailsByFacilityId?facilityId=${data}`;
    return apiWithToken({ method, url, data });
  },
  getSubPurposeAnswersBySubPurposeId: (data) => {
    const method = 'get';
    const url = `/api/Frr/GetFRRAnswerAndQuestionBySubPurposeId?subPurposeId=${data}`;
    return apiWithToken({ method, url, data });
  },
  mapFacilityToCollateral: (data) => {
    const method = 'post';
    const url = '/api/Frr/CreateMappingCollateralToFacility';
    return apiWithToken({ method, url, data });
  },
  unMapCollateralFromFacility: (data) => {
    const method = 'post';
    const url = `/api/Frr/DeleteMappingCollateralToFacility?mappingId=${data}`;
    return apiWithToken({ method, url, data });
  },
  getSolicitorFeeStateMappingByStatId: ({ stateId }) => {
    const method = 'get';
    const url = 'api/Frr/GetSolicitorFeeStateMappingByStatId';
    return apiWithToken({ method, url, params: { stateId } });
  },
  getCollateralById: ({ collateralId }) => {
    const method = 'get';
    const url = 'api/Frr/GetCollateralById';
    return apiWithToken({ method, url, params: { collateralId } });
  },
  getFacilityFeedbackByBusinessId: (businessId) => {
    const method = 'get';
    const url = `api/Frr/GetAllFacilityFeedbacksByBusinessId?businessId=${businessId}`;
    return apiWithToken({ method, url, params: { businessId } });
  },
  getFeedbackByFacilityId: (facilityId) => {
    const method = 'get';
    const url = `/api/Frr/GetFeedbacksByFacilityId?facilityId=${facilityId}`;
    return apiWithToken({ method, url });
  },
  submitFacilityFeedbackResponses: (data) => {
    const method = 'put';
    const url = '/api/Frr/ResubmitFrrResponses';
    return apiWithToken({ method, url, data });
  },
  getSubmittedFacilityFeedbackResponses: (data) => {
    const method = 'get';
    const url = `/api/Frr/GetResponsesByFacilityId?facilityId=${data}`;
    return apiWithToken({ method, url });
  },
  getCreditHistory: (data) => {
    const method = 'get';
    const url = `api/Credit/GetCreditHistory?customerId=${data}`;
    return apiWithToken({ method, url });
  },
  getFacilityDetailsByCollateralId: ({ collateralId }) => {
    const method = 'get';
    const url = 'api/Frr/GetFacilityDetailsByCollateralId';
    return apiWithToken({ method, url, params: { collateralId } });
  },
  // Collateral questions
  getCollateralQuesAndAnswersByCollateralTypeId: ({ collateralTypeId }) => {
    const method = 'get';
    const url = '/api/Frr/GetCollateralQuestionAndAnswersByCollateralTypeId';
    return apiWithToken({ method, url, params: { collateralTypeId } });
  },
  submitAndUpdateCollateralResponse: (data) => {
    const method = 'post';
    const url = '/api/Frr/SubmitAndUpdateCollateralResponse';
    return apiWithToken({ method, url, data });
  },
  getCollateralResponseByCollateralId: ({ collateral }) => {
    const method = 'get';
    const url = 'api/Frr/GetCollateralResponseByCollateralId';
    return apiWithToken({ method, url, params: { collateral } });
  },
  getFlaggedCollateralsByBusinessId: ({ BusinessId }) => {
    const method = 'get';
    const url = 'api/Frr/GetFlaggedCollaterals';
    return apiWithToken({ method, url, params: { BusinessId } });
  },
  getFlaggedCollateralQuestionsByCollateralId: ({ collateralId }) => {
    const method = 'get';
    const url = 'api/Frr/GetFlaggedCollateralQuestionsByCollateralId';
    return apiWithToken({ method, url, params: { collateralId } });
  },
  updateFlaggedCollateralQuestionsByCollateralResponseId: (data) => {
    const method = 'put';
    const url = 'api/Frr/UpdateFlaggedCollaterlQuestionsByCollateralResponseId';
    return apiWithToken({ method, url, data });
  },
};

export default request;
