import React, { useState } from 'react';
import styled from 'styled-components';
import Calculator from 'components/OverLays/Calculator';
import { useSelector, useDispatch } from 'react-redux';
import { CalculatorToggle } from 'store/actions/calculator';
import { FiMenu } from 'react-icons/fi';
import { useNavigate, Link } from 'react-router-dom';
import * as SignUpActions from 'store/actions/signUpForm';
import {
  ToggleSvg,
  CalculatorSvg,
  SignInSvg,
  AnchorSvg,
  QuestionCircleSvg,
  LogoSvg,
} from 'theme/icons';

export default function Navbar() {
  const navigate = useNavigate();
  const { calculator } = useSelector((state) => state);
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
  const dispatch = useDispatch();
  const handleCalculatorToggle = () => {
    dispatch(CalculatorToggle());
  };
  const mobileMenuHandler = () => {
    setMobileMenuToggle((prev) => !prev);
  };
  const linkHandler = (link) => {
    const verifyLogout = window.confirm('Exiting will terminate the application');
    if (verifyLogout) {
      dispatch(SignUpActions.ResetForm());
      navigate(link);
    }
  };
  return (
    <Styled>
      <div className="nav-wrapper maxWidth-1440">
        <div className="logo">
          <div className="nav_link" onClick={() => linkHandler('/')}>
            <LogoSvg width="100px" className="nav_link_svg" height="40px" />
          </div>
        </div>
        <div className="nav-links">
          <div className="nav_link">
            <ToggleSvg width="25px" stroke="#D9B08C" className="nav_link_svg" />
            <p
              className="nav_link_txt"
              onClick={() => linkHandler('/how-it-works')}
            >
              How it Works
            </p>
          </div>
          <div className="nav_link">
            <CalculatorSvg
              width="25px"
              stroke="#D9B08C"
              className="nav_link_svg"
            />
            <p className="nav_link_txt" onClick={handleCalculatorToggle}>
              Loan Calculator
            </p>
          </div>
          <div className="nav_link">
            <AnchorSvg width="25px" stroke="#D9B08C" className="nav_link_svg" />
            <p className="nav_link_txt" onClick={() => linkHandler('/support')}>
              Support
            </p>
          </div>
          <div className="nav_link">
            <QuestionCircleSvg
              width="25px"
              stroke="#D9B08C"
              className="nav_link_svg"
            />
            <p className="nav_link_txt" onClick={() => linkHandler('/faq')}>
              FAQs
            </p>
          </div>
          <div className="nav_link">
            <SignInSvg
              width="25px"
              height="16px"
              stroke="#D9B08C"
              className="nav_link_svg"
              strokeWidth=".05px"
            />
            <p
              className="nav_link_txt"
              onClick={() => linkHandler('/auth/login')}
            >
              Sign In
            </p>
          </div>
        </div>
      </div>
      <div className="mobile-nav">
        <Link to="/">
          <p className="logo">Gazelle</p>
        </Link>
        <div className="mobile-menu-wrapper">
          <FiMenu color="#d9b08c" size="30" onClick={mobileMenuHandler} />
        </div>
        {mobileMenuToggle ? (
          <nav className="mobile-nav-dropdown">
            <Link to="/">Home</Link>
            <Link to="/how-it-works">How it Works</Link>
            <Link to="/support">Support</Link>
            <Link to="/faq">FAQs</Link>
            <Link to="/auth/login">Sign In</Link>
          </nav>
        ) : null}
      </div>
      {calculator.display && (
        <Calculator handleCalculatorToggle={handleCalculatorToggle} />
      )}
    </Styled>
  );
}
const Styled = styled.div`
  background: #004c4e;
  height: 60px;
  @media (max-width: 479px) {
    height: 50px;
  }
  .mobile-nav {
    position: relative;
    height: 100%;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    @media (max-width: 479px) {
      display: flex;
    }
    .logo {
      font-size: 20px;
      color: #fff;
    }
    .mobile-nav-dropdown {
      position: absolute;
      width: ${window.innerWidth}px;
      z-index: 10;
      left: 0px;
      top: 50px;
      background: #fff;
      a {
        display: block;
        padding: 10px;
        font-weight: 500;
        color: #000;
      }
    }
    .mobile-menu-wrapper {
      position: relative;
      cursor: pointer;
    }
  }
  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-left: 20px;
    border-bottom: 1px solid rgba(217, 176, 140, 0.5);
    @media (max-width: 479px) {
      display: none;
    }
    & > div {
      display: flex;
      align-items: center;
    }
  }
  .maxWidth-1440 {
    max-width: 1440px;
    margin: 0 auto;
  }
  a {
    color: #d9b08c;
  }
  .nav_link {
    text-align: center;
    color: #d9b08c;
    font-size: 11px;
    display: flex;
    align-items: center;
    padding-right: 40px;
  }
  .nav_link_svg {
    margin-right: 10px;
    @media (max-width: 700px) {
      display: none;
    }
  }
  .nav_link_txt {
    color: #d9b08c;
    cursor: pointer;
  }
`;
