import React from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';
import Button from 'components/Buttons';
import { useDispatch } from 'react-redux';
import * as SignUpFormActions from 'store/actions/signUpForm';
import styled from 'styled-components';

const customStyles = {
  content: {
    borderRadius: '10px',
    position: 'relative',
    margin: 'auto',
    inset: '0',
  },
};
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0 0 0 / 54%)';
export default function NewExistingModal(props) {
  const dispatch = useDispatch();
  const newOrExistingHandler = (accountType) => {
    dispatch(SignUpFormActions.SaveActivePage('tinOrAcc'));
    dispatch(SignUpFormActions.SaveCustomerType(accountType));
  };
  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      isOpen={props.modalIsOpen}
      onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="bvnValidation"
    >
      <Styled>
        <div className="closeBtn">
          <FiX size={20} color="#505050" onClick={props.closeModal} />
        </div>
        <div>
          <p className="title">Do you have a Sterling Corporate Account?</p>
          <div className="submitButton">
            <Button
              onClick={() => newOrExistingHandler('New')}
              title="No"
              mode="3"
            />
            <Button
              onClick={() => newOrExistingHandler('Old')}
              title="Yes I Do"
              mode="1"
            />
          </div>
        </div>
      </Styled>
    </Modal>
  );
}
const Styled = styled.div`
  margin-top: 1rem;
  color: #505050;
  .closeBtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .title {
    margin-bottom: 1rem;
    font-weight: 500;
    color: #004c4e;
    text-align: center;
    padding: 1rem;
  }
  .submitButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    button {
      margin: 2rem 4rem;
    }
  }
`;
