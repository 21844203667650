import { colors } from './colors';
import { fontSize, lineHeight } from './fonts';
import { media } from './mediaQueries';
import { typography } from './typography';

const theme = {
  colors,
  media,
  fontSize,
  lineHeight,
  typography,
};
export default theme;
