import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCollateralPayload } from 'store/actions/collateral';
import { InputTypes } from './QuestionTypes';

export default function SecurityValue() {
  const { collateral } = useSelector((state) => state);
  const { payLoad, checks } = collateral;
  const [securityValue, setSecurityValue] = useState('');
  const dispatch = useDispatch();
  const inputHandler = (e) => {
    const { value } = e.target;
    setSecurityValue(value);
    dispatch(
      UpdateCollateralPayload({
        securityValue: value,
      })
    );
    sessionStorage.setItem('_currentPage', 'securityValue');
  };

  return (
    <Styled>
      <section>
        <p className="question_title">Whats your Security Value ?</p>
        <div className="">
          <InputTypes
            questionType="number"
            inputHandler={inputHandler}
            inputtedAnswer={payLoad.securityValue || securityValue}
          />
        </div>
      </section>
    </Styled>
  );
}
const Styled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7%;
  width: 100%;
  overflow: scroll;
  height: 44.4rem;
  @media (max-width: 1280px) {
    padding: 0 5%;
  }
  .question_title {
    text-align: center;
    margin: 2rem 0;
  }
`;
