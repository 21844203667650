import React from 'react';
import styled from 'styled-components';

export default function termsAndConditions(props) {
  return (
    <Styled {...props}>
      <div className="terms-wrapper">
        <h1>Terms and Conditions</h1>
        <br />
        <p>Date Updated: March 11, 2021</p>
        <br />
        <p>
          <strong>Welcome to </strong>
          Gazelle by Sterling provided by Sterling
          Bank Plc (“We”, “Our”, “Us” or “Sterling”).
        </p>
        <br />
        <h3>General Terms</h3>
        <p>
          These Terms of Use (the "Agreement") describe the terms and conditions
          applicable to a User’s ("You", “Customer”, "Your") access to and use
          of the Gazelle by Sterling (“Platform”, “service”) as well as our
          obligations with respect thereto. Your access to or use of the
          Services ("Service") available on the Platform is strictly subject to
          Your compliance with this Agreement (as updated periodically).
        </p>
        <br />
        <p>
          Your use of the Services constitutes your agreement to be bound by
          this Agreement. If you do not agree with the terms of this Agreement,
          then you will be unable to access the Services. By entering this
          Agreement, You represent and warrant that you are authorized to do so.
          Where you meet our credit criteria, you will be required to digitally
          accept and execute our facility documents relating to the facility
          offer.
        </p>
        <br />
        <h3>About The Platform</h3>
        <p>
          Gazelle by Sterling is a digital platform that leverages digital
          technology in providing credit to corporate businesses.
        </p>
        <br />
        <h3>Modifications to The Terms and Conditions</h3>
        <p>
          We may change these terms from time to time. This is solely at our
          discretion and changes will be effective when posted on the Platform
          with no other notice provided. Please check the Terms regularly for
          updates as changes shall be effective immediately, and, we may
          indicate the date of such revision.
        </p>
        <br />
        <p>
          In addition, we may change, suspend or discontinue any aspect of the
          Services at any time, including the availability of any feature, or
          content. We may also impose limits on certain features and service or
          restrict your access to parts or all of the Platform or the Services
          provided without notice or liability.
        </p>
        <p>
          If you keep using the Platform after a change, that means you accept
          the new terms.
        </p>
        <br />
        <h3>Use of The Service Offered on The Platform</h3>
        <ol className="list-alpha">
          <li>
            We make no warranty that any part of the Platform will operate
            uninterrupted or error free and we accept no liability for loss or
            damage caused from any interruption or error on any part of the
            Platform.
          </li>
          <li>
            To be eligible to use the Platform, in the case of an individual or
            a sole proprietorship, you must be 18 years or above, a partnership
            firm or a company.
          </li>
          <li>
            We reserve the right to;
            <ol>
              <li>
                refuse to provide access to the Platform to any individual,
                business or other entity at any time without explanation,
                consequence or liability; and;
              </li>
              <li>
                to remove a service from the Platform with no obligation to give
                advance notice, and we shall not be liable for losses, costs or
                expenses arising from any such refusal or removal
              </li>
            </ol>
          </li>
          <li>You assume all risks associated with the use of the Platform</li>
        </ol>
        <br />
        <h3>Eligibility to Use The Platform</h3>
        <p>
          The Platform is available only to registered users. By accepting these
          Terms, you acknowledge that your profile information will become part
          of the Gazelle By Sterling database.
        </p>
        <br />
        <h3>Services on The Platform</h3>
        <p>
          The platform provides users with Lending services, including but not
          limited to; origination, assessment, underwriting, structuring,
          disbursement, monitoring, recovery and advisory services.
        </p>
        <br />
        <h3>General Terms</h3>
        <h3>Account Enrollment and Use of Account</h3>
        <p>
          To access the Services on the Platform, firstly, you need to enrol to
          create an individual or corporate profile (“Account”), by providing
          certain information.
        </p>
        <p>
          In exchange for your use of the Service and, if applicable, in order
          for providers to send notices to you. You agree to: (i) provide true,
          accurate, current and complete information about yourself as prompted
          by our Account enrolment form and (ii) each time you log on, maintain
          and promptly update such Account information to keep it true,
          accurate, current and complete. If you provide any information that is
          untrue, inaccurate, not current or incomplete, or we have reasonable
          grounds to suspect that such Account information is untrue,
          inaccurate, not current or incomplete, we reserve the right to refuse
          any future request(s) to use the Services.
        </p>
        <br />
        <p>
          You shall at all times remain responsible for maintaining the
          confidentiality of your Account password/access code or passcode and
          username (if any) and any other security information related to your
          Account. You agree to accept responsibility for all activities that
          occur under your username and password. We will not be liable for any
          loss that you incur as a result of someone else accessing and using
          your Account, either with or without your knowledge. Where a service
          is to be accessed by a corporate entity, the Organization will be
          required to provide its full registration information including its
          registered address, business objects, names of its key management,
          shareholding information etc.
        </p>
        <br />
        <h3>Express Consent</h3>
        <p>
          The usage of the Platform may also require you to provide consent for
          keying in/uploading your Personal Identifiable Information
          (PII)(including but not limited to user IDs and passwords), as may be
          necessary to process your application through the Platform. Any PII
          which requires to be keyed in/uploaded is required for enabling
          hassle-free, faster and paperless (to the extent possible) processing
          of applications for products so opted/ availed by you. You, whilst
          providing your details/documents including but not limited to copy of
          the certification of incorporation, Bank Verification Numbers, Tax
          Identification Numbers, Financial statement, details of existing
          business relationships etc. expressly consent to us (including our
          service provider(s)/ business partner(s) to authenticate/verify such
          PII submitted by you, through the creation of images of such documents
          including retaining such images for business purposes.
        </p>
        <br />
        <p>
          You hereby authorise and expressly consent us to share your PII with
          our third-party service provider(s) to authenticate you. If you are no
          longer interested in sharing your PII, please e-mail your request to
          {' '}
          <a href="mailto:gazelle@sterling.ng">gazelle@sterling.ng</a>
          . Please
          note that the services of third-party providers are sometimes required
          in the provision of the Services on the platform. When you withdraw
          your consent to share your information with third parties, you may no
          longer be able to access the services on the Platform.
        </p>
        <p>
          We reserve the right (and you expressly authorise us) to the extent
          permissible under the applicable law, to share or disclose your PII
          when we determine, in our sole discretion, that the disclosure of such
          information is necessary or appropriate under the law in force for the
          time being.
        </p>
        <p>
          You hereby acknowledge that the use of the Service may involve costs
          such as valuation fee, company search fee, property title fee etc (
          “associated costs”) necessary to process your service request and the
          payment of any associated cost by you guarantees neither the approval
          of the facility nor subsequent disbursement. You also consent to the
          debit of your account by us for any or all of the associated costs
          which may be required to process your service request.
        </p>
        <br />
        <h3>Prohibited Use</h3>
        <p>The User will not use the Platform to:</p>
        <ol className="list-alpha">
          <li>
            interfere, disrupt or attempt to gain unauthorised access to other
            accounts on the Gazelle by Sterling or any other computer network;
          </li>
          <li>
            use any device, software or routine, including but not limited to
            viruses, trojan horses, worms, time bombs, and cancelbots, which
            damage or hinder, or are intended to damage or hinder, the proper
            working of the web site, or to unlawfully intercept or expropriate
            any software, data or other information from the web site;
          </li>
          <li>
            take any action that imposes an unreasonable or disproportionate
            load on the Platform.
          </li>
          <li>
            interfere with or disrupt the Services or the servers or networks
            connected to the Service, including any security or access control
            mechanism;
          </li>
          <li>
            You will not post, email or otherwise transmit any material that
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer software or hardware or telecommunications
            equipment;
          </li>
          <li>
            You will not forge headers or otherwise manipulate identifiers in
            order to disguise the origin of any information transmitted through
            the Platform.
          </li>
          <li>
            You will not “frame” or “mirror” any part of the Platform, without
            our prior written authorization.
          </li>
          <li>
            You will not modify, adapt, sublicense, translate, sell, reverse
            engineer, decipher, decompile or otherwise disassemble or modify any
            portion of the Platform or any software used on or for the Service
            or cause or enable others to do so;
          </li>
          <li>
            You will not use the Platform if you are prohibited under applicable
            law from using the Platform.
          </li>
          <li>
            You will not conduct activities that may be harmful to others or
            that could damage the reputation of the Platform.
          </li>
          <li>
            You will not post, upload, or distribute marketing or advertising
            links or content, or any User content or other content that is
            unlawful, defamatory, libelous, inaccurate, or that a reasonable
            person could deem to be objectionable, profane, pornographic,
            harassing, threatening, embarrassing, hateful, or otherwise
            inappropriate;
          </li>
          <li>
            You will not make any unsolicited offer or advertisement to another
            user of the Platform or attempt to collect personal information or
            other information about another user or third party without their
            consent.
          </li>
        </ol>
        <br />
        <h3>Access</h3>
        <p>
          You agree that the Platform may be inaccessible or inoperable from
          time to time for many reasons, including: equipment malfunctions,
          periodic maintenance procedures or repairs which may be undertaken
          from time to time; causes beyond our control or which are not
          reasonably foreseeable by us; or at other times at at our sole
          discretion.
        </p>
        <p>
          We reserve the right, in our sole discretion and without notice, at
          any time and for any reason, to: remove or disable access to all or
          any portion of the Platform; or suspend user’s access to or use of all
          or any portion of the Platform.
        </p>
        <br />
        <h3>Licensed Use</h3>
        <p>
          The content and works (including design, text, graphics and the
          selection and arrangement) on the Platform are exclusively owned by
          Sterling and or licensed to Sterling. The duplication, processing,
          distribution and any kind of exploitation of the Platform’s content
          (to the extent not permitted by copyright laws) require the prior
          written consent of the Bank. Any use of the content not expressly
          permitted by these Terms of Use and Cookie Policy is a breach of these
          Terms of Use and Cookie Policy and may violate copyright, trademark
          and other laws. You must not use any part of the materials on the
          Platform for commercial purposes without obtaining a licence to do so
          from us or our licensors.
        </p>
        <br />
        <h3>Involvement With Third Parties</h3>
        <ol className="list-alpha">
          <li>
            <strong>Links to Third Party Sites.</strong>
            {' '}
            The Platform may allow
            access to other websites. These linked sites are not under our
            control and we are not responsible for the contents of any linked
            site. Gazelle by Sterling may provide links only as a convenience,
            and such inclusion of any link does not imply endorsement by Us of
            the site or any part of its contents.
          </li>
          <li>
            <strong>Items of Third Parties.</strong>
            {' '}
            Some of our Services allow
            users to make or process transactions for Items ("Transactions") and
            may allow users to upload or route various information including but
            not limited to information on Items or information relating to
            proposed or actual Transactions ("Content"). You acknowledge that,
            except for Items clearly identified as being those of Sterling, we
            do not operate, control or endorse any Items listed on the Platform
            or processed by the Platform.
          </li>
        </ol>
        <br />
        <h3>Disclaimers</h3>
        <ol className="list-alpha">
          <li>
            The platform may be under constant upgrades, and some functions and
            features may not be fully operational during periods of upgrade or
            software maintenance.
          </li>
          <li>
            We disclaim any liability arising due to the vagaries that can occur
            in the electronic distribution of information.
          </li>
          <li>
            You acknowledge that third party services are available on the
            Platform. We may have formed partnerships or alliances with some of
            these third parties from time to time in order to facilitate the
            provision of certain services to you. However, you acknowledge and
            agree that at no time are we making any representation or warranty
            regarding any third party's services nor will we be liable to you or
            any third party for any consequences or claims arising from or in
            connection with such third party including, and not limited to, any
            liability or responsibility for, death, injury or impairment
            experienced by you or any third party. You hereby disclaim and waive
            any rights and claims you may have against us with respect to third
            party’s services.
          </li>
          <li>
            We do not, in any way, endorse any such third-party information or
            service offered or described on the Platform. In no event shall we
            be liable to you or any third party for any decision made or action
            taken in reliance on such information.
          </li>
          <li>
            You assume all responsibility and risk with respect to your use of
            the Platform. The services are provided and available “as is,” and
            “as available”. You understand and agree that, to the fullest extent
            permitted by law, we disclaim all warranties, representations and
            endorsements, express or implied, with regard to the Platform,
            including, without limitation, implied warranties of title,
            merchantability, non-infringement and fitness for a particular
            purpose.
          </li>
          <li>
            We do not warrant use of the website will be uninterrupted or
            error-free or that errors will be detected or corrected. We do not
            assume any liability or responsibility for any computer viruses,
            bugs, malicious code or other harmful components, delays,
            inaccuracies, errors or omissions, or the accuracy, completeness,
            reliability or usefulness of the information disclosed or accessed
            through the services.
          </li>
          <li>
            You acknowledge that all interim credit decisions and feedback
            communicated to you in the course of a credit application do not
            represent the final approved credit terms. We disclaim any liability
            arising due to acts committed or decisions made by you arising from
            any interim feedback received from us relating to your service
            request.
          </li>
          <li>
            In no event, under no legal or equitable theory (whether tort,
            contract, strict liability or otherwise), shall we or any of our
            respective employees, directors, officers, agents or affiliates, be
            liable hereunder or otherwise for any loss or damage of any kind,
            direct or indirect, in connection with or arising from the use of
            the Platform / services or our agreement with you concerning the
            services, including, but not limited to, (i) the use of or inability
            to use the site, the service, or the content, (ii) any transaction
            conducted through or facilitated by the site; (iii) any claim
            attributable to errors, omissions, or other inaccuracies in the
            site, the service and/or the content, (iv) unauthorized access to or
            alteration of your transmissions or data, or (v) any other matter
            relating to the site, the service, or the content, and any
            compensatory, direct, consequential, incidental, indirect, special
            or punitive damages, lost anticipated profits, loss of goodwill,
            loss of data, business interruption, accuracy of results, or
            computer failure or malfunction, even if we have been advised of or
            should have known of the possibility of such damages.
          </li>
        </ol>
        <br />
        <h3>Data Integrity and Storage</h3>
        <p>
          <strong>Data Integrity.</strong>
          {' '}
          You are responsible for confirming
          the accuracy of any data you send to or receive from the Platform, and
          for maintaining a means external to the Platform, for the
          reconstruction of your lost data. Gazelle by Sterling has procedures
          to assist your efforts to confirm that your Data is accurate, current,
          and complete.
        </p>
        <br />
        <p>
          <strong>Storage of Data.</strong>
          {' '}
          The Platform will allow you to
          access Data you send to or receive from the Platform for a limited
          period of time, as may be announced by us from time to time and which
          may vary for specific Platform or functions. Notwithstanding the
          foregoing, we will not delete the transaction Data you retain on the
          Network that has been stored on the Network for less than
          eighteen-months, provided that your account is active and in good
          standing. You are solely responsible for saving all of Your Data.
          Therefore, You should take proactive measures to store Your Data
          within Your own computers, in order to preserve the accessibility of
          such Data beyond the Platform retention period applicable to You.
        </p>
        <br />
        <h3>Documentation</h3>
        <p>
          After a consideration and approval of a Service request hereunder, you
          may be required to electronically download and execute transactional
          documents and/or credit related documents in respect of the loan
          application/service for the purpose of evidencing and processing the
          transaction. You agree and understand that all documents executed and
          submitted electronically on this platform shall be presumed to be
          regular, enforceable and binding in the same manner as documents made
          physically and executed by hand.
        </p>
        <br />
        <h3>Severability</h3>
        <p>
          If any of these terms should be determined to be illegal, invalid or
          otherwise unenforceable by reason of the laws of any state or country
          in which these terms are intended to be effective, then to the extent
          and within the jurisdiction which that term is illegal, invalid or
          unenforceable, it shall be severed and deleted and the remaining of
          the Terms shall survive, remain in full force and effect and continue
          to be binding and enforceable.
        </p>
        <br />
        <h3>Non-Assignment</h3>
        <p>
          You may not transfer, assign, or delegate these Terms or your rights
          or obligations herein or your Account, in any way (by operation of law
          or otherwise). To the extent permitted by applicable law, we may
          transfer, assign, or delegate these Terms and our rights and
          obligations hereunder without your consent.
        </p>
        <br />
        <h3>Force Majeure</h3>
        <p>
          To the fullest extent permitted under applicable law, we will be
          excused from performance under these Terms for any period that we are
          prevented from or delayed in performing any obligations pursuant to
          these Terms, in whole or in part, as a result of a Force Majeure
          Event. For purposes of this section, “Force Majeure Event” means an
          event or series of events caused by or resulting from any of the
          following: (a) weather conditions or other elements of nature or acts
          of God; (b) acts of war, acts of terrorism, insurrection, riots, civil
          disorders or rebellion; (c) quarantines or embargoes, (d) labor
          strikes; (e); (f) unauthorized access to our information technology
          systems by third parties; or (g) other causes beyond our reasonable
          control and contemplation.
        </p>
        <br />
        <h3>Indemnity</h3>
        <p>
          You agree to unconditionally release, indemnify, defend and hold
          Sterling harmless against any liability, cost, expense, loss or any
          damage incurred by us (including but not limited to professional
          advisors' or cost of legal fees) for any Claim arising from or related
          to: (a) the Bank providing the Service, or accepting or processing a
          transaction or instruction from you or your nominated beneficiary or
          anyone else using the Service with your access credentials (b) you
          breaching this Agreement or a representation or warranty you give, (c)
          a third party claim against you These Indemnity obligations shall
          remain in force after you close your Account or this service
          terminates.
        </p>
        <p>
          These Indemnity obligations shall remain in force after you close your
          Account or this service terminates.
        </p>
        <br />
        <h3>Anti-Money Laundering</h3>
        <p>
          You are now subject to and will at all material times in the future
          comply with all applicable laws, rules and regulations concerning the
          detection of financial crimes, prevention of terrorism and anti-money
          laundering and you acknowledge that any transaction we deal with on
          your instructions will be covered by statutory and Regulatory
          requirements relating to money laundering and combating terrorist
          financing.
        </p>
        <br />
        <h3>Governing Law and Dispute Resolution</h3>
        <p>
          These Terms are governed by the laws of the Federal Republic of
          Nigeria.
        </p>
        <p>
          We will use our best efforts to resolve any issue that may arise from
          the use of the Platform. However, we realize that there may be rare
          cases where we may not be able to resolve an issue to your
          satisfaction. In the event we cannot resolve a dispute between us, you
          agree that all matters related to any use or access to the Platform or
          the services shall be referred to Mediation at the Lagos Multi-Door
          Courthouse, in accordance with the Lagos State Multidoor Courthouse
          Rules 2007 or extant law.
        </p>
        <br />
        <h3>Enquiries and Complaints</h3>
        <p>
          If you have any questions, complaints or claims with respect to these
          terms or the Platform, please contact us at
          {' '}
          <a href="mailto:gazelle@sterling.ng">gazelle@sterling.ng</a>
        </p>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  .terms-wrapper {
    max-width: 900px;
    margin: 0px auto;
    text-align: justify;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    @media (max-width: 479px) {
      padding: 20px 10px;
    }
  }

  p,
  li {
    font-size: 14px;
    line-height: 1.5;
  }
  strong {
    color: #004c4e;
  }
  li {
    padding-bottom: 5px;
    ::marker {
      font-weight: 600;
      color: #004c4e;
    }
  }
  h1 {
    color: #004c4e;
  }
  h3 {
    color: #004c4e;
    margin-bottom: 10px;
  }
  ol {
    padding-left: 10px;
    margin-left: 10px;
  }
  .list-alpha {
    list-style-type: lower-alpha;
    ol {
      list-style-type: lower-roman;
    }
  }
`;
