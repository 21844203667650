import React, { useState } from 'react';
import styled from 'styled-components';
import orrApis from 'apis/orr';
import AsyncSelect from 'react-select/async';
import { NextButton, PrevButton } from 'components/Buttons';
import { toast } from 'react-toastify';

export default function Auditor(props) {
  const [auditor, setAuditor] = useState([]);
  const onChangeHandler = (selectedAuditor) => {
    props.setFinAnswers((prev) => ({
      ...prev,
      auditor: String(selectedAuditor.icanListId),
    }));
  };
  const prevHandler = () => {
    props.prevHandler();
  };
  const nextHandler = () => {
    if (props?.finAnswers?.auditor) {
      props.nextHandler();
    } else {
      toast.error('Invalid Input');
    }
  };
  const getAuditors = async (inputValue, callback) => {
    try {
      if (auditor.length > 10) {
        return filterAuditors(inputValue);
      }
      const res = await orrApis.getAuditorsList();
      if (res.data.status) {
        const auditorsList = res.data.data;
        if (auditorsList.length > 0) {
          setAuditor(auditorsList);
          return auditorsList;
        }
      }
    } catch (error) {
    }
    return [];
  };
  const filterAuditors = (inputValue) => auditor.filter((i) => i.auditorName.toLowerCase().includes(inputValue.toLowerCase()));

  let auditorObj;
  if (props?.finAnswers?.auditor) {
    const getPrevAuditor = auditor?.filter(
      (elem) => elem.icanListId == props?.finAnswers?.auditor
    );
    auditorObj = getPrevAuditor.length == 0 ? {} : getPrevAuditor[0];
  }
  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: '1px solid #004c4e',
      fontSize: '16px'
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#004c4e',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      background: '#004c4e',
    }),
    option: (styles) => ({
      ...styles,
      fontSize: '16px'
    }),
  };

  return (
    <Styled>
      <section className="orr_ques_container">
        <div className="question">
          <p className="question_title">Who is your auditor</p>
          <AsyncSelect
            name="auditor"
            loadOptions={getAuditors}
            cacheOptions
            defaultOptions
            getOptionLabel={(e) => e.auditorName}
            getOptionValue={(e) => e.icanListId}
            onChange={onChangeHandler}
            value={auditorObj}
            styles={customStyles}
          />
          <p className="indicationWrapper">
            2 of
            {' '}
            <strong style={{ fontSize: '14px' }}>10</strong>
          </p>
        </div>
      </section>
      <section className="orr_button_section">
        <PrevButton onClick={prevHandler} />
        <NextButton onClick={nextHandler} />
      </section>
    </Styled>
  );
}
const Styled = styled.div`
  height: 100%;
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    height: 100%;
    margin-bottom: 2rem;
    background-color: #fff;
  }
  .question {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding: 0 10%;
    input{
      --tw-ring-color: none
    }
  }
  .question_title {
    text-align: center;
    margin-bottom: 2rem;
  }

  .orr_button_section {
    display: flex;
    justify-content: space-between;
  }
  .indicationWrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.2rem;
  }
`;
