import React from 'react';
import styled from 'styled-components';
import Portal from './Portal';

const Styled = styled.div`
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  position: fixed;
  background: #232c3038;
  z-index: 10;
  display: flex;
  .overlay__inner {
    margin: auto;
    position: relative;
    display: inline-block;
  }
  .overlay__content {
  }
  .spinner {
    width: 55px;
    height: 55px;
    display: inline-block;
    border-width: 1px;
    border-color: rgba(255, 255, 255);
    border-top-color: #004c4e;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
    ~ p {
      color: #004c4e;
      font-weight: 500;
      margin-left: -5px;
      margin-top: 5px;
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function OverLaySpinner() {
  return (
    <Portal>
      <Styled className="overlay">
        <div className="overlay__inner">
          <div className="overlay__content">
            <span className="spinner" />
            <p>Loading...</p>
          </div>
        </div>
      </Styled>
    </Portal>
  );
}
