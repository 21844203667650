import * as actionTypes from '../action-types/collateral';

export const SetCollateralChecks = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SET_COLLATERAL_CHECKS, payLoad: data });
};
export const UpdateCollateralPayload = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.UPDATE_COLLATERAL_PAYLOAD, payLoad: data });
};
export const ResetCollateral = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.CLEAR_COLLATERAL });
};
