import React, { useEffect, useState } from 'react';
import Header from 'components/Headers/Orr';
import styled from 'styled-components';
import Button from 'components/Buttons';
import { useSelector } from 'react-redux';
import Spinner from 'components/OverLays/OverLaySpinner';
import { settingApis } from 'apis';
import _cloneDeep from 'lodash/cloneDeep';
import { useNavigate } from 'react-router-dom';
import ErrorHandler from 'helpers/errorHandler';
import { FileSvg } from 'theme/icons';
import FileUpload from './BusMgtFin/BusinessMgt/FileUploadV3Upload';

export default function Upload() {
  const navigate = useNavigate();
  const { auth, business } = useSelector((state) => state);
  const [isLoading, setLoader] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [allDoc, setAllDoc] = useState([]);
  useEffect(() => {
    let isMounted = true;
    const getAllDoc = async () => {
      setLoader(true);
      try {
        const response = await settingApis.orrDocumentBySubSectorId(
          business.businessProfiles[0].subSectorId
        );
        const response2 = await settingApis.getOrrDocuments(
          auth.user.businessId
        );
        const documentToUpload = [...response.data.data].filter(
          (elem) => elem.isDisabled == false && !elem.documentName.includes('Facility')
        );
        for (let i = 0; i < documentToUpload.length; i++) {
          for (let j = 0; j < response2.data.data.length; j++) {
            const {
              documentId, status, uploadId, documentUrl
            } = response2?.data?.data[j];
            if (documentToUpload[i]?.documentId == documentId) {
              documentToUpload[i].fileUrl = documentUrl;
              documentToUpload[i].status = status;
              documentToUpload[i].uploadId = uploadId;
            }
          }
        }
        isMounted && setAllDoc(documentToUpload);
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    };
    getAllDoc();
    return () => {
      isMounted = false;
    };
  }, []);

  const fileHandler = (uploadRespond, id) => {
    const stateCopy = _cloneDeep(allDoc);
    stateCopy[id].fileUrl = uploadRespond;
    stateCopy[id].status = 'UPLOADED';
    setAllDoc(stateCopy);
  };
  return (
    <Styled>
      <Header />
      {isLoading ? <Spinner /> : ''}
      <div className="box">
        <p className="upload_title">Document Upload</p>
        <section className="tab_form">
          <div className="tab_form_nav">
            <div className="tab_form_nav_item tab_form_nav_item-active">
              Business Evaluation Documents
            </div>
          </div>
          <div className="tab_form_info_panel">
            <div className="document-type">
              <p className="document-type-ttl">
                pdf, doc, xlsx only
                {' '}
                <span>(10mb max)</span>
              </p>
            </div>
          </div>
          <div className="title-bar">
            <div className="document-title-bar">
              <p>Title</p>
            </div>
            <div className="document-action-bar">
              <p>Action</p>
            </div>
          </div>
          <div className="tab_form_main">
            {allDoc?.map((elem, i) => (
              <div key={elem?.documentId} className="tab_form_main-item">
                <div className="document-title-wrapper">
                  <FileSvg color="#D9B08C" width="25px" />
                  <p className="document-title">{elem?.documentName}</p>
                </div>
                <FileUpload
                  uploadId={elem.uploadId}
                  documentId={elem?.documentId}
                  documentStatus={elem.status}
                  setBtnDisabled={setBtnDisabled}
                  url={elem?.fileUrl}
                  fileHandler={(e) => fileHandler(e, i)}
                />
              </div>
            ))}
          </div>
          <div className="proceed_wrapper">
            <Button
              title="Proceed"
              mode="1"
              onClick={() => navigate('/app/orr/review')}
              disabled={isBtnDisabled}
            />
          </div>
        </section>
      </div>
    </Styled>
  );
}
const Styled = styled.div`
  background: #e5e5e5;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 4rem;
  .box {
    margin: 0 auto;
    max-width: 90rem;
    width: 100%;
    padding: 1rem;
  }
  .document-title-wrapper {
    display: flex;
    width: 60%;
    align-items: center;
    svg {
      margin-right: 1rem;
    }
  }
  .document-title {
    font-size: 1.4rem;
    color: #004c4e;
    font-weight: 500;
    width: 80%;
  }
  .upload_title {
    font-weight: 500;
    color: #004c4e;
    margin: 4rem 0 2rem 0;
    font-size: 2rem;
  }
  .tab_form_nav {
    display: flex;
  }
  .tab_form_info_panel {
    background: #fff;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0.5rem 0 5rem;
  }
  .document-type {
    background-color: ${(props) => props.theme.colors.grey_light_1};
    border: thin dashed ${(props) => props.theme.colors.red};
    padding: 0.2rem;
    border-radius: 0.5rem;
  }
  .document-type-ttl {
    color: ${(props) => props.theme.colors.red};
    font-size: 1.4rem;
    font-weight: 500;
    span {
      font-size: 1.2rem;
    }
  }
  .tab_form_nav_item {
    background-color: #e5e5e5;
    color: #808080;
    margin-right: 0.2rem;
    padding: 0.5rem 1rem;
    text-align: center;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 1.4rem;
  }
  .tab_form_nav_item-active {
    background-color: #dbdbdb;
    color: #000;
  }
  .tab_form_main {
    padding: 0rem 1rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 50rem;
    overflow-y: scroll;
    background: #fff;
  }
  .tab_form_main-item {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
  }
  .orr_button_section {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
  .proceed_wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 2rem;
  }
  .title-bar {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    color: #004c4e;
    border-bottom: 1px solid #c1c1c1;
    padding: 0.8rem 2rem;
    background: #fff;
    border-top-right-radius: 5px;
  }
  .document-title-bar {
    width: 60%;
  }
  .document-action-bar {
    width: 30%;
  }
`;
