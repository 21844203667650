import { useEffect, useState } from 'react';
import { frrApis } from 'apis';
import { useSelector } from 'react-redux';
import errorHandler from 'helpers/errorHandler';

export default function useCollateralFeedBack() {
  const { business } = useSelector((state) => state);
  const [collateralFeedBack, setCollateralFeedBack] = useState({
    status: false,
    loader: false,
  });

  const businessId = business?.businessProfiles[0].businessId;

  useEffect(() => {
    const getFeedBack = async () => {
      try {
        const res = await frrApis.getFlaggedCollateralsByBusinessId({
          BusinessId: businessId,
        });
        setCollateralFeedBack(prev => ({ ...prev, status: !!res?.data?.data?.length }));
      } catch (error) {
        errorHandler(error);
      }
    };

    getFeedBack();
  }, []);

  return [collateralFeedBack];
}
