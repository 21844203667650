import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NextButton, PrevButton } from 'components/Buttons';
import _cloneDeep from 'lodash/cloneDeep';
import { businessApis, adminUserApis } from 'apis';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from 'components/OverLays/OverLaySpinner';

export default function PrimaryPromoter(props) {
  const navigate = useNavigate();
  const [directorsList, setDirectorsList] = useState(props.directors);
  const [mgtList, setMgtList] = useState([]);
  const [isLoading, setLoader] = useState(false);
  useEffect(() => {
    const principal_mgtList = sessionStorage.getItem('principal_mgtList');
    setMgtList(JSON.parse(principal_mgtList));
    return () => {};
  }, []);
  const nextHandler = async (e) => {
    e.preventDefault();
    if (verifyInputs(directorsList)) {
      try {
        setLoader(true);
        const payLoad = directorsList.map((elem) => {
          elem.businessId = props.authStore?.user?.businessId;
          return elem;
        });
        await adminUserApis.saveGenericAnswers(payLoad);
        await businessApis.updateBusinessStatus({
          businessId: props.businessId,
          profileStatus: 'orr-started',
          isEditable: 'false',
        });
        toast.info('Section Saved');
        navigate('/app/orr/business-mgt-financial');
      } catch (error) {
        toast.info(error.message);
      } finally {
        setLoader(false);
      }
    }
  };
  function checkForDuplicates(array) {
    return new Set(array).size !== array.length;
  }
  const verifyInputs = (values) => {
    const getALlBVN = [];
    for (let i = 0; i < values.length; i++) {
      getALlBVN.push(values[i]);
    }
    if (checkForDuplicates(getALlBVN)) {
      toast.error('Duplicate BVN Inputted');
    }
    return !checkForDuplicates(getALlBVN);
  };
  const prevHandler = () => {
    props.prevHandler(props.prev);
  };

  const inputHandler = (e, i) => {
    const cloneState = _cloneDeep(directorsList);
    // turn all to false besides the last
    for (let j = 0; j < cloneState.length; j++) {
      if (j == i) {
        cloneState[j].primaryPromoter = true;
      } else {
        cloneState[j].primaryPromoter = false;
      }
    }
    setDirectorsList(cloneState);
  };
  const getPosition = (position) => {
    const singlePosition = mgtList?.filter(
      (elem) => elem.positionId == position
    )[0];
    return singlePosition?.positionName;
  };
  return (
    <Styled onSubmit={nextHandler}>
      {isLoading ? <Spinner /> : ''}
      <div className="orr_ques_container">
        <div className="py-6 px-8">
          <div className="text-center">
            <p>
              From the names provided below, who is your primary business
              promoter?
            </p>
          </div>
          <section className="py-8">
            {directorsList.map((elem, i) => (
              <div key={i}>
                <div className="flex justify-between mb-5">
                  <div className="ques_title_wrapper">
                    <span className="mr-5">{i + 1}</span>
                    <label htmlFor={`${i + 1}`} className="overflow-hidden text-ellipsis">
                      {`${elem.firstName} ${
                        elem.lastName
                      } (${getPosition(elem.position)})`}
                    </label>
                  </div>
                  <input
                    id={`${i + 1}`}
                    type="radio"
                    name="promoter"
                    required
                    value={`${i + 1}`}
                    checked={elem.primaryPromoter}
                    onChange={(e) => inputHandler(e, i)}
                    className="w-8 h-8 text-blue-600 bg-gray-300 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
              </div>
            ))}
          </section>
        </div>
      </div>
      <div className="flex justify-between">
        <PrevButton onClick={prevHandler} />
        <NextButton type="submit" />
      </div>
    </Styled>
  );
}

const Styled = styled.form`
  padding-top: 8rem;
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    height: 100%;
    overflow-y: scroll;
    height: 52rem;
    margin-bottom: 2rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .ques_title_wrapper {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.4rem;
  }

`;
