import React, { useState } from 'react';

import { UpdateCollateralPayload } from 'store/actions/collateral';
import { useDispatch, useSelector } from 'react-redux';
import QuestionTypes from './QuestionTypes';

export default function PropertyType() {
  const { collateral } = useSelector((state) => state);
  const { payLoad, checks } = collateral;
  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();

  const question = {
    question: 'What option is the Collateral?',
    questionType: 'single',
    optionsArray: [
      { answer: 'Residential', answerId: 446 },
      { answer: 'Commercial', answerId: 447 },
    ],
  };
  const inputHandler = (e) => {
    setSelected(e.answer);
    dispatch(
      UpdateCollateralPayload({
        searchFeePropertyType: e.answer,
      })
    );
    sessionStorage.setItem('_currentPage', 'searchFeePropertyType');
  };
  return (
    <QuestionTypes
      selected={payLoad.searchFeePropertyType || selected}
      questionTitle={question.question}
      optionsArray={question.optionsArray}
      questionType={question.questionType}
      optionKey="answer"
      optionId="answerId"
      selectHandler={inputHandler}
    />
  );
}
