import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';
import styled from 'styled-components';
import Button from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import { adminUserApis } from 'apis';
import ErrorHandler from 'helpers/errorHandler';

const customStyles = {
  content: {
    borderRadius: '10px',
    background: '#505050',
    color: '#fff',
    border: 'none',
    position: 'relative',
    margin: 'auto',
    inset: '0',
  },
};
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0 0 0 / 34%)';
export default function ConfirmSectorModal(props) {
  const navigate = useNavigate();
  const [isLoading, setLoader] = useState(false);
  const [state, setState] = useState({});
  const [isNewApplication, setIsNewApplication] = useState(true);
  useEffect(() => {
    const getSubSectorDetailsById = async () => {
      try {
        setLoader(true);
        // check if there is existing orr application
        const principalOfficersResponse = await adminUserApis.getGenericAnswers(
          props.auth?.user?.businessId
        );
        if (principalOfficersResponse?.data?.data?.length > 0) {
          setIsNewApplication(false);
          return setLoader(false);
        }
        const res = await adminUserApis.getSubSectorDetailsById(
          props.subSectorId
        );
        setState(res.data.data[0]);
      } catch (error) {
        ErrorHandler(error);
      } finally {
        setLoader(false);
      }
    };
    getSubSectorDetailsById();
    return () => {};
  }, []);

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
    >
      <Styled>
        <div className="closeBtn">
          <FiX size={20} color="#FFF" onClick={props.closeModal} />
        </div>
        <div className="container_content">
          {!isLoading ? (
            <>
              {isNewApplication ? (
                <>
                  <p>
                    Dear customer, you are about to begin your business
                    evaluation
                  </p>
                  <p>View your key details below:</p>
                  <p>
                    Sector:
                    {' '}
                    <strong>
                      {' '}
                      {state.sectorName}
                    </strong>
                  </p>
                  <p>
                    Sub-sector:
                    <strong>
                      {' '}
                      {state.subSectorName}
                      {' '}
                    </strong>
                  </p>
                  <div className="container_btn">
                    <Button
                      title="Update"
                      mode="1"
                      onClick={() => navigate(
                        '/app/settings/business-profile?from=facility-dashboard'
                      )}
                    />
                    <Button
                      title="Okay"
                      mode="2"
                      onClick={() => navigate('/app/orr/principals')}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <p>You cant start a new Application</p>
                  <p>Kindly Use The Continue Application Button</p>
                </div>
              )}
            </>
          ) : (
            <div className="loader">
              <div className="overlay__inner">
                <div className="overlay__content">
                  <span className="spinner" />
                </div>
              </div>
            </div>
          )}
        </div>
      </Styled>
    </Modal>
  );
}
const Styled = styled.div`
  max-width: 35rem;
  margin-top: 1rem;
  .closeBtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
  .container_content {
    text-align: center;
    p {
      padding-bottom: 2rem;
    }
    .container_btn {
      display: flex;
      justify-content: space-around;
    }
  }
  .loader {
    padding: 10rem 13rem;
  }
  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .spinner {
    width: 7.5rem;
    height: 7.5rem;
    display: inline-block;
    border-width: 3px;
    border-color: rgba(255, 255, 255);
    border-top-color: #004c4e;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
