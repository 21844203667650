import React, { useState } from 'react';

import { UpdateCollateralPayload } from 'store/actions/collateral';
import { useDispatch } from 'react-redux';
import QuestionTypes from './QuestionTypes';

export default function SearchFeePayment() {
  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();

  const question = {
    question: 'Are you willing to pay for the search fee now?',
    questionType: 'single',
    optionsArray: [
      { answer: 'Yes', answerId: 446 },
      { answer: 'No', answerId: 447 },
    ],
  };
  const inputHandler = (e) => {
    setSelected(e.answer);
    dispatch(
      UpdateCollateralPayload({
        searchFeePayNow: e.answer,
      })
    );
    sessionStorage.setItem('_currentPage', 'searchFeePayNow');
  };
  return (
    <QuestionTypes
      selected={selected}
      questionTitle={question.question}
      optionsArray={question.optionsArray}
      questionType={question.questionType}
      optionKey="answer"
      optionId="answerId"
      selectHandler={inputHandler}
      wrapperStyle={{ 'justify-content': 'center' }}
    />
  );
}
