import React, { useState, useEffect } from 'react';
import Button from 'components/Buttons';
import { useSelector } from 'react-redux';
import { verificationApis } from 'apis';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ErrorHandler from 'helpers/errorHandler';
import _cloneDeep from 'lodash/cloneDeep';
import { FiInfo } from 'react-icons/fi';
import useSubSectorImage from 'hooks/useSubSectorImage';
import { validateAnswer } from 'helpers/questionsAnswers';
import QuestionTypes from './QuestionTypes';

export default function Questions(props) {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const [subSectorImage, isImageLoading] = useSubSectorImage();
  const [star, setStar] = useState(0);
  const [selected, setSelected] = useState({});
  const [input, setInput] = useState('');
  const [selectedMultiple, setSelectedMultiple] = useState([]);
  const [questionDetails, setQuestionDetails] = useState({});
  const { Management, Business } = props.businessMgtQuestionsPool;
  const { questionId } = props.feedBackDetails;
  useEffect(() => {
    const setQuestionInfo = () => {
      const allQuestion = [...Management, ...Business];
      const currentQuestion = allQuestion.filter(
        (elem) => elem.questionId == questionId
      );
      setQuestionDetails((prev) => currentQuestion[0]);
    };
    setQuestionInfo();
    return () => {};
  }, [Management, Business, questionId]);
  const updateCurrentQuestion = (index, answer) => {
    const cloneState = _cloneDeep(questionDetails);
    const quesType = cloneState.questionType;
    if (quesType == 'single' || quesType == 'Multiple') {
      cloneState.selectedAnswer = answer;
    } else {
      cloneState.inputtedAnswer = answer;
    }
    setQuestionDetails((prev) => ({ ...prev, ...cloneState }));
  };

  const answerFormatter = (elem) => {
    const selectedAnswerInstance = elem.selectedAnswer instanceof Array;
    if (elem.selectedAnswer && !selectedAnswerInstance) {
      return {
        orrResponseId: props.feedBackDetails.orrResponseId,
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: String(elem.selectedAnswer.answerId),
        unWeightedAnswer: 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.selectedAnswer && selectedAnswerInstance) {
      const getAnswersId = elem.selectedAnswer.map((elem) => elem.answerId);
      return {
        orrResponseId: props.feedBackDetails.orrResponseId,
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: getAnswersId.join(),
        unWeightedAnswer: 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.questionType == 'File') {
      return {
        orrResponseId: props.feedBackDetails.orrResponseId,
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: 'string',
        hasDocument: true,
        documentUrl: elem.inputtedAnswer,
      };
    } if (elem.questionType == 'list') {
      return {
        orrResponseId: props.feedBackDetails.orrResponseId,
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: elem.inputtedAnswer.join() || 'string',
        hasDocument: false,
        documentUrl: '',
      };
    } if (elem.questionType == 'contact-list') {
      return {
        orrResponseId: props.feedBackDetails.orrResponseId,
        businessId: auth?.user?.businessId,
        questionId: elem.questionId,
        answerId: '0',
        unWeightedAnswer: JSON.stringify(elem.inputtedAnswer) || 'string',
        hasDocument: false,
        documentUrl: '',
      };
    }
    return {
      orrResponseId: props.feedBackDetails.orrResponseId,
      businessId: auth?.user?.businessId,
      questionId: elem.questionId,
      answerId: '0',
      unWeightedAnswer: String(elem.inputtedAnswer) || 'string',
      hasDocument: false,
      documentUrl: '',
    };
  };

  const formatAnswerForSaving = () => {
    const questionList = [questionDetails];
    const formattedAnswers = [];
    for (let i = 0; i < questionList.length; i++) {
      const elem = questionList[i];
      if (elem.questionClass == 'P') {
        formattedAnswers.push(answerFormatter(elem));
      } else if (elem.questionClass == 'S') {
        if (
          (elem.inputtedAnswer != 'undefined'
            && elem.inputtedAnswer?.length > 1)
          || (elem.selectedAnswer && elem.selectedAnswer != 'undefined')
        ) {
          formattedAnswers.push(answerFormatter(elem));
        }
      }
    }
    return formattedAnswers;
  };
  const submitHandler = async () => {
    if (validateAnswer(questionDetails)) {
      const payLoad = formatAnswerForSaving();
      props.setLoader(true);
      try {
        const res = await verificationApis.updateFlaggedQuestionById({
          data: payLoad[0],
        });
        if (res?.data?.data == 'Successful') {
          navigate('/app/orr/feedback');
        }
      } catch (error) {
        ErrorHandler(error);
      } finally {
        props.setLoader(false);
      }
    }
  };

  return (
    <Styled className="section_ques">
      <main className="orr_ques_main">
        <div className="feedback_report">
          <FiInfo color="#D45F36" size="30" />
          <p>{props.feedBackDetails.feedbackComment}</p>
        </div>
        {questionDetails && questionDetails.categoryName != 'Financials' && (
          <>
            <div className="orr_ques_container">
              <QuestionTypes
                index={0}
                question={questionDetails}
                star={star}
                updateCurrentQuestion={updateCurrentQuestion}
                setStar={setStar}
                selected={selected}
                setSelected={setSelected}
                input={input}
                setInput={setInput}
                selectedMultiple={selectedMultiple}
                setSelectedMultiple={setSelectedMultiple}
              />
            </div>
            <div className="orr_button_section">
              <div />
              <Button mode="1" title="Submit" onClick={submitHandler} />
            </div>
          </>
        )}
      </main>
      <aside className="orr_ques_aside">
        <div className="img-wrapper">
          <img
            src={subSectorImage.length > 2 ? subSectorImage : undefined}
            alt=""
          />
        </div>
      </aside>
    </Styled>
  );
}

const Styled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8rem;
  .feedback_report {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    background: #d1e8e2;
    border-radius: 5px;
    padding: 1rem;
    color: #664d03;
    margin-bottom: 2rem;
    p {
      margin-left: 2rem;
    }
  }
  .orr_ques_container {
    border-radius: 2rem;
    border: 6px solid #d1e8e2;
    min-height: 45rem;
    background-color: #fff;
    display: flex;
  }
  .orr_button_section {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
  }
  .orr_ques_main {
    width: 65%;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  .orr_ques_aside {
    width: 28%;
    text-align: center;

    @media (max-width: 1200px) {
      width: 30%;
    }
    @media (max-width: 850px) {
      display: none;
    }
    button {
      background: #004c4e;
      color: #fff;
    }
    .img-wrapper {
      height: 50rem;
      overflow: hidden;
      margin-bottom: 3.5rem;
      border-radius: 1rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
